import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import CheckIcon from '@/shared/assets/svgs/check.svg?react';
import ZendeskIcon from '@/shared/assets/svgs/zendesk.svg?react';
import { useFlags } from '@/shared/hooks';
import { IconButton } from '@/shared/tempo/atom/IconButton';
import { Tag } from '@/shared/tempo/atom/Tag';
import { Tooltip } from '@/shared/tempo/atom/Tooltip';
import { color } from '@/shared/tempo/theme';

import { ParentView } from '../TaskList';
import { useTaskListContext } from '../TaskList/taskListContext';
import type { Task } from '../types';
import { isScheduledTask } from '../utils';
import {
  EditAction,
  PatientProfileButton,
  ResolvedActions,
  UnassignedActions,
} from './AdditionalActions';
import { CardHoverAction } from './CardHoverAction';
import { SlaTag } from './SlaTag';
import {
  cardHoverAction,
  detailHeaderRow,
  headerRow,
  resolvedTaskType,
  zendeskIndicatorContainer,
} from './TaskCard.css';
import { UrgentIndicator } from './indicators';
import { ResolvedIndicator } from './indicators/ResolvedIndicator';
import { ScheduledIndicator } from './indicators/ScheduledIndicator';
import { useTaskCardContext } from './taskCardContext';
import {
  isResolved,
  isUnassigned,
  isUrgent,
  isZendeskTask,
  useCanAssignTaskType,
  useIsAutomatedPatientCaseFollowUpTaskType,
} from './utils';

type Props = {
  task: Task;
  onResolve: () => void;
  onReopen: () => void;
  onSelfAssignTask?: () => void;
};

export function TaskHeader({
  task,
  onResolve,
  onReopen,
  onSelfAssignTask,
}: Props) {
  const { enableScheduledTasks } = useFlags();
  const canAssignTask = useCanAssignTaskType(task);
  const isAutomatedPatientCaseFollowUpTask =
    useIsAutomatedPatientCaseFollowUpTaskType(task);
  const isZd = isZendeskTask(task);
  const { parentView } = useTaskListContext();
  const { isDetailView } = useTaskCardContext();
  const isProfilePage = parentView === ParentView.PatientProfile;

  return (
    <div className={detailHeaderRow}>
      <span className={headerRow.left}>
        <Tag
          variant="default"
          className={cx({ [resolvedTaskType]: isResolved })}
        >
          {task.taskType.typeName}
        </Tag>
        {isUrgent(task) && <UrgentIndicator resolved={isResolved(task)} />}
        {enableScheduledTasks && isScheduledTask(task) && (
          <ScheduledIndicator scheduledTime={task.scheduledTime} />
        )}
      </span>
      <div className={headerRow.right}>
        <SlaTag task={task} />
        {!isResolved(task) && !isUnassigned(task) && (
          <>
            <CardHoverAction
              className={
                isDetailView
                  ? cardHoverAction.showAlways
                  : cardHoverAction.showOnHover
              }
            >
              {({ isDisabled }) => (
                <>
                  {!isProfilePage && <PatientProfileButton task={task} />}
                  <EditAction task={task} isDisabled={isDisabled} />
                  <Tooltip
                    isDisabled={isDisabled}
                    placement="bottom"
                    content={<FormattedMessage defaultMessage="Resolve Task" />}
                  >
                    <IconButton
                      size="small"
                      variant="tertiary"
                      onPress={onResolve}
                      isDisabled={isDisabled}
                    >
                      <CheckIcon width={16} fill={color.Theme.Light.Primary} />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </CardHoverAction>
          </>
        )}
        {isResolved(task) && !isZd && (
          <>
            <CardHoverAction
              className={
                isDetailView
                  ? cardHoverAction.showAlways
                  : cardHoverAction.showOnHover
              }
            >
              {({ isDisabled }) => (
                <>
                  <ResolvedActions
                    onReopen={onReopen}
                    isDisabled={isDisabled}
                  />
                  {!isProfilePage && <PatientProfileButton task={task} />}
                </>
              )}
            </CardHoverAction>
            <ResolvedIndicator />
          </>
        )}
        {isZd && (
          <div className={zendeskIndicatorContainer}>
            <ZendeskIcon />
          </div>
        )}
        {!isResolved(task) &&
          isUnassigned(task) &&
          canAssignTask &&
          !isAutomatedPatientCaseFollowUpTask && (
            <CardHoverAction
              className={
                isDetailView
                  ? cardHoverAction.showAlways
                  : cardHoverAction.showOnHover
              }
            >
              {({ isDisabled }) => (
                <>
                  {!isProfilePage && <PatientProfileButton task={task} />}
                  <EditAction task={task} isDisabled={isDisabled} />

                  <UnassignedActions
                    task={task}
                    isDisabled={isDisabled}
                    onSelfAssignTask={onSelfAssignTask}
                  />
                </>
              )}
            </CardHoverAction>
          )}
      </div>
    </div>
  );
}
