import { useCallback } from 'react';

import type { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { EncounterModuleId } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { useFlags } from '@/shared/hooks';

import type { TemplateContext } from '../types';
import {
  getEndEarlyTemplate,
  getFieldTemplateMap,
} from './useGetFieldTemplateMap';

export function useHasEndEarlyPatientNotes() {
  const flags = useFlags();

  return useCallback(
    (
      encounterType: TypeOfEncounter | undefined,
      endReason: string,
      context: TemplateContext,
    ): boolean => {
      const template = getFieldTemplateMap(
        encounterType,
        EncounterModuleId.PatientNotes,
        context,
        flags,
      );

      return !!getEndEarlyTemplate(template, endReason);
    },
    [flags],
  );
}
