import { useCallback } from 'react';
import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';

import { logger } from '@/logger';
import { useSidePanelPatientCtx } from '@/pages/patients/PatientProfile/SidePanelPatientContext';
import { useFetchQuery } from '@/reactQuery';
import { useFlags } from '@/shared/hooks';
import {
  PATIENTS_VITALS_QUERY_KEY_BASE,
  useInvalidatePatients,
  usePatientVitalsAlertsByStatus,
} from '@/shared/hooks/queries';
import { useDeleteAutosavedNoteByPatientId } from '@/shared/hooks/queries/autosave-notes.queries';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import type { Toaster } from '@/shared/tempo/molecule/Toast/types';
import type { VitalsAlert } from '@/shared/types/alert.types';
import { AlertStatus } from '@/shared/types/alert.types';
import type { AlertNoteContent } from '@/shared/types/note.types';
import type { Patient, PatientDetails } from '@/shared/types/patient.types';
import { getErrorMsg } from '@/shared/utils/helpers';
import Session from '@/shared/utils/session';

export const getFilteredAlerts = (
  currentPatientDetails: PatientDetails,
  deleteAlertId: number,
) => ({
  ...currentPatientDetails,
  active_vitals_alerts: currentPatientDetails.active_vitals_alerts.filter(
    (currentAlert) => deleteAlertId !== currentAlert.id,
  ),
});

export const getFilteredPatients = (
  patients: Patient[],
  deletePatientId: string,
) => patients?.filter(({ id }) => id !== deletePatientId);

const getPatientDetails = async (patientId: string) => {
  try {
    const response = await Session.Api.get<Patient>(
      `/pms/api/v1/patients/${patientId}`,
    );
    return { response, error: false };
  } catch (error) {
    return { response: undefined, error: true };
  }
};

export const getUpdatedPatients = (
  patients: Patient[],
  latestPatientDetails: Patient,
) =>
  patients.map((patient) => {
    if (patient.id !== latestPatientDetails.id) {
      return patient;
    }
    return latestPatientDetails;
  });

const getAlertById = (activeAlerts: VitalsAlert[], targetId: number) =>
  activeAlerts.find(({ id }) => id === targetId);

function useGetNewUnreadPatientDetails() {
  const { fetch: fetchVitalsAlerts } = useFetchQuery(
    usePatientVitalsAlertsByStatus,
  );
  return async (existingAlert: VitalsAlert) => {
    try {
      const [
        { response, error },
        {
          data: { data: vitalsAlerts },
        },
      ] = await Promise.all([
        getPatientDetails(existingAlert.patient_id),
        fetchVitalsAlerts({
          patientId: existingAlert.patient_id,
          includeStatuses: [AlertStatus.Active, AlertStatus.Pending],
        }),
      ]);

      if (!response || error) {
        // TODO :error handling?
        return {
          error: true,
        };
      }

      const latestCurrentAlert = getAlertById(vitalsAlerts, existingAlert.id);

      return {
        error: false,
        latestPatientDetails: response.data,
        latestCurrentAlert,
        showWarning:
          latestCurrentAlert?.updated_at !== existingAlert.updated_at,
      };
    } catch {
      // Catching and returning the error: true to keep it consistent with
      // the rest of the related code
      return { error: true };
    }
  };
}

const updateAlertRequest = async (
  intl: IntlShape,
  alert: VitalsAlert,
  status: AlertStatus,
  toaster: Toaster,
  enableEscalation: boolean,
  note?: AlertNoteContent,
) => {
  /**
   *  no new readings came in after user loaded the page
   *  allow update
   *  */
  try {
    const updateAlert = await Session.Api.put<VitalsAlert>(
      `/pms/api/v1/patients/${alert.patient_id}/vitals-alerts/${alert.id}?patient_id=${alert.patient_id}&vitals_alert_id=${alert.id}`,
      {
        status,
        note,
        updated_at: alert.updated_at,
        ...(enableEscalation && { escalation_status: alert.escalation_status }),
      },
    );

    toaster.success(
      status === AlertStatus.Dismissed
        ? intl.formatMessage({
            defaultMessage: 'The alert was successfully marked as reviewed',
          })
        : intl.formatMessage({
            defaultMessage: 'The alert was successfully resolved',
          }),
    );
    return {
      response: updateAlert.data,
      error: false,
    };
  } catch (error) {
    toaster.error(getErrorMsg(error));
    return { error: true };
  }
};

type UpdateAlertRequest = {
  alert: VitalsAlert;
  onShowWarningForPatientProfilePage?: (latestPatientDetails: Patient) => void;
  setAlertWaitingForNotes?: (alert: VitalsAlert) => void;
  status?: AlertStatus;
  note?: AlertNoteContent;
};

export const useUpdateOrBlockAlertRequest = () => {
  const { toaster } = useToaster();
  const intl = useIntl();
  const client = useQueryClient();
  const { setPatientDetails, patientId } = useSidePanelPatientCtx();
  const invalidatePatients = useInvalidatePatients();
  const { enableAlertEscalations } = useFlags();

  const deleteAutosavedNote = useDeleteAutosavedNoteByPatientId(patientId);

  const getNewUnreadPatientDetails = useGetNewUnreadPatientDetails();

  return useCallback(
    async ({
      alert,
      onShowWarningForPatientProfilePage,
      status = AlertStatus.Dismissed,
      setAlertWaitingForNotes,
      note,
    }: UpdateAlertRequest): Promise<{
      error: boolean;
    }> => {
      const { showWarning, latestPatientDetails, latestCurrentAlert } =
        await getNewUnreadPatientDetails(alert);

      if (!latestPatientDetails) {
        logger.error(
          `Error while fetching patient details during alert resolution for patient ${alert.patient_id}`,
        );
        return { error: true };
      }

      if (!latestCurrentAlert) {
        toaster.error(
          intl.formatMessage({
            defaultMessage:
              'This alert has been resolved by another user. Please refresh the page and view your note in autosaved drafts to continue publishing.',
          }),
        );
        logger.warn('This alert has been resolved by another user already');
        return { error: true };
      }
      /**
       *  new readings came in after user loaded the page
       *  show warning then user can then click publish note again
       *  cause we silently set current alert as the newly fetched latestCurrentAlert here
       *  */

      if (showWarning) {
        // TODO: stop casting once we've eradicated patient details context
        // update AlertDescription
        setPatientDetails(latestPatientDetails as unknown as PatientDetails);

        // update VitalsTable
        client.invalidateQueries(PATIENTS_VITALS_QUERY_KEY_BASE);

        // update PatientList if exists
        onShowWarningForPatientProfilePage?.(latestPatientDetails);

        toaster.alert(
          intl.formatMessage({
            defaultMessage:
              'Heads up! New vitals have just arrived for this patient',
          }),
        );

        // silently update user selected alert that is chosen to be updated
        setAlertWaitingForNotes?.(latestCurrentAlert);
        logger.error('Showing warning due to new vitals arriving for patient.');
        return { error: true };
      }

      const { error, response } = await updateAlertRequest(
        intl,
        alert,
        status,
        toaster,
        enableAlertEscalations,
        note,
      );

      if (response?.note && response.note.note_type !== 'ALERT_DISMISSAL') {
        await deleteAutosavedNote.mutate({ new_note_id: response?.note.id });
      }

      // Setting state update to the end of render loop since we need to close the
      // MarkAsReviewed dialog first if exists
      setTimeout(() => {
        if (!error) {
          // TODO: stop casting once we've eradicated patient details context
          setPatientDetails(
            getFilteredAlerts(
              latestPatientDetails as unknown as PatientDetails,
              alert.id,
            ),
          );
        }
      }, 0);

      // Not awaiting on purpose so first UI may get updated before invalidating
      // the queries, to avoid state updates after unmounting of some components
      invalidatePatients();

      return { error };
    },
    [
      toaster,
      getNewUnreadPatientDetails,
      intl,
      invalidatePatients,
      setPatientDetails,
      client,
      deleteAutosavedNote,
      enableAlertEscalations,
    ],
  );
};
