import cx from 'classnames';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { Table } from '@/shared/common/Table';
import { Tooltip } from '@/shared/tempo/atom/Tooltip';

import { readingCell, table } from './PatientActivity.css';

type Props = {
  reading: ReactNode;
  goal?: ReactNode;
  atGoal?: boolean;
};

export function VitalCell({ reading, goal, atGoal }: Props) {
  return (
    <Table.NodeCell align="right" className={cx(table.cell, table.rightAlign)}>
      <Tooltip
        content={
          <FormattedMessage
            defaultMessage="{atGoal, select, true {At goal} other {Not at goal} } :{goal}"
            values={{
              goal,
              atGoal: atGoal === true,
            }}
          />
        }
      >
        <div>
          <p
            className={cx(readingCell.text, {
              [readingCell.overGoal]: atGoal === false,
              [readingCell.atGoal]: atGoal === true,
            })}
          >
            {reading}
          </p>
          {goal && (
            <p className={cx(readingCell.text, readingCell.goal)}>{goal}</p>
          )}
        </div>
      </Tooltip>
    </Table.NodeCell>
  );
}
