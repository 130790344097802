import type { ReactElement } from 'react';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { Tag } from '@/shared/tempo/atom/Tag';

import { GoalMeasure } from '../../../Goals/goals.types';
import { getAssessmentFieldNames } from '../../formConfig';
import { GapWidget } from './GapWidget';
import type { DaysPerWeek, MinutesPerDay } from './physicalActivity.types';
import * as styles from './shared.css';
import type { PsychosocialAssessmentComponentProps } from './shared.types';

const DAYS_PER_WEEK: DaysPerWeek[] = [0, 1, 2, 3, 4, 5, 6, 7];
const MINUTES_PER_DAY: MinutesPerDay[] = [
  0, 10, 20, 30, 40, 50, 60, 90, 120, 150,
];

export function PhysicalActivity({
  form,
  createGoalWithMeasure,
  hasGap,
  onEditGoal,
  carePlanId,
}: PsychosocialAssessmentComponentProps): ReactElement {
  const intl = useIntl();

  const exerciseDaysPerWeek = form.watch('exerciseDaysPerWeek');

  const showMinutesField =
    exerciseDaysPerWeek !== undefined && exerciseDaysPerWeek !== 0;

  const { description: descriptionFieldName, checked: checkedFieldName } =
    getAssessmentFieldNames('physicalActivity');

  const hasGapVal = hasGap(form.getValues());
  useEffect(() => {
    form.setValue(checkedFieldName, hasGapVal, {
      shouldValidate: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasGapVal]);

  return (
    <>
      <div className={styles.leftColumn}>
        <Form.RadioGroup
          name="exerciseDaysPerWeek"
          label={
            <>
              <Tag variant="default" className={styles.sectionTag}>
                <FormattedMessage defaultMessage="Physical Activity" />
              </Tag>{' '}
              <FormattedMessage defaultMessage="In the last 30 days, on average how many days per week did you engage in moderate exercise (like walking fast, jogging, dancing, swimming, or biking)?" />
            </>
          }
          orientation="horizontal"
          size={11}
          required
          valueMap={Object.fromEntries(
            DAYS_PER_WEEK.map((value) => [value.toString(), value]),
          )}
        >
          {DAYS_PER_WEEK.map((value) => (
            <Form.Radio key={value} value={value.toString()}>
              {value}
            </Form.Radio>
          ))}
        </Form.RadioGroup>

        {showMinutesField && (
          <div className={styles.detailContainer}>
            <Form.RadioGroup
              classes={{ root: styles.radioGroup }}
              name="exerciseMinutesPerDay"
              label={
                <FormattedMessage defaultMessage="On average, how many minutes did you usually spend exercising at this level on one of those days?" />
              }
              orientation="horizontal"
              size={12}
              required
              valueMap={Object.fromEntries(
                MINUTES_PER_DAY.map((value) => [value.toString(), value]),
              )}
            >
              {MINUTES_PER_DAY.map((value) => (
                <Form.Radio key={value} value={value.toString()}>
                  {value === 150 ? (
                    <FormattedMessage defaultMessage="150+" />
                  ) : (
                    value
                  )}
                </Form.Radio>
              ))}
            </Form.RadioGroup>
          </div>
        )}

        {hasGapVal && (
          <div className={styles.gapMessage}>
            <FormattedMessage defaultMessage="Patient has a physical activity need according to CMS guidelines" />
          </div>
        )}
      </div>

      <div className={styles.rightColumn}>
        <GapWidget
          fieldName={descriptionFieldName}
          placeholder={intl.formatMessage({
            defaultMessage:
              'Enter details about physical activity. This is required if a gap is detected (less than 150 minutes a week)',
          })}
          showGoalButton
          onCreateGoal={createGoalWithMeasure}
          measure={GoalMeasure.PhysicalActivity}
          form={form}
          hasGap={hasGapVal}
          onEditGoal={onEditGoal}
          carePlanId={carePlanId}
          showWidget={exerciseDaysPerWeek !== undefined}
        />
      </div>
    </>
  );
}
