import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import type { FormConfig } from '../form.types';
import { getDefaults, getValidationSchema } from '../form.utils';
import type { ConfiguredForm } from './types';

export function useFormFromConfig<TFieldValues extends FieldValues>(
  config: FormConfig,
): ConfiguredForm<TFieldValues> {
  const { fields, cyclicalDependencies, triggerReset } = config;

  const validationSchema = getValidationSchema(fields, cyclicalDependencies);
  const form = useForm<TFieldValues>({
    // Triggers validation on both blur and change events. Note that the validation will be applied only on the changed field -- to validate the entire form, use `form.trigger`
    mode: 'all',
    defaultValues: getDefaults<TFieldValues>(fields),
    resolver: yupResolver(validationSchema),
    shouldUnregister: true,
  });

  useEffect(() => {
    if (triggerReset) {
      form.reset(getDefaults<TFieldValues>(fields));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerReset]);

  /*
  important: formState is wrapped with Proxy to improve render performance
  and skip extra logic if specific state is not subscribed, so make sure
  you deconstruct or read it before render in order to enable the subscription.
  https://react-hook-form.com/api/useformstate/

  do not refactor to `canSubmit = formState.isDirty && formState.isValid`
  or it will cause `canSubmit` to return incorrectly in some cases
  */
  const { formState } = form;
  const { isDirty, isValid, dirtyFields } = formState;
  const canSubmit = isDirty && isValid;
  return {
    ...form,
    canSubmit,
    dirtyFields,
    config,
    validationSchema,
  };
}
