import { zonedTimeToUtc } from 'date-fns-tz';
import type { IntlShape } from 'react-intl';
import * as yup from 'yup';

import type { FormConfig } from '@/shared/common/Form';
import { validators } from '@/shared/common/Form/validations';
import { GRPC_RESOURCE_NAME } from '@/shared/hooks/queries/tasks.queries';
import type { Provider } from '@/shared/types/provider.types';

import { toPartialProvider, urgencyToPriority } from '../TaskCard/utils';
import type { CreateTaskPayload, Task } from '../types';
import { TaskPriority } from '../types';
import { smallestAcceptableDate } from '../utils';

export type FormFields = {
  taskTypeId?: string;
  assignee?: Provider;
  is_urgent: boolean;
  note: string;
  assigneeType: 'team' | 'individual';
  scheduled_time?: Date | null;
};

export const MAX_NOTE_LENGTH = 400;

export function getFormConfig(intl: IntlShape, task?: Task): FormConfig {
  const { required, maxLengthString, date } = validators(intl);

  const defaultScheduledTime = smallestAcceptableDate();
  const noteValidation = maxLengthString({
    maxLength: MAX_NOTE_LENGTH,
    errorMessage: intl.formatMessage({
      defaultMessage: 'Note may not exceed 400 characters',
    }),
  });

  return {
    fields: {
      taskTypeId: {
        defaultValue: task?.taskTypeId,
        validation: yup.string(),
      },
      assignee: {
        defaultValue: task?.assignee
          ? toPartialProvider(task.assignee)
          : undefined,
        validation: yup.mixed().when('assigneeType', {
          is: 'individual',
          then: (schema) => required(schema),
        }),
      },
      assigneeType: {
        defaultValue: task?.assignee ? 'individual' : 'team',
      },
      is_urgent: {
        defaultValue: task?.priority === TaskPriority.Urgent,
        validation: yup.boolean(),
      },
      note: {
        validation: task ? noteValidation : required(noteValidation),
      },
      scheduled_time: {
        defaultValue: undefined,
        validation: date({
          minDate: defaultScheduledTime,
        }),
      },
    },
  };
}

export function formDataToTaskPayload(
  {
    note,
    assignee,
    is_urgent: isUrgent,
    taskTypeId,
    scheduled_time,
  }: FormFields,
  patientId: string,
): CreateTaskPayload {
  const scheduledTimeUTC = scheduled_time
    ? zonedTimeToUtc(scheduled_time, 'UTC').toISOString()
    : undefined;

  return {
    patientId,
    description: note,
    priority: urgencyToPriority(isUrgent),
    scheduledTime: scheduledTimeUTC,
    isSystemGenerated: false,
    ...(assignee && {
      assignee: { name: GRPC_RESOURCE_NAME.careProvider(assignee.id) },
    }),
    ...(taskTypeId && { taskTypeId }),
  };
}
