import { useState } from 'react';

import { TextArea } from '@/shared/common/TextArea';
import { Label } from '@/shared/tempo/atom/Label';

import { EncounterModuleId } from '../../Notes.types';
import type { EncounterModuleInstance } from '../../Notes.types';
import { useEncounterModule } from '../../note.queries';
import { textarea } from './optionalNoteModule.css';

export type OptionalNoteFormProps = {
  initialValues?: OptionalNote;
  onChange: (inputs: EncounterModuleInstance) => void;
};

type OptionalNote = {
  optional_notes?: string;
};

export function OptionalNoteModule({
  initialValues,
  onChange,
}: OptionalNoteFormProps) {
  const [optionalNoteBody, setOptionalNoteBody] = useState(
    initialValues?.optional_notes || '',
  );
  const { encounterModule } = useEncounterModule(
    EncounterModuleId.OptionalNotes,
  );

  if (!encounterModule) {
    return null;
  }

  return (
    <>
      <Label
        label="Optional alert details"
        description="Will be added to published note and automated escalation task created if applicable"
      />
      <TextArea
        classes={{ input: textarea }}
        name="optionalNote"
        maxLength={800}
        placeholder="Enter optional details"
        value={optionalNoteBody}
        onChange={(val) => {
          setOptionalNoteBody(val.target.value);
          onChange({
            encounter_module_id: encounterModule.id,
            inputs: {
              optional_notes: val.target.value,
            } as OptionalNote,
          });
        }}
      />
    </>
  );
}
