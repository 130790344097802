import { EndEncounterReason } from '@/shared/types/note.types';

import { endEarlyNoteTemplates } from '../../../../__shared__/endEarly';

// Create a copy of the shared templates
const sharedTemplatesWithOverrides = { ...endEarlyNoteTemplates };

// Delete the specific templates that aren't applicable to discharge followup, when these end reasons
// are selected, it'll fallback to the 'default' shared template
delete (sharedTemplatesWithOverrides as Record<string, unknown>)[
  EndEncounterReason.TimeConstraints
];
delete (sharedTemplatesWithOverrides as Record<string, unknown>)[
  EndEncounterReason.DeviceDelayTroubleshooting
];

export const endEarlyTemplates = sharedTemplatesWithOverrides;
