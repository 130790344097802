import orderBy from 'lodash/orderBy';
import { useIntl } from 'react-intl';

import { getCcmConditionI18n } from '@/pages/adminPanel/patient-profile/tabs/ClinicalProfileTab/clinicalprofile.utils';
import type { CCMGoal } from '@/shared/generated/grpc/go/pms/pkg/ccm_goal/ccm_goal.pb';
import { usePatientCcmGoals } from '@/shared/hooks/queries/ccmGoals.queries';
import type { CcmCondition } from '@/shared/types/clinicalprofile.types';

import { isReviewGoalForCcmCondition } from '../CarePlanForm/dataTransformation';
import { isConditionReviewGoal } from '../CarePlanForm/sections/GoalsAndInterventions/conditionGoalUtils';
import { type Goal, type GoalMeasure, GoalStatus } from './goals.types';

export function sortGoalsByPriority(goals: Maybe<Goal[]>) {
  const statusPriority: Record<GoalStatus, number> = {
    [GoalStatus.OPEN]: 1,
    [GoalStatus.IN_PROGRESS]: 2,
    [GoalStatus.ABANDONED]: 3,
    [GoalStatus.COMPLETE]: 4,
    [GoalStatus.DECLINED]: 5,
    [GoalStatus.FUTURE]: 6,
    [GoalStatus.GOAL_STATUS_UNSPECIFIED]: 7,
  };
  if (!goals) {
    return [];
  }
  return orderBy(goals, [(goal) => statusPriority[goal.status as GoalStatus]]);
}

export function useExistingMeasureGoal(
  patientId: string,
  carePlanId: string,
  measure: Maybe<GoalMeasure>,
) {
  const { data: goals } = usePatientCcmGoals(patientId);

  if (!measure) {
    return undefined;
  }

  const sortedGoals = sortGoalsByPriority(goals?.ccmGoals);
  return sortedGoals.find(
    (goal) =>
      goal.measure === measure &&
      (goal.ccmCarePlanId === carePlanId ||
        goal.status === GoalStatus.IN_PROGRESS ||
        goal.status === GoalStatus.OPEN),
  );
}

export function useExistingConditionGoals(
  patientId: string,
  carePlanId: string,
  condition: CcmCondition,
  relatedGoalMeasure: Maybe<GoalMeasure>,
) {
  const intl = useIntl();
  const { data: goals } = usePatientCcmGoals(patientId);
  const conditionName = getCcmConditionI18n(intl)[condition];
  const ccmGoals = goals?.ccmGoals;

  const existingMeasureGoal = useExistingMeasureGoal(
    patientId,
    carePlanId,
    relatedGoalMeasure,
  );

  const declinedGoal = ccmGoals?.find(
    (goal) =>
      goal.status === GoalStatus.DECLINED &&
      goal.title?.includes(conditionName) &&
      goal.title?.startsWith('Declined') &&
      goal.ccmCarePlanId === carePlanId,
  );

  const reviewGoal = ccmGoals?.find(
    (goal) =>
      hasActiveStatus(goal) &&
      isReviewGoalForCcmCondition(goal, condition, intl) &&
      goal.title?.includes(conditionName) &&
      goal.ccmCarePlanId === carePlanId,
  );

  const activeMeasureGoal =
    existingMeasureGoal && hasActiveStatus(existingMeasureGoal)
      ? existingMeasureGoal
      : null;

  return {
    declinedGoal,
    reviewGoal:
      activeMeasureGoal && isConditionReviewGoal(activeMeasureGoal)
        ? activeMeasureGoal
        : reviewGoal,
    nonReviewGoal:
      activeMeasureGoal && !isConditionReviewGoal(activeMeasureGoal)
        ? activeMeasureGoal
        : null,
  };
}

export function hasActiveStatus(goal: CCMGoal) {
  return (
    goal.status === GoalStatus.OPEN || goal.status === GoalStatus.IN_PROGRESS
  );
}

export function useInProgressGoals(patientId: string) {
  return (
    usePatientCcmGoals(patientId)
      .data?.ccmGoals?.filter((goal) => goal.status === GoalStatus.IN_PROGRESS)
      .map((goal) => ({
        ...goal,
        isConditionReview: isConditionReviewGoal(goal),
      })) || []
  );
}
