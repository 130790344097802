import { format, parseISO } from 'date-fns';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import type { TestContext } from 'yup';

import { TimeTrackedTaskType } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { useTimeTrackingEditableDateRange } from '@/pages/patients/patientDetails/ui/shared/timeTracking.utils';
import { validators } from '@/shared/common/Form/validations';
import { ProgramType } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import type { MonitoringSession } from '@/shared/types/monitoringSession.types';
import {
  type CommunicationType,
  EntryType,
} from '@/shared/types/monitoringSession.types';
import type { Note } from '@/shared/types/note.types';

export type TimeTrackingFormFields = {
  notes: string;
  start_date: Date;
  start_time: string;
  minutes?: number;
  communication_type: CommunicationType;

  rpm_interactive_duration?: number;
  rpm_non_interactive_duration?: number;
  rpm_tasks_accomplished?: TimeTrackedTaskType[];
  rpm_other_task_description?: string;

  // Only used for displaying total time validation
  totalTimeDisplay: number;
};

type EntryAction =
  | {
      action: 'create';
      timeEntry?: null;
      entryType: EntryType;
      patientNoShow?: boolean;
    }
  | {
      action: 'update';
      timeEntry: MonitoringSession;
      note: Maybe<Note>;
      patientNoShow?: boolean;
    };

export function useTimeTrackingFormConfig(params: EntryAction) {
  const { action, timeEntry } = params;
  const intl = useIntl();
  const { minDate, maxDate } = useTimeTrackingEditableDateRange(
    timeEntry || undefined,
  );
  const { required, maxLengthString, date, enumType, number, array } =
    validators(intl);
  const entryType = getEntryType(params);
  const isEncounterType = entryType === EntryType.Encounter;
  const defaultStartDate = isEncounterType ? new Date() : null;
  const defaultStartTime = isEncounterType ? format(new Date(), 'HH:mm') : null;

  function validateTotalTime<S extends yup.AnySchema>(schema: S) {
    const min = isEncounterType ? 0 : 1;
    return schema.test(
      'total-time',
      intl.formatMessage(
        { defaultMessage: 'Total time must be {min}-120 mins' },
        { min },
      ),
      (_, context: TestContext) => {
        const fields = context.parent as TimeTrackingFormFields;
        const totalTime =
          (fields.rpm_interactive_duration || 0) +
          (fields.rpm_non_interactive_duration || 0);
        return totalTime >= min && totalTime <= 120;
      },
    );
  }

  function validateDuration(isMinZero: boolean, isRequired: boolean) {
    return required({
      schema: number({
        min: isMinZero ? 0 : 1,
        max: 120,
        integer: true,
        errorMessage: intl.formatMessage(
          { defaultMessage: 'Must be {min}-{max} mins' },
          {
            min: isMinZero ? 0 : 1,
            max: 120,
          },
        ),
      }),
      when: () => isRequired,
    });
  }

  function tasksAccomplishedArray(minLength: number) {
    return array({
      ofType: enumType({
        source: TimeTrackedTaskType,
        pluck: 'values',
      }),
      minLength,
    });
  }

  const rpmEntry = timeEntry?.entries?.find(
    (entry) => entry.program_type === ProgramType.RPM,
  );

  return {
    fields: {
      start_date: {
        defaultValue:
          action === 'update' ? timeEntry.start_datetime : defaultStartDate,
        validation: required(
          date({
            minDate,
            maxDate,
          }),
        ),
      },
      start_time: {
        defaultValue:
          action === 'update'
            ? format(parseISO(timeEntry.start_datetime), 'HH:mm')
            : defaultStartTime,
        validation: yup.string().required(
          intl.formatMessage({
            defaultMessage: 'Must be a valid time',
          }),
        ),
      },
      // This field just used for display / validations
      totalTimeDisplay: {
        validation: validateTotalTime(number({})),
      },
      rpm_interactive_duration: {
        defaultValue:
          action === 'update'
            ? rpmEntry?.interactive_duration ?? timeEntry.interactive_duration
            : undefined,
        validation: validateDuration(true, !params.patientNoShow),
      },
      rpm_non_interactive_duration: {
        defaultValue:
          action === 'update'
            ? rpmEntry?.non_interactive_duration ??
              timeEntry.non_interactive_duration
            : undefined,
        validation: validateDuration(true, true),
      },
      rpm_tasks_accomplished: {
        defaultValue:
          action === 'update'
            ? (rpmEntry?.tasks_accomplished ?? timeEntry.tasks_accomplished) ||
              []
            : [],
        validation: tasksAccomplishedArray(0).when(
          ['non_interactive_duration'],
          {
            is: (nonInteractiveDuration?: number) => nonInteractiveDuration,
            then: () => tasksAccomplishedArray(1),
          },
        ),
      },
      rpm_other_task_description: {
        defaultValue:
          action === 'update'
            ? rpmEntry?.other_task_description ??
              timeEntry.other_task_description
            : '',
        validation: maxLengthString({ maxLength: 400 }).test(
          'required',
          intl.formatMessage({ defaultMessage: 'Required' }),
          (value: string | undefined, context: TestContext) => {
            const fields = context.parent as TimeTrackingFormFields;
            return (
              !(fields.rpm_tasks_accomplished || []).includes(
                TimeTrackedTaskType.Other,
              ) || !!value
            );
          },
        ),
      },
    },
  };
}

function getEntryType(params: EntryAction) {
  if (params.action === 'create') {
    return params.entryType;
  }
  return params.timeEntry.type;
}
