import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import { logger } from '@/logger';
import { serializeConditions } from '@/shared/common/ConditionSelect/conditionDataConversionUtils';
import type {
  PatientProgramStatus,
  PatientClinicalProfile as PbPatientClinicalProfile,
  PatientConditionsPerProgram as PbPatientConditionsPerProgram,
  PatientProgramWithProfile as PbPatientProgramWithProfile,
  Program as PbProgram,
  ProgramCondition as PbProgramCondition,
} from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import {
  PatientStatusEnum,
  ProgramStatus,
  ProgramType,
  Condition as grpcCondition,
} from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { ProgramConditionAbbreviation } from '@/shared/hooks/queries';
import {
  convertPtConditionPerProgramToPrograms,
  pbConditionTypeToConditionTypeMap,
} from '@/shared/hooks/queries/patients-grpc/converters';
import { getProgramEnrollmentStatus } from '@/shared/patient/programs.utils';
import type {
  CcmCondition,
  ClinicalProfileFormData,
  ConditionAbbreviationMap,
  ICD10Code,
  NullableValuesPatientClinicalProfile,
} from '@/shared/types/clinicalprofile.types';
import {
  Condition,
  ConditionAbbreviation,
} from '@/shared/types/clinicalprofile.types';
import type {
  Program,
  ProgramCondition,
  ProgramConditionHistory,
} from '@/shared/types/patient.types';
import { PatientStatus } from '@/shared/types/patient.types';
import { idToGrpcName, parseGrpcDate } from '@/shared/utils/grpc';
import { displayA1c } from '@/shared/utils/helpers';
import { convertToGrams, convertToLbs } from '@/shared/utils/unit-helpers';

export const UKNOWN_VALUE = 'UNKNOWN';

function unknownIfNull<T>(val: Nullable<T>): T | typeof UKNOWN_VALUE {
  if (val === null) {
    return UKNOWN_VALUE;
  }
  return val;
}

export function convertFormDataToClinicalProfile(
  formData: ClinicalProfileFormData,
  patientId: string,
  currClinicalProfile: Nullable<PbPatientClinicalProfile>,
): PbPatientClinicalProfile {
  const nullableProfile: NullableValuesPatientClinicalProfile = {
    ...currClinicalProfile,
    name: idToGrpcName('patients', patientId, 'clinicalProfile'),
    efLower: parseInt(formData['chf-ejection-fraction-low'] ?? '', 10) || null,
    efUpper: parseInt(formData['chf-ejection-fraction-high'] ?? '', 10) || null,
    chfClass: formData['chf-class'] || null,
    chfStage: formData['chf-stage'] || null,
    dryWeight: !formData['chf-dry-weight']
      ? null
      : convertToGrams(parseInt(formData['chf-dry-weight'], 10)),
    a1c: (formData['t2d-a1c'] as number | undefined) ?? null,
  };

  return nullableProfile as PbPatientClinicalProfile;
}

const createProgram = (
  conditionIds: PbProgramCondition[],
  programType: ProgramType,
  programStatus: ProgramStatus,
): PbProgram => ({
  programType,
  programStatus,
  conditions: conditionIds,
});

const createPbConditions = (icd10s: Maybe<ICD10Code[]>) =>
  icd10s?.map(({ id, ...rest }) => ({
    name: idToGrpcName('conditions', `${id}`),
    ...rest,
  })) || [];

// TODO: Delete after multiprogram UI rollout
const createPrograms = (
  formData: ClinicalProfileFormData,
  isEnrolledPatient: boolean,
) => {
  const programsToCreate = [];
  const programStatus = isEnrolledPatient
    ? ProgramStatus.ENROLLED
    : ProgramStatus.SELECTED;

  const rpmIcd10Codes = createPbConditions(formData['icd10-codes']);
  const isRpmParticipating = formData['is-participating'];
  if (isRpmParticipating && rpmIcd10Codes.length > 0) {
    programsToCreate.push(
      createProgram(rpmIcd10Codes, ProgramType.RPM, programStatus),
    );
  }

  const isCCMParticipating = formData['ccm-is-participating'];
  const ccmIcd10Codes = createPbConditions(formData['ccm-conditions']);
  if (isCCMParticipating && ccmIcd10Codes.length > 0) {
    programsToCreate.push(
      createProgram(ccmIcd10Codes, ProgramType.CCM, programStatus),
    );
  }
  const apcmProgramStatus = isEnrolledPatient
    ? ProgramStatus.ENROLLED
    : ProgramStatus.SELECTED;
  const isAPCMParticipating = formData['apcm-is-participating'];
  if (isAPCMParticipating) {
    programsToCreate.push(
      createProgram([], ProgramType.APCM, apcmProgramStatus),
    );
  }

  if (isEnrolledPatient) {
    const isSuggestionsActive = formData['is-rpm-suggestion-active'];
    const suggestedRpmIcd10Codes = createPbConditions(
      formData['suggested-rpm-icd10-codes'],
    );
    if (isSuggestionsActive && suggestedRpmIcd10Codes.length > 0) {
      programsToCreate.push(
        createProgram(
          suggestedRpmIcd10Codes,
          ProgramType.RPM,
          ProgramStatus.SUGGESTED,
        ),
      );
    }
  }
  return programsToCreate;
};

const createProgramsV2 = (
  formData: ClinicalProfileFormData,
  programStatuses: PatientProgramStatus[],
) => {
  const programsToCreate = [];

  const { isRPMEnrolled, isCCMEnrolled, isAPCMEnrolled } =
    getProgramEnrollmentStatus(programStatuses);

  const getProgramToCreate = (isEnrolledInProgram: boolean) =>
    isEnrolledInProgram ? ProgramStatus.ENROLLED : ProgramStatus.SELECTED;

  const rpmIcd10Codes = createPbConditions(formData['icd10-codes']);
  const isRpmParticipating = formData['is-participating'];
  if (isRpmParticipating && rpmIcd10Codes.length > 0) {
    programsToCreate.push(
      createProgram(
        rpmIcd10Codes,
        ProgramType.RPM,
        getProgramToCreate(isRPMEnrolled),
      ),
    );
  }

  const isCCMParticipating = formData['ccm-is-participating'];
  const ccmIcd10Codes = createPbConditions(formData['ccm-conditions']);
  if (isCCMParticipating && ccmIcd10Codes.length > 0) {
    programsToCreate.push(
      createProgram(
        ccmIcd10Codes,
        ProgramType.CCM,
        getProgramToCreate(isCCMEnrolled),
      ),
    );
  }
  const isAPCMParticipating = formData['apcm-is-participating'];
  if (isAPCMParticipating) {
    programsToCreate.push(
      createProgram([], ProgramType.APCM, getProgramToCreate(isAPCMEnrolled)),
    );
  }

  if (isRPMEnrolled) {
    const isSuggestionsActive = formData['is-rpm-suggestion-active'];
    const suggestedRpmIcd10Codes = createPbConditions(
      formData['suggested-rpm-icd10-codes'],
    );
    if (isSuggestionsActive && suggestedRpmIcd10Codes.length > 0) {
      programsToCreate.push(
        createProgram(
          suggestedRpmIcd10Codes,
          ProgramType.RPM,
          ProgramStatus.SUGGESTED,
        ),
      );
    }
  }
  return programsToCreate;
};

// TODO: Delete after multiprogram UI rollout
export function convertFormDataToPbPatientProgramWithProfile(
  formData: ClinicalProfileFormData,
  patientId: string,
  isEnrolledPatient: boolean,
  currClinicalProfile: Nullable<PbPatientClinicalProfile>,
): PbPatientProgramWithProfile {
  return {
    name: idToGrpcName('patients', patientId, 'programWithProfile'),
    clinicalProfile: convertFormDataToClinicalProfile(
      formData,
      patientId,
      currClinicalProfile,
    ),
    programs: { programs: createPrograms(formData, isEnrolledPatient) },
  };
}

export function convertFormDataToPbPatientProgramWithProfileV2(
  formData: ClinicalProfileFormData,
  patientId: string,
  programStatuses: PatientProgramStatus[],
  currClinicalProfile: Nullable<PbPatientClinicalProfile>,
): PbPatientProgramWithProfile {
  return {
    name: idToGrpcName('patients', patientId, 'programWithProfile'),
    clinicalProfile: convertFormDataToClinicalProfile(
      formData,
      patientId,
      currClinicalProfile,
    ),
    programs: { programs: createProgramsV2(formData, programStatuses) },
  };
}

export function convertClinicalProfileToFormData(
  clinicalProfile: Nullable<PbPatientClinicalProfile>,
  programConditions: Nullable<Condition[]>,
  programICD10s: Nullable<ICD10Code[]>,
  suggestedProgramConditions?: Nullable<Condition[]>,
  suggestedProgramICD10s?: Nullable<ICD10Code[]>,
  ccmProgramICD10s?: Nullable<ICD10Code[]>,
): Partial<ClinicalProfileFormData> {
  if (clinicalProfile) {
    const conditions = serializeConditions(programConditions) ?? '';
    const suggestedConditions =
      serializeConditions(suggestedProgramConditions) ?? '';

    return {
      'chronic-conditions': conditions,
      'icd10-codes': programICD10s || [],
      'is-participating': Boolean(programConditions),

      // Suggested RPM
      'suggested-rpm-conditions': suggestedConditions,
      'suggested-rpm-icd10-codes': suggestedProgramICD10s,
      'is-rpm-suggestion-active': Boolean(suggestedConditions),

      // CHF
      'chf-stage': unknownIfNull(clinicalProfile?.chfStage) || '',
      'chf-class': unknownIfNull(clinicalProfile?.chfClass) || '',
      'chf-ejection-fraction-low': clinicalProfile?.efLower?.toString() || '',
      'chf-ejection-fraction-high': clinicalProfile?.efUpper?.toString() || '',
      'chf-dry-weight': clinicalProfile?.dryWeight
        ? convertToLbs(clinicalProfile?.dryWeight).toString()
        : '',

      // T2D
      't2d-a1c': displayA1c(clinicalProfile?.a1c, '', ''),

      // HTN

      // CCM
      'ccm-conditions': ccmProgramICD10s || [],
      'ccm-is-participating': isEmpty(ccmProgramICD10s),
    };
  }
  return {};
}

export function isEnrolled(status?: PatientStatus | PatientStatusEnum) {
  return (
    status === PatientStatus.Enrolled || status === PatientStatusEnum.ENROLLED
  );
}

export function isDisenrolled(status?: PatientStatus | PatientStatusEnum) {
  return (
    status === PatientStatus.Disenrolled ||
    status === PatientStatusEnum.DISENROLLED
  );
}

export function convertSuggestedProgramConditionsToConditionList(
  programs: Maybe<Program[]>,
  patientStatus?: PatientStatus | PatientStatusEnum,
): [Condition[], ICD10Code[]] {
  if (!programs?.length) {
    return [[], []];
  }
  const rpmPrograms = programs.filter(
    ({ program_type }) => program_type === ProgramType.RPM,
  );

  const suggestedProgram = rpmPrograms.find(
    ({ program_status }) => program_status === ProgramStatus.SUGGESTED,
  );
  if (suggestedProgram && isEnrolled(patientStatus)) {
    return conditionsAndICD10sFromProgram(suggestedProgram.conditions);
  }

  return [[], []];
}

export function convertCCMProgramConditionsToConditionList(
  programs: Maybe<Program[]>,
  patientStatus?: PatientStatus | PatientStatusEnum,
  isMultiprogramView = false,
): ICD10Code[] {
  if (!programs?.length) {
    return [];
  }
  const ccmPrograms = programs.filter(
    ({ program_type }) => program_type === ProgramType.CCM,
  );
  const enrolledCCM = ccmPrograms.find(
    ({ program_status }) => program_status === ProgramStatus.ENROLLED,
  );
  const selectedCCM = ccmPrograms.find(
    ({ program_status }) => program_status === ProgramStatus.SELECTED,
  );
  const eligibleCCM = ccmPrograms.find(
    ({ program_status }) => program_status === ProgramStatus.ELIGIBLE,
  );

  if (enrolledCCM && isEnrolled(patientStatus)) {
    return enrolledCCM.conditions.map((condition) =>
      icd10CodeFromCondition(condition),
    );
  }
  if (selectedCCM && !isEnrolled(patientStatus)) {
    return selectedCCM.conditions.map((condition) =>
      icd10CodeFromCondition(condition),
    );
  }

  if (isMultiprogramView) {
    if (selectedCCM) {
      // Always show selected conditions regardless of top level status
      return selectedCCM.conditions.map((condition) =>
        icd10CodeFromCondition(condition),
      );
    }
    // Condition changes are disabled for this case, but still display conditions attached
    // to ELIGIBLE if patient hasn't been SELECTED yet.
    if (eligibleCCM && !selectedCCM) {
      return eligibleCCM.conditions.map((condition) =>
        icd10CodeFromCondition(condition),
      );
    }
  }

  return [];
}

export function convertProgramConditionsToConditionList(
  programs: Maybe<Program[]>,
  patientStatus?: PatientStatus | PatientStatusEnum,
  isMultiprogramView = false,
): [Condition[], ICD10Code[]] {
  if (!programs?.length) {
    return [[], []];
  }
  const rpmPrograms = programs.filter(
    ({ program_type }) => program_type === ProgramType.RPM,
  );
  const enrolledRPM = rpmPrograms.find(
    ({ program_status }) => program_status === ProgramStatus.ENROLLED,
  );

  const disenrolledRPM = rpmPrograms.find(
    ({ program_status }) => program_status === ProgramStatus.DISENROLLED,
  );

  const selectedRPM = rpmPrograms.find(
    ({ program_status }) => program_status === ProgramStatus.SELECTED,
  );

  const eligibleRPM = rpmPrograms.find(
    ({ program_status }) => program_status === ProgramStatus.ELIGIBLE,
  );

  if (disenrolledRPM && isDisenrolled(patientStatus)) {
    return conditionsAndICD10sFromProgram(disenrolledRPM.conditions);
  }

  if (enrolledRPM && isEnrolled(patientStatus)) {
    return conditionsAndICD10sFromProgram(enrolledRPM.conditions);
  }

  if (
    (selectedRPM && isMultiprogramView) ||
    // In non-multiprogram programs view, we don't show seleced programs if patient is enrolled
    (selectedRPM && !isEnrolled(patientStatus))
  ) {
    return conditionsAndICD10sFromProgram(selectedRPM.conditions);
  }

  if (eligibleRPM && isMultiprogramView) {
    return conditionsAndICD10sFromProgram(eligibleRPM.conditions);
  }
  return [[], []];
}

export function getCurrentRpmProgram(programs: Maybe<Program[]>): Program {
  if (!programs?.length) {
    return {} as Program;
  }

  const rpmPrograms = programs.filter(
    ({ program_type }) => program_type === ProgramType.RPM,
  );
  const enrolledProgram = rpmPrograms.find(
    ({ program_status }) => program_status === ProgramStatus.ENROLLED,
  );
  if (enrolledProgram) {
    return enrolledProgram;
  }
  return {} as Program;
}
export function getCurrentDxCodesFromProgram(
  programs: Maybe<Program[]>,
  patientStatus?: PatientStatus | PatientStatusEnum,
): string[] {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [conditions, icd10Codes] = convertProgramConditionsToConditionList(
    programs,
    patientStatus,
  );
  return icd10Codes.map(
    (code: ICD10Code) =>
      code.category + (code.etiology ? `.${code.etiology}` : ''),
  );
}

export function getPreviousDxCodesFromProgram(
  programs: Maybe<Program[]>,
  rpmDxCorrectedDate: GoogleDate,
) {
  const rpmProgram = getCurrentRpmProgram(programs);
  const correctionDate = parseGrpcDate(rpmDxCorrectedDate);
  const isDelete = (pc: ProgramConditionHistory) => pc.operation === 'D';

  const isValidDate = (pc: ProgramConditionHistory, correctedDate: Date) =>
    pc.operation_time &&
    pc.operation_time.getUTCFullYear() === correctedDate.getUTCFullYear() &&
    pc.operation_time.getUTCMonth() === correctedDate.getUTCMonth() &&
    pc.operation_time.getUTCDate() === correctedDate.getUTCDate();
  const previousConditions = rpmProgram.condition_history?.filter(
    (pc) => isDelete(pc) && isValidDate(pc, correctionDate),
  );

  if (rpmProgram?.condition_history?.length === 1) {
    return { isValid: true, prevConditions: [] };
  }

  if (!previousConditions?.length) {
    return { isValid: false, prevConditions: [] };
  }

  const dedupedConditions = new Set(
    previousConditions?.map(
      (pc) => pc.category + (pc.etiology ? `.${pc.etiology}` : ''),
    ),
  );
  // dedupedConditions can still be empty if new order has the same icd-10 as the old order
  return { isValid: true, prevConditions: [...dedupedConditions] };
}

const conditionAbbreviationToConditionMap: Record<
  ProgramConditionAbbreviation,
  Condition
> = {
  [ProgramConditionAbbreviation.CHF]: Condition.CHF,
  [ProgramConditionAbbreviation.HTN]: Condition.Hypertension,
  [ProgramConditionAbbreviation.T2D]: Condition.TypeTwoDiabetes,
};

function conditionsAndICD10sFromProgram(
  programConditions?: ProgramCondition[] | ProgramConditionHistory[],
): [Condition[], ICD10Code[]] {
  if (!programConditions || programConditions?.length === 0) {
    return [[], []];
  }
  let preFilteredConditions: Condition[] = [];
  const icd10ByCondition: Map<Condition, ICD10Code[]> = new Map([
    [Condition.CHF, []],
    [Condition.Hypertension, []],
    [Condition.TypeTwoDiabetes, []],
  ]);

  programConditions.forEach((pc) => {
    if (pc.condition_type === grpcCondition.GENERIC) {
      const rawCondition = pc.category as ProgramConditionAbbreviation;
      const condition = conditionAbbreviationToConditionMap[rawCondition];
      preFilteredConditions.push(condition);
      addICD10ToCondition(icd10ByCondition, condition, pc);
    }
    if (pc.condition_type === grpcCondition.HEART_FAILURE) {
      preFilteredConditions.push(Condition.CHF);
      addICD10ToCondition(icd10ByCondition, Condition.CHF, pc);
    }
    if (pc.condition_type === grpcCondition.HYPERTENSION) {
      preFilteredConditions.push(Condition.Hypertension);
      addICD10ToCondition(icd10ByCondition, Condition.Hypertension, pc);
    }
    if (pc.condition_type === grpcCondition.TYPE_2_DIABETES) {
      preFilteredConditions.push(Condition.TypeTwoDiabetes);
      addICD10ToCondition(icd10ByCondition, Condition.TypeTwoDiabetes, pc);
    }
  });

  preFilteredConditions = Array.from(new Set(preFilteredConditions));

  if (preFilteredConditions.includes(Condition.CHF)) {
    return [[Condition.CHF], icd10ByCondition.get(Condition.CHF) || []];
  }
  return [
    preFilteredConditions,
    Array.from(
      new Set(([] as ICD10Code[]).concat(...icd10ByCondition.values())),
    ),
  ];
}

function addICD10ToCondition(
  icd10ByCondition: Map<Condition, ICD10Code[]>,
  conditionType: Condition,
  condition: ProgramCondition | ProgramConditionHistory,
) {
  if (
    !icd10ByCondition
      .get(conditionType)
      ?.find((icd10) => icd10.id === condition.id)
  ) {
    icd10ByCondition
      .get(conditionType)
      ?.push(icd10CodeFromCondition(condition));
  }
}

function icd10CodeFromCondition(
  condition: ProgramCondition | ProgramConditionHistory,
): ICD10Code {
  return {
    id: condition.id,
    category: condition.category || '',
    etiology: condition.etiology || '',
    description: condition.description || '',
    type:
      pbConditionTypeToConditionTypeMap[condition.condition_type?.valueOf()] ||
      '',
  };
}

export function generateICD10CodeOptionLabel(option: ICD10Code) {
  const { category, etiology, description } = option;

  // Prepend the '.' to the etiology if one exists
  //
  let etiologyText = '';
  if (etiology) {
    etiologyText = `.${etiology}`;
  }

  const optionLabel = `${category}${etiologyText} - ${description}`;

  return optionLabel;
}

const ABBREVIATION_MAP: ConditionAbbreviationMap = {
  [Condition.CHF]: ConditionAbbreviation.CHF,
  [Condition.Hypertension]: ConditionAbbreviation.HTN,
  [Condition.COPD]: ConditionAbbreviation.COPD,
  [Condition.TypeTwoDiabetes]: ConditionAbbreviation.T2D,
  // Conditions without abbreviations yet
  [Condition.AfibAndFlutter]: ConditionAbbreviation.ABBREVIATION_UNAVAILABLE,
  [Condition.Asthma]: ConditionAbbreviation.ABBREVIATION_UNAVAILABLE,
  [Condition.ChronicKidneyDisease]:
    ConditionAbbreviation.ABBREVIATION_UNAVAILABLE,
  [Condition.Hyperlipidemia]: ConditionAbbreviation.ABBREVIATION_UNAVAILABLE,
  [Condition.Hypothyroidism]: ConditionAbbreviation.ABBREVIATION_UNAVAILABLE,
  [Condition.IschemicHeartDisease]:
    ConditionAbbreviation.ABBREVIATION_UNAVAILABLE,
  [Condition.MorbidObesity]: ConditionAbbreviation.ABBREVIATION_UNAVAILABLE,
  [Condition.ObstructiveSleepApnea]:
    ConditionAbbreviation.ABBREVIATION_UNAVAILABLE,
  [Condition.Osteoarthritis]: ConditionAbbreviation.ABBREVIATION_UNAVAILABLE,
  [Condition.PeripheralArteryDisease]:
    ConditionAbbreviation.ABBREVIATION_UNAVAILABLE,
  [Condition.Generic]: ConditionAbbreviation.GENERIC,
  [Condition.Unspecified]: ConditionAbbreviation.ABBREVIATION_UNAVAILABLE,
  [Condition.Dementia]: ConditionAbbreviation.ABBREVIATION_UNAVAILABLE,
};

export function conditionToAbbreviation(
  condition: Condition,
): Nullable<ConditionAbbreviation | Condition> {
  const abbv = ABBREVIATION_MAP[condition];
  if (abbv === undefined) {
    return null;
  }
  if (abbv !== ConditionAbbreviation.ABBREVIATION_UNAVAILABLE) {
    return abbv;
  }
  logger.warn(`Abbreviation not yet available for condition: ${condition}`);
  return condition;
}

export function convertProgramResponseToConditions(
  ptConditionsPerProgram?: PbPatientConditionsPerProgram,
  status?: PatientStatusEnum,
  isMultiprogramView = false,
) {
  const programs = convertPtConditionPerProgramToPrograms(
    ptConditionsPerProgram,
  );

  return convertProgramConditionsToConditionList(
    programs,
    status,
    isMultiprogramView,
  );
}

export function convertSuggestedProgramResponseToConditions(
  ptConditionsPerProgram?: PbPatientConditionsPerProgram,
  status?: PatientStatusEnum,
) {
  const programs = convertPtConditionPerProgramToPrograms(
    ptConditionsPerProgram,
  );

  return convertSuggestedProgramConditionsToConditionList(programs, status);
}

export function convertCCMProgramResponseToConditions(
  ptConditionsPerProgram?: PbPatientConditionsPerProgram,
  status?: PatientStatusEnum,
  isMultiprogramView = false,
) {
  const programs = convertPtConditionPerProgramToPrograms(
    ptConditionsPerProgram,
  );

  return convertCCMProgramConditionsToConditionList(
    programs,
    status,
    isMultiprogramView,
  );
}

export function getCcmConditionI18n(
  intl: IntlShape,
): Record<CcmCondition, string> {
  return {
    [Condition.Asthma]: intl.formatMessage({ defaultMessage: 'Asthma' }),
    [Condition.AfibAndFlutter]: intl.formatMessage({
      defaultMessage: 'Atrial Fibrillation and Flutter',
    }),
    [Condition.ChronicKidneyDisease]: intl.formatMessage({
      defaultMessage: 'Chronic Kidney Disease',
    }),
    [Condition.COPD]: intl.formatMessage({
      defaultMessage: 'Chronic Obstructive Pulmonary Disease',
    }),
    [Condition.Dementia]: intl.formatMessage({ defaultMessage: 'Dementia' }),
    [Condition.TypeTwoDiabetes]: intl.formatMessage({
      defaultMessage: 'Type 2 Diabetes',
    }),
    [Condition.CHF]: intl.formatMessage({
      defaultMessage: 'Heart Failure',
    }),
    [Condition.Hyperlipidemia]: intl.formatMessage({
      defaultMessage: 'Hyperlipidemia',
    }),
    [Condition.Hypertension]: intl.formatMessage({
      defaultMessage: 'Hypertension',
    }),
    [Condition.Hypothyroidism]: intl.formatMessage({
      defaultMessage: 'Hypothyroidism',
    }),
    [Condition.IschemicHeartDisease]: intl.formatMessage({
      defaultMessage: 'Ischemic Heart Disease',
    }),
    [Condition.MorbidObesity]: intl.formatMessage({
      defaultMessage: 'Morbid Obesity',
    }),
    [Condition.ObstructiveSleepApnea]: intl.formatMessage({
      defaultMessage: 'Obstructive Sleep Apnea',
    }),
    [Condition.Osteoarthritis]: intl.formatMessage({
      defaultMessage: 'Osteoarthritis',
    }),
    [Condition.PeripheralArteryDisease]: intl.formatMessage({
      defaultMessage: 'Peripheral Arterial Disease',
    }),
  };
}

export function useCcmConditionOptions(): {
  label: string;
  value: CcmCondition;
}[] {
  const intl = useIntl();
  const conditionI18n = getCcmConditionI18n(intl);

  return sortBy(
    [
      {
        label: conditionI18n[Condition.CHF],
        value: Condition.CHF,
      },
      {
        label: conditionI18n[Condition.Hypertension],
        value: Condition.Hypertension,
      },
      {
        label: conditionI18n[Condition.TypeTwoDiabetes],
        value: Condition.TypeTwoDiabetes,
      },
      {
        label: conditionI18n[Condition.COPD],
        value: Condition.COPD,
      },
      {
        label: conditionI18n[Condition.Asthma],
        value: Condition.Asthma,
      },
      {
        label: conditionI18n[Condition.AfibAndFlutter],
        value: Condition.AfibAndFlutter,
      },
      {
        label: conditionI18n[Condition.ChronicKidneyDisease],
        value: Condition.ChronicKidneyDisease,
      },
      {
        label: conditionI18n[Condition.Hyperlipidemia],
        value: Condition.Hyperlipidemia,
      },
      {
        label: conditionI18n[Condition.Hypothyroidism],
        value: Condition.Hypothyroidism,
      },
      {
        label: conditionI18n[Condition.IschemicHeartDisease],
        value: Condition.IschemicHeartDisease,
      },
      {
        label: conditionI18n[Condition.MorbidObesity],
        value: Condition.MorbidObesity,
      },
      {
        label: conditionI18n[Condition.ObstructiveSleepApnea],
        value: Condition.ObstructiveSleepApnea,
      },
      {
        label: conditionI18n[Condition.Osteoarthritis],
        value: Condition.Osteoarthritis,
      },
      {
        label: conditionI18n[Condition.PeripheralArteryDisease],
        value: Condition.PeripheralArteryDisease,
      },
      // TODO: Uncomment this once Dementia is a Cadence Supported CCM Condition
      // Will also need to ensure ICD-10 Codes are added for the Admin section
      // {
      //   label: conditionI18n[Condition.Dementia],
      //   value: Condition.Dementia,
      // },
    ],
    'label',
  );
}
