import type { AxiosResponse } from 'axios';
import { subYears } from 'date-fns';
import omit from 'lodash/omit';
import { useIntl } from 'react-intl';
import type { QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { logger } from '@/logger';
import { ToolboxService } from '@/shared/generated/api/pms';
import type {
  ListPatientAllergiesResponse,
  PatientConditionsFromProblemListResponse,
} from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import {
  type BestThreeConditionsResponse,
  type DiagnosisCodeCondition,
  type ListAllPatientsPreviewsRequest,
  type ListAllPatientsPreviewsResponse,
  type ListPatientDiagnosisCodeConditionsResponse,
  PatientService,
  PatientStatusEnum,
  type PatientDetails as PbPatientDetails,
  Condition as grpcCondition,
} from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import type { EhrSyncTaskRequestScope } from '@/shared/generated/grpc/go/pms/pkg/patient/synchronization/synchronization.pb';
import { SynchronizationService } from '@/shared/generated/grpc/go/pms/pkg/patient/synchronization/synchronization.pb';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import type { AlertStatus, VitalsAlert } from '@/shared/types/alert.types';
import { Condition } from '@/shared/types/clinicalprofile.types';
import type { PaginatedData } from '@/shared/types/pagination.types';
import type {
  Patient,
  PatientDetails,
  PatientStatus,
} from '@/shared/types/patient.types';
import { idToGrpcName } from '@/shared/utils/grpc';
import Session from '@/shared/utils/session';
import { formatISOUriDate } from '@/shared/utils/time-helpers';
import { isValidUuid } from '@/shared/utils/uuid.utils';

import { useInvalidateProspectivePatients } from './prospective-patients.queries';

type PatientListParams = {
  statuses?: PatientStatus[];
  searchTerm?: string;
  source?: string;
  fullName?: string;
  dobFrom?: string;
  dobTo?: string;
};

const BASE_URL = '/pms/api/v1/patients';
export const PATIENT_QUERY_KEY_BASE = ['pms', 'api', 'v1', 'patients'] as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const patientKeys: Record<string, (...args: any[]) => QueryKey> = {
  detail: (patientId: string) =>
    [...PATIENT_QUERY_KEY_BASE, patientId] as const,
  list: (page: number, pageSize: number, params?: PatientListParams) =>
    [
      ...PATIENT_QUERY_KEY_BASE,
      {
        sort_by: 'created_at',
        order_by: 'desc',
        page_size: pageSize,
        page,
        ...(params?.statuses && { status: params?.statuses }),
        ...(params?.searchTerm &&
          (isValidUuid(params.searchTerm.trim())
            ? { id: params.searchTerm.trim() }
            : { fnormrn: params.searchTerm })),
        ...(params?.source && { source: params?.source }),
        ...(params?.fullName && { full_name: params.fullName }),
        ...(params?.dobFrom && { dob_from: params.dobFrom }),
        ...(params?.dobTo && { dob_to: params.dobTo }),
      },
    ] as const,
  search: (searchTerm: string, source?: string) =>
    patientKeys.list(1, 100, { searchTerm, source }),
  vitalsAlerts: (params: PatientVitalsAlertsParams) => [
    ...patientKeys.detail(params.patient_id),
    'vitals-alerts',
    omit(params, 'patient_id'),
  ],
  bestThreeChronicConditions: (patientId: string) =>
    [...PATIENT_QUERY_KEY_BASE, patientId, 'threeChronicConditions'] as const,
  conditionsFromProblemList: (patientId: string) =>
    [...PATIENT_QUERY_KEY_BASE, patientId, 'problemListConditions'] as const,
  allergies: (patientId: string) =>
    [...PATIENT_QUERY_KEY_BASE, patientId, 'allergies'] as const,
};

export function useInvalidatePatients() {
  const queryClient = useQueryClient();
  return async () => queryClient.invalidateQueries(PATIENT_QUERY_KEY_BASE);
}

/**
 * @deprecated in favor of overloaded usePatientDetails, which will start using GRPC endpoint.
 */
function usePatientDetailsOld(
  patientId: string,
  config: UseQueryOptions<Patient> = {},
) {
  return useQuery<Patient>(patientKeys.detail(patientId), {
    ...config,
    enabled: config?.enabled ?? true,
  });
}

export const GRPC_PATIENT_QUERY_KEY_BASE = ['rpm', 'v1', 'patients'];
export const patientDetailsQueryKeys = {
  detail: (patientId: string) => [
    ...GRPC_PATIENT_QUERY_KEY_BASE,
    patientId,
    'patientDetails',
  ],
  diagnosisCodes: (patientId: string) => [
    ...GRPC_PATIENT_QUERY_KEY_BASE,
    patientId,
    'patientDiagnosisCodeConditions',
  ],
};

export enum ProgramConditionAbbreviation {
  CHF = 'CHF',
  HTN = 'HTN',
  T2D = 'T2D',
}

export const patientRpmConditionMap: Record<grpcCondition, Condition> = {
  [grpcCondition.HEART_FAILURE]: Condition.CHF,
  [grpcCondition.HYPERTENSION]: Condition.Hypertension,
  [grpcCondition.TYPE_2_DIABETES]: Condition.TypeTwoDiabetes,
  [grpcCondition.GENERIC]: Condition.Generic,
  [grpcCondition.CONDITION_UNSPECIFIED]: Condition.Unspecified,
  [grpcCondition.COPD]: Condition.COPD,
  [grpcCondition.ASTHMA]: Condition.Asthma,
  [grpcCondition.AFIB_AND_AFL]: Condition.AfibAndFlutter,
  [grpcCondition.CHRONIC_KIDNEY_DISEASE]: Condition.ChronicKidneyDisease,
  [grpcCondition.HYPERLIPIDEMIA]: Condition.Hyperlipidemia,
  [grpcCondition.HYPOTHYROIDISM]: Condition.Hypothyroidism,
  [grpcCondition.ISCHEMIC_HEART_DISEASE]: Condition.IschemicHeartDisease,
  [grpcCondition.MORBID_OBESITY]: Condition.MorbidObesity,
  [grpcCondition.OBSTRUCTIVE_SLEEP_APNEA]: Condition.ObstructiveSleepApnea,
  [grpcCondition.OSTEOARTHRITIS]: Condition.Osteoarthritis,
  [grpcCondition.PERIPHERAL_ARTERY_DISEASE]: Condition.PeripheralArteryDisease,
};

export function usePatientDiagnosisCodeConditions(
  patientId: string,
  config: UseQueryOptions<PaginatedData<DiagnosisCodeCondition>> = {},
) {
  return useQuery<ListPatientDiagnosisCodeConditionsResponse>(
    patientDetailsQueryKeys.diagnosisCodes(patientId),
    () =>
      PatientService.ListPatientDiagnosisCodeConditions({
        parent: patientId,
      }),
    {
      enabled: config?.enabled ?? true,
      select: ({ diagnosisCodeConditions, ...rest }) => {
        const formatted =
          diagnosisCodeConditions?.map(
            ({ name, category, etiology, conditionType, description }) => ({
              id: Number(name),
              category,
              etiology,
              type: conditionType
                ? patientRpmConditionMap[conditionType]
                : undefined,
              description,
            }),
          ) || [];
        return {
          ...rest,
          diagnosisCodeConditions: formatted,
        };
      },
    },
  );
}

function usePatientDetailsV2(
  patientId: string,
  config: UseQueryOptions<PbPatientDetails> = {},
) {
  return useQuery<PbPatientDetails>(
    patientDetailsQueryKeys.detail(patientId),
    () =>
      PatientService.GetPatientDetails({
        name: idToGrpcName('patients', patientId, 'patientDetails'),
      }),
    {
      ...config,
      enabled: config?.enabled ?? true,
    },
  );
}

export function usePatientDetails(
  patientId: string,
  grpcReady: true,
  enabled?: boolean,
  onSuccess?: (data: PbPatientDetails) => void,
  onError?: (err: unknown) => void,
): UseQueryResult<PbPatientDetails>;

/**
 * @deprecated use gRPC version of usePatientDetails
 */
export function usePatientDetails(
  patientId: string,
  grpcReady: false,
  enabled?: boolean,
  onSuccess?: (data: Patient) => void,
  onError?: (err: unknown) => void,
): UseQueryResult<Patient>;

export function usePatientDetails(
  patientId: string,
  grpcReady: boolean,
  enabled: boolean = true,
  onSuccess:
    | ((data: Patient) => void)
    | ((data: PbPatientDetails) => void) = () => {},
  onError: (err: unknown) => void = () => {},
): UseQueryResult<PbPatientDetails> | UseQueryResult<Patient> {
  const v2PatientDetails = usePatientDetailsV2(patientId, {
    enabled: (enabled ?? true) && Boolean(patientId) && grpcReady,
    onSuccess: grpcReady
      ? (onSuccess as (data: PbPatientDetails) => void)
      : undefined,
    onError: grpcReady ? onError : undefined,
  });
  const oldPatientDetails = usePatientDetailsOld(patientId, {
    enabled: (enabled ?? true) && Boolean(patientId) && !grpcReady,
    onSuccess: !grpcReady ? (onSuccess as (data: Patient) => void) : undefined,
    onError: !grpcReady ? onError : undefined,
  });

  return grpcReady ? v2PatientDetails : oldPatientDetails;
}

/**
 * @deprecated
 */
export function useRouteParamPatientDetails(
  config: UseQueryOptions<Patient> = {},
) {
  const { patientId } = useParams<{ patientId: string }>();
  return usePatientDetails(
    patientId,
    false,
    !!patientId,
    config.onSuccess,
    config.onError,
  );
}

export function useRouteParamPatientDetailsGrpc(
  config: UseQueryOptions<PbPatientDetails> = {},
) {
  const { patientId } = useParams<{ patientId: string }>();
  return usePatientDetails(
    patientId,
    true,
    !!patientId,
    config.onSuccess,
    config.onError,
  );
}

type UpdatePatientContext = {
  previousPatient?: Maybe<PatientDetails>;
};

const noop = () => {};

export function useUpdatePatient(
  patientId: string,
  onSuccess: (updatedPatient: PatientDetails) => void = noop,
) {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const { toaster } = useToaster();
  return useMutation<
    AxiosResponse<PatientDetails>,
    Error,
    PatientDetails,
    UpdatePatientContext
  >(
    (patientToUpdate) =>
      Session.Api.put<PatientDetails>(
        `${BASE_URL}/${patientId}`,
        patientToUpdate,
      ),
    {
      onMutate: (updatedPatient) => {
        const previousPatient = queryClient.getQueryData<PatientDetails>([
          ...PATIENT_QUERY_KEY_BASE,
          patientId,
        ]);

        if (!previousPatient) {
          return {};
        }

        queryClient.setQueryData(patientKeys.detail(patientId), updatedPatient);

        return { previousPatient };
      },
      onSuccess: (updatedPatient, previousPatient) => {
        /*
        Instead of refetching patient query for that patientId and
        wasting a network call for data we already have, we can take
        advantage of the object returned by the mutation function and
        update the existing query with the new data immediately
        */
        queryClient.setQueryData(patientKeys.detail(patientId), {
          ...updatedPatient.data,
          // This is a temporary solution until API returns this property on PATCH and PUT operation
          feature_flags: previousPatient.feature_flags,
        });
        onSuccess(updatedPatient.data);
      },
      onError: (error, updatedPatient, context) => {
        queryClient.setQueryData(
          patientKeys.detail(patientId),
          context?.previousPatient,
        );

        logger.error(
          `Failed to update a patient with id: ${patientId}. Error message: ${error.message}`,
        );
        toaster.error(
          intl.formatMessage({
            defaultMessage: 'Failed to update patient',
          }),
        );
      },
    },
  );
}

type Callbacks<D = unknown, E = unknown> = {
  onSuccess?: (data?: D) => void;
  onError?: (err?: E) => void;
};
export const toolboxPatientKeys = {
  orders: (patientId: string) =>
    [...PATIENT_QUERY_KEY_BASE, patientId, 'orders'] as const,
};
export function useReprocessOnePatientOrders(
  patientId: string,
  callbacks?: Callbacks<unknown>,
) {
  const invalidateProspectivePatients = useInvalidateProspectivePatients();
  const invalidatePatients = useInvalidatePatients();
  return useMutation(
    toolboxPatientKeys.orders(patientId),
    () => ToolboxService.postPmsApiV1ToolboxPatientOrders(patientId),
    {
      onSuccess: async (status) => {
        await invalidateProspectivePatients();
        await invalidatePatients();
        callbacks?.onSuccess?.(status);
      },
      onError: callbacks?.onError,
    },
  );
}

export function usePatchPatient(
  patientId: string,
  onSuccess: (updated: PatientDetails) => void = noop,
) {
  const intl = useIntl();
  const { toaster } = useToaster();
  const invalidateProspectivePatients = useInvalidateProspectivePatients();
  const invalidatePatients = useInvalidatePatients();
  return useMutation<
    AxiosResponse<PatientDetails>,
    Error,
    Partial<PatientDetails>
  >(
    (patientFieldsToPatch) =>
      Session.Api.patch<PatientDetails>(
        `${BASE_URL}/${patientId}`,
        patientFieldsToPatch,
      ),
    {
      onSuccess: async ({ data }) => {
        await invalidatePatients();
        await invalidateProspectivePatients();
        onSuccess?.(data);
      },
      onError: (error) => {
        logger.error(
          `Failed to patch a patient with id: ${patientId}. Error message: ${error.message}`,
        );
        toaster.error(
          intl.formatMessage({
            defaultMessage: 'Failed to patch a patient.',
          }),
        );
      },
    },
  );
}

export function usePaginatedPatientList(
  page = 1,
  pageSize = 25,
  params?: PatientListParams,
  options = { enabled: true },
) {
  return useQuery<PaginatedData<PatientDetails>>(
    patientKeys.list(page, pageSize, params),
    { ...options, keepPreviousData: true },
  );
}

// source is not used by the backend, it's a bit of a hack to allow us to invalidate queries
// with a little more granularity. use case at the time of implementation is clearing the
// search results from the patient search typeahead but _not_ from the patient search results
// page. because they both use the same query, we need to differentiate somehow in order to
// only clear the query we are interested in.
export type PatientSearchConfig = {
  statuses?: PatientStatus[];
};

/**
 * @deprecated in favor of grpc endpoint. at the time of this writing we are still using
 * this in shared/common/@deprecated/PatientSearch, which is used in the non-admin side of
 * the app and is very resistant to type changes
 */
export function useDeprecatedPatientSearch(
  searchTerm: string,
  enabled: boolean,
  config: PatientSearchConfig = {},
  source?: string,
) {
  return usePaginatedPatientList(
    1,
    100,
    {
      searchTerm,
      source,
      ...config,
    },
    { enabled },
  );
}

function fnormrnOrIdFilter(fullNameOrMrnOrId: string) {
  if (isValidUuid(fullNameOrMrnOrId)) {
    return `id="${fullNameOrMrnOrId}"`;
  }

  // jason says we only need to worry about stripping % and that
  // no other special characters should cause problems. we'll see (:
  // https://cadencerpm.atlassian.net/browse/PLAT-4274 "sanitized" lol
  const sanitized = `%${fullNameOrMrnOrId.replace(/%/g, '')}%`;
  const quoted = sanitized.includes('"') ? `'${sanitized}'` : `"${sanitized}"`;
  return `(fullName ILIKE ${quoted} OR mrn ILIKE ${quoted})`;
}

function statusFilter(statuses: PatientStatus[]) {
  const filters = statuses.map((status) => `status = "${status}"`).join(' OR ');

  return `(${filters})`;
}

export function usePatientSearch(
  searchTerm: string,
  enabled: boolean,
  config: PatientSearchConfig = {},
  source?: string,
) {
  let filter = fnormrnOrIdFilter(searchTerm.trim());

  if (config.statuses) {
    filter = `${filter} AND ${statusFilter(config.statuses)}`;
  }

  return useAllPatients(
    {
      pageSize: 100,
      orderBy: 'createTime desc',
      filter,
    },
    { enabled },
    patientKeys.search(searchTerm, source),
  );
}

export function useResetSearch() {
  const queryClient = useQueryClient();

  return (searchTerm: string, source?: string) =>
    queryClient.resetQueries(patientKeys.search(searchTerm, source));
}

export function useAllPatients(
  request: ListAllPatientsPreviewsRequest,
  options: UseQueryOptions<ListAllPatientsPreviewsResponse> = {},
  key?: QueryKey,
) {
  const queryKey = key ?? ['grpc all patients', { ...request }];

  return useQuery(
    queryKey,
    () => PatientService.ListAllPatientsPreviews(request),
    {
      ...options,
      // Don't refetch on focus as this is an expensive API call
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );
}

export function useWasPatientEnrolled(patientId: string) {
  const { data: patientDetails } = usePatientDetails(patientId, true);

  return (
    patientDetails?.patient?.status &&
    [PatientStatusEnum.ENROLLED, PatientStatusEnum.DISENROLLED].includes(
      patientDetails.patient.status,
    )
  );
}

type PatientVitalsAlertsParams = {
  patient_id: string;
  date_to: string;
  date_from: string;
  status?: AlertStatus[];
  sort_by?: keyof VitalsAlert;
  order_by?: 'asc' | 'desc';
};

export function usePatientVitalsAlerts(
  params: PatientVitalsAlertsParams,
  options?: UseQueryOptions<PaginatedData<VitalsAlert>>,
) {
  return useQuery<PaginatedData<VitalsAlert>>(
    patientKeys.vitalsAlerts(params),
    options,
  );
}

export function usePatientVitalsAlertsByStatus(
  {
    patientId,
    includeStatuses,
  }: { patientId: string; includeStatuses: AlertStatus[] },
  options?: Parameters<typeof usePatientVitalsAlerts>[1],
) {
  return usePatientVitalsAlerts(
    {
      patient_id: patientId,
      // As range is required, to get all patient alerts we're passing very wide range here
      date_from: formatISOUriDate(subYears(new Date(), 1)),
      date_to: formatISOUriDate(new Date()),
      status: includeStatuses,
      sort_by: 'created_at',
      order_by: 'desc',
    },
    options,
  );
}

export function useSynchronizePatientV2(
  name: string,
  callbacks?: Callbacks<void>,
) {
  const queryClient = useQueryClient();

  return useMutation(
    (scopes: EhrSyncTaskRequestScope[]) =>
      SynchronizationService.SynchronizePatient({ name, scopes }),
    {
      onSuccess: async () => {
        callbacks?.onSuccess?.();
        await queryClient.invalidateQueries(patientKeys.detail(name));
      },
      onError: callbacks?.onError,
    },
  );
}

export function usePatientBestThreeConditionsFromProblemList(
  id: string,
  options: UseQueryOptions<BestThreeConditionsResponse> = {},
) {
  return useQuery(
    patientKeys.bestThreeChronicConditions(id) as QueryKey,
    () =>
      PatientService.GetPatientBestThreeConditionsFromProblemList({ name: id }),
    options,
  );
}

export function usePatientConditionsFromProblemList(
  id: string,
  options: UseQueryOptions<PatientConditionsFromProblemListResponse> = {},
) {
  return useQuery(
    patientKeys.conditionsFromProblemList(id) as QueryKey,
    () => PatientService.GetPatientConditionsFromProblemList({ name: id }),
    options,
  );
}

export function usePatientAllergies(
  patientId: string,
  options: UseQueryOptions<ListPatientAllergiesResponse> = {},
) {
  return useQuery(
    patientKeys.allergies(patientId),
    () => PatientService.ListPatientAllergies({ parent: patientId }),
    {
      ...options,
      enabled: options?.enabled !== false && !!patientId,
    },
  );
}

export function usePatientMedicationAllergies(
  patientId: string,
  options: UseQueryOptions<ListPatientAllergiesResponse> = {},
) {
  const { isLoading, data } = usePatientAllergies(patientId, options);
  const medAllergies = data?.allergies
    ?.filter(
      (allergy) =>
        !allergy.categories?.length ||
        allergy.categories?.includes('MEDICATION'),
    )
    .sort(({ allergen: a = '' }, { allergen: b = '' }) => a.localeCompare(b));

  return {
    isLoading,
    medAllergies,
  };
}
