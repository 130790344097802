import last from 'lodash/last';
import { FormattedMessage, useIntl } from 'react-intl';

import { newMedActionsContainer } from '@/components/AsyncTitration/EmptyNewMedSuggestion.css';
import { FormlessPatientTitrationRejectionReason } from '@/components/AsyncTitration/PatientTitrationRejectionReason';
import { UndoAction } from '@/components/AsyncTitration/SuggestedTitration/Actions';
import { InitiallyReviewedActions } from '@/components/AsyncTitration/SuggestedTitration/Actions/InitiallyReviewedActions';
import { MedicationDetails } from '@/components/AsyncTitration/SuggestedTitration/MedicationDetails';
import { notTitrating } from '@/components/AsyncTitration/SuggestedTitration/SuggestedTitration.css';
import { SuggestedTitrationMode } from '@/components/AsyncTitration/SuggestedTitration/types';
import { useTitrationRecommendation } from '@/components/AsyncTitration/hooks';
import { MedInfo } from '@/pages/patients/PatientMedications/MedInfo';
import { StatusIcon } from '@/pages/patients/PatientMedications/MedicationRow';
import { medRowTitle } from '@/pages/patients/PatientMedications/MedicationRow.css';
import {
  actions,
  container,
  medRow,
  patientDecisionContainer,
  tag,
} from '@/pages/patients/PatientMedications/NewMedSuggestionRow.css';
import { usePatientMedicationsContext } from '@/pages/patients/PatientMedications/PatientMedicationsContext';
import { MedPermissions } from '@/pages/patients/PatientMedications/PatientMedicationsList';
import { useReferenceMedication } from '@/pages/patients/PatientMedications/referenceMedications.queries';
import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { NoteStatus } from '@/shared/generated/grpc/go/pms/pkg/note/note.pb';
import {
  AsyncTitrationAsyncTitrationStatus,
  AsyncTitrationAsyncTitrationType,
  type AsyncTitrationPatientRejectedTitrationReason,
  MedicationChangeStatus,
  type NewMedSuggestion,
} from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { useIsCnExperience } from '@/shared/hooks';
import {
  useRevertAsyncTitrationForNote,
  useUpdateAsyncTitration,
} from '@/shared/hooks/queries/medication.queries';
import { Tag } from '@/shared/tempo/atom/Tag';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { getGrpcErrorMessage } from '@/shared/utils/helpers';

type Props = {
  newMedSuggestion: NewMedSuggestion;
  typeOfEncounter?: TypeOfEncounter;
  medPermissions?: MedPermissions;
};

export function NewMedSuggestionRow({
  newMedSuggestion,
  typeOfEncounter,
  medPermissions,
}: Props) {
  const intl = useIntl();
  const { toaster } = useToaster();
  const latestTitration = last(newMedSuggestion.asyncTitrations);
  const { noteId, hideSuggestions } = usePatientMedicationsContext();
  const { data: referenceMedication, isLoading: isLoadingRefMedLoading } =
    useReferenceMedication(
      newMedSuggestion.referenceMedicationId || '',
      !!newMedSuggestion.referenceMedicationId,
    );
  const titrationRecommendation = useTitrationRecommendation(
    { asyncTitration: latestTitration },
    newMedSuggestion.referenceMedicationId || '',
  );
  const updateAsyncTitration = useUpdateAsyncTitration(
    latestTitration?.inputPatientId ?? '',
    latestTitration?.id ?? '',
  );
  const revertAsyncTitrationForNote = useRevertAsyncTitrationForNote(
    titrationRecommendation.patientId ?? '',
  );
  const { isCnExperience, isLoading: isLoadingCnExperienceCheck } =
    useIsCnExperience();
  const isLoading = isLoadingRefMedLoading || isLoadingCnExperienceCheck;
  if (!latestTitration || isLoading || !referenceMedication?.rxNorms) {
    return null;
  }

  const rxnorm = referenceMedication.rxNorms.find(
    (r) => r.rxcui === latestTitration.editedRxcui,
  );
  if (!rxnorm) {
    return null;
  }

  const isSaving = updateAsyncTitration.isLoading;
  const isApprovalNote = latestTitration.initialReviewNoteId === noteId;
  const isTitrationOutreach =
    typeOfEncounter === TypeOfEncounter.TITRATION_OUTREACH ||
    (typeOfEncounter === TypeOfEncounter.CN_TITRATION_OUTREACH &&
      medPermissions === MedPermissions.ProactiveTitrationConsent);
  const isInitiallyReviewedInThisNote =
    typeOfEncounter &&
    latestTitration.initialReviewNoteId === noteId &&
    latestTitration.status ===
      AsyncTitrationAsyncTitrationStatus.INITIALLY_REVIEWED;
  const isRejectedByPatientInThisNote =
    typeOfEncounter &&
    latestTitration.consentRequestNoteId === noteId &&
    latestTitration.status ===
      AsyncTitrationAsyncTitrationStatus.PATIENT_REJECTED;
  const isConsentedToByPatientInThisNote =
    typeOfEncounter &&
    latestTitration.consentRequestNoteId === noteId &&
    latestTitration.status ===
      AsyncTitrationAsyncTitrationStatus.PATIENT_CONSENTED;

  const shouldShow =
    !hideSuggestions &&
    (isInitiallyReviewedInThisNote ||
      isRejectedByPatientInThisNote ||
      isConsentedToByPatientInThisNote ||
      (latestTitration.status ===
        AsyncTitrationAsyncTitrationStatus.INITIALLY_REVIEWED &&
        latestTitration.initialReviewNoteStatus === NoteStatus.PUBLISHED)) &&
    (!noteId ||
      !isCnExperience ||
      typeOfEncounter === TypeOfEncounter.CN_TITRATION_OUTREACH);
  if (!shouldShow) {
    return null;
  }

  function onUndo() {
    revertAsyncTitrationForNote.mutate(
      {
        noteId: noteId ?? undefined,
        asyncTitrationId: titrationRecommendation.id,
      },
      {
        onError: (err) => {
          toaster.error(
            intl.formatMessage(
              {
                defaultMessage: `Failed to undo titration decision: {message}`,
              },
              { message: getGrpcErrorMessage(err) },
            ),
          );
        },
      },
    );
  }

  function onPatientConsented() {
    updateAsyncTitration.mutate(
      {
        status: AsyncTitrationAsyncTitrationStatus.PATIENT_CONSENTED,
        consentRequestNoteId: noteId ?? 0,
      },
      {
        onError: (err) => {
          toaster.error(
            intl.formatMessage(
              {
                defaultMessage: `Failed to update titration decision: {message}`,
              },
              { message: getGrpcErrorMessage(err) },
            ),
          );
        },
      },
    );
  }

  function onPatientRejected(
    reason?: AsyncTitrationPatientRejectedTitrationReason,
  ) {
    updateAsyncTitration.mutate(
      {
        status: AsyncTitrationAsyncTitrationStatus.PATIENT_REJECTED,
        consentRequestNoteId: noteId ?? 0,
        ...(reason ? { patientRejectedTitrationReason: reason } : {}),
      },
      {
        onError: (err) => {
          toaster.error(
            intl.formatMessage(
              {
                defaultMessage: `Failed to update titration decision: {message}`,
              },
              { message: getGrpcErrorMessage(err) },
            ),
          );
        },
      },
    );
  }

  return (
    <div className={container}>
      <div className={medRow}>
        <Tag variant="unstyled" className={tag}>
          <FormattedMessage defaultMessage="New med pending" />
        </Tag>
        <div className={medRowTitle}>
          <StatusIcon status={MedicationChangeStatus.ACTIVE} />
          <MedInfo
            medChange={{
              rxnorm,
              doseQuantities: latestTitration.editedDoseQuantities,
              frequencies: latestTitration.editedFrequencies,
            }}
            referenceMed={newMedSuggestion.referenceMedication}
          />
        </div>
      </div>
      {typeOfEncounter === TypeOfEncounter.ASYNC_REVIEW && isApprovalNote && (
        <div className={actions}>
          <UndoAction
            variant="accepted"
            onUndo={() => onUndo()}
            readOnly={false}
            isProcessing={isSaving}
            acceptedHeader={
              <FormattedMessage defaultMessage="New med captured" />
            }
            acceptedBody={
              <FormattedMessage defaultMessage="The patient will be notified about the med suggestion." />
            }
          />
        </div>
      )}
      {isTitrationOutreach && (
        <>
          {latestTitration.status ===
            AsyncTitrationAsyncTitrationStatus.INITIALLY_REVIEWED && (
            <div className={patientDecisionContainer}>
              {referenceMedication && (
                <MedicationDetails
                  referenceMed={referenceMedication}
                  medChange={{ rxnorm }}
                  recommendation={titrationRecommendation}
                  forCnEncounter={
                    typeOfEncounter === TypeOfEncounter.CN_TITRATION_OUTREACH
                  }
                />
              )}
              <InitiallyReviewedActions
                mode={SuggestedTitrationMode.Default}
                isDisabled={isSaving}
                onAccept={onPatientConsented}
                onReject={() => onPatientRejected()}
                asyncTitrationType={AsyncTitrationAsyncTitrationType.NEW_MED}
              />
            </div>
          )}
          {isRejectedByPatientInThisNote && (
            <>
              <div className={newMedActionsContainer}>
                <UndoAction
                  variant="rejected"
                  onUndo={() => onUndo()}
                  readOnly={false}
                  isProcessing={isSaving}
                  rejectedMessage={
                    <FormattedMessage defaultMessage="New medication rejected by the patient" />
                  }
                />
              </div>
              <div className={notTitrating.container}>
                <FormlessPatientTitrationRejectionReason
                  onChange={(reason) => onPatientRejected(reason)}
                />
              </div>
            </>
          )}
          {isConsentedToByPatientInThisNote && (
            <div className={newMedActionsContainer}>
              <UndoAction
                variant="accepted"
                onUndo={() => onUndo()}
                readOnly={false}
                isProcessing={isSaving}
                acceptedBody={
                  <FormattedMessage defaultMessage="New med consented to by the patient" />
                }
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
