import { useIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';

import { getCcmConditionI18n } from '@/pages/adminPanel/patient-profile/tabs/ClinicalProfileTab/clinicalprofile.utils';
import { lastChange } from '@/pages/patients/PatientMedications/utils/medChangeUtils';
import {
  CCMPrognosis,
  GoalsAndInterventionsActionStep,
  GoalsAndInterventionsHealthGoal,
} from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import {
  GoalCategory,
  GoalStatus,
} from '@/shared/generated/grpc/go/pms/pkg/ccm_goal/ccm_goal.pb';
import { MedicationChangeStatus } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import type { PatientMedications } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { convertCcmConditionToPmsCondition } from '@/shared/patient/conditions.utils';
import type { CcmCondition } from '@/shared/types/clinicalprofile.types';
import { Condition } from '@/shared/types/clinicalprofile.types';

import { GoalMeasure } from '../../../Goals/goals.types';
import type { FormFields } from '../../formConfig';

// Define type for conditions with auto-created goals
export type ConditionsWithRelatedGoals =
  | Condition.Hypertension
  | Condition.COPD
  | Condition.TypeTwoDiabetes
  | Condition.Asthma
  | Condition.AfibAndFlutter
  | Condition.ChronicKidneyDisease
  | Condition.Hyperlipidemia
  | Condition.Hypothyroidism
  | Condition.MorbidObesity
  | Condition.ObstructiveSleepApnea;

const RELATED_GOAL_MEASURE_MAP: Record<
  ConditionsWithRelatedGoals,
  GoalMeasure
> = {
  [Condition.Hypertension]: GoalMeasure.BloodPressure,
  [Condition.COPD]: GoalMeasure.OxygenSaturation,
  [Condition.TypeTwoDiabetes]: GoalMeasure.BloodSugar,
  [Condition.Asthma]: GoalMeasure.OxygenSaturation,
  [Condition.AfibAndFlutter]: GoalMeasure.HeartRate,
  [Condition.ChronicKidneyDisease]: GoalMeasure.FluidIntake,
  [Condition.Hyperlipidemia]: GoalMeasure.Cholesterol,
  [Condition.Hypothyroidism]: GoalMeasure.ThyroidHormone,
  [Condition.MorbidObesity]: GoalMeasure.Weight,
  [Condition.ObstructiveSleepApnea]: GoalMeasure.OxygenSaturation,
};

// Check if a condition has an auto-created goal
export function hasRelatedGoal(
  condition: CcmCondition,
): condition is ConditionsWithRelatedGoals {
  return condition in RELATED_GOAL_MEASURE_MAP;
}

export function getRelatedGoalMeasure(
  condition: CcmCondition,
): Maybe<GoalMeasure> {
  if (!hasRelatedGoal(condition)) {
    return null;
  }

  return RELATED_GOAL_MEASURE_MAP[condition];
}

// Get internationalized titles for auto-created goals
export function useAutoCreatedGoalTitles(): Record<
  ConditionsWithRelatedGoals,
  string
> {
  const intl = useIntl();
  return getAutoCreatedGoalTitles(intl);
}

export function getAutoCreatedGoalTitles(intl: IntlShape) {
  return {
    [Condition.Hypertension]: intl.formatMessage({
      defaultMessage: 'Achieve BP levels of < 130/80',
    }),
    [Condition.COPD]: intl.formatMessage({
      defaultMessage: 'Achieve SpO2 levels of > 88%',
    }),
    [Condition.TypeTwoDiabetes]: intl.formatMessage({
      defaultMessage: 'Achieve an A1C level of < 7%',
    }),
    [Condition.Asthma]: intl.formatMessage({
      defaultMessage: 'Achieve SpO2 levels of > 92%',
    }),
    [Condition.AfibAndFlutter]: intl.formatMessage({
      defaultMessage: 'Achieve HR levels of between 60 and 100 bpm',
    }),
    [Condition.ChronicKidneyDisease]: intl.formatMessage({
      defaultMessage: 'Achieve adequate hydration of 64 oz daily',
    }),
    [Condition.Hyperlipidemia]: intl.formatMessage({
      defaultMessage: 'Achieve TC levels of < 200 mg/dL',
    }),
    [Condition.Hypothyroidism]: intl.formatMessage({
      defaultMessage: 'Achieve TSH levels between 0.5 and 5 miU/L',
    }),
    [Condition.MorbidObesity]: intl.formatMessage({
      defaultMessage: 'Achieve 2 lb weight loss',
    }),
    [Condition.ObstructiveSleepApnea]: intl.formatMessage({
      defaultMessage: 'Achieve SpO2 levels of > 92%',
    }),
  };
}

// Create a related goal with the given title, measure, and description
export function constructRelatedGoal(
  title: string,
  measure: GoalMeasure,
  description: string,
) {
  return {
    title,
    measure,
    description,
    status: GoalStatus.IN_PROGRESS,
    category: GoalCategory.CONDITION_MANAGEMENT,
  };
}

export function buildDeclinedGoal(condition: CcmCondition, intl: IntlShape) {
  return {
    title: intl.formatMessage(
      { defaultMessage: 'Declined {condition} management' },
      { condition: getCcmConditionI18n(intl)[condition] },
    ),
    measure: GoalMeasure.ChronicConditions,
    description: intl.formatMessage({
      defaultMessage:
        'The patient decided to not pursue a goal related to this condition at this time',
    }),
    status: GoalStatus.DECLINED,
    category: GoalCategory.CONDITION_MANAGEMENT,
  };
}

export function getPatientGoalOptions(
  condition: CcmCondition,
  intl: IntlShape,
) {
  const goalOptions = {
    [Condition.Hypertension]: [
      {
        label: intl.formatMessage({
          defaultMessage: 'Maintain healthy BP levels of < 130/80',
        }),
        value: GoalsAndInterventionsHealthGoal.MAINTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.GOOD,
        actionSteps: [
          {
            label: intl.formatMessage({
              defaultMessage: 'Stay active',
            }),
            value: GoalsAndInterventionsActionStep.EXERCISE,
            selectByDefault: hasExerciseGap,
          },
          {
            label: intl.formatMessage({
              defaultMessage: 'Maintain heart healthy diet',
            }),
            value: GoalsAndInterventionsActionStep.DIET,
            selectByDefault: () => true,
          },
        ],
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Achieve BP levels of < 130/80',
        }),
        value: GoalsAndInterventionsHealthGoal.OBTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.FAIR,
        actionSteps: [
          {
            label: intl.formatMessage({
              defaultMessage: 'Increase activity',
            }),
            value: GoalsAndInterventionsActionStep.EXERCISE,
            selectByDefault: hasExerciseGap,
          },
          {
            label: intl.formatMessage({
              defaultMessage: 'Achieve a heart healthy diet',
            }),
            value: GoalsAndInterventionsActionStep.DIET,
          },
          {
            label: intl.formatMessage({
              defaultMessage: 'Limit sodium',
            }),
            value: GoalsAndInterventionsActionStep.REDUCE_SODIUM,
            selectByDefault: (formFields: FormFields) =>
              formFields.dietNotes?.includes('adds salt'),
          },
          {
            label: intl.formatMessage({
              defaultMessage: 'Take medications as prescribed',
            }),
            value: GoalsAndInterventionsActionStep.TAKE_MEDICATION,
            selectMedsByDefault: (meds: PatientMedications) =>
              hasConditionMed(meds, condition),
          },
        ],
      },
    ],
    [Condition.Hyperlipidemia]: [
      {
        label: intl.formatMessage({
          defaultMessage: 'Maintain healthy TC levels of < 200 mg/dL',
        }),
        value: GoalsAndInterventionsHealthGoal.MAINTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.GOOD,
        actionSteps: [
          {
            label: intl.formatMessage({
              defaultMessage: 'Stay active',
            }),
            value: GoalsAndInterventionsActionStep.EXERCISE,
            selectByDefault: hasExerciseGap,
          },
          {
            label: intl.formatMessage({
              defaultMessage: 'Maintain heart healthy diet',
            }),
            value: GoalsAndInterventionsActionStep.DIET,
            selectByDefault: () => true,
          },
          {
            label: intl.formatMessage({
              defaultMessage: 'Take medications as prescribed',
            }),
            value: GoalsAndInterventionsActionStep.TAKE_MEDICATION,
            selectMedsByDefault: (meds: PatientMedications) =>
              hasConditionMed(meds, Condition.Hyperlipidemia),
          },
        ],
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Achieve TC levels of < 200 mg/dL',
        }),
        value: GoalsAndInterventionsHealthGoal.OBTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.FAIR,
        actionSteps: [
          {
            label: intl.formatMessage({
              defaultMessage: 'Increase activity',
            }),
            value: GoalsAndInterventionsActionStep.EXERCISE,
            selectByDefault: hasExerciseGap,
          },
          // {
          //   label: intl.formatMessage({
          //     defaultMessage: 'Limit fatty foods',
          //   }),
          //   value: GoalsAndInterventionsActionStep.REDUCE_FAT,
          // },
          {
            label: intl.formatMessage({
              defaultMessage: 'Achieve a heart healthy diet',
            }),
            value: GoalsAndInterventionsActionStep.DIET,
            selectByDefault: () => true,
          },
          {
            label: intl.formatMessage({
              defaultMessage: 'Take medications as prescribed',
            }),
            value: GoalsAndInterventionsActionStep.TAKE_MEDICATION,
            selectMedsByDefault: (meds: PatientMedications) =>
              hasConditionMed(meds, Condition.Hyperlipidemia),
          },
          // {
          //   label: intl.formatMessage({
          //     defaultMessage: 'Get updated labs',
          //   }),
          //   value: GoalsAndInterventionsActionStep.UPDATE_LABS,
          // },
        ],
      },
    ],
    [Condition.TypeTwoDiabetes]: [
      {
        label: intl.formatMessage({
          defaultMessage: 'Maintain a healthy A1C level of < 7%',
        }),
        value: GoalsAndInterventionsHealthGoal.MAINTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.GOOD,
        actionSteps: [
          {
            label: intl.formatMessage({
              defaultMessage: 'Stay active',
            }),
            value: GoalsAndInterventionsActionStep.EXERCISE,
            selectByDefault: hasExerciseGap,
          },
          {
            label: intl.formatMessage({
              defaultMessage: 'Maintain healthy diet',
            }),
            value: GoalsAndInterventionsActionStep.DIET,
            selectByDefault: () => true,
          },
          {
            label: intl.formatMessage({
              defaultMessage: 'Take medications as prescribed',
            }),
            value: GoalsAndInterventionsActionStep.TAKE_MEDICATION,
            selectMedsByDefault: (meds: PatientMedications) =>
              hasConditionMed(meds, condition),
          },
          {
            label: intl.formatMessage({
              defaultMessage: 'Monitor blood sugar regularly',
            }),
            value: GoalsAndInterventionsActionStep.INCREASE_MONITORING,
          },
        ],
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Achieve an A1C level of < 7%',
        }),
        value: GoalsAndInterventionsHealthGoal.OBTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.FAIR,
        actionSteps: [
          {
            label: intl.formatMessage({
              defaultMessage: 'Increase activity',
            }),
            value: GoalsAndInterventionsActionStep.EXERCISE,
            selectByDefault: hasExerciseGap,
          },
          {
            label: intl.formatMessage({
              defaultMessage: 'Improve diet habits',
            }),
            value: GoalsAndInterventionsActionStep.DIET,
            selectByDefault: () => true,
          },
          // {
          //   label: intl.formatMessage({
          //     defaultMessage: 'Lower carb intake',
          //   }),
          //   value: GoalsAndInterventionsActionStep.REDUCE_CARBS,
          // },
          {
            label: intl.formatMessage({
              defaultMessage: 'Limit sugar intake',
            }),
            value: GoalsAndInterventionsActionStep.REDUCE_SUGAR,
          },
          {
            label: intl.formatMessage({
              defaultMessage: 'Take medications as prescribed',
            }),
            value: GoalsAndInterventionsActionStep.TAKE_MEDICATION,
            selectMedsByDefault: (meds: PatientMedications) =>
              hasConditionMed(meds, condition),
          },
          {
            label: intl.formatMessage({
              defaultMessage: 'Monitor blood sugar daily',
            }),
            value: GoalsAndInterventionsActionStep.INCREASE_MONITORING,
          },
          // {
          //   label: intl.formatMessage({
          //     defaultMessage: 'Learn about diabetes',
          //   }),
          //   value: GoalsAndInterventionsActionStep.EDUCATION,
          // },
        ],
      },
    ],
    [Condition.AfibAndFlutter]: [
      {
        label: intl.formatMessage({
          defaultMessage:
            'Maintain healthy HR levels between 60 and 100 bpm and remain asymptomatic',
        }),
        value: GoalsAndInterventionsHealthGoal.MAINTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.GOOD,
      },
      {
        label: intl.formatMessage({
          defaultMessage:
            'Achieve HR levels of between 60 and 100 bpm and avoid symptoms',
        }),
        value: GoalsAndInterventionsHealthGoal.OBTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.FAIR,
      },
    ],
    [Condition.COPD]: [
      {
        label: intl.formatMessage({
          defaultMessage:
            'Maintain healthy SpO2 levels of > 88% without exacerbations',
        }),
        value: GoalsAndInterventionsHealthGoal.MAINTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.GOOD,
      },
      {
        label: intl.formatMessage({
          defaultMessage:
            'Achieve SpO2 levels of > 88% and avoid exacerbations',
        }),
        value: GoalsAndInterventionsHealthGoal.OBTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.FAIR,
      },
    ],
    [Condition.Asthma]: [
      {
        label: intl.formatMessage({
          defaultMessage:
            'Maintain healthy SpO2 levels of > 92% without exacerbations',
        }),
        value: GoalsAndInterventionsHealthGoal.MAINTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.GOOD,
      },
      {
        label: intl.formatMessage({
          defaultMessage:
            'Achieve SpO2 levels of > 92% and avoid exacerbations',
        }),
        value: GoalsAndInterventionsHealthGoal.OBTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.FAIR,
      },
    ],
    [Condition.Hypothyroidism]: [
      {
        label: intl.formatMessage({
          defaultMessage:
            'Maintain healthy TSH levels between 0.5 and 5 miU/L and remain asymptomatic',
        }),
        value: GoalsAndInterventionsHealthGoal.MAINTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.GOOD,
      },
      {
        label: intl.formatMessage({
          defaultMessage:
            'Achieve TSH levels between 0.5 and 5 miU/L and avoid symptoms',
        }),
        value: GoalsAndInterventionsHealthGoal.OBTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.FAIR,
      },
    ],
    [Condition.Osteoarthritis]: [
      {
        label: intl.formatMessage({
          defaultMessage: 'Maintain minimal joint pain',
        }),
        value: GoalsAndInterventionsHealthGoal.MAINTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.GOOD,
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Minimize joint pain',
        }),
        value: GoalsAndInterventionsHealthGoal.OBTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.FAIR,
      },
    ],
    [Condition.MorbidObesity]: [
      {
        label: intl.formatMessage({
          defaultMessage: 'Maintain healthy weight',
        }),
        value: GoalsAndInterventionsHealthGoal.MAINTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.GOOD,
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Achieve 2 lb weight loss',
        }),
        value: GoalsAndInterventionsHealthGoal.OBTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.FAIR,
      },
    ],
    [Condition.ObstructiveSleepApnea]: [
      {
        label: intl.formatMessage({
          defaultMessage:
            'Maintain good sleep quality and healthy SpO2 levels of > 92%',
        }),
        value: GoalsAndInterventionsHealthGoal.MAINTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.GOOD,
      },
      {
        label: intl.formatMessage({
          defaultMessage:
            'Improve sleep quality and achieve SpO2 levels of > 92%',
        }),
        value: GoalsAndInterventionsHealthGoal.OBTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.FAIR,
      },
    ],
    [Condition.ChronicKidneyDisease]: [
      {
        label: intl.formatMessage({
          defaultMessage: 'Maintain kidney function',
        }),
        value: GoalsAndInterventionsHealthGoal.MAINTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.GOOD,
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Improve kidney function',
        }),
        value: GoalsAndInterventionsHealthGoal.OBTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.FAIR,
      },
    ],
    [Condition.CHF]: [
      {
        label: intl.formatMessage({
          defaultMessage:
            'Maintain heart function without symptoms, exacerbations, or hospitalizations',
        }),
        value: GoalsAndInterventionsHealthGoal.MAINTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.GOOD,
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Avoid symptoms, exacerbations, and hospitalizations',
        }),
        value: GoalsAndInterventionsHealthGoal.OBTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.FAIR,
      },
    ],
    [Condition.IschemicHeartDisease]: [
      {
        label: intl.formatMessage({
          defaultMessage: 'Maintain cardiac health with minimal symptoms',
        }),
        value: GoalsAndInterventionsHealthGoal.MAINTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.GOOD,
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Achieve cardiac health and minimize symptoms',
        }),
        value: GoalsAndInterventionsHealthGoal.OBTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.FAIR,
      },
    ],
    [Condition.PeripheralArteryDisease]: [
      {
        label: intl.formatMessage({
          defaultMessage: 'Maintain health with minimal symptoms',
        }),
        value: GoalsAndInterventionsHealthGoal.MAINTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.GOOD,
      },
      {
        label: intl.formatMessage({
          defaultMessage: 'Minimize symptoms',
        }),
        value: GoalsAndInterventionsHealthGoal.OBTAIN_GOAL,
        isDefault: (prognosis: CCMPrognosis) => prognosis === CCMPrognosis.FAIR,
      },
    ],
  } as Partial<
    Record<
      CcmCondition,
      {
        label: string;
        value: GoalsAndInterventionsHealthGoal;
        isDefault?: (prognosis: CCMPrognosis) => boolean;
        actionSteps?: ActionStepOption[];
      }[]
    >
  >;

  const options = goalOptions[condition];
  if (options) {
    options.push({
      label: intl.formatMessage({
        defaultMessage: 'Other',
      }),
      value: GoalsAndInterventionsHealthGoal.HEALTH_GOAL_UNSPECIFIED,
      isDefault: () => false,
    });
  }

  return options;
}

export type ActionStepOption = {
  label: string;
  value: GoalsAndInterventionsActionStep;
  selectByDefault?: (formFields: FormFields) => boolean;
  selectMedsByDefault?: (meds: PatientMedications) => boolean;
};

export function isOtherGoal(healthGoalEnum?: GoalsAndInterventionsHealthGoal) {
  return (
    healthGoalEnum === GoalsAndInterventionsHealthGoal.HEALTH_GOAL_UNSPECIFIED
  );
}

function hasExerciseGap(formFields: FormFields) {
  return formFields['assessment-physicalActivity-checked'];
}

function hasConditionMed(meds: PatientMedications, condition: CcmCondition) {
  const pmsCondition = convertCcmConditionToPmsCondition(condition);
  return meds.referencedMedications?.some(
    (med) =>
      med.referenceMedication?.conditions?.includes(pmsCondition) &&
      lastChange(med.medChanges)?.status === MedicationChangeStatus.ACTIVE,
  );
}

export function getDefaultHealthGoal(
  condition: CcmCondition,
  prognosis: CCMPrognosis,
  intl: IntlShape,
) {
  const goalOptions = getPatientGoalOptions(condition, intl);
  return goalOptions?.find((option) => option.isDefault?.(prognosis));
}
