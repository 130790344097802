import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { logger } from '@/logger';
import type {
  CCMCarePlan,
  GoalsAndInterventionsRecentIndicator,
} from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import { useFlags, usePrevious } from '@/shared/hooks';
import { useSaveCarePlan } from '@/shared/hooks/queries/carePlan.queries';
import { usePatientDemographics } from '@/shared/hooks/usePatientDemographics';
import { idToGrpcName } from '@/shared/utils/grpc';

import { useInProgressGoals } from '../Goals/goalUtils';
import {
  GRPC_CONDITION_TO_FALCON_CONDITION,
  marshalFormDataToCarePlan,
} from './dataTransformation';
import type { FormFields } from './formConfig';
import { existsInCarePlan } from './sections/GoalsAndInterventions/conditionGoalUtils';
import { useFormattedConditionVitals } from './sections/GoalsAndInterventions/hooks/useFormattedConditionVitals';

export function useOnSave(
  patientId: string,
  noteId: Maybe<number>,
  shouldShowCarePlanV1Changes: boolean,
) {
  const intl = useIntl();
  const {
    mutate: saveCarePlan,
    isLoading: isSaving,
    defaultSuccessCallback,
  } = useSaveCarePlan();
  const { carePlanOptimization } = useFlags();

  const patientDemographics = usePatientDemographics(patientId);
  const getFormattedVitals = useFormattedConditionVitals(patientId);
  const inProgressGoals = useInProgressGoals(patientId);

  const onSave = useCallback(
    (
      data: FormFields,
      { onSuccess }: { onSuccess?: (resp: CCMCarePlan) => void } = {},
    ) => {
      const carePlanPayload = marshalFormDataToCarePlan(
        data,
        intl,
        shouldShowCarePlanV1Changes,
        patientDemographics,
        carePlanOptimization,
      );
      if (carePlanOptimization) {
        carePlanPayload.carePlanVersion = '0.2';

        // Add recent vital/lab indicators to each condition's payload,
        // capturing the values at the time of this Care Plan update
        if (carePlanPayload.goalsAndInterventions) {
          carePlanPayload.goalsAndInterventions =
            carePlanPayload.goalsAndInterventions.map((goal) => {
              if (goal.condition) {
                const ccmCondition =
                  GRPC_CONDITION_TO_FALCON_CONDITION[goal.condition];
                if (ccmCondition) {
                  const vitals = getFormattedVitals(ccmCondition);
                  if (vitals.label && vitals.value) {
                    const recentIndicator: GoalsAndInterventionsRecentIndicator =
                      {
                        indicatorType: vitals.label,
                        indicatorValue: vitals.value.toString(),
                      };
                    return {
                      ...goal,
                      recentIndicator,
                    };
                  }
                }
              }
              return goal;
            });
        }

        // Add goals to payload, capturing the value at the time of this Care Plan update
        if (inProgressGoals) {
          carePlanPayload.goals = inProgressGoals.map((goal) => ({
            title: goal.title,
            description: goal.description,
            measure: goal.measure,
            category: goal.category,
            isConditionReview:
              goal.isConditionReview &&
              !existsInCarePlan(goal, carePlanPayload, intl),
          }));
        }
      }
      if (!noteId) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return (_: CCMCarePlan) => {
          logger.error('Attempted to save care plan but missing noteId.');
        };
      }

      return saveCarePlan(
        {
          carePlan: carePlanPayload,
          noteId,
          patient: idToGrpcName('patients', patientId),
        },
        {
          onSuccess: async (carePlan) => {
            onSuccess?.(carePlan);
            await defaultSuccessCallback(carePlan);
          },
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      noteId,
      saveCarePlan,
      patientId,
      defaultSuccessCallback,
      intl,
      shouldShowCarePlanV1Changes,
      patientDemographics.age,
      patientDemographics.gender,
      patientDemographics.conditions?.length,
      getFormattedVitals,
    ],
  );

  return { onSave, isSaving };
}

export function useAutosaveCarePlan(
  form: UseFormReturn<FormFields>,
  saveFn: (data: FormFields) => void,
  { enabled = true }: { enabled: boolean },
) {
  const saveFnDebounced = useMemo(() => debounce(saveFn, 3000), [saveFn]);
  const formData = form.watch();
  const previousFormData = usePrevious(formData);
  const changed = !isEqual(formData, previousFormData);

  useEffect(() => {
    if (changed && enabled) {
      // Need to call getValues() instead of using formData in order to get the
      // most up to date values
      saveFnDebounced(form.getValues());
    }

    // we only care if the form data changes, no need to call
    // this repeatedly for stuff that doesn't matter
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changed]);

  useEffect(() => () => saveFnDebounced.flush(), [saveFnDebounced]);

  return {
    // Even though this hook watches for changes and trigger autosaves
    // occasionally there are fields (such as useFieldArray fields) that will
    // require manual autosaving -- exposing this function for such purposes
    manualAutoSave: () => {
      if (enabled) {
        saveFnDebounced(form.getValues());
      }
    },
    cancelAutosave: () => {
      saveFnDebounced.cancel();
    },
  };
}
