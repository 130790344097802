import type { IntlShape } from 'react-intl';

import { validators } from '@/shared/common/Form/validations';
import { AsyncTitrationMedicationWasNotTitratedReason as NotTitratingReason } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';

export type FormFields = {
  reason?: NotTitratingReason;
  description?: string;
};

export const DESCRIPTION_MAX_LENGTH = 400;

export function getFormConfig(intl: IntlShape, values?: Partial<FormFields>) {
  const { enumType, required, maxLengthString } = validators(intl);

  return {
    fields: {
      reason: {
        defaultValue: values?.reason,
        validation: required(
          enumType({
            source: NotTitratingReason,
            pluck: 'values',
          }),
        ),
      },
      description: {
        defaultValue: values?.description,
        validation: maxLengthString({ maxLength: DESCRIPTION_MAX_LENGTH }).when(
          'reason',
          {
            is: shouldShowDescription,
            then: (schema) => required(schema),
          },
        ),
      },
    },
  };
}

export function shouldShowDescription(reason?: NotTitratingReason) {
  return reason && reason !== NotTitratingReason.ADJUSTED_ANOTHER_MEDICATION;
}
