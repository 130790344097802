import { NoteStatus } from '@/shared/generated/grpc/go/pms/pkg/note/note.pb';
import { AsyncTitrationAsyncTitrationStatus as Status } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';

import type { TitrationRecommendation } from './hooks';

export function isAsyncTitrationPending(
  titration?: TitrationRecommendation,
  noteId?: Maybe<number>,
) {
  if (!titration?.status || !titration?.initialReviewNoteStatus) {
    return false;
  }

  if (titration.status === Status.INITIALLY_REVIEWED) {
    return (
      titration.initialReviewNoteStatus === NoteStatus.PUBLISHED ||
      titration.initialReviewNoteId === noteId
    );
  }

  if (titration.status === Status.PROVIDER_ACCEPTED) {
    return (
      titration.providerDecisionNoteStatus === NoteStatus.PUBLISHED ||
      titration.providerDecisionNoteId === noteId
    );
  }

  return false;
}
