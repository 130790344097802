import cx from 'classnames';

import type { PatientDxInfo } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { useFlags } from '@/shared/hooks';
import {
  usePatientDetails,
  useWasPatientEnrolled,
} from '@/shared/hooks/queries';
import { isDateValid } from '@/shared/patient/datetimeUtils';

import { useNoteEditorContext } from '../patientDetails/ui/Notes/NoteEditorContext';
import { DynamicPatientInfoSummary } from '../patientInfoSummaries';
import { useNumberOfPatientInfoColumns } from '../patientInfoSummaries/numberOfPatientInfoColumns';
import {
  patientOverviewContainer,
  patientOverviewFullName,
  patientOverviewInfoAndScheduling,
  patientOverviewOverviewHeader,
} from './PatientOverview.css';
import { PatientReminderNote } from './PatientReminderNote';
import { PatientScheduling } from './PatientScheduling';
import { PatientProfileBanner } from './shared/PatientProfileBanner';

type Props = {
  patientId: string;
};

const hasValidGoogleDate = (dxInfo?: PatientDxInfo) =>
  isDateValid(dxInfo?.rpmDxSuggestedDate) ||
  isDateValid(dxInfo?.rpmDxCorrectedDate);

export function PatientOverview({ patientId }: Props) {
  const { isEditorOpen } = useNoteEditorContext();
  const numberOfPatientInfoColumns = useNumberOfPatientInfoColumns();
  const wasPatientEnrolled = useWasPatientEnrolled(patientId);

  const { data: patientDetail } = usePatientDetails(patientId, true);

  const patient = patientDetail?.patient;

  const { showDxWarningBanner } = useFlags();
  const hasDxInfoChange =
    showDxWarningBanner && hasValidGoogleDate(patientDetail?.dxInfo);
  return (
    <div className={patientOverviewContainer}>
      {patientDetail?.dxInfo && hasDxInfoChange && (
        <PatientProfileBanner
          patient={patientDetail}
          healthSystemId={
            patientDetail.ehrInformation?.hospital?.healthSystem?.name
          }
        />
      )}
      <div className={patientOverviewOverviewHeader}>
        <div className={patientOverviewFullName}>
          {patient?.givenName} {patient?.familyName}
        </div>
        {wasPatientEnrolled && <PatientReminderNote />}
      </div>
      <div
        className={cx({
          [patientOverviewInfoAndScheduling.editorOpen]: isEditorOpen,
          [patientOverviewInfoAndScheduling.fourCols]:
            !isEditorOpen && numberOfPatientInfoColumns === 4,
          [patientOverviewInfoAndScheduling.lessThan4cols]:
            !isEditorOpen && numberOfPatientInfoColumns < 4,
        })}
      >
        {patientDetail && (
          <DynamicPatientInfoSummary patientDetails={patientDetail} />
        )}
      </div>
      <PatientScheduling patientId={patientId} />
    </div>
  );
}
