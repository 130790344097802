import cx from 'classnames';
import { useMemo } from 'react';

import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { Skeleton } from '@/shared/tempo/atom/Skeleton';

import { NextScheduledVisit } from './NextScheduledVisit';
import { schedulingContainerV2 } from './PatientScheduling.css';
import {
  useGetNextScheduledAppointment,
  useListAppointments,
} from './appointments.queries';

type Props = {
  patientId: string;
};

export function PatientScheduling({ patientId }: Props) {
  const { data: nextScheduledAppointment, isLoading: isLoadingAppointment } =
    useGetNextScheduledAppointment(patientId, true);
  // We want to always show the next appointment if there is one, even if it's in the past
  const nextAppointment = nextScheduledAppointment?.nextAppointment;
  const start = useMemo(() => new Date().toISOString(), []);
  const { data: appts } = useListAppointments({
    patientId,
    apptStartTimeFrom: start,
  });

  return (
    <div
      className={cx(schedulingContainerV2.primary, {
        [schedulingContainerV2.error]: nextAppointment?.patientNoShow,
      })}
    >
      <LoadingPlaceholder
        isLoading={isLoadingAppointment}
        placeholder={<Skeleton variant="text" width="100%" />}
      >
        <NextScheduledVisit
          appointment={nextAppointment}
          patientId={patientId}
          upcomingAppointments={appts?.appointments}
        />
      </LoadingPlaceholder>
    </div>
  );
}
