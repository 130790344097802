import { format, getMinutes, isSameDay, isValid } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import type { IntlShape } from 'react-intl';

import { NoteStatus as ApptNoteStatus } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { type AppointmentDetails } from '@/shared/generated/grpc/go/pms/pkg/scheduling/scheduling.pb';

export function getFormattedDate(intl: IntlShape, start: Date) {
  if (!isValid(start)) {
    return intl.formatMessage({ defaultMessage: 'N/A' });
  }

  if (isSameDay(start, Date.now())) {
    return intl.formatMessage({ defaultMessage: 'Today' });
  }

  return format(start, 'MM/dd/yyyy');
}

export function getFormattedTime(
  intl: IntlShape,
  start: Date,
  timezone: string,
) {
  if (!isValid(start)) {
    return intl.formatMessage({ defaultMessage: 'N/A' });
  }

  if (getMinutes(start) === 0) {
    return formatInTimeZone(start, timezone, 'haaa z');
  }

  return formatInTimeZone(start, timezone, 'h:mmaaa z');
}

export function getBadge(intl: IntlShape, apptType: string) {
  const lowerApptType = apptType.toLowerCase();

  if (lowerApptType.includes('apcm')) {
    return intl.formatMessage({ defaultMessage: 'APCM' });
  }

  if (lowerApptType.includes('ccm')) {
    return intl.formatMessage({ defaultMessage: 'CCM' });
  }

  return intl.formatMessage({ defaultMessage: 'RPM' });
}

export function isNewEncounter(appt: AppointmentDetails) {
  return appt.noteStatus === ApptNoteStatus.NOTE_STATUS_UNSPECIFIED;
}

export function isPublishedNoShow(appt: AppointmentDetails) {
  return appt.noteStatus === ApptNoteStatus.PUBLISHED && appt.patientNoShow;
}
