import { getCurrentEnvHostname } from '@/config';
import { formatConditions } from '@/pages/patients/patientInfoSummaries/utils/formatting.utils';
import type { NextAppointmentRecommendation } from '@/shared/generated/grpc/go/pms/pkg/scheduling/scheduling.pb';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { getDeprecatedRpmConditionsFromProgramAndStatus } from '@/shared/patient/deprecated/conditions.utils';
import type { Patient } from '@/shared/types/patient.types';

import { getCurrentEnvAcuityFieldIds } from './acuityIds.utils';
import { getAppointmentsFilterParam } from './suggestion.utils';
import type { AcuityIframeUrlParams } from './types';
import { ApptsFilter } from './types';

const CADENCE_NO_REPLY_EMAIL = 'scheduling@cadencerpm.com';

const formattedTimezone = (timezone: string) => {
  // Acuity doesn't support Hawaii timezone `Etc/GMT+10` stably, so we need to convert it to Pacific/Honolulu
  if (timezone === 'Etc/GMT+10') {
    return 'Pacific/Honolulu';
  }
  return timezone;
};

export enum AppointmentCreationMethod {
  SMART_SCHEDULER = 'SMART_SCHEDULER',
  SUGGESTED_OPTIONS = 'SUGGESTED_OPTIONS',
  ALL_OPTIONS = 'ALL_OPTIONS',
  UNSPECIFIED = 'UNSPECIFIED',
}

// This is used for getting params for loading the acuity scheduling in an iframe
export function useAcuityIframeUrlParams({
  patientDetails,
  patientOrCaregiverPhone,
  apptsFilter,
  recommendedAppt,
  careProviderName,
}: {
  patientDetails: Patient;
  patientOrCaregiverPhone: string;
  recommendedAppt: Maybe<NextAppointmentRecommendation>;
  apptsFilter: ApptsFilter;
  careProviderName?: string;
}): AcuityIframeUrlParams {
  const {
    first_name: firstName,
    last_name: lastName,
    ehr_information: ehrInformation,
    timezone,
    programs,
    status,
  } = patientDetails;
  const { currentUserId } = useCurrentUser();

  const mrn = ehrInformation?.mrn || patientDetails.mrns?.[0] || '';
  const condition =
    formatConditions(
      getDeprecatedRpmConditionsFromProgramAndStatus(programs, status),
    ) || '';
  const appointmentTypesFilter = getAppointmentsFilterParam(
    apptsFilter,
    recommendedAppt,
    patientDetails.status,
  );

  const env = getCurrentEnvHostname();
  const patientLink = `https://${env}/patients/${patientDetails.id}`;
  const fieldIds = getCurrentEnvAcuityFieldIds();

  // Determine the appointment creation method
  let creationMethod = AppointmentCreationMethod.UNSPECIFIED;

  if (apptsFilter === ApptsFilter.SHOW_SUGGESTED) {
    creationMethod = AppointmentCreationMethod.SUGGESTED_OPTIONS;
  } else if (apptsFilter === ApptsFilter.SHOW_ALL) {
    creationMethod = AppointmentCreationMethod.ALL_OPTIONS;
  }

  return {
    // Default fields
    firstName,
    lastName,
    email: CADENCE_NO_REPLY_EMAIL,
    timezone: formattedTimezone(timezone || ''),
    phone: `${patientOrCaregiverPhone}`,
    // Custom fields
    [`field:${fieldIds.cadenceUrl}`]: patientLink,
    [`field:${fieldIds.mrn}`]: `${mrn}`,
    [`field:${fieldIds.conditions}`]: `${condition}`,
    [`field:${fieldIds.orderingProvider}`]: careProviderName || '',
    [`field:${fieldIds.schedulingProviderId}`]: currentUserId,
    [`field:${fieldIds.recommendedSourceApptId}`]:
      recommendedAppt?.sourceAppointmentId || '',
    [`field:${fieldIds.creationMethod}`]: String(creationMethod),
    // Filter fields
    'appointmentType[]': appointmentTypesFilter,
    calendarID:
      apptsFilter === ApptsFilter.SHOW_SUGGESTED
        ? recommendedAppt?.careProviderAcuityCalendarId
        : undefined,
  };
}

export function getEmbeddedAcuityIframeUrl(
  baseUrl: string,
  iframeParams: AcuityIframeUrlParams,
) {
  const iframeSearchParams = new URLSearchParams();
  Object.keys(iframeParams).forEach((k) => {
    const key = k as keyof AcuityIframeUrlParams;
    const value = iframeParams[key];
    if (Array.isArray(value)) {
      // Ensure we format array fields properly
      value.forEach((val) => {
        const keyName = key.endsWith('[]') ? key : `${key}[]`;
        iframeSearchParams.append(keyName, val);
      });
    } else if (value) {
      iframeSearchParams.append(key, value);
    }
  });

  // Base url already contains a query string, so append additional params via ampersand
  return `${baseUrl}&${iframeSearchParams.toString()}`;
}
