import cx from 'classnames';
import { type ReactNode, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { PatientCumulativeTimeTracker } from '@/pages/patients/PatientCumulativeTimeTracker';
import { useGetAppointment } from '@/pages/patients/PatientProfile/PatientScheduling/appointments.queries';
import CloseIcon from '@/shared/assets/svgs/close.svg?react';
import Eyecon from '@/shared/assets/svgs/eye.svg?react';
import { IconButton } from '@/shared/common/IconButton';
import { useFlags } from '@/shared/hooks';
import { Timer, type TimerState, getTimerUrgency } from '@/shared/notes/Timer';
import {
  HotkeyTooltip,
  SpecialChar,
} from '@/shared/tempo/molecule/HotkeyTooltip';
import type { EHR } from '@/shared/types/ehr.types';

import { useNoteEditorContext } from '../../NoteEditorContext';
import { NoteSubmitButtons } from '../../NoteSubmitButtons';
import type { TypeOfEncounter } from '../../Notes.types';
import { isClinicalNavigatorEncounterType } from '../../utils/encounterTypeUtils';
import { useEncounterModuleInstances } from '../hooks/useEncounterModuleInstances.hook';
import type { EndFormValues } from '../noteFormState';
import {
  buttonWrapper,
  headerContainer,
  previewButtonActive,
  previewButtonInactive,
  timeTracker,
  timer as timerClass,
} from './NoteEditorHeader.css';

type NoteEditorHeaderProps = {
  timer: TimerState;
  onCloseClick: () => void;
  onSaveDraft: () => void;
  onPublishToCadenceClick: () => void;
  onPublishToEhrClick: () => void;
  onEndEarly: () => void;
  onDiscardEndEarly: () => void;
  endEncounterValues?: EndFormValues;
  isSaveAsDraftAvailable: boolean;
  ehr?: EHR;
  disablePublishToEhr: boolean;
};

type HeaderProps = Pick<NoteEditorHeaderProps, 'onCloseClick'> & {
  children: ReactNode;
};
function NoteSidebarHeader({ children, onCloseClick }: HeaderProps) {
  const { isPreviewing, setIsPreviewing } = useNoteEditorContext();
  const intl = useIntl();

  return (
    <div className={headerContainer}>
      <IconButton onClick={onCloseClick}>
        <CloseIcon />
      </IconButton>
      {isPreviewing && <PatientCumulativeTimeTracker className={timeTracker} />}
      <div className={buttonWrapper}>
        {children}
        <HotkeyTooltip
          placement="bottom-start"
          hotkey={[SpecialChar.Meta, 'P']}
          title={intl.formatMessage({ defaultMessage: 'Preview' })}
        >
          <IconButton
            className={cx({
              [previewButtonActive]: isPreviewing,
              [previewButtonInactive]: !isPreviewing,
            })}
            onClick={() => setIsPreviewing(!isPreviewing)}
          >
            {/* hehe */}
            <Eyecon />
          </IconButton>
        </HotkeyTooltip>
      </div>
    </div>
  );
}

export const NoteEditorHeader = ({
  timer,
  onCloseClick,
  onSaveDraft,
  onPublishToCadenceClick,
  onPublishToEhrClick,
  isSaveAsDraftAvailable,
  onEndEarly,
  onDiscardEndEarly,
  endEncounterValues,
  ehr,
  disablePublishToEhr,
}: NoteEditorHeaderProps) => {
  const { appointmentReminders } = useFlags();
  const { editingNote } = useNoteEditorContext();
  // Appointment this encounter is associated with
  const appointmentId =
    editingNote?.note?.appointment_id ||
    editingNote?.note?.no_show_appointment_id;
  const showTimer = appointmentReminders && !!appointmentId;

  const { encounterTypeInstance } = useEncounterModuleInstances();
  const typeOfEncounter = encounterTypeInstance?.inputs
    .type_of_encounter as TypeOfEncounter;
  const { timeElapsed, timerRunning, setTimerRunning } = timer;

  const shouldTimerRun = showTimer && !!typeOfEncounter;
  useEffect(() => {
    setTimerRunning(shouldTimerRun);
    // Can't watch setTimerRunning since it also changes on timerRunning change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldTimerRun]);

  const { data: appointment } = useGetAppointment(appointmentId, {
    enabled: appointmentReminders,
  });

  return (
    <NoteSidebarHeader onCloseClick={onCloseClick}>
      {showTimer && (
        <Timer
          className={timerClass}
          urgency={getTimerUrgency(timeElapsed, appointment?.duration)}
          timerRunning={timerRunning}
          onToggle={() => setTimerRunning(!timerRunning)}
          totalTime={timeElapsed}
          isPauseable={
            !!typeOfEncounter &&
            !isClinicalNavigatorEncounterType(typeOfEncounter)
          }
        />
      )}
      <NoteSubmitButtons
        onSaveAsDraftClick={onSaveDraft}
        onPublishToCadenceClick={onPublishToCadenceClick}
        onPublishToEhrClick={onPublishToEhrClick}
        isSaveAsDraftAvailable={isSaveAsDraftAvailable}
        onEndEarly={onEndEarly}
        onDiscardEndEarly={onDiscardEndEarly}
        endEncounterValues={endEncounterValues}
        typeOfEncounter={typeOfEncounter}
        ehr={ehr}
        disablePublishToEhr={disablePublishToEhr}
      />
    </NoteSidebarHeader>
  );
};
