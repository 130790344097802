import type { ReactNode } from 'react';

import { color } from '@/shared/generated/theme/variables';

import { styles } from './Notice.css';

type Props = {
  title: ReactNode;
  icon: ReactNode;
  children: ReactNode;
  warning?: boolean;
};

export function Notice({ title, icon, children, warning = false }: Props) {
  return (
    <div
      className={styles.notice}
      style={{
        background: warning
          ? color.Theme.Light['Warning Tint']
          : color.Theme.Light['Primary Tint'],
      }}
    >
      <div className={styles.content}>
        {icon}
        <div className={styles.messages}>
          <div>{title}</div>
          <div className={styles.description}>{children}</div>
        </div>
      </div>
    </div>
  );
}
