import { useIntl } from 'react-intl';

import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { queriesAreLoading } from '@/reactQuery';
import { useFlags } from '@/shared/hooks/useFlags';

import { EHR } from '../generated/grpc/cadence/models/models.pb';
import { PatientExternalProviderType } from '../generated/grpc/go/pms/pkg/patient/pms.pb';
import {
  useCareProvider,
  useInstance,
  usePatientDetails,
  usePatientHospitals,
} from './queries';
import { convertEhrToModel } from './queries/patients-grpc/converters';
import { useCurrentUser } from './useCurrentUser';

export const useIsNotePublishable = (
  patientId: string,
  typeOfEncounter: TypeOfEncounter | undefined,
) => {
  const {
    disablePublishIfEpicProviderIdMissing,
    hidePublishToEhr,
    hidePublishToCadenceButton,
  } = useFlags();
  const intl = useIntl();

  const patientDetailQuery = usePatientDetails(patientId, true);
  const isEpicPatient =
    patientDetailQuery?.data?.ehrInformation?.ehr !== undefined &&
    patientDetailQuery.data.ehrInformation.ehr === EHR.EPIC;

  // find patient hospital instance
  const patientHospitalsQuery = usePatientHospitals({
    patientId,
    pageSize: 1,
    page: 1,
  });
  const hospitalId = patientHospitalsQuery.data?.hospitals[0]?.id || '';
  const patientInstanceId =
    patientHospitalsQuery.data?.hospitals[0]?.ehr_information?.instance_id;

  // find current user same instance hospital associated ehr_provider
  const currentUser = useCurrentUser();
  const careProviderQuery = useCareProvider(currentUser?.currentUserId, {
    enabled: !!currentUser?.currentUserId,
  });

  const currentUserSameInstanceHospital =
    careProviderQuery.data?.hospitals?.find(
      ({ ehr_information }) =>
        ehr_information?.instance_id === patientInstanceId,
    );
  const patientEhrProvider = careProviderQuery.data?.ehr_information?.find(
    ({ hospital_id }) => hospital_id === currentUserSameInstanceHospital?.id,
  );

  // check if current user same instance hospital is in hidePublishToEhr list
  const isVerificationExemptedHospital = hidePublishToEhr.includes(hospitalId);

  // check if we need to consider disabling the publish button
  // only some epic partnership requires note author id check
  const shouldGuardPublishToEhrButton = Boolean(
    disablePublishIfEpicProviderIdMissing &&
      isEpicPatient &&
      !isVerificationExemptedHospital,
  );

  const showPublishToCadence =
    isVerificationExemptedHospital || !hidePublishToCadenceButton;

  const ehrInformation =
    patientHospitalsQuery.data?.hospitals[0]?.ehr_information?.ehr;
  const ehrValuesToCheck = [EHR.EPIC, EHR.ATHENA, EHR.CERNER];
  const showPublishToEhr =
    ehrInformation &&
    ehrValuesToCheck.includes(convertEhrToModel(ehrInformation));

  const instanceId =
    patientHospitalsQuery.data?.hospitals[0]?.ehr_information?.instance_id;
  const instanceQuery = useInstance(instanceId || '', {
    enabled: Boolean(instanceId),
  });
  const hasGenericSer = Boolean(instanceQuery.data?.epicConfig?.genericSer);
  const usesScannedDocNotes = Boolean(
    instanceQuery.data?.epicConfig?.useScannedDocNotes,
  );
  const hasNeededIdOrException =
    hasGenericSer ||
    usesScannedDocNotes ||
    Boolean(patientEhrProvider?.epic_external_id);

  const isCarePlanUpload =
    typeOfEncounter &&
    [TypeOfEncounter.CCM_CARE_PLAN, TypeOfEncounter.APCM_CARE_PLAN].includes(
      typeOfEncounter,
    );

  const referringProvider =
    patientDetailQuery?.data?.externalCareProviders?.find(
      ({ careProviderType }) =>
        careProviderType === PatientExternalProviderType.REFERRING,
    );

  // participating epic partnership requires note author
  // to have epic SER Id to publish note
  const canPublishToEpic =
    isVerificationExemptedHospital ||
    ((!shouldGuardPublishToEhrButton || hasNeededIdOrException) &&
      (showPublishToEhr || showPublishToCadence));

  const isPublishable = isCarePlanUpload
    ? referringProvider && canPublishToEpic
    : canPublishToEpic;

  const isLoading = queriesAreLoading([
    patientHospitalsQuery,
    careProviderQuery,
    patientDetailQuery,
    instanceQuery,
  ]);

  const helperText = (() => {
    if (isCarePlanUpload && !referringProvider) {
      return intl.formatMessage({
        defaultMessage: 'Missing supervising provider',
      });
    }
    if (
      shouldGuardPublishToEhrButton &&
      !patientEhrProvider?.epic_external_id
    ) {
      return intl.formatMessage({ defaultMessage: 'User missing Epic ID' });
    }
    return intl.formatMessage({ defaultMessage: 'No hospital association' });
  })();

  return {
    isLoading,
    isPublishable,
    helperText,
    showPublishToCadence,
  };
};
