import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import {
  AsyncTitrationAsyncTitrationStatus,
  AsyncTitrationAsyncTitrationType as AsyncTitrationType,
} from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { useFlags } from '@/shared/hooks';
import type { MedManagementDelegationUiMappingValue } from '@/shared/hooks/queries/med-management.queries';
import { MedManagementDelegationUiMapping as MedMgmt } from '@/shared/hooks/queries/med-management.queries';

import type { TitrationRecommendation } from './useTitrationRecommendation';

export function useShouldDisplayRecommendOnlyActions(
  asyncTitration: TitrationRecommendation,
  medDelegation: MedManagementDelegationUiMappingValue,
  typeOfEncounter: Maybe<TypeOfEncounter>,
) {
  const { titrationOutreachActions } = useFlags();

  if (
    asyncTitration.status !==
    AsyncTitrationAsyncTitrationStatus.INITIALLY_REVIEWED
  ) {
    return false;
  }

  if (
    !titrationOutreachActions ||
    typeOfEncounter !== TypeOfEncounter.PATIENT_FOLLOW_UP
  ) {
    return false;
  }

  const isCurrentMedsOnly =
    medDelegation === MedMgmt.OptInCurrentMeds ||
    medDelegation === MedMgmt.OptInCurrentMedsAndNotify;
  const isRecommendOnly = medDelegation === MedMgmt.OptOutRecommendMeds;

  if (isRecommendOnly && asyncTitration.isWritable) {
    return true;
  }

  if (isCurrentMedsOnly && asyncTitration.type === AsyncTitrationType.NEW_MED) {
    return true;
  }

  return false;
}
