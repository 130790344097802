import type { UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import type { ListCareProviderSchema } from '@/shared/generated/api/pms';
import { CareProviderRole } from '@/shared/generated/grpc/cadence/models/models.pb';
import { EHR } from '@/shared/types/ehr.types';
import type { Hospital } from '@/shared/types/hospital.types';
import type { EhrInformation } from '@/shared/types/patient.types';
import { ProviderType } from '@/shared/types/provider.types';

import { useQueryNoRefetch } from '../useQueryNoRefetch';

type EhrAddress = {
  address: string;
  apt?: string;
  city: string;
  state: string;
  zip: string;
};

type EhrClinicPatient = {
  birth_date: string;
  ehr: EHR;
  full_name: string;
  mrn: string;
  phone_number: string;
  address?: EhrAddress;
};

export type CernerClinicPatient = EhrClinicPatient & {
  ehr: EHR.Cerner;
  cerner_fhir_id: string;
};

export type EpicClinicPatient = EhrClinicPatient & {
  ehr: EHR.Epic;
  epic_fhir_id: string;
  epic_id: string;
};

export type AthenaClinicPatient = EhrClinicPatient & {
  ehr: EHR.Athena;
};

export type NonIntegratedClinicPatient = {
  ehr: EHR.NON_INTEGRATED;
  mrn: string;
};

export type EpicProvider = {
  epic_provider_id?: string;
  epic_fhir_id?: string;
  epic_external_id?: string;
};

export type CernerProvider = {
  cerner_provider_id?: string;
  cerner_fhir_id?: string;
  cerner_external_id?: string;
};

export type ExternalProvider = {
  fhir_id?: string;
  fhir_provider_id?: string;
  fhir_external_id?: string;
};

export type EhrProvider = {
  npi: string;
  provider_id: string;
  provider_name: string;
  provider_type: string;
} & EpicProvider &
  ExternalProvider;

const ehrClinicPrefix = (clinicId?: string) => [
  'ehr',
  'api',
  'v1',
  'hospital',
  clinicId,
];

type MrnSearchPayload = {
  patients: (EpicClinicPatient | AthenaClinicPatient)[];
};

export function useMrnSearch(clinic?: Hospital, mrn?: string) {
  const ehrInfo = clinic?.ehr_information;
  const ehr = ehrInfo?.ehr;

  return useQuery<MrnSearchPayload>(
    [
      ...ehrClinicPrefix(clinic?.id),
      'mrn-lookup',
      {
        ehr,
        mrn,
        health_system_id: clinic?.health_system_id,
        ...(ehr === EHR.Athena && {
          athena_department_id: ehrInfo?.athena_department_id,
          athena_practice_id: ehrInfo?.athena_practice_id,
        }),
      },
    ],
    {
      enabled: Boolean(clinic && mrn),
    },
  );
}

type ExternalProviderSearchPayload = {
  providers: EhrProvider[];
};

export function useExternalProviderSearch(
  clinic?: Hospital,
  external_provider_id?: string,
) {
  const ehrInfo = clinic?.ehr_information;
  const ehr = ehrInfo?.ehr;

  return useQuery<ExternalProviderSearchPayload>(
    [
      ...ehrClinicPrefix(clinic?.id),
      'external-provider-lookup',
      {
        ehr,
        external_provider_id,
        health_system_id: clinic?.health_system_id,
        ...(ehr === EHR.Athena && {
          athena_department_id: ehrInfo?.athena_department_id,
          athena_practice_id: ehrInfo?.athena_practice_id,
        }),
      },
    ],
    {
      enabled: Boolean(clinic && external_provider_id),
    },
  );
}

export function useExternalProviderSearchByNPI(
  clinic?: Hospital,
  npi?: string,
) {
  const ehr = clinic?.ehr_information?.ehr;

  return useQuery<ExternalProviderSearchPayload>(
    [
      ...ehrClinicPrefix(clinic?.id),
      'external-provider-lookup',
      {
        ehr,
        npi,
        health_system_id: clinic?.health_system_id,
      },
    ],
    {
      enabled: Boolean(
        clinic && npi && (ehr === EHR.Epic || ehr === EHR.Cerner),
      ),
    },
  );
}
export function useEpicExternalProviderSearchByName(
  clinic?: Hospital,
  full_name?: string,
  email?: string,
) {
  const ehr = clinic?.ehr_information?.ehr;

  return useQuery<ExternalProviderSearchPayload>(
    [
      ...ehrClinicPrefix(clinic?.id),
      'external-provider-lookup',
      {
        ehr,
        full_name,
        email,
        health_system_id: clinic?.health_system_id,
      },
    ],
    {
      enabled: Boolean(clinic && full_name && ehr === EHR.Epic),
    },
  );
}

type ProviderListResponse = {
  providers: EhrProvider[];
};

type UseProviderListOptions = {
  enabled: boolean;
};

export function usePmsPatientExternalCareProviders(
  patientId: string,
  options?: UseProviderListOptions,
): UseQueryResult<ProviderListResponse, unknown> {
  return useQuery<ListCareProviderSchema, unknown, ProviderListResponse>(
    ['pms', 'api', 'v1', 'patients', patientId, 'care_providers'],
    {
      enabled: options?.enabled !== false && Boolean(patientId),
      staleTime: Infinity,
      select: (data) => {
        if (!data.care_providers) {
          return {
            providers: [],
          };
        }
        return {
          providers: data.care_providers
            .filter(
              (provider) => provider.provider_type === ProviderType.Hospital,
            )
            .map((provider) => ({
              npi: provider.npi_id?.toString() ?? '',
              provider_id: provider.id ?? '',
              provider_name: `${provider.first_name} ${provider.last_name}`,
              provider_type: provider.role ?? CareProviderRole.ROLE_OTHER,
              // Epic specific fields
              epic_provider_id: provider.ehr_information?.[0]?.epic_provider_id,
              epic_fhir_id:
                provider.ehr_information?.[0]?.epic_fhir_id ?? undefined,
              epic_external_id:
                provider.ehr_information?.[0]?.epic_external_id ?? undefined,
              // Cerner specific fields
              cerner_provider_id:
                provider.ehr_information?.[0]?.cerner_provider_id,
              cerner_fhir_id:
                provider.ehr_information?.[0]?.cerner_fhir_id ?? undefined,
              cerner_external_id:
                provider.ehr_information?.[0]?.cerner_external_id ?? undefined,
              // Generic FHIR fields
              fhir_id:
                (provider.ehr_information?.[0]?.epic_fhir_id ||
                  provider.ehr_information?.[0]?.cerner_fhir_id) ??
                undefined,
              fhir_provider_id:
                provider.ehr_information?.[0]?.epic_provider_id ||
                provider.ehr_information?.[0]?.cerner_provider_id,
              fhir_external_id:
                (provider.ehr_information?.[0]?.epic_external_id ||
                  provider.ehr_information?.[0]?.cerner_external_id) ??
                undefined,
            })),
        };
      },
    },
  );
}

export function useProviderList(
  clinic?: Hospital,
  patientEhrInfo?: EhrInformation,
  options?: UseProviderListOptions,
) {
  const ehrInfo = clinic?.ehr_information;
  const ehr = ehrInfo?.ehr;

  return useQueryNoRefetch<ProviderListResponse>(
    [
      ...ehrClinicPrefix(clinic?.id),
      'patient',
      'providers',
      {
        ehr,
        mrn: patientEhrInfo?.mrn,
        health_system_id: clinic?.health_system_id,
        ...(ehr === EHR.Athena && {
          athena_department_id: ehrInfo?.athena_department_id,
          athena_practice_id: ehrInfo?.athena_practice_id,
        }),
        ...(ehr === EHR.Epic && {
          epic_fhir_id: patientEhrInfo?.epic_fhir_id,
          fhir_id: patientEhrInfo?.epic_fhir_id,
        }),
        ...(ehr === EHR.Cerner && {
          fhir_id: patientEhrInfo?.cerner_fhir_id,
        }),
      },
    ],
    {
      enabled: options?.enabled !== false && Boolean(clinic && patientEhrInfo),
    },
  );
}
