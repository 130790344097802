import { useAllPatientConditions } from '@/apps/shared/useProgramConditions';
import type { Condition } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { useFlags } from '@/shared/hooks';
import { usePatientBestThreeConditionsFromProblemList } from '@/shared/hooks/queries';

import type { PatientConditionData } from '../prognosisUtils';
import { getConditionPrognosis, sortConditions } from '../prognosisUtils';
import { usePatientConditionData } from './usePatientConditionData.hook';

export type InitialCarePlanConditions = ReturnType<
  typeof useInitialCarePlanConditions
>['conditions'];

// useInitialCarePlanConditions returns the conditions that initially populate the care plan
// and initial Prognosis if relevant data is available for that condition
export function useInitialCarePlanConditions(
  patientId: string,
  isCcmEncounter: boolean,
  enabled = true,
) {
  const { carePlanOptimization } = useFlags();

  const determineTopConditionUsingPrognosis =
    carePlanOptimization && !isCcmEncounter;

  const { data: bestThreeConditionsResp, isLoading: isLoadingBestConditions } =
    usePatientBestThreeConditionsFromProblemList(patientId, {
      enabled: !determineTopConditionUsingPrognosis && enabled,
    });

  const { conditions, isLoading: isLoadingConditions } =
    useAllPatientConditions(
      patientId,
      determineTopConditionUsingPrognosis && enabled,
    );

  const { data: conditionData, isLoading: isLoadingConditionData } =
    usePatientConditionData(patientId);

  // For CCM Encounters, we use the best 3 conditions endpoint, which is also used to determine the programs
  // for the patient. For APCM Encounters, we determine the top 3 conditions by taking into prognosis into account.
  // Note for APCM all of this logic is currently in the FE because we're already pulling in all the needed vital data, but it could be moved to the backend once more solidified.
  const prioritizedConditions = determineTopConditionUsingPrognosis
    ? pickTopThreeConditions(conditions, conditionData)
    : (bestThreeConditionsResp?.conditions || [])
        .map((c) => c.conditionType)
        .filter((c): c is Condition => !!c);

  const skippedConditions = conditions
    .filter((condition) => !prioritizedConditions.includes(condition))
    .map((condition) => ({
      condition,
      prognosis: getConditionPrognosis(
        condition,
        conditionData,
        carePlanOptimization,
      ),
    }));

  return {
    conditions: prioritizedConditions.map((c) => ({
      condition: c,
      prognosis: getConditionPrognosis(c, conditionData, carePlanOptimization),
    })),
    skippedConditions,
    isLoading:
      isLoadingBestConditions ||
      isLoadingConditions ||
      isLoadingConditionData ||
      !enabled,
  };
}

function pickTopThreeConditions(
  conditions: Condition[],
  conditionData: PatientConditionData,
) {
  const sortedConditions = sortConditions(
    conditions.map((condition) => ({
      condition,
      prognosis: getConditionPrognosis(
        condition,
        conditionData,
        true, // This is only called when carePlanOptimization is true
      ),
    })),
  );
  return sortedConditions.slice(0, 3).map((c) => c.condition);
}
