import type { ReactNode } from 'react';

import { Select as MUISelect } from '@/deprecated/mui';
import {
  addInputClassname,
  addInputWrapperClassname,
} from '@/shared/common/Input';
import { SelectArrow } from '@/shared/common/SelectArrow';

import type { BaseFieldProps } from '../../BaseField';
import { BaseField } from '../../BaseField';

type Props<TVal> = {
  children: ReactNode;
  disabled?: boolean;
  parseValue?: (val: string) => unknown;
  inputClass?: string;
  selectClass?: string;
  ariaLabel?: string;
  onInputChange?: (val: TVal) => void;
} & BaseFieldProps<TVal>;

// TODO: Do we want this to be a native select?
// Should we use MUI's MenuItem to create non-native styles options?
export function Select<TVal>({
  children,
  disabled,
  parseValue,
  inputClass,
  selectClass,
  ariaLabel,
  onInputChange,
  ...baseProps
}: Props<TVal>) {
  const { name } = baseProps;
  return (
    <BaseField {...baseProps}>
      {({ controller: { field, fieldState } }) => (
        <>
          <MUISelect
            {...field}
            disabled={disabled || baseProps.isDisabled}
            error={!!fieldState.error}
            className={addInputWrapperClassname(
              baseProps.isDisabled,
              baseProps.isReadOnly,
              !!fieldState.error,
              inputClass,
            )}
            id={name}
            labelId={name}
            native
            variant="outlined"
            inputProps={addInputClassname({
              name,
              id: name,
              className: selectClass,
            })}
            onChange={({ target: { value } }) => {
              const val = parseValue ? parseValue(value) : value;
              onInputChange?.(val);
              field.onChange(val);
            }}
            IconComponent={() => <SelectArrow />}
            aria-label={ariaLabel}
          >
            {children}
          </MUISelect>
        </>
      )}
    </BaseField>
  );
}
