import { apcmCarePlanVisit } from './apcmCarePlanVisit';
import { ccmCarePlanVisit } from './ccmCarePlanVisit';
import { dischargeFollowup } from './dischargeFollowup';
import { initialCnVisit } from './initialCnVisit';
import { initialNpVisit } from './initialNpVisit';
import { regularApcmVisit } from './regularApcmVisit';
import { regularCcmVisit } from './regularCcmVisit';
import { regularNpVisit } from './regularNpVisit';
import { titrationOutreachVisit } from './titrationOutreachVisit';

export const templates = {
  ...apcmCarePlanVisit,
  ...ccmCarePlanVisit,
  ...dischargeFollowup,
  ...initialCnVisit,
  ...initialNpVisit,
  ...regularApcmVisit,
  ...regularCcmVisit,
  ...regularNpVisit,
  ...titrationOutreachVisit,
};
