import { useIntl } from 'react-intl';

import type { CCMGoal } from '@/shared/generated/grpc/go/pms/pkg/ccm_goal/ccm_goal.pb';
import { GoalStatus } from '@/shared/generated/grpc/go/pms/pkg/ccm_goal/ccm_goal.pb';
import {
  useCreateCcmGoal,
  useDeleteCcmGoal,
  useSaveCcmGoal,
} from '@/shared/hooks/queries/ccmGoals.queries';
import { useToaster } from '@/shared/tempo/molecule/Toast/ToasterContext';

export function useGoalMutations(patientId: string) {
  const { mutate: updateGoal } = useSaveCcmGoal();
  const { mutate: createGoalMutation } = useCreateCcmGoal(patientId);
  const { mutate: deleteGoalMutation } = useDeleteCcmGoal();

  const { toaster } = useToaster();
  const intl = useIntl();

  function closeGoal(goal: CCMGoal) {
    updateGoal(
      {
        ...goal,
        status: GoalStatus.COMPLETE,
      },
      {
        onError: () => {
          toaster.error(
            intl.formatMessage({ defaultMessage: 'Failed to close goal' }),
          );
        },
      },
    );
  }

  function declineGoal(goal: CCMGoal) {
    updateGoal(
      {
        ...goal,
        status: GoalStatus.DECLINED,
      },
      {
        onError: () => {
          toaster.error(
            intl.formatMessage({ defaultMessage: 'Failed to decline goal' }),
          );
        },
      },
    );
  }

  function createGoal(goal: CCMGoal, carePlanId: string) {
    createGoalMutation(
      {
        goal,
        carePlanId,
      },
      {
        onError: () => {
          toaster.error(
            intl.formatMessage({ defaultMessage: 'Failed to create goal' }),
          );
        },
      },
    );
  }

  function updateGoalDescription(goal: CCMGoal, description: string) {
    updateGoal(
      {
        ...goal,
        // Move the goal to in progress if it is complete
        ...(goal.status === GoalStatus.COMPLETE
          ? { status: GoalStatus.IN_PROGRESS }
          : {}),
        description,
      },
      {
        onError: () => {
          toaster.error(
            intl.formatMessage({ defaultMessage: 'Failed to update goal' }),
          );
        },
      },
    );
  }

  function updateGoalStatus(goal: CCMGoal, status: GoalStatus) {
    updateGoal(
      {
        ...goal,
        status,
      },
      {
        onError: () => {
          toaster.error(
            intl.formatMessage({
              defaultMessage: 'Failed to update goal status',
            }),
          );
        },
      },
    );
  }

  function deleteGoalIfExists(goal: Maybe<CCMGoal>) {
    if (!goal?.name) {
      return;
    }

    deleteGoalMutation(goal.name, {
      onError: () => {
        toaster.error(
          intl.formatMessage({ defaultMessage: 'Failed to delete goal' }),
        );
      },
    });
  }

  return {
    closeGoal,
    declineGoal,
    updateGoalDescription,
    updateGoalStatus,
    createGoal,
    deleteGoalIfExists,
  };
}
