import type { UseFormReturn } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useCcmConditionOptions } from '@/pages/adminPanel/patient-profile/tabs/ClinicalProfileTab/clinicalprofile.utils';
import ArrowUpRight from '@/shared/assets/svgs/arrow-up-right.svg?react';
import { Form } from '@/shared/common/Form';
import type { CCMGoal } from '@/shared/generated/grpc/go/pms/pkg/ccm_goal/ccm_goal.pb';
import { useFlags } from '@/shared/hooks';
import { convertCcmConditionToPmsCondition } from '@/shared/patient/conditions.utils';
import { Link } from '@/shared/tempo/atom/Link';
import { color } from '@/shared/tempo/theme';
import type { CcmCondition } from '@/shared/types/clinicalprofile.types';
import type { RouteParam } from '@/shared/types/route.types';

import type { GoalMeasure } from '../../../Goals/goals.types';
import { RemovableRow } from '../../RemovableRow';
import { CCM_CONDITION_RESOURCES, useCcmConditionI18n } from '../../conditions';
import type { FormFields } from '../../formConfig';
import {
  conditionSelect,
  inlineCareGuide,
  treatmentText,
} from './GoalsAndInterventions.css';
import { PrognosisAndPlan } from './PrognosisAndPlan';
import { usePatientConditionData } from './hooks/usePatientConditionData.hook';
import { getConditionPrognosis, getDefaultGoalSetting } from './prognosisUtils';

type Props = {
  index: number;
  onRemove: () => void;
  manualAutoSave: () => void;
  form: UseFormReturn<FormFields>;
  isRemovable: boolean;
  numOfConditions: number;
  openCreateGoalModal: (measure: GoalMeasure, description?: string) => void;
  openEditGoalModal: (goal: CCMGoal) => void;
  carePlanId: string;
};

export function ConditionSection({
  index,
  form,
  isRemovable,
  onRemove,
  numOfConditions,
  manualAutoSave,
  openCreateGoalModal,
  openEditGoalModal,
  carePlanId,
}: Props) {
  const { carePlanOptimization } = useFlags();

  const intl = useIntl();
  const ccmConditionI18n = useCcmConditionI18n();
  const conditionOptions = useCcmConditionOptions();
  const selectedCondition = form.watch(
    `goalsAndInterventions.${index}.condition`,
  );

  const { patientId }: RouteParam = useParams();
  const patientConditionData = usePatientConditionData(patientId);

  function triggerPopulatedConditionValidations() {
    for (let i = 0; i < numOfConditions; i++) {
      if (form.getValues(`goalsAndInterventions.${i}.condition`)) {
        form.trigger(`goalsAndInterventions.${i}.condition`);
      }
    }
  }

  const conditionLabel = intl.formatMessage(
    {
      defaultMessage: 'Condition {count}',
    },
    { count: index + 1 },
  );

  return (
    <>
      <RemovableRow
        onChange={() => manualAutoSave()}
        onRemove={() => {
          onRemove();
          // Trigger validation for uniqueness check
          triggerPopulatedConditionValidations();
        }}
        hasError={
          !!form.formState.errors.goalsAndInterventions?.[index]?.condition
        }
        enabled={isRemovable}
      >
        <Form.DeprecatedSelect
          selectClass={conditionSelect}
          required
          size={11}
          onInputChange={(condition: CcmCondition) => {
            if (condition) {
              form.setValue(
                `goalsAndInterventions.${index}.treatmentGoal`,
                CCM_CONDITION_RESOURCES[condition].goal,
              );

              const defaultPrognosis = getConditionPrognosis(
                convertCcmConditionToPmsCondition(condition),
                patientConditionData.data,
                carePlanOptimization,
                undefined,
              );
              form.setValue(
                `goalsAndInterventions.${index}.prognosis`,
                defaultPrognosis,
              );

              const defaultGoalSetting =
                getDefaultGoalSetting(defaultPrognosis);
              if (carePlanOptimization) {
                form.setValue(
                  `goalsAndInterventions.${index}.goalSetting`,
                  defaultGoalSetting,
                );
              } else {
                form.setValue(
                  `goalsAndInterventions.${index}.skipGoalSetting`,
                  !defaultGoalSetting,
                );
              }

              // Trigger validation for uniqueness check
              triggerPopulatedConditionValidations();
            }
          }}
          label={conditionLabel}
          name={`goalsAndInterventions.${index}.condition`}
        >
          <option disabled selected value={undefined}>
            {intl.formatMessage({ defaultMessage: 'Select one' })}
          </option>
          {conditionOptions.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Form.DeprecatedSelect>
      </RemovableRow>
      <div className={treatmentText}>
        <FormattedMessage
          defaultMessage="Treatment Goal: {treatmentGoal}."
          values={{
            treatmentGoal: selectedCondition
              ? CCM_CONDITION_RESOURCES[selectedCondition].goal
              : 'N/A',
          }}
        />
        {selectedCondition && (
          <Link.Native
            className={inlineCareGuide.link}
            href={CCM_CONDITION_RESOURCES[selectedCondition].link}
            target="_blank"
          >
            {intl.formatMessage(
              { defaultMessage: 'See {condition} Care Guide' },
              { condition: ccmConditionI18n[selectedCondition] },
            )}
            <ArrowUpRight
              fill={color.Theme.Light.Primary}
              className={inlineCareGuide.icon}
            />
          </Link.Native>
        )}
      </div>

      {selectedCondition && (
        <PrognosisAndPlan
          index={index}
          condition={selectedCondition}
          openCreateGoalModal={openCreateGoalModal}
          openEditGoalModal={openEditGoalModal}
          carePlanId={carePlanId}
        />
      )}
    </>
  );
}
