import {
  CareModelVersion,
  type FeatureFlagSet,
} from '@/shared/types/featureFlags.types';

import { disenrollment } from '../__shared__/disenrollment';
import { patientEnrollment } from '../__shared__/patientEnrollment';
import { virtualEnrollment } from '../__shared__/virtualEnrollment';
import { welcomeCall } from '../__shared__/welcomeCall';
import { templates as zeroTemplates } from './3.0.0';
import { templates as patchOneTemplates } from './3.0.1';
import { templates as minorOneTemplates } from './3.1.0';

const zero = {
  [CareModelVersion.V3]: {
    ...zeroTemplates,
    ...patientEnrollment,
    ...virtualEnrollment,
    ...welcomeCall,
    ...disenrollment,
  },
};

const zeroPatchOne = {
  [CareModelVersion.V3]: {
    ...zero[CareModelVersion.V3],
    ...patchOneTemplates,
  },
};

const zeroMinorOne = {
  [CareModelVersion.V3]: {
    ...zeroPatchOne[CareModelVersion.V3],
    ...minorOneTemplates,
  },
};

// zeroMinorOnePatchOne
// zeroMinorTwo
// zeroMinorTwoPatchOne
// zeroMinorTwoPatchTwo and so on..

export function getThreeDotOhTemplates(flags: FeatureFlagSet) {
  // Add conditional statements (e.g. LD flagging) for newer versions above all existing returns,
  // so they take precedence over the older ones.
  if (flags.additionalEndEarlyTemplates) {
    return zeroMinorOne;
  }
  if (flags.structuredClinicalGoalReached) {
    return zeroPatchOne;
  }
  return zero;
}
