import { useQuery } from 'react-query';

import { CareProviderRole as ModelsCareProviderRole } from '@/shared/generated/grpc/cadence/models/models.pb';
import type { NoteSymptom } from '@/shared/generated/grpc/go/pms/pkg/note/note.pb';
import type {
  CheckPatientAlertEscalationRequiredRequest,
  CheckPatientAlertEscalationRequiredResponse,
} from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import {
  PatientService,
  CareProviderRole as PmsCareProviderRole,
} from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';

import type { SymptomsInputs } from '../../../pages/patients/patientDetails/ui/Notes/NotePreview/formatSymptomsInputs.util';

// Map from Models CareProviderRole to PMS CareProviderRole
const CareProviderRoleMapping = {
  [ModelsCareProviderRole.MD]: PmsCareProviderRole.MD,
  [ModelsCareProviderRole.DO]: PmsCareProviderRole.DO,
  [ModelsCareProviderRole.NP]: PmsCareProviderRole.NP,
  [ModelsCareProviderRole.RN]: PmsCareProviderRole.RN,
  [ModelsCareProviderRole.MA]: PmsCareProviderRole.MA,
  [ModelsCareProviderRole.PA]: PmsCareProviderRole.PA,
  [ModelsCareProviderRole.LPN]: PmsCareProviderRole.LPN,
  [ModelsCareProviderRole.LVN]: PmsCareProviderRole.LVN,
  [ModelsCareProviderRole.ROLE_OTHER]: PmsCareProviderRole.OTHER,
  [ModelsCareProviderRole.ROLE_UNSPECIFIED]:
    PmsCareProviderRole.CARE_PROVIDER_ROLE_UNSPECIFIED,
};

type NewOrWorseningSymptoms = {
  [key: string]: NoteSymptom;
};

function symptomsInputsToNewOrWorsening(
  obj: SymptomsInputs,
): NewOrWorseningSymptoms {
  return Object.keys(obj).reduce((acc, key) => {
    const v = obj[key];
    const isBool = typeof v === 'boolean';

    if (!isBool && !v) {
      return acc;
    }

    return {
      ...acc,
      [key]: {
        hasSymptom: isBool ? v : v.has_symptom,
        isNewOrWorseningSymptom: isBool
          ? undefined
          : v.is_new_or_worsening_symptom,
        description: isBool ? undefined : v.description,
      },
    };
  }, {} as NewOrWorseningSymptoms);
}

export function useCheckPatientAlertEscalationRequired(
  patientId: string,
  newOrWorseningSymptoms: SymptomsInputs,
  role?: ModelsCareProviderRole,
) {
  const symptoms = symptomsInputsToNewOrWorsening(newOrWorseningSymptoms);
  // Map from Models CareProviderRole to PMS CareProviderRole
  const mappedRole = role ? CareProviderRoleMapping[role] : undefined;

  const request: CheckPatientAlertEscalationRequiredRequest = {
    patientId,
    newOrWorseningSymptoms: symptoms,
    role: mappedRole,
  };

  return useQuery<CheckPatientAlertEscalationRequiredResponse, Error>(
    ['checkPatientAlertEscalationRequired', patientId, role, symptoms],
    () => PatientService.CheckPatientAlertEscalationRequired(request),
    { enabled: true },
  );
}
