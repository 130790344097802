import pick from 'lodash/pick';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Form } from '@/shared/common/Form';
import { useFormFromConfig } from '@/shared/common/Form/FormContainer';
import { useFlags } from '@/shared/hooks';
import { usePatientDetails } from '@/shared/hooks/queries';
import { getRpmConditions } from '@/shared/patient/conditions.utils';
import {
  Condition,
  type RpmCondition,
} from '@/shared/types/clinicalprofile.types';
import { EndEncounterType } from '@/shared/types/note.types';

import {
  type ClinicalGoalReachedFormFields,
  type ClinicalGoalReachedInputs,
  EncounterModuleId,
  type EncounterModuleInstance,
  type EncounterTypeInputs,
  GoalUnmetReason,
} from '../../../Notes.types';
import { usePatientNotes } from '../../../note.queries';
import { getInstance } from '../../../utils/encounterModuleInstanceUtils';
import {
  getEncounterType,
  isNPEncounterType,
} from '../../../utils/encounterTypeUtils';
import { useNoteFormValuesContext } from '../../NoteFormValuesProvider';
import { header } from './ClinicalGoalReachedForm.css';
import { getFormConfig } from './clinicalGoalReachedFormConfig';
import { ChfGoals, HtnAndT2dGoals, HtnGoal, T2dGoal } from './goals';
import { STRING_TO_BOOL_MAP } from './goals/stringToBoolMap';
import { useVitalAvgAndGoals } from './useVitalAvgAndGoals';

type Props = {
  initialValues?: ClinicalGoalReachedInputs;
  onChange: (inputs: EncounterModuleInstance) => void;
  shouldShowValidation: boolean;
  encounterTypeInstance: EncounterModuleInstance<EncounterTypeInputs>;
};

export function ClinicalGoalReachedForm({ initialValues, ...props }: Props) {
  const { patientId } = useParams<{ patientId: string }>();
  const { isLoading: isVitalValuesAndGoalsLoading, vitalValuesAndGoals } =
    useVitalAvgAndGoals();
  const { infiniteQuery: patientNotesQuery, items: recentNotes } =
    usePatientNotes(patientId);
  const { data: patientDetails, isLoading: isLoadingPatientDetails } =
    usePatientDetails(patientId, true);

  if (
    isLoadingPatientDetails ||
    isVitalValuesAndGoalsLoading ||
    !vitalValuesAndGoals ||
    patientNotesQuery.isLoading
  ) {
    return null;
  }

  const conditions = getRpmConditions(patientDetails);
  const recentNpNote = recentNotes.find((note) => {
    if (!note.encounter_instances) {
      return false;
    }

    const encounterType = getEncounterType(note.encounter_instances);

    return isNPEncounterType(encounterType);
  });
  const prevNoteClinicalGoalInstance = getInstance(
    recentNpNote?.encounter_instances ?? [],
    EncounterModuleId.ClinicalGoalReached,
  );

  return (
    <ClinicalGoalReached
      {...props}
      conditions={conditions}
      vitalValuesAndGoals={vitalValuesAndGoals}
      initialValues={{
        hfType: prevNoteClinicalGoalInstance?.inputs.hfType,
        historyOfHospitalizationOrSymptoms:
          prevNoteClinicalGoalInstance?.inputs
            .historyOfHospitalizationOrSymptoms,
        ...initialValues,
      }}
    />
  );
}

function ClinicalGoalReached({
  initialValues,
  onChange,
  shouldShowValidation,
  conditions,
  encounterTypeInstance,
  vitalValuesAndGoals,
}: Props & {
  conditions: RpmCondition[];
  vitalValuesAndGoals: Exclude<
    ReturnType<typeof useVitalAvgAndGoals>['vitalValuesAndGoals'],
    null
  >;
}) {
  const intl = useIntl();
  const { gatherNonTitrationReasons } = useFlags();
  const noteFormValues = useNoteFormValuesContext();
  const isChf = conditions.includes(Condition.CHF);
  const isHtn = conditions.includes(Condition.Hypertension);
  const isT2d = conditions.includes(Condition.TypeTwoDiabetes);
  const isNoShow =
    encounterTypeInstance.inputs.patient_no_show ||
    noteFormValues.endEncounter?.endType === EndEncounterType.EndEarly ||
    noteFormValues.endEncounter?.endType === EndEncounterType.NoShow;

  const goalValues = pick(
    vitalValuesAndGoals,
    'systolicGoal',
    'diastolicGoal',
    'a1cGoal',
    'bgGoal',
  );
  const formConfig = getFormConfig(
    intl,
    shouldShowValidation,
    isChf,
    isT2d,
    isHtn,
    goalValues,
    initialValues,
    undefined,
    gatherNonTitrationReasons,
  );
  const form = useFormFromConfig<ClinicalGoalReachedFormFields>({
    ...formConfig,
    triggerReset: isNoShow,
  });
  const {
    hfType,
    isBpGoalMet,
    isBgGoalMet,
    isGdmtGoalMet,
    isBgBpGoalMet,
    bpGoalDetails,
    bgGoalDetails,
    bgBpGoalDetails,
    gdmtGoalDetails,
    reasonsGoalUnmet,
    otherReasonDetails,
    historyOfHospitalizationOrSymptoms,
    notTitratingReason,
    isA1cGoalMet,
    isT2dGoalMet,
    patientTitrationRejectionReason,
    capturingNonTitrationReason,
    hasMedChange,
  } = form.watch();

  useEffect(
    () => {
      // Trigger whole form validation when validation gets enabled and when new inputs are added
      if (shouldShowValidation) {
        form.trigger();
      }

      // Using setTimeout to avoid race condition causing
      // form validation to not work for new notes and untouched form fields
      setTimeout(() =>
        onChange({
          encounter_module_id: EncounterModuleId.ClinicalGoalReached,
          inputs: isNoShow
            ? {}
            : ({
                hfType,
                isBpGoalMet,
                bpGoalDetails,
                isBgGoalMet,
                bgGoalDetails,
                isGdmtGoalMet,
                gdmtGoalDetails,
                reasonsGoalUnmet,
                otherReasonDetails,
                isBgBpGoalMet,
                bgBpGoalDetails,
                historyOfHospitalizationOrSymptoms,
                notTitratingReason,
                isA1cGoalMet,
                isT2dGoalMet,
                patientTitrationRejectionReason,
                capturingNonTitrationReason,
                hasMedChange,
                ...vitalValuesAndGoals,
                ...(isChf ? { isChf } : {}),
                ...(isT2d ? { isT2d } : {}),
                ...(isHtn ? { isHtn } : {}),

                // this is temporary until full rollout. these fields are
                // currently required on the backend but are being deprecated
                // with the work flagged behind gatherNonTitrationReasons.
                // once we're ready to roll out fully, we'll remove that validation
                // and remove these lines
                ...(gatherNonTitrationReasons && {
                  reasonsGoalUnmet: [GoalUnmetReason.Other],
                  otherReasonDetails:
                    'Capturing reason in non-titration reasons.',
                }),
              } satisfies ClinicalGoalReachedInputs),
        }),
      );
    },
    // Run only on form fields, isNoShow or shouldShowValidation change (e.g. not on onChange change)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      hfType,
      isBpGoalMet,
      bpGoalDetails,
      isBgGoalMet,
      bgGoalDetails,
      isGdmtGoalMet,
      gdmtGoalDetails,
      reasonsGoalUnmet,
      otherReasonDetails,
      isBgBpGoalMet,
      bgBpGoalDetails,
      shouldShowValidation,
      isNoShow,
      historyOfHospitalizationOrSymptoms,
      notTitratingReason,
      isA1cGoalMet,
      isT2dGoalMet,
      patientTitrationRejectionReason,
      capturingNonTitrationReason,
      hasMedChange,
    ],
  );

  if (isNoShow) {
    return null;
  }

  if (!conditions.length) {
    return (
      <div>
        <FormattedMessage defaultMessage="The patient has no conditions set for which vital goals can be displayed." />
      </div>
    );
  }

  return (
    <>
      <div className={header}>
        <FormattedMessage defaultMessage="Clinical Goals" />
      </div>
      <Form form={form}>
        <Form.Hidden
          name="capturingNonTitrationReason"
          value={String(gatherNonTitrationReasons)}
          valueMap={STRING_TO_BOOL_MAP}
        />
        {isChf && (
          <ChfGoals
            hfType={hfType}
            isBpGoalMet={isBpGoalMet}
            isGdmtGoalMet={isGdmtGoalMet}
            vitalValuesAndGoals={vitalValuesAndGoals}
            historyOfHospitalizationOrSymptoms={
              historyOfHospitalizationOrSymptoms
            }
          />
        )}
        {isHtn && !isT2d && (
          <HtnGoal
            isBpGoalMet={isBpGoalMet}
            vitalValuesAndGoals={vitalValuesAndGoals}
          />
        )}
        {isT2d && !isHtn && (
          <T2dGoal
            isBgGoalMet={isBgGoalMet}
            vitalValuesAndGoals={vitalValuesAndGoals}
          />
        )}
        {isT2d && isHtn && (
          <HtnAndT2dGoals
            isBgBpGoalMet={isBgBpGoalMet}
            vitalValuesAndGoals={vitalValuesAndGoals}
          />
        )}
      </Form>
    </>
  );
}
