import type { DeviceType } from './device.types';
import type { Note } from './note.types';
import type { ReadingDataType } from './patientSummary.types';
import type { AllTagType } from './tagsAndThreshold.types';

export type ReadingId = string;

export enum AlertStatus {
  Active = 'ACTIVE',
  Resolved = 'RESOLVED',
  Dismissed = 'DISMISSED',
  Pending = 'PENDING',
}

export type ReadingRelations = {
  [key in ReadingDataType]?: ReadingId[];
};

export type RelationTagsUnsuppressed = {
  [key in ReadingDataType]?: {
    [key: ReadingId]: AllTagType[];
  };
};

export enum EscalationStatus {
  NotEscalated = 'NOT_ESCALATED',
  NeedsEscalationToNP = 'NEEDS_ESCALATION_TO_NP',
  NeedsEscalationToPhysician = 'NEEDS_ESCALATION_TO_PHYSICIAN',
  EscalatedToNP = 'ESCALATED_TO_NP',
  EscalatedToPhysician = 'ESCALATED_TO_PHYSICIAN',
}

export type VitalsAlert = {
  id: number;
  patient_id: string;
  status: AlertStatus;
  priority: number;
  patient_day: string;
  note: Nullable<Note>;
  relations: ReadingRelations;
  // Old alerts will potentially have an undefined relation tags object because
  // it was introduced post-release
  relation_tags_unsuppressed?: RelationTagsUnsuppressed;
  has_custom_threshold: boolean;
  alert_tags: AllTagType[];
  alert_verified_tags?: AllTagType[];
  created_at: string;
  updated_at: Nullable<string>;
  created_by: Nullable<string>;
  updated_by: Nullable<string>;
  retake_notification_for_device?: DeviceType[];
  escalation_status?: EscalationStatus;
};

export type ActiveVitalsAlert = VitalsAlert & {
  status: AlertStatus.Active;
  note: null;
};
