import cx from 'classnames';
import {
  addDays,
  compareAsc,
  differenceInMinutes,
  endOfDay,
  format,
  formatISO,
  parseISO,
  startOfDay,
  subDays,
} from 'date-fns';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { cnNoteKeys } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/querykeys';
import { appointmentKeys } from '@/pages/patients/PatientProfile/PatientScheduling/appointments.queries';
import { ConfirmationDialog } from '@/pages/patients/patientDetails/ui/Notes/NoteEditor/dialogs';
import { useCreateEncounter } from '@/pages/patients/patientDetails/ui/Notes/note.queries';
import { useFetchQuery } from '@/reactQuery';
import { ChevronLeft, ChevronRight } from '@/shared/assets/svgs';
import CalendarIcon from '@/shared/assets/svgs/calendar.svg?react';
import DraftFileIcon from '@/shared/assets/svgs/file.svg?react';
import NewFileIcon from '@/shared/assets/svgs/fileNew.svg?react';
import PublishedFileIcon from '@/shared/assets/svgs/filePublished.svg?react';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { NoteStatus as ApptNoteStatus } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import type { AppointmentDetails } from '@/shared/generated/grpc/go/pms/pkg/scheduling/scheduling.pb';
import {
  useDeleteAutosavedNoteByPatientId,
  usePatientAutosavedNote,
} from '@/shared/hooks/queries/autosave-notes.queries';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { Button } from '@/shared/tempo/atom/Button/Button';
import { IconButton } from '@/shared/tempo/atom/IconButton';
import { NotificationBadge } from '@/shared/tempo/atom/NotificationBadge';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { getErrorMsg } from '@/shared/utils/helpers';

import { NoShowDetails } from './NoShowDetails';
import {
  apptRowContainer,
  apptRowMain,
  apptRowNames,
  apptsContainer,
  arrowIcon,
  datePickerContainer,
  day,
  draftedIcon,
  duration,
  emptyApptIcon,
  emptyApptMessage,
  emptyApptState,
  monthDay,
  newIcon,
  noShowTime,
  pastApptContainer,
  patientName,
  patientNameLink,
  pickerDirectionButton,
  publishedIcon,
  scheduleContainer,
  time,
  timeDetailsContainer,
  today,
  weekDay,
} from './ScheduleTray.css';
import { useCreateNoteFromAppointment } from './useCreateNoteFromAppointment';
import type { NotificationInfo } from './utils';

type ScheduleTrayBodyProps = {
  isLoading?: boolean;
  onUpdateDateUsed: (date: Date) => void;
  dateUsed: Date;
  appointments: AppointmentDetails[];
  notification: NotificationInfo;
};

export function ScheduleTrayBody({
  onUpdateDateUsed,
  dateUsed,
  isLoading,
  appointments,
  notification,
}: ScheduleTrayBodyProps) {
  return (
    <div className={scheduleContainer}>
      <DatePicker
        selectedDate={dateUsed}
        onDateChange={(date: Date) => {
          onUpdateDateUsed(date);
        }}
      />
      <LoadingPlaceholder isLoading={!!isLoading}>
        <div className={apptsContainer}>
          {appointments?.length === 0 && (
            <div className={emptyApptState}>
              <CalendarIcon className={emptyApptIcon} />
              <div className={emptyApptMessage}>
                <FormattedMessage defaultMessage="You have no appointments on this date yet." />
              </div>
            </div>
          )}
          {appointments
            ?.sort((a, b) => {
              if (!a.startTime) {
                return -1;
              }
              if (!b.startTime) {
                return 1;
              }
              const dateA = parseISO(a.startTime);
              const dateB = parseISO(b.startTime);

              return compareAsc(dateA, dateB);
            })
            .map((appt) => (
              <AppointmentEntry
                key={appt.name}
                apptDetails={appt}
                notification={
                  notification.appointmentId === appt.name ? notification : null
                }
              />
            ))}
        </div>
      </LoadingPlaceholder>
    </div>
  );
}

type DatePickerProps = {
  selectedDate: Date;
  onDateChange: (date: Date) => void;
};
function DatePicker({ selectedDate, onDateChange }: DatePickerProps) {
  const currentDate = new Date(selectedDate);
  const prevDate = subDays(selectedDate, 1);
  const nextDate = addDays(selectedDate, 1);
  const intl = useIntl();
  const todayDate = new Date();

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLDivElement>,
    date: Date,
  ) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onDateChange(date);
    }
  };

  return (
    <div className={datePickerContainer}>
      <IconButton
        className={pickerDirectionButton}
        variant="tertiary"
        onPress={() => onDateChange(prevDate)}
      >
        <ChevronLeft />
      </IconButton>
      <div
        className={day}
        onClick={() => onDateChange(prevDate)}
        role="button"
        tabIndex={0}
        onKeyPress={(e) => handleKeyPress(e, prevDate)}
      >
        <div className={weekDay}>{format(prevDate, 'EEE').toUpperCase()}</div>
        <div className={monthDay}>{format(prevDate, 'd')}</div>
      </div>
      <div className={today}>
        <div className={weekDay}>
          {currentDate.getDate() === todayDate.getDate()
            ? intl.formatMessage(
                { defaultMessage: 'TODAY {currentDate}' },
                { currentDate: format(currentDate, 'EEE').toUpperCase() },
              )
            : format(currentDate, 'EEE').toUpperCase()}
        </div>
        <div className={monthDay}>{format(currentDate, 'd')}</div>
      </div>
      <div
        className={day}
        onClick={() => onDateChange(nextDate)}
        role="button"
        tabIndex={0}
        onKeyPress={(e) => handleKeyPress(e, nextDate)}
      >
        <div className={weekDay}>{format(nextDate, 'EEE').toUpperCase()}</div>
        <div className={monthDay}>{format(nextDate, 'd')}</div>
      </div>
      <IconButton
        className={pickerDirectionButton}
        variant="tertiary"
        onPress={() => onDateChange(nextDate)}
      >
        <ChevronRight className={arrowIcon} />
      </IconButton>
    </div>
  );
}

type AppointmentEntryProps = {
  apptDetails: AppointmentDetails;
  notification: Nullable<NotificationInfo>;
};

function AppointmentEntry({
  apptDetails,
  notification,
}: AppointmentEntryProps): React.ReactElement {
  const intl = useIntl();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { toaster } = useToaster();
  const [isNewNoteConfirmationOpen, setIsNewNoteConfirmationOpen] =
    useState(false);

  const deleteAutoSavedNote = useDeleteAutosavedNoteByPatientId(
    apptDetails.patientId ?? '',
    () => {},
    (error: unknown) => {
      toaster.error(getErrorMsg(error));
    },
  );

  const { fetch: fetchAutosavedNote } = useFetchQuery(usePatientAutosavedNote);
  const { currentUserId: providerId } = useCurrentUser();

  const apptDateTime = parseISO(apptDetails.startTime ?? '');
  const { mutate: createEncounter, isLoading: isSaving } = useCreateEncounter({
    onSuccess: async (response) => {
      await queryClient.invalidateQueries(
        cnNoteKeys.autosaved(apptDetails.patientId ?? ''),
      );
      await queryClient.invalidateQueries(
        appointmentKeys.list({
          apptStartTimeFrom: formatISO(startOfDay(apptDateTime)),
          apptStartTimeTo: formatISO(endOfDay(apptDateTime)),
          careProviderId: providerId,
        }),
      );
      await queryClient.invalidateQueries(
        appointmentKeys.nextScheduled(apptDetails.patientId ?? ''),
      );
      setIsNewNoteConfirmationOpen(false);
      history.push({
        pathname: `/patients/${apptDetails.patientId}`,
        search: `?noteId=${response?.name}`,
      });
    },
    onError: (err: unknown) => {
      toaster.error(getErrorMsg(err));
    },
  });

  const navigateToNote = () =>
    history.push({
      pathname: `/patients/${apptDetails.patientId}`,
      search: `?noteId=${apptDetails.noteId}`,
    });

  const { isLoading: isLoadingContext, createNoteFromAppointment } =
    useCreateNoteFromAppointment(apptDetails.patientId ?? '', createEncounter);

  const timeToAppt = differenceInMinutes(apptDateTime, new Date(), {
    roundingMethod: 'ceil',
  });
  const isApptInPast = timeToAppt < 0;

  return (
    <>
      <div
        className={cx(apptRowContainer, {
          [pastApptContainer]: isApptInPast,
        })}
      >
        <div className={apptRowMain}>
          <div
            className={cx({
              [timeDetailsContainer]: true,
              [noShowTime]: apptDetails.patientNoShow,
            })}
          >
            <div className={time}>
              {format(parseISO(apptDetails.startTime ?? ''), ' h:mm a')}
            </div>
            <div className={duration}>
              {intl.formatMessage(
                { defaultMessage: '{duration}mins' },
                { duration: apptDetails.duration },
              )}
            </div>
          </div>
          <div className={apptRowNames}>
            <Button
              size="small"
              className={patientNameLink}
              variant="tertiary"
              onPress={() => history.push(`/patients/${apptDetails.patientId}`)}
            >
              <div
                className={patientName}
              >{`${apptDetails.patientFirstName} ${apptDetails.patientLastName}`}</div>
            </Button>
            {apptDetails.appointmentCanonicalName}
            {apptDetails.patientNoShow && (
              <NoShowDetails
                noShowAttemptNotes={apptDetails.noShowAttemptNotes || []}
              />
            )}
          </div>
          {notification?.appointmentId && (
            <div>
              <NotificationBadge
                variant={notification.startingSoon ? 'warn' : 'default'}
                count={
                  notification.startingSoon
                    ? intl.formatMessage(
                        { defaultMessage: 'Starts in {duration} min' },
                        { duration: Math.abs(notification.minutes || 0) },
                      )
                    : intl.formatMessage(
                        { defaultMessage: '{duration} min past' },
                        { duration: Math.abs(notification.minutes || 0) },
                      )
                }
              />
            </div>
          )}
        </div>
        {(apptDetails.noteStatus === ApptNoteStatus.AUTOSAVED ||
          apptDetails.noteStatus === ApptNoteStatus.DRAFT) && (
          <IconButton.Tooltip
            content={<FormattedMessage defaultMessage="Note started" />}
          >
            <IconButton
              size="small"
              variant="secondary"
              className={draftedIcon}
              onPress={navigateToNote}
            >
              <DraftFileIcon />
            </IconButton>
          </IconButton.Tooltip>
        )}
        {apptDetails.noteStatus === ApptNoteStatus.PUBLISHED && (
          <IconButton.Tooltip
            content={<FormattedMessage defaultMessage="Note published" />}
          >
            <IconButton
              size="small"
              variant="secondary"
              className={publishedIcon}
              onPress={navigateToNote}
            >
              <PublishedFileIcon />
            </IconButton>
          </IconButton.Tooltip>
        )}
        {apptDetails.noteStatus === ApptNoteStatus.NOTE_STATUS_UNSPECIFIED && (
          <IconButton.Tooltip
            content={<FormattedMessage defaultMessage="Start note" />}
          >
            <IconButton
              size="small"
              variant="secondary"
              isDisabled={isLoadingContext}
              isProcessing={isSaving}
              className={newIcon}
              onPress={async () => {
                const query = await fetchAutosavedNote(
                  apptDetails.patientId ?? '',
                );
                if (query.data) {
                  setIsNewNoteConfirmationOpen(true);
                } else {
                  createNoteFromAppointment(apptDetails);
                }
              }}
            >
              <NewFileIcon />
            </IconButton>
          </IconButton.Tooltip>
        )}
      </div>
      <ConfirmationDialog
        isOpen={isNewNoteConfirmationOpen}
        onCancel={() => setIsNewNoteConfirmationOpen(false)}
        onConfirm={() => {
          deleteAutoSavedNote.mutate(undefined, {
            onSuccess: () => {
              createNoteFromAppointment(apptDetails);
            },
          });
        }}
        onSecondaryAction={() => {
          history.push({
            pathname: `/patients/${apptDetails.patientId}`,
          });
        }}
        confirmButtonText={intl.formatMessage({
          defaultMessage: 'Create Note',
        })}
        dialogTitle={intl.formatMessage({
          defaultMessage: 'Create a new note from appointment',
        })}
        dialogDescription={intl.formatMessage({
          defaultMessage:
            'Are you sure you want to create a new note? This will discard your current autosaved note not associated to this appointment.',
        })}
        secondaryButtonText={intl.formatMessage({
          defaultMessage: 'Navigate to Patient',
        })}
      />
    </>
  );
}
