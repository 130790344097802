import { FormattedMessage, useIntl } from 'react-intl';

import { useFormFromConfig } from '@/shared/common/Form/FormContainer';
import { Modal } from '@/shared/common/Modal';
import {
  GRPC_RESOURCE_NAME,
  useCreateComment,
  useUpdateTask,
} from '@/shared/hooks/queries/tasks.queries';
import type { Task } from '@/shared/tasking/types';
import { Button } from '@/shared/tempo/atom/Button';
import { useToaster } from '@/shared/tempo/molecule/Toast';

import { TaskForm } from '../../../TaskModal/TaskForm';
import { type FormFields, getFormConfig } from '../../../TaskModal/form.config';
import { urgencyToPriority } from '../../utils';

type Props = {
  open: boolean;
  task: Task;
  onClose: () => void;
};

export function EditTaskModal({ open, task, onClose }: Props) {
  const intl = useIntl();
  const config = getFormConfig(intl, task);
  const form = useFormFromConfig<FormFields>(config);
  const { toaster } = useToaster();

  const { isLoading: isSaving, mutate: updateTask } = useUpdateTask(task, {
    onError: () =>
      toaster.error(
        intl.formatMessage({ defaultMessage: 'Failed to update task' }),
      ),
    onSuccess: () => {
      toaster.success(intl.formatMessage({ defaultMessage: 'Task updated' }));
      onClose();
    },
  });
  const { isLoading: isCommentSaving, mutate: createComment } =
    useCreateComment(task.name);

  const onSubmit = (data: FormFields) => {
    updateTask({
      task: {
        scheduledTime: data.scheduled_time?.toISOString(),
        priority: urgencyToPriority(data.is_urgent),
        assignee:
          data.assigneeType === 'individual' && data.assignee
            ? { name: GRPC_RESOURCE_NAME.careProvider(data.assignee.id) }
            : undefined,
      },
    });
    if (data.note) {
      createComment({
        body: data.note,
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      // stops clicks from propagating to the underlying page and
      // opening random windows from clicking on tasks
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Modal.Header
        title={intl.formatMessage({ defaultMessage: 'Reassign Task' })}
      />
      <Modal.Body>
        <TaskForm form={form} onSubmit={onSubmit} task={task} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onPress={onClose}>
          <FormattedMessage defaultMessage="Cancel" />
        </Button>
        <Button
          variant="primary"
          type="submit"
          isProcessing={isSaving || isCommentSaving}
          isDisabled={!form.canSubmit}
          onPress={() => onSubmit(form.getValues())}
        >
          <FormattedMessage defaultMessage="Update Task" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
