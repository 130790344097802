import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { BillableTimeGuidanceLink } from '@/pages/patients/patientDetails/ui/shared';
import type { TimeTrackingFormFields } from '@/pages/patients/patientDetails/ui/tabs/TimeTracking/ManualTimeTrackingForm';
import {
  ManualTimeTrackingForm,
  useTimeTrackingFormConfig,
} from '@/pages/patients/patientDetails/ui/tabs/TimeTracking/ManualTimeTrackingForm';
import { Wizard } from '@/shared/common/Wizard';
import { getFullStepPath } from '@/shared/common/Wizard/path.utils';
import {
  useGetWizardStepValues,
  useWizardFormFromConfig,
  useWizardStateContext,
} from '@/shared/common/Wizard/state';
import { useOnMount } from '@/shared/hooks/useOnMount';
import { EntryType } from '@/shared/types/monitoringSession.types';

import { useCNFormContext } from '../../../CNFormContext';
import { PublishButton } from '../../../PublishButton';
import { type CNSectionMap, CN_SECTIONS_MAP } from '../../metadata';
import type { IntroFormFields } from '../IntroPatientAttendance';
import { container } from './SharedTimeTracking.css';

type Props = {
  sections: CNSectionMap;
  onAfterPublish?: (noteId: number) => void;
};

// TODO: we can drop this after the changes have been rolled out for a few days
const FIELD_NAME_MIGRATION: Record<string, string> = {
  interactive_duration: 'rpm_interactive_duration',
  non_interactive_duration: 'rpm_non_interactive_duration',
  tasks_accomplished: 'rpm_tasks_accomplished',
  other_task_description: 'rpm_other_task_description',
};

export function SharedTimeTracking({ sections, onAfterPublish }: Props) {
  const intl = useIntl();
  const { setIsPauseable, setTimerRunning } = useWizardStateContext();
  const { cnVisitType, patientId, noteId } = useCNFormContext();
  const patientAttendanceVals = useGetWizardStepValues(
    CN_SECTIONS_MAP[cnVisitType],
    '/intro',
    '/patient-attendance',
  )<IntroFormFields>();

  const patientNoShow = patientAttendanceVals?.patientAttendance === 'false';
  const config = useTimeTrackingFormConfig({
    action: 'create',
    timeEntry: null,
    entryType: EntryType.Encounter,
    patientNoShow,
  });
  const form = useWizardFormFromConfig<TimeTrackingFormFields>(
    getFullStepPath(sections, '/time-tracking', '/index'),
    config,
    (fieldName) => FIELD_NAME_MIGRATION[fieldName] || fieldName,
  );

  useOnMount(() => {
    setIsPauseable(true);
    setTimerRunning(false);
  });

  const interactiveDuration = form.watch('rpm_interactive_duration');
  useEffect(() => {
    // Can't have interactive minutes if patient no show, so reset the value to zero
    if (patientNoShow && interactiveDuration) {
      form.setValue('rpm_interactive_duration', 0);
    }
    // form is an unstable ref, so excluding it here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientNoShow, interactiveDuration]);

  return (
    <Wizard.Step
      sections={sections}
      title={intl.formatMessage({ defaultMessage: 'Time tracking' })}
      form={form}
      onNext={() => {
        // Noop, handled by publish button
      }}
      submitButton={({ ...submitProps }) => (
        <PublishButton {...submitProps} onAfterPublish={onAfterPublish} />
      )}
    >
      <div className={container}>
        <div>
          <ManualTimeTrackingForm
            action="create"
            patientId={patientId}
            noteId={noteId ?? undefined}
            patientNoShow={patientNoShow}
            entryType={EntryType.Encounter}
          />
        </div>
        <BillableTimeGuidanceLink />
      </div>
    </Wizard.Step>
  );
}
