import type { IntlShape } from 'react-intl';
import * as yup from 'yup';

import type { CyclicalDependencies } from '@/shared/common/Form/form.types';
import {
  CYCLICAL_DEPENDENCIES as ADDRESS_CYCLICAL_DEPENDENCIES,
  addressValidation,
} from '@/shared/common/FormSections/MailingAddress';
import type { AddressValues } from '@/shared/common/FormSections/MailingAddress/formUtils';

export function getAddressFormConfig(intl: IntlShape, address: AddressValues) {
  const validateAddress = addressValidation(intl);

  return {
    fields: {
      street_address: {
        defaultValue: address?.street_address || '',
        validation: validateAddress('street_address'),
      },
      premise: {
        defaultValue: address?.premise || '',
        validation: yup.string(),
      },
      postal_code: {
        defaultValue: address?.postal_code || '',
        validation: validateAddress('postal_code'),
      },
      locality: {
        defaultValue: address?.locality || '',
        validation: validateAddress('locality'),
      },
      region: {
        defaultValue: address?.region || '',
        validation: validateAddress('region'),
      },
    },
    cyclicalDependencies: [
      ...ADDRESS_CYCLICAL_DEPENDENCIES,
    ] as CyclicalDependencies,
  };
}
