import lowerFirst from 'lodash/lowerFirst';
import { type IntlShape, useIntl } from 'react-intl';

import { getCcmConditionI18n } from '@/pages/adminPanel/patient-profile/tabs/ClinicalProfileTab/clinicalprofile.utils';
import {
  CCMPrognosis,
  type CarePlan,
} from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import {
  type CCMGoal,
  GoalCategory,
  GoalStatus,
} from '@/shared/generated/grpc/go/pms/pkg/ccm_goal/ccm_goal.pb';
import type { Condition } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { convertToCcmCondition } from '@/shared/patient/conditions.utils';
import type { CcmCondition } from '@/shared/types/clinicalprofile.types';

import { useInProgressGoals } from '../../../Goals/goalUtils';
import { GoalMeasure } from '../../../Goals/goals.types';
import { isReviewGoalForCondition } from '../../dataTransformation';
import {
  getAutoCreatedGoalTitles,
  getRelatedGoalMeasure,
  hasRelatedGoal,
} from './goalUtils';

// Returns the initial Chronic Condition Management goals to create when a care plan is first created. These will be for conditions that are not in the top 3 conditions shown in the care plan
export function getInitialConditionGoals(
  skippedConditions: {
    condition: Condition;
    prognosis: CCMPrognosis;
  }[],
  intl: IntlShape,
) {
  const suboptimalGoals = skippedConditions
    .filter((c) => c.prognosis === CCMPrognosis.FAIR)
    .map((c) => buildReviewGoal(c.condition, intl))
    .filter((g): g is Goal => !!g);

  const unknownPrognosisGoals = skippedConditions
    .filter((c) => c.prognosis === CCMPrognosis.CCM_PROGNOSIS_UNSPECIFIED)
    .map((c) => buildUnknownPrognosisGoal(c.condition, intl))
    .filter((g): g is Goal => !!g);

  return [...(suboptimalGoals || []), ...(unknownPrognosisGoals || [])];
}

type Goal = {
  measure: GoalMeasure;
  title: string;
  description: string;
  status: GoalStatus;
  category: GoalCategory;
};
function buildUnknownPrognosisGoal(condition: Condition, intl: IntlShape) {
  const ccmCondition = convertToCcmCondition(condition);
  if (!ccmCondition) {
    return null;
  }

  const conditionLabel = getCcmConditionI18n(intl)[ccmCondition];

  return {
    title: intl.formatMessage(
      { defaultMessage: 'Review {condition} with patient' },
      { condition: conditionLabel },
    ),
    measure: GoalMeasure.ChronicConditions,
    description: intl.formatMessage({
      defaultMessage:
        'Will review with the patient next check-in to determine severity and potential follow-up goals',
    }),
    status: GoalStatus.IN_PROGRESS,
    category: GoalCategory.CONDITION_MANAGEMENT,
  };
}

export function buildReviewGoal(condition: Condition, intl: IntlShape) {
  const ccmCondition = convertToCcmCondition(condition);
  if (!ccmCondition) {
    return null;
  }

  return buildConditionReviewGoal(ccmCondition, intl);
}

export function buildConditionReviewGoal(
  ccmCondition: CcmCondition,
  intl: IntlShape,
) {
  const conditionLabel = getCcmConditionI18n(intl)[ccmCondition];
  const relatedGoalMeasure = getRelatedGoalMeasure(ccmCondition);

  // !hasRelatedGoal check used for type inference
  if (!relatedGoalMeasure || !hasRelatedGoal(ccmCondition)) {
    return {
      title: intl.formatMessage(
        { defaultMessage: 'Review {condition} goal setting with patient' },
        { condition: conditionLabel },
      ),
      measure: GoalMeasure.ChronicConditions,
      description: reviewText(intl),
      status: GoalStatus.IN_PROGRESS,
      category: GoalCategory.CONDITION_MANAGEMENT,
    };
  }

  const goalTitle = getAutoCreatedGoalTitles(intl)[ccmCondition];

  return {
    title: intl.formatMessage(
      { defaultMessage: 'Review {condition} goal to {goalTitle}' },
      { condition: conditionLabel, goalTitle: lowerFirst(goalTitle) },
    ),
    measure: relatedGoalMeasure,
    description: reviewText(intl),
    status: GoalStatus.IN_PROGRESS,
    category: GoalCategory.CONDITION_MANAGEMENT,
  };
}

export function reviewText(intl: IntlShape) {
  return intl.formatMessage({
    defaultMessage:
      'Have not reviewed with the patient yet, but will do so and determine follow-up goals in our next visit with them or at their request before that',
  });
}

export function useConditionReviewGoals(
  patientId: string,
  carePlan: Maybe<CarePlan>,
) {
  const goals = useInProgressGoals(patientId);
  const intl = useIntl();

  return goals?.filter(
    (goal) => goal.isConditionReview && !existsInCarePlan(goal, carePlan, intl),
  );
}

export function existsInCarePlan(
  goal: CCMGoal,
  carePlan: Maybe<CarePlan>,
  intl: IntlShape,
) {
  return carePlan?.goalsAndInterventions?.find(
    (g) => g.condition && isReviewGoalForCondition(goal, g.condition, intl),
  );
}

export function isConditionReviewGoal(goal: CCMGoal) {
  return (
    goal.title?.startsWith('Review') &&
    (goal.category === GoalCategory.CONDITION_MANAGEMENT ||
      goal.measure === GoalMeasure.ChronicConditions)
  );
}
