import { FormattedMessage, useIntl } from 'react-intl';

import { Box } from '@/deprecated/mui';
import { PatientProfileDialogTriggerButton } from '@/pages/patients/PatientProfile/PatientProfileDialogTriggerButton';
import { Button } from '@/shared/tempo/atom/Button';
import type { VitalsAlert } from '@/shared/types/alert.types';
import type { PatientVitals } from '@/shared/types/vitals.types';

import { MarkAlertAsReviewedDialog } from './MarkAlertAsReviewedDialog';
import { fullWidthRow } from './PatientAlertDetail.styles.css';

export const AlertDescriptionFooter = ({
  alert,
  vitals,
  isProfilePage,
  onMarkAsReviewed,
  onMarkAsResolved,
}: {
  alert: VitalsAlert;
  vitals: PatientVitals;
  isProfilePage: boolean;
  onMarkAsReviewed: (alert: VitalsAlert, closeDialog: () => void) => void;
  onMarkAsResolved?: (alert: VitalsAlert, vitals: PatientVitals) => void;
}) => {
  const intl = useIntl();

  return (
    <>
      {isProfilePage && (
        <Box sx={{ ...fullWidthRow, padding: '12px 0' }}>
          <PatientProfileDialogTriggerButton
            buttonInfo={{
              label: intl.formatMessage({
                defaultMessage: 'Mark as reviewed',
              }),
              variant: 'secondary',
            }}
          >
            {({ isDialogOpen, closeDialog }) => (
              <MarkAlertAsReviewedDialog
                isDialogOpen={isDialogOpen}
                closeDialog={closeDialog}
                onMarkAsReviewed={() => {
                  onMarkAsReviewed(alert, closeDialog);
                }}
              />
            )}
          </PatientProfileDialogTriggerButton>
          <Button
            id="resolveAlert"
            onPress={() => onMarkAsResolved?.(alert, vitals)}
          >
            <FormattedMessage defaultMessage="Resolve Alert" />
          </Button>
        </Box>
      )}
    </>
  );
};
