import type { ProgramTimeEntry } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';

import type { ProgramType } from '../generated/grpc/cadence/models/models.pb';

export enum EntryType {
  Manual = 'MANUAL',
  Encounter = 'ENCOUNTER',
}

export enum CommunicationType {
  PhoneCall = 'PHONE_CALL',
  Sms = 'SMS',
  PhoneAndSms = 'PHONE_CALL_AND_SMS',
  NoCommunication = 'NO_COMMUNICATION',
}

export type MonitoringSession = {
  care_provider_id: string;
  care_provider_name: string;
  communication_type: CommunicationType;
  created_at: string;
  created_by: string;
  end_datetime: string;
  id: string;
  live_communication: boolean;
  manual: boolean;
  npi: string;
  patient_id: string;
  start_datetime: string;
  timezone: string;
  type: EntryType;
  updated_at: string;
  updated_by: string;
  // Feature flagged values
  interactive_duration?: number;
  non_interactive_duration?: number;
  tasks_accomplished?: string[];
  other_task_description?: string;
  entries?: (ProgramTimeEntry & { program_type: ProgramType })[];
};
