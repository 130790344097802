import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import type {
  CCMCarePlan,
  CarePlan,
} from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import { GoalStatus } from '@/shared/generated/grpc/go/pms/pkg/ccm_goal/ccm_goal.pb';
import { useFlags } from '@/shared/hooks';
import { usePatientCcmGoals } from '@/shared/hooks/queries/ccmGoals.queries';
import { Button } from '@/shared/tempo/atom/Button';
import { useToaster } from '@/shared/tempo/molecule/Toast';

import { useValidateCarePlan } from '../../../../tabs/CarePlan/CarePlanForm/useValidateCarePlan';
import type { CarePlanInputs } from '../../../Notes.types';
import { EncounterModuleId } from '../../../Notes.types';
import { useUpdateInstance } from '../../hooks/useUpdateInstance';
import { carePlanDraftJsFieldTemplate } from '../../templates/carePlanTemplate';
import careGapTemplate from '../../templates/files/partials/careGaps.hbs';
import carePlanTemplate from '../../templates/files/partials/carePlan.hbs';
import goalsTemplate from '../../templates/files/partials/carePlanGoals.hbs';
import { useCarePlanGoalsTemplateContext } from './hooks/useCarePlanGoalsTemplateContext';
import { useCarePlanTemplateContext } from './hooks/useCarePlanTemplateContext';

type Props = {
  carePlan: Maybe<CCMCarePlan>;
  setCarePlanKey: (key: string) => void;
  draftUrl: string;
  isFetching: boolean;
};

const carePlanFieldName = 'care_plan_body';

export function CopyCarePlanButton({
  carePlan,
  setCarePlanKey,
  draftUrl,
  isFetching,
}: Props) {
  const getCarePlanTemplateOutput = useCarePlanTemplateOutput(
    carePlan?.carePlan,
  );
  const { toaster } = useToaster();
  const updateCarePlanInNote = useUpdateInstance(
    EncounterModuleId.CarePlan,
    getCarePlanTemplateOutput,
  );
  const intl = useIntl();
  const { isValid: isValidCarePlan } = useValidateCarePlan(
    !isFetching ? carePlan : null,
  );
  const history = useHistory();

  function handlePress() {
    if (!isValidCarePlan) {
      toaster.alert(
        intl.formatMessage({
          defaultMessage: 'Please complete the care plan before copying',
        }),
      );

      history.push({
        pathname: draftUrl,
        search: '?validate=true',
      });

      return;
    }

    const error = updateCarePlanInNote();
    if (error) {
      toaster.error(error);
      return;
    }

    // This is a hack to force the form to re-render
    setCarePlanKey(Math.random().toString());
  }

  return (
    <Button onPress={handlePress} variant="secondary">
      <FormattedMessage defaultMessage="Copy Care Plan" />
    </Button>
  );
}

function useCarePlanTemplateOutput(carePlan?: Maybe<CarePlan>) {
  const { carePlanOptimization } = useFlags();
  const intl = useIntl();
  const cPTemplateContext = useCarePlanTemplateContext(carePlan);
  const goalsTemplateContext = useCarePlanGoalsTemplateContext();
  const { patientId } = useParams<{ patientId: string }>();
  const { data: goals } = usePatientCcmGoals(patientId);

  return (inputs: CarePlanInputs) => {
    if (
      carePlanOptimization &&
      goals?.ccmGoals?.find((g) => g.status === GoalStatus.OPEN)
    ) {
      return {
        error: intl.formatMessage({
          defaultMessage:
            'There are goals that need review. Please change their status before copying the care plan',
        }),
      };
    }

    const noteBody = inputs[carePlanFieldName];

    const alreadyHasTemplate = noteBody?.blocks?.some(
      (block) =>
        block.text.includes('General Assessment') ||
        block.text.includes('Goals and Interventions') ||
        block.text.includes('Chronic Condition Management'),
    );

    if (alreadyHasTemplate) {
      return {
        error: intl.formatMessage({
          defaultMessage:
            'Note body already contains care plan text. Please remove the care plan text before copying again',
        }),
      };
    }

    const carePlanTemplateOutput =
      carePlanDraftJsFieldTemplate(carePlanTemplate)(cPTemplateContext);

    const careGapsTemplateOutput = carePlanOptimization
      ? carePlanDraftJsFieldTemplate(goalsTemplate)(goalsTemplateContext)
      : carePlanDraftJsFieldTemplate(careGapTemplate)(cPTemplateContext);

    const newBlocks = [
      ...(careGapsTemplateOutput.blocks || []),
      ...(noteBody?.blocks || []),
      ...(carePlanTemplateOutput.blocks || []),
    ];

    return {
      newInputs: {
        ...inputs,
        [carePlanFieldName]: {
          blocks: newBlocks,
          ...{ entityMap: inputs[carePlanFieldName]?.entityMap || {} },
        },
      } as CarePlanInputs,
    };
  };
}
