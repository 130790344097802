import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Form } from '@/shared/common/Form';
import { GoalsAndInterventionsHealthGoal } from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import { useFlags } from '@/shared/hooks';
import type { CcmCondition } from '@/shared/types/clinicalprofile.types';

import type { GoalMeasure } from '../../../Goals/goals.types';
import { type Goal } from '../../../Goals/goals.types';
import type { FormFields } from '../../formConfig';
import { GoalStatusButton } from '../shared/GoalStatusButton';
import { useGoalMutations } from '../shared/goalHooks';
import {
  getConditionPrefix,
  getUpdatedGoalDescription,
} from './goalDescriptionUtils';
import {
  constructRelatedGoal,
  hasRelatedGoal,
  useAutoCreatedGoalTitles,
} from './goalUtils';

type Props = {
  index: number;
  condition: CcmCondition;
  healthGoalEnum: Maybe<GoalsAndInterventionsHealthGoal>;
  openCreateGoalModal: (measure: GoalMeasure, description?: string) => void;
  openEditGoalModal: (goal: Goal) => void;
  carePlanId: string;
  relatedGoalMeasure: Maybe<GoalMeasure>;
  existingGoal: Maybe<Goal>;
};

export function ActionSteps({
  index,
  condition,
  healthGoalEnum,
  openCreateGoalModal,
  openEditGoalModal,
  carePlanId,
  relatedGoalMeasure,
  existingGoal,
}: Props) {
  const intl = useIntl();
  const { carePlanOptimization } = useFlags();
  const { patientId } = useParams<{ patientId: string }>();
  const form = useFormContext<FormFields>();

  const fieldName = `goalsAndInterventions.${index}.actionSteps`;
  const actionSteps =
    form.watch(`goalsAndInterventions.${index}.actionSteps`) || '';

  const { createGoal, updateGoalDescription } = useGoalMutations(patientId);
  const autoCreatedGoalTitles = useAutoCreatedGoalTitles();

  if (!carePlanOptimization) {
    return (
      <Form.TextArea
        required
        size={11}
        rows={2}
        label={intl.formatMessage({
          defaultMessage: 'Patient Action Steps',
        })}
        name={fieldName}
      />
    );
  }

  const conditionPrefix = getConditionPrefix(condition, intl);
  const newGoalDescription = `${conditionPrefix} ${actionSteps}`;

  function handleActionStepsBlur() {
    if (
      healthGoalEnum !== GoalsAndInterventionsHealthGoal.OBTAIN_GOAL ||
      !hasRelatedGoal(condition) ||
      !relatedGoalMeasure
    ) {
      return;
    }

    if (existingGoal) {
      // If (For {condition}) already exists in the description, replace the description following it
      // If it doesn't, append the new actionSteps to the end of the description
      const updatedDescription = getUpdatedGoalDescription(
        existingGoal,
        condition,
        intl,
        actionSteps,
      );
      updateGoalDescription(existingGoal, updatedDescription);
    } else {
      // Create a new goal with the description
      createGoal(
        constructRelatedGoal(
          autoCreatedGoalTitles[condition],
          relatedGoalMeasure,
          newGoalDescription,
        ),
        carePlanId,
      );
    }
  }

  return (
    <>
      <Form.TextArea
        required
        size={11}
        rows={2}
        label={intl.formatMessage({
          defaultMessage: 'Patient Action Steps',
        })}
        name={fieldName}
        onBlur={handleActionStepsBlur}
        endAdornment={
          // Only show related goal info if there is a related goal measure
          relatedGoalMeasure && (
            <GoalStatusButton
              carePlanId={carePlanId}
              existingGoal={existingGoal}
              onPressCreateGoal={() =>
                openCreateGoalModal(relatedGoalMeasure, newGoalDescription)
              }
              onPressEditGoal={openEditGoalModal}
            />
          )
        }
      />
    </>
  );
}
