import { FormattedMessage, useIntl } from 'react-intl';

import { Modal } from '@/shared/common/Modal';
import { PatientExternalProviderType } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { usePatientDetails } from '@/shared/hooks/queries';

import {
  BulletList,
  EnrollmentSection,
  NumberedList,
  RichText,
  Text,
} from './SectionHelpers';
import { VerifyAddress } from './VerifyAddress/VerifyAddress';

type Props = {
  patientId: string;
  isOpen: boolean;
  onClose: () => void;
};

export const RpmDualEnrollmentModal = ({
  patientId,
  isOpen,
  onClose,
}: Props) => {
  const intl = useIntl();
  const { data: patientDetailsGrpc, isLoading: isPatientDetailsGrpcLoading } =
    usePatientDetails(patientId, true);

  const { data: patientDetails, isLoading: isPatientDetailsLoading } =
    usePatientDetails(patientId, false);

  const patientName =
    patientDetailsGrpc?.patient?.givenName &&
    patientDetailsGrpc.patient.familyName
      ? `${patientDetailsGrpc.patient.givenName} ${patientDetailsGrpc.patient.familyName}`
      : intl.formatMessage({ defaultMessage: 'N/A' });

  const referringProvider = patientDetailsGrpc?.externalCareProviders?.find(
    (provider) =>
      provider.careProviderType === PatientExternalProviderType.REFERRING,
  )?.careProvider;

  const providerName =
    referringProvider?.givenName && referringProvider?.familyName
      ? `${referringProvider.givenName} ${referringProvider.familyName}`
      : intl.formatMessage({ defaultMessage: 'N/A' });

  const primaryInsurance =
    patientDetailsGrpc?.primaryInsurance?.replace('_', ' ') ??
    intl.formatMessage({ defaultMessage: 'N/A' });

  return (
    <Modal
      size="large"
      open={isOpen}
      onClose={onClose}
      isLoading={isPatientDetailsGrpcLoading || isPatientDetailsLoading}
    >
      <Modal.Header
        title={intl.formatMessage({
          defaultMessage: 'Remote Patient Monitoring Enrollment',
        })}
      />

      <Modal.Body>
        {/* Pitch Section */}
        <EnrollmentSection
          title={<FormattedMessage defaultMessage="1. Pitch" />}
        >
          <Text>
            <FormattedMessage
              defaultMessage="When you enroll, we'll mail you a device so you can take daily readings, which will be sent directly to {providerName} and your Cadence Care Team. We'll monitor these readings and reach out if we see any significant changes. Many patients appreciate the peace of mind and {providerName} finds extra data between office visits is very helpful. You'll also have regular phone appointments with your Care Team to review your readings and get guidance."
              values={{ providerName }}
            />
          </Text>
          <Text>
            <FormattedMessage defaultMessage="Does this sound good to you?" />
          </Text>
          <RichText>
            <FormattedMessage
              defaultMessage="If {yes}, proceed to verify shipping address and review insurance information."
              values={{ yes: <strong>YES</strong> }}
            />
          </RichText>
          <RichText>
            <FormattedMessage
              defaultMessage="If {no}, mark patient rejected below."
              values={{ no: <strong>NO</strong> }}
            />
          </RichText>
        </EnrollmentSection>

        {/* Qualification Section */}
        <EnrollmentSection
          title={
            <FormattedMessage defaultMessage="2. Confirm patient is qualified" />
          }
        >
          <Text>
            <FormattedMessage defaultMessage="If the patients answers yes to any of the following, decline" />
          </Text>
          <BulletList
            items={[
              <FormattedMessage defaultMessage="Are you currently using a CGM (dexcom, freestyle libre)?" />,
              <FormattedMessage defaultMessage="Are you able to stand on the scale and weight yourself daily?" />,
              <FormattedMessage defaultMessage="Are you currently on dialysis?" />,
            ]}
          />
        </EnrollmentSection>

        {/* Address Section */}
        {patientDetails && (
          <VerifyAddress
            patientDetails={patientDetails}
            isLoading={isPatientDetailsLoading}
            patientId={patientId}
          />
        )}

        {/* Insurance Section */}
        <EnrollmentSection
          title={<FormattedMessage defaultMessage="4. Review Insurance" />}
        >
          <RichText>
            <FormattedMessage
              defaultMessage="Let's review any potential cost based on your insurance coverage with {insuranceProvider}. Typically, if your copay with {provider} is $0, this program also has no cost. If you normally pay a copay, your cost for this program may range from your copay amount up to twice that amount. I am giving a range because the cost depends on how much time you spend with our Care Team, and varies from insurance to insurance."
              values={{
                insuranceProvider: <strong>{primaryInsurance}</strong>,
                provider: <strong>{providerName}</strong>,
              }}
            />
          </RichText>
          <Text>
            <FormattedMessage defaultMessage="Deductibles may apply. You can confirm exact costs directly with your insurance." />
          </Text>
        </EnrollmentSection>

        {/* Consent Section */}
        <EnrollmentSection
          title={<FormattedMessage defaultMessage="5. Capture consent" />}
        >
          <Text>
            <FormattedMessage defaultMessage="The last thing I need to do is read through a general consent statement. Please stop me if you have any questions." />
          </Text>
          <Text>
            <FormattedMessage defaultMessage="The program involves sharing your health data and communicating with us through calls, texts, and emails for treatment and care management. While we have security measures in place, texts and emails are not encrypted, and there could be a security risk. You can opt out of electronic communications anytime." />
          </Text>
          <Text>
            <FormattedMessage defaultMessage="We may share your health information as allowed by HIPAA but only as it relates to treatment, payment, or operations. To stay in the program, provide accurate health info, engage with us regularly, and be respectful to our staff. Depending on your insurance, you could have copays or deductibles. By participating, you authorize us to access and accept third-party benefits and agree to be financially responsible." />
          </Text>
          <Text>
            <FormattedMessage defaultMessage="You can leave the program anytime. We'll provide a full consent form and terms of service, and we're open to your feedback." />
          </Text>
          <NumberedList
            items={[
              <FormattedMessage
                defaultMessage="{patientName}: Do you have any questions about what I've just reviewed with you?"
                values={{
                  patientName: <strong>{patientName}</strong>,
                }}
              />,
              <FormattedMessage
                defaultMessage="Do you consent to treatment in {referringProvider}'s remote care program?"
                values={{
                  referringProvider: <strong>{providerName}</strong>,
                }}
              />,
            ]}
          />
          <RichText>
            <FormattedMessage
              defaultMessage="{caregiver}: By providing consent on the patient's behalf, you agree that you have the authority to do so."
              values={{
                caregiver: <em>If Caregiver</em>,
              }}
            />
          </RichText>
        </EnrollmentSection>
      </Modal.Body>
    </Modal>
  );
};
