import cx from 'classnames';
import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { FormattedMessage, useIntl } from 'react-intl';

import { Chevron, FileNew, Reschedule } from '@/shared/assets/svgs';
import type { AppointmentDetails } from '@/shared/generated/grpc/go/pms/pkg/scheduling/scheduling.pb';
import { useFlags } from '@/shared/hooks';
import {
  flexAlignItems,
  flexContainer,
  flexJustify,
} from '@/shared/jsStyle/flex.css';
import { gap } from '@/shared/jsStyle/gap.css';
import { Popover } from '@/shared/tempo/@labs/molecule/Popover';
import { Badge } from '@/shared/tempo/atom/Badge';
import { Button } from '@/shared/tempo/atom/Button';
import { IconButton } from '@/shared/tempo/atom/IconButton';
import { Tooltip } from '@/shared/tempo/atom/Tooltip';
import { color } from '@/shared/tempo/theme';

import { nextVisitPopoverTrigger } from './PatientScheduling.css';
import {
  apptRow,
  apptText,
  apptTimestamp,
  badge,
  innerApptRow,
  popover,
} from './UpcomingAppointments.css';
import { useIsRescheduleAllowed } from './useIsRescheduleAllowed';
import { getBadge } from './util';

type Props = {
  appointments: AppointmentDetails[];
  timezone: string;
  onCreateNote: (appointment: AppointmentDetails) => void;
  onReschedule: (appointment: AppointmentDetails) => void;
};

export function UpcomingAppointments({
  appointments,
  timezone,
  onCreateNote,
  onReschedule,
}: Props) {
  return (
    <Popover.Trigger
      placement="bottom left"
      classes={{ popover }}
      content={
        <div>
          {appointments.map((appt) => (
            <ApptRow
              key={appt.name}
              appt={appt}
              timezone={timezone}
              onCreateNote={() => onCreateNote(appt)}
              onReschedule={() => onReschedule(appt)}
            />
          ))}
        </div>
      }
    >
      <Button
        variant="tertiary"
        size="small"
        className={nextVisitPopoverTrigger}
      >
        <FormattedMessage
          defaultMessage="{apptCount} {apptCount, plural, =1 {appt} other {appts}}"
          values={{ apptCount: appointments.length }}
        />
        <Chevron fill={color.Theme.Light['Base Font']} />
      </Button>
    </Popover.Trigger>
  );
}

type ApptRowProps = {
  appt: AppointmentDetails;
  timezone: string;
  onCreateNote: () => void;
  onReschedule: () => void;
};

function ApptRow({ appt, timezone, onCreateNote, onReschedule }: ApptRowProps) {
  const intl = useIntl();
  const { smartRescheduling } = useFlags();
  const isRescheduleAllowed = useIsRescheduleAllowed(appt);
  const start = parseISO(appt.startTime ?? '');

  return (
    <div className={apptRow}>
      <div className={cx(apptTimestamp, innerApptRow)}>
        {formatInTimeZone(start, timezone, `MMMM d, yyyy 'at' h:mmaaa z`)}
      </div>
      <div
        className={cx(
          flexContainer.row,
          gap.XS,
          flexAlignItems.center,
          flexJustify.spaceBetween,
          innerApptRow,
        )}
      >
        <div className={cx(flexContainer.row, gap.XS)}>
          <Badge className={badge}>
            {getBadge(intl, appt.appointmentCanonicalName ?? '')}
          </Badge>
          <span className={apptText}>
            <FormattedMessage
              defaultMessage="{apptType}, {careProviderName}, {careProviderRole}"
              values={{
                apptType: appt.appointmentCanonicalName,
                careProviderName: `${appt.careProviderFirstName} ${appt.careProviderLastName}`,
                careProviderRole: appt.careProviderRole,
              }}
            />
          </span>
        </div>
        <div className={cx(flexContainer.row, gap.XS)}>
          <IconButton variant="secondary" size="small" onPress={onCreateNote}>
            <FileNew fill={color.Theme.Light.Primary} />
          </IconButton>
          {smartRescheduling && (
            <Tooltip
              isDisabled={isRescheduleAllowed}
              content={
                <FormattedMessage defaultMessage="Unsupported appointment type. Please reschedule manually in Acuity until supported." />
              }
            >
              <IconButton
                variant="secondary"
                size="small"
                onPress={onReschedule}
              >
                <Reschedule fill={color.Theme.Light.Primary} />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
}
