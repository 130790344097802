import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { CarePlanAlertBanner } from '@/pages/patients/patientDetails/ui/tabs/CarePlan/CarePlanAlertBanner';
import { marshalCarePlanToFormData } from '@/pages/patients/patientDetails/ui/tabs/CarePlan/CarePlanForm/dataTransformation';
import { useOnSave } from '@/pages/patients/patientDetails/ui/tabs/CarePlan/CarePlanForm/persistence';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import {
  PatientStatusEnum,
  ProgramType,
} from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { useFlags } from '@/shared/hooks';
import { usePatientDetails } from '@/shared/hooks/queries';
import { useCarePlanDraft } from '@/shared/hooks/queries/carePlan.queries';
import {
  useCreateCcmGoal,
  usePatientCcmGoals,
} from '@/shared/hooks/queries/ccmGoals.queries';
import { useCarePlanV1Changes } from '@/shared/hooks/useCarePlanV1Changes';
import { usePatientDemographics } from '@/shared/hooks/usePatientDemographics';
import { isParticipatingInProgramType } from '@/shared/patient/programs.utils';
import { Button } from '@/shared/tempo/atom/Button';
import { Link } from '@/shared/tempo/atom/Link';
import { Tooltip } from '@/shared/tempo/atom/Tooltip';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { grpcNameToId } from '@/shared/utils/grpc';

import { getInitialConditionGoals } from '../../../../tabs/CarePlan/CarePlanForm/sections/GoalsAndInterventions/conditionGoalUtils';
import { useInitialCarePlanConditions } from '../../../../tabs/CarePlan/CarePlanForm/sections/GoalsAndInterventions/hooks/useInitialCarePlanConditions.hook';
import { getInitialPreventativeCareGoals } from '../../../../tabs/CarePlan/CarePlanForm/sections/PreventativeCare/preventativeCare.utils';
import { getInitialTobaccoGoal } from '../../../../tabs/CarePlan/Goals/tobaccoUtils';
import { useIsCcmCarePlanEncounter } from '../../hooks/useEncounterModuleInstances.hook';
import { container, divider } from './CarePlanButton.css';
import { CopyCarePlanButton } from './CopyCarePlanButton';
import { ReassociateCarePlanModal } from './ReassociateCarePlanModal';

type Props = {
  noteId: Maybe<number>;
  setCarePlanKey: (key: string) => void;
};

export function CarePlanButton({ noteId, setCarePlanKey }: Props) {
  const { carePlanOptimization } = useFlags();
  const history = useHistory();
  const intl = useIntl();
  const [showReassignModal, setShowReassignModal] = useState(false);
  const { toaster } = useToaster();
  const { url, params } = useRouteMatch<{ patientId: string }>();
  const { data, isLoading, isFetching } = useCarePlanDraft(params.patientId);
  const hasDraft = !!data?.carePlan;
  const shouldShowCarePlanV1Changes = useCarePlanV1Changes(data?.carePlan);
  const { onSave, isSaving } = useOnSave(
    params.patientId,
    noteId,
    shouldShowCarePlanV1Changes,
  );
  const { data: existingGoalsData } = usePatientCcmGoals(params.patientId);

  const { data: patient, isLoading: isLoadingPatient } = usePatientDetails(
    params.patientId,
    true,
  );
  const { isParticipating: isCcmParticipating } = isParticipatingInProgramType(
    patient,
    ProgramType.CCM,
    { checkConsent: true },
  );
  const { isParticipating: isApcmParticipating } = isParticipatingInProgramType(
    patient,
    ProgramType.APCM,
    { checkConsent: true },
  );
  const isParticipatingInProgramWithCarePlan =
    isCcmParticipating || isApcmParticipating;

  const isPatientEnrolled =
    patient?.patient?.status === PatientStatusEnum.ENROLLED;

  const baseUrl = `${url}/care-plan`;
  const draftUrl = `${baseUrl}/draft`;

  const patientDemographics = usePatientDemographics(params.patientId);
  const { mutate: createGoal } = useCreateCcmGoal(params.patientId);

  const isCcmEncounter = useIsCcmCarePlanEncounter();

  const { skippedConditions } = useInitialCarePlanConditions(
    params.patientId,
    isCcmEncounter,
  );

  async function onPress() {
    if (hasDraft) {
      history.push(`${baseUrl}/${grpcNameToId(data?.name ?? '')}`);
    } else {
      // Create a new care plan and redirect to draft
      onSave(
        {},
        {
          onSuccess: async (resp) => {
            history.push(draftUrl);

            if (resp.carePlan && carePlanOptimization) {
              const preventativeCareGoals = getInitialPreventativeCareGoals(
                resp.carePlan,
                patientDemographics,
                intl,
              );
              const tobaccoGoal = getInitialTobaccoGoal(
                resp.carePlan.socialHistory,
                intl,
              );
              const conditionGoals = getInitialConditionGoals(
                skippedConditions,
                intl,
              );

              const initialGoals = [
                ...(preventativeCareGoals || []),
                ...(tobaccoGoal ? [tobaccoGoal] : []),
                ...(conditionGoals || []),
              ];

              let hasError = false;
              // Run goal creation in parallel
              await Promise.all(
                initialGoals?.map((goal) => {
                  if (!resp.name) {
                    return Promise.resolve();
                  }

                  // Check if a similar goal already exists
                  const goalExists = existingGoalsData?.ccmGoals?.some(
                    (existingGoal) =>
                      existingGoal.measure === goal.measure &&
                      existingGoal.title === goal.title,
                  );

                  if (!goalExists) {
                    return createGoal(
                      {
                        goal,
                        carePlanId: grpcNameToId(resp.name),
                      },
                      {
                        onError: () => {
                          hasError = true;
                        },
                      },
                    );
                  }
                  return Promise.resolve();
                }) ?? [],
              );

              if (hasError) {
                toaster.error(
                  intl.formatMessage({
                    defaultMessage:
                      'Failed to automatically create preventative care goals',
                  }),
                );
              }
            }
          },
        },
      );
    }
  }

  if (isLoading || isLoadingPatient) {
    return <LoadingPlaceholder isLoading />;
  }

  // In this case, there is a draft Care Plan associated with a previously published
  // encounter and so we want to warn the user
  if (hasDraft && data.noteId !== noteId) {
    return (
      <CarePlanAlertBanner
        title={
          <FormattedMessage defaultMessage="Please fix the following errors" />
        }
      >
        <FormattedMessage
          defaultMessage="The <PlanLink>Care Plan draft</PlanLink> is associated with another care plan encounter. Complete the current care plan draft to create a new care plan."
          values={{
            PlanLink: (text: string) => (
              <Link.Routed
                to={`${url}/care-plan/${grpcNameToId(data?.name ?? '')}`}
              >
                {text}
              </Link.Routed>
            ),
          }}
        />
        <div className={divider} />
        <FormattedMessage
          defaultMessage="If the care plan draft should be re-associated with the current care plan encounter (i.e. prior encounter was a no-show) please click <ReassociateButton>here.</ReassociateButton>"
          values={{
            ReassociateButton: (text: string) => (
              <Button
                variant="tertiary"
                size="small"
                onPress={() => setShowReassignModal(true)}
              >
                {text}
              </Button>
            ),
          }}
        />
        <ReassociateCarePlanModal
          isOpen={showReassignModal}
          onClose={() => setShowReassignModal(false)}
          onConfirm={() => {
            if (data?.carePlan) {
              onSave(
                marshalCarePlanToFormData(
                  data.carePlan,
                  shouldShowCarePlanV1Changes,
                  intl,
                ),
                {
                  onSuccess() {
                    setShowReassignModal(false);
                    toaster.success(
                      intl.formatMessage({
                        defaultMessage: 'Reassociated care plan draft',
                      }),
                    );
                  },
                },
              );
            }
          }}
        />
      </CarePlanAlertBanner>
    );
  }

  return (
    <div className={container}>
      <Tooltip
        isDisabled={
          (isPatientEnrolled && isParticipatingInProgramWithCarePlan) ||
          hasDraft
        }
        content={
          <FormattedMessage defaultMessage="Patient must be enrolled in either CCM (with conditions) or APCM, and have provided program consent." />
        }
      >
        <Button
          onPress={onPress}
          isProcessing={isSaving}
          isDisabled={
            !(isParticipatingInProgramWithCarePlan && isPatientEnrolled)
          }
        >
          {hasDraft ? (
            <FormattedMessage defaultMessage="View Care Plan" />
          ) : (
            <FormattedMessage defaultMessage="Create Care Plan" />
          )}
        </Button>
      </Tooltip>
      {data && (
        <CopyCarePlanButton
          carePlan={data}
          isFetching={isFetching}
          setCarePlanKey={setCarePlanKey}
          draftUrl={draftUrl}
        />
      )}
    </div>
  );
}
