import cx from 'classnames';
import { type ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import type { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import {
  AsyncTitrationAsyncTitrationStatus as AsyncTitrationStatus,
  type MedicationChange,
} from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { Tag } from '@/shared/tempo/atom/Tag';
import { useToaster } from '@/shared/tempo/molecule/Toast';

import { FormlessPatientTitrationRejectionReason } from '../PatientTitrationRejectionReason';
import type { TitrationRecommendation } from '../hooks/useTitrationRecommendation';
import { isAsyncTitrationPending } from '../statuses';
import { ApprovingProviderDetails } from './ApprovingProviderDetails';
import { ConditionalActions } from './ConditionalActions';
import { NotTitratingReasonForm } from './NotTitratingReasonForm';
import { Protocols } from './Protocols';
import {
  container,
  notTitrating,
  protocols,
  tag,
} from './SuggestedTitration.css';
import { TitrationTagText } from './TitrationTagText';
import { SuggestedTitrationMode } from './types';
import { useSuggestedTitrationState } from './useSuggestedTitrationState';

export type Props = {
  children: ReactNode;
  titrationRecommendation: TitrationRecommendation;
  currentMed: MedicationChange;
  readOnly: boolean;
  onEdit?: (proposedChange: MedicationChange) => void;
  onCancelEdit?: () => void;
  isEditing?: boolean;
  noteId?: Maybe<number>;
  referenceMedicationId?: string;
  typeOfEncounter?: TypeOfEncounter;
};

export function SuggestedTitration({
  children,
  titrationRecommendation,
  currentMed,
  noteId,
  onEdit,
  onCancelEdit,
  isEditing,
  readOnly,
  referenceMedicationId,
  typeOfEncounter,
}: Props) {
  const { toaster } = useToaster();
  const {
    mode,
    setMode,
    isSaving,
    onInitialReviewed,
    onPatientConsented,
    onPatientRejected,
    onRejectedOnInitialReview,
    onUndo,
    onCapturedPatientRejectionReason,
  } = useSuggestedTitrationState({
    noteId,
    titrationRecommendation,
    onModeChanged: () => onCancelEdit?.(),
    onMutationError: () =>
      toaster.error(
        <FormattedMessage defaultMessage="Failed to update titration recommendation." />,
      ),
  });
  const recommendedRxNorm = titrationRecommendation.rxNorm;

  if (!recommendedRxNorm) {
    return <>{children}</>;
  }

  const {
    status: recommendationStatus,
    approvingCareProvider,
    approvingCareProviderId,
    initialApprovedAt,
    patientId,
  } = titrationRecommendation;
  const isPending = isAsyncTitrationPending(titrationRecommendation, noteId);

  return (
    <div className={container}>
      <div>
        <Tag
          variant={isPending ? 'default' : 'info'}
          className={cx(tag.decision, { [tag.pending]: isPending })}
        >
          <TitrationTagText
            readOnly={readOnly}
            recommendation={titrationRecommendation}
            noteId={noteId}
          />
        </Tag>
        {children}
      </div>
      {!isEditing && (
        <>
          {recommendationStatus === AsyncTitrationStatus.NEW && (
            <div
              className={cx(protocols.wrapper, {
                [protocols.wrapperBorder]: !readOnly,
              })}
            >
              <Protocols
                titrationRecommendation={titrationRecommendation}
                recommendedRxNorm={recommendedRxNorm}
              />
            </div>
          )}
          {approvingCareProvider &&
            initialApprovedAt &&
            recommendationStatus !==
              AsyncTitrationStatus.REJECTED_ON_INITIAL_REVIEW && (
              <ApprovingProviderDetails
                patientId={patientId}
                providerId={approvingCareProviderId}
                provider={approvingCareProvider}
                approvalDate={initialApprovedAt}
                withBottomBorder={!readOnly}
              />
            )}
          <ConditionalActions
            titrationRecommendation={titrationRecommendation}
            currentMed={currentMed}
            isSaving={isSaving}
            mode={mode}
            onEdit={(change) => {
              setMode(SuggestedTitrationMode.Default);
              onEdit?.(change);
            }}
            onInitialApproved={onInitialReviewed}
            onInitialRejected={() =>
              setMode(SuggestedTitrationMode.NotTitrating)
            }
            onPatientConsented={onPatientConsented}
            onPatientRejected={onPatientRejected}
            onUndo={onUndo}
            readOnly={readOnly}
            referenceMedicationId={referenceMedicationId}
            typeOfEncounter={typeOfEncounter}
          />
        </>
      )}
      {mode === SuggestedTitrationMode.NotTitrating && (
        <div className={notTitrating.container}>
          <NotTitratingReasonForm
            onSubmit={onRejectedOnInitialReview}
            isDisabled={isSaving}
          />
        </div>
      )}
      {!readOnly &&
        recommendationStatus === AsyncTitrationStatus.PATIENT_REJECTED && (
          <div className={notTitrating.container}>
            <FormlessPatientTitrationRejectionReason
              onChange={onCapturedPatientRejectionReason}
            />
          </div>
        )}
    </div>
  );
}
