import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { FormLabel, TextField } from '@/deprecated/mui';

import { useNoteEditorContext } from '../NoteEditorContext';
import { ErrorMessage } from './ErrorMessage';
import {
  addNoteFormElemContainer,
  encounterTypeFormContainer,
  errorLabel,
  sectionLabel,
} from './NoteEditor.css';
import { EncounterTypeForm } from './TypeOfEncounterForm';
import { VisitLayout } from './VisitLayout';
import { useInjectTemplates } from './hooks/useInjectTemplates.hooks';
import type {
  NoteFormExtras,
  NoteFormValues,
  SetNoteFormValue,
} from './noteFormState';
import type {
  NoteFormSubmissionType,
  NoteFormValidationResult,
} from './validation';

export function NoteEditorForm({
  noteFormValues,
  setNoteFormValue,
  noteFormExtras,
  noteFormValidationResult,
  noteFormSubmissionType,
}: {
  noteFormValues: NoteFormValues;
  setNoteFormValue: SetNoteFormValue;
  noteFormExtras: NoteFormExtras;
  noteFormValidationResult: NoteFormValidationResult;
  noteFormSubmissionType: NoteFormSubmissionType;
}) {
  const { patientId } = useParams<{ patientId: string }>();
  const { editingNote } = useNoteEditorContext();

  const { injectTemplates } = useInjectTemplates();

  const intl = useIntl();

  const titleError = noteFormValidationResult.title.length > 0;
  const titleHasRequiredError = Boolean(
    noteFormValidationResult.title.find((result) => result.type === 'required'),
  );

  return (
    <div className={addNoteFormElemContainer}>
      <div>
        <FormLabel className={titleError ? errorLabel : sectionLabel}>
          <FormattedMessage defaultMessage="Title *" />
        </FormLabel>
        <TextField
          name="title"
          value={noteFormValues.title}
          onChange={(event) => setNoteFormValue('title', event.target.value)}
          placeholder={intl.formatMessage({ defaultMessage: 'Title' })}
          fullWidth
          required
          error={titleError}
        />
        <ErrorMessage hasError={titleError && titleHasRequiredError} />
      </div>
      <div className={encounterTypeFormContainer}>
        <EncounterTypeForm
          timeEntry={noteFormValues.timeEntry}
          initialValues={noteFormExtras.encounterTypeInstance?.inputs}
          onChange={noteFormExtras.setEncounterModuleInstance}
          onEncounterTypeConfirmChange={(
            encounterType,
            templateContext,
            endFormValues,
          ) => {
            injectTemplates(encounterType, templateContext, endFormValues);
            // ensures that endEncounter is updated in the event the encounter type changes
            // from scheduled to unscheduled or vice versa
            setNoteFormValue('endEncounter', endFormValues);
          }}
          shouldShowValidation={Boolean(
            noteFormValidationResult.encounterModuleInstances.length,
          )}
        />
      </div>

      <VisitLayout
        encounterTypeInstance={noteFormExtras.encounterTypeInstance}
        noteFormExtras={noteFormExtras}
        validationResult={noteFormValidationResult}
        noteFormValues={noteFormValues}
        setNoteFormValue={setNoteFormValue}
        noteFormSubmissionType={noteFormSubmissionType}
        patientId={patientId}
        noteId={editingNote?.note?.id}
      />
    </div>
  );
}
