import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import states from '@/shared/assets/states.json';
import { Form } from '@/shared/common/Form';

import type { AddressValues } from './formUtils';
import { isAddressFieldRequired } from './formUtils';

type Props = {
  addressValues: {
    street_address: string;
    premise: string;
    locality: string;
    region: string;
    postal_code: string;
  };
  showWrapper?: boolean;
  isCyclicalDependent?: boolean;
};

export function MailingAddress({
  addressValues,
  showWrapper = true,
  isCyclicalDependent = true,
}: Props) {
  const intl = useIntl();
  const form = useFormContext<AddressValues>();
  const triggerAddressFields = useCallback(
    (value: string) => {
      // make sure we reset validation state if we're clearing out a value
      if (form.formState.isDirty && !value) {
        form.trigger([
          'street_address',
          'premise',
          'locality',
          'region',
          'postal_code',
        ]);
      }
    },
    [form],
  );

  return showWrapper ? (
    <Form.CardSection
      title={intl.formatMessage({
        defaultMessage: 'Mailing Address',
      })}
    >
      <AddressSection
        triggerAddressFields={triggerAddressFields}
        addressValues={addressValues}
        isCyclicalDependent={isCyclicalDependent}
      />
    </Form.CardSection>
  ) : (
    <AddressSection
      triggerAddressFields={triggerAddressFields}
      addressValues={addressValues}
      isCyclicalDependent={isCyclicalDependent}
    />
  );
}

const AddressSection = ({
  triggerAddressFields,
  addressValues,
  isCyclicalDependent = true,
}: {
  triggerAddressFields: (value: string) => void;
  addressValues: AddressValues;
  isCyclicalDependent?: boolean;
}) => {
  const intl = useIntl();
  return (
    <>
      <Form.Row>
        <Form.TextField
          name="street_address"
          label={<FormattedMessage defaultMessage="Address" />}
          size={4}
          {...(isCyclicalDependent && {
            required: isAddressFieldRequired('street_address', addressValues),
            onChange: triggerAddressFields,
          })}
        />
        <Form.TextField
          name="premise"
          label={<FormattedMessage defaultMessage="Apt/Suite/Other" />}
          size={4}
          {...(isCyclicalDependent && {
            onChange: triggerAddressFields,
          })}
        />
      </Form.Row>
      <Form.Row>
        <Form.TextField
          name="locality"
          label={<FormattedMessage defaultMessage="City" />}
          size={4}
          {...(isCyclicalDependent && {
            required: isAddressFieldRequired('locality', addressValues),
            onChange: triggerAddressFields,
          })}
        />
        <Form.DeprecatedSelect
          name="region"
          label={<FormattedMessage defaultMessage="State" />}
          size={4}
          {...(isCyclicalDependent && {
            required: isAddressFieldRequired('region', addressValues),
            onChange: triggerAddressFields,
          })}
        >
          <option disabled value="">
            {intl.formatMessage({ defaultMessage: 'Select state' })}
          </option>
          {states.map((state) => (
            <option key={state.abbreviation} value={state.abbreviation}>
              {state.name}
            </option>
          ))}
        </Form.DeprecatedSelect>
        <Form.TextField
          name="postal_code"
          label={<FormattedMessage defaultMessage="Zip Code" />}
          size={4}
          {...(isCyclicalDependent && {
            required: isAddressFieldRequired('postal_code', addressValues),
            onChange: triggerAddressFields,
          })}
        />
      </Form.Row>
    </>
  );
};
