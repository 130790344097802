import { useParams } from 'react-router-dom';

import type { CcmCondition } from '@/shared/types/clinicalprofile.types';
import type { RouteParam } from '@/shared/types/route.types';

import {
  fieldContainer,
  fieldLabel,
  fieldValue,
} from './GoalsAndInterventions.css';
import { useFormattedConditionVitals } from './hooks/useFormattedConditionVitals';

type Props = {
  condition: CcmCondition;
};
export function ConditionPrognosisVitals({ condition }: Props) {
  const { patientId }: RouteParam = useParams();
  const formattedVitals = useFormattedConditionVitals(patientId);

  if (!condition) return null;

  const { label, value } = formattedVitals(condition);

  if (label && !value) {
    return (
      <div className={fieldContainer}>
        <div className={fieldLabel}>{label}:</div>
        <div className={fieldValue}>-</div>
      </div>
    );
  }

  if (label && value) {
    return (
      <div className={fieldContainer}>
        <div className={fieldLabel}>{label}:</div>
        <div className={fieldValue}>{value}</div>
      </div>
    );
  }

  return null;
}
