import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { useCallback } from 'react';

import { logger } from '@/logger';
import type { EncounterModuleId } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { ProgramType } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { useFlags } from '@/shared/hooks';
import type { RpmCondition } from '@/shared/types/clinicalprofile.types';
import { Condition } from '@/shared/types/clinicalprofile.types';
import { ConditionProgram } from '@/shared/types/condition.types';
import type { FeatureFlagSet } from '@/shared/types/featureFlags.types';

import type { EndFormValues } from '../../noteFormState';
import { injectContext } from '../context.utils';
import { getSmartTemplatesMap } from '../templates';
import type { TemplateContext } from '../types';

export function useGetFieldTemplateMap() {
  const flags = useFlags();
  return useCallback(
    (
      encounterType: TypeOfEncounter | undefined,
      moduleId: EncounterModuleId,
      context: TemplateContext,
      endEncounter: EndFormValues | undefined,
    ) => {
      const moduleTemplate = getFieldTemplateMap(
        encounterType,
        moduleId,
        context,
        flags,
      );

      if (has(moduleTemplate, 'endEarly')) {
        // it's important to check if there are end-early templates
        // defined, even if the end encounter/reason isn't set.
        //
        // if there are end-early templates, we need to "clear/omit"
        // them prior to returning the evaluated templates.
        // additional properties on the modules may break validation
        // during encounter submission/publshing.
        const defaultTemplate = omit(moduleTemplate, 'endEarly');
        if (endEncounter?.endReason) {
          return (
            getEndEarlyTemplate(moduleTemplate, endEncounter.endReason) ||
            defaultTemplate
          );
        }
        return defaultTemplate;
      }

      return moduleTemplate;
    },
    [flags],
  );
}

export function getFieldTemplateMap(
  encounterType: TypeOfEncounter | undefined,
  moduleId: EncounterModuleId,
  context: TemplateContext,
  flags: FeatureFlagSet,
) {
  if (!encounterType) {
    return {};
  }

  const contextualTemplateMap = getTemplateMap(encounterType, context, flags);
  const program = conditionsToProgram(context.rpmConditions);
  const hasCcmProgramType = context.programTypes?.includes(ProgramType.CCM);
  const hasApcmProgramType = context.programTypes?.includes(ProgramType.APCM);

  const getTemplateForConditionProgram = (
    programKey: Nullable<'default' | ConditionProgram>,
  ) =>
    get(
      contextualTemplateMap,
      [flags.careModelVersion, encounterType, programKey || '', moduleId],
      {},
    ) as Record<string, unknown>;

  // Disenrolled patients have a null program, but we still
  // want to be able to render the disenrollment template.
  if (!program && encounterType === TypeOfEncounter.DISENROLLMENT) {
    return getTemplateForConditionProgram('default');
  }

  // Favor template-mapping using RPM condition program type, then by CCM, then by APCM
  const rpmConditionFieldTemplateMap = getTemplateForConditionProgram(program);
  if (!isEmpty(rpmConditionFieldTemplateMap)) {
    return rpmConditionFieldTemplateMap;
  }

  if (hasCcmProgramType) {
    return getTemplateForConditionProgram(ConditionProgram.CCM);
  }

  if (hasApcmProgramType) {
    return getTemplateForConditionProgram(ConditionProgram.APCM);
  }

  return {};
}

// getEndEarlyTemplate will retrieve either the reason-specific or default end-early template
// if defined for a module. if neither are defined, return will be null.
export function getEndEarlyTemplate(
  template: Record<string, unknown>,
  endReason: string,
): Nullable<Record<string, unknown>> {
  if (endReason) {
    const reasonSpecificTemplate = get(template, ['endEarly', endReason]);
    if (reasonSpecificTemplate) {
      return reasonSpecificTemplate as Record<string, unknown>;
    }
  }

  const defaultTemplate = get(template, 'endEarly.default');
  if (defaultTemplate) {
    return defaultTemplate as Record<string, unknown>;
  }

  return null;
}

/**
 * Returns the full smart template map
 * injected with context data fetched from the API.
 */
function getTemplateMap(
  encounterType: TypeOfEncounter,
  context: TemplateContext,
  flags: FeatureFlagSet,
) {
  // if we don't have encounterType in context, or it's different from the encounterType passed in, update context
  // this is a hacky implementation to ensure that the encounter type referenced in templates (via context) is set.
  // the useTemplateContext hook cannot set encounterType via NoteStateContext because of it's usage in the Appointment sidebar.
  // Without syncing too much time into a refactor, this is a quick fix to ensure it's set appropriately.
  if (!context.encounterType || context.encounterType !== encounterType) {
    context.encounterType = encounterType;
  }
  return injectContext(getSmartTemplatesMap(flags), context);
}

export function conditionsToProgram(
  conditions: RpmCondition[] = [],
): Nullable<ConditionProgram> {
  if (conditions.length === 0) {
    return null;
  }

  if (
    rpmConditionsEqual(conditions, [
      Condition.Hypertension,
      Condition.TypeTwoDiabetes,
    ])
  ) {
    return ConditionProgram.T2DAndHTN;
  }
  if (rpmConditionsEqual(conditions, [Condition.CHF])) {
    return ConditionProgram.CHF;
  }
  if (rpmConditionsEqual(conditions, [Condition.COPD])) {
    return ConditionProgram.COPD;
  }
  if (rpmConditionsEqual(conditions, [Condition.Hypertension])) {
    return ConditionProgram.Hypertension;
  }
  if (rpmConditionsEqual(conditions, [Condition.TypeTwoDiabetes])) {
    return ConditionProgram.TypeTwoDiabetes;
  }

  logger.warn(
    `Could not map conditions: [${conditions.join(
      ',',
    )}] to a condition program.`,
  );
  return null;
}

function rpmConditionsEqual(argA: RpmCondition[], argB: RpmCondition[]) {
  return isEqual(argA.sort(), argB.sort());
}
