import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';

import { logger } from '@/logger';
import {
  appointmentKeys,
  useGetNextRecommendedAppointment,
} from '@/pages/patients/PatientProfile/PatientScheduling/appointments.queries';
import { Form } from '@/shared/common/Form';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { Modal } from '@/shared/common/Modal';
import { ProgramType } from '@/shared/generated/grpc/cadence/models/models.pb';
import type { NextAppointmentRecommendation } from '@/shared/generated/grpc/go/pms/pkg/scheduling/scheduling.pb';
import { useFlags } from '@/shared/hooks';
import { useCareProviders, useCaregivers } from '@/shared/hooks/queries';
import { Button } from '@/shared/tempo/atom/Button';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { type Patient, PatientStatus } from '@/shared/types/patient.types';
import {
  extractPhoneNumberFromContact,
  getPreferredPhoneContact,
} from '@/shared/utils/contact.utils';

import { useFormFromConfig } from '../Form/FormContainer';
import { footer } from './SchedulePatientModal.css';
import { Scheduler } from './Scheduler';
import { SelfServeSchedulerModal } from './SelfServeSchedulerModal';
import { SmartScheduler } from './SmartScheduler';
import { useAcuityIframeUrlParams } from './acuityIframeUrl.utils';
import {
  isCarePlanVisit,
  isInitialCNAppointment,
  isInitialNPAppointment,
  isRegularAPCMVisit,
  isRegularCNAppointment,
  isRegularNPAppointment,
} from './appointment.utils';
import { ApptsFilter } from './types';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  patientDetails: Patient;
  forceExistingAppointmentRecommendation: boolean;
  isAdminPanel: boolean;
};

export function SchedulePatientModal({
  isOpen,
  onClose,
  patientDetails,
  forceExistingAppointmentRecommendation,
  isAdminPanel,
}: Props) {
  const intl = useIntl();
  const { toaster } = useToaster();
  const queryClient = useQueryClient();
  const { showCreateAppointmentLink, enableAppointmentSelection } = useFlags();
  const [filterType, setFilterType] = useState<ApptsFilter>(
    ApptsFilter.SHOW_ALL,
  );
  const availablePrograms = useMemo(() => {
    const programStatuses = patientDetails.program_statuses || [];

    const programs = programStatuses
      .filter((p) => {
        if (isAdminPanel) {
          // Admin panel - check for ReadyToEnroll programs
          // For RPM, check program status if patient is ENROLLED, otherwise check top-level status
          if (p.program_type === ProgramType.RPM) {
            if (patientDetails.status === PatientStatus.Enrolled) {
              return p.status === PatientStatus.ReadyToEnroll;
            }
            return patientDetails.status === PatientStatus.ReadyToEnroll;
          }
          // For other programs, check program_statuses for ReadyToEnroll
          return (
            p.status === PatientStatus.ReadyToEnroll &&
            p.program_type !== undefined
          );
        }
        // Non-admin - check for Enrolled programs
        return (
          p.status === PatientStatus.Enrolled && p.program_type !== undefined
        );
      })
      .map((p) => p.program_type as ProgramType);

    return programs;
  }, [patientDetails.program_statuses, patientDetails.status, isAdminPanel]);

  const form = useFormFromConfig<{ programType: ProgramType }>({
    fields: {
      programType: {
        defaultValue: availablePrograms[0],
      },
    },
  });
  const programType = form.watch('programType') ?? availablePrograms[0];
  const npiId = patientDetails.npi_id || undefined;
  const { data: careProviderList, isLoading: isLoadingCareProviders } =
    useCareProviders({ npiId }, { enabled: Boolean(npiId) && isOpen });

  const careProvider = careProviderList?.care_providers?.[0];
  const careProviderName = careProvider
    ? `${careProvider.first_name} ${careProvider.last_name}, ${careProvider.role}`
    : '';

  const { isLoading: isLoadingCaregivers, data: caregivers } = useCaregivers(
    patientDetails.id || '',
    // The has_caregiver field means we want to use the caregiver contact as the primary contact method
    //  so we need to fetch it
    { enabled: patientDetails.has_caregiver },
  );

  const preferredPhoneContact = getPreferredPhoneContact(
    patientDetails,
    caregivers || [],
  );

  const patientId = patientDetails.id || '';
  const {
    isLoading: isLoadingRecommendedAppt,
    data: recommendedAppt,
    refetch: refetchRecommendedAppt,
  } = useGetNextRecommendedAppointment(
    patientId,
    programType,
    forceExistingAppointmentRecommendation,
    {
      enabled: isOpen,
      onError: () => {
        toaster.error(
          intl.formatMessage({
            defaultMessage: 'Failed to fetch next appointment recommendation',
          }),
        );
      },
    },
  );

  useEffect(() => {
    if (!isLoadingRecommendedAppt && isOpen) {
      setFilterType(
        recommendedAppt ? ApptsFilter.SHOW_SUGGESTED : ApptsFilter.SHOW_ALL,
      );
    }
  }, [isOpen, isLoadingRecommendedAppt, recommendedAppt]);

  const { phone: preferredContactPhone } = extractPhoneNumberFromContact(
    preferredPhoneContact,
  );

  const acuityIframeUrlParams = useAcuityIframeUrlParams({
    patientDetails,
    recommendedAppt,
    apptsFilter: filterType,
    careProviderName,
    patientOrCaregiverPhone: preferredContactPhone || '',
  });

  const isLoading = isLoadingCareProviders || isLoadingCaregivers;
  const showSmartScheduler =
    useShouldShowSmartScheduler(patientDetails, recommendedAppt) &&
    // Added just for type inference
    recommendedAppt;

  const shouldShowProgramSelector = useMemo(
    () => availablePrograms.length > 1 && enableAppointmentSelection,
    [availablePrograms, enableAppointmentSelection],
  );

  return (
    <>
      {showCreateAppointmentLink ? (
        <SelfServeSchedulerModal
          isOpen={isOpen}
          onClose={onClose}
          patientId={patientId}
        />
      ) : (
        <Modal
          open={isOpen}
          onClose={() => {
            onClose();
            // Invalidate the next scheduled appt. query. Assuming that a new appt. was
            // likely created and/or that it's generally helpful to refetch on close
            queryClient.invalidateQueries(
              appointmentKeys.nextScheduled(patientId),
            );
          }}
          size="large"
          isLoading={isLoading}
        >
          <Modal.Header
            title={<FormattedMessage defaultMessage="Schedule Patient" />}
          />
          <Modal.Body>
            <Form form={form}>
              {shouldShowProgramSelector && (
                <Form.Row>
                  <Form.RadioGroup
                    orientation="horizontal"
                    size={12}
                    label={
                      <FormattedMessage defaultMessage="Please select which program you would like to schedule an appointment for." />
                    }
                    name="programType"
                    onChange={(value: ProgramType) => {
                      if (value) {
                        setFilterType(ApptsFilter.SHOW_ALL);
                        refetchRecommendedAppt();
                      }
                    }}
                  >
                    {availablePrograms.map((program) => (
                      <Form.Radio key={program} value={program}>
                        {program === ProgramType.RPM && (
                          <FormattedMessage defaultMessage="RPM" />
                        )}
                        {program === ProgramType.CCM && (
                          <FormattedMessage defaultMessage="CCM" />
                        )}
                        {program === ProgramType.APCM && (
                          <FormattedMessage defaultMessage="APCM" />
                        )}
                      </Form.Radio>
                    ))}
                  </Form.RadioGroup>
                </Form.Row>
              )}
              {isLoadingRecommendedAppt && <LoadingPlaceholder isLoading />}
              {!isLoadingRecommendedAppt &&
                (showSmartScheduler ? (
                  <SmartScheduler
                    onCancel={onClose}
                    patient={patientDetails}
                    filterType={filterType}
                    iframeParams={acuityIframeUrlParams}
                    recommendedAppt={recommendedAppt}
                  />
                ) : (
                  <Scheduler
                    patientId={patientId}
                    filterType={filterType}
                    iframeParams={acuityIframeUrlParams}
                    recommendedAppt={recommendedAppt}
                  />
                ))}
            </Form>
          </Modal.Body>
          <Modal.Footer className={footer}>
            {recommendedAppt && (
              <Button
                variant="tertiary"
                onPress={() =>
                  setFilterType(
                    filterType === ApptsFilter.SHOW_ALL
                      ? ApptsFilter.SHOW_SUGGESTED
                      : ApptsFilter.SHOW_ALL,
                  )
                }
              >
                {filterType === ApptsFilter.SHOW_ALL ? (
                  <FormattedMessage defaultMessage="View Suggested Options" />
                ) : (
                  <FormattedMessage defaultMessage="View All Options" />
                )}
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

function useShouldShowSmartScheduler(
  patient: Patient,
  recommendedAppt: Maybe<NextAppointmentRecommendation>,
) {
  const {
    smartSchedulingInitialVisit,
    smartSchedulingRegularVisit,
    smartSchedulingInitialNpVisit,
    smartSchedulingRegularNpVisit,
    smartSchedulingRte,
  } = useFlags();
  return useMemo(() => {
    if (!smartSchedulingInitialVisit) {
      return false;
    }
    if (!recommendedAppt?.appointmentTypeAcuityId) {
      logger.info(
        'SmartScheduler is disabled: No recommendation appointmentTypeAcuityId',
      );
      return false;
    }
    if (
      !(
        isInitialCNAppointment(recommendedAppt?.appointmentTypeName) ||
        (isRegularCNAppointment(recommendedAppt?.appointmentTypeName) &&
          smartSchedulingRegularVisit) ||
        (isRegularNPAppointment(recommendedAppt?.appointmentTypeName) &&
          smartSchedulingRegularNpVisit) ||
        (isInitialNPAppointment(recommendedAppt?.appointmentTypeName) &&
          smartSchedulingInitialNpVisit) ||
        isCarePlanVisit(recommendedAppt?.appointmentTypeName) ||
        isRegularAPCMVisit(recommendedAppt?.appointmentTypeName)
      )
    ) {
      logger.info(
        `SmartScheduler is disabled for recommended appointment type: ${recommendedAppt?.appointmentTypeName}. Flags: initial=${smartSchedulingInitialVisit}, regular=${smartSchedulingRegularVisit}, regularNP=${smartSchedulingRegularNpVisit}`,
      );
      return false;
    }
    const validStatuses = [
      PatientStatus.Enrolled,
      smartSchedulingRte ? PatientStatus.ReadyToEnroll : undefined,
    ].filter((status) => status !== undefined);

    const rtePrograms = patient.program_statuses?.filter(
      (p) => p.status === PatientStatus.ReadyToEnroll,
    );
    if (!validStatuses.includes(patient.status) && rtePrograms?.length === 0) {
      logger.info(
        `SmartScheduler is disabled: Patient is not in ${validStatuses.join(
          ' or ',
        )} status: (${patient.status}) and no RTE programs`,
      );
      return false;
    }
    logger.info('SmartScheduler is enabled');
    return true;
  }, [
    patient.status,
    patient.program_statuses,
    recommendedAppt?.appointmentTypeAcuityId,
    recommendedAppt?.appointmentTypeName,
    smartSchedulingInitialVisit,
    smartSchedulingRegularVisit,
    smartSchedulingRegularNpVisit,
    smartSchedulingInitialNpVisit,
    smartSchedulingRte,
  ]);
}
