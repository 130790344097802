import { useState } from 'react';

import { AsyncTitrationAsyncTitrationStatus as AsyncTitrationStatus } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import type {
  AsyncTitrationMedicationWasNotTitratedReason,
  AsyncTitrationPatientRejectedTitrationReason,
} from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import {
  useRevertAsyncTitrationForNote,
  useUpdateAsyncTitration,
} from '@/shared/hooks/queries/medication.queries';

import type { TitrationRecommendation } from '../hooks';
import { SuggestedTitrationMode } from './types';

type Props = {
  noteId: Maybe<number>;
  onMutationError: () => void;
  onModeChanged: (mode: SuggestedTitrationMode) => void;
  titrationRecommendation: TitrationRecommendation;
};

export function useSuggestedTitrationState({
  noteId,
  onModeChanged,
  onMutationError,
  titrationRecommendation,
}: Props) {
  const [mode, setMode] = useState(SuggestedTitrationMode.Default);
  const {
    mutate: mutateRecommendation,
    isLoading: isMutateRecommendationSaving,
  } = useUpdateAsyncTitration(
    titrationRecommendation.patientId ?? '',
    titrationRecommendation.id ?? '',
    {
      onSettled: () => {
        // practically speaking, this doesn't matter for success since the
        // titration recommendation will be in a state where the mode doesn't
        // have any effect on what we're displaying to the user. that said,
        // i think it's better to make sure we're in a default state
        setMode(SuggestedTitrationMode.Default);
      },
      onError: onMutationError,
    },
  );
  const { mutate: mutateRevert, isLoading: isRevertSaving } =
    useRevertAsyncTitrationForNote(titrationRecommendation.patientId ?? '');
  const updateRecommendation = (
    status: AsyncTitrationStatus,
    reason?: AsyncTitrationMedicationWasNotTitratedReason,
    patientReason?: AsyncTitrationPatientRejectedTitrationReason,
    rejectionDetails?: string,
  ) => {
    mutateRecommendation({
      status,
      ...(status === AsyncTitrationStatus.INITIALLY_REVIEWED && {
        initialReviewNoteId: noteId ?? 0,
      }),
      ...(status === AsyncTitrationStatus.REJECTED_ON_INITIAL_REVIEW && {
        medicationWasNotTitratedReason: reason,
        initialReviewNoteId: noteId ?? 0,
        medicationWasNotTitratedDetails: rejectionDetails,
      }),
      ...([
        AsyncTitrationStatus.PATIENT_CONSENTED,
        AsyncTitrationStatus.PATIENT_REJECTED,
      ].includes(status) && {
        consentRequestNoteId: noteId ?? 0,
        patientRejectedTitrationReason: patientReason,
      }),
    });
  };

  const revertRecommendationChange = () => {
    mutateRevert({
      noteId: noteId ?? undefined,
      asyncTitrationId: titrationRecommendation.id,
    });
  };

  const onInitialReviewed = () => {
    setMode(SuggestedTitrationMode.Accepted);
    updateRecommendation(AsyncTitrationStatus.INITIALLY_REVIEWED);
  };

  const onRejectedOnInitialReview = (
    reason: AsyncTitrationMedicationWasNotTitratedReason,
    description: string,
  ) => {
    updateRecommendation(
      AsyncTitrationStatus.REJECTED_ON_INITIAL_REVIEW,
      reason,
      undefined,
      description,
    );
  };

  const onUndo = () => {
    revertRecommendationChange();
  };

  const onPatientConsented = () => {
    updateRecommendation(AsyncTitrationStatus.PATIENT_CONSENTED);
  };

  const onPatientRejected = () => {
    updateRecommendation(AsyncTitrationStatus.PATIENT_REJECTED);
  };

  const onCapturedPatientRejectionReason = (
    reason: AsyncTitrationPatientRejectedTitrationReason,
  ) =>
    updateRecommendation(
      AsyncTitrationStatus.PATIENT_REJECTED,
      undefined,
      reason,
    );

  return {
    mode,
    setMode: (nextMode: SuggestedTitrationMode) => {
      setMode(nextMode);
      onModeChanged(nextMode);
    },
    isSaving: isMutateRecommendationSaving || isRevertSaving,
    onInitialReviewed,
    onRejectedOnInitialReview,
    onPatientConsented,
    onPatientRejected,
    onUndo,
    onCapturedPatientRejectionReason,
  };
}
