import {
  add,
  endOfDay,
  endOfMonth,
  formatDistanceStrict,
  isBefore,
  parseISO,
  set,
  startOfDay,
  startOfMonth,
  subMonths,
} from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useIntl } from 'react-intl';

import { logger } from '@/logger';
import { ProgramType } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { useFlags } from '@/shared/hooks';
import type { MonitoringSession } from '@/shared/types/monitoringSession.types';

import type { TimeEntry } from '../Notes/Notes.types';
import type { TimeTrackingFormFields } from '../tabs/TimeTracking/ManualTimeTrackingForm';

export function useFormatDistanceMinutes() {
  const intl = useIntl();

  // https://github.com/date-fns/date-fns/issues/1706#issuecomment-836601089
  function distanceFormatter(token: string, count: number) {
    switch (token) {
      case 'lessThanXMinutes':
      case 'xMinutes':
        return intl.formatMessage(
          { defaultMessage: '{count} mins' },
          { count },
        );
      default:
        logger.error(`Invalid time token '${token}' for formatting in minutes`);
        return intl.formatMessage({ defaultMessage: 'N/A' });
    }
  }

  return function formatDistanceMinutes(startDate: Date, endDate: Date) {
    return formatDistanceStrict(endDate, startDate, {
      addSuffix: true,
      unit: 'minute',
      locale: {
        formatDistance: distanceFormatter,
      },
    });
  };
}

const BILLING_TIME_ZONE = 'America/Los_Angeles';

export function getIsActiveEditableTimeTrackingEntry(
  timeEntry: MonitoringSession,
) {
  const currDateTime = new Date();
  const editableUntilDateTime = getMaxEditableDate(timeEntry, false, true);
  return isBefore(
    zonedTimeToUtc(currDateTime, BILLING_TIME_ZONE),
    zonedTimeToUtc(editableUntilDateTime, BILLING_TIME_ZONE),
  );
}

export function getIsLateEditableTimeTrackingEntry(
  timeEntry: MonitoringSession,
) {
  const currDateTime = new Date();
  const editableUntilDateTime = getMaxEditableDate(timeEntry, true, true);
  return isBefore(
    zonedTimeToUtc(currDateTime, BILLING_TIME_ZONE),
    zonedTimeToUtc(editableUntilDateTime, BILLING_TIME_ZONE),
  );
}

export const EDITABLE_UNTIL_X_DAYS_AFTER_BILLING_MONTH = 4;

function getMaxEditableDate(
  timeEntry?: MonitoringSession,
  canLateEdit: boolean = false,
  checkingCanEdit: boolean = false,
) {
  const currDate = new Date();
  const endOfToday = endOfDay(currDate);
  /* Creation Flow */
  if (!timeEntry) {
    // For creation, use the current date
    return endOfToday;
  }
  /* Update Flow */
  const startDate = parseISO(timeEntry.start_datetime);

  // If checking the editability of an entry, allow up to X days after the month
  const endOfActiveEditingPeriod = getEndOfActiveEditingPeriod(timeEntry);
  if (checkingCanEdit) {
    if (!canLateEdit) {
      return endOfActiveEditingPeriod;
    }

    const endOfLateEditingPeriod = getEndOfLateEditingPeriod(timeEntry);
    return endOfLateEditingPeriod;
  }

  // Active edits can move the date until today
  const isLateEdit = currDate > endOfActiveEditingPeriod;
  if (!isLateEdit) {
    return endOfToday;
  }

  // This should not be possible
  if (!canLateEdit) {
    return endOfDay(startDate);
  }

  // Late edits can move until beginning of the active editing period
  const beginningOfCurrentBillingMonth =
    getBeginningOfCurrentBillingMonth(currDate);
  const endOfMonthBeforeBillingMonth = endOfDay(
    endOfMonth(subMonths(beginningOfCurrentBillingMonth, 1)),
  );
  return endOfMonthBeforeBillingMonth;
}

function getMinEditableDate(
  timeEntry?: MonitoringSession,
  canLateEdit: boolean = false,
) {
  const currDate = new Date();
  /* Creation Flow */
  const beginningOfCurrentBillingMonth =
    getBeginningOfCurrentBillingMonth(currDate);
  if (!timeEntry) {
    if (canLateEdit) {
      // Allow creates up to 12 months in the past
      return subMonths(beginningOfCurrentBillingMonth, 11);
    }
    return beginningOfCurrentBillingMonth;
  }

  /* Update Flow */
  const startDate = parseISO(timeEntry.start_datetime);

  // Active edits can move to the beginning of the current billing month
  const endOfActiveEditingPeriod = getEndOfActiveEditingPeriod(timeEntry);
  const isLateEdit = currDate > endOfActiveEditingPeriod;
  if (!isLateEdit) {
    return beginningOfCurrentBillingMonth;
  }

  // This should not be possible
  if (!canLateEdit) {
    return startOfDay(startDate);
  }

  // Late edits can move to any date in the past 12 months
  return subMonths(beginningOfCurrentBillingMonth, 11);
}

function getBeginningOfCurrentBillingMonth(_currDate?: Date) {
  const currDate = _currDate || new Date();
  const beginningOfMonth = startOfDay(startOfMonth(currDate));
  const xDaysAfterBillingMonth = set(beginningOfMonth, {
    date: EDITABLE_UNTIL_X_DAYS_AFTER_BILLING_MONTH,
  });

  // If the current date is past the first X days of the billing month
  // then the minimum date is the beginning of the month
  if (currDate > endOfDay(xDaysAfterBillingMonth)) {
    return beginningOfMonth;
  }

  // If the current date is within the first X days of the billing month, then
  // the minimum date is the beginning of the previous month
  return subMonths(beginningOfMonth, 1);
}

function getEndOfActiveEditingPeriod(timeEntry: MonitoringSession) {
  const monthAfterStartDate = add(parseISO(timeEntry.end_datetime), {
    months: 1,
  });
  const xDaysAfterMonthAfterStartDate = endOfDay(
    set(monthAfterStartDate, {
      // Editable until the Xth day of the next month at the end of the day
      date: EDITABLE_UNTIL_X_DAYS_AFTER_BILLING_MONTH,
    }),
  );
  return xDaysAfterMonthAfterStartDate;
}

function getEndOfLateEditingPeriod(timeEntry: MonitoringSession) {
  return add(parseISO(timeEntry.end_datetime), {
    months: 8,
  });
}

export function useTimeTrackingEditableDateRange(
  timeEntry?: MonitoringSession,
  checkingCanEdit: boolean = false,
) {
  const { timeTrackingLateEdits } = useFlags();
  const minDate = getMinEditableDate(timeEntry, timeTrackingLateEdits);
  const maxDate = getMaxEditableDate(
    timeEntry,
    timeTrackingLateEdits,
    checkingCanEdit,
  );
  return {
    minDate,
    maxDate,
  };
}

export function timeTrackingFormValsToEntry(
  vals: Partial<TimeTrackingFormFields>,
) {
  const { start_date: startDate } = vals;
  const timeTracking = {
    start_date:
      typeof startDate === 'string' ? parseISO(startDate) : (startDate as Date),
    start_time: vals.start_time as string,
    note: vals.notes,
    totalTimeDisplay: vals.totalTimeDisplay ?? 0,
    communication_type: vals.communication_type,
    entries: {
      [ProgramType.RPM]: {
        interactive_duration: vals.rpm_interactive_duration,
        non_interactive_duration: vals.rpm_non_interactive_duration,
        other_task_description: vals.rpm_other_task_description,
        tasks_accomplished: vals.rpm_tasks_accomplished,
      },
    },
  } satisfies TimeEntry;

  return timeTracking;
}

export function getTotalTime(tracking: Partial<TimeEntry>) {
  const entries = tracking.entries ?? {};
  return Object.values(entries).reduce(
    (acc, entry) =>
      acc +
      (entry.interactive_duration || 0) +
      (entry.non_interactive_duration || 0),
    0,
  );
}

export function getTotalTimeForProgram(
  tracking: Partial<TimeEntry>,
  program: ProgramType,
) {
  const entry = tracking.entries?.[program];

  if (!entry) {
    return 0;
  }

  return (
    (entry.interactive_duration || 0) + (entry.non_interactive_duration || 0)
  );
}

export function hasInteractiveTime(tracking: Partial<TimeEntry>) {
  return Object.values(tracking.entries ?? {}).some(
    (entry) => (entry.interactive_duration || 0) > 0,
  );
}
