import { useMutation } from 'react-query';

import type { AddressValues } from '@/shared/common/FormSections/MailingAddress/formUtils';
import {
  type Address,
  AddressRegion,
  PatientService,
  type UpsertPatientPrimaryAddressRequest,
} from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';

export const mapFormValuesToAddress = (values: AddressValues): Address => ({
  streetAddress: values.street_address,
  premise: values.premise,
  postalCode: values.postal_code,
  locality: values.locality,
  region: values.region
    ? AddressRegion[values.region as keyof typeof AddressRegion] || undefined
    : undefined,
  primary: true,
});

export const useUpsertPatientPrimaryAddress = (patientId: string) =>
  useMutation({
    mutationFn: async (addressValues: AddressValues) => {
      const address = mapFormValuesToAddress(addressValues);
      const request: UpsertPatientPrimaryAddressRequest = {
        name: patientId,
        address,
      };

      return PatientService.UpsertPatientPrimaryAddress(request);
    },
  });
