import type { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';

export type FormFieldError = FieldError | Merge<FieldError, FieldErrorsImpl>;
export type Errors<T> = FieldErrorsImpl<Record<keyof T, FormFieldError>>;

export type FlattenedError = {
  field: string;
  error: FieldError;
};

export function hasErrorMessage(
  error: FormFieldError | undefined,
): error is FieldError {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as FieldError).message === 'string'
  );
}

export function isNestedErrorObject(
  value: unknown,
): value is Record<string, FormFieldError> {
  return (
    typeof value === 'object' &&
    value !== null &&
    !Array.isArray(value) &&
    Object.values(value).every(
      (val) =>
        typeof val === 'object' &&
        val !== null &&
        (hasErrorMessage(val) || isNestedErrorObject(val)),
    )
  );
}

export function flattenErrors<T>(errors: Errors<T>): FlattenedError[] {
  const flattened: FlattenedError[] = [];

  function processError(field: string, error: FormFieldError) {
    if (Array.isArray(error)) {
      error.forEach((item, index) => {
        if (isNestedErrorObject(item)) {
          Object.entries(item).forEach(([nestedField, nestedError]) => {
            processError(`${field}.${index}.${nestedField}`, nestedError);
          });
        }
      });
    } else if (isNestedErrorObject(error)) {
      Object.entries(error).forEach(([nestedField, nestedError]) => {
        processError(`${field}.${nestedField}`, nestedError);
      });
    } else if (hasErrorMessage(error)) {
      flattened.push({ field, error });
    }
  }

  Object.entries(errors).forEach(([field, error]) => {
    if (error) {
      processError(field, error);
    }
  });

  return flattened;
}

export function getFieldLabel<T>(
  field: string,
  errorLabels: Record<keyof T, string>,
  subfieldLabels: Record<string, string> = {},
): string {
  // Handle array fields (e.g., "emergencyVisits.0.hospital")
  const arrayMatch = field.match(/^(.+)\.(\d+)\.(.+)$/);
  if (arrayMatch) {
    const [, arrayField, index, subField] = arrayMatch;
    const baseLabel = errorLabels[arrayField as keyof T] || arrayField;
    const subfieldLabel = subfieldLabels[subField] || subField;
    return `${baseLabel} #${Number(index) + 1} - ${subfieldLabel}`;
  }

  return errorLabels[field as keyof T] || field;
}
