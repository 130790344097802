import type { IntlShape } from 'react-intl';

import {
  TobaccoType,
  TobaccoUserStatus,
} from '@/shared/generated/grpc/go/pms/pkg/care_plan/care_plan.pb';
import type { GeneralAssessmentSocialHistory } from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';

import { getTobaccoUseDescription } from '../../../Goals/tobaccoUtils';

export function getTobaccoTypes(intl: IntlShape) {
  return [
    {
      label: intl.formatMessage({
        defaultMessage: 'Smoking',
      }),
      value: TobaccoType.SMOKING,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Vape/E-cigarette',
      }),
      value: TobaccoType.ECIG,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Chewing tobacco',
      }),
      value: TobaccoType.CHEWING,
    },
    {
      label: intl.formatMessage({
        defaultMessage: 'Snuff',
      }),
      value: TobaccoType.SNUFF,
    },
  ];
}

export function getTobaccoTypeLabel(type: TobaccoType, intl: IntlShape) {
  return getTobaccoTypes(intl).find((t) => t.value === type)?.label;
}

export function getTobaccoUserStatuses(intl: IntlShape) {
  return [
    {
      label: intl.formatMessage({ defaultMessage: 'No' }),
      value: TobaccoUserStatus.NEVER,
    },
    {
      label: intl.formatMessage({ defaultMessage: 'Yes, current user' }),
      value: TobaccoUserStatus.CURRENT,
    },
    {
      label: intl.formatMessage({ defaultMessage: 'Yes, former user' }),
      value: TobaccoUserStatus.FORMER,
    },
    {
      label: intl.formatMessage({ defaultMessage: 'Unknown' }),
      value: TobaccoUserStatus.TOBACCO_USER_STATUS_UNSPECIFIED,
    },
  ];
}

export function getTobaccoUserStatusLabel(
  status: Maybe<TobaccoUserStatus>,
  intl: IntlShape,
) {
  return status
    ? getTobaccoUserStatuses(intl).find((s) => s.value === status)?.label
    : intl.formatMessage({ defaultMessage: 'Unknown' });
}

export function formatTobaccoUse(
  socialHistory: Maybe<GeneralAssessmentSocialHistory>,
  intl: IntlShape,
) {
  if (!socialHistory) {
    return null;
  }

  const statusLabel = getTobaccoUserStatusLabel(
    socialHistory.tobaccoUserStatus,
    intl,
  );
  const description = getTobaccoUseDescription(
    socialHistory.tobaccoTypes,
    socialHistory.smokingQuantity,
    socialHistory.smokingYears,
    socialHistory.tobaccoUse,
    intl,
  );

  return `${statusLabel}. ${description}`;
}
