import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import type { CCMPrognosis } from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import { GoalsAndInterventionsHealthGoal } from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import { useFlags, usePrevious } from '@/shared/hooks';
import type { CcmCondition } from '@/shared/types/clinicalprofile.types';

import type { GoalsAndInterventions } from '../../formConfig';
import {
  hiddenField,
  otherGoalRow,
  patientConditionRow,
} from './GoalsAndInterventions.css';
import { getPatientGoalOptions, isOtherGoal } from './goalUtils';

type Props = {
  index: number;
  prognosis: CCMPrognosis;
  condition: CcmCondition;
  showGoalSetting: boolean;
};

export function PatientConditionGoal({
  index,
  prognosis,
  condition,
  showGoalSetting,
}: Props) {
  const intl = useIntl();
  const form = useFormContext();
  const { carePlanOptimization } = useFlags();

  const { healthGoalEnum, customHealthGoal } = form.watch(
    `goalsAndInterventions.${index}`,
  ) as GoalsAndInterventions;

  const patientGoalOptions = getPatientGoalOptions(condition, intl);

  const prevPrognosis = usePrevious(prognosis);
  const prevCondition = usePrevious(condition);

  // Effect to set the default health goal if the prognosis or condition has changed
  useEffect(() => {
    if (
      carePlanOptimization &&
      patientGoalOptions &&
      ((prevPrognosis && prognosis !== prevPrognosis) ||
        (prevCondition && condition !== prevCondition))
    ) {
      for (let i = 0; i < patientGoalOptions.length; i++) {
        const option = patientGoalOptions[i];
        if (
          option.isDefault?.(prognosis) &&
          option.value !==
            GoalsAndInterventionsHealthGoal.HEALTH_GOAL_UNSPECIFIED
        ) {
          form.setValue(
            `goalsAndInterventions.${index}.healthGoalEnum`,
            option.value,
          );

          break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prognosis, condition]);

  const goalLabel = carePlanOptimization
    ? intl.formatMessage({
        defaultMessage: 'Patient Condition Objective',
      })
    : intl.formatMessage({
        defaultMessage: 'Patient Goal',
      });

  if (carePlanOptimization && patientGoalOptions) {
    return (
      <>
        <Form.Row rowSpacing={1} className={patientConditionRow}>
          <Form.DeprecatedSelect
            // Hide this field instead of unmount when no goal setting so that react hook form doesn't lose the field's state
            className={!showGoalSetting ? hiddenField : ''}
            required
            size={11}
            label={goalLabel}
            name={`goalsAndInterventions.${index}.healthGoalEnum`}
          >
            {patientGoalOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.DeprecatedSelect>
        </Form.Row>

        {isOtherGoal(healthGoalEnum) && (
          <Form.Row rowSpacing={1} className={otherGoalRow}>
            <Form.TextField
              className={!showGoalSetting ? hiddenField : ''}
              autoFocus={!customHealthGoal}
              required
              size={11}
              label={intl.formatMessage({
                defaultMessage: 'Enter custom objective:',
              })}
              name={`goalsAndInterventions.${index}.customHealthGoal`}
            />
          </Form.Row>
        )}
      </>
    );
  }

  return (
    <Form.Row rowSpacing={1} className={patientConditionRow}>
      <Form.TextField
        className={!showGoalSetting ? hiddenField : ''}
        required
        size={11}
        label={goalLabel}
        name={`goalsAndInterventions.${index}.healthGoal`}
      />
    </Form.Row>
  );
}
