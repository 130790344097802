import { add, set } from 'date-fns';

import { TimeTrackedTaskType } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { ProgramType } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import type {
  MonitoringSessionPostPayload,
  MonitoringSessionPutPayload,
} from '@/shared/hooks/queries';

import type { TimeTrackingFormFields } from '../../ManualTimeTrackingForm';

const NOTE_TITLE = 'Time tracking note';

export function getManualEntryCreatePayload(
  patientId: string,
  values: TimeTrackingFormFields,
  currentUserName: string,
): MonitoringSessionPostPayload {
  return getMonitoringSessionPayload(patientId, values, currentUserName);
}

export function getManualEntryUpdatePayload(
  patientId: string,
  sessionId: string,
  values: TimeTrackingFormFields,
  currentUserName: string,
): MonitoringSessionPutPayload {
  return {
    id: sessionId,
    ...getMonitoringSessionPayload(patientId, values, currentUserName),
  };
}

function getMonitoringSessionPayload(
  patientId: string,
  values: TimeTrackingFormFields,
  currentUserName: string,
) {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const startDate = values.start_date;
  const [hours, minutes] = values.start_time.split(':');

  // Create a start datetime object by joining the date/time
  const startDateTime = set(startDate, {
    hours: Number(hours),
    minutes: Number(minutes),
    seconds: 0,
  });

  const isInteractiveNonInteractive =
    typeof values.rpm_interactive_duration === 'number' ||
    typeof values.rpm_non_interactive_duration === 'number';
  const endDateTime = add(startDateTime, {
    minutes: isInteractiveNonInteractive
      ? (values.rpm_interactive_duration || 0) +
        (values.rpm_non_interactive_duration || 0)
      : values.minutes,
  });
  const body = isInteractiveNonInteractive ? composeNote(values) : values.notes;

  return {
    session: {
      patient_id: patientId,
      timezone: timeZone,
      communication_type: values.communication_type,
      start_datetime: startDateTime.toISOString(),
      end_datetime: endDateTime.toISOString(),
      entries: [
        {
          program_type: ProgramType.RPM,
          interactive_duration: values.rpm_interactive_duration,
          non_interactive_duration: values.rpm_non_interactive_duration,
          tasks_accomplished: values.rpm_tasks_accomplished,
          other_task_description: values.rpm_tasks_accomplished?.includes(
            TimeTrackedTaskType.Other,
          )
            ? values.rpm_other_task_description
            : undefined,
        },
      ],
    },
    note: {
      author: currentUserName,
      body,
      title: NOTE_TITLE,
    },
  };
}

function composeNote(values: TimeTrackingFormFields) {
  const interactiveDuration = values.rpm_interactive_duration || 0;
  const nonInteractiveDuration = values.rpm_non_interactive_duration || 0;
  const tasksAccomplished = values.rpm_tasks_accomplished || [];
  const otherTaskDescription = values.rpm_other_task_description || '';
  return `Phone call: ${interactiveDuration} minutes.
Non-interactive time: ${nonInteractiveDuration} minutes.
Tasks accomplished: ${tasksAccomplished
    .map(
      (task) =>
        `${
          // No i18n as this isn't user-facing
          {
            [TimeTrackedTaskType.ChartPrepOrDocumentation]:
              'Chart prep or documentation',
            [TimeTrackedTaskType.PatientVoicemail]: 'Patient voicemail',
            [TimeTrackedTaskType.LabOrMedOrder]: 'Lab or med order',
            [TimeTrackedTaskType.PatientSMS]: 'Patient SMS',
            [TimeTrackedTaskType.InternalOrProviderCommunication]:
              'Internal or provider communication',
            [TimeTrackedTaskType.Other]: 'Other',
          }[task]
        }${
          task === TimeTrackedTaskType.Other ? ` (${otherTaskDescription})` : ''
        }`,
    )
    .join(', ')}.`;
}
