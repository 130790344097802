import { type BaseSyntheticEvent, useEffect, useState } from 'react';
import { type FieldValues } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { TypeOfEncounter } from '@/components/NotesV2/types';
import AlertTriangle from '@/shared/assets/svgs/alertTriangle.svg?react';
import TrashIcon from '@/shared/assets/svgs/trash.svg?react';
import { Form } from '@/shared/common/Form';
import { useFormFromConfig } from '@/shared/common/Form/FormContainer';
import { FormErrors } from '@/shared/common/Form/FormErrors';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { NoteStatus } from '@/shared/generated/grpc/go/pms/pkg/note/note.pb';
import { useFlags, useQueryParams } from '@/shared/hooks';
import { usePatientDetails } from '@/shared/hooks/queries';
import {
  useCarePlanDraft,
  useCarePlanNoteInfo,
} from '@/shared/hooks/queries/carePlan.queries';
import { useCarePlanV1Changes } from '@/shared/hooks/useCarePlanV1Changes';
import { usePatientDemographics } from '@/shared/hooks/usePatientDemographics';
import { Button } from '@/shared/tempo/atom/Button';
import { Link } from '@/shared/tempo/atom/Link';
import { color } from '@/shared/tempo/theme';
import { grpcNameToId } from '@/shared/utils/grpc';
import { getCarePlanSuggestionsUrl } from '@/shared/utils/metabase';
import { parentPath } from '@/shared/utils/route-helpers';

import { CarePlanSuggestionBanner } from '../CarePlanSuggestionBanner';
import { ContextualGoalButton } from '../Goals/ContextualGoalButton';
import { GoalsGrid } from '../Goals/GoalsGrid';
import { GoalModalType } from '../Goals/goalModal.types';
import type { Goal } from '../Goals/goals.types';
import { GoalMeasure } from '../Goals/goals.types';
import { useGoalGoalMeasureI18n } from '../Goals/i18nMapping';
import {
  actions,
  alertIcon,
  autopopulatedSectionBanner,
  autopopulatedSectionContainer,
  buttonContainer,
  errorRow,
  floatingFooter,
  footerButtonsContainer,
  formWrapper,
  gridContainer,
  viewErrorsButton,
} from './CarePlanForm.css';
import {
  CarePlanFormModals,
  type ModalState,
  ModalType,
} from './CarePlanFormModals';
import { marshalCarePlanToFormData } from './dataTransformation';
import { getFormConfig } from './formConfig';
import {
  useFieldLabelsForErrors,
  useSubfieldLabelsForErrors,
} from './hooks/useFieldLabelsForErrors';
import { useAutosaveCarePlan, useOnSave } from './persistence';
import { CulturalFactors } from './sections/CulturalFactors';
import { EmergencyVisits } from './sections/EmergencyVisits';
import { GoalsAndInterventions } from './sections/GoalsAndInterventions';
import { useInitialCarePlanConditions } from './sections/GoalsAndInterventions/hooks/useInitialCarePlanConditions.hook';
import { MedicationAllergies } from './sections/MedicationAllergies';
import { Medications } from './sections/Medications';
import { NextFollowUp } from './sections/NextFollowUp';
import { PastSurgicalHistory } from './sections/PastSurgicalHistory';
import { PreventativeCare } from './sections/PreventativeCare';
import { PreventativeCareGaps } from './sections/PreventativeCareGaps';
import { ProblemList } from './sections/ProblemList';
import { PsychosocialAssessment } from './sections/PsychosocialAssessment';
import { Specialists } from './sections/Specialists';
import { TobaccoUse } from './sections/TobaccoUse';
import {
  goalSectionContainer,
  helpText,
  sectionTitle,
} from './sections/carePlanSections.css';

export function CarePlanForm() {
  const { showCarePlanSuggestions, carePlanOptimization } = useFlags();
  const intl = useIntl();
  const history = useHistory();
  const [modalState, setModalState] = useState<Nullable<ModalState>>(null);
  const [shouldShowErrors, setShouldShowErrors] = useState(false);
  const { url, params } = useRouteMatch<{ patientId: string }>();
  const queryParams = useQueryParams();
  const validate = queryParams.get('validate');
  const goalMeasureI18n = useGoalGoalMeasureI18n();

  function navigateUp() {
    history.push(parentPath(url));
  }

  useEffect(() => {
    if (validate) {
      form.trigger();
      // Clear validate query param after triggering validation
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete('validate');
      history.replace({ search: searchParams.toString() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validate]);

  const {
    data: existingDraft,
    isLoading: isLoadingDraft,
    isFetching: isFetchingDraft,
  } = useCarePlanDraft(params.patientId, {
    onSuccess(data) {
      if (!data.ccmCarePlans?.length) {
        // No draft so navigate up
        navigateUp();
      }
    },
  });
  const draftPlan = existingDraft?.carePlan;
  const hasDraftPlan = !!draftPlan;
  const noteId = hasDraftPlan ? (existingDraft.noteId as number) : undefined;
  const shouldShowCarePlanV1Changes = useCarePlanV1Changes(draftPlan);

  const { data: patient, isLoading: isLoadingPatient } = usePatientDetails(
    params.patientId,
    true,
  );

  const demographics = usePatientDemographics(
    params.patientId,
    shouldShowCarePlanV1Changes,
  );

  const draftCarePlanId = existingDraft?.name
    ? grpcNameToId(existingDraft.name)
    : undefined;
  const {
    data: noteInfo,
    isLoading: isLoadingNoteInfo,
    isFetching: isFetchingNoteInfo,
    isFetched: isFetchedNoteInfo,
  } = useCarePlanNoteInfo(draftCarePlanId || '', {
    enabled: Boolean(draftCarePlanId),
  });
  const isCCMEncounter =
    noteInfo?.noteEncounterType === TypeOfEncounter.CCM_CARE_PLAN;

  // Get conditions that populate the Care Plan on initial creation
  const {
    conditions: initialConditions,
    isLoading: isLoadingInitialConditions,
  } = useInitialCarePlanConditions(
    params.patientId,
    isCCMEncounter,
    isFetchedNoteInfo,
  );

  const form = useFormFromConfig({
    ...getFormConfig(
      intl,
      hasDraftPlan
        ? marshalCarePlanToFormData(
            draftPlan,
            shouldShowCarePlanV1Changes,
            intl,
          )
        : {},
      shouldShowCarePlanV1Changes,
      isCCMEncounter,
      carePlanOptimization,
      demographics,
      initialConditions,
    ),
    triggerReset:
      !isLoadingDraft &&
      hasDraftPlan &&
      !isLoadingPatient &&
      !!patient &&
      !demographics.isLoading &&
      !isLoadingInitialConditions,
  });

  const { onSave, isSaving } = useOnSave(
    params.patientId,
    noteId,
    shouldShowCarePlanV1Changes,
  );
  const { manualAutoSave, cancelAutosave } = useAutosaveCarePlan(form, onSave, {
    enabled: !isFetchingDraft && !isFetchingNoteInfo,
  });

  const associatedNoteIsPublished =
    noteInfo?.noteStatus === NoteStatus.PUBLISHED;

  const errorLabels = useFieldLabelsForErrors();
  const subfieldLabels = useSubfieldLabelsForErrors();

  if (isLoadingDraft || !noteId) {
    return <LoadingPlaceholder isLoading />;
  }

  function createGoalWithMeasure(measure: GoalMeasure, description?: string) {
    setModalState({
      type: GoalModalType.CreateGoal,
      defaults: { measure, title: goalMeasureI18n[measure], description },
    });
  }

  function onEditGoal(goal: Goal) {
    setModalState({ type: GoalModalType.EditGoal, goal });
  }

  async function saveAndClearErrors() {
    // Cancel any outgoing debounced saves, which will have stale values
    cancelAutosave();
    // Trigger the form to get the most up-to-date values
    await form.trigger();
    // form.trigger() will cause errors to show in the UI, so clear them because this button is for allowing saving incomplete care plans
    form.clearErrors();

    onSave(form.getValues());
  }

  function handleSubmit(data: FieldValues, e?: BaseSyntheticEvent) {
    onSave(data, {
      onSuccess: () => {
        if (
          !carePlanOptimization ||
          (e?.nativeEvent as SubmitEvent)?.submitter?.classList.contains(
            'view-preview-button',
          )
        ) {
          navigateUp();
        }
      },
    });
  }

  const hasErrors = Object.values(form.formState.errors).some(
    (error) => error !== undefined,
  );

  return (
    <>
      <div className={gridContainer}>
        <GoalsGrid
          patientId={params.patientId}
          onEditGoal={onEditGoal}
          onCreateGoal={() => setModalState({ type: GoalModalType.CreateGoal })}
        />
      </div>
      {showCarePlanSuggestions && (
        <CarePlanSuggestionBanner
          title={
            <FormattedMessage defaultMessage="Smart Suggestions available" />
          }
        >
          <FormattedMessage
            defaultMessage="You can now view the {link} for insights on how to complete this Care Plan."
            values={{
              link: (
                <Link.Native
                  target="_blank"
                  href={getCarePlanSuggestionsUrl(params.patientId)}
                >
                  <FormattedMessage defaultMessage="Smart Care Plan Suggestions dashboard" />
                </Link.Native>
              ),
            }}
          />
        </CarePlanSuggestionBanner>
      )}
      <Form form={form} onSubmit={handleSubmit}>
        {({ canSubmit }) => (
          <>
            <div className={formWrapper}>
              {!shouldShowCarePlanV1Changes && (
                <>
                  <ProblemList />
                  <PastSurgicalHistory
                    form={form}
                    manualAutoSave={manualAutoSave}
                  />
                </>
              )}
              <Specialists form={form} manualAutoSave={manualAutoSave} />
              <EmergencyVisits
                form={form}
                manualAutoSave={manualAutoSave}
                createGoalWithMeasure={createGoalWithMeasure}
              />
              <Medications
                noteId={noteId}
                createGoalWithMeasure={createGoalWithMeasure}
              />
              {!shouldShowCarePlanV1Changes && (
                <MedicationAllergies
                  form={form}
                  manualAutoSave={manualAutoSave}
                />
              )}
              <PreventativeCare
                form={form}
                createGoalWithMeasure={createGoalWithMeasure}
                patientId={params.patientId}
                shouldShowCarePlanV1Changes={shouldShowCarePlanV1Changes}
                onEditGoal={onEditGoal}
                carePlanId={draftCarePlanId || ''}
              />
              <Form.Section
                title={
                  <div className={goalSectionContainer}>
                    {carePlanOptimization ? (
                      intl.formatMessage({
                        defaultMessage: 'Social History: Tobacco use',
                      })
                    ) : (
                      <>
                        {intl.formatMessage({
                          defaultMessage: 'Social history',
                        })}
                        <ContextualGoalButton
                          onPress={() =>
                            createGoalWithMeasure(GoalMeasure.Tobacco)
                          }
                        />
                      </>
                    )}
                  </div>
                }
                classes={{ title: sectionTitle }}
              >
                {carePlanOptimization ? (
                  <TobaccoUse
                    openCreateGoalModal={createGoalWithMeasure}
                    openEditGoalModal={onEditGoal}
                    carePlanId={draftCarePlanId || ''}
                  />
                ) : (
                  <Form.Row>
                    <Form.TextField
                      size={11}
                      label={
                        <FormattedMessage defaultMessage="History of tobacco use?" />
                      }
                      name="tobaccoUse"
                    />
                  </Form.Row>
                )}
              </Form.Section>
              <Form.Section
                title={
                  <div className={goalSectionContainer}>
                    {intl.formatMessage({ defaultMessage: 'Diet' })}
                    <ContextualGoalButton
                      onPress={() => createGoalWithMeasure(GoalMeasure.Diet)}
                    />
                  </div>
                }
                classes={{ title: sectionTitle }}
              >
                {shouldShowCarePlanV1Changes ? (
                  <>
                    <Form.Row>
                      <Form.TextArea
                        classes={{ persistedHelpText: helpText }}
                        size={11}
                        label={
                          <FormattedMessage defaultMessage="Tell me about your diet. What does a typical day of eating look like?" />
                        }
                        name="dietNotes"
                        rows={3}
                        required
                        persistedHelp={
                          <FormattedMessage defaultMessage="Pay attention especially to fast food, snacks, sugar, salt, and water intake " />
                        }
                      />
                    </Form.Row>
                  </>
                ) : (
                  <>
                    <Form.Row>
                      <Form.TextField
                        size={11}
                        label={
                          <FormattedMessage defaultMessage="How many times per week do you eat fast food meals?" />
                        }
                        name="fastFood"
                      />
                    </Form.Row>
                    <Form.Row>
                      <Form.TextField
                        size={11}
                        label={
                          <FormattedMessage defaultMessage="Do you snack often?" />
                        }
                        name="snacks"
                      />
                    </Form.Row>
                    <Form.Row>
                      <Form.TextField
                        size={11}
                        label={
                          <FormattedMessage defaultMessage="How many times per week do you eat desserts or other sweets?" />
                        }
                        name="desserts"
                      />
                    </Form.Row>
                    <Form.Row>
                      <Form.TextField
                        size={11}
                        label={
                          <FormattedMessage defaultMessage="Do you drink regular soda?" />
                        }
                        name="regularSoda"
                      />
                    </Form.Row>
                    <Form.Row>
                      <Form.TextField
                        size={11}
                        label={
                          <FormattedMessage defaultMessage="Do you drink sweet tea?" />
                        }
                        name="sweetTea"
                      />
                    </Form.Row>
                    <Form.Row>
                      <Form.TextField
                        size={11}
                        label={
                          <FormattedMessage defaultMessage="How much water do you drink per day?" />
                        }
                        name="waterIntake"
                      />
                    </Form.Row>
                    <Form.Row>
                      <Form.TextField
                        size={11}
                        label={
                          <FormattedMessage defaultMessage="Do you add salt to meals regularly?" />
                        }
                        name="saltIntake"
                      />
                    </Form.Row>
                  </>
                )}
              </Form.Section>
              {!shouldShowCarePlanV1Changes && (
                <Form.Section
                  title={
                    <div className={goalSectionContainer}>
                      {intl.formatMessage({ defaultMessage: 'Exercise' })}
                      <ContextualGoalButton
                        onPress={() =>
                          createGoalWithMeasure(GoalMeasure.Exercise)
                        }
                      />
                    </div>
                  }
                  classes={{ title: sectionTitle }}
                >
                  <Form.Row>
                    <Form.TextArea
                      size={11}
                      rows={3}
                      label={
                        <FormattedMessage defaultMessage="Do you exercise regularly? If so, what type of exercise? How long and how often?" />
                      }
                      name="exercise"
                    />
                  </Form.Row>
                </Form.Section>
              )}
              <PsychosocialAssessment
                form={form}
                createGoalWithMeasure={createGoalWithMeasure}
                shouldShowCarePlanV1Changes={shouldShowCarePlanV1Changes}
                onEditGoal={onEditGoal}
                carePlanId={draftCarePlanId || ''}
              />
              <CulturalFactors />
              <GoalsAndInterventions
                form={form}
                manualAutoSave={manualAutoSave}
                createGoalWithMeasure={createGoalWithMeasure}
                openEditGoalModal={onEditGoal}
                carePlanId={draftCarePlanId || ''}
                isCCMEncounter={isCCMEncounter}
              />
              <PreventativeCareGaps form={form} />
              <Form.Section
                title={intl.formatMessage({ defaultMessage: 'Follow up' })}
                classes={{ title: sectionTitle }}
              >
                <Form.Row>
                  {carePlanOptimization ? (
                    <NextFollowUp patientId={params.patientId} />
                  ) : (
                    <Form.TextField
                      size={11}
                      label={
                        <FormattedMessage defaultMessage="Date and time for next follow-up with my care manager" />
                      }
                      name="ccmAppointment"
                    />
                  )}
                </Form.Row>
                <Form.Row>
                  <Form.TextField
                    size={11}
                    label={
                      <FormattedMessage defaultMessage="Date and time for next appointment with my doctor" />
                    }
                    name="providerAppointment"
                  />
                </Form.Row>
              </Form.Section>
              {!carePlanOptimization && (
                <Form.GridItem size={11} className={actions.container}>
                  {!associatedNoteIsPublished ? (
                    <DeleteButton
                      isLoadingNoteInfo={isLoadingNoteInfo}
                      isFetchingNoteInfo={isFetchingNoteInfo}
                      existingDraft={existingDraft}
                      setModalState={setModalState}
                      navigateUp={navigateUp}
                    />
                  ) : (
                    // Empty Div to keep Save button on the right
                    <div />
                  )}
                  <div className={buttonContainer}>
                    <Button
                      onPress={saveAndClearErrors}
                      isProcessing={isSaving}
                      variant="secondary"
                    >
                      <FormattedMessage defaultMessage="Save Chart Prep" />
                    </Button>
                    <Button
                      type="submit"
                      isDisabled={!canSubmit}
                      isProcessing={isSaving}
                    >
                      <FormattedMessage defaultMessage="Save Draft" />
                    </Button>
                  </div>
                </Form.GridItem>
              )}
            </div>
            {shouldShowCarePlanV1Changes && (
              <div className={autopopulatedSectionContainer}>
                <CarePlanSuggestionBanner
                  className={autopopulatedSectionBanner}
                  title={
                    <FormattedMessage defaultMessage="Sections below auto-populated" />
                  }
                >
                  <FormattedMessage defaultMessage="We are autopopulating these sections with data from the EMR. You do not need to review them with the patient" />
                </CarePlanSuggestionBanner>
                <ProblemList />
                <PastSurgicalHistory
                  form={form}
                  manualAutoSave={manualAutoSave}
                />
                <MedicationAllergies
                  form={form}
                  manualAutoSave={manualAutoSave}
                />
              </div>
            )}
            {carePlanOptimization && (
              <Form.FloatingFooter className={floatingFooter}>
                <div className={footerButtonsContainer}>
                  {!associatedNoteIsPublished ? (
                    <DeleteButton
                      isLoadingNoteInfo={isLoadingNoteInfo}
                      isFetchingNoteInfo={isFetchingNoteInfo}
                      existingDraft={existingDraft}
                      setModalState={setModalState}
                      navigateUp={navigateUp}
                    />
                  ) : (
                    // Empty Div to keep Save button on the right
                    <div />
                  )}
                  {!form.formState.isValid ? (
                    <div className={buttonContainer}>
                      <Button
                        className={viewErrorsButton}
                        onPress={async () => {
                          if (!shouldShowErrors) {
                            await form.trigger();
                          } else {
                            await form.clearErrors();
                          }
                          setShouldShowErrors(!shouldShowErrors);
                        }}
                        isProcessing={isSaving}
                        variant="tertiary"
                      >
                        {!shouldShowErrors && (
                          <Button.Icon>
                            <AlertTriangle className={alertIcon} />
                          </Button.Icon>
                        )}
                        {shouldShowErrors ? (
                          <FormattedMessage defaultMessage="Hide incomplete fields" />
                        ) : (
                          <FormattedMessage defaultMessage="View incomplete fields" />
                        )}
                      </Button>
                      <Button
                        onPress={saveAndClearErrors}
                        isProcessing={isSaving}
                        variant="secondary"
                      >
                        <FormattedMessage defaultMessage="Save Draft" />
                      </Button>
                    </div>
                  ) : (
                    <div className={buttonContainer}>
                      <Button
                        type="submit"
                        className="view-preview-button"
                        isProcessing={isSaving}
                        variant="tertiary"
                      >
                        <FormattedMessage defaultMessage="View Preview" />
                      </Button>
                      <Button type="submit" isProcessing={isSaving}>
                        <FormattedMessage defaultMessage="Save Draft" />
                      </Button>
                    </div>
                  )}
                </div>
                {shouldShowErrors && hasErrors && (
                  <div className={errorRow}>
                    <div className={actions.errorContainer}>
                      <FormErrors
                        errors={form.formState.errors}
                        errorLabels={errorLabels}
                        subfieldLabels={subfieldLabels}
                        message={intl.formatMessage({
                          defaultMessage:
                            'Please fix the following errors before completing this Care Plan:',
                        })}
                      />
                    </div>
                  </div>
                )}
              </Form.FloatingFooter>
            )}
          </>
        )}
      </Form>

      <CarePlanFormModals
        modalState={modalState}
        setModalState={setModalState}
        existingDraft={existingDraft}
      />
    </>
  );
}

interface DeleteButtonProps {
  isLoadingNoteInfo: boolean;
  isFetchingNoteInfo: boolean;
  existingDraft: { name?: string } | undefined;
  setModalState: (state: ModalState | null) => void;
  navigateUp: () => void;
}

function DeleteButton({
  isLoadingNoteInfo,
  isFetchingNoteInfo,
  existingDraft,
  setModalState,
  navigateUp,
}: DeleteButtonProps) {
  return (
    <Button
      variant="tertiary"
      isDisabled={isLoadingNoteInfo || isFetchingNoteInfo}
      className={actions.deleteButton}
      onPress={() => {
        if (existingDraft?.name) {
          setModalState({
            type: ModalType.Delete,
            carePlanName: existingDraft.name,
          });
        } else {
          // if we don't have a name we haven't saved yet so we can just navigate out
          navigateUp();
        }
      }}
    >
      <Button.Icon>
        <TrashIcon fill={color.Theme.Light.Danger} />
      </Button.Icon>
      <FormattedMessage defaultMessage="Delete" />
    </Button>
  );
}
