import {
  EncounterModuleId,
  TypeOfEncounter,
} from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { ConditionProgram } from '@/shared/types/condition.types';

import { draftJsFieldTemplate } from '../../../../template.utils';
import { endEarlyNoteTemplates } from '../../../__shared__/endEarly';
import assessmentBody from './assessment_body.hbs';
import notesBody from './notes_body.hbs';

const MODULES = {
  [EncounterModuleId.PatientNotes]: {
    notes_body: draftJsFieldTemplate(notesBody),
    endEarly: endEarlyNoteTemplates,
  },
  [EncounterModuleId.GeneralAssessmentAndPlan]: {
    assessment_body: draftJsFieldTemplate(assessmentBody),
  },
};

export const regularApcmVisit = {
  [TypeOfEncounter.APCM_VISIT]: {
    [ConditionProgram.APCM]: { ...MODULES },
  },
};
