import { Fragment } from 'react';
import { type UseFormReturn, useFieldArray } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { useFlags } from '@/shared/hooks';
import { Button } from '@/shared/tempo/atom/Button';

import { RemovableRow } from '../RemovableRow';
import type { FormFields } from '../formConfig';
import {
  addItemButton,
  sectionTitle,
  specialistsDivider,
} from './carePlanSections.css';

type Props = {
  manualAutoSave: () => void;
  form: UseFormReturn<FormFields>;
};

export function Specialists({ form, manualAutoSave }: Props) {
  const { carePlanOptimization } = useFlags();
  const intl = useIntl();
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'specialists',
  });

  const specialistsErrors = form.formState.errors.specialists;

  return (
    <Form.Section
      title={intl.formatMessage({ defaultMessage: 'Specialists' })}
      description={
        carePlanOptimization
          ? intl.formatMessage({
              defaultMessage:
                "Ask the patient about which specialists they plan to see in the next two months and which specialists they've been referred to but haven't yet scheduled with.",
            })
          : undefined
      }
      classes={{ title: sectionTitle }}
    >
      {fields.map((field, index) => (
        <Fragment key={field.id}>
          <RemovableRow
            onChange={() => manualAutoSave()}
            onRemove={() => remove(index)}
            hasError={
              !!specialistsErrors?.[index]?.name ||
              !!specialistsErrors?.[index]?.specialty
            }
          >
            <Form.TextField
              size={5}
              label={intl.formatMessage({
                defaultMessage: 'Specialist name',
              })}
              name={`specialists.${index}.name`}
            />
            <Form.TextField
              size={6}
              label={intl.formatMessage({
                defaultMessage: 'Specialty',
              })}
              name={`specialists.${index}.specialty`}
            />
          </RemovableRow>
          <Form.Row>
            <Form.TextField
              size={5}
              onChange={() => manualAutoSave()}
              label={intl.formatMessage({
                defaultMessage: 'Last visit',
              })}
              name={`specialists.${index}.lastVisit`}
            />
            <Form.TextField
              size={6}
              onChange={() => manualAutoSave()}
              label={intl.formatMessage({
                defaultMessage: 'Next Visit',
              })}
              name={`specialists.${index}.nextVisit`}
            />
          </Form.Row>
          {index < fields.length - 1 && <div className={specialistsDivider} />}
        </Fragment>
      ))}
      <div
        className={
          !fields.length ? addItemButton.emptyList : addItemButton.default
        }
      >
        <Button
          variant="secondary"
          size="small"
          onPress={() =>
            append({ name: '', specialty: '', lastVisit: '', nextVisit: '' })
          }
        >
          <FormattedMessage defaultMessage="Add Specialist" />
        </Button>
      </div>
    </Form.Section>
  );
}
