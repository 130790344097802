import groupBy from 'lodash/groupBy';
import maxBy from 'lodash/maxBy';
import type { IntlShape } from 'react-intl';

import type { CareProvider } from '@/shared/generated/grpc/cadence/models/models.pb';
import type {
  ExternalAppointment,
  PatientExternalProvider,
} from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { PatientSelectedCareProviderConfidence } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { Link } from '@/shared/tempo/atom/Link';

export function getMostFrequentProvider(
  appointments: ExternalAppointment[],
): Nullable<CareProvider> {
  const groupedAppointments = groupBy(
    appointments,
    (appointment) =>
      `${appointment.careProvider?.givenName}-${appointment.careProvider?.familyName}`,
  );
  const mostFrequentGroup = maxBy(
    Object.values(groupedAppointments),
    (appts) => appts.length,
  );
  return mostFrequentGroup?.[0]?.careProvider || null;
}

export function sortAppointments(
  appointments: ExternalAppointment[],
): ExternalAppointment[] {
  return [...appointments].sort((a, b) => {
    const dateA = a.appointmentDatetime
      ? new Date(a.appointmentDatetime)
      : null;
    const dateB = b.appointmentDatetime
      ? new Date(b.appointmentDatetime)
      : null;

    if (dateA && dateB) {
      return dateB.getTime() - dateA.getTime(); // Changed order here
    }
    if (dateA) {
      return 1; // Changed sign here
    }
    if (dateB) {
      return -1; // Changed sign here
    }
    return 0;
  });
}

export function getPastAppointments(
  appointments: ExternalAppointment[],
): ExternalAppointment[] {
  const now = new Date();
  return appointments.filter((appointment) => {
    const date = appointment.appointmentDatetime
      ? new Date(appointment.appointmentDatetime)
      : null;
    return date && date < now;
  });
}

export function getFutureAppointments(
  appointments: ExternalAppointment[],
): ExternalAppointment[] {
  const now = new Date();
  return appointments.filter((appointment) => {
    const date = appointment.appointmentDatetime
      ? new Date(appointment.appointmentDatetime)
      : null;
    return date && date >= now;
  });
}

export function getNextApptProvider(
  appointments: ExternalAppointment[],
): Maybe<CareProvider> {
  return appointments.length > 0 ? appointments[0].careProvider : null;
}

export function formatProviderFullName(
  provider: Maybe<CareProvider>,
  fallback: Nullable<string> = null,
) {
  const fName = provider
    ? [provider.givenName, provider.familyName].filter(Boolean)
    : [];
  if (!fName.length) {
    return fallback;
  }
  const name = fName.join(' ');
  return (
    <Link.Routed to={`/admin/provider/${provider?.id}`}>{name}</Link.Routed>
  );
}

export function formatProviderConfidence(
  provider: Maybe<PatientExternalProvider>,
  intl: IntlShape,
): string {
  switch (provider?.careProviderConfidence) {
    case PatientSelectedCareProviderConfidence.LOW:
      return intl.formatMessage({ defaultMessage: '(Low Confidence)' });
    case PatientSelectedCareProviderConfidence.MEDIUM:
      return intl.formatMessage({ defaultMessage: '(Medium Confidence)' });
    case PatientSelectedCareProviderConfidence.HIGH:
      return intl.formatMessage({ defaultMessage: '(High Confidence)' });
    default:
      return '';
  }
}
