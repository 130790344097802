import { assignInlineVars } from '@vanilla-extract/dynamic';
import cx from 'classnames';
import type { MutableRefObject, ReactNode } from 'react';
import { useRef, useState } from 'react';

import type { TextFieldProps } from '@/deprecated/mui';
import { InputAdornment, TextField as MUITextField } from '@/deprecated/mui';
import { fullWidth } from '@/shared/jsStyle/utils.css';
import { color } from '@/shared/tempo/theme';
import { focusRingCss } from '@/shared/tempo/util/FocusRing/FocusRing.css';

import { addInputWrapperClassname } from '../Input';
import { input } from '../Input/Input.css';
import {
  container,
  expandHeightVar,
  inputRoot,
  letterCount,
  letterCountWarning,
  textArea,
} from './TextArea.css';

export type ExpandState = 'auto' | 'expanded' | 'collapsed' | false;

type Props = Omit<
  TextFieldProps,
  'multiline' | 'rows' | 'placeholder' | 'classes'
> & {
  expand?: ExpandState;
  expandHeight?: number;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  maxLength?: number;
  maxRows?: number;
  rows?: number;
  placeholder?: string;
  value?: string;
  wrapperClassName?: string;
  isReadOnly?: boolean;
  endAdornment?: ReactNode;
  classes?: {
    root?: string;
    input?: string;
  };
};

const DEFAULT_EXPAND_HEIGHT = 86;

export function TextArea({
  value,
  maxRows,
  rows,
  className,
  placeholder,
  expand,
  expandHeight = DEFAULT_EXPAND_HEIGHT,
  maxLength,
  InputProps,
  onFocus,
  onBlur,
  inputRef,
  wrapperClassName,
  endAdornment,
  classes,
  ...rest
}: Props) {
  const timeoutId = useRef<Nullable<number>>(null);
  const [focused, setFocused] = useState(false);
  const localRef = useRef<HTMLInputElement>();
  const textAreaRef = inputRef ?? localRef;

  return (
    <div className={cx(container, wrapperClassName)}>
      <MUITextField
        multiline
        inputRef={textAreaRef}
        rows={rows}
        maxRows={maxRows}
        placeholder={placeholder}
        sx={{
          '& .MuiOutlinedInput-root': {
            '&.Mui-disabled fieldset': {
              borderColor: color.Theme.Light['Base Form Border'],
            },
            '&:hover fieldset': {
              borderColor: fieldsetHoverBorderColor(
                !!rest.error,
                !!rest.disabled,
              ),
            },
            '&.Mui-focused fieldset': {
              borderWidth: '1px',
            },
          },
        }}
        InputProps={{
          style: assignInlineVars({ [expandHeightVar]: `${expandHeight}px` }),
          classes: {
            root: cx(
              inputRoot.default,
              input,
              {
                [rest.error
                  ? focusRingCss.keyboardWithError
                  : focusRingCss.keyboard]: focused,
                [inputRoot.disabled]: rest.disabled,
              },
              classes?.root,
            ),
            input: cx(
              addInputWrapperClassname(
                rest.disabled,
                rest.isReadOnly,
                !!rest.error,
              ),
              {
                [textArea.default]: !expand,
                [textArea.collapsed]:
                  (expand === 'auto' && !focused) || expand === 'collapsed',
                [textArea.expanded]:
                  (expand === 'auto' && focused) || expand === 'expanded',
                [textArea.disabled]: rest.disabled,
              },
              classes?.input,
            ),
          },
          onFocus: (e) => {
            if (timeoutId.current !== null) {
              window.clearTimeout(timeoutId.current);
            }
            setFocused(true);
            onFocus?.(e);
          },
          onBlur: (e) => {
            const textAreaInput = textAreaRef.current;
            if (textAreaInput) {
              textAreaInput.scrollTop = 0;
            }
            // Need to fire this in timeout to prevent mouse events not being
            // handled for elements beneath this component when the height is transitioning
            timeoutId.current = window.setTimeout(() => {
              setFocused(false);
              onBlur?.(e);
            }, 100);
          },
          'aria-label': rest.name,
          ...(endAdornment && {
            endAdornment: (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ),
          }),
        }}
        className={cx(className, fullWidth)}
        value={value}
        {...rest}
      />
      {focused && maxLength && (
        <div
          className={cx(letterCount, {
            [letterCountWarning]: (maxLength || 0) - (value || '').length < 10,
          })}
        >
          {characterCount(value || '', maxLength || 0)}
        </div>
      )}
    </div>
  );
}

function characterCount(str: string, maxLength: number) {
  return `${str.length} / ${maxLength}`;
}

function fieldsetHoverBorderColor(isError: boolean, isDisabled: boolean) {
  if (isError) {
    return color.Palette.Danger[600];
  }
  if (isDisabled) {
    return color.Theme.Light['Base Form Border'];
  }
  return color.Palette.Primary[500];
}
