import { CheckPatientAlertEscalationRequiredResponseEscalationReason as Reason } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import type { CheckPatientAlertEscalationRequiredResponse } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { useFlags } from '@/shared/hooks';
import {
  usePatientDetails,
  useProviderStateLicensureByState,
} from '@/shared/hooks/queries';
import { useCheckPatientAlertEscalationRequired } from '@/shared/hooks/queries/alert-escalations.queries';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';

import type { EncounterModuleInstance } from '../../Notes.types';
import { EncounterModuleId, TypeOfEncounter } from '../../Notes.types';
import { getInstance } from '../../utils/encounterModuleInstanceUtils';
import type { SymptomsInputs } from '../formatSymptomsInputs.util';

export function useShouldShowAlertEscalation() {
  const { enableAlertEscalations } = useFlags();

  return (encounterType?: TypeOfEncounter) => {
    if (!encounterType || !enableAlertEscalations) {
      return false;
    }

    return encounterType === TypeOfEncounter.ALERT_DOCUMENTATION;
  };
}

export function usePatientAlertEscalationRequiredFromEncounterInstances(
  patientId: string,
  instances: EncounterModuleInstance[],
) {
  const symptoms = getInstance(instances, EncounterModuleId.Symptoms);

  return usePatientAlertEscalationRequired(patientId, symptoms?.inputs ?? {});
}

export function usePatientAlertEscalationRequired(
  patientId: string,
  symptoms: SymptomsInputs,
) {
  const { currentUserId } = useCurrentUser();
  const { data: patient } = usePatientDetails(patientId, true);
  const { data: licensure } = useProviderStateLicensureByState(
    currentUserId,
    patient?.addresses?.find((addr) => addr.primary)?.region,
  );

  const { data: escalationsResponse, isLoading: escalationsLoading } =
    useCheckPatientAlertEscalationRequired(
      patientId,
      symptoms,
      licensure?.stateLicensure?.[0]?.licenseType,
    );

  if (escalationsLoading || !escalationsResponse) {
    return null;
  }
  return escalationsResponse;
}

export function getEscalationReasonsForVitals(
  data: CheckPatientAlertEscalationRequiredResponse,
) {
  const reasons = [];
  const redAlertCount = getRedAlertsCountLast7Days(data);
  const redAlertPercentage14Days = getRedAlertPercentageLast14Days(data);
  const redAlertPercentage30Days = getRedAlertPercentageLast30Days(data);

  if (redAlertCount > 0) {
    reasons.push(`Patient had red alerts ${redAlertCount} out of last 7 days`);
  }

  if (redAlertPercentage14Days > 0) {
    reasons.push(
      `${redAlertPercentage14Days}% of readings were red alerts last 14 days (min. 6 readings)`,
    );
  }

  if (redAlertPercentage30Days > 0) {
    reasons.push(
      `1 month (calendar): ${redAlertPercentage30Days}% of readings were red alerts (min. 4 readings)`,
    );
  }

  return reasons;
}

function getRedAlertsCountLast7Days(
  data: CheckPatientAlertEscalationRequiredResponse,
) {
  if (!data.escalationReasons) {
    return 0;
  }
  return Math.max(
    ...data.escalationReasons.map((e) => {
      switch (e) {
        case Reason.BLOOD_GLUCOSE_RED_ALERTS_IN_LAST_7_DAYS:
          return data.bloodGlucoseEscalation?.redAlertsInLast7DaysCount ?? 0;
        case Reason.BLOOD_PRESSURE_RED_ALERTS_IN_LAST_7_DAYS:
          return data.bloodPressureEscalation?.redAlertsInLast7DaysCount ?? 0;
        case Reason.HEART_RATE_RED_ALERTS_IN_LAST_7_DAYS:
          return data.heartRateEscalation?.redAlertsInLast7DaysCount ?? 0;
        case Reason.WEIGHT_RED_ALERTS_IN_LAST_7_DAYS:
          return data.weightEscalation?.redAlertsInLast7DaysCount ?? 0;
        default:
          return 0;
      }
    }),
  );
}

function getRedAlertPercentageLast14Days(
  data: CheckPatientAlertEscalationRequiredResponse,
) {
  if (!data.escalationReasons) {
    return 0;
  }
  return Math.max(
    ...data.escalationReasons.map((e) => {
      switch (e) {
        case Reason.BLOOD_GLUCOSE_PERCENTAGE_RED_ALERTS_IN_LAST_14_DAYS:
          return data.bloodGlucoseEscalation?.percentRedAlertsInLast14Days ?? 0;
        case Reason.BLOOD_PRESSURE_PERCENTAGE_RED_ALERTS_IN_LAST_14_DAYS:
          return (
            data.bloodPressureEscalation?.percentRedAlertsInLast14Days ?? 0
          );
        case Reason.HEART_RATE_PERCENTAGE_RED_ALERTS_IN_LAST_14_DAYS:
          return data.heartRateEscalation?.percentRedAlertsInLast14Days ?? 0;
        case Reason.WEIGHT_PERCENTAGE_RED_ALERTS_IN_LAST_14_DAYS:
          return data.weightEscalation?.percentRedAlertsInLast14Days ?? 0;
        default:
          return 0;
      }
    }),
  );
}

function getRedAlertPercentageLast30Days(
  data: CheckPatientAlertEscalationRequiredResponse,
) {
  if (!data.escalationReasons) {
    return 0;
  }
  return Math.max(
    ...data.escalationReasons.map((e) => {
      switch (e) {
        case Reason.BLOOD_GLUCOSE_PERCENTAGE_RED_ALERTS_IN_LAST_30_DAYS:
          return data.bloodGlucoseEscalation?.percentRedAlertsInLast30Days ?? 0;
        case Reason.BLOOD_PRESSURE_PERCENTAGE_RED_ALERTS_IN_LAST_30_DAYS:
          return (
            data.bloodPressureEscalation?.percentRedAlertsInLast30Days ?? 0
          );
        case Reason.HEART_RATE_PERCENTAGE_RED_ALERTS_IN_LAST_30_DAYS:
          return data.heartRateEscalation?.percentRedAlertsInLast30Days ?? 0;
        case Reason.WEIGHT_PERCENTAGE_RED_ALERTS_IN_LAST_30_DAYS:
          return data.weightEscalation?.percentRedAlertsInLast30Days ?? 0;
        default:
          return 0;
      }
    }),
  );
}
