import { useState } from 'react';
import { useIntl } from 'react-intl';

import { HorizontalDots } from '@/shared/assets/svgs';
import type { MedicationChange } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { Menu } from '@/shared/tempo/@labs/molecule/Menu';
import { IconButton } from '@/shared/tempo/atom/IconButton';

import { ConfirmTitrationModal } from './ConfirmTitrationModal';
import type { AllowedReason as RevertTitrationAllowedReason } from './RevertTitrationModal';
import { RevertTitrationModal } from './RevertTitrationModal';
import { FollowUpReason, ModalType } from './types';

type Props = {
  medChange: MedicationChange;
  referenceMedId: string;
  noteId: number;
};

export function RecommendOnlyActions({
  medChange,
  referenceMedId,
  noteId,
}: Props) {
  const intl = useIntl();
  const [followUpReason, setFollowUpReason] =
    useState<Nullable<FollowUpReason>>(null);
  const [modalType, setModalType] = useState<Nullable<ModalType>>(null);

  return (
    <>
      <Menu.Trigger
        placement="bottom right"
        menu={
          <Menu
            onAction={(key) => {
              setFollowUpReason(key as FollowUpReason);

              switch (key as FollowUpReason) {
                case FollowUpReason.ProviderAccepted:
                  setModalType(ModalType.ConfirmTitration);
                  break;
                case FollowUpReason.ProviderRejected:
                // fallthrough
                case FollowUpReason.ProviderUnreachable:
                  setModalType(ModalType.RevertTitration);
                  break;
                default:
                // noop, should not be possible
              }
            }}
          >
            <Menu.Item key={FollowUpReason.ProviderAccepted}>
              {intl.formatMessage({ defaultMessage: 'Provider accepted' })}
            </Menu.Item>
            <Menu.Item key={FollowUpReason.ProviderRejected}>
              {intl.formatMessage({ defaultMessage: 'Provider rejected' })}
            </Menu.Item>
            <Menu.Item key={FollowUpReason.ProviderUnreachable}>
              {intl.formatMessage({ defaultMessage: 'Provider unreachable' })}
            </Menu.Item>
          </Menu>
        }
      >
        <IconButton variant="secondary" size="small">
          <HorizontalDots />
        </IconButton>
      </Menu.Trigger>
      {modalType === ModalType.ConfirmTitration && (
        <ConfirmTitrationModal
          onClose={() => setModalType(null)}
          medChange={medChange}
          referenceMedId={referenceMedId}
          noteId={noteId}
        />
      )}
      {modalType === ModalType.RevertTitration && (
        <RevertTitrationModal
          onClose={() => setModalType(null)}
          medChange={medChange}
          // we can safely cast here since this modal type is only set when the followUpReason is one of these two
          reason={followUpReason as RevertTitrationAllowedReason}
          noteId={noteId}
        />
      )}
    </>
  );
}
