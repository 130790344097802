import cx from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Swap } from '@/shared/assets/svgs';
import { Modal } from '@/shared/common/Modal';
import {
  AsyncTitrationAsyncTitrationStatus as AsyncTitrationStatus,
  type MedicationChange,
} from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { useUpdateAsyncTitration } from '@/shared/hooks/queries/medication.queries';
import { flexContainer } from '@/shared/jsStyle/flex.css';
import { gap } from '@/shared/jsStyle/gap.css';
import { removeMargin } from '@/shared/jsStyle/utils.css';
import { Button } from '@/shared/tempo/atom/Button';

import { MedInfo } from '../MedInfo';
import { medInfo, swapIcon } from './recommendOnlyActions.css';
import { FollowUpReason } from './types';

export type AllowedReason =
  | FollowUpReason.ProviderRejected
  | FollowUpReason.ProviderUnreachable;

type Props = {
  onClose: () => void;
  medChange: MedicationChange;
  reason: AllowedReason;
  noteId: number;
};

const REASON_TO_STATUS: Record<AllowedReason, AsyncTitrationStatus> = {
  [FollowUpReason.ProviderRejected]: AsyncTitrationStatus.PROVIDER_REJECTED,
  [FollowUpReason.ProviderUnreachable]:
    AsyncTitrationStatus.PROVIDER_UNREACHABLE,
};

export function RevertTitrationModal({
  onClose,
  medChange,
  reason,
  noteId,
}: Props) {
  const { mutate: updateTitration, isLoading } = useUpdateAsyncTitration(
    medChange.asyncTitration?.inputPatientId ?? '',
    medChange.asyncTitration?.id ?? '',
  );

  const onConfirm = () => {
    updateTitration(
      {
        status: REASON_TO_STATUS[reason],
        providerDecisionNoteId: noteId,
      },
      {
        onSuccess: onClose,
      },
    );
  };

  return (
    <Modal open onClose={onClose}>
      <Modal.Header
        title={
          <FormattedMessage defaultMessage="Confirm titration status change" />
        }
      />
      <Modal.Body className={cx(flexContainer.column, gap.XS)}>
        <p className={removeMargin}>
          <FormattedMessage defaultMessage="Confirming will reset the medication to its state prior to the proposed titration:" />
        </p>
        <div className={medInfo}>
          <Swap className={swapIcon} />
          <MedInfo medChange={medChange} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onPress={onClose}>
          <FormattedMessage defaultMessage="Cancel" />
        </Button>
        <Button isProcessing={isLoading} onPress={onConfirm}>
          <FormattedMessage defaultMessage="Confirm" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
