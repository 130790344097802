import cx from 'classnames';
import { formatISO, parseISO } from 'date-fns';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import type { TitrationRecommendation } from '@/components/AsyncTitration/hooks';
import { isAsyncTitrationPending } from '@/components/AsyncTitration/statuses';
import { logger } from '@/logger';
import {
  hasActiveEhrMedication,
  hasMultipleActiveDoses,
  lastChange,
} from '@/pages/patients/PatientMedications/utils/medChangeUtils';
import { EllipseIcon } from '@/shared/assets/icons/Ellipse';
import type {
  Medication,
  MedicationChange,
} from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import {
  AsyncTitrationAsyncTitrationStatus,
  MedicationChangeChangeType,
  MedicationChangeStatus,
} from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { Tag } from '@/shared/tempo/atom/Tag';

import { TypeOfEncounter } from '../patientDetails/ui/Notes/Notes.types';
import { MedInfo } from './MedInfo';
import { MedicationRequiredActions } from './MedicationRequiredActions';
import {
  medRow,
  medRowContent,
  medRowTitle,
  medStatusIcon,
  partnerUpdateRow,
  rowBottomBorder,
  tagArea,
  updateDate,
} from './MedicationRow.css';
import { MedicationSubtext } from './MedicationSubtext';
import { MedicationActions } from './MedicationsActions/MedicationActions';
import { MedicationHistoryModal } from './MedicationsActions/MedicationHistoryModal';
import { usePatientMedicationsContext } from './PatientMedicationsContext';
import type { MedPermissions } from './PatientMedicationsList';
import { EditMedicationForm } from './forms/EditMedicationForm';
import { RemoveMedicationForm } from './forms/RemoveMedicationForm';
import { FormType } from './forms/formTypeEnum';
import { ManageMedicationForms } from './forms/manage/ManageMedicationForms';
import { SuggestTitrationForm } from './forms/manage/SuggestTitrationForm';
import { FormType as ManageMedsFormType } from './forms/manage/manageMedicationForms.type';
import { medicationFormContainer } from './forms/styles.css';
import { RxNormRestriction } from './forms/types';
import { medHistoryTypeTag, multipleDoseTag } from './styles.css';

type Props = {
  medChange: MedicationChange;
  medPermissions?: MedPermissions;
  medication?: Medication;
  existingReferencedMedIds?: Maybe<string>[];
  showHistory?: boolean;
  withBorder?: boolean;
  proposedChange?: MedicationChange;
  titrationRecommendation?: TitrationRecommendation;
  typeOfEncounter?: TypeOfEncounter;
  showRecommendOnlyAsyncTitrationActions?: boolean;
} & AllOrNone<{
  openFormType: Nullable<FormType>;
  onFormTypeChange: (formType: Nullable<FormType>) => void;
}>;

export function MedicationRow({
  medChange,
  medPermissions,
  medication,
  existingReferencedMedIds,
  showHistory = true,
  withBorder = true,
  openFormType: controlledOpenFormType,
  onFormTypeChange: controlledOnFormTypeChange,
  proposedChange,
  titrationRecommendation,
  typeOfEncounter,
  showRecommendOnlyAsyncTitrationActions,
}: Props) {
  const isProposing = typeOfEncounter === TypeOfEncounter.ASYNC_REVIEW;
  // only used if openFormType is not controlled
  const [internalOpenFormType, setInternalOpenFormType] =
    useState<Nullable<FormType>>(null);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const { showRequiredActions, noteId, setOriginalMedChange } =
    usePatientMedicationsContext();
  const referenceId = medication?.referenceMedicationId;

  const [suppressWarning, setSuppressWarning] = useState(false);

  function setInitialChange() {
    setOriginalMedChange(medChange, referenceId);
  }

  const { status, updatedDate, name: medChangeId } = medChange;

  if (!medChangeId) {
    logger.error('No id found for med change');
    return null;
  }

  const openFormType = controlledOpenFormType ?? internalOpenFormType;
  const onFormTypeChange =
    controlledOnFormTypeChange ?? setInternalOpenFormType;

  function handleClose() {
    onFormTypeChange(null);
    setSuppressWarning(false);
  }

  let renderedForm = null;
  switch (openFormType) {
    case FormType.Review:
      renderedForm = (
        <EditMedicationForm
          initValues={medChange}
          medChangeId={medChangeId}
          onClose={handleClose}
          referenceId={referenceId}
          existingReferencedMedIds={existingReferencedMedIds}
          rxNormRestriction={getRxNormRestriction(medication, noteId)}
          onSuccess={setInitialChange}
        />
      );
      break;
    case FormType.ManageRx:
      if (isProposing) {
        renderedForm = (
          <SuggestTitrationForm
            initValues={medChange}
            onClose={handleClose}
            referenceId={referenceId}
            rxNormRestriction={RxNormRestriction.CURRENT_MED}
            onSuccess={setInitialChange}
          />
        );
      } else {
        renderedForm = (
          <ManageMedicationForms
            initValues={
              proposedChange
                ? {
                    ...proposedChange,
                    isAsyncTitration: true,
                    asyncTitrationId: titrationRecommendation?.id,
                  }
                : medChange
            }
            onClose={handleClose}
            referenceId={referenceId}
            rxNormRestriction={RxNormRestriction.CURRENT_MED}
            onSuccess={setInitialChange}
            initialFormType={
              proposedChange ? ManageMedsFormType.Titrate : undefined
            }
          />
        );
      }
      break;
    case FormType.Remove:
      renderedForm = (
        <RemoveMedicationForm
          medChange={medChange}
          isDiseaseSpecific={Boolean(medication?.isDiseaseSpecific)}
          medChangeId={medChangeId}
          onClose={handleClose}
          onSuccess={setInitialChange}
        />
      );
      break;
    default:
  }

  const showUnstructuredWarning =
    (medication?.isDiseaseSpecific || false) &&
    medChange.status === MedicationChangeStatus.ACTIVE &&
    (!medChange.doseQuantities?.length || !medChange.frequencies?.length) &&
    !suppressWarning;
  const showInactiveInEhrWarning = !hasActiveEhrMedication(medication, noteId);
  const isPartnerUpdate =
    medChange.changeType === MedicationChangeChangeType.EMR_CAPTURE &&
    (medication?.medChanges || []).length > 1;
  const isMultipleDoseMed = hasMultipleActiveDoses(medication);
  return (
    <div
      className={cx({
        [partnerUpdateRow]: isPartnerUpdate,
        [medRow]: !isPartnerUpdate,
        [rowBottomBorder]: withBorder,
      })}
    >
      <div className={medRowContent}>
        <div className={medRowTitle}>
          <StatusIcon
            status={status}
            titrationRecommendation={titrationRecommendation}
          />
          <div>
            {(isPartnerUpdate || isMultipleDoseMed) && (
              <div className={tagArea}>
                {isMultipleDoseMed && (
                  <Tag variant="warning" className={multipleDoseTag}>
                    <FormattedMessage defaultMessage="Multiple Active Doses" />
                  </Tag>
                )}
                {isPartnerUpdate && (
                  <>
                    <Tag className={medHistoryTypeTag}>
                      <FormattedMessage defaultMessage="Partner Update" />
                    </Tag>
                    {updatedDate && (
                      <span className={updateDate}>
                        {formatISO(parseISO(updatedDate), {
                          representation: 'date',
                        })}
                      </span>
                    )}
                  </>
                )}
              </div>
            )}
            <MedInfo
              medChange={medChange}
              referenceMed={medication?.referenceMedication}
            />
          </div>
        </div>
        <MedicationActions
          isDiseaseSpecific={medication?.isDiseaseSpecific || false}
          medPermissions={medPermissions}
          medChangeId={medChangeId}
          medChange={medChange}
          showInactiveInEhrWarning={showInactiveInEhrWarning}
          showUnstructuredWarning={showUnstructuredWarning}
          onReview={() => onFormTypeChange(FormType.Review)}
          onRemove={() => onFormTypeChange(FormType.Remove)}
          onManageRx={() => onFormTypeChange(FormType.ManageRx)}
          onShowHistory={
            showHistory ? () => setIsHistoryModalOpen(true) : undefined
          }
          onClose={handleClose}
          onSuccess={setInitialChange}
          onVerifyUnstructured={() => {
            setSuppressWarning(true);
            onFormTypeChange(FormType.Review);
          }}
          typeOfEncounter={typeOfEncounter}
          showRecommendOnlyAsyncTitrationActions={
            showRecommendOnlyAsyncTitrationActions
          }
          referenceMedId={medication?.referenceMedicationId ?? ''}
        />
      </div>
      {(noteId === medChange.noteId ||
        isAsyncTitrationPending(titrationRecommendation, noteId)) && (
        <MedicationSubtext
          medChange={medChange}
          status={status}
          titrationRecommendation={titrationRecommendation}
          noteId={noteId}
        />
      )}
      {showRequiredActions && status !== MedicationChangeStatus.INACTIVE && (
        <MedicationRequiredActions
          medChange={medChange}
          isDiseaseSpecific={medication?.isDiseaseSpecific || false}
        />
      )}
      {renderedForm && (
        <div
          className={cx(medicationFormContainer.default, {
            [medicationFormContainer.withBorder]: !proposedChange,
          })}
        >
          {renderedForm}
        </div>
      )}
      {isHistoryModalOpen && medication && (
        <MedicationHistoryModal
          onClose={() => setIsHistoryModalOpen(false)}
          medication={medication}
        />
      )}
    </div>
  );
}

export function StatusIcon({
  status,
  fromHistoryModal = false,
  titrationRecommendation,
}: {
  status?: MedicationChangeStatus;
  fromHistoryModal?: boolean;
  titrationRecommendation?: TitrationRecommendation;
}) {
  const variant = statusToVariant(
    status,
    fromHistoryModal,
    titrationRecommendation,
  );

  return <EllipseIcon className={medStatusIcon[variant]} />;
}

function statusToVariant(
  status?: MedicationChangeStatus,
  fromHistoryModal?: boolean,
  titrationRecommendation?: TitrationRecommendation,
) {
  if (
    titrationRecommendation?.status ===
    AsyncTitrationAsyncTitrationStatus.INITIALLY_REVIEWED
  ) {
    return 'asyncTitrationPending';
  }

  switch (status) {
    case MedicationChangeStatus.ACTIVE:
      return 'active';
    case MedicationChangeStatus.INACTIVE:
      return 'inactive';
    case MedicationChangeStatus.NEEDS_REVIEW:
    default:
      if (fromHistoryModal) {
        return 'needs_review_in_modal';
      }
      return 'needs_review';
  }
}

// Constrain rx norm if there is a referenceId and there are any previous med changes with rxnorms
function getRxNormRestriction(
  med: Maybe<Medication>,
  noteId?: Nullable<number>,
) {
  if (!med) {
    return RxNormRestriction.NON_EXISTING_MEDS_FREE_TEXT;
  }
  const prevMedChange = lastChange(
    med.medChanges?.filter(
      (mc) =>
        Number(mc.noteId) !== noteId && mc.rxnorm?.id && mc.rxnorm?.id !== '0',
    ),
  );
  const lastRxNormId = prevMedChange?.rxnorm?.id;
  // TODO: clean up id check logic https://cadencerpm.atlassian.net/browse/PLAT-6388
  if (med.isDiseaseSpecific && lastRxNormId && lastRxNormId !== '0') {
    return RxNormRestriction.CURRENT_MED;
  }
  return RxNormRestriction.NON_EXISTING_MEDS_AND_CURRENT_FREE_TEXT;
}
