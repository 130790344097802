import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Change } from '@/shared/assets/svgs';
import type { Task } from '@/shared/tasking/types';
import { IconButton } from '@/shared/tempo/atom/IconButton';
import { Tooltip } from '@/shared/tempo/atom/Tooltip';
import { color } from '@/shared/tempo/theme';

import { EditTaskModal } from './EditTaskModal';

type Props = {
  task: Task;
  isDisabled: boolean;
};

export function EditAction({ task, isDisabled }: Props) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Tooltip
        isDisabled={isDisabled}
        placement="bottom"
        content={<FormattedMessage defaultMessage="Reassign" />}
      >
        <IconButton
          size="small"
          variant="tertiary"
          onPress={() => setModalOpen(true)}
        >
          <Change width={16} fill={color.Theme.Light.Primary} />
        </IconButton>
      </Tooltip>
      <EditTaskModal
        task={task}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
}
