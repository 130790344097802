import lowerFirst from 'lodash/lowerFirst';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useInProgressGoals } from '@/pages/patients/patientDetails/ui/tabs/CarePlan/Goals/goalUtils';
import type { GoalMeasure } from '@/pages/patients/patientDetails/ui/tabs/CarePlan/Goals/goals.types';
import { useGoalGoalMeasureI18n } from '@/pages/patients/patientDetails/ui/tabs/CarePlan/Goals/i18nMapping';
import { GoalCategory } from '@/shared/generated/grpc/go/pms/pkg/ccm_goal/ccm_goal.pb';

// Builds the context for the template used to populate Care Plan goals at the top of a Care Plan visit note
export function useCarePlanGoalsTemplateContext() {
  const { patientId } = useParams<{ patientId: string }>();
  const goals = useInProgressGoals(patientId);
  const intl = useIntl();

  const goalMeasureI18n = useGoalGoalMeasureI18n();

  const inProgressGoals = goals?.filter(
    // Don't show condition review goals at the top of the Care Plan -- these will be shown in the Condition section
    (goal) => !goal.isConditionReview,
  );

  const categories: {
    category: GoalCategory;
    label: string;
  }[] = [
    {
      category: GoalCategory.CONDITION_MANAGEMENT,
      label: intl.formatMessage({
        defaultMessage: 'Chronic Condition Management',
      }),
    },
    {
      category: GoalCategory.PREVENTATIVE_CARE,
      label: intl.formatMessage({ defaultMessage: 'Vaccines/Screenings' }),
    },
    {
      category: GoalCategory.SOCIAL_NEEDS,
      label: intl.formatMessage({
        defaultMessage: 'Social Determinants of Health',
      }),
    },
    {
      category: GoalCategory.LIFESTYLE,
      label: intl.formatMessage({ defaultMessage: 'Lifestyle Care' }),
    },
    {
      category: GoalCategory.GOAL_CATEGORY_UNSPECIFIED,
      label: intl.formatMessage({ defaultMessage: 'Other' }),
    },
  ];

  const categorizedGoals = categories.map(({ category, label }) => ({
    category,
    label,

    goals: inProgressGoals
      .filter((goal) => goal.category === category)
      .map(({ title, measure, description }) => {
        const measureLabel = goalMeasureI18n[measure as GoalMeasure];
        return {
          measure: measureLabel,
          title:
            title !== measureLabel
              ? intl.formatMessage(
                  { defaultMessage: 'Patient goal is to {goalTitle}' },
                  { goalTitle: lowerFirst(title) },
                )
              : undefined,
          description,
          categoryName: GoalCategory[category],
        };
      }),
  }));

  return {
    categorizedGoals,
  };
}
