import { useCallback, useEffect, useState } from 'react';
import type { UseInfiniteQueryResult } from 'react-query';
import type { OverlayTriggerState } from 'react-stately';

import { useFlatPages } from '@/reactQuery';
import { InfiniteScrollLoader } from '@/shared/common/InfiniteScrollLoader';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import type {
  CountTasksByTypeResponse,
  TaskType as TaskTypePb,
} from '@/shared/generated/grpc/go/pms/pkg/task/task.pb';
import type { PaginatedTasks } from '@/shared/hooks/queries/tasks.queries';
import {
  useAssignedTasksInfinite,
  useCombinedTasksWeighted,
  useFollowedTasksInfinite,
  useResolvedTasksInfinite,
  useTaskCountByType,
  useTaskTypesInfinite,
  useTeamTasksInfinite,
} from '@/shared/hooks/queries/tasks.queries';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { EmptyTasks } from '@/shared/tasking/EmptyTasks';
import { TaskCardCompact } from '@/shared/tasking/TaskCard/TaskCardCompact';
import { TaskCardSkeleton } from '@/shared/tasking/TaskCard/TaskCardSkeleton';
import { TaskCountHeader } from '@/shared/tasking/TaskCountHeader';
import {
  ParentView,
  TaskList as SharedTaskList,
} from '@/shared/tasking/TaskList';
import { compactContainer } from '@/shared/tasking/TaskList/TaskList.css';
import type { Task } from '@/shared/tasking/types';
import { TaskState } from '@/shared/tasking/types';

import { useFlags } from '../../shared/hooks';
import { startOfTodayUTC } from '../../shared/tasking/utils';
import { FilterCards } from './FilterCards';
import { filterContainer } from './FilterCards/FilterCards.css';
import { TabKey } from './TabKey';
import { useTaskFilterContext } from './TaskFilterContext';
import { TaskFilters } from './TaskFilters';
import {
  emptyTasksContainer,
  newUiPadding,
  taskListHeader,
} from './TaskHub.css';

type Props = {
  taskHubOverlayState?: OverlayTriggerState;
  parentView: ParentView;
  activeTaskId?: string;
  onOpenTaskDetail?: (task: Task) => void;
};

export function AssignedAndTeamTasksList(props: Props) {
  const { getFilter } = useTaskFilterContext();
  const query = useCombinedTasksWeighted(
    getFilter(TabKey.CombinedOpenAndTeam, TaskState.OPENED),
  );

  return (
    <TaskList {...props} query={query} tabKey={TabKey.CombinedOpenAndTeam} />
  );
}

export function AssignedTasksList(props: Props) {
  const { orderBy, getFilter } = useTaskFilterContext();
  const query = useAssignedTasksInfinite(
    getFilter(TabKey.Open, TaskState.OPENED),
    orderBy,
  );

  return <TaskList {...props} query={query} tabKey={TabKey.Open} />;
}

export function TeamTasksList(props: Props) {
  const { orderBy, getFilter } = useTaskFilterContext();
  const query = useTeamTasksInfinite(
    getFilter(TabKey.Team, TaskState.OPENED),
    orderBy,
  );

  return <TaskList {...props} query={query} tabKey={TabKey.Team} />;
}

export function FollowedTasksList(props: Props) {
  const { orderBy, getFilter } = useTaskFilterContext();
  const query = useFollowedTasksInfinite(
    getFilter(TabKey.Following, TaskState.OPENED),
    orderBy,
  );

  return <TaskList {...props} query={query} tabKey={TabKey.Following} />;
}

export function ResolvedTasksList(props: Props) {
  const { getFilter } = useTaskFilterContext();
  const query = useResolvedTasksInfinite(
    getFilter(TabKey.Resolved, TaskState.CLOSED),
    'updateTime desc, priority asc, uid desc',
  );

  return <TaskList {...props} query={query} tabKey={TabKey.Resolved} />;
}

type TaskListProps = Props & {
  query: UseInfiniteQueryResult<PaginatedTasks>;
  tabKey: TabKey;
  // only used for admin toolbox
  simulatingUserId?: string;
};

const SLA_RISK_THRESHOLD_HOURS = 4;

const PRIORITIZED_TASK_TYPES: Record<string, number> = {
  'NP alert escalation': 0,
  'NP escalation': 1,
  'Proactive Titration': 2,
  'Prescription Change': 3,
};

const LOWEST_RANK = 999;
const DEPRIORITIZED_TASK_TYPES = ['Reminder'];

const getTaskTypeRank = (typeName: string): number => {
  if (typeName in PRIORITIZED_TASK_TYPES) {
    return PRIORITIZED_TASK_TYPES[typeName];
  }

  if (DEPRIORITIZED_TASK_TYPES.includes(typeName)) {
    return LOWEST_RANK;
  }

  return LOWEST_RANK - 1;
};

export function TaskList({
  taskHubOverlayState,
  query,
  parentView,
  activeTaskId,
  onOpenTaskDetail,
  tabKey,
  simulatingUserId,
}: TaskListProps) {
  const { taskTypes: filteredTaskTypes, onTaskTypesChange } =
    useTaskFilterContext();
  const tasks = useFlatPages<Task, 'data'>(query);
  const taskTypes = useFlatPages<TaskTypePb, 'data'>(useTaskTypesInfinite({}));
  const sortedTaskTypes = taskTypes.sort((a, b) => {
    const rankA = getTaskTypeRank(a.typeName as string);
    const rankB = getTaskTypeRank(b.typeName as string);

    if (rankA !== rankB) {
      return rankA - rankB;
    }

    return a.typeName?.localeCompare(b.typeName as string) ?? 0;
  });
  const {
    counts,
    isLoading: isLoadingCounts,
    previousTabCount,
  } = useTaskCountForTab(tabKey);
  const hasTasks = Object.keys(counts).length > 0;
  const { currentUserId } = useCurrentUser();
  const effectiveProviderId =
    parentView === ParentView.Toolbox && simulatingUserId
      ? simulatingUserId
      : currentUserId;
  const bucketer = useCallback(
    (taskList: Task[]) => {
      if (tabKey !== TabKey.CombinedOpenAndTeam) {
        return { rest: taskList, urgent: [], ownPatient: [] };
      }

      const needsAttentionTypes = ['NP alert escalation', 'NP escalation'];

      return taskList.reduce(
        (acc, task) => {
          const isOwnPatient = !!task.weightValues.ownPatientWeight;
          const isAssigned = !!task.assignee;
          const isAssignedToUser =
            isAssigned && task.assignee?.uid === effectiveProviderId;
          const isUrgent = task.priority === 1;
          const isSlaRisk = task.hoursUntilSla <= SLA_RISK_THRESHOLD_HOURS;

          if (
            isSlaRisk &&
            (isUrgent ||
              needsAttentionTypes.includes(task.taskType.typeName as string))
          ) {
            acc.urgent.push(task);
            return acc;
          }

          if ((isOwnPatient && !isAssigned) || isAssignedToUser) {
            acc.ownPatient.push(task);
            return acc;
          }

          acc.rest.push(task);
          return acc;
        },
        { rest: [], urgent: [], ownPatient: [] } as Record<
          'rest' | 'urgent' | 'ownPatient',
          Task[]
        >,
      );
    },
    [tabKey, effectiveProviderId],
  );

  return (
    <>
      {hasTasks && !isLoadingCounts && (
        <div className={newUiPadding}>
          <FilterCards
            types={sortedTaskTypes}
            selectedType={filteredTaskTypes}
            onChangeSelected={onTaskTypesChange}
            typeCounts={counts}
          />
        </div>
      )}
      {previousTabCount > 0 && isLoadingCounts && (
        <div className={newUiPadding}>
          <div className={filterContainer} />
        </div>
      )}
      <div className={taskListHeader}>
        <TaskCountHeader query={query} tabKey={tabKey} />
        <TaskFilters tabKey={tabKey} />
      </div>
      <LoadingPlaceholder isLoading={query.isLoading}>
        {tasks.length ? (
          <SharedTaskList
            tasks={tasks}
            parentView={parentView}
            taskHubOverlayState={taskHubOverlayState}
            classes={{ container: compactContainer }}
            TaskCardComponent={TaskCardCompact}
            isCompact
            activeTaskId={activeTaskId}
            onOpenTaskDetail={onOpenTaskDetail}
            bucketFunction={bucketer}
            tab={tabKey}
          >
            <InfiniteScrollLoader
              query={query}
              loadingPlaceholder={<TaskCardSkeleton />}
              height={48}
            />
          </SharedTaskList>
        ) : (
          <div className={emptyTasksContainer.borderless}>
            <EmptyTasks />
          </div>
        )}
      </LoadingPlaceholder>
    </>
  );
}

function useTaskCountForTab(tabKey: TabKey) {
  const { getFilter } = useTaskFilterContext();
  const { healthSystemIds, hasAssignee } = getFilter(TabKey.Team);
  const { enableScheduledTasks } = useFlags();
  const { data: taskCounts, isLoading } = useTaskCountByType(
    healthSystemIds,
    hasAssignee,
    enableScheduledTasks ? startOfTodayUTC() : undefined,
  );
  const tabCounts = getCountForTab(taskCounts, tabKey) ?? {};
  const totalCount = Object.keys(tabCounts).length;

  // we aren't using usePrevious for this because the value of totalCount
  // gets blown away when we re-run useTaskCountByType, and we want to
  // maintain the actual value until a new value is loaded. this is used
  // to prevent a lot of UI jumping around
  const [previousTabCount, setPreviousTabCount] = useState(totalCount);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setPreviousTabCount(totalCount);
  }, [isLoading, totalCount]);

  return {
    previousTabCount,
    counts: Object.keys(tabCounts).reduce(
      (acc, key) => ({
        ...acc,
        [key]: parseInt(tabCounts[key], 10),
      }),
      {} as Record<string, number>,
    ),
    isLoading,
  };
}

function getCountForTab(
  counts: Maybe<CountTasksByTypeResponse>,
  tabKey: TabKey,
) {
  switch (tabKey) {
    case TabKey.Open:
      return counts?.assigned;
    case TabKey.Following:
      return counts?.following;
    case TabKey.Resolved:
      return counts?.resolved;
    case TabKey.Team:
      return counts?.team;
    case TabKey.CombinedOpenAndTeam:
      return counts?.allTeam;
    default:
      return undefined;
  }
}
