import { addDays, addMonths, endOfDay, format, subDays } from 'date-fns';
import { EditorState, convertToRaw } from 'draft-js';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import set from 'lodash/set';
import { useMemo, useState } from 'react';
import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import type { AnySchema, TestContext } from 'yup';
import { ValidationError as YupValidationError, mixed, object } from 'yup';

import { logger } from '@/logger';
import {
  getTotalTime,
  useTimeTrackingEditableDateRange,
} from '@/pages/patients/patientDetails/ui/shared/timeTracking.utils';
import type { UiSchemaLabels } from '@/shared/common/@deprecated/SchemaDrivenForm/';
import type { FormConfig } from '@/shared/common/Form';
import type { RequiredParams } from '@/shared/common/Form/validations';
import { validators } from '@/shared/common/Form/validations';
import { validateJsonSchemaData } from '@/shared/common/Form/validations/jsonSchemaValidator';
import { EHR } from '@/shared/generated/grpc/cadence/models/models.pb';
import { ProgramType } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { useFlags } from '@/shared/hooks';
import {
  useInstance,
  useRouteParamPatientDetailsGrpc,
} from '@/shared/hooks/queries';
import type { TNoteBodyRTF } from '@/shared/types/note.types';

import { useNoteEditorContext } from '../../NoteEditorContext';
import { useNoteBody } from '../../NotePreview/getVisitLayoutNoteBody';
import type { ProgramTimeEntry } from '../../Notes.types';
import {
  EncounterModuleId,
  type EncounterModuleInstance,
  type TimeEntry,
  TimeTrackedTaskType,
} from '../../Notes.types';
import {
  getEncounterModuleById,
  useEncounterModules,
} from '../../note.queries';
import { getEncounterTypeInstance } from '../../utils/encounterTypeUtils';
import { MEDICATIONS_VALIDATION_PROPERTY_NAMES } from '../MedicationsForm';
import { HOSPITALIZATION_FORMATTED_MESSAGES } from '../RecentHospitalizationForm';
import { CARE_PLAN_BODY_LABEL } from '../VisitLayout/CarePlanForm';
import { CLINICAL_ATTESTATION_BODY_LABEL } from '../VisitLayout/ClinicalAttestationForm';
import { getClinicalGoalReachedFormYupSchema } from '../VisitLayout/ClinicalGoalReachedForm';
import { ASSESSMENT_BODY_LABEL } from '../VisitLayout/GeneralAssessmentAndPlanForm';
import { NOTES_BODY_LABEL } from '../VisitLayout/PatientNotesForm';
import { useEncounterModuleInstances } from '../hooks/useEncounterModuleInstances.hook';
import { useGetIsTimeTrackedTypeOfEncounterFromInstances } from '../hooks/useIsTimeTrackedTypeOfEncounter';
import { ENCOUNTER_TYPE_FORMATTED_MESSAGES } from '../i18nMappings';
import type { NoteFormValues } from '../noteFormState';
import { getFieldLabels } from './validationFieldLabels';

export enum NoteFormSubmissionType {
  None,
  Draft,
  Publish,
}

export function useNoteFormConfig() {
  const [noteFormSubmissionType, setNoteFormSubmissionType] =
    useState<NoteFormSubmissionType>(NoteFormSubmissionType.None);
  const [lastSubmitTimestamp, setLastSubmitTimestamp] = useState(0);
  const getFormConfig = useGetFormConfig();

  return {
    enableValidation(submissionType: NoteFormSubmissionType) {
      setNoteFormSubmissionType(submissionType);
      const newLastSubmitTimestamp = Date.now();
      setLastSubmitTimestamp(newLastSubmitTimestamp);
      return getFormConfig(submissionType, newLastSubmitTimestamp);
    },
    disableValidation() {
      setNoteFormSubmissionType(NoteFormSubmissionType.None);
    },
    formConfig: getFormConfig(noteFormSubmissionType, lastSubmitTimestamp),
    noteFormSubmissionType,
  };
}

function useGetFormConfig() {
  const { noteEditorContent } = useNoteEditorContext();

  const intl = useIntl();
  const { maxLengthString, required, array, boolean } = validators(intl);
  const yupGetEncounterInstancesSchema = useGetYupEncounterInstancesSchema();
  const getTimeEntryYupSchema = useGetTimeEntryYupSchema();
  const memoEmptyArray = useMemo(() => [], []);
  const memoEmptyBody = useMemo(
    () => convertToRaw(EditorState.createEmpty().getCurrentContent()),
    [],
  );
  const memoEmptyTimeEntry = useMemo(
    () => ({
      start_date: new Date(),
      start_time: format(new Date(), 'HH:mm'),
      // Adding default empty array, so it is already one without having user to touch related checkboxes
      tasks_accomplished: [],
    }),
    [],
  );
  const initialTimeEntry = useMemo(
    () =>
      noteEditorContent?.timeTracking && {
        ...omit(noteEditorContent.timeTracking, ['start_datetime', 'entries']),
        ...(noteEditorContent.timeTracking.start_datetime && {
          start_date: new Date(noteEditorContent.timeTracking.start_datetime),
          start_time: format(
            new Date(noteEditorContent.timeTracking.start_datetime),
            'HH:mm',
          ),
        }),
        entries: noteEditorContent.timeTracking.entries?.reduce(
          (acc, entry) => ({
            ...acc,
            [entry.program_type]: entry,
          }),
          {} as TimeEntry['entries'],
        ),
      },
    [noteEditorContent?.timeTracking],
  );
  const { data: patientDetails } = useRouteParamPatientDetailsGrpc();
  const ehr = patientDetails?.ehrInformation?.ehr;

  const ehrHospital = patientDetails?.ehrInformation?.hospital?.ehrInfo;
  const instanceId =
    ehrHospital?.epicHospitalInfo?.instanceId ||
    ehrHospital?.athenaHospitalInfo?.instanceId ||
    ehrHospital?.cernerHospitalInfo?.instanceId;
  const { data: instance } = useInstance(instanceId || '', {
    enabled: !!instanceId && ehr === EHR.EPIC,
  });

  const epicHasAssignedProvider =
    instance?.epicConfig?.actionableNotesAssignedProvider;

  const providerSearchDisabled = ehr === EHR.EPIC && !epicHasAssignedProvider;
  const bodyYupSchema = useBodyYupSchema(ehr);

  return (
    noteFormSubmissionType: NoteFormSubmissionType,
    lastSubmitTimestamp: number,
  ) => {
    const formConfig: FormConfig = {
      fields: {
        // No form field for zendesk ticket, but we still want to track the
        // state of it
        zendeskTicket: {
          defaultValue: noteEditorContent?.zendeskTicket ?? null,
        },
        // No form field for appointment id, but we still want to track the
        // state of it
        appointmentId: {
          defaultValue: noteEditorContent?.appointmentId ?? null,
        },
        // No form field for no show appointment id, but we still want to track the
        // state of it
        noShowAppointmentId: {
          defaultValue: noteEditorContent?.noShowAppointmentId ?? null,
        },
        // No form field for timeElapsed, but we still want to track the
        // state of it
        timeElapsed: {
          defaultValue: noteEditorContent?.timeElapsed ?? null,
        },
        title: {
          defaultValue: noteEditorContent?.title ?? '',
          validation: required({
            schema: maxLengthString({
              maxLength: 30,
              errorMessage: intl.formatMessage({
                defaultMessage: 'Title has a character limit of 30',
              }),
            }),
            errorMessage: intl.formatMessage({
              defaultMessage: 'Title is required',
            }),
          }),
        },
        body: {
          defaultValue: noteEditorContent?.body ?? memoEmptyBody,
          validation: bodyYupSchema,
        },
        labels: {
          defaultValue: noteEditorContent?.labels ?? memoEmptyArray,
          validation: array({
            ofType: mixed(),
          }),
        },
        actionRequired: {
          defaultValue: noteEditorContent?.actionRequired ?? false,
          validation: boolean(),
        },
        escalationMessage: {
          defaultValue: noteEditorContent?.escalationMessage ?? undefined,
          validation: maxLengthString({
            maxLength: MAX_ESCALATION_MESSAGE_LENGTH,
          }).when('actionRequired', {
            is: true,
            then: (schema) =>
              providerSearchDisabled
                ? schema
                : required({
                    schema,
                    errorMessage: intl.formatMessage({
                      defaultMessage: 'Escalation message is required',
                    }),
                  }),
          }),
        },
        urgent: {
          defaultValue: noteEditorContent?.urgent ?? false,
          validation: boolean(),
        },
        externalProviderId: {
          defaultValue: noteEditorContent?.externalProviderId ?? undefined,
          validation: yup.string().when('actionRequired', {
            is: true,
            then: (schema) =>
              providerSearchDisabled
                ? schema
                : required({
                    schema,
                    errorMessage: intl.formatMessage({
                      defaultMessage: 'Provider is required',
                    }),
                  }),
          }),
        },
        encounterModuleInstances: {
          validation: yupGetEncounterInstancesSchema(
            lastSubmitTimestamp,
            noteFormSubmissionType,
          ),
        },
        timeEntry: {
          defaultValue: initialTimeEntry ?? memoEmptyTimeEntry,
          validation: getTimeEntryYupSchema(noteFormSubmissionType),
        },
        endEncounter: {
          defaultValue: noteEditorContent?.endEncounter ?? undefined,
          validation: yup.object({
            endType: yup.string().nullable(),
            endReason: yup.string().nullable(),
            endNote: yup.string().nullable(),
          }),
        },
      },
    };

    const disabledValidationFormConfig = {
      fields: Object.fromEntries(
        Object.entries(formConfig.fields).map(([name, field]) => [
          name,
          { defaultValue: field.defaultValue },
        ]),
      ),
    };

    return noteFormSubmissionType === NoteFormSubmissionType.None
      ? disabledValidationFormConfig
      : formConfig;
  };
}

const MAX_NOTE_LENGTH = 20000;
const MAX_ATHENA_ACTIONABLE_NOTE_LENGTH = 3320;
const MAX_ESCALATION_MESSAGE_LENGTH = 3000;

function useBodyYupSchema(ehr: EHR | undefined) {
  const intl = useIntl();
  const { patientId } = useParams<{ patientId: string }>();
  const getBody = useNoteBody(patientId);
  return mixed().test((body: TNoteBodyRTF, context: TestContext) => {
    const noteFormValues = context.parent as NoteFormValues;

    if (!body?.blocks) {
      return true;
    }

    const bodyText = getBody(noteFormValues.encounterModuleInstances, null);

    // Athena Patient Cases have a lower length cap, but we use the escalationMessage
    // for this if provided.
    const maxNoteLength =
      ehr === EHR.ATHENA &&
      noteFormValues.actionRequired &&
      !noteFormValues.escalationMessage
        ? MAX_ATHENA_ACTIONABLE_NOTE_LENGTH
        : MAX_NOTE_LENGTH;
    return bodyText.length > maxNoteLength
      ? context.createError({
          message: `${intl.formatMessage({
            defaultMessage: 'Description has a character limit of',
          })} ${intl.formatNumber(maxNoteLength)}. ${intl.formatMessage({
            defaultMessage: 'Current length:',
          })} ${intl.formatNumber(bodyText.length)}`,
          type: 'max',
        })
      : true;
  });
}

function useGetYupEncounterInstancesSchema() {
  const intl = useIntl();
  const { data: encounterModules, isLoading: isLoadingEncounterModules } =
    useEncounterModules();
  const { disableDraftNoteValidation, gatherNonTitrationReasons } = useFlags();

  return (
    lastSubmitTimestamp: number,
    noteFormSubmissionType: NoteFormSubmissionType,
  ) =>
    mixed().test((encounterModuleInstances: EncounterModuleInstance[]) => {
      if (
        disableDraftNoteValidation &&
        noteFormSubmissionType !== NoteFormSubmissionType.Publish
      ) {
        return true;
      }

      const yupErrors = encounterModuleInstances
        .filter(
          (instance) =>
            (instance.addedToFormTimestamp ?? 0) <= lastSubmitTimestamp,
        )
        .map((instance) => {
          const encounterModulesUsingYup = new Map([
            [
              EncounterModuleId.ClinicalGoalReached,
              () =>
                getClinicalGoalReachedFormYupSchema(
                  intl,
                  instance.inputs,
                  getEncounterTypeInstance(encounterModuleInstances),
                  gatherNonTitrationReasons,
                ),
            ],
          ]);

          let instanceValidationErrorOrTrue: boolean | YupValidationError =
            true;
          if (encounterModulesUsingYup.has(instance.encounter_module_id)) {
            const yupSchema =
              encounterModulesUsingYup.get(instance.encounter_module_id)?.() ??
              yup.mixed();
            let yupValidationError: Nullable<YupValidationError> = null;
            try {
              yupSchema.validateSync(instance.inputs, { abortEarly: false });
            } catch (error) {
              if (error instanceof YupValidationError) {
                yupValidationError = error;
              } else {
                throw error;
              }
            }
            instanceValidationErrorOrTrue = yupValidationError ?? true;
          } else {
            const schema = getEncounterModuleById(
              encounterModules,
              instance.encounter_module_id,
            )?.schema;
            if (!schema) {
              if (!isLoadingEncounterModules) {
                logger.error(
                  `Could not find schema for encounter module with id ${instance.encounter_module_id}`,
                );
              }
              return true;
            }
            instanceValidationErrorOrTrue = validateJsonSchemaData(
              intl,
              {
                jsonSchema: schema,
                labels: {
                  ...allFormattedMessages,
                  ...getFieldLabels(intl.formatMessage),
                },
              },
              instance.inputs,
            );
          }
          if (typeof instanceValidationErrorOrTrue === 'object') {
            setEncounterModuleIdInErrorParams(
              instanceValidationErrorOrTrue,
              instance.encounter_module_id,
            );
          }
          return instanceValidationErrorOrTrue;
        })
        .filter((result) => result !== true) as YupValidationError[];
      return yupErrors.length ? new YupValidationError(yupErrors) : true;
    });
}

const allFormattedMessages: UiSchemaLabels = merge(
  {},
  HOSPITALIZATION_FORMATTED_MESSAGES,
  ENCOUNTER_TYPE_FORMATTED_MESSAGES,
  MEDICATIONS_VALIDATION_PROPERTY_NAMES,
  {
    assessment_body: ASSESSMENT_BODY_LABEL,
    notes_body: NOTES_BODY_LABEL,
    clinical_attestation_body: CLINICAL_ATTESTATION_BODY_LABEL,
    care_plan_body: CARE_PLAN_BODY_LABEL,
  },
);

function setEncounterModuleIdInErrorParams(
  error: YupValidationError,
  encounterModuleId: EncounterModuleId,
) {
  set(error, 'params.encounterModuleId', encounterModuleId);
  if (error.inner.length) {
    error.inner.forEach((innerError) =>
      setEncounterModuleIdInErrorParams(innerError, encounterModuleId),
    );
  }
}

const DATE_FORMAT = 'MM/dd/yyyy';

function useGetTimeEntryYupSchema() {
  const intl = useIntl();
  function validateTotalTime<S extends yup.AnySchema>(schema: S) {
    return schema.test(
      'total-time',
      intl.formatMessage({
        defaultMessage: 'Total time spent on encounter must be 0-120 mins',
      }),
      (_, context: TestContext) => {
        const fields = context.parent as TimeEntry;
        const totalTime = getTotalTime(fields);
        return totalTime >= 0 && totalTime <= 120;
      },
    );
  }

  const { encounterTypeInstance } = useEncounterModuleInstances();
  const patientNoShow = !!encounterTypeInstance?.inputs.patient_no_show;
  const { minDate: minStartDate } = useTimeTrackingEditableDateRange();
  const { required, number, date } = validators(intl);
  const getIsTimeTrackedTypeOfEncounter =
    useGetIsTimeTrackedTypeOfEncounterFromInstances();
  return (noteFormSubmissionType: NoteFormSubmissionType) => {
    const requiredIfPublish = (
      params: RequiredParams,
      disabled: boolean = false,
    ) =>
      noteFormSubmissionType === NoteFormSubmissionType.Publish && !disabled
        ? required(params)
        : params.schema;

    return object().when('encounterModuleInstances', {
      is: (encounterModuleInstances: EncounterModuleInstance[] = []) =>
        getIsTimeTrackedTypeOfEncounter(encounterModuleInstances),
      then: (schema) =>
        schema.shape({
          start_date: requiredIfPublish({
            schema: date({
              minDate: minStartDate,
              minDateErrorMessage: intl.formatMessage(
                {
                  defaultMessage: 'Date of encounter must be after {minDate}',
                },
                { minDate: format(subDays(minStartDate, 1), DATE_FORMAT) },
              ),
              ...(noteFormSubmissionType === NoteFormSubmissionType.Publish
                ? {
                    maxDate: endOfDay(new Date()),
                    maxDateErrorMessage: intl.formatMessage(
                      {
                        defaultMessage:
                          'Date of encounter must be before {tomorrowDate}',
                      },
                      {
                        tomorrowDate: format(
                          addDays(new Date(), 1),
                          DATE_FORMAT,
                        ),
                      },
                    ),
                  }
                : {
                    maxDate: addMonths(endOfDay(new Date()), 1),
                    maxDateErrorMessage: intl.formatMessage(
                      {
                        defaultMessage:
                          'Date of encounter must be before {monthAndDayFromNowDate}',
                      },
                      {
                        monthAndDayFromNowDate: format(
                          addDays(addMonths(new Date(), 1), 1),
                          DATE_FORMAT,
                        ),
                      },
                    ),
                  }),
              invalidDateErrorMessage: intl.formatMessage({
                defaultMessage: 'Date of encounter must be a valid date',
              }),
            }),
            errorMessage: intl.formatMessage({
              defaultMessage: 'Date of encounter is required',
            }),
          }),
          start_time: requiredIfPublish({
            schema: yup.string(),
            errorMessage: intl.formatMessage({
              defaultMessage: 'Start of encounter must be a valid time',
            }),
          }),
          totalTimeDisplay: validateTotalTime(number({})),
          // not sure what eslint is complaining about here - it doesn't like `object`
          // eslint-disable-next-line react/forbid-prop-types
          entries: object({
            [ProgramType.RPM]: getProgramTimeEntryValidator(
              intl,
              patientNoShow,
              requiredIfPublish,
              noteFormSubmissionType,
            ),
          }),
        }),
      otherwise: (schema) => schema.shape({}),
    });
  };
}

export function getMaxDate() {
  return endOfDay(new Date());
}

function getProgramTimeEntryValidator(
  intl: IntlShape,
  patientNoShow: boolean,
  requiredIfPublish: (params: RequiredParams, disabled?: boolean) => AnySchema,
  noteFormSubmissionType: NoteFormSubmissionType,
) {
  const { maxLengthString, required, number, array } = validators(intl);

  function tasksAccomplishedArray(minLength: number) {
    return array({
      ofType: yup.string(),
      minLength,
      minLengthMessage: intl.formatMessage({
        defaultMessage: 'Tasks accomplished is required',
      }),
    });
  }

  return object({
    interactive_duration: requiredIfPublish(
      {
        schema: number({
          min: 0,
          max: 120,
          integer: true,
          errorMessage: intl.formatMessage({
            defaultMessage: 'Interactive time must be 0-120 mins',
          }),
        }),
        errorMessage: intl.formatMessage({
          defaultMessage: 'Interactive time is required',
        }),
      },
      // Field is disabled if this is true
      patientNoShow,
    ),
    non_interactive_duration: requiredIfPublish({
      schema: number({
        min: 0,
        max: 120,
        integer: true,
        errorMessage: intl.formatMessage({
          defaultMessage: 'Non-interactive time must be 0-120 mins',
        }),
      }),
      errorMessage: intl.formatMessage({
        defaultMessage: 'Non-interactive time is required',
      }),
    }),
    tasks_accomplished: tasksAccomplishedArray(0).when(
      ['non_interactive_duration'],
      {
        is: (nonInteractiveDuration?: number) =>
          nonInteractiveDuration &&
          noteFormSubmissionType === NoteFormSubmissionType.Publish,
        then: () =>
          required({
            schema: tasksAccomplishedArray(1),
            errorMessage: intl.formatMessage({
              defaultMessage: 'Tasks accomplished is required',
            }),
          }),
      },
    ),
    other_task_description: maxLengthString({
      maxLength: 400,
      errorMessage: intl.formatMessage({
        defaultMessage: 'Task description may not exceed 400 characters',
      }),
    }).test(
      'required',
      intl.formatMessage({
        defaultMessage: 'Task description is required',
      }),
      (value: string | undefined, context: TestContext) => {
        const fields = context.parent as ProgramTimeEntry;
        const shouldValidate =
          noteFormSubmissionType === NoteFormSubmissionType.Publish;

        if (!shouldValidate) {
          return true;
        }

        return (
          !(fields.tasks_accomplished || []).includes(
            TimeTrackedTaskType.Other,
          ) || !!value
        );
      },
    ),
  });
}
