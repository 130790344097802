import first from 'lodash/first';
import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

import { CareProviderRole } from '@/shared/generated/grpc/cadence/models/models.pb';
import type { CareProviderStateLicensure } from '@/shared/generated/grpc/go/pms/pkg/care_provider/care_provider.pb';
import type { CheckPatientAlertEscalationRequiredResponse } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { CheckPatientAlertEscalationRequiredResponseEscalationReason as EscalationReason } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { useFlags } from '@/shared/hooks';
import {
  usePatientDetails,
  useProviderStateLicensureByState,
} from '@/shared/hooks/queries';
import type { MedManagementDelegationUiMappingValue } from '@/shared/hooks/queries/med-management.queries';
import {
  MedManagementDelegationUiMapping,
  useMedManagementDelegation,
} from '@/shared/hooks/queries/med-management.queries';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import type { FeatureFlagSet } from '@/shared/types/featureFlags.types';
import type {
  AllBloodPressureTagType,
  AllHeartRateTagType,
  AllWeightGainTagType,
  BloodGlucoseTagType,
} from '@/shared/types/tagsAndThreshold.types';

import { MarkdownBuilder } from '../../../../PatientProfile/CNNotesSidebarPanel/templates/engine/MarkdownBuilder';
import {
  isBgRelatedTags,
  isBpRelatedTags,
  isHeartRateRelatedTags,
  isWeightRelatedTags,
} from '../../AlertDescription';
import {
  BloodGlucoseAlertFormatter,
  BloodPressureAlertFormatter,
  HeartRateAlertFormatter,
  WeightFormatter,
} from '../../AlertDescription/utils/alertDescriptionFormatters';
import { showMedReview } from '../NoteEditor/utils/medReviewUtils';
import { useNoteEditorContext } from '../NoteEditorContext';
import type { EditableNoteState } from '../Notes.types';
import {
  EditableNoteType,
  type EncounterModuleId,
  type EncounterModuleInstance,
  type EncounterTypeInputs,
} from '../Notes.types';
import { getEncounterTypeInstance } from '../utils/encounterTypeUtils';
import { convertDraftJsToMarkdown } from '../utils/rtfBodyUtil';
import { InputsPreview } from './InputsPreview';
import { getMarkdownFromNode } from './getMarkdownFromNode';
import { OutputType, getVisitLayoutOutput } from './getVisitLayoutOutput';
import {
  getEscalationReasonsForVitals,
  useShouldShowAlertEscalation,
} from './hooks/useAlertEscalationsList';
import {
  showDiseaseSpecificMeds,
  useGetDiseaseSpecificMedsNoteBody,
} from './hooks/useDSMedsNoteBody';
import {
  shouldShowAsyncTitration,
  useGetAsyncTitrationNoteBody,
} from './hooks/useGetAsyncTitrationNoteBody';
import { useGetMedReviewNoteBody } from './hooks/useMedReviewNoteBody';

export function useNoteBody(patientId: string) {
  const intl = useIntl();
  const flags = useFlags();
  const { careModelVersion } = flags;
  const { editingNote } = useNoteEditorContext();
  const { currentUserId } = useCurrentUser();
  const { data: patient } = usePatientDetails(patientId, true);
  const { medDelegation } = useMedManagementDelegation(patient);
  const { data: licensure } = useProviderStateLicensureByState(
    currentUserId,
    patient?.addresses?.find((addr) => addr.primary)?.region,
  );

  const getMedReviewNoteBody = useGetMedReviewNoteBody(
    patientId,
    editingNote?.note?.id,
  );

  const getDiseaseSpecificMedsNoteBody = useGetDiseaseSpecificMedsNoteBody(
    patientId,
    editingNote?.note?.id,
  );
  const getAsyncTitrationNoteBody = useGetAsyncTitrationNoteBody(
    patientId,
    editingNote?.note?.id,
  );

  const shouldShowAlertEscalation = useShouldShowAlertEscalation();

  return (
    encounterData: EncounterModuleInstance[],
    alertEscalations: CheckPatientAlertEscalationRequiredResponse | null,
  ) => {
    const encounterInputs =
      getEncounterTypeInstance(encounterData)?.inputs || {};
    const {
      patient_no_show: patientNoShow,
      has_meds_to_report: hasMedsToReport,
      type_of_encounter: encounterType,
    } = encounterInputs;
    const patientDeclinedMedReview = hasMedsToReport === false;

    const moduleNoteBodies = getVisitLayoutOutput(
      encounterData,
      getInputsNoteBody,
      convertDraftJsToMarkdown,
      intl,
      careModelVersion,
      OutputType.PublishedNoteBody,
      showMedReview(encounterData) && !patientNoShow
        ? getMedReviewOutput(
            intl,
            patientDeclinedMedReview,
            getMedReviewNoteBody(encounterType),
          )
        : null,
      showDiseaseSpecificMeds(encounterData) && !patientNoShow
        ? getDiseaseSpecificMedsNoteBody(encounterType)
        : null,
      shouldShowAsyncTitration(encounterType)
        ? getAsyncTitrationNoteBody(
            encounterType,
            patientNoShow ?? false,
            getMedReviewOutput(
              intl,
              patientDeclinedMedReview,
              getMedReviewNoteBody(encounterType, true),
              false,
            ),
          )
        : null,
      shouldShowAlertEscalation(encounterType) && alertEscalations
        ? getAlertEscalationsNoteBody(
            intl,
            flags,
            editingNote,
            alertEscalations,
            first(licensure?.stateLicensure),
            medDelegation,
          )
        : null,
    );
    return (
      moduleNoteBodies?.filter((value) => value !== null).join('\n\n') ?? ''
    );
  };
}

function getInputsNoteBody<T extends {}>(
  moduleId: EncounterModuleId,
  encounterTypeInstance: Maybe<EncounterModuleInstance<EncounterTypeInputs>>,
  inputs: T,
) {
  return getMarkdownFromNode(
    <InputsPreview
      encounterModuleId={moduleId}
      encounterTypeInstance={encounterTypeInstance}
      inputs={inputs}
    />,
  );
}

function getMedReviewOutput(
  intl: IntlShape,
  patientDeclinedMedReview: boolean,
  medReviewNoteBody: Nullable<string>,
  withTitle = true,
) {
  let medReviewContent = medReviewNoteBody;

  if (patientDeclinedMedReview) {
    medReviewContent = intl.formatMessage({
      defaultMessage: 'Patient declined to review medications',
    });
  } else if (!medReviewNoteBody) {
    medReviewContent = intl.formatMessage({
      defaultMessage: 'No medications to review',
    });
  }

  const title = intl.formatMessage({
    defaultMessage: 'Medication Review',
  });

  if (!withTitle) {
    return medReviewContent;
  }

  const titleMarkdown = `### ${title}\n`;

  return titleMarkdown + medReviewContent;
}

function getAlertEscalationsNoteBody(
  intl: IntlShape,
  flags: FeatureFlagSet,
  editingNote?: Nullable<EditableNoteState>,
  escalationsResponse?: CheckPatientAlertEscalationRequiredResponse,
  licensure?: CareProviderStateLicensure,
  medManagementDelegation?: MedManagementDelegationUiMappingValue,
): Nullable<string> {
  const wF = new WeightFormatter(intl);
  const bgF = new BloodGlucoseAlertFormatter(intl, flags);
  const hrF = new HeartRateAlertFormatter(intl);
  const bpF = new BloodPressureAlertFormatter(intl);

  const alertTitles =
    editingNote?.type === EditableNoteType.Alert &&
    editingNote?.alert?.alert_tags.reduce((acc, tag) => {
      const leader = `${acc}${acc ? ',' : ''}`;

      if (isBpRelatedTags(tag)) {
        return `${leader}${bpF.getTitle(tag as AllBloodPressureTagType, '')}`;
      }
      if (isWeightRelatedTags(tag)) {
        return `${leader}${wF.getTitle(tag as AllWeightGainTagType, '')}`;
      }
      if (isBgRelatedTags(tag)) {
        return `${leader}${bgF.getTitle(tag as BloodGlucoseTagType, '')}`;
      }
      if (isHeartRateRelatedTags(tag)) {
        return `${leader}${hrF.getTitle(tag as AllHeartRateTagType, '')}`;
      }
      return acc;
    }, '' as string);
  const reviewedText = alertTitles ? `Alert for ${alertTitles} reviewed.` : '';

  const md = new MarkdownBuilder();

  md.h4('Objective:');

  if (escalationsResponse) {
    if (
      escalationsResponse.escalationReasons?.some(
        (v) => v !== EscalationReason.NEW_OR_WORSENING_SYMPTOMS,
      )
    ) {
      md.p(`${reviewedText} Patient meets the following escalation criteria:`);
      const reasons = getEscalationReasonsForVitals(escalationsResponse);
      if (reasons.length !== 0) {
        md.newline();
        reasons.forEach((e) => md.uli(e));
      }
    } else {
      md.p(
        `${reviewedText} Patient does not meet vital based escalation criteria.`,
      );
    }
  } else {
    md.p(`${reviewedText} Patient does not meet escalation criteria.`);
  }

  if (
    medManagementDelegation !==
      MedManagementDelegationUiMapping.OptOutProviderManaged &&
    escalationsResponse?.escalationReasons?.length
  ) {
    const hasSymptomsEscalation = escalationsResponse.escalationReasons.some(
      (v) => v === EscalationReason.NEW_OR_WORSENING_SYMPTOMS,
    );
    const hasVitalsEscalation = escalationsResponse.escalationReasons.some(
      (v) => v !== EscalationReason.NEW_OR_WORSENING_SYMPTOMS,
    );

    const isLpn = licensure?.licenseType === CareProviderRole.LPN;

    // LPNs should only escalate based on vitals, so we don't generate anything if we have
    // an LPN and symptoms escalation criteria
    if (!isLpn && hasSymptomsEscalation && hasVitalsEscalation) {
      md.p(
        'Based on recent vital trends and reported symptoms, patient will be escalated to NP.',
      );
    } else if (!isLpn && hasSymptomsEscalation) {
      md.p('Based on reported symptoms, patient will be escalated to NP.');
    } else if (hasVitalsEscalation) {
      md.p('Based on recent vital trends, patient will be escalated to NP.');
    }
  }

  return md.toString();
}
