import type { ReactElement } from 'react';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { Tag } from '@/shared/tempo/atom/Tag';

import { GoalMeasure } from '../../../Goals/goals.types';
import { getAssessmentFieldNames } from '../../formConfig';
import { GapWidget } from './GapWidget';
import { FoodFrequencyChoice, FoodSecurityChoice } from './foodSecurity.types';
import * as styles from './shared.css';
import type { PsychosocialAssessmentComponentProps } from './shared.types';

export function FoodSecurity({
  form,
  createGoalWithMeasure,
  hasGap,
  onEditGoal,
  carePlanId,
}: PsychosocialAssessmentComponentProps): ReactElement {
  const intl = useIntl();
  const foodSecurityChoice = form.watch('foodSecurityChoice');
  const showNotes = foodSecurityChoice === FoodSecurityChoice.Yes;

  const { description: descriptionFieldName, checked: checkedFieldName } =
    getAssessmentFieldNames('food');

  const hasGapVal = hasGap(form.getValues());
  useEffect(() => {
    form.setValue(checkedFieldName, hasGapVal, { shouldValidate: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasGapVal]);

  return (
    <>
      <div className={styles.leftColumn}>
        <Form.RadioGroup
          name="foodSecurityChoice"
          label={
            <>
              <Tag variant="default" className={styles.sectionTag}>
                <FormattedMessage defaultMessage="Food Security" />
              </Tag>{' '}
              <FormattedMessage defaultMessage="In the past 12 months, have you ever had to worry about not having enough food?" />
            </>
          }
          orientation="horizontal"
          size={11}
          required
          onChange={(value) => {
            if (value === FoodSecurityChoice.No) {
              form.setValue('foodWorryFrequency', undefined, {
                shouldValidate: true,
              });
              form.setValue('foodRunOutFrequency', undefined, {
                shouldValidate: true,
              });
            }
          }}
        >
          <Form.Radio value={FoodSecurityChoice.No}>
            <FormattedMessage defaultMessage="No" />
          </Form.Radio>
          <Form.Radio value={FoodSecurityChoice.Yes}>
            <FormattedMessage defaultMessage="Yes" />
          </Form.Radio>
        </Form.RadioGroup>

        {showNotes && (
          <div className={styles.detailContainer}>
            <Form.RadioGroup
              name="foodWorryFrequency"
              label={
                <FormattedMessage defaultMessage="Patient worried that food would run out:" />
              }
              orientation="horizontal"
              size={11}
              required
            >
              <Form.Radio value={FoodFrequencyChoice.Never}>
                <FormattedMessage defaultMessage="Never" />
              </Form.Radio>
              <Form.Radio value={FoodFrequencyChoice.Sometimes}>
                <FormattedMessage defaultMessage="Sometimes" />
              </Form.Radio>
              <Form.Radio value={FoodFrequencyChoice.Often}>
                <FormattedMessage defaultMessage="Often" />
              </Form.Radio>
            </Form.RadioGroup>

            <Form.RadioGroup
              name="foodRunOutFrequency"
              label={
                <FormattedMessage defaultMessage="Patient had food run out:" />
              }
              orientation="horizontal"
              size={11}
              required
            >
              <Form.Radio value={FoodFrequencyChoice.Never}>
                <FormattedMessage defaultMessage="Never" />
              </Form.Radio>
              <Form.Radio value={FoodFrequencyChoice.Sometimes}>
                <FormattedMessage defaultMessage="Sometimes" />
              </Form.Radio>
              <Form.Radio value={FoodFrequencyChoice.Often}>
                <FormattedMessage defaultMessage="Often" />
              </Form.Radio>
            </Form.RadioGroup>
          </div>
        )}

        {hasGapVal && (
          <div className={styles.gapMessage}>
            <FormattedMessage defaultMessage="Patient has a food security need according to CMS guidelines" />
          </div>
        )}
      </div>

      <div className={styles.rightColumn}>
        <GapWidget
          fieldName={descriptionFieldName}
          placeholder={intl.formatMessage({
            defaultMessage:
              'Enter details about food security. This is required if a gap is detected',
          })}
          showGoalButton={hasGapVal}
          onCreateGoal={createGoalWithMeasure}
          measure={GoalMeasure.Food}
          form={form}
          hasGap={hasGapVal}
          onEditGoal={onEditGoal}
          carePlanId={carePlanId}
          showWidget={showNotes}
        />
      </div>
    </>
  );
}
