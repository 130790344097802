import { EndEncounterReason } from '@/shared/types/note.types';

/**
 * Converts an end encounter reason from scheduled visit to unscheduled visit
 */
export function convertScheduledToUnscheduledReason(reason: string): string {
  switch (reason) {
    case EndEncounterReason.NoShow:
      return EndEncounterReason.UnabletoReachPatient;
    case EndEncounterReason.PatientRequestReschedule:
      return EndEncounterReason.PatientRequestedCallback;
    case EndEncounterReason.SchedulingErrorDisenrollment:
      return EndEncounterReason.Disenrollment;
    default:
      return reason; // Keep other reasons like PatientHospitalized unchanged
  }
}

/**
 * Converts an end encounter reason from unscheduled visit to scheduled visit
 */
export function convertUnscheduledToScheduledReason(reason: string): string {
  switch (reason) {
    case EndEncounterReason.UnabletoReachPatient:
      return EndEncounterReason.NoShow;
    case EndEncounterReason.PatientRequestedCallback:
      return EndEncounterReason.PatientRequestReschedule;
    case EndEncounterReason.Disenrollment:
      return EndEncounterReason.SchedulingErrorDisenrollment;
    default:
      return reason; // Keep other reasons like PatientHospitalized unchanged
  }
}
