export enum FollowUpReason {
  ProviderAccepted = 'ProviderAccepted',
  ProviderRejected = 'ProviderRejected',
  ProviderUnreachable = 'ProviderUnreachable',
}

export enum ModalType {
  ConfirmTitration,
  RevertTitration,
}
