import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import CadenceLogoIcon from '@/shared/assets/svgs/cadence-logo.svg?react';
import ChevronIcon from '@/shared/assets/svgs/chevron.svg?react';
import CloseCircleIcon from '@/shared/assets/svgs/closeCircle.svg?react';
import { EhrIcon, getEhrName } from '@/shared/common/Ehr';
import { LoadingPlaceholder } from '@/shared/common/LoadingPlaceholder';
import { Menu } from '@/shared/common/Menu';
import { useIsNotePublishable } from '@/shared/hooks/useIsNotePublishable';
import { Button } from '@/shared/tempo/atom/Button';
import { Tag } from '@/shared/tempo/atom/Tag';
import { Tooltip } from '@/shared/tempo/atom/Tooltip';
import type { EHR } from '@/shared/types/ehr.types';
import { EndEncounterType } from '@/shared/types/note.types';

import type { EndFormValues } from './NoteEditor/noteFormState';
import { useNoteEditorContext } from './NoteEditorContext';
import {
  buttonsContainer,
  header,
  icon,
  logo,
  menuTitle,
  rightButtonsContainer,
  tagContainer,
  tertiaryButton,
} from './NoteSubmitButtons.css';
import type { TypeOfEncounter } from './Notes.types';
import { EditableNoteType } from './Notes.types';

type Props = {
  onSaveAsDraftClick: () => void;
  onPublishToCadenceClick: () => void;
  onPublishToEhrClick: () => void;
  isSaveAsDraftAvailable: boolean;
  onEndEarly: () => void;
  onDiscardEndEarly: () => void;
  typeOfEncounter: TypeOfEncounter;
  ehr?: EHR;
  disablePublishToEhr: boolean;
  endEncounterValues?: EndFormValues;
};

export function NoteSubmitButtons({
  onSaveAsDraftClick,
  onPublishToCadenceClick,
  onPublishToEhrClick,
  isSaveAsDraftAvailable,
  onEndEarly,
  onDiscardEndEarly,
  endEncounterValues,
  typeOfEncounter,
  ehr,
  disablePublishToEhr,
}: Props) {
  const { editingNote } = useNoteEditorContext();
  const { closeMenu, isMenuOpen, menuAnchorEl, openMenu } = Menu.use();
  const showEndEarly = !!typeOfEncounter;

  const { patientId } = useParams<{ patientId: string }>();

  const { isLoading, isPublishable, helperText, showPublishToCadence } =
    useIsNotePublishable(patientId, typeOfEncounter);

  return (
    <div className={header}>
      <span className={tagContainer}>
        {editingNote?.type === EditableNoteType.Draft && (
          <Tag variant="default">
            <FormattedMessage defaultMessage="Draft" />
          </Tag>
        )}
      </span>
      <div className={cx({ [buttonsContainer]: showEndEarly })}>
        {showEndEarly &&
          getEndEncounter(onEndEarly, onDiscardEndEarly, endEncounterValues)}
        <div className={rightButtonsContainer}>
          {isSaveAsDraftAvailable && (
            <Button
              size="small"
              variant={showEndEarly ? 'tertiary' : 'secondary'}
              onPress={onSaveAsDraftClick}
            >
              <FormattedMessage defaultMessage="Save Draft" />
            </Button>
          )}

          <LoadingPlaceholder isLoading={isLoading}>
            <Tooltip
              isDisabled={isPublishable}
              placement="bottom"
              content={helperText}
            >
              <Button
                size="small"
                variant="primary"
                isDisabled={!isPublishable}
                onPress={(e) => openMenu({ currentTarget: e.target })}
              >
                <FormattedMessage defaultMessage="Publish to" />
                <Button.Icon>
                  <ChevronIcon />
                </Button.Icon>
              </Button>
            </Tooltip>
          </LoadingPlaceholder>
        </div>
        <Menu anchorEl={menuAnchorEl} isOpen={isMenuOpen} close={closeMenu}>
          <div className={menuTitle}>
            <FormattedMessage defaultMessage="Publish to" />
          </div>
          {!!showPublishToCadence && (
            <Menu.Item
              onClick={() => {
                closeMenu();
                onPublishToCadenceClick();
              }}
            >
              <CadenceLogoIcon className={logo} />
              Cadence
            </Menu.Item>
          )}
          {!disablePublishToEhr && (
            <Menu.Item
              onClick={() => {
                closeMenu();
                onPublishToEhrClick();
              }}
            >
              <EhrIcon ehr={ehr} className={logo} />
              {getEhrName(ehr)}
            </Menu.Item>
          )}
        </Menu>
      </div>
    </div>
  );
}

function getEndEncounter(
  onEndEarly: () => void,
  onDiscardEndEarly: () => void,
  endEncounterValues?: EndFormValues,
) {
  if (endEncounterValues != null) {
    if (
      endEncounterValues.endType === EndEncounterType.NoShow &&
      endEncounterValues.endReason
    ) {
      return (
        <span className={tagContainer}>
          <Tag variant="default">
            <FormattedMessage defaultMessage="No Show" />
            <CloseCircleIcon className={icon} onClick={onDiscardEndEarly} />
          </Tag>
        </span>
      );
    }
    if (
      endEncounterValues.endType === EndEncounterType.EndEarly &&
      endEncounterValues.endReason
    ) {
      return (
        <span className={tagContainer}>
          <Tag variant="default">
            <FormattedMessage defaultMessage="Visit Incomplete" />
            <CloseCircleIcon className={icon} onClick={onDiscardEndEarly} />
          </Tag>
        </span>
      );
    }
  }

  return (
    <Button
      className={tertiaryButton}
      size="small"
      variant="tertiary"
      onPress={onEndEarly}
    >
      <FormattedMessage defaultMessage="End Early" />
    </Button>
  );
}
