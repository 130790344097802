import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { useFormFromConfig } from '@/shared/common/Form/FormContainer';
import type { AsyncTitrationMedicationWasNotTitratedReason as Reason } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { Select } from '@/shared/tempo/@labs/molecule/Select';
import { Button } from '@/shared/tempo/atom/Button';
import { useAsyncTitrationOptions } from '@/shared/titrationRejectionReasons';

import type { FormFields } from './formConfig';
import {
  DESCRIPTION_MAX_LENGTH,
  getFormConfig,
  shouldShowDescription,
} from './formConfig';

type Props = {
  isDisabled?: boolean;
  onSubmit: (reason: Reason, description: string) => void;
};

export function NotTitratingReasonForm({
  isDisabled = false,
  onSubmit,
}: Props) {
  const intl = useIntl();
  const form = useFormFromConfig<FormFields>(getFormConfig(intl));
  const options = useAsyncTitrationOptions();
  const rejectionReason = form.watch('reason');

  return (
    <Form
      form={form}
      onSubmit={() => {
        const [reason, description] = form.getValues(['reason', 'description']);

        // safe to cast, validation should prevent these being undefined
        onSubmit(reason as Reason, description as string);
      }}
    >
      {({ canSubmit }) => (
        <>
          <Form.Row>
            <Form.Select
              size={12}
              required
              name="reason"
              label={intl.formatMessage({
                defaultMessage:
                  "Select a reason why a medication wasn't titrated",
              })}
              isDisabled={isDisabled}
              items={options}
            >
              {({ key, label }) => (
                <Select.Option key={key}>{label}</Select.Option>
              )}
            </Form.Select>
          </Form.Row>
          {shouldShowDescription(rejectionReason) && (
            <Form.Row>
              <Form.TextField
                size={12}
                required
                maxLength={DESCRIPTION_MAX_LENGTH}
                name="description"
                label={intl.formatMessage({
                  defaultMessage: 'Additional details',
                })}
              />
            </Form.Row>
          )}
          <Form.Actions direction="row-reverse">
            <Button type="submit" isDisabled={!canSubmit || isDisabled}>
              <FormattedMessage defaultMessage="Reject titration" />
            </Button>
          </Form.Actions>
        </>
      )}
    </Form>
  );
}
