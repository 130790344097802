/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as CadenceModelsV1Models from "../../../../cadence/models/models.pb"
import * as GoogleProtobufEmpty from "../../../../google/protobuf/empty.pb"
import * as GoogleProtobufField_mask from "../../../../google/protobuf/field_mask.pb"
import * as GoogleProtobufStruct from "../../../../google/protobuf/struct.pb"
import * as GoogleProtobufTimestamp from "../../../../google/protobuf/timestamp.pb"
import * as GoogleProtobufWrappers from "../../../../google/protobuf/wrappers.pb"
import * as GoogleTypeDate from "../../../../google/type/date.pb"
import * as GoPmsPkgPatientPms from "../patient/pms.pb"
import * as GoPmsPkgTaskTask from "../task/task.pb"
import * as fm from "@/shared/generated/grpc/fetch.pb"

export enum CareProviderPreferencesListDay {
  LIST_DAY_UNSET = "LIST_DAY_UNSET",
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
}

export enum CareProviderPreferencesListFrequency {
  LIST_FREQUENCY_NOTSET = "LIST_FREQUENCY_NOTSET",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  BIWEEKLY = "BIWEEKLY",
  DAILY = "DAILY",
}

export enum CareProviderPreferencesProgramEnum {
  HEART_FAILURE = "HEART_FAILURE",
  HYPERTENSION = "HYPERTENSION",
  TYPE_2_DIABETES = "TYPE_2_DIABETES",
  CCM = "CCM",
  APCM = "APCM",
}

export enum CareProviderPreferencesOrderingRoute {
  APPOINTMENT_BASED = "APPOINTMENT_BASED",
  ELIGIBLE_PATIENT_LIST = "ELIGIBLE_PATIENT_LIST",
  DIRECT_TO_PEND_ASSESSMENT_AND_PLAN = "DIRECT_TO_PEND_ASSESSMENT_AND_PLAN",
  CM_DUAL_ENROLLMENT_RPM = "CM_DUAL_ENROLLMENT_RPM",
}

export enum CareProviderPreferencesAcuityTierEnum {
  ACUITY_TIER_ENUM_UNSPECIFIED = "ACUITY_TIER_ENUM_UNSPECIFIED",
  TIER_1 = "TIER_1",
  TIER_2 = "TIER_2",
  TIER_3 = "TIER_3",
  TIER_4 = "TIER_4",
}

export enum CareProviderWithPreferencesCareProviderStatusEnum {
  PROSPECT = "PROSPECT",
  ONBOARDED = "ONBOARDED",
  DEACTIVATED = "DEACTIVATED",
  UNKNOWN = "UNKNOWN",
  DISENGAGED = "DISENGAGED",
}

export enum CareProviderProviderType {
  PROVIDER_TYPE_UNSPECIFIED = "PROVIDER_TYPE_UNSPECIFIED",
  CADENCE_PROVIDER = "CADENCE_PROVIDER",
  HOSPITAL_PROVIDER = "HOSPITAL_PROVIDER",
}

export enum CareProviderProviderStatus {
  PROVIDER_STATUS_UNSPECIFIED = "PROVIDER_STATUS_UNSPECIFIED",
  PROSPECT = "PROSPECT",
  ONBOARDED = "ONBOARDED",
  DEACTIVATED = "DEACTIVATED",
  UNKNOWN = "UNKNOWN",
  DISENGAGED = "DISENGAGED",
}

export type RecycleDeclinedPatientsRequest = {
  name?: string
}

export type RecycleDeclinedPatientsResponse = {
  recycledPatientsCount?: number
}

export type GetAthenaReferringProviderRequest = {
  name?: string
}

export type AthenaEhrProvider = {
  athenaProviderId?: string
  careProviderId?: string
  careProviderNpi?: string
  athenaOtherProviderIds?: string[]
}

export type CreateIdentifiedCareProviderRequest = {
  identifiedCareProvider?: IdentifiedCareProvider
}

export type IdentifiedCareProvider = {
  ehrInfo?: EHRInfo
  firstName?: string
  lastName?: string
  npi?: string
  role?: GoPmsPkgPatientPms.CareProviderRole
}

export type ReferringProvider = {
  careProvider?: CareProvider
  medManagementDelegation?: CadenceModelsV1Models.MedManagementDelegation
  allowPatientLevelMedManagementDelegation?: boolean
}

export type GetReferringProviderRequest = {
  name?: string
}

export type GetCareProviderRequest = {
  name?: string
}

export type GetCareProviderEhrHospitalsRequest = {
  name?: string
}

export type UpdatePrimaryHospitalRequest = {
  name?: string
  hospitalId?: string
}

export type UpdateHeuristicBasedAcuityTierMetadataByProviderIdRequest = {
  name?: string
  heuristicBasedAcuityTier?: string
}

export type LookupCareProviderWithEpicInfoRequest = {
  healthSystemId?: number
  epicExternalId?: string
  epicFhirId?: string
  epicProviderId?: string
  instanceId?: number
}

export type LookupCareProviderWithAthenaInfoRequest = {
  athenaPracticeId?: string
  athenaProviderId?: string
}

export type LookupCareProviderWithEmailRequest = {
  email?: string
  ehr?: CadenceModelsV1Models.EHR
  contextId?: string
  healthSystemId?: number
}

export type LookupCareProviderWithNpiRequest = {
  npi?: string
  ehr?: CadenceModelsV1Models.EHR
  contextId?: string
  healthSystemId?: number
}

export type LookupCareProviderEhrInfoWithEmailRequest = {
  email?: string
  ehr?: CadenceModelsV1Models.EHR
  contextId?: string
  healthSystemId?: number
}

export type LookupCareProviderEhrInfoWithAthenaUsernameRequest = {
  username?: string
  athenaPracticeId?: string
}

export type LookupCareProviderEhrInfoWithFullNameRequest = {
  ehr?: CadenceModelsV1Models.EHR
  contextId?: string
  fullName?: string
  healthSystemId?: number
}

export type LookupCareProviderEhrInfoWithNpiRequest = {
  npi?: string
  ehr?: CadenceModelsV1Models.EHR
  contextId?: string
  healthSystemId?: number
}

export type ListPatientCareProvidersRequest = {
  parent?: string
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
  filter?: string
  orderBy?: string
}

export type ListPatientCareProvidersResponse = {
  careProviders?: CareProvider[]
  nextPageToken?: string
  totalSize?: number
}

export type ListHospitalCareProviderEHRInfosRequest = {
  parent?: string
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
  filter?: string
  orderBy?: string
}

export type ListHospitalCareProviderEHRInfosResponse = {
  careProviders?: CareProviderEHRInfo[]
  nextPageToken?: string
  totalSize?: number
}

export type LookupAPEnabledCareProvidersRequest = {
  contextId?: string
}

export type LookupProvidersWithPreferencesRequest = {
  contextId?: string
  ehr?: CadenceModelsV1Models.EHR
}

export type LookupProviderOrderStatsRequest = {
  careProviderId?: string
  numLastOrders?: number
}

export type ProviderOrderStats = {
  numOrdersApproved?: number
  numTotalOrders?: number
  approvalRatePct?: number
  lastOrderTime?: GoogleProtobufTimestamp.Timestamp
}

export type LookupProviderOrderStatsResponse = {
  acuityToProviderOrderStats?: {[key: string]: ProviderOrderStats}
}

export type LookupUpcomingProviderApptStatsRequest = {
  careProviderId?: string
  numDaysApptLookahead?: number
}

export type UpcomingProviderApptStats = {
  numUpcomingAppointments?: number
}

export type LookupUpcomingProviderApptStatsResponse = {
  acuityToUpcomingProviderApptStats?: {[key: string]: UpcomingProviderApptStats}
}

export type LookupAPEnabledCareProvidersResponse = {
  careProviderEmrIds?: CareProviderEMRID[]
}

export type LookupProvidersWithPreferencesResponse = {
  careProviders?: CareProviderWithPreferences[]
}

export type CareProviderFeedback = {
  name?: string
  feedback?: string
}

export type CareProviderPreferences = {
  name?: string
  orderingRoutes?: CareProviderPreferencesOrderingRoute[]
  eligiblePatientListConditions?: CareProviderPreferencesProgramEnum[]
  eligiblePatientListFrequency?: CareProviderPreferencesListFrequency
  eligiblePatientListPreferredWeekday?: CareProviderPreferencesListDay
  eligiblePatientListMaxSize?: GoogleProtobufWrappers.Int32Value
  eplAcuityTier?: CareProviderPreferencesAcuityTierEnum
  apAcuityTier?: CareProviderPreferencesAcuityTierEnum
  heuristicBasedAcuityTier?: CareProviderPreferencesAcuityTierEnum
  heuristicBasedAcuityTierUpdatedAt?: GoogleProtobufTimestamp.Timestamp
}

export type CareProviderWithPreferences = {
  name?: string
  ehrInfo?: EHRInfo[]
  preferences?: CareProviderPreferences
  status?: CareProviderWithPreferencesCareProviderStatusEnum
  providerSpecialty?: string
}

export type CareProviderEMRID = {
  careProviderId?: string
  emrIds?: string[]
}

export type EHRInfo = {
  ehr?: CadenceModelsV1Models.EHR
  epicFhirId?: GoogleProtobufWrappers.StringValue
  epicProviderId?: GoogleProtobufWrappers.StringValue
  epicExternalId?: GoogleProtobufWrappers.StringValue
  athenaPracticeId?: GoogleProtobufWrappers.StringValue
  athenaProviderId?: GoogleProtobufWrappers.StringValue
  athenaDepartmentId?: GoogleProtobufWrappers.StringValue
  hospitalId?: string
  cernerFhirId?: GoogleProtobufWrappers.StringValue
  cernerProviderId?: GoogleProtobufWrappers.StringValue
  cernerExternalProviderId?: GoogleProtobufWrappers.StringValue
  athenaOtherProviderIds?: string[]
  isPrimary?: boolean
  instanceId?: string
  healthSystemId?: number
}

export type CareProviderEHRInfo = {
  name?: string
  careProvider?: CareProvider
  ehrInfo?: EHRInfo[]
}

export type GetCareProviderEHRInfoRequest = {
  name?: string
}

export type CareProvider = {
  name?: string
  uid?: string
  givenName?: string
  familyName?: string
  role?: string
  npi?: string
  email?: string
  providerSpecialty?: string
  providerType?: CareProviderProviderType
  team?: GoPmsPkgTaskTask.Team
  conditionPreferences?: CareProviderPreferencesProgramEnum[]
  status?: CareProviderProviderStatus
}

export type EnrollmentPreferences = {
  orderingRoutes?: string[]
  apAcuityTier?: CareProviderPreferencesAcuityTierEnum
}

export type ProviderHospitalAssociationPreferences = {
  conditions?: GoPmsPkgPatientPms.Condition[]
}

export type HospitalAssociation = {
  ehrInfo?: EHRInfo
  ehrProviderId?: string
  preferences?: ProviderHospitalAssociationPreferences
}

export type CareProviderEnrollmentSettingsResponse = {
  careProviderEnrollmentSettings?: CareProviderEnrollmentSettings[]
}

export type CareProviderEnrollmentSettings = {
  careProvider?: CareProvider
  enrollmentPreferences?: EnrollmentPreferences
  hospitalAssociations?: HospitalAssociation[]
}

export type GetBillingSummaryEhrProviderRequest = {
  name?: string
}

export type GetCareProviderEnrollmentSettingsRequest = {
  contextId?: string
}

export type BillingSummaryEhrProvider = {
  name?: string
  hospitalId?: string
  providerId?: GoogleProtobufWrappers.StringValue
  athenaProviderId?: GoogleProtobufWrappers.StringValue
  epicFhirId?: GoogleProtobufWrappers.StringValue
  epicProviderId?: GoogleProtobufWrappers.StringValue
  epicExternalId?: GoogleProtobufWrappers.StringValue
  cernerFhirId?: GoogleProtobufWrappers.StringValue
  cernerProviderId?: GoogleProtobufWrappers.StringValue
  cernerExternalId?: GoogleProtobufWrappers.StringValue
}

export type GetCareProviderHealthSystemsRequest = {
  name?: string
}

export type CareProviderHealthSystem = {
  healthSystemId?: string
  healthSystemName?: string
}

export type CareProviderHealthSystems = {
  careProviderId?: string
  healthSystems?: CareProviderHealthSystem[]
}

export type Market = {
  name?: string
}

export type ListMarketCareProvidersRequest = {
  parent?: string
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
  filter?: string
  orderBy?: string
}

export type ListMarketCareProvidersResponse = {
  careProviders?: CareProvider[]
  nextPageToken?: string
  totalSize?: number
}

export type MarketCareProvider = {
  name?: string
  marketId?: string
  careProviderId?: string
  createTime?: GoogleProtobufTimestamp.Timestamp
  updateTime?: GoogleProtobufTimestamp.Timestamp
  createUser?: string
  updateUser?: string
  active?: boolean
}

export type CreateMarketCareProviderRequest = {
  marketCareProvider?: MarketCareProvider
}

export type DeleteMarketCareProviderRequest = {
  name?: string
}

export type UpdateMarketCareProviderRequest = {
  name?: string
  marketCareProvider?: MarketCareProvider
}

export type GetCareProviderPanelRequest = {
  name?: string
}

export type GetCareProviderPanelResponse = {
  name?: string
  panelSummary?: ProviderPanelSummary
  patientEngagement?: ProviderPanelPatientEngagement
  patientOutcomes?: ProviderPanelPatientOutcome[]
  patientGoals?: ProviderPanelPatientGoals[]
}

export type ProviderPanelSummary = {
  totalEligiblePatients?: string
  ordersInProgress?: string
  totalEnrolledPatients?: string
  launchDate?: GoogleProtobufTimestamp.Timestamp
  averageAge?: string
  genderDistribution?: GenderDistribution
}

export type CareProviderActivity = {
  name?: string
  careProvider?: CadenceModelsV1Models.CareProvider
  panelSummary?: ProviderPanelSummary
}

export type ListCareProviderActivityRequest = {
  parent?: string
  pageSize?: number
  pageToken?: string
  filter?: string
  orderBy?: string
}

export type ListCareProviderActivityResponse = {
  careProviderActivities?: CareProviderActivity[]
  nextPageToken?: string
  totalSize?: number
}

export type ListCareProviderNeighborsRequest = {
  parent?: string
  pageSize?: number
  pageToken?: string
  filter?: string
  orderBy?: string
}

export type ListCareProviderNeighborsResponse = {
  careProviders?: CadenceModelsV1Models.CareProvider[]
  nextPageToken?: string
  totalSize?: number
}

export type ListHospitalAndProvidersByProviderIdRequest = {
  parent?: string
  pageSize?: number
  pageToken?: string
  filter?: string
  orderBy?: string
}

export type ListHospitalAndProvidersByProviderIdResponse = {
  hospitalProviders?: HospitalProviders[]
  nextPageToken?: string
  totalSize?: number
}

export type HospitalProviders = {
  name?: string
  hospital?: CadenceModelsV1Models.Hospital
  providers?: CadenceModelsV1Models.CareProvider[]
}

export type GenderDistribution = {
  male?: number
  female?: number
  other?: number
  x?: number
}

export type ProviderPanelPatientEngagement = {
  vitalsPercentage?: string
  vitalsPercentageLast30d?: string
  vitalsPercentageChange?: string
  totalVitalsReceived?: string
  totalVitalsReceivedLast30d?: string
  totalVitalsReceivedPrevious30d?: string
  vitalsReceivedPercentageChange?: string
  totalVitalsAlerts?: string
  totalVitalsAlertsLast30d?: string
  totalVitalsAlertsPrevious30d?: string
  vitalsAlertsPercentageChange?: string
  totalVisits?: string
  totalVisitsLast30d?: string
  totalVisitsPrevious30d?: string
  visitsPercentageChange?: string
  totalEscalations?: string
  totalEscalationsLast30d?: string
  totalEscalationsPrevious30d?: string
  escalationsPercentageChange?: string
}

export type ProviderPanelPatientOutcome = {
  program?: string
  period?: string
  metrics?: GoogleProtobufStruct.Struct
}

export type ProviderPanelPatientGoals = {
  program?: string
  metric?: string
  thresholds?: GoogleProtobufStruct.Struct
  currentGoals?: CurrentPatientGoals
  tenureGoals?: TenureGoals[]
}

export type CurrentPatientGoals = {
  patientsTotal?: number
  meetingGoal?: PatientGoalDetails
  notMeetingGoal?: PatientGoalDetails
}

export type PatientGoalDetails = {
  count?: number
  percentage?: number
}

export type TenureGoals = {
  stage?: string
  patientsTotal?: number
  patientsMeetingGoal?: number
  goalPercentage?: number
}

export type PatientRosterDetailsLastVitalTaken = {
  date?: GoogleTypeDate.Date
  value?: GoogleProtobufWrappers.Int32Value
}

export type PatientRosterDetails = {
  name?: string
  mrn?: string
  givenName?: string
  familyName?: string
  status?: CadenceModelsV1Models.PatientStatus
  statusChangeDateUtc?: GoogleTypeDate.Date
  statusReasons?: string
  statusReasonDescription?: string
  daysWVitalsPreviousThirty?: string
  program?: string
  firstOrderDateUtc?: GoogleTypeDate.Date
  lastSystolicTaken?: PatientRosterDetailsLastVitalTaken
  lastDiastolicTaken?: PatientRosterDetailsLastVitalTaken
  lastBloodGlucoseTaken?: PatientRosterDetailsLastVitalTaken
  lastWeightTaken?: PatientRosterDetailsLastVitalTaken
  avgSystolicLast7d?: GoogleProtobufWrappers.Int32Value
  avgDiastolicLast7d?: GoogleProtobufWrappers.Int32Value
  avgGlucoseLast7d?: GoogleProtobufWrappers.Int32Value
  avgWeightLast7d?: GoogleProtobufWrappers.Int32Value
  avgPulseLast7d?: GoogleProtobufWrappers.Int32Value
  avgSystolicLast30d?: GoogleProtobufWrappers.Int32Value
  avgDiastolicLast30d?: GoogleProtobufWrappers.Int32Value
  avgGlucoseLast30d?: GoogleProtobufWrappers.Int32Value
  avgWeightLast30d?: GoogleProtobufWrappers.Int32Value
  avgPulseLast30d?: GoogleProtobufWrappers.Int32Value
  fullName?: string
  isRpm?: boolean
  isCcm?: boolean
  isApcm?: boolean
  rpmConditions?: string[]
  ccmConditions?: string[]
  usualProviderId?: GoogleProtobufWrappers.StringValue
  cardiologistProviderId?: GoogleProtobufWrappers.StringValue
  referringProviderId?: GoogleProtobufWrappers.StringValue
  referringProviderFullName?: GoogleProtobufWrappers.StringValue
  referringProviderRole?: GoogleProtobufWrappers.StringValue
  rpmProgramStatus?: CadenceModelsV1Models.PatientStatus
  ccmProgramStatus?: CadenceModelsV1Models.PatientStatus
  apcmProgramStatus?: CadenceModelsV1Models.PatientStatus
}

export type PatientRosterDetailsWithMultiProgramLastVitalTaken = {
  date?: GoogleTypeDate.Date
  value?: GoogleProtobufWrappers.Int32Value
}

export type PatientRosterDetailsWithMultiProgramProgramDetail = {
  status?: CadenceModelsV1Models.PatientStatus
  programType?: string
  programConditions?: string[]
  statusChangeDate?: GoogleTypeDate.Date
}

export type PatientRosterDetailsWithMultiProgram = {
  name?: string
  mrn?: string
  givenName?: string
  familyName?: string
  fullName?: string
  programDetails?: PatientRosterDetailsWithMultiProgramProgramDetail[]
  lastSystolicTaken?: PatientRosterDetailsWithMultiProgramLastVitalTaken
  lastDiastolicTaken?: PatientRosterDetailsWithMultiProgramLastVitalTaken
  lastBloodGlucoseTaken?: PatientRosterDetailsWithMultiProgramLastVitalTaken
  lastWeightTaken?: PatientRosterDetailsWithMultiProgramLastVitalTaken
  avgSystolicLast7d?: GoogleProtobufWrappers.Int32Value
  avgDiastolicLast7d?: GoogleProtobufWrappers.Int32Value
  avgGlucoseLast7d?: GoogleProtobufWrappers.Int32Value
  avgWeightLast7d?: GoogleProtobufWrappers.Int32Value
  avgPulseLast7d?: GoogleProtobufWrappers.Int32Value
  avgSystolicLast30d?: GoogleProtobufWrappers.Int32Value
  avgDiastolicLast30d?: GoogleProtobufWrappers.Int32Value
  avgGlucoseLast30d?: GoogleProtobufWrappers.Int32Value
  avgWeightLast30d?: GoogleProtobufWrappers.Int32Value
  avgPulseLast30d?: GoogleProtobufWrappers.Int32Value
  daysWVitalsPreviousThirty?: string
  status?: CadenceModelsV1Models.PatientStatus
  statusChangeDateUtc?: GoogleTypeDate.Date
  referringProviderFullName?: GoogleProtobufWrappers.StringValue
  referringProviderRole?: GoogleProtobufWrappers.StringValue
  referringProviderId?: GoogleProtobufWrappers.StringValue
}

export type ListPatientRosterDetailsRequest = {
  parent?: string
  pageSize?: number
  pageToken?: string
  filter?: string
  orderBy?: string
}

export type ListPatientRosterDetailsResponse = {
  patientRosterDetails?: PatientRosterDetails[]
  nextPageToken?: string
  totalSize?: number
}

export type ListPatientRosterDetailsV3Response = {
  patientRosterDetails?: PatientRosterDetailsWithMultiProgram[]
  nextPageToken?: string
  totalSize?: number
}

export type EhrHospital = {
  hospitalId?: string
  primaryHospital?: boolean
  upcomingPatientsWithAppointments?: string
  ehr?: CadenceModelsV1Models.EHR
}

export type GetCareProviderEhrHospitalsResponse = {
  hospitals?: EhrHospital[]
}

export type CareProviderStateLicensure = {
  state?: string
  activeSince?: GoogleTypeDate.Date
  updatedBy?: string
  updatedDate?: GoogleProtobufTimestamp.Timestamp
  createdBy?: string
  createdDate?: GoogleProtobufTimestamp.Timestamp
  authorName?: string
  expireOn?: GoogleTypeDate.Date
  licenseType?: CadenceModelsV1Models.CareProviderRole
}

export type GetCareProviderStateLicensureRequest = {
  name?: string
}

export type GetCareProviderStateLicensureByStateRequest = {
  name?: string
  state?: string
}

export type UpdateCareProviderStateLicensuresRequest = {
  name?: string
  stateLicensure?: CareProviderStateLicensure[]
}

export type CareProviderStateLicensureResponse = {
  name?: string
  stateLicensure?: CareProviderStateLicensure[]
}

export type UpdateCareProviderStateLicensureRequest = {
  name?: string
  stateLicensure?: CareProviderStateLicensure
}

export type DeleteCareProviderStateLicensureRequest = {
  name?: string
  state?: string
  expireOn?: string
}

export type ListCareProviderEligiblePanelRequest = {
  parent?: string
  pageSize?: number
  pageToken?: string
  filter?: string
  orderBy?: string
}

export type ListCareProviderEligiblePanelResponse = {
  providerPanelPatients?: ProviderPanelPatient[]
  nextPageToken?: string
  totalSize?: number
}

export type ProviderPanelPatient = {
  name?: string
  fullName?: string
  conditions?: GoPmsPkgPatientPms.Condition[]
  lastBloodPressureAverageSystolic?: GoogleProtobufWrappers.DoubleValue
  lastBloodPressureAverageDiastolic?: GoogleProtobufWrappers.DoubleValue
  lastBloodPressureSystolic?: GoogleProtobufWrappers.DoubleValue
  lastBloodPressureDiastolic?: GoogleProtobufWrappers.DoubleValue
  a1c?: GoogleProtobufWrappers.DoubleValue
  efLower?: GoogleProtobufWrappers.DoubleValue
  efUpper?: GoogleProtobufWrappers.DoubleValue
  patientTier?: string
  lastEdVisitDate?: GoogleProtobufTimestamp.Timestamp
  mrn?: string
  diagnosisCodes?: {[key: string]: GoPmsPkgPatientPms.DiagnosisCode}
  mostRecentApptDate?: GoogleProtobufTimestamp.Timestamp
  program?: GoPmsPkgPatientPms.Program
  eligiblePrograms?: GoPmsPkgPatientPms.Program
  conditionsRank?: number
  severityScore?: number
  upcomingApptDate?: GoogleProtobufTimestamp.Timestamp
}

export type GetCareProviderEnrollmentPreferencesRequest = {
  parent?: string
}

export type UpdateCareProviderEnrollmentPreferencesRequest = {
  parent?: string
  enrollmentPreferences?: EnrollmentPreferences
}

export type CreateCareProviderFeedbackRequest = {
  parent?: string
  careProviderFeedback?: CareProviderFeedback
}

export type GetCareProviderEhrInfoByContextRequest = {
  name?: string
  ehr?: CadenceModelsV1Models.EHR
  contextId?: string
}

export class CareProviderService {
  static GetCareProvider(req: GetCareProviderRequest, initReq?: fm.InitReq): Promise<CareProvider> {
    return fm.fetchReq<GetCareProviderRequest, CareProvider>(`/rpm/v1/careProvider/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static LookupCareProviderWithEpicInfo(req: LookupCareProviderWithEpicInfoRequest, initReq?: fm.InitReq): Promise<CareProvider> {
    return fm.fetchReq<LookupCareProviderWithEpicInfoRequest, CareProvider>(`/rpm/v1/careProvider:lookupCareProviderWithEpicInfo?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static LookupAPEnabledCareProviders(req: LookupAPEnabledCareProvidersRequest, initReq?: fm.InitReq): Promise<LookupAPEnabledCareProvidersResponse> {
    return fm.fetchReq<LookupAPEnabledCareProvidersRequest, LookupAPEnabledCareProvidersResponse>(`/rpm/v1/careProvider:lookupApEnabledCareProviders?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static LookupProvidersWithPreferences(req: LookupProvidersWithPreferencesRequest, initReq?: fm.InitReq): Promise<LookupProvidersWithPreferencesResponse> {
    return fm.fetchReq<LookupProvidersWithPreferencesRequest, LookupProvidersWithPreferencesResponse>(`/rpm/v1/careProvider:listProvidersWithPreferences?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static LookupProviderOrderStats(req: LookupProviderOrderStatsRequest, initReq?: fm.InitReq): Promise<LookupProviderOrderStatsResponse> {
    return fm.fetchReq<LookupProviderOrderStatsRequest, LookupProviderOrderStatsResponse>(`/rpm/v1/careProvider:lookupProviderOrderStats?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static LookupUpcomingProviderApptStats(req: LookupUpcomingProviderApptStatsRequest, initReq?: fm.InitReq): Promise<LookupUpcomingProviderApptStatsResponse> {
    return fm.fetchReq<LookupUpcomingProviderApptStatsRequest, LookupUpcomingProviderApptStatsResponse>(`/rpm/v1/careProvider:lookupUpcomingProviderApptStats?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static GetBillingSummaryEhrProvider(req: GetBillingSummaryEhrProviderRequest, initReq?: fm.InitReq): Promise<BillingSummaryEhrProvider> {
    return fm.fetchReq<GetBillingSummaryEhrProviderRequest, BillingSummaryEhrProvider>(`/rpm/v1/hospitals/${req["name"]}/billingSummaryEhrProvider?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static LookupCareProviderWithAthenaInfo(req: LookupCareProviderWithAthenaInfoRequest, initReq?: fm.InitReq): Promise<CareProvider> {
    return fm.fetchReq<LookupCareProviderWithAthenaInfoRequest, CareProvider>(`/rpm/v1/careProvider:lookupCareProviderWithAthenaInfo?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static LookupCareProviderWithEmail(req: LookupCareProviderWithEmailRequest, initReq?: fm.InitReq): Promise<CareProvider> {
    return fm.fetchReq<LookupCareProviderWithEmailRequest, CareProvider>(`/rpm/v1/careProvider:lookupCareProviderByEmail?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static LookupCareProviderWithNpi(req: LookupCareProviderWithNpiRequest, initReq?: fm.InitReq): Promise<CareProvider> {
    return fm.fetchReq<LookupCareProviderWithNpiRequest, CareProvider>(`/rpm/v1/careProvider:lookupCareProviderByNpi?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static LookupCareProviderEhrInfoWithEmail(req: LookupCareProviderEhrInfoWithEmailRequest, initReq?: fm.InitReq): Promise<CareProviderEHRInfo> {
    return fm.fetchReq<LookupCareProviderEhrInfoWithEmailRequest, CareProviderEHRInfo>(`/rpm/v1/careProvider:lookupCareProviderEhrInfoByEmail?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static LookupCareProviderEhrInfoWithAthenaUsername(req: LookupCareProviderEhrInfoWithAthenaUsernameRequest, initReq?: fm.InitReq): Promise<CareProviderEHRInfo> {
    return fm.fetchReq<LookupCareProviderEhrInfoWithAthenaUsernameRequest, CareProviderEHRInfo>(`/rpm/v1/careProvider:lookupCareProviderEhrInfoByAthenaUsername?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static LookupCareProviderEhrInfoWithFullName(req: LookupCareProviderEhrInfoWithFullNameRequest, initReq?: fm.InitReq): Promise<CareProviderEHRInfo> {
    return fm.fetchReq<LookupCareProviderEhrInfoWithFullNameRequest, CareProviderEHRInfo>(`/rpm/v1/careProvider:lookupCareProviderEhrInfoByFullName?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static LookupCareProviderEhrInfoWithNpi(req: LookupCareProviderEhrInfoWithNpiRequest, initReq?: fm.InitReq): Promise<CareProviderEHRInfo> {
    return fm.fetchReq<LookupCareProviderEhrInfoWithNpiRequest, CareProviderEHRInfo>(`/rpm/v1/careProvider:lookupCareProviderEhrInfoByNpi?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static ListPatientCareProviders(req: ListPatientCareProvidersRequest, initReq?: fm.InitReq): Promise<ListPatientCareProvidersResponse> {
    return fm.fetchReq<ListPatientCareProvidersRequest, ListPatientCareProvidersResponse>(`/rpm/v1/${req["parent"]}/careProviders?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static GetCareProviderEHRInfo(req: GetCareProviderEHRInfoRequest, initReq?: fm.InitReq): Promise<CareProviderEHRInfo> {
    return fm.fetchReq<GetCareProviderEHRInfoRequest, CareProviderEHRInfo>(`/rpm/v1/careProvider/${req["name"]}/ehrInfo?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static ListHospitalCareProviderEHRInfos(req: ListHospitalCareProviderEHRInfosRequest, initReq?: fm.InitReq): Promise<ListHospitalCareProviderEHRInfosResponse> {
    return fm.fetchReq<ListHospitalCareProviderEHRInfosRequest, ListHospitalCareProviderEHRInfosResponse>(`/rpm/v1/${req["parent"]}/careProviders/ehrInfo?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static GetCareProviderHealthSystems(req: GetCareProviderHealthSystemsRequest, initReq?: fm.InitReq): Promise<CareProviderHealthSystems> {
    return fm.fetchReq<GetCareProviderHealthSystemsRequest, CareProviderHealthSystems>(`/rpm/v1/careProvider/${req["name"]}/healthSystems?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static GetCareProvidersEnrollmentSettings(req: GetCareProviderEnrollmentSettingsRequest, initReq?: fm.InitReq): Promise<CareProviderEnrollmentSettingsResponse> {
    return fm.fetchReq<GetCareProviderEnrollmentSettingsRequest, CareProviderEnrollmentSettingsResponse>(`/rpm/v1/careProvider:enrollmentSettings?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static GetCareProviderEnrollmentPreferences(req: GetCareProviderEnrollmentPreferencesRequest, initReq?: fm.InitReq): Promise<EnrollmentPreferences> {
    return fm.fetchReq<GetCareProviderEnrollmentPreferencesRequest, EnrollmentPreferences>(`/rpm/v1/careProvider/${req["parent"]}/enrollmentPreferences?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static UpdateCareProviderEnrollmentPreferences(req: UpdateCareProviderEnrollmentPreferencesRequest, initReq?: fm.InitReq): Promise<EnrollmentPreferences> {
    return fm.fetchReq<UpdateCareProviderEnrollmentPreferencesRequest, EnrollmentPreferences>(`/rpm/v1/careProvider/${req["parent"]}/enrollmentPreferences`, {...initReq, method: "PUT", body: JSON.stringify(req, fm.replacer)})
  }
  static ListMarketCareProviders(req: ListMarketCareProvidersRequest, initReq?: fm.InitReq): Promise<ListMarketCareProvidersResponse> {
    return fm.fetchReq<ListMarketCareProvidersRequest, ListMarketCareProvidersResponse>(`/rpm/v1/${req["parent"]}/careProviders?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static CreateMarketCareProvider(req: CreateMarketCareProviderRequest, initReq?: fm.InitReq): Promise<MarketCareProvider> {
    return fm.fetchReq<CreateMarketCareProviderRequest, MarketCareProvider>(`/rpm/v1/marketCareProvider`, {...initReq, method: "POST", body: JSON.stringify(req["market_care_provider"], fm.replacer)})
  }
  static DeleteMarketCareProvider(req: DeleteMarketCareProviderRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<DeleteMarketCareProviderRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/${req["name"]}`, {...initReq, method: "DELETE"})
  }
  static UpdateMarketCareProvider(req: UpdateMarketCareProviderRequest, initReq?: fm.InitReq): Promise<MarketCareProvider> {
    return fm.fetchReq<UpdateMarketCareProviderRequest, MarketCareProvider>(`/rpm/v1/${req["name"]}`, {...initReq, method: "PUT", body: JSON.stringify(req["market_care_provider"], fm.replacer)})
  }
  static GetCareProviderPanel(req: GetCareProviderPanelRequest, initReq?: fm.InitReq): Promise<GetCareProviderPanelResponse> {
    return fm.fetchReq<GetCareProviderPanelRequest, GetCareProviderPanelResponse>(`/rpm/v1/careProvider/${req["name"]}/panel?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static ListCareProviderActivity(req: ListCareProviderActivityRequest, initReq?: fm.InitReq): Promise<ListCareProviderActivityResponse> {
    return fm.fetchReq<ListCareProviderActivityRequest, ListCareProviderActivityResponse>(`/rpm/v1/careProvider/${req["parent"]}/careProviderActivity?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static ListCareProviderNeighbors(req: ListCareProviderNeighborsRequest, initReq?: fm.InitReq): Promise<ListCareProviderNeighborsResponse> {
    return fm.fetchReq<ListCareProviderNeighborsRequest, ListCareProviderNeighborsResponse>(`/rpm/v1/careProvider/${req["parent"]}/neighbors?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static ListHospitalAndProvidersByProviderId(req: ListHospitalAndProvidersByProviderIdRequest, initReq?: fm.InitReq): Promise<ListHospitalAndProvidersByProviderIdResponse> {
    return fm.fetchReq<ListHospitalAndProvidersByProviderIdRequest, ListHospitalAndProvidersByProviderIdResponse>(`/rpm/v1/careProvider/${req["parent"]}/hospitalAndProviders?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static ListPatientRosterDetails(req: ListPatientRosterDetailsRequest, initReq?: fm.InitReq): Promise<ListPatientRosterDetailsResponse> {
    return fm.fetchReq<ListPatientRosterDetailsRequest, ListPatientRosterDetailsResponse>(`/rpm/v1/careProvider/${req["parent"]}/patientRosterDetails?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static ListPatientRosterDetailsV2(req: ListPatientRosterDetailsRequest, initReq?: fm.InitReq): Promise<ListPatientRosterDetailsResponse> {
    return fm.fetchReq<ListPatientRosterDetailsRequest, ListPatientRosterDetailsResponse>(`/rpm/v1/careProvider/${req["parent"]}/patientRosterDetailsV2?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static ListPatientRosterDetailsV3(req: ListPatientRosterDetailsRequest, initReq?: fm.InitReq): Promise<ListPatientRosterDetailsV3Response> {
    return fm.fetchReq<ListPatientRosterDetailsRequest, ListPatientRosterDetailsV3Response>(`/rpm/v1/careProvider/${req["parent"]}/patientRosterDetailsWithMultiProgram?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static GetReferringProvider(req: GetReferringProviderRequest, initReq?: fm.InitReq): Promise<ReferringProvider> {
    return fm.fetchReq<GetReferringProviderRequest, ReferringProvider>(`/rpm/v1/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static GetCareProviderStateLicensure(req: GetCareProviderStateLicensureRequest, initReq?: fm.InitReq): Promise<CareProviderStateLicensureResponse> {
    return fm.fetchReq<GetCareProviderStateLicensureRequest, CareProviderStateLicensureResponse>(`/rpm/v1/careProvider/${req["name"]}/stateLicensure?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static GetCareProviderStateLicensureByState(req: GetCareProviderStateLicensureByStateRequest, initReq?: fm.InitReq): Promise<CareProviderStateLicensureResponse> {
    return fm.fetchReq<GetCareProviderStateLicensureByStateRequest, CareProviderStateLicensureResponse>(`/rpm/v1/careProvider/${req["name"]}/stateLicensureByState?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static UpdateCareProviderStateLicensures(req: UpdateCareProviderStateLicensuresRequest, initReq?: fm.InitReq): Promise<CareProviderStateLicensureResponse> {
    return fm.fetchReq<UpdateCareProviderStateLicensuresRequest, CareProviderStateLicensureResponse>(`/rpm/v1/careProvider/${req["name"]}/stateLicensures`, {...initReq, method: "PUT", body: JSON.stringify(req, fm.replacer)})
  }
  static UpdateCareProviderStateLicensure(req: UpdateCareProviderStateLicensureRequest, initReq?: fm.InitReq): Promise<CareProviderStateLicensure> {
    return fm.fetchReq<UpdateCareProviderStateLicensureRequest, CareProviderStateLicensure>(`/rpm/v1/careProvider/${req["name"]}/stateLicensure`, {...initReq, method: "PUT", body: JSON.stringify(req["state_licensure"], fm.replacer)})
  }
  static DeleteCareProviderStateLicensure(req: DeleteCareProviderStateLicensureRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<DeleteCareProviderStateLicensureRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/careProvider/${req["name"]}/stateLicensure/${req["state"]}/${req["expireOn"]}`, {...initReq, method: "DELETE"})
  }
  static CreateIdentifiedCareProvider(req: CreateIdentifiedCareProviderRequest, initReq?: fm.InitReq): Promise<CareProviderEHRInfo> {
    return fm.fetchReq<CreateIdentifiedCareProviderRequest, CareProviderEHRInfo>(`/rpm/v1/identifiedCareProvider`, {...initReq, method: "POST", body: JSON.stringify(req["identified_care_provider"], fm.replacer)})
  }
  static GetCareProviderEhrHospitals(req: GetCareProviderEhrHospitalsRequest, initReq?: fm.InitReq): Promise<GetCareProviderEhrHospitalsResponse> {
    return fm.fetchReq<GetCareProviderEhrHospitalsRequest, GetCareProviderEhrHospitalsResponse>(`/rpm/v1/careProvider/${req["name"]}/ehrHospitals?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static UpdatePrimaryHospitalByProviderId(req: UpdatePrimaryHospitalRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<UpdatePrimaryHospitalRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/careProvider/${req["name"]}/primaryHospital`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static UpdateHeuristicBasedAcuityTierMetadataByProviderId(req: UpdateHeuristicBasedAcuityTierMetadataByProviderIdRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<UpdateHeuristicBasedAcuityTierMetadataByProviderIdRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/careProvider/${req["name"]}/acuityTierHeuristicMetadata`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static ListCareProviderEligiblePanel(req: ListCareProviderEligiblePanelRequest, initReq?: fm.InitReq): Promise<ListCareProviderEligiblePanelResponse> {
    return fm.fetchReq<ListCareProviderEligiblePanelRequest, ListCareProviderEligiblePanelResponse>(`/rpm/v1/careProvider/${req["parent"]}/eligiblePanel?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static GetAthenaReferringProviderByPatientId(req: GetAthenaReferringProviderRequest, initReq?: fm.InitReq): Promise<AthenaEhrProvider> {
    return fm.fetchReq<GetAthenaReferringProviderRequest, AthenaEhrProvider>(`/rpm/v1/careProvider/${req["name"]}/athenaClinicalId?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static CreateCareProviderFeedback(req: CreateCareProviderFeedbackRequest, initReq?: fm.InitReq): Promise<CareProviderFeedback> {
    return fm.fetchReq<CreateCareProviderFeedbackRequest, CareProviderFeedback>(`/rpm/v1/careProvider/${req["parent"]}/feedback`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static RecycleDeclinedPatients(req: RecycleDeclinedPatientsRequest, initReq?: fm.InitReq): Promise<RecycleDeclinedPatientsResponse> {
    return fm.fetchReq<RecycleDeclinedPatientsRequest, RecycleDeclinedPatientsResponse>(`/rpm/v1/careProvider/${req["name"]}/recycleDeclinedPatients`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static GetCareProviderEhrInfoByContext(req: GetCareProviderEhrInfoByContextRequest, initReq?: fm.InitReq): Promise<CareProviderEHRInfo> {
    return fm.fetchReq<GetCareProviderEhrInfoByContextRequest, CareProviderEHRInfo>(`/rpm/v1/careProvider/${req["name"]}/ehrInfoByContext?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
}