import { makeI18nHook, useFlags } from '@/shared/hooks';

import { GoalMeasure, GoalStatus } from './goals.types';

export const useGoalGoalMeasureI18n = makeI18nHook(
  (intl) =>
    ({
      [GoalMeasure.Medications]: intl.formatMessage({
        defaultMessage: 'Medications',
      }),
      [GoalMeasure.EDVisitOrHospitalization]: intl.formatMessage({
        defaultMessage: 'ED Visit/Hospitalization',
      }),
      [GoalMeasure.CareCoordination]: intl.formatMessage({
        defaultMessage: 'Care Coordination',
      }),
      [GoalMeasure.Tobacco]: intl.formatMessage({
        defaultMessage: 'Tobacco',
      }),
      [GoalMeasure.Diet]: intl.formatMessage({
        defaultMessage: 'Diet',
      }),
      [GoalMeasure.Exercise]: intl.formatMessage({
        defaultMessage: 'Exercise',
      }),
      [GoalMeasure.ChronicConditions]: intl.formatMessage({
        defaultMessage: 'Chronic Conditions',
      }),
      [GoalMeasure.Pneumovax]: intl.formatMessage({
        defaultMessage: 'Pneumovax',
      }),
      [GoalMeasure.Influenza]: intl.formatMessage({
        defaultMessage: 'Influenza',
      }),
      [GoalMeasure.Tdap]: intl.formatMessage({
        defaultMessage: 'Tdap',
      }),
      [GoalMeasure.Shingrix]: intl.formatMessage({
        defaultMessage: 'Shingrix',
      }),
      [GoalMeasure.Colonoscopy]: intl.formatMessage({
        defaultMessage: 'Colorectal Cancer Screening',
      }),
      [GoalMeasure.BreastCancerScreening]: intl.formatMessage({
        defaultMessage: 'Breast Cancer Screening',
      }),
      [GoalMeasure.DiabeticEyeExam]: intl.formatMessage({
        defaultMessage: 'Diabetic Eye Exam',
      }),
      [GoalMeasure.LivingSituation]: intl.formatMessage({
        defaultMessage: 'Living Situation',
      }),
      [GoalMeasure.Food]: intl.formatMessage({
        defaultMessage: 'Food',
      }),
      [GoalMeasure.Transportation]: intl.formatMessage({
        defaultMessage: 'Transportation',
      }),
      [GoalMeasure.Utilities]: intl.formatMessage({
        defaultMessage: 'Utilities',
      }),
      [GoalMeasure.Safety]: intl.formatMessage({
        defaultMessage: 'Safety',
      }),
      [GoalMeasure.PhysicalActivity]: intl.formatMessage({
        defaultMessage: 'Physical Activity',
      }),
      [GoalMeasure.Vitals]: intl.formatMessage({
        defaultMessage: 'Vitals',
      }),
      [GoalMeasure.BloodPressure]: intl.formatMessage({
        defaultMessage: 'Blood Pressure',
      }),
      [GoalMeasure.Cholesterol]: intl.formatMessage({
        defaultMessage: 'Cholesterol',
      }),
      [GoalMeasure.BloodSugar]: intl.formatMessage({
        defaultMessage: 'Blood Sugar',
      }),
      [GoalMeasure.HeartRate]: intl.formatMessage({
        defaultMessage: 'Heart Rate',
      }),
      [GoalMeasure.Weight]: intl.formatMessage({
        defaultMessage: 'Weight',
      }),
      [GoalMeasure.OxygenSaturation]: intl.formatMessage({
        defaultMessage: 'Oxygen Saturation',
      }),
      [GoalMeasure.FluidIntake]: intl.formatMessage({
        defaultMessage: 'Fluid Intake',
      }),
      [GoalMeasure.ThyroidHormone]: intl.formatMessage({
        defaultMessage: 'Thyroid Hormone',
      }),
    }) as Record<GoalMeasure, string>,
);

export const useGoalStatusI18n = makeI18nHook<Record<GoalStatus, string>>(
  (intl) => {
    const { carePlanOptimization } = useFlags();
    return {
      [GoalStatus.ABANDONED]: intl.formatMessage({
        defaultMessage: 'Abandoned',
      }),
      [GoalStatus.COMPLETE]: intl.formatMessage({
        defaultMessage: 'Complete',
      }),
      [GoalStatus.IN_PROGRESS]: intl.formatMessage({
        defaultMessage: 'In Progress',
      }),
      ...(carePlanOptimization
        ? {
            [GoalStatus.OPEN]: intl.formatMessage({
              defaultMessage: 'Needs Review',
            }),
          }
        : {
            [GoalStatus.OPEN]: intl.formatMessage({
              defaultMessage: 'Open',
            }),
          }),
      [GoalStatus.GOAL_STATUS_UNSPECIFIED]: intl.formatMessage({
        defaultMessage: 'Unspecified',
      }),
      [GoalStatus.DECLINED]: intl.formatMessage({
        defaultMessage: 'Declined',
      }),
      [GoalStatus.FUTURE]: intl.formatMessage({
        defaultMessage: 'Future',
      }),
    };
  },
);
