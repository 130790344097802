import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';

export function useGetSlaText() {
  const intl = useIntl();

  return (hours: number) => getSlaText(intl, hours);
}

export function getSlaText(
  intl: IntlShape,
  hours: Maybe<number>,
): Maybe<string> {
  if (hours === null || hours === undefined) {
    return null;
  }

  const isPast = hours < 0;
  const absHours = Math.abs(hours);
  const days = Math.floor(absHours / 24);

  if (isPast) {
    return intl.formatMessage({ defaultMessage: 'Past due' });
  }

  if (days > 1) {
    return null;
  }

  if (days === 1) {
    return intl.formatMessage({ defaultMessage: 'Due tomorrow' });
  }

  if (days === 0) {
    return intl.formatMessage({ defaultMessage: 'Due today' });
  }

  return null;
}
