import { AsyncTitrationMedicationWasNotTitratedReason } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';

enum ClinicalGoalSpecificReason {
  PATIENT_REJECTED = 'PATIENT_REJECTED',
  MAX_TOLERATED_DOSE_REACHED = 'MAX_TOLERATED_DOSE_REACHED',
  RECOMMEND_ONLY_CURRENT_MEDS_PATIENT_CASE_SENT = 'RECOMMEND_ONLY_CURRENT_MEDS_PATIENT_CASE_SENT',
}

export const ReasonForNotTitrating = {
  ...ClinicalGoalSpecificReason,
  ...AsyncTitrationMedicationWasNotTitratedReason,
};

export type TReasonForNotTitrating = keyof typeof ReasonForNotTitrating;
