import type { IntlShape } from 'react-intl';

import { getCcmConditionI18n } from '@/pages/adminPanel/patient-profile/tabs/ClinicalProfileTab/clinicalprofile.utils';
import type { CcmCondition } from '@/shared/types/clinicalprofile.types';

import type { Goal } from '../../../Goals/goals.types';

/**
 * Removes a condition's section from a goal description
 * @param existingGoal - The existing goal containing the description
 * @param condition - The condition to remove from the description
 * @returns The updated description with the condition section removed
 */
export function removeConditionFromGoalDescription(
  existingGoal: Goal,
  condition: CcmCondition,
  intl: IntlShape,
): string {
  const conditionPrefix = getConditionPrefix(condition, intl);
  const existingDescription = existingGoal.description || '';

  if (existingDescription.includes(conditionPrefix)) {
    // Create a regex to match the entire segment for this condition
    // Will match "(For Condition) ... " up until the next condition or end of string
    const regex = new RegExp(`${conditionPrefix}.*?(?=For .*:|$)`, 's');

    // Remove the segment for this condition and clean up spaces
    return existingDescription.replace(regex, '').trim();
  }

  return existingDescription;
}

export function getConditionPrefix(condition: CcmCondition, intl: IntlShape) {
  const conditionName = getCcmConditionI18n(intl)[condition];
  return intl.formatMessage(
    { defaultMessage: 'For {conditionName}:' },
    { conditionName },
  );
}

export function getUpdatedGoalDescription(
  existingGoal: Goal,
  condition: CcmCondition,
  intl: IntlShape,
  newActionSteps: string,
) {
  const conditionPrefix = getConditionPrefix(condition, intl);
  const existingDescription = existingGoal.description || '';

  if (existingDescription.includes(conditionPrefix)) {
    const regex = new RegExp(`(?<=${conditionPrefix}).*?(?=For .*:|$)`, 's');
    const newConditionDescription = addPeriodIfNeeded(newActionSteps);
    return existingDescription
      .replace(regex, ` ${newConditionDescription} `)
      .trim();
  }

  const newGoalDescription = `${conditionPrefix} ${newActionSteps}`;
  return existingDescription
    ? `${addPeriodIfNeeded(existingDescription)} ${newGoalDescription}`
    : newGoalDescription;
}

function addPeriodIfNeeded(str: string) {
  return str.charAt(str.length - 1) === '.' ? str : `${str}.`;
}
