import type { ReactNode } from 'react';

import {
  headerText,
  list,
  listItem,
  textContainer,
} from './RpmDualEnrollmentModal.css';

type SectionProps = {
  title?: string | ReactNode;
  children: ReactNode;
};

export const EnrollmentSection = ({ title, children }: SectionProps) => (
  <>
    {title && <div className={headerText}>{title}</div>}
    <div className={textContainer}>{children}</div>
  </>
);

type TextProps = {
  children: ReactNode;
};

export const Text = ({ children }: TextProps) => <div>{children}</div>;

type RichTextProps = {
  children: ReactNode;
};

export const RichText = ({ children }: RichTextProps) => <div>{children}</div>;

type BulletListProps = {
  items: ReactNode[];
};

export const BulletList = ({ items }: BulletListProps) => (
  <ul className={list}>
    {items.map((item, index) => (
      <li key={index} className={listItem}>
        {item}
      </li>
    ))}
  </ul>
);

type NumberedListProps = {
  items: ReactNode[];
};

export const NumberedList = ({ items }: NumberedListProps) => (
  <ol className={list}>
    {items.map((item, index) => (
      <li key={index} className={listItem}>
        {item}
      </li>
    ))}
  </ol>
);
