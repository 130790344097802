import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIsMutating } from 'react-query';
import { useParams } from 'react-router-dom';

import { logger } from '@/logger';
import { useNotesLoadingStateContext } from '@/pages/patients/PatientProfile/PatientNotesSidebarPanel/NotesLoadingStateContext';
import { getObjectDiff } from '@/shared/devUtils/getObjectDiff';
import { usePrevious } from '@/shared/hooks';
import {
  autosavedNotesQueryKey,
  usePatientAutosavedNote,
  useUpsertPatientAutosavedNote,
} from '@/shared/hooks/queries/autosave-notes.queries';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { useOnMount } from '@/shared/hooks/useOnMount';
import type { TimerState } from '@/shared/notes/Timer';
import type { RequiredNoteType } from '@/shared/types/note.types';
import type { RouteParam } from '@/shared/types/route.types';

import { useNoteEditorContext } from '../../NoteEditorContext';
import { useNoteBody } from '../../NotePreview/getVisitLayoutNoteBody';
import { usePatientAlertEscalationRequiredFromEncounterInstances } from '../../NotePreview/hooks/useAlertEscalationsList';
import { EditableNoteType } from '../../Notes.types';
import type { PatientNoteParams } from '../../note.queries';
import { patientNotesQueryKey } from '../../note.queries';
import { useSetNoteEditorContentFromNote } from '../../utils/useSetNoteEditorContent.hook';
import type { NoteFormValues } from '../noteFormState';
import { useEncounterModuleInstances } from './useEncounterModuleInstances.hook';
import { useMarshaledTimeTrackingPayloadByEncounter } from './useMarshaledTimeTrackingPayload.hook';

export function useAutosaveNoteOnChanges(
  note: NoteFormValues,
  timer: TimerState,
  isSubmitting: boolean | undefined = false,
  config: {
    onSuccess?: (note: RequiredNoteType) => void;
  } = {},
) {
  const [fetchedInitialAutosave, setFetchedInitialAutosave] = useState(false);
  const { patientId }: RouteParam = useParams();
  const { mutateAsync: upsertAutosavedNote } = useUpsertPatientAutosavedNote(
    patientId,
    timer,
    { onSuccess: config.onSuccess },
  );

  const zendeskTicketRef = useRef(note.zendeskTicket);
  zendeskTicketRef.current = note.zendeskTicket;

  const creatingDraft = useIsMutating({
    mutationKey: patientNotesQueryKey.createDraft(),
  });
  const publishing = useIsMutating({
    mutationKey: patientNotesQueryKey.publish(),
  });
  const deletingAutosave = useIsMutating({
    mutationKey: autosavedNotesQueryKey.delete(patientId),
  });

  const incompatibleRequestInFlight = Boolean(
    creatingDraft || publishing || deletingAutosave,
  );
  const incompatibleRequestInFlightRef = useRef(incompatibleRequestInFlight);
  incompatibleRequestInFlightRef.current = incompatibleRequestInFlight;

  // this ensures we always use the most up-to-date zendesk ticket info in case
  // it has changed since the last invocation was debounced
  const makeRequestWithCurrentZendeskTicket = (params: PatientNoteParams) =>
    upsertAutosavedNote({ ...params, zendeskTicket: zendeskTicketRef.current });

  const debouncedOnNoteChange = useMemo(
    () =>
      debounce(async (noteParams: PatientNoteParams) => {
        if (isSubmitting || incompatibleRequestInFlightRef.current) {
          return Promise.resolve(null);
        }

        return makeRequestWithCurrentZendeskTicket(noteParams);
      }, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [upsertAutosavedNote, isSubmitting],
  );

  // We want to cancel the debounced function if the user is submitting.
  // Otherwise for slower networks, an extraneous note will be autosaved before the publish request completes.
  const handleNoteChange = useCallback(
    (noteParams) => {
      if (isSubmitting) {
        debouncedOnNoteChange.cancel();
      } else {
        debouncedOnNoteChange(noteParams);
      }
    },
    [isSubmitting, debouncedOnNoteChange],
  );

  const { editingNote, isEditorOpen } = useNoteEditorContext();

  const patientNoteParams = usePatientNoteParams(note);
  useLoadAutosavedNote(patientId, {
    onSuccess: async (autosaved) => {
      config.onSuccess?.(autosaved);
      if (!fetchedInitialAutosave) {
        // For non-drafts, trigger an autosave if there wasn't one to get a zendesk ticket
        if (!autosaved && editingNote?.type !== EditableNoteType.Draft) {
          // Await this so we only set fetchedInitialAutosave to true after
          // we've autosaved
          await upsertAutosavedNote(patientNoteParams);
        }
        setFetchedInitialAutosave(true);
      }
    },
  });

  // TODO: Implement autosaving of draft notes
  // (https://cadencerpm.atlassian.net/browse/PLAT-3602)
  const shouldHandleNoteChanges =
    isEditorOpen &&
    editingNote?.type !== EditableNoteType.Draft &&
    fetchedInitialAutosave;

  useHandleNoteChanges(note, handleNoteChange, shouldHandleNoteChanges);

  useEffect(() => debouncedOnNoteChange.cancel(), [debouncedOnNoteChange]);

  // ensure we persist any pending updates when we unmount
  useOnMount(() => () => {
    debouncedOnNoteChange.flush();
  });
}

/**
 * Fetch autosaved note
 * Set NoteEditorContent values once note has been fetched
 */
function useLoadAutosavedNote(
  patientId: string,
  config: { onSuccess?: (note: RequiredNoteType) => void } = {},
) {
  const { isEditorOpen, editingNote } = useNoteEditorContext();

  // TODO: Load autosaved draft note when isEditingDraft note is true
  // (https://cadencerpm.atlassian.net/browse/PLAT-3602) once autosaving draft
  // notes is implemented (https://cadencerpm.atlassian.net/browse/PLAT-3602)

  const {
    data: autosavedNote,
    isFetched,
    isFetching,
    isLoading,
  } = usePatientAutosavedNote(patientId, {
    enabled: editingNote?.type !== EditableNoteType.Draft,
    onSuccess: config.onSuccess,
  });

  const setNoteEditorContentFromNote = useSetNoteEditorContentFromNote();

  useEffect(() => {
    const editingNoteType = editingNote?.type;
    const shouldUseAutoSavedNote =
      isEditorOpen &&
      editingNoteType &&
      [EditableNoteType.Autosaved, EditableNoteType.Alert].includes(
        editingNoteType,
      );
    const hasAutoSavedNote =
      autosavedNote && isFetched && !isFetching && !isLoading;

    if (shouldUseAutoSavedNote && hasAutoSavedNote) {
      setNoteEditorContentFromNote(autosavedNote);
    }
  }, [
    isFetched,
    autosavedNote,
    setNoteEditorContentFromNote,
    isEditorOpen,
    isFetching,
    isLoading,
    // whatever you do, PLEASE do not pass editingNote in as an effect dependency. the
    // reference can change and will cause this effect to run, which blows away the
    // note the user is currently editing.
    // see https://cadencerpm.atlassian.net/browse/PLAT-6335 for details
    editingNote?.type,
  ]);
}

export function usePatientNoteParams({
  title,
  labels,
  body: rtfBody,
  bodyHtml,
  actionRequired,
  urgent,
  externalProviderId,
  escalationMessage,
  encounterModuleInstances,
  timeEntry,
  appointmentId,
  noShowAppointmentId,
  endEncounter,
  timeElapsed,
}: NoteFormValues): PatientNoteParams {
  const { currentUserFullName } = useCurrentUser();
  const { patientId } = useParams<{ patientId: string }>();
  const noteBody = useNoteBody(patientId);
  const alertEscalationInfo =
    usePatientAlertEscalationRequiredFromEncounterInstances(
      patientId,
      encounterModuleInstances,
    );
  const body = noteBody(encounterModuleInstances, alertEscalationInfo);
  const timeTracking = useMarshaledTimeTrackingPayloadByEncounter(
    timeEntry,
    encounterModuleInstances,
  );

  return useMemo(
    () => ({
      title: title || DEFAULT_TITLE,
      body: body || DEFAULT_BODY,
      rtfBody: rtfBody || DEFAULT_RTF_BODY,
      bodyHtml: bodyHtml ?? '',
      labels: labels.map((id: number) => ({
        id,
      })),
      actionRequired,
      urgent,
      externalProviderId,
      escalationMessage: escalationMessage ?? undefined,
      encounterModuleInstances,
      author: currentUserFullName,
      shouldEMRSync: false, // TODO: Fix this, it is turning into should_e_m_r_sync
      timeTracking,
      appointmentId,
      noShowAppointmentId,
      endEncounter,
      timeElapsed,
    }),
    // Ignoring rtfBody because this gets reconstructed frequently, even
    // not on user change. `body` will change when `rtfBody` changes
    // `encounterModuleInstances` will change more than once on user input change,
    // but this is okay because we are debouncing
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentUserFullName,
      title,
      labels,
      body,
      actionRequired,
      urgent,
      externalProviderId,
      escalationMessage,
      encounterModuleInstances,
      timeTracking,
      appointmentId,
      noShowAppointmentId,
      endEncounter?.endType,
      endEncounter?.endReason,
      endEncounter?.endNote,
      timeElapsed,
    ],
  );
}

function useHandleNoteChanges(
  noteFormValues: NoteFormValues,
  onChange: (params: PatientNoteParams) => void,
  shouldHandleChanges: boolean,
) {
  const patientNoteParams = usePatientNoteParams(noteFormValues);
  const prevPatientNoteParams = usePrevious(patientNoteParams);
  const { isLoading } = useNotesLoadingStateContext();
  const { encounterModuleInstances } = useEncounterModuleInstances();

  useEffect(() => {
    if (
      shouldHandleChanges &&
      // This check prevents autosaving when the user hasn't changed anything. Needed because this effect relies on running when patientNoteParams changes, but we don't fully memoize encounterModuleInstances, so it will change on rerender
      !isEqual(patientNoteParams, prevPatientNoteParams) &&
      // Don't run if useAdjustEncounterModuleInstances hasn't run yet
      encounterModuleInstances?.length !== 0 &&
      // Don't run if we're still loading note context
      !isLoading
    ) {
      logger.debug('Note param changes detected', {
        noteParamsDiff: getObjectDiff(prevPatientNoteParams, patientNoteParams),
      });

      // For a new note, this will get triggered after useAdjustEncounterModuleInstances
      // has run even though a user hasn't made a change. This is ok because we
      // need an initial autosave in order to get a zendesk ticket
      onChange({
        ...patientNoteParams,
        zendeskTicket: noteFormValues.zendeskTicket,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldHandleChanges, patientNoteParams, isLoading]);
}

// The notes api requires the title, body, and rtfBody fields, so add defaults
// so that autosave still works if they're not populated
const DEFAULT_TITLE = '(Autosaved Note)';
const DEFAULT_BODY = '(This note was autosaved.)';
const DEFAULT_RTF_BODY = {
  blocks: [
    {
      // This key can be any unique value and is usually randomly generated by draftjs
      key: 'body_key',
      text: DEFAULT_BODY,
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
  entityMap: {},
};
