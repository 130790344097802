import cx from 'classnames';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useTitrationRecommendation } from '@/components/AsyncTitration/hooks';
import { GreenCheckMark } from '@/shared/assets/svgs';
import { Modal } from '@/shared/common/Modal';
import {
  AsyncTitrationAsyncTitrationStatus as AsyncTitrationStatus,
  AsyncTitrationPatientConsentCollectorEnum as Collector,
  type MedicationChange,
} from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { useUpdateAsyncTitration } from '@/shared/hooks/queries/medication.queries';
import { flexContainer } from '@/shared/jsStyle/flex.css';
import { gap } from '@/shared/jsStyle/gap.css';
import { tiledRadioLabel } from '@/shared/jsStyle/ui.css';
import { removeMargin } from '@/shared/jsStyle/utils.css';
import { Button } from '@/shared/tempo/atom/Button';
import { Radio } from '@/shared/tempo/atom/Radio';
import { RadioGroup } from '@/shared/tempo/molecule/RadioGroup';

import { MedInfo } from '../MedInfo';
import { cadenceOutreachMessage, medInfo } from './recommendOnlyActions.css';

type Props = {
  onClose: () => void;
  medChange: MedicationChange;
  referenceMedId: string;
  noteId: number;
};

export function ConfirmTitrationModal({
  onClose,
  medChange,
  referenceMedId,
  noteId,
}: Props) {
  const [collector, setCollector] = useState<Nullable<Collector>>(null);
  const { mutate: updateTitration, isLoading } = useUpdateAsyncTitration(
    medChange.asyncTitration?.inputPatientId ?? '',
    medChange.asyncTitration?.id ?? '',
  );
  const recommendation = useTitrationRecommendation(medChange, referenceMedId);

  const onConfirm = () => {
    if (!collector) {
      return;
    }

    updateTitration(
      {
        patientConsentCollector: collector,
        providerDecisionNoteId: noteId,
        status: AsyncTitrationStatus.PROVIDER_ACCEPTED,
      },
      {
        onSuccess: onClose,
      },
    );
  };

  return (
    <Modal open onClose={onClose}>
      <Modal.Header
        title={<FormattedMessage defaultMessage="Confirm provider accepted" />}
      />
      <Modal.Body className={cx(flexContainer.column, gap.M)}>
        <p className={removeMargin}>
          <FormattedMessage defaultMessage="Select who is responsible for reaching out to the patient." />
        </p>
        <RadioGroup
          orientation="horizontal"
          label={
            <FormattedMessage defaultMessage="Select patient outreach type" />
          }
          onChange={(key) => setCollector(key as Collector)}
          classes={{ root: gap.M }}
        >
          <Radio
            value={Collector.PROVIDER}
            classes={{ label: tiledRadioLabel.horizontal }}
          >
            <FormattedMessage defaultMessage="Provider will reach out" />
          </Radio>
          <Radio
            value={Collector.CADENCE}
            classes={{ label: tiledRadioLabel.horizontal }}
          >
            <FormattedMessage defaultMessage="Cadence will reach out" />
          </Radio>
        </RadioGroup>
        {collector === Collector.PROVIDER && (
          <>
            <FormattedMessage defaultMessage="Clicking confirm will update the medication to reflect the proposed titration:" />
            <div className={medInfo}>
              <GreenCheckMark />
              <MedInfo
                medChange={{
                  ...medChange,
                  rxnorm: recommendation.rxNorm,
                }}
              />
            </div>
          </>
        )}
        {collector === Collector.CADENCE && (
          <div className={cadenceOutreachMessage}>
            <FormattedMessage defaultMessage="If the provider isn't responsible to submit the order, create a Titration Outreach task to capture consent from the patient." />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onPress={onClose}>
          <FormattedMessage defaultMessage="Cancel" />
        </Button>
        <Button
          isDisabled={!collector}
          isProcessing={isLoading}
          onPress={onConfirm}
        >
          <FormattedMessage defaultMessage="Confirm" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
