import { type ReactElement, useEffect } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Form } from '@/shared/common/Form';
import { GoalCategory } from '@/shared/generated/grpc/go/pms/pkg/ccm_goal/ccm_goal.pb';
import { useFlags } from '@/shared/hooks/useFlags';

import { ContextualGoalButton } from '../../../Goals/ContextualGoalButton';
import { container } from '../../../Goals/ContextualGoalButton.css';
import { useExistingMeasureGoal } from '../../../Goals/goalUtils';
import type { Goal } from '../../../Goals/goals.types';
import { type GoalMeasure, GoalStatus } from '../../../Goals/goals.types';
import { useGoalGoalMeasureI18n } from '../../../Goals/i18nMapping';
import { type FormFields } from '../../formConfig';
import { GoalStatusButton } from '../shared/GoalStatusButton';
import { useGoalMutations } from '../shared/goalHooks';
import {
  PSYCHOSOCIAL_ASSESSMENT_OPTIONS,
  SDOH_GOAL_MEASURE_MAP,
} from './psychoSocialAssessmentOptions';
import * as styles from './shared.css';

interface Props {
  fieldName: keyof FormFields;
  placeholder: string;
  onCreateGoal: (measure: GoalMeasure, description?: string) => void;
  measure: GoalMeasure;
  form: UseFormReturn<FormFields>;
  showGoalButton: boolean;
  hasGap: boolean;
  onEditGoal: (goal: Goal) => void;
  carePlanId: string;
  showWidget: boolean;
}

export function GapWidget({
  fieldName,
  placeholder,
  onCreateGoal: onPressCreateGoal,
  measure,
  form,
  showGoalButton,
  hasGap,
  onEditGoal: onPressEditGoal,
  carePlanId,
  showWidget,
}: Props): ReactElement {
  const { carePlanOptimization } = useFlags();
  const intl = useIntl();
  const goalMeasureI18n = useGoalGoalMeasureI18n();
  const { patientId } = useParams<{ patientId: string }>();

  const notes = form.getValues(fieldName) as string;

  const { closeGoal, updateGoalDescription, createGoal } =
    useGoalMutations(patientId);
  const existingGoal = useExistingMeasureGoal(patientId, carePlanId, measure);

  // Close goal if widget is hidden meaning gap is no longer relevant
  useEffect(() => {
    if (carePlanOptimization && !showWidget && existingGoal) {
      closeGoal(existingGoal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showWidget]);

  function handleNotesBlur() {
    if (carePlanOptimization) {
      if (existingGoal) {
        updateGoalDescription(existingGoal, notes);
      }

      if (!existingGoal && hasGap) {
        createGoal(constructSDOHGoal(measure, notes), carePlanId);
      }
    }
  }

  function handlePressCreateGoal() {
    onPressCreateGoal(measure, notes);
  }

  if (!showWidget) {
    return <></>;
  }

  return (
    <div className={styles.gapContainer}>
      <Form.TextArea
        label={intl.formatMessage({ defaultMessage: 'Notes' })}
        name={fieldName}
        placeholder={placeholder}
        size={12}
        rows={2}
        classes={{ input: styles.notesField }}
        required={hasGap}
        onBlur={handleNotesBlur}
      />

      {carePlanOptimization && (
        <div className={container.default}>
          <GoalStatusButton
            existingGoal={existingGoal}
            onPressEditGoal={onPressEditGoal}
            onPressCreateGoal={handlePressCreateGoal}
            carePlanId={carePlanId}
          />
        </div>
      )}

      {!carePlanOptimization && showGoalButton && (
        <ContextualGoalButton
          className={styles.gapButton}
          goalName={goalMeasureI18n[measure]}
          onPress={handlePressCreateGoal}
        />
      )}
    </div>
  );
}

function constructSDOHGoal(measure: GoalMeasure, description: string) {
  const title = PSYCHOSOCIAL_ASSESSMENT_OPTIONS.find(
    (o) => SDOH_GOAL_MEASURE_MAP[o.name] === measure,
  )?.label;

  return {
    title,
    measure,
    description,
    status: GoalStatus.IN_PROGRESS,
    category: GoalCategory.SOCIAL_NEEDS,
  };
}
