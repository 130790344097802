{{> header val="Goals and Interventions" }}
<ul>
{{#each categorizedGoals}}
  {{#if goals.length}}
  <li><i>{{label}}</i></li>
  <ul>
    {{#each goals}}
    <li>
      <b>{{measure}} -</b> {{#if title}}{{title}}. {{description}}{{else}}{{description}}{{/if}}
    </li>
    {{/each}}
  </ul>
  {{/if}}
{{/each}}
</ul>
{{> free_text_space }}
