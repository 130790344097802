import { useMemo } from 'react';

import { logger } from '@/logger';
import {
  isCarePlanVisit,
  isInitialCNAppointment,
  isInitialNPAppointment,
  isRegularAPCMVisit,
  isRegularCNAppointment,
  isRegularNPAppointment,
} from '@/shared/common/SchedulePatientModal/appointment.utils';
import type { AppointmentDetails } from '@/shared/generated/grpc/go/pms/pkg/scheduling/scheduling.pb';

export function useIsRescheduleAllowed(appointment: Maybe<AppointmentDetails>) {
  return useMemo(() => {
    if (!appointment) {
      logger.info('SmartRescheduler is disabled: No next appointment');
      return false;
    }
    if (
      isInitialCNAppointment(appointment?.appointmentCanonicalName) ||
      isRegularCNAppointment(appointment?.appointmentCanonicalName) ||
      isRegularNPAppointment(appointment?.appointmentCanonicalName) ||
      isInitialNPAppointment(appointment?.appointmentCanonicalName) ||
      isCarePlanVisit(appointment?.appointmentCanonicalName) ||
      isRegularAPCMVisit(appointment?.appointmentCanonicalName)
    ) {
      return true;
    }
    logger.info(
      `SmartScheduler is disabled for recommended appointment type: ${appointment?.appointmentCanonicalName}.`,
    );
    return false;
  }, [appointment]);
}
