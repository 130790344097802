import type { IntlShape } from 'react-intl';

import { getTitrationRecommendation } from '@/components/AsyncTitration/hooks';
import { MarkdownBuilder } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/templates/engine/MarkdownBuilder';
import { EMPTY_NEW_MED } from '@/pages/patients/patientDetails/ui/Notes/NotePreview/hooks/useGetAsyncTitrationNoteBody/getSortedRelevantMedChanges';
import type {
  MedicationChange,
  AsyncTitrationMedicationWasNotTitratedReason as Reason,
  ReferenceMedication,
} from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import {
  AsyncTitrationAsyncTitrationStatus as AsyncTitrationStatus,
  AsyncTitrationAsyncTitrationType as AsyncTitrationType,
} from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import {
  type MedManagementDelegationUiMappingValue,
  MedManagementDelegationUiMapping as MedMgmtDelegation,
} from '@/shared/hooks/queries/med-management.queries';

import { getMedicationStrings } from './getMedicationStrings';
import type { MedicationInfo, VitalStrings } from './types';

export function getAsyncReviewNoteBody(
  intl: IntlShape,
  changesWithAsyncTitration: MedicationInfo[],
  vitals: VitalStrings,
  medReviewBody: string,
  rejectionReasonsI18n: Record<Reason, string>,
  medManagementDelegation?: MedManagementDelegationUiMappingValue,
) {
  const md = new MarkdownBuilder();

  md.p(
    `Chart reviewed. 30 day BP average is ${vitals.bp}, and 30 day HR average is ${vitals.hr}. Patient currently on the following medication(s):`,
  );
  md.newline();
  md.concat(medReviewBody);
  md.newline();

  changesWithAsyncTitration.forEach(([referenceMed, change]) => {
    const status = change.asyncTitration?.status;

    if (status === AsyncTitrationStatus.INITIALLY_REVIEWED) {
      appendInitiallyApprovedTitrationBody(
        md,
        intl,
        change,
        referenceMed,
        medManagementDelegation,
      );
    }

    if (status === AsyncTitrationStatus.REJECTED_ON_INITIAL_REVIEW) {
      appendInitiallyRejectedTitrationBody(
        md,
        change,
        referenceMed,
        rejectionReasonsI18n,
      );
    }
  });

  return md;
}

function appendInitiallyApprovedTitrationBody(
  md: MarkdownBuilder,
  intl: IntlShape,
  med: MedicationChange,
  referenceMed: ReferenceMedication,
  medManagementDelegation?: MedManagementDelegationUiMappingValue,
) {
  const { isValid, medicationName, dosage, frequencyText } =
    getMedicationStrings(intl, med, referenceMed);
  if (!isValid) {
    return;
  }

  if (med.asyncTitration?.type === AsyncTitrationType.NEW_MED) {
    md.p(
      `Review of chart shows patient has tolerated their current medications
    well and recent laboratory test results, as indicated by Cadence Protocols,
    reveal no contraindications. Recommend starting ${medicationName} ${dosage} ${frequencyText}. `,
    );
  } else {
    md.p(
      `Review of chart shows patient has tolerated ${medicationName} well and recent laboratory ` +
        'test results, as indicated by Cadence Protocols, reveal no contraindications. Recommend ' +
        `increasing ${medicationName} to ${dosage} ${frequencyText}. `,
    );
  }

  const isNewMed = med.asyncTitration?.type === AsyncTitrationType.NEW_MED;
  const isCurrentMedsOnly =
    medManagementDelegation === MedMgmtDelegation.OptInCurrentMeds ||
    medManagementDelegation === MedMgmtDelegation.OptInCurrentMedsAndNotify;
  const shouldSkipNotification =
    medManagementDelegation === MedMgmtDelegation.OptOutRecommendMeds ||
    (isNewMed && isCurrentMedsOnly);

  if (!shouldSkipNotification) {
    const notificationMessage = isNewMed
      ? 'Patient will be notified of recommended medication adjustment and possible side effects.'
      : 'Patient will be notified of recommended titration and possible side effects.';

    md.p(notificationMessage);

    if (referenceMed.requiredLabs) {
      md.p(
        `This medication needs labs to be drawn ${referenceMed.requiredLabs}.`,
      );
    }
  }
}

function appendInitiallyRejectedTitrationBody(
  md: MarkdownBuilder,
  med: MedicationChange,
  referenceMed: ReferenceMedication,
  rejectionReasonI18n: Record<Reason, string>,
) {
  const { rejectionReason, rejectionDetails } = getTitrationRecommendation(
    med,
    referenceMed,
    true,
    false,
  );

  md.p(
    `${
      referenceMed.name === EMPTY_NEW_MED
        ? 'We do not recommend prescribing a new medication. '
        : `We do not recommend titrating ${referenceMed.name?.toUpperCase()}. `
    }Reason: ${rejectionReasonI18n[rejectionReason as Reason]}. ${
      rejectionDetails ?? ''
    }`,
  );
}
