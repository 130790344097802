{{!-- Care Plan Template --}}
{{> header val="General Assessment" }}
<br/>

{{> subheader val="Problem List" }}
{{> problem_list }}

{{> subheader val="Past Surgical History" }}
{{#if surgicalHistories.length}}
  <ul>
  {{#each surgicalHistories}}
  <li>{{#if diagnosis}}{{diagnosis}}{{/if}}{{#if details}}: {{details}}{{/if}}</li>
  {{/each}}
  </ul>
{{else}}
<p>No known surgical history at this time</p>
<br/>
{{/if}}

{{> subheader val="Specialists" }}
{{#if specialists.length}}
  <ul>
  {{#each specialists}}
  <li>{{#if name}}{{name}}{{/if}}{{#if specialty}}, {{specialty}}{{/if}}{{#if lastVisit}}, Last visit: {{lastVisit}}{{/if}}{{#if nextVisit}}, Next visit: {{nextVisit}}{{/if}}</li>
  {{/each}}
  </ul>
{{else}}
<p>No known specialists at this time</p>
<br/>
{{/if}}

{{> subheader val="Emergency Visits and Hospitalizations in the last 12 months" }}
{{#if emergencyVisits.length}}
  <ul>
  {{#each emergencyVisits}}
  <li>{{formatEmergencyVisit this}}</li>
  {{/each}}
  </ul>
{{else}}
<p>No emergency visits reported</p>
<br/>
{{/if}}

{{> subheader val="Medications" }}
{{#if medsNotReviewed}}
<p>Unable to verify medications at this time</p>
<br/>
{{else}}
  <ul>
  {{#each medications}}
  {{#if isInactive}}
  <del><li>{{medStr}}</li></del>
  {{else}}
  <li>{{medStr}}</li>
  {{/if}}
  {{/each}}
  </ul>
{{/if}}

{{> subheader val="Medication Allergies" }}
{{#if medAllergies.length}}
  <ul>
  {{#each medAllergies}}
  <li>{{medication}}{{#if reaction}}: {{reaction}}{{/if}}</li>
  {{/each}}
  </ul>
{{else}}
<p>NKDA</p>
<br/>
{{/if}}


{{> subheader val="Preventative Care" }}
{{#if preventativeCare.length}}
  <ul>
  {{#each preventativeCare}}
    <li>{{formatPreventativeCare this}}</li>
  {{/each}}
  </ul>
{{else}}
<p>No preventative care information available</p>
<br/>
{{/if}}

{{> subheader val="Social History" }}
{{#if flags.carePlanOptimization}}
  <p>History of tobacco use?</p>
  <p>{{formatTobaccoUse socialHistory intl}}</p>
{{else}}
  <p>History of tobacco use?: {{socialHistory.tobaccoUse}}</p>
{{/if}}
<br/>

{{> subheader val="Diet" }}
{{#if dietNotes}}
<p>Tell me about your diet. What does a typical day of eating look like?</p>
<p>{{dietNotes}}</p>
<br/>
{{else}}
  <ul>
  <li>How many times per week do you eat fast food meals? {{diet.fastFood}}</li>
  <li>Do you snack often? {{diet.snacks}}</li>
  <li>How many times per week do you eat desserts or other sweets? {{diet.desserts}}</li>
  <li>Do you drink regular soda? {{diet.regularSoda}}</li>
  <li>Do you drink sweet tea? {{diet.sweetTea}}</li>
  <li>How much water do you drink per day? {{diet.waterIntake}}</li>
  <li>Do you add salt to meals regularly? {{diet.saltIntake}}</li>
  </ul>
{{/if}}

{{#if exercise}}
{{> subheader val="Exercise" }}
<p>Do you exercise regularly? If so, What type of exercise? How long and how often?</p>
<p>{{exercise}}</p>
<br/>
{{/if}}

{{> subheader val="Psychosocial Assessment/Social Determinants of Need" }}
{{#if psychosocialAssessments.length}}
  <ul>
  {{#each psychosocialAssessments}}
  {{#if enabled}}
  <li>{{label}}: {{description}}</li>
  {{/if}}
  {{/each}}
  </ul>

  <p>Domains assessed with no current need:</p>
  {{#if disabledPsychosocialAssessments}}
    <ul>
    {{#each disabledPsychosocialAssessments}}
      <li>{{label}}{{#if description}} - {{description}}{{/if}}</li>
    {{/each}}
    </ul>
  {{else}}
    <p>None at this time</p>
  {{/if}}
{{/if}}
<br/>

{{#if flags.carePlanOptimization}}
  {{> subheader val="Chronic Condition Management" }}
{{else}}
  {{> header val="Goals and Interventions" }}
{{/if}}
{{#if goalsAndInterventions.length}}
  {{#if flags.carePlanOptimization}}
    {{#each goalsAndInterventionsV2}}
      <p>Condition {{addOne @index}}: {{conditionName}}</p>
      <ul>
        <li>{{formatTreatmentGoal treatmentGoal ../intl}}</li>
        <li>{{formatConditionAssessment this this.vital ../intl}}</li>
      </ul>
    {{/each}}
    {{#if conditionReviewGoals.length}}
      <p>Other conditions</p>
      <ul>
        {{#each conditionReviewGoals}}
          <li>{{title}}: {{description}}</li>
        {{/each}}
      </ul>
    {{/if}}
  {{else}}
    {{#each goalsAndInterventions}}  
      <p>Condition {{addOne @index}}: {{condition}}</p>
      <ul>
        <li>Prognosis: {{prognosis}}</li>
        {{#if vital}}
          <ul>
            <li>{{vital.label}}: {{vital.value}}</li>
            {{#if prognosisNotes}}
              <li>{{prognosisNotes}}</li>
            {{/if}}
          </ul>
        {{else}}
          {{#if prognosisNotes}}
            <ul>
              <li>{{prognosisNotes}}</li>
            </ul>
          {{/if}}
        {{/if}}
        <li>Treatment Goal: {{treatmentGoal}}</li>
        {{#if skipGoalSetting}}
          <li>Patient self-defined health goal: Will review in upcoming visits</li>
        {{else}}
          <li>Patient self-defined health goal: {{healthGoal}}</li>
          <li>Patient self-defined action step(s): {{actionSteps}}</li>
          <li>Coordination of care/resources: {{coordination}}</li>
        {{/if}}
      </ul>      
    {{/each}}
  {{/if}}
{{else}}
  {{#if flags.carePlanOptimization}}
    <p>No chronic conditions identified</p>
  {{else}}
    <p>No goals and interventions defined</p>
  {{/if}}
<br/>
{{/if}}

{{#unless flags.carePlanOptimization}}
  {{> subheader val="Preventative Care Gaps" }}
  {{#if preventativeCareGaps.length}}
    <ul>
    {{#each preventativeCareGaps}}  
      <li>{{label}}: {{nextSteps}}</li>
    {{/each}}
    </ul>
  {{else}}
  <p>No preventative care gaps identified</p>
  <br/>
  {{/if}}
{{/unless}}
{{> subheader val="Cultural Factors" }}
  <ul>
  <li>Do you have any religious, spiritual, or cultural preferences relevant to your healthcare?
  {{culturalPreferences}}</li>
  <li>Do you currently rely on any cultural or faith-based community resources?
  {{culturalCommunityResources}}</li>
  </ul>

{{> subheader val="Follow-up" }}
  <ul>
  <li>Date and time for next follow-up with my care manager: {{followUp.ccmAppointment}}</li>
  <li>Date and time for next appointment with my doctor: {{followUp.providerAppointment}}</li>
  </ul>