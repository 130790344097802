import { EndEncounterReason } from '@/shared/types/note.types';

import { draftJsFieldTemplate } from '../../../template.utils';
import deviceDelayTroubleshootingTemplate from './device_delay_troubleshooting.hbs';
import disenrollmentTemplate from './disenrollment.hbs';
import noShowTemplate from './no_show.hbs';
import otherTemplate from './other_reasons.hbs';
import patientDissatisfiedTemplate from './patient_dissatisfied.hbs';
import hospitalizedTemplate from './patient_hospitalized.hbs';
import patientInNonCadenceStateTemplate from './patient_in_non_cadence_state.hbs';
import patientRefusedAppointmentTemplate from './patient_refused_appointment.hbs';
import patientRequestedCallbackTemplate from './patient_requested_callback.hbs';
import patientRequestRescheduleTemplate from './patient_requested_reschedule.hbs';
import patientUnableToSpeakTemplate from './patient_unable_to_speak.hbs';
import schedulingErrorDisenrollmentTemplate from './scheduling_error_disenrollment.hbs';
import timeConstraintsTemplate from './time_constraints.hbs';
import unableToReachTemplate from './unable_to_reach.hbs';

export const endEarlyNoteTemplates = {
  [EndEncounterReason.NoShow]: {
    notes_body: draftJsFieldTemplate(noShowTemplate),
  },
  [EndEncounterReason.UnabletoReachPatient]: {
    notes_body: draftJsFieldTemplate(unableToReachTemplate),
  },
  [EndEncounterReason.PatientRequestedCallback]: {
    notes_body: draftJsFieldTemplate(patientRequestedCallbackTemplate),
  },
  [EndEncounterReason.PatientRequestReschedule]: {
    notes_body: draftJsFieldTemplate(patientRequestRescheduleTemplate),
  },
  [EndEncounterReason.PatientHospitalized]: {
    notes_body: draftJsFieldTemplate(hospitalizedTemplate),
  },
  [EndEncounterReason.Disenrollment]: {
    notes_body: draftJsFieldTemplate(disenrollmentTemplate),
  },
  [EndEncounterReason.PatientUnableToSpeak]: {
    notes_body: draftJsFieldTemplate(patientUnableToSpeakTemplate),
  },
  [EndEncounterReason.SchedulingErrorDisenrollment]: {
    notes_body: draftJsFieldTemplate(schedulingErrorDisenrollmentTemplate),
  },
  [EndEncounterReason.PatientInNonCadenceState]: {
    notes_body: draftJsFieldTemplate(patientInNonCadenceStateTemplate),
  },
  [EndEncounterReason.PatientRefusedAppointment]: {
    notes_body: draftJsFieldTemplate(patientRefusedAppointmentTemplate),
  },
  [EndEncounterReason.PatientDissatisfied]: {
    notes_body: draftJsFieldTemplate(patientDissatisfiedTemplate),
  },
  [EndEncounterReason.TimeConstraints]: {
    notes_body: draftJsFieldTemplate(timeConstraintsTemplate),
  },
  [EndEncounterReason.DeviceDelayTroubleshooting]: {
    notes_body: draftJsFieldTemplate(deviceDelayTroubleshootingTemplate),
  },
  [EndEncounterReason.Other]: {
    notes_body: draftJsFieldTemplate(otherTemplate),
  },
  default: {
    notes_body: draftJsFieldTemplate(otherTemplate),
  },
};

export const endEarlyCarePlanTemplates = {
  [EndEncounterReason.NoShow]: {
    care_plan_body: draftJsFieldTemplate(noShowTemplate),
  },
  [EndEncounterReason.UnabletoReachPatient]: {
    care_plan_body: draftJsFieldTemplate(unableToReachTemplate),
  },
  [EndEncounterReason.PatientRequestedCallback]: {
    care_plan_body: draftJsFieldTemplate(patientRequestedCallbackTemplate),
  },
  [EndEncounterReason.PatientRequestReschedule]: {
    care_plan_body: draftJsFieldTemplate(patientRequestRescheduleTemplate),
  },
  [EndEncounterReason.PatientHospitalized]: {
    care_plan_body: draftJsFieldTemplate(hospitalizedTemplate),
  },
  [EndEncounterReason.Disenrollment]: {
    care_plan_body: draftJsFieldTemplate(disenrollmentTemplate),
  },
  [EndEncounterReason.PatientUnableToSpeak]: {
    care_plan_body: draftJsFieldTemplate(patientUnableToSpeakTemplate),
  },
  [EndEncounterReason.SchedulingErrorDisenrollment]: {
    care_plan_body: draftJsFieldTemplate(schedulingErrorDisenrollmentTemplate),
  },
  [EndEncounterReason.PatientInNonCadenceState]: {
    care_plan_body: draftJsFieldTemplate(patientInNonCadenceStateTemplate),
  },
  [EndEncounterReason.PatientRefusedAppointment]: {
    care_plan_body: draftJsFieldTemplate(patientRefusedAppointmentTemplate),
  },
  [EndEncounterReason.PatientDissatisfied]: {
    care_plan_body: draftJsFieldTemplate(patientDissatisfiedTemplate),
  },
  [EndEncounterReason.TimeConstraints]: {
    care_plan_body: draftJsFieldTemplate(timeConstraintsTemplate),
  },
  [EndEncounterReason.DeviceDelayTroubleshooting]: {
    care_plan_body: draftJsFieldTemplate(deviceDelayTroubleshootingTemplate),
  },
  [EndEncounterReason.Other]: {
    care_plan_body: draftJsFieldTemplate(otherTemplate),
  },
  default: {
    care_plan_body: draftJsFieldTemplate(otherTemplate),
  },
};
