import classNames from 'classnames';

import { FormControlLabel, Radio } from '@/deprecated/mui';
import { RadioGroup } from '@/shared/common/RadioGroup';

import { EncounterModuleId } from '../../Notes.types';
import type { TypeOfEncounter } from '../../Notes.types';
import { isAPCMVisit, isCCMVisit } from '../../utils/encounterTypeUtils';
import { ErrorMessage } from '../ErrorMessage';
import type { NoteFieldValidationResult } from '../validation';
import {
  formContainer,
  moduleContainer,
  moduleDescription,
  moduleTitle,
  radioContainer,
  radioControl,
  radioGroupContainer,
  radioLabel,
  withError,
} from './EncounterModule.css';
import { useEncounterModuleIntlMsgs } from './useEncounterModuleIntlMessages';

type Props = {
  moduleId: RequirableEncounterModule;
  form: React.ReactElement;
  encounterType?: TypeOfEncounter;
  hasInfoToReport?: boolean;
  isPatientNoShow: boolean;
  onChangeHasInfoToReport: (hasInfoToReport: boolean) => void;
  validationResults?: NoteFieldValidationResult[];
};

export type RequirableEncounterModule =
  | EncounterModuleId.Medications
  | EncounterModuleId.Symptoms
  | EncounterModuleId.Hospitalization;

export const EncounterModule = ({
  moduleId,
  form,
  encounterType,
  hasInfoToReport,
  isPatientNoShow,
  onChangeHasInfoToReport,
  validationResults,
}: Props) => {
  const { title, description, optionLabel } = useEncounterModuleIntlMsgs(
    moduleId,
    encounterType,
  );

  const isRequired =
    !isPatientNoShow &&
    !(
      (isCCMVisit(encounterType) || isAPCMVisit(encounterType)) &&
      moduleId === EncounterModuleId.Symptoms
    );
  const hasError = Boolean(validationResults?.length);
  const hasRequiredError = Boolean(
    validationResults?.find((error) => error.params?.name === 'required'),
  );

  return (
    <div className={moduleContainer}>
      <div className={classNames(moduleTitle, { [withError]: hasError })}>
        {title}
        {isRequired ? ' *' : ''}
      </div>
      <div className={moduleDescription}>{description}</div>
      <RadioGroup
        className={radioGroupContainer}
        onChange={(_, val) => {
          onChangeHasInfoToReport(val === 'true');
        }}
        value={!hasInfoToReport ? '' : hasInfoToReport.toString()}
        row
      >
        <div
          className={
            hasInfoToReport === false
              ? radioContainer.selected
              : radioContainer.unselected
          }
          key="nothingToReport"
        >
          <FormControlLabel
            className={radioControl}
            classes={{ label: radioLabel }}
            control={<Radio />}
            label="Nothing to report"
            value="false"
          />
        </div>
        <div
          className={
            hasInfoToReport
              ? radioContainer.selected
              : radioContainer.unselected
          }
          key="report"
        >
          <FormControlLabel
            className={radioControl}
            classes={{ label: radioLabel }}
            control={<Radio />}
            label={optionLabel}
            value="true"
          />
        </div>
      </RadioGroup>
      <ErrorMessage hasError={hasRequiredError} />
      {hasInfoToReport ? <div className={formContainer}>{form}</div> : null}
    </div>
  );
};
