import { Fragment } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useFieldArray } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useAllPatientConditions } from '@/apps/shared/useProgramConditions';
import { getCcmConditionI18n } from '@/pages/adminPanel/patient-profile/tabs/ClinicalProfileTab/clinicalprofile.utils';
import { Divider } from '@/shared/common/Divider';
import { Form } from '@/shared/common/Form';
import { useFlags } from '@/shared/hooks';
import {
  convertCcmConditionToPmsCondition,
  convertToCcmCondition,
} from '@/shared/patient/conditions.utils';
import { Button } from '@/shared/tempo/atom/Button';
import type { CcmCondition } from '@/shared/types/clinicalprofile.types';

import { ContextualGoalButton } from '../../../Goals/ContextualGoalButton';
import { type Goal, GoalMeasure } from '../../../Goals/goals.types';
import { EMPTY_GOALS_SECTION, type FormFields } from '../../formConfig';
import { useCcmPrognosisI18n } from '../../prognosis';
import {
  addItemButton,
  divider,
  goalSectionContainer,
  sectionTitle,
} from '../carePlanSections.css';
import { ConditionSection } from './ConditionSection';
import {
  fieldLabel,
  fieldValue,
  patientConditionSection,
} from './GoalsAndInterventions.css';
import { usePatientConditionData } from './hooks/usePatientConditionData.hook';
import { getConditionPrognosis } from './prognosisUtils';

type Props = {
  form: UseFormReturn<FormFields>;
  manualAutoSave: () => void;
  createGoalWithMeasure: (measure: GoalMeasure, description?: string) => void;
  openEditGoalModal: (goal: Goal) => void;
  carePlanId: string;
  isCCMEncounter: boolean;
};

export function GoalsAndInterventions({
  form,
  manualAutoSave,
  createGoalWithMeasure,
  openEditGoalModal,
  carePlanId,
  isCCMEncounter,
}: Props) {
  const { carePlanOptimization } = useFlags();
  const intl = useIntl();
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'goalsAndInterventions',
  });

  return (
    <Form.Section
      title={
        <div className={goalSectionContainer}>
          {carePlanOptimization
            ? intl.formatMessage({
                defaultMessage: 'Chronic Condition Management',
              })
            : intl.formatMessage({ defaultMessage: 'Goals and Interventions' })}
          <ContextualGoalButton
            onPress={() => createGoalWithMeasure(GoalMeasure.ChronicConditions)}
          />
        </div>
      }
      classes={{ title: sectionTitle }}
    >
      {fields.map((field, index) => (
        <Fragment key={field.id}>
          <ConditionSection
            numOfConditions={fields.length}
            index={index}
            form={form}
            manualAutoSave={manualAutoSave}
            onRemove={() => remove(index)}
            isRemovable={isCCMEncounter ? fields.length > 2 : fields.length > 0}
            openCreateGoalModal={createGoalWithMeasure}
            openEditGoalModal={openEditGoalModal}
            carePlanId={carePlanId}
          />
          {index < fields.length - 1 && <Divider className={divider} />}
        </Fragment>
      ))}
      {carePlanOptimization && <UnreviewedConditions form={form} />}
      <div
        className={
          !fields.length ? addItemButton.emptyList : addItemButton.default
        }
      >
        <Button
          variant="secondary"
          size="small"
          onPress={() => append({ ...EMPTY_GOALS_SECTION })}
        >
          <FormattedMessage defaultMessage="Add Condition" />
        </Button>
      </div>
    </Form.Section>
  );
}

function UnreviewedConditions({ form }: { form: UseFormReturn<FormFields> }) {
  const { patientId } = useParams<{ patientId: string }>();
  const intl = useIntl();
  const { conditions } = useAllPatientConditions(patientId);
  const patientConditions = conditions.map((condition) =>
    convertToCcmCondition(condition),
  );
  const conditionLabels = getCcmConditionI18n(intl);
  const { data: conditionData } = usePatientConditionData(patientId);

  const carePlanConditions = form
    .getValues('goalsAndInterventions')
    ?.map((goal) => goal.condition);

  // Find conditions that exist in the patient's record but are not in the care plan
  const unreviewedConditions = patientConditions.filter(
    (c) => c && !carePlanConditions?.includes(c),
  );
  const ccmPrognosisI18n = useCcmPrognosisI18n();

  if (!unreviewedConditions.length) {
    return null;
  }

  return (
    <div className={patientConditionSection}>
      <div className={fieldLabel}>
        <FormattedMessage defaultMessage="Unreviewed Conditions" />
      </div>
      <ul>
        {unreviewedConditions
          .filter((c): c is CcmCondition => !!c)
          .map((c) => {
            const prognosis = getConditionPrognosis(
              convertCcmConditionToPmsCondition(c),
              conditionData,
              true, // This is only rendered if carePlanOptimization is on
            );
            return (
              <li key={c} className={fieldValue}>
                {conditionLabels[c]} - {ccmPrognosisI18n[prognosis]}
              </li>
            );
          })}
      </ul>
    </div>
  );
}
