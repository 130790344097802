/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as GoogleProtobufField_mask from "../../../../google/protobuf/field_mask.pb"
import * as GoogleProtobufTimestamp from "../../../../google/protobuf/timestamp.pb"
import * as GoogleTypeDate from "../../../../google/type/date.pb"
import * as fm from "@/shared/generated/grpc/fetch.pb"

export enum ProgramType {
  PROGRAM_TYPE_UNSPECIFIED = "PROGRAM_TYPE_UNSPECIFIED",
  PROGRAM_TYPE_RPM = "PROGRAM_TYPE_RPM",
  PROGRAM_TYPE_CCM = "PROGRAM_TYPE_CCM",
  PROGRAM_TYPE_APCM = "PROGRAM_TYPE_APCM",
}

export enum LobLetterLetterType {
  LETTER_TYPE_UNSPECIFIED = "LETTER_TYPE_UNSPECIFIED",
  ENROLLMENT = "ENROLLMENT",
  CCM_ENROLLMENT = "CCM_ENROLLMENT",
  APCM_ENROLLMENT = "APCM_ENROLLMENT",
  CARE_PLAN = "CARE_PLAN",
  UNKNOWN = "UNKNOWN",
}

export enum LobLetterLetterStatus {
  LETTER_STATUS_UNSPECIFIED = "LETTER_STATUS_UNSPECIFIED",
  MAILED = "MAILED",
  IN_TRANSIT = "IN_TRANSIT",
  IN_LOCAL_AREA = "IN_LOCAL_AREA",
  PROCESSED_FOR_DELIVERY = "PROCESSED_FOR_DELIVERY",
  DELIVERED = "DELIVERED",
  RE_ROUTED = "RE_ROUTED",
  RETURNED_TO_SENDER = "RETURNED_TO_SENDER",
  INTERNATIONAL_EXIT = "INTERNATIONAL_EXIT",
  FAILED_TO_CREATE = "FAILED_TO_CREATE",
  CREATED = "CREATED",
  PENDING_FOR_PROVIDER = "PENDING_FOR_PROVIDER",
}

export enum LobLetterSyncStatus {
  SYNC_STATUS_UNSPECIFIED = "SYNC_STATUS_UNSPECIFIED",
  SYNC_CREATED = "SYNC_CREATED",
  SYNC_WAITING_FOR_DELIVERY = "SYNC_WAITING_FOR_DELIVERY",
  SYNC_DELIVERED = "SYNC_DELIVERED",
  SYNC_FAILED_TO_DELIVER = "SYNC_FAILED_TO_DELIVER",
  SYNC_FAILED_TO_CREATE = "SYNC_FAILED_TO_CREATE",
  SYNC_PENDING = "SYNC_PENDING",
}

export type MarketConfig = {
  name?: string
  programName?: string
  programNameEsp?: string
  partnerLogo?: string
  cadenceLogo?: string
  createTime?: GoogleProtobufTimestamp.Timestamp
  updateTime?: GoogleProtobufTimestamp.Timestamp
  createUser?: string
  updateUser?: string
}

export type CreateMarketConfigRequest = {
  marketConfig?: MarketConfig
}

export type UpdateMarketConfigRequest = {
  marketConfig?: MarketConfig
  updateMask?: GoogleProtobufField_mask.FieldMask
}

export type GetMarketConfigRequest = {
  name?: string
  readMask?: GoogleProtobufField_mask.FieldMask
}

export type HospitalConfig = {
  name?: string
  clinicName?: string
  lobTemplateId?: string
  enabled?: boolean
  rpmLobTemplateId?: string
  rpmEnabled?: boolean
  ccmLobTemplateId?: string
  ccmEnabled?: boolean
  apcmLobTemplateId?: string
  apcmEnabled?: boolean
  createTime?: GoogleProtobufTimestamp.Timestamp
  updateTime?: GoogleProtobufTimestamp.Timestamp
  createUser?: string
  updateUser?: string
}

export type CreateHospitalConfigRequest = {
  hospitalConfig?: HospitalConfig
}

export type UpdateHospitalConfigRequest = {
  hospitalConfig?: HospitalConfig
  updateMask?: GoogleProtobufField_mask.FieldMask
}

export type GetHospitalConfigRequest = {
  name?: string
  readMask?: GoogleProtobufField_mask.FieldMask
}

export type LobTemplate = {
  name?: string
  template?: string
  externalId?: string
  lobCreateTime?: GoogleProtobufTimestamp.Timestamp
  lobUpdateTime?: GoogleProtobufTimestamp.Timestamp
  deleted?: boolean
  program?: string
  createTime?: GoogleProtobufTimestamp.Timestamp
  updateTime?: GoogleProtobufTimestamp.Timestamp
  createUser?: string
  updateUser?: string
}

export type LobLetter = {
  name?: string
  letterId?: string
  letterType?: LobLetterLetterType
  letterStatus?: LobLetterLetterStatus
  syncStatus?: LobLetterSyncStatus
  sendTime?: GoogleProtobufTimestamp.Timestamp
  expectedDeliveryDate?: GoogleTypeDate.Date
  createTime?: GoogleProtobufTimestamp.Timestamp
  updateTime?: GoogleProtobufTimestamp.Timestamp
  createUser?: string
  updateUser?: string
}

export type ListLobTemplatesRequest = {
  filter?: string
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
  orderBy?: string
}

export type ListLobTemplatesResponse = {
  lobTemplates?: LobTemplate[]
  nextPageToken?: string
  totalSize?: number
}

export type GetLobTemplateRequest = {
  name?: string
  readMask?: GoogleProtobufField_mask.FieldMask
}

export type ListPatientLobLettersRequest = {
  parent?: string
  filter?: string
  pageSize?: number
  pageToken?: string
  readMask?: GoogleProtobufField_mask.FieldMask
  orderBy?: string
}

export type ListPatientLobLettersResponse = {
  patientLobLetters?: LobLetter[]
  nextPageToken?: string
  totalSize?: number
}

export type GeneratePatientLobTemplateDataRequest = {
  name?: string
  programType?: ProgramType
}

export type PatientLobTemplateData = {
  lobTemplateName?: string
  lobTemplateExternalId?: string
  lobTemplateEnabled?: boolean
  lobTemplateDeleted?: boolean
  patientId?: string
  patientFirstName?: string
  patientLastName?: string
  partnerLogo?: string
  cadenceLogo?: string
  clinicName?: string
  marketName?: string
  programName?: string
  senderAddress?: Address
  recipientAddress?: Address
  programNameEsp?: string
  providerName?: string
}

export type Address = {
  name?: string
  addressLine1?: string
  addressLine2?: string
  addressCity?: string
  addressState?: string
  addressZip?: string
}

export class PatientOutreachService {
  static CreateMarketConfig(req: CreateMarketConfigRequest, initReq?: fm.InitReq): Promise<MarketConfig> {
    return fm.fetchReq<CreateMarketConfigRequest, MarketConfig>(`/rpm/v1/patient-outreach-market-config`, {...initReq, method: "POST", body: JSON.stringify(req["market_config"], fm.replacer)})
  }
  static UpdateMarketConfig(req: UpdateMarketConfigRequest, initReq?: fm.InitReq): Promise<MarketConfig> {
    return fm.fetchReq<UpdateMarketConfigRequest, MarketConfig>(`/rpm/v1/${req["marketConfig"]?.["name"]}`, {...initReq, method: "PATCH", body: JSON.stringify(req["market_config"], fm.replacer)})
  }
  static GetMarketConfig(req: GetMarketConfigRequest, initReq?: fm.InitReq): Promise<MarketConfig> {
    return fm.fetchReq<GetMarketConfigRequest, MarketConfig>(`/rpm/v1/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static CreateHospitalConfig(req: CreateHospitalConfigRequest, initReq?: fm.InitReq): Promise<HospitalConfig> {
    return fm.fetchReq<CreateHospitalConfigRequest, HospitalConfig>(`/rpm/v1/patient-outreach-hospital-config`, {...initReq, method: "POST", body: JSON.stringify(req["hospital_config"], fm.replacer)})
  }
  static UpdateHospitalConfig(req: UpdateHospitalConfigRequest, initReq?: fm.InitReq): Promise<HospitalConfig> {
    return fm.fetchReq<UpdateHospitalConfigRequest, HospitalConfig>(`/rpm/v1/${req["hospitalConfig"]?.["name"]}`, {...initReq, method: "PATCH", body: JSON.stringify(req["hospital_config"], fm.replacer)})
  }
  static GetHospitalConfig(req: GetHospitalConfigRequest, initReq?: fm.InitReq): Promise<HospitalConfig> {
    return fm.fetchReq<GetHospitalConfigRequest, HospitalConfig>(`/rpm/v1/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static ListLobTemplates(req: ListLobTemplatesRequest, initReq?: fm.InitReq): Promise<ListLobTemplatesResponse> {
    return fm.fetchReq<ListLobTemplatesRequest, ListLobTemplatesResponse>(`/rpm/v1/patient-outreach-lob-template?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static GetLobTemplate(req: GetLobTemplateRequest, initReq?: fm.InitReq): Promise<LobTemplate> {
    return fm.fetchReq<GetLobTemplateRequest, LobTemplate>(`/rpm/v1/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static ListPatientLobLetters(req: ListPatientLobLettersRequest, initReq?: fm.InitReq): Promise<ListPatientLobLettersResponse> {
    return fm.fetchReq<ListPatientLobLettersRequest, ListPatientLobLettersResponse>(`/rpm/v1/${req["parent"]}/patient-outreach-lob-letter?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
  static GeneratePatientLobTemplateData(req: GeneratePatientLobTemplateDataRequest, initReq?: fm.InitReq): Promise<PatientLobTemplateData> {
    return fm.fetchReq<GeneratePatientLobTemplateDataRequest, PatientLobTemplateData>(`/rpm/v1/patients/${req["name"]}:generateLobTemplateData`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
}