import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  MedPermissions,
  PatientMedicationsList,
} from '@/pages/patients/PatientMedications/PatientMedicationsList';
import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { Form } from '@/shared/common/Form';
import { Wizard } from '@/shared/common/Wizard';
import {
  useWizardFormFromConfig,
  useWizardStateContext,
} from '@/shared/common/Wizard/state';
import { useFlags } from '@/shared/hooks';
import { useCareProvider } from '@/shared/hooks/queries';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { ProviderTeamRole } from '@/shared/types/provider.types';

import { FieldGroup } from '../../../shared';
import { useCNFormContext } from '../../CNFormContext';
import { CallButton } from '../shared/CallButton';
import { NOTES_FORM_CONFIG } from './notesFormConfig';
import type { NotesFormFields } from './notesFormConfig';

export function Notes() {
  const intl = useIntl();
  const history = useHistory();
  const form = useWizardFormFromConfig<NotesFormFields>(
    '/notes/index',
    NOTES_FORM_CONFIG,
  );
  const { onNavigate, wizardFormData } = useWizardStateContext();
  const { patientId, noteId } = useCNFormContext();
  const { currentUserId } = useCurrentUser();
  const { data: provider } = useCareProvider(currentUserId);
  const { titrationOutreachActions } = useFlags();
  const shouldDisplayMedsModule =
    titrationOutreachActions &&
    [
      ProviderTeamRole.PATIENT_COORDINATOR,
      ProviderTeamRole.TITRATION_CLINICAL_NAVIGATOR,
    ].includes(provider?.team_role as ProviderTeamRole);

  useEffect(() => {
    if (wizardFormData.navigationHistory.length === 0) {
      onNavigate(
        { pathname: '/notes/index', search: '', state: '', hash: '' },
        'PUSH',
      );
    }
  }, [wizardFormData.navigationHistory, onNavigate]);

  return (
    <Wizard.Step
      title={intl.formatMessage({ defaultMessage: 'Notes' })}
      sections={[]} // TODO: remove from Wizard.Step component
      form={form}
      onNext={() => history.push('/time-tracking/index')}
    >
      <FieldGroup>
        <CallButton />
        <Form.TextArea
          size={12}
          rows={8}
          name="notes"
          label={<FormattedMessage defaultMessage="Notes" />}
        />
        {shouldDisplayMedsModule && (
          <PatientMedicationsList
            patientId={patientId}
            noteId={noteId}
            medPermissions={MedPermissions.Review}
            showRequiredActions={false}
            hideSuggestions={false}
            typeOfEncounter={TypeOfEncounter.PATIENT_FOLLOW_UP}
          />
        )}
      </FieldGroup>
    </Wizard.Step>
  );
}
