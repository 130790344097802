import { useIntl } from 'react-intl';

import { AlertDescription } from '@/pages/patients/patientDetails/ui/AlertDescription';
import { Table } from '@/shared/common/Table';
import type { VitalsAlert } from '@/shared/types/alert.types';
import type { PatientVitals } from '@/shared/types/vitals.types';

import { table } from './PatientActivity.css';

type Props = {
  vitalsAlert: VitalsAlert;
  vitals: PatientVitals;
};

export function AlertDetail({ vitalsAlert, vitals }: Props) {
  const intl = useIntl();
  return (
    <Table>
      <Table.Header
        columns={[
          {
            title: intl.formatMessage({
              defaultMessage: 'Alert details',
            }),
            className: table.headerCell,
          },
        ]}
      />
      <Table.Body>
        <Table.Row>
          <Table.NodeCell className={table.cell}>
            <AlertDescription
              alert={vitalsAlert}
              vitals={vitals}
              isProfilePage={false}
            />
          </Table.NodeCell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
}
