import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { HeartRate } from '@/shared/assets/svgs';
import ListIcon from '@/shared/assets/svgs/list.svg?react';
import StopWatchIcon from '@/shared/assets/svgs/stopWatch.svg?react';
import { EmptyState } from '@/shared/common/EmptyState';
import { useFlags } from '@/shared/hooks';
import { Button } from '@/shared/tempo/atom/Button';
import { color } from '@/shared/tempo/theme';

import {
  type EncounterModuleInstance,
  type EncounterTypeInputs,
  TypeOfEncounter,
} from '../../Notes.types';
import { isAPCMEncounterType } from '../../utils/encounterTypeUtils';
import { NoteTimeTracker } from '../NoteTimeTracker';
import { useIsTimeTrackedTypeOfEncounter } from '../hooks/useIsTimeTrackedTypeOfEncounter';
import type {
  NoteFormExtras,
  NoteFormValues,
  SetNoteFormValue,
} from '../noteFormState';
import type {
  NoteFormSubmissionType,
  NoteFormValidationResult,
} from '../validation';
import { EncounterModuleSections } from './EncounterModuleSections';
import { EscalationFormFields } from './EscalationFormFields';
import { LabelsFormField } from './LabelsFormField';
import { RpmDualEnrollmentModal } from './RpmDualEnrollmentModal';
import { VisitLayoutSection } from './VisitLayoutSection';
import { button } from './VisitLayoutSection.css';

type Props = {
  encounterTypeInstance?: EncounterModuleInstance<EncounterTypeInputs>;
  validationResult: NoteFormValidationResult;
  noteFormExtras: NoteFormExtras;
  noteFormValues: NoteFormValues;
  setNoteFormValue: SetNoteFormValue;
  noteFormSubmissionType: NoteFormSubmissionType;
  patientId?: Maybe<string>;
  noteId?: Maybe<number>;
};

export const VisitLayout = ({
  encounterTypeInstance,
  noteFormExtras,
  noteFormValues,
  validationResult,
  setNoteFormValue,
  noteFormSubmissionType,
  patientId,
  noteId,
}: Props) => {
  const { showDualEnrollmentModule } = useFlags();
  const encounterTypeInputs = encounterTypeInstance?.inputs;
  const encounterType = encounterTypeInputs?.type_of_encounter;
  const isTimeTrackedEncounter =
    useIsTimeTrackedTypeOfEncounter(encounterTypeInputs);

  const isAPCMEncounter = isAPCMEncounterType(encounterType);
  const [isOpen, setIsOpen] = useState(false);

  if (!encounterType) {
    return (
      <EmptyState
        title={
          <FormattedMessage defaultMessage="Select an encounter type to begin" />
        }
      />
    );
  }

  const { setEncounterModuleInstance } = noteFormExtras;
  const { encounterModuleInstances: encounterModuleValidationResults } =
    validationResult;

  return (
    <>
      <EncounterModuleSections
        encounterTypeInstance={encounterTypeInstance}
        onChangeInstance={setEncounterModuleInstance}
        values={noteFormValues.encounterModuleInstances}
        validationResults={encounterModuleValidationResults}
        noteFormSubmissionType={noteFormSubmissionType}
        noteId={noteId}
        setNoteFormValue={setNoteFormValue}
      />
      {showDualEnrollmentModule && isAPCMEncounter && (
        <VisitLayoutSection
          title={
            <FormattedMessage defaultMessage="Remote Patient Monitoring Enrollment" />
          }
          icon={<HeartRate fill={color.Theme.Light['Base Font']} />}
        >
          <FormattedMessage defaultMessage="Start pitch to enroll patient." />
          <Button
            className={button}
            onPress={() => {
              setIsOpen(true);
            }}
          >
            <FormattedMessage defaultMessage="Start" />
          </Button>
        </VisitLayoutSection>
      )}
      {patientId && isOpen && (
        <RpmDualEnrollmentModal
          patientId={patientId}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      )}
      <VisitLayoutSection
        title={<FormattedMessage defaultMessage="Encounter Labels & Actions" />}
        icon={
          <ListIcon
            fill={color.Theme.Light['Base Font']}
            width={22}
            height={22}
          />
        }
      >
        <LabelsFormField
          hasLabelsError={validationResult.labels.length > 0}
          labels={noteFormValues.labels}
          onChange={(value) => setNoteFormValue('labels', value)}
        />
        <EscalationFormFields
          noteFormValues={noteFormValues}
          setNoteFormValue={setNoteFormValue}
          hasProviderError={validationResult.externalProviderId.length > 0}
        />
      </VisitLayoutSection>
      {isTimeTrackedEncounter && (
        <VisitLayoutSection
          title={<FormattedMessage defaultMessage="Time Tracking" />}
          icon={
            <StopWatchIcon
              fill={color.Theme.Light['Base Font']}
              width="20"
              height="20"
            />
          }
        >
          <NoteTimeTracker
            encounterTypeInputs={encounterTypeInputs}
            onChange={(timeEntry) => setNoteFormValue('timeEntry', timeEntry)}
            timeEntry={noteFormValues.timeEntry}
            validationResults={validationResult.timeEntry}
            patientId={patientId}
            noteId={noteId}
            captureInteractiveTime={
              encounterType !== TypeOfEncounter.ASYNC_REVIEW
            }
          />
        </VisitLayoutSection>
      )}
    </>
  );
};
