import findKey from 'lodash/findKey';

import type { Props as TempoSelectProps } from '@/shared/tempo/@labs/molecule/Select';
import { Select as TempoSelect } from '@/shared/tempo/@labs/molecule/Select';

import type { ValueMappable } from '../../form.types';
import { BaseField, type BaseFieldProps } from '../BaseField';
import { select } from './Select.css';

type Props<T, V> = {
  label: string;
} & Pick<TempoSelectProps<T>, 'children' | 'placeholder' | 'items'> &
  Omit<BaseFieldProps<V>, 'label'> &
  ValueMappable;

export function Select<T extends object, V>({
  children,
  placeholder,
  items,
  label,
  valueMap,
  ...baseProps
}: Props<T, V>) {
  return (
    <BaseField<V> {...baseProps} label="">
      {({ controller: { field, fieldState } }) => {
        // Get serialized value for the select component
        // This ensures proper comparison with option keys which are always strings in React
        const getSerializedValue = (value: unknown) => {
          if (!valueMap) {
            return value as string;
          }

          return findKey(valueMap, (val) => val === value);
        };

        const selectedKey = getSerializedValue(field.value);

        return (
          <TempoSelect
            isRequired={baseProps.required}
            label={label}
            name={field.name}
            className={select}
            errorMessage={fieldState.error?.message}
            hasError={Boolean(fieldState.error)}
            isDisabled={baseProps.isDisabled}
            selectedKey={selectedKey}
            onSelectionChange={(value) => {
              if (value === null) {
                field.onChange(null);
                return;
              }

              // Use valueMap if provided, otherwise use the string value as is
              const convertedValue = valueMap ? (valueMap[value] as V) : value;
              field.onChange(convertedValue);
            }}
            items={items}
            placeholder={placeholder}
            aria-label={label}
          >
            {children}
          </TempoSelect>
        );
      }}
    </BaseField>
  );
}
