import { useIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';

import { CCMPrognosis } from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';

export const CCM_PROGNOSIS_OPTIONS = [
  CCMPrognosis.GOOD,
  CCMPrognosis.FAIR,
  CCMPrognosis.POOR,
];

export function useCcmPrognosisI18n(): Record<CCMPrognosis, string> {
  const intl = useIntl();

  return ccmPrognosisI18n(intl);
}

export function ccmPrognosisI18n(intl: IntlShape, forNote?: boolean) {
  return {
    [CCMPrognosis.GOOD]: intl.formatMessage({
      defaultMessage: 'Well-controlled',
    }),
    [CCMPrognosis.FAIR]: forNote
      ? intl.formatMessage({
          defaultMessage: 'Suboptimal control',
        })
      : intl.formatMessage({
          defaultMessage: 'Suboptimal',
        }),
    [CCMPrognosis.POOR]: intl.formatMessage({
      defaultMessage: 'Poor',
    }), // Not being used
    [CCMPrognosis.CCM_PROGNOSIS_UNSPECIFIED]: intl.formatMessage({
      defaultMessage: 'Unknown condition severity',
    }),
  };
}
