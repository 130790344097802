import { isValid, set } from 'date-fns';
import omit from 'lodash/omit';
import { useMemo } from 'react';

import { ProgramType } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { getUserTimezone } from '@/shared/utils/time-helpers';

import type {
  EncounterModuleInstance,
  TimeEntry,
  TimeTracking,
} from '../../Notes.types';
import { useIsTimeTrackedTypeOfEncounterFromInstances } from './useIsTimeTrackedTypeOfEncounter';

export function useMarshaledTimeTrackingPayload(timeEntry: Partial<TimeEntry>) {
  return useMemo(() => {
    const timeEntryDateTime = getDateTimeFromTimeEntry(timeEntry);
    const entries = timeEntry.entries ?? {};

    return {
      ...omit(timeEntry, ['start_date', 'start_time', 'entries']),
      ...(timeEntryDateTime && {
        start_datetime: timeEntryDateTime.toISOString(),
      }),
      timezone: getUserTimezone(),
      entries: Object.keys(entries).map((key) => ({
        // default these just in case we don't have them for some reason.
        tasks_accomplished:
          entries[key as ProgramType]?.tasks_accomplished ?? [],
        other_task_description:
          entries[key as ProgramType]?.other_task_description ?? '',
        ...entries[key as ProgramType],
        program_type: key as ProgramType,
      })),

      // this is just for backwards compatibility and will be removed in a
      // subsequent PR (CARE-999)
      interactive_duration: entries[ProgramType.RPM]?.interactive_duration,
      non_interactive_duration:
        entries[ProgramType.RPM]?.non_interactive_duration,
      tasks_accomplished: entries[ProgramType.RPM]?.tasks_accomplished,
      other_task_description: entries[ProgramType.RPM]?.other_task_description,
    };
  }, [timeEntry]) satisfies TimeTracking;
}

export function useMarshaledTimeTrackingPayloadByEncounter(
  timeEntry: Partial<TimeEntry>,
  encounterModuleInstances: EncounterModuleInstance[],
) {
  const marshalled = useMarshaledTimeTrackingPayload(timeEntry);
  const isTimeTrackedTypeOfEncounterFromInstances =
    useIsTimeTrackedTypeOfEncounterFromInstances(encounterModuleInstances);
  return useMemo(() => {
    if (isTimeTrackedTypeOfEncounterFromInstances) {
      return marshalled;
    }
    return {};
  }, [isTimeTrackedTypeOfEncounterFromInstances, marshalled]) as
    | TimeTracking
    | Record<string, never>;
}

export function getDateTimeFromTimeEntry(timeEntry: Partial<TimeEntry>) {
  if (
    timeEntry.start_date &&
    timeEntry.start_time &&
    isValid(timeEntry.start_date)
  ) {
    return set(timeEntry.start_date, {
      hours: Number(timeEntry.start_time.split(':')[0]),
      minutes: Number(timeEntry.start_time.split(':')[1]),
      seconds: 0,
    });
  }

  return null;
}
