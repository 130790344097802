import { FormattedMessage } from 'react-intl';

import { errorMessage, errorMessageContainer } from './FormErrors.css';
import type { Errors } from './FormErrors.utils';
import { flattenErrors, getFieldLabel } from './FormErrors.utils';

type Props<T> = {
  errors: Errors<T>;
  errorLabels: Record<keyof T, string>;
  subfieldLabels?: Record<string, string>;
  message?: string;
};

export function FormErrors<T>({
  errors,
  errorLabels,
  subfieldLabels = {},
  message,
}: Props<T>) {
  const flattenedErrors = flattenErrors(errors);

  function scrollToField(fieldName: string) {
    const element = document.querySelector(`[name="${fieldName}"]`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  if (flattenedErrors.length === 0) {
    return null;
  }

  return (
    <>
      {message && <div className={errorMessage}>{message}</div>}
      {flattenedErrors.map(({ field, error }) => (
        <div
          key={field}
          className={errorMessageContainer}
          onClick={() => scrollToField(field)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              scrollToField(field);
            }
          }}
          role="button"
          tabIndex={0}
        >
          <FormattedMessage
            defaultMessage="{field}: {message}"
            values={{
              field: getFieldLabel(field, errorLabels, subfieldLabels),
              message: error.message,
            }}
          />
        </div>
      ))}
    </>
  );
}
