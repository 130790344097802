import {
  EncounterModuleId,
  TypeOfEncounter,
} from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { ConditionProgram } from '@/shared/types/condition.types';

import { draftJsFieldTemplate } from '../../../../template.utils';
import { endEarlyNoteTemplates } from '../../../__shared__/endEarly';
import patientNotesBody from './patient_notes.hbs';

const SHARED_MODULES = {
  [EncounterModuleId.PatientNotes]: {
    notes_body: draftJsFieldTemplate(patientNotesBody),
    endEarly: endEarlyNoteTemplates,
  },
};

export const titrationOutreachVisit = {
  [TypeOfEncounter.TITRATION_OUTREACH]: {
    [ConditionProgram.Hypertension]: { ...SHARED_MODULES },
    [ConditionProgram.TypeTwoDiabetes]: { ...SHARED_MODULES },
    [ConditionProgram.T2DAndHTN]: { ...SHARED_MODULES },
    [ConditionProgram.CHF]: { ...SHARED_MODULES },
  },
};
