import type { UseFormReturn } from 'react-hook-form';
import { useFieldArray } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { useFlags } from '@/shared/hooks';
import { Button } from '@/shared/tempo/atom/Button';

import { ContextualGoalButton } from '../../Goals/ContextualGoalButton';
import { GoalMeasure } from '../../Goals/goals.types';
import { RemovableRow } from '../RemovableRow';
import type { FormFields } from '../formConfig';
import {
  addItemButton,
  goalSectionContainer,
  sectionTitle,
} from './carePlanSections.css';

type Props = {
  form: UseFormReturn<FormFields>;
  manualAutoSave: () => void;
  createGoalWithMeasure: (measure: GoalMeasure) => void;
};

export function EmergencyVisits({
  form,
  manualAutoSave,
  createGoalWithMeasure,
}: Props) {
  const { carePlanOptimization } = useFlags();

  const intl = useIntl();
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'emergencyVisits',
  });

  return (
    <Form.Section
      title={
        <div className={goalSectionContainer}>
          {intl.formatMessage({
            defaultMessage:
              'Emergency Visits and Hospitalizations in the last 12 months',
          })}
          <ContextualGoalButton
            onPress={() =>
              createGoalWithMeasure(GoalMeasure.EDVisitOrHospitalization)
            }
          />
        </div>
      }
      description={
        carePlanOptimization
          ? intl.formatMessage({
              defaultMessage:
                "Ask the patient if they've had any emergency visits in the last 3 months. This includes visits to urgent care, the emergency room, or being admitted to the hospital.",
            })
          : undefined
      }
      classes={{ title: sectionTitle }}
    >
      {fields.map((field, index) => (
        <RemovableRow
          key={field.id}
          onChange={() => manualAutoSave()}
          onRemove={() => remove(index)}
          hasError={!!form.formState.errors.emergencyVisits?.[index]}
        >
          <Form.TextField
            required
            size={4}
            label={intl.formatMessage({
              defaultMessage: 'Hospital',
            })}
            name={`emergencyVisits.${index}.hospital`}
          />
          <Form.DatePicker
            required
            size={3}
            label={intl.formatMessage({
              defaultMessage: 'Date',
            })}
            name={`emergencyVisits.${index}.date`}
          />
          <Form.TextField
            required
            size={4}
            label={intl.formatMessage({
              defaultMessage: 'Related Diagnosis',
            })}
            name={`emergencyVisits.${index}.relatedDiagnosis`}
          />
        </RemovableRow>
      ))}
      <div
        className={
          !fields.length ? addItemButton.emptyList : addItemButton.default
        }
      >
        <Button
          variant="secondary"
          size="small"
          onPress={() =>
            append({ hospital: '', date: '', relatedDiagnosis: '' })
          }
        >
          <FormattedMessage defaultMessage="Add Emergency Visit" />
        </Button>
      </div>
    </Form.Section>
  );
}
