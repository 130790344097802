import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ArrowFillUp } from '@/shared/assets/svgs/index';
import { Table } from '@/shared/common/Table';
import { Button } from '@/shared/tempo/atom/Button/Button';
import type { Note } from '@/shared/types/note.types';
import {
  type NoteSymptom,
  useNoteSymptomI18nMap,
} from '@/shared/utils/note-symptom-data';

import { NoteDataRow } from './NoteDataRow';
import {
  noteDataExpand,
  noteDataExpandIcon,
  table,
} from './PatientActivity.css';

const MINIMIZED_NOTE_COUNT = 3;

type Props = {
  notesWithSymptoms: { note: Note; symptoms: NoteSymptom[] }[];
};

export function NoteDataTable({ notesWithSymptoms }: Props) {
  const intl = useIntl();
  const symptomI18n = useNoteSymptomI18nMap();
  const [isExpanded, setIsExpanded] = useState(false);
  const [viewCount, setViewCount] = useState(MINIMIZED_NOTE_COUNT);
  const shouldShowExpand = notesWithSymptoms.length > MINIMIZED_NOTE_COUNT;

  useEffect(() => {
    if (isExpanded) {
      setViewCount(notesWithSymptoms.length);
    } else {
      setViewCount(MINIMIZED_NOTE_COUNT);
    }
  }, [isExpanded, notesWithSymptoms.length]);

  return (
    <>
      <Table>
        <Table.Header
          columns={[
            {
              title: intl.formatMessage({ defaultMessage: 'Recent Symptoms' }),
              className: table.headerCell,
            },
          ]}
        />
        <Table.Body>
          {notesWithSymptoms.slice(0, viewCount).map(({ note, symptoms }) => (
            <NoteDataRow key={note.id} note={note}>
              {symptoms
                .map((symptom) => symptomI18n[symptom])
                .sort()
                .join('; ')}
            </NoteDataRow>
          ))}
          {shouldShowExpand && (
            <Table.Row>
              <Button
                variant="tertiary"
                type="button"
                className={noteDataExpand}
                onPress={() => setIsExpanded(!isExpanded)}
              >
                <ArrowFillUp
                  className={
                    isExpanded
                      ? noteDataExpandIcon.opened
                      : noteDataExpandIcon.closed
                  }
                />
                <FormattedMessage
                  defaultMessage="View { expanded }"
                  values={{ expanded: isExpanded ? 'less' : 'more' }}
                />
              </Button>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </>
  );
}
