import { useIntl } from 'react-intl';

import type { FormFields } from '../formConfig';
import { PREVENTATIVE_CARE_OPTIONS } from '../sections/preventativeCareOptions';

export function useFieldLabelsForErrors(): Record<keyof FormFields, string> {
  const intl = useIntl();

  // Create preventative care field labels
  const preventativeCareLabels = PREVENTATIVE_CARE_OPTIONS.reduce(
    (acc, option) => {
      const baseFieldName = `preventativeCare-${option.name}`;
      return {
        ...acc,
        [`${baseFieldName}-checked`]: intl.formatMessage(
          { defaultMessage: '{name} checked' },
          { name: option.label },
        ),
        [`${baseFieldName}-date`]: intl.formatMessage(
          { defaultMessage: '{name} date' },
          { name: option.label },
        ),
        [`${baseFieldName}-nextSteps`]: intl.formatMessage(
          { defaultMessage: '{name} next steps' },
          { name: option.label },
        ),
        [`${baseFieldName}-notApplicable`]: intl.formatMessage(
          { defaultMessage: '{name} not applicable' },
          { name: option.label },
        ),
        [`${baseFieldName}-status`]: intl.formatMessage(
          { defaultMessage: '{name} status' },
          { name: option.label },
        ),
      };
    },
    {} as Record<string, string>,
  );

  // Create assessment field labels
  const assessmentLabels = {
    'assessment-physicalHarmFrequency-checked': intl.formatMessage({
      defaultMessage: 'Physical harm frequency checked',
    }),
    'assessment-physicalHarmFrequency-description': intl.formatMessage({
      defaultMessage: 'Physical harm frequency description',
    }),
    'assessment-threatFrequency-checked': intl.formatMessage({
      defaultMessage: 'Threat frequency checked',
    }),
    'assessment-threatFrequency-description': intl.formatMessage({
      defaultMessage: 'Threat frequency description',
    }),
    'assessment-verbalAbuseFrequency-checked': intl.formatMessage({
      defaultMessage: 'Verbal abuse frequency checked',
    }),
    'assessment-verbalAbuseFrequency-description': intl.formatMessage({
      defaultMessage: 'Verbal abuse frequency description',
    }),
    'assessment-insultFrequency-checked': intl.formatMessage({
      defaultMessage: 'Insult frequency checked',
    }),
    'assessment-insultFrequency-description': intl.formatMessage({
      defaultMessage: 'Insult frequency description',
    }),
    'assessment-exerciseDaysPerWeek-checked': intl.formatMessage({
      defaultMessage: 'Exercise days per week checked',
    }),
    'assessment-exerciseDaysPerWeek-description': intl.formatMessage({
      defaultMessage: 'Exercise days per week description',
    }),
    'assessment-exerciseMinutesPerDay-checked': intl.formatMessage({
      defaultMessage: 'Exercise minutes per day checked',
    }),
    'assessment-exerciseMinutesPerDay-description': intl.formatMessage({
      defaultMessage: 'Exercise minutes per day description',
    }),
    physicalHarmFrequency: intl.formatMessage({
      defaultMessage: 'Physical harm frequency',
    }),
    threatFrequency: intl.formatMessage({
      defaultMessage: 'Threat frequency',
    }),
    verbalAbuseFrequency: intl.formatMessage({
      defaultMessage: 'Verbal abuse frequency',
    }),
    insultFrequency: intl.formatMessage({
      defaultMessage: 'Insult frequency',
    }),
    exerciseDaysPerWeek: intl.formatMessage({
      defaultMessage: 'Exercise days per week',
    }),
    exerciseMinutesPerDay: intl.formatMessage({
      defaultMessage: 'Exercise minutes per day',
    }),
  };

  // Create base form fields
  const baseFormFields = {
    tobaccoUse: intl.formatMessage({ defaultMessage: 'Tobacco use' }),
    tobaccoUserStatus: intl.formatMessage({
      defaultMessage: 'Tobacco user status',
    }),
    tobaccoTypes: intl.formatMessage({ defaultMessage: 'Tobacco types' }),
    smokingQuantity: intl.formatMessage({ defaultMessage: 'Smoking quantity' }),
    smokingYears: intl.formatMessage({ defaultMessage: 'Years smoking' }),
    fastFood: intl.formatMessage({ defaultMessage: 'Fast food consumption' }),
    snacks: intl.formatMessage({ defaultMessage: 'Snacking habits' }),
    desserts: intl.formatMessage({ defaultMessage: 'Dessert consumption' }),
    regularSoda: intl.formatMessage({
      defaultMessage: 'Regular soda consumption',
    }),
    sweetTea: intl.formatMessage({ defaultMessage: 'Sweet tea consumption' }),
    waterIntake: intl.formatMessage({ defaultMessage: 'Water intake' }),
    saltIntake: intl.formatMessage({ defaultMessage: 'Salt intake' }),
    exercise: intl.formatMessage({ defaultMessage: 'Exercise habits' }),
    ccmAppointment: intl.formatMessage({
      defaultMessage: 'Next follow-up appointment',
    }),
    providerAppointment: intl.formatMessage({
      defaultMessage: 'Next provider appointment',
    }),
    medsNotReviewed: intl.formatMessage({
      defaultMessage: 'Medications not reviewed',
    }),
    culturalCommunityResources: intl.formatMessage({
      defaultMessage: 'Cultural community resources',
    }),
    culturalPreferences: intl.formatMessage({
      defaultMessage: 'Cultural preferences',
    }),
    medicationAllergies: intl.formatMessage({
      defaultMessage: 'Medication allergies',
    }),
    surgicalHistories: intl.formatMessage({
      defaultMessage: 'Surgical history',
    }),
    specialists: intl.formatMessage({ defaultMessage: 'Specialists' }),
    emergencyVisits: intl.formatMessage({ defaultMessage: 'Emergency visits' }),
    goalsAndInterventions: intl.formatMessage({
      defaultMessage: 'Goals and interventions',
    }),
    housingStabilityChoice: intl.formatMessage({
      defaultMessage: 'Housing stability',
    }),
    housingStabilityDetail: intl.formatMessage({
      defaultMessage: 'Housing stability details',
    }),
    housingConcernsChoice: intl.formatMessage({
      defaultMessage: 'Housing concerns',
    }),
    housingConcerns: intl.formatMessage({ defaultMessage: 'Housing concerns' }),
    livingSituationNotes: intl.formatMessage({
      defaultMessage: 'Living situation notes',
    }),
    foodSecurityChoice: intl.formatMessage({ defaultMessage: 'Food security' }),
    foodWorryFrequency: intl.formatMessage({
      defaultMessage: 'Food worry frequency',
    }),
    foodRunOutFrequency: intl.formatMessage({
      defaultMessage: 'Food run out frequency',
    }),
    foodSecurityNotes: intl.formatMessage({
      defaultMessage: 'Food security notes',
    }),
    transportationSecurityChoice: intl.formatMessage({
      defaultMessage: 'Transportation security',
    }),
    transportationSecurityNotes: intl.formatMessage({
      defaultMessage: 'Transportation security notes',
    }),
    utilitySecurityChoice: intl.formatMessage({
      defaultMessage: 'Utility security',
    }),
    utilityStatus: intl.formatMessage({ defaultMessage: 'Utility status' }),
    utilitySecurityNotes: intl.formatMessage({
      defaultMessage: 'Utility security notes',
    }),
    safetyChoice: intl.formatMessage({ defaultMessage: 'Safety' }),
    safetyNotes: intl.formatMessage({ defaultMessage: 'Safety notes' }),
    dietNotes: intl.formatMessage({ defaultMessage: 'Diet notes' }),
  };

  // Create a function to handle dynamic assessment fields
  const getAssessmentFieldLabel = (field: string) => {
    if (field.startsWith('assessment-') && field.endsWith('-checked')) {
      return intl.formatMessage({ defaultMessage: 'Assessment checked' });
    }
    if (field.startsWith('assessment-') && field.endsWith('-description')) {
      return intl.formatMessage({ defaultMessage: 'Assessment description' });
    }
    return '';
  };

  // Combine all labels
  const allLabels = {
    ...baseFormFields,
    ...preventativeCareLabels,
    ...assessmentLabels,
  };

  // Add dynamic assessment field handling
  const dynamicLabels = new Proxy(allLabels, {
    get(target, prop) {
      if (typeof prop === 'string' && prop.startsWith('assessment-')) {
        return getAssessmentFieldLabel(prop);
      }
      return target[prop as keyof typeof target];
    },
  });

  // Type assertion to handle the dynamic nature of the fields
  return dynamicLabels as unknown as Record<keyof FormFields, string>;
}

export function useSubfieldLabelsForErrors(): Record<string, string> {
  const intl = useIntl();

  return {
    // Emergency visits subfields
    hospital: intl.formatMessage({ defaultMessage: 'Hospital name' }),
    date: intl.formatMessage({ defaultMessage: 'Visit date' }),
    relatedDiagnosis: intl.formatMessage({
      defaultMessage: 'Related diagnosis',
    }),

    // Medication allergies subfields
    medication: intl.formatMessage({ defaultMessage: 'Medication name' }),
    reaction: intl.formatMessage({ defaultMessage: 'Reaction' }),

    // Surgical history subfields
    details: intl.formatMessage({ defaultMessage: 'Surgery details' }),
    diagnosis: intl.formatMessage({ defaultMessage: 'Diagnosis' }),

    // Specialists subfields
    name: intl.formatMessage({ defaultMessage: 'Specialist name' }),
    specialty: intl.formatMessage({ defaultMessage: 'Specialty' }),
    lastVisit: intl.formatMessage({ defaultMessage: 'Last visit date' }),
    nextVisit: intl.formatMessage({ defaultMessage: 'Next visit date' }),

    // Goals and interventions subfields
    condition: intl.formatMessage({ defaultMessage: 'Condition' }),
    prognosis: intl.formatMessage({ defaultMessage: 'Prognosis' }),
    treatmentGoal: intl.formatMessage({ defaultMessage: 'Treatment goal' }),
    healthGoal: intl.formatMessage({ defaultMessage: 'Health goal' }),
    actionSteps: intl.formatMessage({ defaultMessage: 'Action steps' }),
    confidence: intl.formatMessage({ defaultMessage: 'Confidence level' }),
    coordination: intl.formatMessage({ defaultMessage: 'Coordination' }),
    prognosisNotes: intl.formatMessage({ defaultMessage: 'Prognosis notes' }),
  };
}
