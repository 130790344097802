import cx from 'classnames';
import { differenceInDays, isEqual, startOfDay, startOfToday } from 'date-fns';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import type { TimeEntry } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import {
  EditableNoteType,
  TypeOfEncounter,
} from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import type { Appointment } from '@/shared/generated/grpc/go/pms/pkg/scheduling/scheduling.pb';
import { Label } from '@/shared/tempo/atom/Label';

import { getTotalTime } from '../../../../shared/timeTracking.utils';
import { useEncounterModuleInstances } from '../../hooks/useEncounterModuleInstances.hook';
import { useIsTimeTrackedTypeOfEncounterFromInstances } from '../../hooks/useIsTimeTrackedTypeOfEncounter';
import { AppointmentsSelect, NO_ASSOCIATED_APPT } from './AppointmentsSelect';
import { ListContainer, ListItemContainer } from './ConfirmationComponents';
import {
  appts,
  confirmation as confirmationStyles,
  xxsLeftPad,
} from './PublishConfirmationDialog.css';

/**
 * Returns criteria for which we need confirmations of (when true) in order to publish
 * the note
 */
export function useGetPublishDialogConfirmations(
  timeEntry: Partial<TimeEntry>,
  noteType: Maybe<EditableNoteType>,
  hasApptAssociations: boolean = false,
) {
  const { encounterTypeInstance, encounterModuleInstances } =
    useEncounterModuleInstances();
  const isTimeTracked = useIsTimeTrackedTypeOfEncounterFromInstances(
    encounterModuleInstances,
  );
  const totalTime = getTotalTime(timeEntry);

  return {
    isZeroDuration: Boolean(isTimeTracked && totalTime === 0),
    isNonTodayStartDate: Boolean(
      isTimeTracked &&
        timeEntry.start_date &&
        !isEqual(startOfDay(timeEntry.start_date), startOfToday()),
    ),
    isAppointmentAssociationNeeded:
      !hasApptAssociations &&
      noteType !== EditableNoteType.Alert &&
      // Only associate appointments for the following encounter types
      [
        TypeOfEncounter.INITIAL_CN_VISIT,
        TypeOfEncounter.INITIAL_RN_VISIT_DEPRECATED,
        TypeOfEncounter.INITIAL_NP_VISIT,
        TypeOfEncounter.CN_VISIT,
        TypeOfEncounter.REGULAR_RN_VISIT_DEPRECATED,
        TypeOfEncounter.NP_VISIT,
        TypeOfEncounter.CCM_VISIT,
        TypeOfEncounter.CCM_CARE_PLAN,
        TypeOfEncounter.APCM_CARE_PLAN,
        TypeOfEncounter.APCM_VISIT,
        TypeOfEncounter.VIRTUAL_ENROLLMENT,
        TypeOfEncounter.PATIENT_ENROLLMENT,
        TypeOfEncounter.WELCOME_CALL,
      ].includes(
        encounterTypeInstance?.inputs.type_of_encounter as TypeOfEncounter,
      ),
  };
}

export type PublishConfirmations = ReturnType<
  typeof useGetPublishDialogConfirmations
>;

export function getTimeTrackingConfirmations(
  confirmations: PublishConfirmations,
  timeEntry: Partial<TimeEntry>,
) {
  const showAsList = getNumOfConfirmations(confirmations) > 1;
  const nonApptAssociationConfirmations = getNumOfConfirmations({
    ...confirmations,
    // Ignore appt confirmation since it is not shown in bullet list
    isAppointmentAssociationNeeded: false,
  });
  const singleBulletPoint = nonApptAssociationConfirmations === 1;

  const {
    isNonTodayStartDate,
    isZeroDuration,
    isAppointmentAssociationNeeded,
  } = confirmations;
  const confirmationsList: ReactNode[] = [];
  if (isZeroDuration) {
    confirmationsList.push(
      <ListItemContainer showAsList={showAsList} key="is_zero_duration">
        <FormattedMessage defaultMessage="Less than 1 minute of time has been tracked with this encounter." />
        {singleBulletPoint && (
          <span className={xxsLeftPad}>
            <FormattedMessage defaultMessage="If this is not accurate, press cancel and update the time tracker of this encounter." />
          </span>
        )}
      </ListItemContainer>,
    );
  }
  if (isNonTodayStartDate && timeEntry.start_date) {
    confirmationsList.push(
      <ListItemContainer showAsList={showAsList} key="is_non_today_start_date">
        <FormattedMessage
          defaultMessage="The date of this encounter was {daysAgo, plural, one {yesterday} other {{daysAgo} days ago}}."
          values={{
            daysAgo: differenceInDays(new Date(), timeEntry.start_date),
          }}
        />
        {singleBulletPoint && (
          <span className={xxsLeftPad}>
            <FormattedMessage defaultMessage="If this is not accurate, press cancel and update the date of this encounter." />
          </span>
        )}
      </ListItemContainer>,
    );
  }
  return (
    <>
      <ListContainer showAsList={showAsList}>{confirmationsList}</ListContainer>
      {isNonTodayStartDate && isZeroDuration && (
        <div className={confirmationStyles.spacedSection}>
          <FormattedMessage defaultMessage="If this is not accurate, press cancel and update the time tracker and date of this encounter." />
        </div>
      )}
      <div
        className={cx({
          [confirmationStyles.spacedSection]: confirmationsList.length > 0,
        })}
      >
        {isAppointmentAssociationNeeded ? (
          <FormattedMessage
            defaultMessage="{confirmations, select, 0 {Please } other {If this is accurate, }}confirm this encounter is associated with the selected appointment and press Confirm & Publish to complete this encounter."
            values={{ confirmations: nonApptAssociationConfirmations }}
          />
        ) : (
          <FormattedMessage defaultMessage="If this is accurate, press Confirm & Publish to complete this encounter." />
        )}
      </div>
    </>
  );
}

export function getAssociatedAppointmentConfirmation(
  confirmations: PublishConfirmations,
  appointments: Appointment[],
  selectedAppointmentId: Maybe<string>,
  onSetAppointment: (appointmentId: Maybe<string>) => void,
) {
  if (confirmations.isAppointmentAssociationNeeded) {
    return (
      <div className={appts.container}>
        <Label
          className={appts.label}
          label={
            <FormattedMessage defaultMessage="Patient appointment associated with this encounter" />
          }
        />
        <AppointmentsSelect
          selectedId={selectedAppointmentId}
          appointments={appointments}
          onChange={(apptId) => {
            if (apptId === NO_ASSOCIATED_APPT) {
              onSetAppointment(null);
            } else {
              onSetAppointment(apptId);
            }
          }}
        />
      </div>
    );
  }
  return null;
}

function getNumOfConfirmations(confirmations: PublishConfirmations) {
  return Object.values(confirmations).filter(Boolean).length;
}
