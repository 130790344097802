import concat from 'lodash/concat';
import times from 'lodash/times';
import type { IntlShape } from 'react-intl';
import * as yup from 'yup';
import type { TestContext } from 'yup';
import type { InternalOptions } from 'yup/lib/types';

import type { FormConfig } from '@/shared/common/Form';
import type { FieldsMap } from '@/shared/common/Form/form.types';
import { validators } from '@/shared/common/Form/validations';
import {
  PreventativeCareStatus,
  TobaccoType,
  TobaccoUserStatus,
} from '@/shared/generated/grpc/go/pms/pkg/care_plan/care_plan.pb';
import type {
  CCMCarePlan,
  MedicationAllergy,
  Specialist,
  SurgicalHistory,
} from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import {
  CCMPrognosis,
  GoalsAndInterventionsActionStep,
  GoalsAndInterventionsHealthGoal,
} from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import { type PatientDemographics } from '@/shared/hooks/usePatientDemographics';
import { convertToCcmCondition } from '@/shared/patient/conditions.utils';
import {
  type CcmCondition,
  Condition,
} from '@/shared/types/clinicalprofile.types';

import { CCM_CONDITION_RESOURCES } from './conditions';
import { getDefaultHealthGoal } from './sections/GoalsAndInterventions/goalUtils';
import type { InitialCarePlanConditions } from './sections/GoalsAndInterventions/hooks/useInitialCarePlanConditions.hook';
import { getDefaultGoalSetting } from './sections/GoalsAndInterventions/prognosisUtils';
import {
  getDefaultDescription,
  getDefaultPreventativeCareStatus,
} from './sections/PreventativeCare/preventativeCare.utils';
import {
  HousingConcern,
  HousingConcernsChoice,
  HousingStabilityChoice,
  HousingStabilityDetail,
} from './sections/PsychosocialAssessment/livingSituation.types';
import {
  type DaysPerWeek,
  type MinutesPerDay,
} from './sections/PsychosocialAssessment/physicalActivity.types';
import { PSYCHOSOCIAL_ASSESSMENT_OPTIONS } from './sections/PsychosocialAssessment/psychoSocialAssessmentOptions';
import {
  SafetyChoice,
  SafetyFrequencyChoice,
} from './sections/PsychosocialAssessment/safety.types';
import { TransportationSecurityChoice } from './sections/PsychosocialAssessment/transportationSecurity.types';
import {
  UtilitySecurityChoice,
  UtilityStatus,
} from './sections/PsychosocialAssessment/utilities.types';
import { PREVENTATIVE_CARE_OPTIONS } from './sections/preventativeCareOptions';
import {
  FoodFrequencyChoice,
  FoodSecurityChoice,
} from './sections/reliableSourceOfFood.types';

export type PreventativeCareName =
  (typeof PREVENTATIVE_CARE_OPTIONS)[number]['name'];
export type AssessmentName =
  (typeof PSYCHOSOCIAL_ASSESSMENT_OPTIONS)[number]['name'];

export type PreventativeCareFields = {
  [key in `preventativeCare-${PreventativeCareName}-checked`]?: boolean;
} & {
  [key in `preventativeCare-${PreventativeCareName}-date`]?: Date | null;
} & {
  [key in `preventativeCare-${PreventativeCareName}-nextSteps`]?: string | null;
} & {
  [key in `preventativeCare-${PreventativeCareName}-notApplicable`]?: boolean;
} & {
  [key in `preventativeCare-${PreventativeCareName}-status`]?: PreventativeCareStatus;
};

export type AssessmentFields = {
  [key in `assessment-${AssessmentName}-checked`]?: boolean;
} & {
  [key in `assessment-${AssessmentName}-description`]?: string;
};

export type FormFields = {
  tobaccoUse?: string;
  tobaccoUserStatus?: TobaccoUserStatus;
  tobaccoTypes?: TobaccoType[];
  smokingQuantity?: string;
  smokingYears?: number;
  fastFood?: string;
  snacks?: string;
  desserts?: string;
  regularSoda?: string;
  sweetTea?: string;
  waterIntake?: string;
  saltIntake?: string;
  exercise?: string;
  ccmAppointment?: string;
  providerAppointment?: string;
  medsNotReviewed?: boolean;
  culturalCommunityResources?: string;
  culturalPreferences?: string;
  medicationAllergies?: MedicationAllergy[];
  surgicalHistories?: SurgicalHistory[];
  specialists?: Specialist[];
  emergencyVisits?: EmergencyVisit[];
  goalsAndInterventions?: GoalsAndInterventions[];
  housingStabilityChoice?: HousingStabilityChoice;
  housingStabilityDetail?: HousingStabilityDetail;
  housingConcernsChoice?: HousingConcernsChoice;
  housingConcerns?: HousingConcern[];
  livingSituationNotes?: string;
  foodSecurityChoice?: FoodSecurityChoice;
  foodWorryFrequency?: FoodFrequencyChoice;
  foodRunOutFrequency?: FoodFrequencyChoice;
  foodSecurityNotes?: string;
  transportationSecurityChoice?: TransportationSecurityChoice;
  transportationSecurityNotes?: string;
  utilitySecurityChoice?: UtilitySecurityChoice;
  utilityStatus?: UtilityStatus;
  utilitySecurityNotes?: string;
  safetyChoice?: SafetyChoice;
  safetyNotes?: string;
  physicalHarmFrequency?: SafetyFrequencyChoice;
  threatFrequency?: SafetyFrequencyChoice;
  verbalAbuseFrequency?: SafetyFrequencyChoice;
  insultFrequency?: SafetyFrequencyChoice;
  exerciseDaysPerWeek?: DaysPerWeek;
  exerciseMinutesPerDay?: MinutesPerDay;
  dietNotes?: string;
} & PreventativeCareFields &
  AssessmentFields;

type EmergencyVisit = {
  hospital?: string;
  // Must use empty string for empty date or picker will default to today
  date?: Date | '';
  relatedDiagnosis?: string;
};

export type GoalSetting = boolean | 'notDiscussed';

export type GoalsAndInterventions = {
  condition?: CcmCondition;
  prognosis?: CCMPrognosis;
  treatmentGoal?: string;
  healthGoal?: string;
  healthGoalEnum?: GoalsAndInterventionsHealthGoal;
  customHealthGoal?: string;
  actionSteps?: string;
  actionStepsListEnums?: GoalsAndInterventionsActionStep[];
  actionStepsList?: string[];
  customActionStep?: string;
  confidence?: number;
  coordination?: string;
  skipGoalSetting?: boolean;
  prognosisNotes?: string;
  goalSetting?: GoalSetting;
};

export const EMPTY_GOALS_SECTION: GoalsAndInterventions = {
  condition: undefined,
  prognosis: undefined,
  treatmentGoal: '',
  healthGoal: '',
  actionSteps: '',
  confidence: undefined,
  coordination: '',
};

export const EMPTY_ALLERGIES_SECTION: MedicationAllergy = {
  medication: '',
  reaction: '',
};

export const EMPTY_EMERGENCY_VISIT_SECTION: EmergencyVisit = {
  date: '',
  hospital: '',
  relatedDiagnosis: '',
};

export const EMPTY_PAST_SURGICAL_HISTORY_SECTION: SurgicalHistory = {
  details: '',
  diagnosis: '',
};

export const EMPTY_SPECIALISTS_SECTION: Specialist = {
  name: '',
  specialty: '',
  lastVisit: '',
  nextVisit: '',
};

export function getFormConfig(
  intl: IntlShape,
  fieldValues: Partial<FormFields>,
  shouldShowCarePlanV1Changes: boolean,
  isCCMCarePlan: boolean,
  carePlanOptimization: boolean,
  patientDemographics?: PatientDemographics,
  initialConditions?: InitialCarePlanConditions,
): FormConfig {
  const { required, date, number, enumType } = validators(intl);

  return {
    fields: {
      ...(carePlanOptimization
        ? {
            tobaccoUse: {
              defaultValue: fieldValues.tobaccoUse ?? '',
              validation: yup.string(),
            },
            tobaccoUserStatus: {
              defaultValue: fieldValues.tobaccoUserStatus,
              validation: enumType({
                source: TobaccoUserStatus,
                pluck: 'values',
              }),
            },
            tobaccoTypes: {
              defaultValue: fieldValues.tobaccoTypes ?? [],
              validation: yup
                .array()
                .of(enumType({ source: TobaccoType, pluck: 'values' })),
            },
            smokingQuantity: {
              defaultValue: fieldValues.smokingQuantity,
              validation: yup.string().test(
                'is-valid-number-format',
                intl.formatMessage({
                  defaultMessage:
                    'Please enter a valid number (integer, decimal, or fraction)',
                }),
                (value) => {
                  if (!value) return true; // Allow empty values

                  // Allow 3+, which we are using to represent the "3 or more" value in Athena
                  if (value === '3+') return true;

                  // Regex to match integers, decimals, or fractions
                  // Integers: 0, 1, 123
                  // Decimals: 0.5, 1.25
                  // Fractions: 1/2, 3/4, 10/3
                  // Note: Denominator cannot be 0
                  return /^(\d+(\.\d+)?|\d+\/[1-9]\d*)$/.test(value);
                },
              ),
            },
            smokingYears: {
              defaultValue: fieldValues.smokingYears,
              validation: yup
                .number()
                .transform((value, originalValue) =>
                  originalValue === '' ? null : value,
                )
                .nullable(),
            },
          }
        : {
            tobaccoUse: {
              defaultValue: fieldValues.tobaccoUse ?? '',
              validation: required(yup.string()),
            },
          }),
      ...(shouldShowCarePlanV1Changes
        ? {
            dietNotes: {
              defaultValue: fieldValues.dietNotes ?? '',
              validation: required(yup.string()),
            },
          }
        : {
            fastFood: {
              defaultValue: fieldValues.fastFood ?? '',
              validation: required(yup.string()),
            },
            snacks: {
              defaultValue: fieldValues.snacks ?? '',
              validation: required(yup.string()),
            },
            desserts: {
              defaultValue: fieldValues.desserts ?? '',
              validation: required(yup.string()),
            },
            regularSoda: {
              defaultValue: fieldValues.regularSoda ?? '',
              validation: required(yup.string()),
            },
            sweetTea: {
              defaultValue: fieldValues.sweetTea ?? '',
              validation: required(yup.string()),
            },
            waterIntake: {
              defaultValue: fieldValues.waterIntake ?? '',
              validation: required(yup.string()),
            },
            saltIntake: {
              defaultValue: fieldValues.saltIntake ?? '',
              validation: required(yup.string()),
            },
            exercise: {
              defaultValue: fieldValues.exercise ?? '',
              validation: required(yup.string()),
            },
          }),
      ccmAppointment: {
        defaultValue: fieldValues.ccmAppointment ?? '',
        validation: required(yup.string()),
      },
      providerAppointment: {
        defaultValue: fieldValues.providerAppointment ?? '',
        validation: required(yup.string()),
      },
      surgicalHistories: {
        defaultValue: fieldValues.surgicalHistories ?? [
          { ...EMPTY_PAST_SURGICAL_HISTORY_SECTION },
        ],
        validation: yup.array().of(
          yup.object().shape({
            details: yup.string(),
            diagnosis: yup.string(),
          }),
        ),
      },
      specialists: {
        defaultValue: fieldValues.specialists ?? [
          { ...EMPTY_SPECIALISTS_SECTION },
        ],
        validation: yup.array().of(
          yup.object().shape({
            name: yup.string(),
            specialty: yup.string(),
            lastVisit: yup.string(),
            nextVisit: yup.string(),
          }),
        ),
      },
      medsNotReviewed: {
        defaultValue: fieldValues.medsNotReviewed ?? false,
        validation: yup.boolean(),
      },
      medicationAllergies: {
        defaultValue: fieldValues.medicationAllergies ?? [
          { ...EMPTY_ALLERGIES_SECTION },
        ],
        validation: yup.array().of(
          yup.object().shape({
            medication: required(yup.string()),
            reaction: yup.string(),
          }),
        ),
      },
      emergencyVisits: {
        defaultValue: fieldValues.emergencyVisits ?? [
          { ...EMPTY_EMERGENCY_VISIT_SECTION },
        ],
        validation: yup.array().of(
          yup.object().shape({
            hospital: required(yup.string()),
            date: required(date()),
            relatedDiagnosis: required(yup.string()),
          }),
        ),
      },
      goalsAndInterventions: {
        defaultValue: fieldValues.goalsAndInterventions?.length
          ? fieldValues.goalsAndInterventions.map((goal) => {
              if (!carePlanOptimization) {
                return goal;
              }
              const otherActionStepIndex =
                goal.actionStepsListEnums?.findIndex(
                  (actionStep) =>
                    actionStep === GoalsAndInterventionsActionStep.OTHER,
                ) ?? -1;
              return {
                ...goal,
                customActionStep:
                  otherActionStepIndex !== -1
                    ? goal.actionStepsList?.[otherActionStepIndex]
                    : undefined,
              };
            })
          : getDefaultGoalsAndInterventions(
              initialConditions,
              carePlanOptimization,
              isCCMCarePlan,
              intl,
            ),
        validation: yup
          .array()
          .of(
            yup.object().shape({
              condition: required(
                enumType({ source: Condition, pluck: 'values' }),
              ).test(
                'unique-condition',
                intl.formatMessage({
                  defaultMessage: 'Condition must be unique',
                }),
                (value: string | undefined, context: TestContext) => {
                  // https://github.com/jquense/yup/issues/398#issuecomment-916693907
                  const options = context.options as InternalOptions;
                  const { from: parents } = options;
                  const allConditions = (
                    parents?.[1].value as CCMCarePlan['carePlan']
                  )?.goalsAndInterventions
                    ?.map((item) => item.condition)
                    .filter(Boolean);

                  const conditionOccurrences =
                    allConditions?.filter((cond) => value && cond === value)
                      .length || 0;

                  return conditionOccurrences <= 1;
                },
              ),
              prognosis: required(
                enumType({ source: CCMPrognosis, pluck: 'values' }),
              ).test(
                'not-undetermined',
                intl.formatMessage({
                  defaultMessage: 'Must not be undetermined',
                }),
                (value: string) =>
                  value !== CCMPrognosis.CCM_PROGNOSIS_UNSPECIFIED,
              ),
              treatmentGoal: yup.string(),
              ...(carePlanOptimization
                ? {
                    goalSetting: yup.mixed().nullable(),
                    healthGoalEnum: enumType({
                      source: GoalsAndInterventionsHealthGoal,
                      pluck: 'values',
                    }),
                    customHealthGoal: yup
                      .string()
                      .when(['healthGoalEnum', 'goalSetting'], {
                        is: (
                          healthGoalEnum: GoalsAndInterventionsHealthGoal,
                          goalSetting: GoalSetting,
                        ) =>
                          healthGoalEnum ===
                            GoalsAndInterventionsHealthGoal.HEALTH_GOAL_UNSPECIFIED &&
                          goalSetting === true,
                        then: (schema) => required(schema),
                      }),
                    actionStepsListEnums: yup
                      .array()
                      .nullable()
                      .of(
                        enumType({
                          source: GoalsAndInterventionsActionStep,
                          pluck: 'values',
                        }),
                      ),
                    customActionStep: yup.string(),
                    actionSteps: yup.string().when('goalSetting', {
                      is: (goalSetting: GoalSetting) => goalSetting === true,
                      then: (schema) => required(schema),
                    }),
                  }
                : {
                    skipGoalSetting: yup.boolean().nullable(),
                    healthGoal: yup.string().when('skipGoalSetting', {
                      is: (skipGoalSetting: boolean) => !skipGoalSetting,
                      then: (schema) => required(schema),
                    }),
                    actionSteps: yup.string().when('skipGoalSetting', {
                      is: (skipGoalSetting: boolean) => !skipGoalSetting,
                      then: (schema) => required(schema),
                    }),
                    coordination: yup.string().when('skipGoalSetting', {
                      is: (skipGoalSetting: boolean) => !skipGoalSetting,
                      then: (schema) => required(schema),
                    }),
                  }),
              confidence: number({ min: 1, max: 10 }).nullable(),
              prognosisNotes: yup.string(),
            }),
          )
          .test('min-conditions', '', (value, context) => {
            if (!isCCMCarePlan) return true;

            const minConditions = 2;
            const isValid = (value?.length ?? 0) >= minConditions;

            return (
              isValid ||
              context.createError({
                message: intl.formatMessage({
                  defaultMessage: 'At least two conditions are required',
                }),
              })
            );
          }),
      },
      ...getPreventativeCareFields(
        intl,
        fieldValues,
        shouldShowCarePlanV1Changes,
        patientDemographics,
        carePlanOptimization,
      ),
      ...getAssessmentFields(intl, fieldValues),
      culturalPreferences: {
        validation: required(yup.string()),
        defaultValue: fieldValues.culturalPreferences,
      },
      culturalCommunityResources: {
        validation: required(yup.string()),
        defaultValue: fieldValues.culturalCommunityResources,
      },
      ...(shouldShowCarePlanV1Changes
        ? getAssessmentFieldsV2(intl, fieldValues)
        : {}),
    },
  };
}

export function getPreventativeCareFieldNames(baseName: PreventativeCareName) {
  const baseFieldName = `preventativeCare-${baseName}`;

  return {
    checked: `${baseFieldName}-checked` as keyof PreventativeCareFields,
    date: `${baseFieldName}-date` as keyof PreventativeCareFields,
    nextSteps: `${baseFieldName}-nextSteps` as keyof PreventativeCareFields,
    notApplicable:
      `${baseFieldName}-notApplicable` as keyof PreventativeCareFields,
    status: `${baseFieldName}-status` as keyof PreventativeCareFields,
  };
}

function getPreventativeCareFields(
  intl: IntlShape,
  fieldValues: Partial<FormFields>,
  shouldShowCarePlanV1Changes: boolean,
  patientDemographics?: PatientDemographics,
  carePlanOptimization?: boolean,
): FieldsMap {
  const { required } = validators(intl);

  return PREVENTATIVE_CARE_OPTIONS.reduce((fields, { name, notApplicable }) => {
    const {
      checked: checkedFieldName,
      date: dateFieldName,
      nextSteps: nextStepsFieldName,
      notApplicable: notApplicableFieldName,
      status: statusFieldName,
    } = getPreventativeCareFieldNames(name);

    const isNotApplicable = Boolean(
      patientDemographics && notApplicable?.(patientDemographics),
    );

    const statusFieldDefaultValue =
      fieldValues[statusFieldName] &&
      fieldValues[statusFieldName] !==
        PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_UNSPECIFIED
        ? (fieldValues[statusFieldName] as PreventativeCareStatus)
        : getDefaultPreventativeCareStatus(
            isNotApplicable,
            fieldValues[dateFieldName] as string,
            name,
            carePlanOptimization,
          );
    const defaultDescription =
      fieldValues[nextStepsFieldName] ||
      getDefaultDescription(
        intl,
        statusFieldDefaultValue,
        fieldValues[dateFieldName] as string,
      );

    return {
      ...fields,
      ...(shouldShowCarePlanV1Changes
        ? {
            [dateFieldName]: {
              defaultValue: fieldValues[dateFieldName] ?? '',
              validation: yup.string().when(statusFieldName, {
                is: PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_UP_TO_DATE,
                then: (schema) => required(schema),
              }),
            },
            [statusFieldName]: {
              defaultValue: statusFieldDefaultValue,
            },
            [notApplicableFieldName]: {
              defaultValue: isNotApplicable,
            },
            [nextStepsFieldName]: {
              defaultValue: carePlanOptimization
                ? defaultDescription
                : fieldValues[nextStepsFieldName] ?? '',
              validation: yup
                .string()
                .trim()
                .when(statusFieldName, {
                  is: PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_ACTION_NEEDED,
                  then: (schema) => required(schema),
                }),
            },
          }
        : {
            [checkedFieldName]: {
              defaultValue: fieldValues[checkedFieldName] ?? false,
            },
            [dateFieldName]: {
              defaultValue: fieldValues[dateFieldName] ?? '',
              validation: yup.string().when(checkedFieldName, {
                is: true,
                then: (schema) => required(schema),
              }),
            },
            [notApplicableFieldName]: {
              defaultValue: fieldValues[notApplicableFieldName] ?? false,
            },
            [nextStepsFieldName]: {
              defaultValue: fieldValues[nextStepsFieldName] ?? '',
            },
          }),
    };
  }, {});
}

export function getAssessmentFieldNames(baseName: AssessmentName) {
  const baseFieldName = `assessment-${baseName}`;

  return {
    checked: `${baseFieldName}-checked` as keyof AssessmentFields,
    description: `${baseFieldName}-description` as keyof AssessmentFields,
  };
}

function getAssessmentFields(
  intl: IntlShape,
  fieldValues: Partial<FormFields>,
): FieldsMap {
  const { required } = validators(intl);

  return PSYCHOSOCIAL_ASSESSMENT_OPTIONS.reduce((fields, { name }) => {
    const { checked: checkedFieldName, description: descriptionFieldName } =
      getAssessmentFieldNames(name);

    return {
      ...fields,
      [checkedFieldName]: {
        defaultValue: fieldValues[checkedFieldName] ?? false,
      },
      [descriptionFieldName]: {
        defaultValue: fieldValues[descriptionFieldName] ?? '',
        validation: yup
          .string()
          .trim()
          .when(checkedFieldName, {
            is: true,
            then: (schema) => required(schema),
          }),
      },
    };
  }, {});
}

function getAssessmentFieldsV2(
  intl: IntlShape,
  fieldValues: Partial<FormFields>,
): FieldsMap {
  const { required, enumType } = validators(intl);

  return {
    housingStabilityChoice: {
      defaultValue: fieldValues.housingStabilityChoice,
      validation: required(
        enumType({ source: HousingStabilityChoice, pluck: 'values' }),
      ),
    },
    housingStabilityDetail: {
      defaultValue: fieldValues.housingStabilityDetail,
      validation: yup.string().when('housingStabilityChoice', {
        is: HousingStabilityChoice.Yes,
        then: enumType({ source: HousingStabilityDetail, pluck: 'values' }),
      }),
    },
    housingConcernsChoice: {
      defaultValue: fieldValues.housingConcernsChoice,
      validation: required(
        enumType({ source: HousingConcernsChoice, pluck: 'values' }),
      ),
    },
    housingConcerns: {
      defaultValue: fieldValues.housingConcerns ?? [],
      validation: yup.array().when('housingConcernsChoice', {
        is: HousingConcernsChoice.Yes,
        then: required(
          yup.array().of(enumType({ source: HousingConcern, pluck: 'values' })),
        ),
      }),
    },
    livingSituationNotes: {
      defaultValue: fieldValues.livingSituationNotes ?? '',
      validation: yup.string().nullable(),
    },
    foodSecurityChoice: {
      defaultValue: fieldValues.foodSecurityChoice,
      validation: required(
        enumType({ source: FoodSecurityChoice, pluck: 'values' }),
      ),
    },
    foodWorryFrequency: {
      defaultValue: fieldValues.foodWorryFrequency,
      validation: yup.string().when('foodSecurityChoice', {
        is: FoodSecurityChoice.Yes,
        then: required(
          enumType({ source: FoodFrequencyChoice, pluck: 'values' }),
        ),
      }),
    },
    foodRunOutFrequency: {
      defaultValue: fieldValues.foodRunOutFrequency,
      validation: yup.string().when('foodSecurityChoice', {
        is: FoodSecurityChoice.Yes,
        then: required(
          enumType({ source: FoodFrequencyChoice, pluck: 'values' }),
        ),
      }),
    },
    foodSecurityNotes: {
      defaultValue: fieldValues.foodSecurityNotes ?? '',
      validation: yup.string().nullable(),
    },
    transportationSecurityChoice: {
      defaultValue: fieldValues.transportationSecurityChoice,
      validation: required(
        enumType({ source: TransportationSecurityChoice, pluck: 'values' }),
      ),
    },
    transportationSecurityNotes: {
      defaultValue: fieldValues.transportationSecurityNotes ?? '',
      validation: yup.string().nullable(),
    },
    utilitySecurityChoice: {
      defaultValue: fieldValues.utilitySecurityChoice,
      validation: required(
        enumType({ source: UtilitySecurityChoice, pluck: 'values' }),
      ),
    },
    utilityStatus: {
      defaultValue: fieldValues.utilityStatus,
      validation: yup.string().when('utilitySecurityChoice', {
        is: UtilitySecurityChoice.Yes,
        then: required(enumType({ source: UtilityStatus, pluck: 'values' })),
      }),
    },
    utilitySecurityNotes: {
      defaultValue: fieldValues.utilitySecurityNotes ?? '',
      validation: yup.string().nullable(),
    },
    safetyChoice: {
      defaultValue: fieldValues.safetyChoice,
      validation: required(enumType({ source: SafetyChoice, pluck: 'values' })),
    },
    safetyNotes: {
      defaultValue: fieldValues.safetyNotes ?? '',
      validation: yup.string().nullable(),
    },
    physicalHarmFrequency: {
      defaultValue: fieldValues.physicalHarmFrequency,
      validation: yup.string().when('safetyChoice', {
        is: SafetyChoice.Yes,
        then: required(
          enumType({ source: SafetyFrequencyChoice, pluck: 'values' }),
        ),
      }),
    },
    threatFrequency: {
      defaultValue: fieldValues.threatFrequency,
      validation: yup.string().when('safetyChoice', {
        is: SafetyChoice.Yes,
        then: required(
          enumType({ source: SafetyFrequencyChoice, pluck: 'values' }),
        ),
      }),
    },
    verbalAbuseFrequency: {
      defaultValue: fieldValues.verbalAbuseFrequency,
      validation: yup.string().when('safetyChoice', {
        is: SafetyChoice.Yes,
        then: required(
          enumType({ source: SafetyFrequencyChoice, pluck: 'values' }),
        ),
      }),
    },
    insultFrequency: {
      defaultValue: fieldValues.insultFrequency,
      validation: yup.string().when('safetyChoice', {
        is: SafetyChoice.Yes,
        then: required(
          enumType({ source: SafetyFrequencyChoice, pluck: 'values' }),
        ),
      }),
    },
    exerciseDaysPerWeek: {
      defaultValue: fieldValues.exerciseDaysPerWeek,
      validation: yup.number().oneOf([0, 1, 2, 3, 4, 5, 6, 7]).required(),
    },
    exerciseMinutesPerDay: {
      defaultValue: fieldValues.exerciseMinutesPerDay,
      validation: yup.number().when('exerciseDaysPerWeek', {
        is: (val: number) => val !== 0,
        then: yup
          .number()
          .oneOf([0, 10, 20, 30, 40, 50, 60, 90, 120, 150])
          .required(),
      }),
    },
  };
}

export function getDefaultGoalsAndInterventions(
  initialConditions: Maybe<InitialCarePlanConditions>,
  carePlanOptimization: boolean,
  isCCMCarePlan: boolean,
  intl: IntlShape,
): GoalsAndInterventions[] {
  const goals = Array.from(initialConditions || [])
    .map((c) => ({
      ...c,
      condition: convertToCcmCondition(c.condition),
    }))
    .filter(
      (
        c,
      ): c is {
        condition: CcmCondition;
        prognosis: CCMPrognosis;
      } => !!c.condition,
    )
    .map((c) => {
      const defaultHealthGoal = getDefaultHealthGoal(
        c.condition,
        c.prognosis,
        intl,
      );
      const defaultGoalSetting = getDefaultGoalSetting(
        c.prognosis as CCMPrognosis,
      );
      return {
        ...EMPTY_GOALS_SECTION,
        condition: c.condition,
        prognosis: c.prognosis,
        treatmentGoal: CCM_CONDITION_RESOURCES[c.condition].goal,
        ...(carePlanOptimization
          ? {
              healthGoalEnum: defaultHealthGoal?.value,
              goalSetting: defaultGoalSetting,
            }
          : {
              skipGoalSetting: !defaultGoalSetting,
            }),
      };
    });

  return isCCMCarePlan ? ensureMinimumGoals(goals, 2) : goals;
}

// Helper function to ensure a minimum number of goals by padding with empty goals
function ensureMinimumGoals(
  goals: GoalsAndInterventions[],
  min: number,
): GoalsAndInterventions[] {
  const missingCount = Math.max(0, min - goals.length);
  return concat(
    goals,
    times(missingCount, () => EMPTY_GOALS_SECTION),
  );
}
