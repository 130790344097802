import type { Dispatch, SetStateAction } from 'react';

import { useFlags } from '@/shared/hooks';
import { useUpsertPatientAutosavedNote } from '@/shared/hooks/queries/autosave-notes.queries';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import type { TimerState } from '@/shared/notes/Timer';

import { useNoteBody } from '../../NotePreview/getVisitLayoutNoteBody';
import { usePatientAlertEscalationRequiredFromEncounterInstances } from '../../NotePreview/hooks/useAlertEscalationsList';
import { type EditableNoteState, EditableNoteType } from '../../Notes.types';
import {
  useCreatePatientNote,
  useCreatePatientNoteDraft,
  usePublishDraftNote,
  usePublishNote,
  useUpdatePatientNoteDraft,
} from '../../note.queries';
import { useGetPublishDialogConfirmations } from '../dialogs/PublishConfirmationDialog/confirmation.utils';
import { useMarshaledTimeTrackingPayloadByEncounter } from '../hooks/useMarshaledTimeTrackingPayload.hook';
import type { NoteFormValues } from '../noteFormState';
import type { NoteFormValidationResult } from '../validation';
import { NoteFormSubmissionType, hasValidationErrors } from '../validation';
import { useResolveAlert } from './useResolveAlert';

export function useSubmitHandlers(
  noteFormValues: NoteFormValues,
  timer: TimerState,
  editingNote: Nullable<EditableNoteState>,
  patientId: string,
  onResetAddNote: () => void,
  openReplaceDraftNoteConfirmation: () => void,
  validate: (
    noteFormSubmissionType: NoteFormSubmissionType,
  ) => NoteFormValidationResult,
  openPublishConfirmation: () => void,
  setShouldEmrSync: (value: boolean) => void,
  hasRequiredMedActionsErrors: boolean,
  draftNote?: boolean,
) {
  const { updateAutosavedNotes } = useFlags();
  const isEditingDraftNote = editingNote?.type === EditableNoteType.Draft;
  const isEditingAlertNote = editingNote?.type === EditableNoteType.Alert;
  const publishDialogConfirmations = useGetPublishDialogConfirmations(
    noteFormValues.timeEntry,
    editingNote?.type,
    Boolean(noteFormValues.appointmentId) ||
      Boolean(noteFormValues.noShowAppointmentId),
  );
  const { mutate: createPatientNote } = useCreatePatientNote(timer);
  const { mutate: publishNote } = usePublishNote(patientId);
  const { mutate: publishDraftNote } = usePublishDraftNote(
    isEditingDraftNote,
    timer,
  );
  const { mutate: createDraftNote } = useCreatePatientNoteDraft(timer);
  const { mutate: updateDraftNote } = useUpdatePatientNoteDraft(
    isEditingDraftNote,
    timer,
  );
  const { mutate: upsertAutosavedNote } = useUpsertPatientAutosavedNote(
    patientId,
    timer,
    {},
  );
  const resolveAlert = useResolveAlert();
  const alertEscalationInfo =
    usePatientAlertEscalationRequiredFromEncounterInstances(
      patientId,
      noteFormValues.encounterModuleInstances,
    );
  const noteBody = useNoteBody(patientId);

  const notePayload = {
    patientId,
    title: noteFormValues.title,
    body: noteBody(
      noteFormValues.encounterModuleInstances,
      alertEscalationInfo,
    ),
    rtfBody: noteFormValues.body,
    bodyHtml: noteFormValues.bodyHtml ?? '',
    author: useCurrentUser().currentUserFullName,
    labels: noteFormValues.labels.map((id: number) => ({ id })),
    actionRequired: noteFormValues.actionRequired,
    urgent: noteFormValues.actionRequired ? noteFormValues.urgent : false,
    timeTracking: useMarshaledTimeTrackingPayloadByEncounter(
      noteFormValues.timeEntry,
      noteFormValues.encounterModuleInstances,
    ),
    externalProviderId: noteFormValues.actionRequired
      ? noteFormValues.externalProviderId
      : undefined,
    escalationMessage:
      noteFormValues.actionRequired && noteFormValues.escalationMessage
        ? noteFormValues.escalationMessage
        : undefined,
    encounterModuleInstances: noteFormValues.encounterModuleInstances,
    zendeskTicket: noteFormValues.zendeskTicket,
    appointmentId: noteFormValues.appointmentId,
    noShowAppointmentId: noteFormValues.noShowAppointmentId,
    endEncounter: noteFormValues.endEncounter,
  };

  return {
    saveDraftNote,
    saveDraftNoteImmediately,
    publish,
    publishImmediately,
  };

  function saveDraftNote(): void {
    if (hasValidationErrors(validate(NoteFormSubmissionType.Draft))) {
      return;
    }

    const isReplacingDraft = !isEditingDraftNote && draftNote;
    if (isReplacingDraft) {
      openReplaceDraftNoteConfirmation();
    } else {
      saveDraftNoteImmediately();
    }
  }

  function saveDraftNoteImmediately(): void {
    if (isEditingDraftNote) {
      updateDraftNote(notePayload, { onSuccess: onResetAddNote });
    } else {
      createDraftNote(notePayload, { onSuccess: onResetAddNote });
    }
  }

  function publish(
    shouldEMRSync: boolean,
    appointmentId: Maybe<string>,
    noShowAppointmentId: Maybe<string>,
    setIsSubmitting: Dispatch<SetStateAction<boolean>>,
  ) {
    setIsSubmitting?.(true);
    if (
      hasValidationErrors(validate(NoteFormSubmissionType.Publish)) ||
      hasRequiredMedActionsErrors
    ) {
      return;
    }

    if (Object.values(publishDialogConfirmations).some(Boolean)) {
      setShouldEmrSync(shouldEMRSync);
      openPublishConfirmation();
      return;
    }

    publishImmediately(shouldEMRSync, appointmentId, noShowAppointmentId);
  }

  function publishImmediately(
    shouldEMRSync: boolean,
    associatedAppointmentId?: Maybe<string>,
    associatedNoShowAppointmentId?: Maybe<string>,
  ): void {
    if (isEditingDraftNote) {
      updateDraftNote(notePayload, {
        onSuccess: () => {
          publishDraftNote(
            {
              patientId,
              shouldEMRSync,
              appointmentId: associatedAppointmentId,
              noShowAppointmentId: associatedNoShowAppointmentId,
              zendeskTicket: notePayload.zendeskTicket,
            },
            { onSuccess: onResetAddNote },
          );
        },
      });
      return;
    }

    if (isEditingAlertNote) {
      (async () => {
        await resolveAlert({ ...notePayload, shouldEMRSync });
        onResetAddNote();
      })();
      return;
    }

    if (updateAutosavedNotes) {
      upsertAutosavedNote(
        {
          ...notePayload,
          shouldEMRSync,
          appointmentId: associatedAppointmentId,
          noShowAppointmentId: associatedNoShowAppointmentId,
          zendeskTicket: notePayload.zendeskTicket,
        },
        {
          onSuccess: ({ data: savedNote }) => {
            publishNote(savedNote.id, {
              onSuccess: onResetAddNote,
            });
          },
        },
      );
    } else {
      createPatientNote(
        {
          ...notePayload,
          shouldEMRSync,
          appointmentId: associatedAppointmentId,
        },
        { onSuccess: onResetAddNote },
      );
    }
  }
}
