import { useCallback } from 'react';

import { useFlags } from '@/shared/hooks';
import type { CareModelVersion } from '@/shared/types/featureFlags.types';
import { EndEncounterType } from '@/shared/types/note.types';

import { useNoteEditorContext } from '../../NoteEditorContext';
import type { TypeOfEncounter } from '../../Notes.types';
import { EncounterModuleId } from '../../Notes.types';
import { ensureCorrectModulesArePresent } from '../../utils/encounterModuleInstanceUtils';
import { mergeModuleInstanceInputs } from '../../utils/encounterModuleUtils';
import { InitialEncounterTypeInstance } from '../../utils/encounterTypeUtils';
import type { EndFormValues } from '../noteFormState';
import { useGetFieldTemplateMap } from '../templates/hooks';
import type { TemplateContext } from '../templates/types';

function createTemplateFromType(
  newType: TypeOfEncounter | undefined,
  context: TemplateContext,
  endEncounter: EndFormValues | undefined,
  careModelVersion: CareModelVersion,
  enableAlertEscalations: boolean,
  getFieldTemplateMap: (
    encounterType: TypeOfEncounter | undefined,
    moduleId: EncounterModuleId,
    context: TemplateContext,
    endEncounter: EndFormValues | undefined,
  ) => Record<string, unknown>,
) {
  // Reset encounter module instances when changing encounter type
  const blankEncounterModuleInstances = [InitialEncounterTypeInstance];

  // Ensure that all correct modules are present before templating
  const transformedModuleInstances = ensureCorrectModulesArePresent(
    newType,
    blankEncounterModuleInstances,
    careModelVersion,
    enableAlertEscalations,
  );

  const templatedInstances = transformedModuleInstances.map((module) => {
    if (module.encounter_module_id === EncounterModuleId.EncounterType) {
      return {
        ...module,
        inputs: mergeModuleInstanceInputs(module.inputs, {
          type_of_encounter: newType,
          end_encounter_type: endEncounter?.endType ?? undefined,
          end_encounter_reason: endEncounter?.endReason ?? undefined,
          patient_no_show: endEncounter?.endType === EndEncounterType.NoShow,
          visit_layout: true,
        }),
      };
    }

    if (module.encounter_module_id === EncounterModuleId.Medications) {
      return {
        ...module,
        inputs: {},
      };
    }

    return {
      ...module,
      inputs: mergeModuleInstanceInputs(
        module.inputs,
        getFieldTemplateMap(
          newType,
          module.encounter_module_id,
          context,
          endEncounter,
        ),
      ),
    };
  });
  return templatedInstances;
}

export function useInjectTemplates() {
  const { setEncounterModuleInstances } = useNoteEditorContext();
  const getFieldTemplateMap = useGetFieldTemplateMap();
  const { careModelVersion, enableAlertEscalations } = useFlags();

  const injectTemplates = useCallback(
    (
      newType: TypeOfEncounter | undefined,
      context: TemplateContext,
      endEncounter?: EndFormValues | undefined,
    ) => {
      const templatedInstances = createTemplateFromType(
        newType,
        context,
        endEncounter,
        careModelVersion,
        enableAlertEscalations,
        getFieldTemplateMap,
      );
      setEncounterModuleInstances(templatedInstances);
    },
    [
      careModelVersion,
      getFieldTemplateMap,
      setEncounterModuleInstances,
      enableAlertEscalations,
    ],
  );

  return { injectTemplates };
}

// For cases where we just need the template values without updating state
export function useInjectTemplateValue() {
  const getFieldTemplateMap = useGetFieldTemplateMap();
  const { careModelVersion, enableAlertEscalations } = useFlags();

  const injectTemplates = useCallback(
    (
      newType: TypeOfEncounter | undefined,
      context: TemplateContext,
      endEncounter?: EndFormValues | undefined,
    ) =>
      createTemplateFromType(
        newType,
        context,
        endEncounter,
        careModelVersion,
        enableAlertEscalations,
        getFieldTemplateMap,
      ),
    [careModelVersion, getFieldTemplateMap, enableAlertEscalations],
  );

  return { injectTemplates };
}
