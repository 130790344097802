import { isValid, parseISO } from 'date-fns';

import { useReferenceMedication } from '@/pages/patients/PatientMedications/referenceMedications.queries';
import { NoteStatus } from '@/shared/generated/grpc/go/pms/pkg/note/note.pb';
import type {
  AsyncTitrationAsyncTitrationType,
  AsyncTitrationPatientConsentCollectorEnum,
  MedicationChangeFrequency,
} from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import {
  type AsyncTitration,
  AsyncTitrationAsyncTitrationStatus,
  type AsyncTitrationMedicationWasNotTitratedReason,
  type CareProviderDetails,
  type MedicationChange,
  type NextTitrationStep,
  type ReferenceMedication,
  type RxNorm,
} from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { useFlags } from '@/shared/hooks';

export type TitrationRecommendation = {
  id?: string;
  rxcui?: string;
  doseQuantities?: number[];
  frequencies?: MedicationChangeFrequency[];
  vitalsCriteria?: string;
  labsCriteria?: string;
  symptomsCriteria?: string;
  status?: AsyncTitrationAsyncTitrationStatus;
  patientId?: string;
  rxNorm?: RxNorm;
  initialRxcui?: string;
  initialDoseQuantity?: number;
  initialFrequency?: MedicationChangeFrequency;
  initialReviewNoteId?: number;
  initialReviewNoteStatus?: NoteStatus;
  consentRequestNoteId?: number;
  consentRequestNoteStatus?: NoteStatus;
  approvingCareProvider?: CareProviderDetails;
  approvingCareProviderId?: string;
  initialApprovedAt?: Date;
  rejectionReason?: AsyncTitrationMedicationWasNotTitratedReason;
  rejectionDetails?: string;
  maxToleratedDoseAchieved?: boolean;
  type?: AsyncTitrationAsyncTitrationType;
  patientConsentCollector?: AsyncTitrationPatientConsentCollectorEnum;
  providerDecisionNoteId?: number;
  providerDecisionNoteStatus?: NoteStatus;

  // since we're coercing both a titration suggestion (not backed
  // by a db record and generated on demand) and an async titration
  // (backed by a db record and generated in a cronjob), we need
  // to indicate whether or not we're working with a record that
  // can be written to so that we don't show the wrong UI to users.
  isWritable?: boolean;
};

export function useTitrationRecommendation(
  medChange: Nullable<MedicationChange>,
  referenceMedicationId: string,
): TitrationRecommendation {
  const { asyncTitrations, suggestedTitration } = useFlags();
  const { data: referenceMed } = useReferenceMedication(
    referenceMedicationId,
    true,
  );

  if (!asyncTitrations && !suggestedTitration) {
    return {};
  }

  return getTitrationRecommendation(
    medChange,
    referenceMed,
    asyncTitrations,
    suggestedTitration,
  );
}

export function getTitrationRecommendation(
  medChange: Nullable<MedicationChange>,
  referenceMed: Maybe<ReferenceMedication>,
  extractAsyncTitration: boolean,
  extractNextTitrationStep: boolean,
) {
  let recommendation: TitrationRecommendation = {};

  if (extractAsyncTitration && medChange?.asyncTitration) {
    recommendation = asyncTitrationToTitrationRecommendation(
      medChange.asyncTitration,
    );
  } else if (extractNextTitrationStep && medChange?.nextTitrationStep) {
    recommendation = nextTitrationStepToTitrationRecommendation(
      medChange.nextTitrationStep,
    );
  }

  const recommendedRxNorm = referenceMed?.rxNorms?.find(
    (rxNorm) => rxNorm.rxcui === recommendation.rxcui,
  );

  return {
    ...recommendation,
    rxNorm: recommendedRxNorm,
  };
}

function asyncTitrationToTitrationRecommendation(
  asyncTitration: AsyncTitration,
): TitrationRecommendation {
  const approvalDate = asyncTitration.initialReviewAt
    ? parseISO(asyncTitration.initialReviewAt)
    : null;
  let doseQuantities = asyncTitration.nextDoseQuantity
    ? [asyncTitration.nextDoseQuantity]
    : [];
  if (asyncTitration.editedDoseQuantities?.length) {
    doseQuantities = asyncTitration.editedDoseQuantities;
  }
  let frequencies = asyncTitration.nextFrequency
    ? [asyncTitration.nextFrequency]
    : [];
  if (asyncTitration.editedFrequencies?.length) {
    frequencies = asyncTitration.editedFrequencies;
  }
  return {
    isWritable: true,
    id: asyncTitration.id,
    rxcui: asyncTitration.editedRxcui ?? asyncTitration.nextRxcui,
    doseQuantities,
    frequencies,
    maxToleratedDoseAchieved: asyncTitration.editedMaxToleratedDoseAchieved,
    initialRxcui: asyncTitration.nextRxcui,
    initialDoseQuantity: asyncTitration.nextDoseQuantity,
    initialFrequency: asyncTitration.nextFrequency,
    vitalsCriteria: asyncTitration.nextVitalsCriteria,
    labsCriteria: asyncTitration.nextLabsCriteria,
    symptomsCriteria: asyncTitration.nextSymptomsCriteria,
    status: asyncTitration.status,
    patientId: asyncTitration.inputPatientId,
    initialReviewNoteId: asyncTitration.initialReviewNoteId,
    initialReviewNoteStatus: noteStatusUnspecifiedToUndefined(
      asyncTitration.initialReviewNoteStatus,
    ),
    consentRequestNoteId: asyncTitration.consentRequestNoteId,
    consentRequestNoteStatus: noteStatusUnspecifiedToUndefined(
      asyncTitration.consentRequestNoteStatus,
    ),
    approvingCareProvider: asyncTitration.approvingCareProvider,
    approvingCareProviderId: asyncTitration.initialReviewCareProviderId,
    ...(approvalDate &&
      isValid(approvalDate) && {
        initialApprovedAt: approvalDate,
      }),
    rejectionReason: asyncTitration.medicationWasNotTitratedReason,
    rejectionDetails: asyncTitration.medicationWasNotTitratedDetails,
    type: asyncTitration.type,
    patientConsentCollector: asyncTitration.patientConsentCollector,
    providerDecisionNoteId: asyncTitration.providerDecisionNoteId,
    providerDecisionNoteStatus: noteStatusUnspecifiedToUndefined(
      asyncTitration.providerDecisionNoteStatus,
    ),
  };
}

function nextTitrationStepToTitrationRecommendation(
  nextTitrationStep: NextTitrationStep,
): TitrationRecommendation {
  return {
    isWritable: false,
    rxcui: nextTitrationStep.nextRxcui,
    doseQuantities: nextTitrationStep.nextDoseQuantity
      ? [nextTitrationStep.nextDoseQuantity]
      : [],
    frequencies: nextTitrationStep.nextFrequency
      ? [nextTitrationStep.nextFrequency]
      : [],
    vitalsCriteria: nextTitrationStep.nextVitalsCriteria,
    labsCriteria: nextTitrationStep.nextLabsCriteria,
    symptomsCriteria: nextTitrationStep.nextSymptomsCriteria,
    patientId: nextTitrationStep.patientId,
    // always treat "next titration step" as a new titration
    // recommendation, since it is stateless but the UI inspects
    // the state in a few places.
    status: AsyncTitrationAsyncTitrationStatus.NEW,
  };
}

function noteStatusUnspecifiedToUndefined(noteStatus: NoteStatus | undefined) {
  return noteStatus === NoteStatus.STATUS_UNSPECIFIED ? undefined : noteStatus;
}
