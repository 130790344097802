import { FormattedMessage, useIntl } from 'react-intl';

import { type GoalsAndInterventions } from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';

import { useCcmConditionI18n } from '../../CarePlanForm/conditions';
import { GRPC_CONDITION_TO_FALCON_CONDITION } from '../../CarePlanForm/dataTransformation';
import {
  goalsAndInterventionsContainer,
  goalsAndInterventionsHeader,
} from './CarePlanPreview.css';
import { fieldValue } from './Layout/Layout.css';
import {
  type RelatedVital,
  formatConditionAssessment,
  formatTreatmentGoal,
} from './conditionFormatters';

type Props = {
  conditionData: GoalsAndInterventions;
  index: number;
  relatedVital: RelatedVital;
};

export function CarePlanConditionPreview({
  conditionData,
  index,
  relatedVital,
}: Props) {
  const intl = useIntl();
  const conditionI18n = useCcmConditionI18n();

  const { condition, treatmentGoal } = conditionData;

  if (!condition) {
    return null;
  }

  const ccmCondition = GRPC_CONDITION_TO_FALCON_CONDITION[condition];
  return (
    <div key={condition} className={goalsAndInterventionsContainer}>
      <p className={goalsAndInterventionsHeader}>
        <FormattedMessage
          defaultMessage="Condition {number}: {condition}"
          values={{
            number: index + 1,
            condition: conditionI18n[ccmCondition],
          }}
        />
      </p>
      <p className={fieldValue}>{formatTreatmentGoal(treatmentGoal, intl)}</p>
      <p className={fieldValue}>
        {formatConditionAssessment(conditionData, relatedVital, intl)}
      </p>
    </div>
  );
}
