import merge from 'lodash/merge';
import set from 'lodash/set';
import type { MutableRefObject } from 'react';
import { createRef, useCallback, useMemo, useState } from 'react';

type FieldRefs = {
  [fieldPath: string]: MutableRefObject<HTMLDivElement | null> | null;
};

export function useFieldPortals(fieldPaths: string[]) {
  const [fieldRefs, setFieldRefs] = useState<FieldRefs>(() =>
    Object.fromEntries(fieldPaths.map((fieldPath) => [fieldPath, null])),
  );

  const fieldPortalsUiSchema = getFieldPortalsUiSchema(fieldRefs);

  /**
   *  Ensures that the uiSchema holds refs that contain mounted
   *  elements by waiting until after the element is assigned
   *  before triggering another rerender with a recalculated ui
   *  schema that contains this ref.
   *
   *  A few notes:
   *  - We have to create a completely new ref object because rjsf
   *  implements shouldComponentUpdate (https://github.com/rjsf-team/react-jsonschema-form/blob/master/packages/core/src/components/fields/SchemaField.js#L421)]
   *  - It's okay that we copy all of the refs every time because the
   *  elements will still stay the same since React uses the key
   *  to make sure the elements have a stable identity
   */
  const updateFieldRef = useCallback(
    (fieldPath: string, el: HTMLDivElement | null) => {
      if (el) {
        const newRef: MutableRefObject<HTMLDivElement | null> = createRef();
        newRef.current = el;
        setFieldRefs((prev) => ({
          ...prev,
          [fieldPath]: newRef,
        }));
      }
    },
    [],
  );

  // Force re-creation of portal containers when fieldRefs change
  const portalContainers = useMemo(
    () =>
      Object.entries(fieldRefs).map(([fieldPath, ref]) => (
        <div
          key={fieldPath}
          ref={(el) => {
            if (el && (!ref || ref?.current !== el)) {
              updateFieldRef(fieldPath, el);
            }
          }}
        />
      )),
    [fieldRefs, updateFieldRef],
  );

  return { fieldPortalsUiSchema, portalContainers };
}

function getFieldPortalsUiSchema(fieldRefs: FieldRefs) {
  // Group fieldRefs by the fieldPath
  return merge(
    {},
    ...Object.entries(fieldRefs).map(([fieldPath, ref]) =>
      set({}, fieldPath, {
        'ui:portalRef': ref,
      }),
    ),
  );
}
