import { format } from 'date-fns';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { useGetNextScheduledAppointment } from '@/pages/patients/PatientProfile/PatientScheduling/appointments.queries';
import { Form } from '@/shared/common/Form';
import { Button } from '@/shared/tempo/atom/Button';

type Props = {
  patientId: string;
};
export function NextFollowUp({ patientId }: Props) {
  const { data } = useGetNextScheduledAppointment(patientId, true);

  const nextAppt = data?.nextAppointment;
  const nextApptDate = nextAppt?.startTime
    ? format(new Date(nextAppt.startTime), 'MM/dd/yyyy')
    : '';

  const form = useFormContext();
  const history = useHistory();

  useEffect(() => {
    if (nextApptDate) {
      form.setValue('ccmAppointment', nextApptDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextApptDate]);

  return (
    <Form.TextField
      size={11}
      label={
        <FormattedMessage defaultMessage="Date and time for next follow-up with my care manager" />
      }
      name="ccmAppointment"
      subLabel={
        <FormattedMessage
          defaultMessage="This will automatically update after you {schedulerLink}."
          values={{
            schedulerLink: (
              <Button
                variant="tertiary"
                onPress={() =>
                  history.push({ search: '?forceOpenScheduler=true' })
                }
              >
                schedule the next appointment
              </Button>
            ),
          }}
        />
      }
    />
  );
}
