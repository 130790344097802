import type { EhrMedication } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';

export function unstructuredMedInfo(
  ehrMed?: EhrMedication,
  medicationName?: string | null,
) {
  // If ehrMed exists and has valid medName and unstructuredsig, use those
  if (ehrMed && ehrMed.medName && ehrMed.unstructuredsig) {
    return `${ehrMed.medName} | ${ehrMed.unstructuredsig}`;
  }

  // If ehrMed exists but only has medName, use that
  if (ehrMed && ehrMed.medName) {
    return ehrMed.medName;
  }

  // If medicationName is provided as a fallback, use that
  if (medicationName) {
    return medicationName;
  }

  // Otherwise return null
  return null;
}
