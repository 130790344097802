import { useIntl } from 'react-intl';

import { useFlags } from '@/shared/hooks';
import type { CcmCondition } from '@/shared/types/clinicalprofile.types';
import { Condition } from '@/shared/types/clinicalprofile.types';
import { displayA1c, displayBloodPressure } from '@/shared/utils/helpers';

import { usePatientConditionData } from './usePatientConditionData.hook';

export function useFormattedConditionVitals(patientId: string) {
  const { carePlanOptimization } = useFlags();
  const {
    data: {
      a1c,
      avgDiastolic,
      avgSystolic,
      totalCholesterol,
      bmi,
      tsh,
      hr,
      spo2,
    },
  } = usePatientConditionData(patientId);
  const intl = useIntl();

  return (condition: Maybe<CcmCondition>) => {
    switch (condition) {
      case Condition.TypeTwoDiabetes:
        return {
          label: intl.formatMessage({
            defaultMessage: 'A1c',
          }),
          value: a1c ? displayA1c(a1c, '%') : undefined,
        };
      case Condition.Hypertension:
        return {
          label: intl.formatMessage({
            defaultMessage: 'Avg BP',
          }),
          value:
            avgSystolic && avgDiastolic
              ? displayBloodPressure(avgSystolic, avgDiastolic)
              : undefined,
        };
      case Condition.Hyperlipidemia:
        return {
          label: intl.formatMessage({
            defaultMessage: 'TC',
          }),
          value: totalCholesterol ? `${totalCholesterol} mg/dL` : undefined,
        };
      case Condition.Hypothyroidism:
        return {
          label: intl.formatMessage({
            defaultMessage: 'TSH',
          }),
          value: tsh ? `${tsh} mIU/L` : undefined,
        };
      case Condition.MorbidObesity:
        return {
          label: intl.formatMessage({
            defaultMessage: 'BMI',
          }),
          value: bmi || undefined,
        };
      case Condition.AfibAndFlutter:
        return {
          label: intl.formatMessage({
            defaultMessage: 'HR',
          }),
          value: hr ? `${hr} bpm` : undefined,
        };
      case Condition.COPD:
        return {
          label: intl.formatMessage({
            defaultMessage: 'SpO2',
          }),
          value: spo2 ? `${spo2}%` : undefined,
        };
      case Condition.Asthma: {
        if (carePlanOptimization) {
          return {
            label: intl.formatMessage({
              defaultMessage: 'SpO2',
            }),
            value: spo2 ? `${spo2}%` : undefined,
          };
        }
        return {
          label: undefined,
          value: undefined,
        };
      }
      case Condition.ObstructiveSleepApnea: {
        if (carePlanOptimization) {
          return {
            label: intl.formatMessage({
              defaultMessage: 'SpO2',
            }),
            value: spo2 ? `${spo2}%` : undefined,
          };
        }
        return {
          label: undefined,
          value: undefined,
        };
      }

      default:
        return {
          label: undefined,
          value: undefined,
        };
    }
  };
}
