import { compareDesc, parseISO } from 'date-fns';

import { logger } from '@/logger';
import {
  PatientProgramEnum as PatientProgram,
  PatientProgramEnum,
  type RPMOrder,
} from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';

import { ProgramType } from '../generated/grpc/cadence/models/models.pb';
import type { EligibilityCheckData } from '../generated/grpc/go/pms/pkg/patient/program/program.pb';
import type { PatientEligibilityResult } from '../generated/grpc/go/pms/pkg/patient/v2/patient.pb';
import type { EligibilityFilterType } from '../types/eligibility.types';

export function getCodesFromRPMOrder(order: RPMOrder): Nullable<string> {
  if (!order.icd10Codes) {
    return null;
  }
  try {
    return JSON.parse(order.icd10Codes).codes.join(', ');
  } catch {
    return null;
  }
}

export function getOrderCondition(
  order: RPMOrder,
): Nullable<PatientProgramEnum> {
  if (!order.icd10Codes) {
    return null;
  }

  let codes;
  try {
    codes = JSON.parse(order.icd10Codes).detailed_codes;
  } catch (e) {
    if (e instanceof SyntaxError) {
      logger.error('Failed to parse icd10Codes:', e);
    } else {
      logger.error('Unexpected error:', e as Error);
    }

    return null;
  }

  const conditionType = codes?.[0]?.type;

  if (conditionType in PatientProgram) {
    return PatientProgram[conditionType as keyof typeof PatientProgram];
  }

  return null;
}

export function sortOrders(orders: RPMOrder[]): RPMOrder[] {
  return [...orders].sort((a, b) => {
    if (!a.ehrCreatedAt) {
      return 0;
    }
    if (!b.ehrCreatedAt) {
      return 1;
    }
    return compareDesc(parseISO(a.ehrCreatedAt), parseISO(b.ehrCreatedAt));
  });
}

export function getConditionKey(program: PatientProgramEnum) {
  switch (program) {
    case PatientProgramEnum.HEART_FAILURE:
      return 'CHF';
    case PatientProgramEnum.TYPE_2_DIABETES:
      return 'T2D';
    case PatientProgramEnum.HYPERTENSION:
      return 'HTN';
    default:
      logger.error(`Unexpected program: ${program}`);
      return null;
  }
}

type ExclusionFilter = {
  filterType: EligibilityFilterType;
  program: PatientProgramEnum;
  eligibilityCheckData: EligibilityCheckData;
};

type ExclusionFiltersResult = {
  hasWarnings: boolean;
  filters: ExclusionFilter[];
};

export function getExclusionFilters(
  data: PatientEligibilityResult[],
  orderProgram: Nullable<PatientProgramEnum>,
): ExclusionFiltersResult | null {
  if (!orderProgram) {
    return null;
  }

  const latestRecord = data?.filter(
    (record) => record.programType === ProgramType.RPM,
  )?.[0];
  if (!latestRecord || !latestRecord.eligibilityResult?.conditionResults) {
    return null;
  }

  const conditionKey = getConditionKey(orderProgram);
  if (!conditionKey) {
    return null;
  }

  // Check both of these for backwards compatibility
  const conditionResult =
    latestRecord.eligibilityResult?.conditionResults[conditionKey] ||
    latestRecord.eligibilityResult?.conditionResults[orderProgram];

  if (!conditionResult) {
    return null;
  }

  const exclusionResult = conditionResult.exclusion;
  if (exclusionResult?.result?.eligible) {
    return null;
  }

  const exclusionFilters = Object.keys(exclusionResult?.filters || {})
    .filter((filter) => exclusionResult?.filters?.[filter])
    .map((filter) => ({
      filterType: filter as EligibilityFilterType,
      program: orderProgram,
      eligibilityCheckData: latestRecord.eligibilityCheckData,
    })) as ExclusionFilter[];

  return {
    hasWarnings: exclusionFilters.length > 0,
    filters: exclusionFilters,
  };
}
