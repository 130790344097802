import cx from 'classnames';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { EmptyNewMedSuggestion } from '@/components/AsyncTitration/EmptyNewMedSuggestion';
import { NewMedSuggestionRow } from '@/pages/patients/PatientMedications/NewMedSuggestionRow';
import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { ChevronLeft, ChevronRight } from '@/shared/assets/svgs';
import MedicationIcon from '@/shared/assets/svgs/medication.svg?react';
import type {
  Medication,
  PatientMedications,
} from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { MedicationChangeStatus } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { Button } from '@/shared/tempo/atom/Button';
import { IconButton } from '@/shared/tempo/atom/IconButton';

import { MedCategoryRequiredActions } from '../MedCategoryRequiredActions';
import { MedicationRow } from '../MedicationRow';
import { actionIcon } from '../MedicationsActions/styles.css';
import { ReferencedMedicationRow } from '../ReferencedMedicationRow';
import { AddMedicationModal } from '../modals/AddMedicationModal';
import { StartMedicationModal } from '../modals/StartMedicationModal';
import { SuggestNewMedicationModal } from '../modals/SuggestNewMedicationModal';
import { lastChange } from '../utils/medChangeUtils';
import { MedCategory } from '../utils/requiredActions';
import type { MedOrMedChange } from '../utils/sortMeds';
import {
  addMedContainer,
  enabledPagination,
  otherMedsContainer,
  paginationActions,
  paginationButton,
  paginationContainer,
  paginationTitle,
} from './styles.css';
import { MedPermissions } from './types';

const MEDICATIONS_PER_PAGE = 5;

type Props = {
  patientId: string;
  noteId: Maybe<number>;
  medPermissions: MedPermissions;
  showRequiredActions: boolean;
  hideOtherMeds?: boolean;
  typeOfEncounter?: TypeOfEncounter;
  diseaseSpecificMeds: Medication[];
  otherMeds: MedOrMedChange[];
  patientMeds: PatientMedications | undefined;
};

export function MedsSection({
  noteId,
  patientId,
  medPermissions,
  showRequiredActions,
  hideOtherMeds,
  typeOfEncounter,
  diseaseSpecificMeds,
  otherMeds,
  patientMeds,
}: Props) {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isStartModalOpen, setIsStartModalOpen] = useState(false);
  const totalDiseaseSpecificMeds = diseaseSpecificMeds.length;
  const totalOtherMeds = otherMeds.length;
  const maxDiseaseSpecificPage = Math.ceil(
    totalDiseaseSpecificMeds / MEDICATIONS_PER_PAGE,
  );
  const maxOtherMedPage = Math.ceil(totalOtherMeds / MEDICATIONS_PER_PAGE);

  const [diseaseSpecificPage, setDiseaseSpecificPage] = useState<number>(0);
  const [otherPage, setOtherPage] = useState<number>(0);

  const diseaseSpecificInPage = diseaseSpecificMeds.slice(
    diseaseSpecificPage * MEDICATIONS_PER_PAGE,
    (diseaseSpecificPage + 1) * MEDICATIONS_PER_PAGE,
  );

  const otherMedsInPage = otherMeds.slice(
    otherPage * MEDICATIONS_PER_PAGE,
    (otherPage + 1) * MEDICATIONS_PER_PAGE,
  );

  const existingReferencedMedIds = (patientMeds?.referencedMedications || [])
    .filter((m) => {
      const lastMedChange = lastChange(m.medChanges);
      const lastMedChangeIsWithinNote = lastMedChange?.noteId === noteId;
      const lastMedChangeIsInactive =
        lastMedChange?.status === MedicationChangeStatus.INACTIVE;

      if (lastMedChangeIsInactive) {
        return Boolean(lastMedChangeIsWithinNote);
      }
      return true;
    })
    .map((m) => m.referenceMedicationId);

  const newMedSuggestions = patientMeds?.newMedSuggestions || [];
  const emptyNewMedSuggestion = newMedSuggestions.find(
    (newMedSuggestion) => newMedSuggestion.isEmpty,
  );
  const nonEmptyNewMedSuggestions = newMedSuggestions.filter(
    (newMedSuggestion) =>
      !newMedSuggestion.isEmpty &&
      !existingReferencedMedIds.includes(
        newMedSuggestion.referenceMedicationId,
      ),
  );

  const isProposeTitration = typeOfEncounter === TypeOfEncounter.ASYNC_REVIEW;

  return (
    <>
      {emptyNewMedSuggestion &&
        typeOfEncounter === TypeOfEncounter.ASYNC_REVIEW && (
          <EmptyNewMedSuggestion
            emptyNewMedSuggestion={emptyNewMedSuggestion}
            patientId={patientId}
            existingReferencedMedIds={existingReferencedMedIds}
          />
        )}
      <PaginationContainer
        isDiseaseSpecific
        currentPage={diseaseSpecificPage}
        maxPage={maxDiseaseSpecificPage}
        totalItems={totalDiseaseSpecificMeds}
        setPage={setDiseaseSpecificPage}
      />
      {showRequiredActions && (
        <MedCategoryRequiredActions
          patientId={patientId}
          category={MedCategory.DiseaseSpecific}
        />
      )}
      {nonEmptyNewMedSuggestions.map((newMedSuggestion) => (
        <NewMedSuggestionRow
          newMedSuggestion={newMedSuggestion}
          typeOfEncounter={typeOfEncounter}
          medPermissions={medPermissions}
        />
      ))}
      {diseaseSpecificInPage.map((m, i) => (
        <ReferencedMedicationRow
          key={m.referenceMedicationId}
          med={m}
          existingReferencedMedIds={existingReferencedMedIds}
          medPermissions={medPermissions}
          withBorder={i !== diseaseSpecificInPage.length - 1}
          typeOfEncounter={typeOfEncounter}
          noteId={noteId}
        />
      ))}
      {!hideOtherMeds && (
        <div className={otherMedsContainer}>
          <PaginationContainer
            currentPage={otherPage}
            maxPage={maxOtherMedPage}
            totalItems={totalOtherMeds}
            setPage={setOtherPage}
          />
          {showRequiredActions && (
            <MedCategoryRequiredActions
              patientId={patientId}
              category={MedCategory.Other}
            />
          )}
          {otherMedsInPage.map((m, i) => (
            <div key={m.medChange.name}>
              <MedicationRow
                medChange={m.medChange}
                medication={m.med || undefined}
                medPermissions={medPermissions}
                existingReferencedMedIds={existingReferencedMedIds}
                showHistory={false}
                withBorder={i !== otherMedsInPage.length - 1}
              />
            </div>
          ))}
        </div>
      )}
      {![
        MedPermissions.View,
        MedPermissions.ProactiveTitrationConsent,
      ].includes(medPermissions) && (
        <div className={addMedContainer}>
          <Button variant="tertiary" onPress={() => setIsAddModalOpen(true)}>
            <FormattedMessage defaultMessage="Add missing medication" />
          </Button>
          {medPermissions === MedPermissions.Titrate && (
            <Button
              variant="secondary"
              onPress={() => setIsStartModalOpen(true)}
            >
              <Button.Icon>
                <MedicationIcon className={actionIcon} />
              </Button.Icon>
              {isProposeTitration ? (
                <FormattedMessage defaultMessage="Propose new medication" />
              ) : (
                <FormattedMessage defaultMessage="Start new medication" />
              )}
            </Button>
          )}
        </div>
      )}
      {isAddModalOpen && (
        <AddMedicationModal
          onClose={() => setIsAddModalOpen(false)}
          existingReferencedMedIds={existingReferencedMedIds}
        />
      )}
      {!isProposeTitration && isStartModalOpen && (
        <StartMedicationModal
          patientId={patientId}
          onClose={() => setIsStartModalOpen(false)}
          existingReferencedMedIds={existingReferencedMedIds}
        />
      )}
      {isProposeTitration && isStartModalOpen && (
        <SuggestNewMedicationModal
          patientId={patientId}
          onClose={() => setIsStartModalOpen(false)}
          existingReferencedMedIds={existingReferencedMedIds}
        />
      )}
    </>
  );
}

function PaginationContainer({
  isDiseaseSpecific,
  currentPage,
  maxPage,
  setPage,
  totalItems,
}: {
  isDiseaseSpecific?: boolean;
  currentPage: number;
  maxPage: number;
  setPage: (num: number) => void;
  totalItems: number;
}) {
  const hasItems = totalItems > 0;
  const isBackDisabled = !hasItems || currentPage === 0;
  const isNextDisabled = !hasItems || currentPage === maxPage - 1;

  return (
    <div className={paginationContainer}>
      <div className={paginationTitle}>
        {isDiseaseSpecific ? (
          <FormattedMessage defaultMessage="Disease-specific Medications" />
        ) : (
          <FormattedMessage defaultMessage="Other Medications" />
        )}
      </div>
      <div className={paginationActions}>
        <FormattedMessage
          defaultMessage="{minMed} - {maxMed} of {medCount}"
          values={{
            minMed: !hasItems ? 0 : currentPage * MEDICATIONS_PER_PAGE + 1,
            maxMed: Math.min(
              (currentPage + 1) * MEDICATIONS_PER_PAGE,
              totalItems,
            ),
            medCount: totalItems,
          }}
        />
      </div>
      <div>
        <IconButton
          variant="tertiary"
          size="small"
          isDisabled={isBackDisabled}
          onPress={() => {
            setPage(Math.max(currentPage - 1, 0));
          }}
        >
          <ChevronLeft
            className={cx(paginationButton, {
              [enabledPagination]: !isBackDisabled,
            })}
          />
        </IconButton>
        <IconButton
          variant="tertiary"
          size="small"
          isDisabled={isNextDisabled}
          onPress={() => {
            setPage(Math.min(currentPage + 1, maxPage - 1));
          }}
        >
          <ChevronRight
            className={cx(paginationButton, {
              [enabledPagination]: !isNextDisabled,
            })}
          />
        </IconButton>
      </div>
    </div>
  );
}
