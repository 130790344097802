import cx from 'classnames';
import type { ReactNode } from 'react';

import { requiredAsterisk } from '../NoteTimeTracker.css';
import { label, validationErrorLabel } from './TimeTrackerLabel.css';

export function TimeTrackerLabel({
  message,
  className,
  error = false,
  required = true,
}: {
  message: ReactNode;
  className?: string;
  error?: boolean;
  required?: boolean;
}) {
  return (
    <div className={cx(label, { [validationErrorLabel]: error }, className)}>
      {message}
      {required && <span className={requiredAsterisk}> *</span>}
    </div>
  );
}
