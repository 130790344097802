import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  MedPermissions,
  PatientMedicationsList,
} from '@/pages/patients/PatientMedications/PatientMedicationsList';
import {
  MedCategory,
  useHasRequiredMedActions,
} from '@/pages/patients/PatientMedications/utils/requiredActions';
import { Wizard } from '@/shared/common/Wizard';
import {
  useWizardFormFromConfig,
  useWizardStateContext,
} from '@/shared/common/Wizard/state';

import { FieldGroup, Script, YesNoRadioGroup } from '../../../../shared';
import { useCNFormContext } from '../../../CNFormContext';
import { TITRATION_OUTREACH_SECTIONS } from '../../metadata';
import { medReviewStepInfo } from '../../shared/MedReview/SharedMedReview.css';
import { useGoToRegActionPlan } from '../../shared/useGoToActionPlan';
import { titrationOutreachPath } from '../paths';
import { divider, warning } from './MedReview.css';
import { type MedReviewFormFields, getMedReviewFormConfig } from './formConfig';

export function MedReview() {
  const intl = useIntl();
  const history = useHistory();
  const { noteId, patientId, cnVisitType } = useCNFormContext();
  const { skipSectionStep } = useWizardStateContext();
  const form = useWizardFormFromConfig<MedReviewFormFields>(
    titrationOutreachPath('/med-review', '/index'),
    getMedReviewFormConfig(intl),
  );
  const hasRequiredMedActions = useHasRequiredMedActions(
    patientId,
    true,
    MedCategory.DiseaseSpecific,
  );
  const [goToActionPlan] = useGoToRegActionPlan();
  const patientAdheringToMeds = form.watch('patientAdheringToMeds');

  return (
    <Wizard.Step
      sections={TITRATION_OUTREACH_SECTIONS}
      form={form}
      onNext={() => {
        const titrationPath = titrationOutreachPath('/titration', '/index');

        if (patientAdheringToMeds === 'true') {
          history.push(titrationPath);
        } else {
          skipSectionStep(titrationPath);
          goToActionPlan();
        }
      }}
    >
      <FieldGroup>
        <Script>
          <FormattedMessage defaultMessage="Let's confirm the dosage and frequency of your medications. Then I'll explain the proposed medication change." />
        </Script>
        <Script>
          <FormattedMessage defaultMessage="I have a list that was provided by your doctor. As you know, it's important to take your medications consistently, and if you feel like that's something you struggle with, we can talk about it later in our appointment." />
        </Script>
        <div className={medReviewStepInfo}>
          <FormattedMessage defaultMessage="Read the name, dosage, and frequency of each medication and verify if the patient is taking the medication." />
        </div>
        <PatientMedicationsList
          showRequiredActions={hasRequiredMedActions}
          noteId={noteId}
          patientId={patientId}
          medPermissions={MedPermissions.Review}
          typeOfEncounter={cnVisitType}
          hideOtherMeds
          hideSuggestions
        />
        <div className={divider} />
        <YesNoRadioGroup
          name="patientAdheringToMeds"
          label={
            <FormattedMessage defaultMessage="Confirm no changes were made to the med module and patient is taking their medications as prescribed" />
          }
        />
        {patientAdheringToMeds === 'false' && (
          <div className={warning}>
            <FormattedMessage defaultMessage="If the patient is not taking their disease-specific medication as prescribed, we will not move forward with the titration. Please remind the patient to take their medicine as directed and continue taking their vital signs so we can adjust the medication if needed. If the patient is not taking their medication due to side effects, please send a task at the end of your call to the triage RN for further review." />
          </div>
        )}
      </FieldGroup>
    </Wizard.Step>
  );
}
