import classnames from 'classnames';
import { format, isEqual, isToday, isYesterday, parseISO } from 'date-fns';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { CNNoteBodyPreview } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNNoteBodyPreview';
import { INIT_VISIT_SECTIONS } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/initialVisit';
import type {
  EndCallFormFields,
  EndCallReason,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/EndCall/formConfig';
import type {
  AppointmentCancellationType,
  IntroFormFields,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/IntroPatientAttendance';
import { useAppointmentCancellationTypeI18n } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/CNWizardForm/sections/shared/IntroPatientAttendance/appointmentCancellationTypeI18n';
import { isCnNote } from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared/note.utils';
import AlertCircleIcon from '@/shared/assets/svgs/alertCircle.svg?react';
import HospitalIcon from '@/shared/assets/svgs/hospital.svg?react';
import ProtocolsIcon from '@/shared/assets/svgs/protocols.svg?react';
import { Tag as LabelTag } from '@/shared/common/Tag';
import {
  type SectionStepsState,
  getWizardStepValues,
} from '@/shared/common/Wizard/state';
import { flexContainer } from '@/shared/jsStyle/flex.css';
import { bodyStrongBolded } from '@/shared/jsStyle/typography.css';
import { Tag } from '@/shared/tempo/atom/Tag';
import { Tooltip } from '@/shared/tempo/atom/Tooltip';
import {
  EndEncounterType,
  type Note,
  NoteSyncStatus,
} from '@/shared/types/note.types';

import { AudioPlayer } from '../../tabs/Messages/ConversationExchange';
import type { Conference } from '../../tabs/Messages/Messages';
import {
  useEndEncounterReasonI18nMapping,
  useEndEncounterTypeI18nMapping,
} from '../NoteEditor/dialogs/EndEarlyConfirmationDialog/i18n';
import { NoteBodyPreview } from '../NotePreview';
import { NoteRepublishButton } from '../NoteRepublishButton';
import '../Notes.scss';
import { useTruncateNoteBody } from '../utils/useTruncateNoteBody';
import { TruncateButton } from './TruncateButton';
import {
  emrIcon,
  escalationMessageSection,
  noteContainer,
  noteFooter,
  noteHeader,
  noteHeaderTopRow,
  noteInfoRow,
  noteTitle,
  statusBox,
  statusIcon,
  statusRow,
} from './noteRow.css';

function FormattedDate({ date }: { date: Date }) {
  if (isToday(date)) {
    return <FormattedMessage defaultMessage="TODAY" />;
  }
  if (isYesterday(date)) {
    return <FormattedMessage defaultMessage="YESTERDAY" />;
  }
  return <>{format(date, 'MM/dd/yyyy')}</>;
}

function NoteDate({
  createdAt,
  updatedAt,
}: {
  createdAt: string;
  updatedAt: string;
}) {
  if (!createdAt) {
    return <span className="notes-content-info">-</span>;
  }
  // Add 'Z' to end of timestamp string because time is stored without timezone in backend, but date library needs to know time is in UTC
  const createdDate = parseISO(`${createdAt}Z`);
  const updatedDate = parseISO(`${updatedAt}Z`);
  const formatString = 'MM/dd/yyyy hh:mm:ss a';

  if (isEqual(createdDate, updatedDate)) {
    return (
      <Tooltip content={format(createdDate, formatString)} placement="bottom">
        <span>
          <FormattedDate date={createdDate} />
        </span>
      </Tooltip>
    );
  }
  return (
    <Tooltip content={format(updatedDate, formatString)} placement="bottom">
      <span>
        <FormattedMessage defaultMessage="EDITED:" />
        &nbsp;
        <FormattedDate date={updatedDate} />, &nbsp;
        <FormattedMessage defaultMessage="CREATED:" />
        &nbsp;
        <FormattedDate date={createdDate} />
      </span>
    </Tooltip>
  );
}

function SyncState({ syncStatus }: { syncStatus?: NoteSyncStatus }) {
  switch (syncStatus) {
    case NoteSyncStatus.Failed:
      return (
        <div className={statusBox.failed}>
          <HospitalIcon className={emrIcon.failed} />
          <FormattedMessage defaultMessage="Failed EMR sync" />
        </div>
      );
    case NoteSyncStatus.Skipped:
      return (
        <div className={statusBox.skipped}>
          <HospitalIcon className={emrIcon.skipped} />
          <FormattedMessage defaultMessage="Skipped EMR sync" />
        </div>
      );
    case NoteSyncStatus.Complete:
      return (
        <div className={statusBox.synced}>
          <HospitalIcon className={emrIcon.synced} />
          <FormattedMessage defaultMessage="Synced to EMR" />
        </div>
      );
    case NoteSyncStatus.Pending:
    case NoteSyncStatus.Syncing:
    case NoteSyncStatus.Waiting:
      return (
        <div className={statusBox.syncing}>
          <HospitalIcon className={emrIcon.syncing} />
          <FormattedMessage defaultMessage="Pending EMR sync" />
        </div>
      );
    case NoteSyncStatus.InTriage:
      return (
        <div className={statusBox.triage}>
          <HospitalIcon className={emrIcon.triage} />
          <FormattedMessage defaultMessage="Failed EMR sync, in triage" />
        </div>
      );
    default:
      return null;
  }
}

type NoteRowProps = {
  note: Note;
  enableRepublish?: boolean;
  onRepublishToEhrClick: () => void;
  innerRef?: ForwardedRef<unknown>;
  allowTruncate?: boolean;
  calls?: Conference[];
};

function Row({
  innerRef,
  note,
  onRepublishToEhrClick,
  enableRepublish = false,
  allowTruncate = true,
  calls = [],
}: NoteRowProps) {
  const endEncounterTypeI18n = useEndEncounterTypeI18nMapping();
  const endEncounterReasonI18n = useEndEncounterReasonI18nMapping();
  const apptCancellationReasonI18n = useAppointmentCancellationTypeI18n();
  const { setNoteBodyRef, isTruncated, isTruncatable, setIsTruncated } =
    useTruncateNoteBody(allowTruncate);

  const endEarlyData = getNormalizedEndEarlyData(note);

  return (
    <div
      className={noteContainer}
      ref={innerRef as ForwardedRef<HTMLDivElement>}
    >
      <div className={noteHeader}>
        <div>
          <NoteDate createdAt={note.created_at} updatedAt={note.updated_at} />
        </div>
        <div className={noteHeaderTopRow}>
          <div className={noteTitle}>
            <span>{note.title || '-'}</span>
          </div>
          {endEarlyData.endEncounterReason && (
            <Tooltip
              placement="bottom-start"
              content={
                endEncounterReasonI18n[
                  endEarlyData.endEncounterReason as EndCallReason
                ] ||
                apptCancellationReasonI18n[
                  endEarlyData.endEncounterReason as AppointmentCancellationType
                ]
              }
            >
              <Tag variant="warning">
                {
                  endEncounterTypeI18n[
                    endEarlyData.endEncounterType as EndEncounterType
                  ]
                }
              </Tag>
            </Tooltip>
          )}
        </div>
        <div>
          <div>{note.author}</div>
          <div className={statusRow}>
            {note.urgent && (
              <div className={statusBox.default}>
                <AlertCircleIcon className={statusIcon} stroke="red" />
                <FormattedMessage defaultMessage="Urgent" />
              </div>
            )}
            {note.action_required && (
              <div className={statusBox.default}>
                <ProtocolsIcon className={statusIcon} />
                <FormattedMessage defaultMessage="Action required" />
              </div>
            )}
            {note.should_emr_sync && (
              <SyncState syncStatus={note.emr_sync_status} />
            )}
            <div className={noteInfoRow}>
              <div className={flexContainer.center}>
                {enableRepublish && (
                  <NoteRepublishButton
                    onRepublishToEhrClick={onRepublishToEhrClick}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        ref={setNoteBodyRef}
        className={classnames('notes-content-body', {
          truncated: isTruncated,
        })}
      >
        {isCnNote(note) ? (
          <CNNoteBodyPreview body={note.body} />
        ) : (
          <>
            {note.escalation_message && (
              <>
                <div className={bodyStrongBolded}>
                  <FormattedMessage defaultMessage="Escalation message:" />
                </div>
                <p className={escalationMessageSection}>
                  {note.escalation_message}
                </p>
              </>
            )}
            <NoteBodyPreview
              hasInvalidMarkdown={hasInvalidMarkdown(note.created_at)}
              body={note.body}
              rtfBody={note.rtf_body}
              bodyHtml={note.body_html}
              encounterModuleInstances={note.encounter_instances}
            />
          </>
        )}
      </div>
      <div className="truncate-padding">
        {isTruncatable ? (
          <TruncateButton
            onTruncateClick={() => setIsTruncated(!isTruncated)}
            isTruncated={isTruncated}
          />
        ) : null}
      </div>
      <div>
        {calls
          .filter((call) => Boolean(call.recordingSid))
          .map((call) => (
            <AudioPlayer
              key={call.recordingSid || ''}
              recordingSid={call.recordingSid || ''}
            />
          ))}
      </div>
      <div className={noteFooter}>
        {note.labels?.map((label) => (
          <LabelTag key={label.id} backgroundColor={label.rgb_hex}>
            {label.name}
          </LabelTag>
        ))}
      </div>
    </div>
  );
}

function hasInvalidMarkdown(createdAt: string) {
  const createdDate = parseISO(`${createdAt}Z`);
  // Last fix for breaking markdown was https://github.com/cadencerpm/falcon/pull/3815
  return createdDate < new Date('2023-12-06T17:00:00-08:00');
}

export const NoteRow = forwardRef((props: NoteRowProps, ref) => (
  <Row innerRef={ref} {...props} />
));

type EndEarlyData = {
  endEncounterReason: Maybe<EndCallReason | AppointmentCancellationType>;
  endEncounterType: Maybe<EndEncounterType>;
};

function getNormalizedEndEarlyData(note: Note): EndEarlyData {
  if (!isCnNote(note)) {
    return {
      endEncounterReason: note.end_encounter_reason as EndCallReason,
      endEncounterType: note.end_encounter_type,
    };
  }

  const cnNoteData =
    note.clinical_navigator_note?.[0].form?.data || ({} as SectionStepsState);

  const endCallData = getWizardStepValues(
    cnNoteData,
    INIT_VISIT_SECTIONS,
    '/end-call',
    '/index',
  )<EndCallFormFields>();

  if (endCallData?.endCallReason) {
    return {
      endEncounterReason: endCallData?.endCallReason,
      endEncounterType: EndEncounterType.EndEarly,
    };
  }

  const patientAttendanceData = getWizardStepValues(
    cnNoteData,
    INIT_VISIT_SECTIONS,
    '/intro',
    '/patient-attendance',
  )<IntroFormFields>();

  if (patientAttendanceData?.patientAttendance === 'false') {
    return {
      endEncounterReason: patientAttendanceData?.cancellationReason,
      endEncounterType: EndEncounterType.NoShow,
    };
  }

  return {
    endEncounterReason: null,
    endEncounterType: null,
  };
}
