import type { ReactElement } from 'react';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { Tag } from '@/shared/tempo/atom/Tag';

import { GoalMeasure } from '../../../Goals/goals.types';
import { getAssessmentFieldNames } from '../../formConfig';
import { GapWidget } from './GapWidget';
import * as styles from './shared.css';
import type { PsychosocialAssessmentComponentProps } from './shared.types';
import { UtilitySecurityChoice, UtilityStatus } from './utilities.types';

export function Utilities({
  form,
  createGoalWithMeasure,
  hasGap,
  onEditGoal,
  carePlanId,
}: PsychosocialAssessmentComponentProps): ReactElement {
  const intl = useIntl();

  const utilitySecurityChoice = form.watch('utilitySecurityChoice');
  const showNotes = utilitySecurityChoice === UtilitySecurityChoice.Yes;

  const { description: descriptionFieldName, checked: checkedFieldName } =
    getAssessmentFieldNames('utilities');

  const hasGapVal = hasGap(form.getValues());
  useEffect(() => {
    form.setValue(checkedFieldName, hasGapVal, { shouldValidate: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasGapVal]);

  return (
    <>
      <div className={styles.leftColumn}>
        <Form.RadioGroup
          name="utilitySecurityChoice"
          label={
            <>
              <Tag variant="default" className={styles.sectionTag}>
                <FormattedMessage defaultMessage="Utilities" />
              </Tag>{' '}
              <FormattedMessage defaultMessage="In the past 12 months has the electric, gas, or water company threatened to shut off services in your home?" />
            </>
          }
          orientation="horizontal"
          size={11}
          required
          onChange={(value) => {
            if (value === UtilitySecurityChoice.No) {
              form.setValue('utilityStatus', undefined, {
                shouldValidate: true,
              });
            }
          }}
        >
          <Form.Radio value={UtilitySecurityChoice.No}>
            <FormattedMessage defaultMessage="No" />
          </Form.Radio>
          <Form.Radio value={UtilitySecurityChoice.Yes}>
            <FormattedMessage defaultMessage="Yes" />
          </Form.Radio>
        </Form.RadioGroup>

        {showNotes && (
          <Form.RadioGroup
            name="utilityStatus"
            label={<FormattedMessage defaultMessage="Utilities were:" />}
            orientation="horizontal"
            size={11}
            required
          >
            <Form.Radio value={UtilityStatus.Threatened}>
              <FormattedMessage defaultMessage="Threatened to shut off" />
            </Form.Radio>
            <Form.Radio value={UtilityStatus.AlreadyShutOff}>
              <FormattedMessage defaultMessage="Already shut off" />
            </Form.Radio>
          </Form.RadioGroup>
        )}

        {hasGapVal && (
          <div className={styles.gapMessage}>
            <FormattedMessage defaultMessage="Patient has a utilities need according to CMS guidelines" />
          </div>
        )}
      </div>

      <div className={styles.rightColumn}>
        <GapWidget
          fieldName={descriptionFieldName}
          placeholder={intl.formatMessage({
            defaultMessage:
              'Enter details about issues with utilities. This is required if a gap is detected',
          })}
          showGoalButton={hasGapVal}
          onCreateGoal={createGoalWithMeasure}
          measure={GoalMeasure.Utilities}
          form={form}
          hasGap={hasGapVal}
          onEditGoal={onEditGoal}
          carePlanId={carePlanId}
          showWidget={showNotes}
        />
      </div>
    </>
  );
}
