import cx from 'classnames';
import { useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import type { BaseFieldProps } from '@/shared/common/Form/fields/BaseField';
import { useFlags } from '@/shared/hooks';

import { GoalStatus } from './goals.types';
import { useGoalStatusI18n } from './i18nMapping';

type Props = {
  className?: string;
  size?: BaseFieldProps['size'];
};

export function StatusSelect({ className, size }: Props) {
  const intl = useIntl();
  const goalStatusI18n = useGoalStatusI18n();
  const { carePlanOptimization } = useFlags();

  const statusOptions = [
    <Form.Select.Option key={GoalStatus.OPEN}>
      {goalStatusI18n[GoalStatus.OPEN]}
    </Form.Select.Option>,
    ...(carePlanOptimization
      ? [
          <Form.Select.Option key={GoalStatus.DECLINED}>
            {goalStatusI18n[GoalStatus.DECLINED]}
          </Form.Select.Option>,
        ]
      : []),
    <Form.Select.Option key={GoalStatus.IN_PROGRESS}>
      {goalStatusI18n[GoalStatus.IN_PROGRESS]}
    </Form.Select.Option>,
    <Form.Select.Option key={GoalStatus.COMPLETE}>
      {goalStatusI18n[GoalStatus.COMPLETE]}
    </Form.Select.Option>,
    <Form.Select.Option key={GoalStatus.ABANDONED}>
      {goalStatusI18n[GoalStatus.ABANDONED]}
    </Form.Select.Option>,
  ];

  return (
    <Form.Select
      name="status"
      required
      size={size}
      label={intl.formatMessage({ defaultMessage: 'Goal status' })}
      className={cx(className)}
    >
      {statusOptions}
    </Form.Select>
  );
}
