import { useEffect } from 'react';
import type { FieldValues, FormState } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { useFormFromConfig } from '@/shared/common/Form/FormContainer';
import {
  type CCMCarePlan,
  State,
} from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import { useFlags } from '@/shared/hooks';
import { useCarePlanNoteInfo } from '@/shared/hooks/queries/carePlan.queries';
import { useCarePlanV1Changes } from '@/shared/hooks/useCarePlanV1Changes';
import { grpcNameToId } from '@/shared/utils/grpc';

import { TypeOfEncounter } from '../../../Notes/Notes.types';
import { marshalCarePlanToFormData } from './dataTransformation';
import { getFormConfig } from './formConfig';

export function useValidateCarePlan(ccmCarePlan: Maybe<CCMCarePlan>): {
  isValid: boolean;
  errors: FormState<FieldValues>['errors'];
} {
  const { carePlanOptimization } = useFlags();
  const intl = useIntl();
  const carePlanData = ccmCarePlan?.carePlan;
  const hasCarePlanData = !!carePlanData;
  const shouldShowCarePlanV1Changes = useCarePlanV1Changes(carePlanData);

  const carePlanId = ccmCarePlan?.name
    ? grpcNameToId(ccmCarePlan.name)
    : undefined;

  const { data: noteInfo } = useCarePlanNoteInfo(carePlanId || '', {
    enabled: Boolean(carePlanId),
  });
  const isCCMEncounter =
    noteInfo?.noteEncounterType === TypeOfEncounter.CCM_CARE_PLAN;

  const form = useFormFromConfig({
    ...getFormConfig(
      intl,
      carePlanData
        ? marshalCarePlanToFormData(
            carePlanData,
            shouldShowCarePlanV1Changes,
            intl,
          )
        : {},
      shouldShowCarePlanV1Changes,
      isCCMEncounter,
      carePlanOptimization,
    ),
    triggerReset: hasCarePlanData,
  });

  // Set the form values to ensure it is valid or not
  useEffect(() => {
    if (carePlanData) {
      Object.entries(
        marshalCarePlanToFormData(
          carePlanData,
          shouldShowCarePlanV1Changes,
          intl,
        ),
      ).forEach(([k, v]) => {
        form.setValue(k, v);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ccmCarePlan?.name, shouldShowCarePlanV1Changes]);

  if (
    !!ccmCarePlan &&
    [
      State.APPROVED,
      State.ARCHIVED_APPROVED,
      // Include states where care plan was already published so that
      // we do not block manual approval by invalidating the form.
      State.UNASSIGNED_APPROVAL,
      State.CLINICALDOC_NOT_FOUND,
    ].includes(ccmCarePlan.state as State)
  ) {
    return { isValid: true, errors: {} };
  }
  const { formState } = form;
  return { isValid: formState.isValid, errors: formState.errors };
}
