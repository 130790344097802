import get from 'lodash/get';
import type { FieldError, FieldErrorsImpl } from 'react-hook-form';

// Supports array fields in react hook form, where the field name is in the format: "<fieldName>[<index>]" e.g. "doseQuantities[0]"
export function getArrayField(fieldName: string) {
  // eslint-disable-next-line no-useless-escape
  const regex = /^([^\[\]]+)\[(\d+)\]$/;
  const match = fieldName.match(regex);

  if (match) {
    return {
      fieldName: match[1],
      index: Number(match[2]),
    };
  }

  return undefined;
}

// Gets the error object from a nested field name, supporting dot notation
// Example: getFieldError(errors, "goalsAndInterventions.1.prognosis")
export function getFieldError(errors: FieldErrorsImpl, fieldName: string) {
  return fieldName
    .split('.')
    .reduce<FieldErrorsImpl | FieldError>((current, part) => {
      if (current === undefined || current === null) {
        return undefined;
      }

      return get(current, part);
    }, errors);
}
