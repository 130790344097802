import lowerFirst from 'lodash/lowerFirst';
import upperFirst from 'lodash/upperFirst';
import type { IntlShape } from 'react-intl';

import {
  CCMPrognosis,
  type GoalsAndInterventions,
} from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';

import { ccmPrognosisI18n } from '../../CarePlanForm/prognosis';
import { reviewText } from '../../CarePlanForm/sections/GoalsAndInterventions/conditionGoalUtils';

export type RelatedVital = {
  label: Maybe<string>;
  value: Maybe<string | number>;
};

export function formatConditionAssessment(
  conditionData: GoalsAndInterventions,
  relatedVital: Maybe<RelatedVital>,
  intl: IntlShape,
) {
  const prognosisText = getPrognosisText(conditionData, relatedVital, intl);
  const planText = getPlanText(conditionData, intl);

  if (!planText) {
    return prognosisText;
  }

  return combineSentences(prognosisText, planText);
}

function getPlanText(conditionData: GoalsAndInterventions, intl: IntlShape) {
  const { prognosis, healthGoal, actionSteps, goalSetting } = conditionData;

  // If goal setting hasn't been discussed yet
  if (!goalSetting) {
    switch (prognosis) {
      case CCMPrognosis.FAIR:
        // If patient declined goal setting
        if (goalSetting === false) {
          return intl.formatMessage({
            defaultMessage:
              'The patient decided to not pursue a goal related to this condition at this time.',
          });
        }
        return reviewText(intl);
      case CCMPrognosis.GOOD:
        return intl.formatMessage({
          defaultMessage: 'No action being taken.',
        });
      case CCMPrognosis.CCM_PROGNOSIS_UNSPECIFIED:
      default:
        return intl.formatMessage({
          defaultMessage:
            'Will be reviewing with the patient at our next check-in to determine severity and potential follow-up goals.',
        });
    }
  }

  if (prognosis === CCMPrognosis.GOOD) {
    return intl.formatMessage(
      {
        defaultMessage:
          'The patient elected to have us monitor their symptoms to {healthGoal} and make sure this condition stays well controlled. {actionSteps}',
      },
      {
        healthGoal: lowerFirst(healthGoal),
        actionSteps: upperFirst(actionSteps),
      },
    );
  }

  if (prognosis === CCMPrognosis.FAIR) {
    return intl.formatMessage(
      {
        defaultMessage:
          'The patient and care team agreed on goal with patient to {healthGoal}. {actionSteps}',
      },
      {
        healthGoal: lowerFirst(healthGoal),
        actionSteps: upperFirst(actionSteps),
      },
    );
  }

  return null;
}

function getPrognosisText(
  conditionData: GoalsAndInterventions,
  relatedVital: Maybe<RelatedVital>,
  intl: IntlShape,
) {
  const { prognosis, prognosisNotes } = conditionData;
  let result = prognosis
    ? ccmPrognosisI18n(intl, true)[prognosis]
    : intl.formatMessage({ defaultMessage: 'Unknown condition severity' });

  if (relatedVital?.label && relatedVital?.value) {
    result = intl.formatMessage(
      { defaultMessage: '{result}, recent {label} of {value}' },
      { result, label: relatedVital.label, value: relatedVital.value },
    );
  }

  result = intl.formatMessage(
    { defaultMessage: 'Assessment: {result}' },
    { result },
  );

  if (prognosisNotes) {
    const formattedNotes = prognosisNotes.replace(/\.$/, ''); // Remove trailing period if exists
    result = intl.formatMessage(
      { defaultMessage: '{result}. {notes}' },
      {
        result,
        notes: upperFirst(formattedNotes),
      },
    );
  }

  return result;
}

export function formatTreatmentGoal(
  treatmentGoal: Maybe<string>,
  intl: IntlShape,
) {
  if (!treatmentGoal) {
    return null;
  }

  return intl.formatMessage(
    { defaultMessage: 'Treatment Goal: {treatmentGoal}' },
    { treatmentGoal },
  );
}

function combineSentences(sentence1: string, sentence2: string): string {
  // Remove any trailing periods and spaces from the first sentence
  const trimmedSentence1 = sentence1.replace(/[.\s]+$/, '');
  return `${trimmedSentence1}. ${sentence2}`;
}
