import type { KeyboardEventHandler, MouseEvent, ReactNode } from 'react';
import { forwardRef, useState } from 'react';

import { Dialog } from '@/deprecated/mui';
import { useEscHotkey } from '@/shared/hooks';

import { ModalContextProvider } from './ModalContext';
import { ModalTransition, TRANSITION_DURATION } from './ModalTransition';
import { MODAL_OPEN_ATTR } from './constants';
import { modal } from './styles.css';

type Props = {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  size?: 'small' | 'large' | 'xlarge';
  onClick?: (e: MouseEvent) => void;
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
};

export const ModalContainer = forwardRef<HTMLDivElement, Props>(
  (
    {
      open,
      disabled = false,
      isLoading = false,
      onClose,
      children,
      size = 'small',
      onClick,
      onKeyDown,
    },
    ref,
  ) => {
    const [isBodyScrollVisible, setIsBodyScrollVisible] = useState(false);

    // Escape key hotkey for closing modal
    useEscHotkey(
      () => {
        onClose();
        return true;
      },
      [onClose],
      {
        enabled: open && !disabled,
        enableOnFormTags: true,
        enableOnContentEditable: true,
      },
    );

    return (
      <ModalContextProvider
        value={{
          onClose,
          disabled,
          isLoading,
          isBodyScrollVisible,
          setIsBodyScrollVisible,
        }}
      >
        {/* Transition can improperly restore page body scroll unless modal is
            completely unmounted on close */}
        {open && (
          <Dialog
            {...{ [MODAL_OPEN_ATTR]: open }}
            ref={ref}
            open
            fullWidth
            disableEscapeKeyDown
            onClick={onClick}
            onKeyDown={onKeyDown}
            transitionDuration={TRANSITION_DURATION}
            TransitionComponent={ModalTransition}
            PaperProps={{ elevation: 0 }}
            sx={{
              '.MuiPaper-root': {
                // Elevation/200
                filter: 'drop-shadow(0px 8px 20px rgba(43, 61, 78, 0.16))',
              },
            }}
            classes={{
              paper: modal[size],
            }}
          >
            {children}
          </Dialog>
        )}
      </ModalContextProvider>
    );
  },
);
