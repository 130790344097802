import { format, parseISO } from 'date-fns';
import type { IntlShape } from 'react-intl';
import { FormattedMessage, useIntl } from 'react-intl';

import CalendarIcon from '@/shared/assets/svgs/calendar.svg?react';
import { Gender } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import type { NextAppointmentRecommendation } from '@/shared/generated/grpc/go/pms/pkg/scheduling/scheduling.pb';
import { useFlags } from '@/shared/hooks';
import { usePatientDetails } from '@/shared/hooks/queries';
import { type Patient } from '@/shared/types/patient.types';
import { CADENCE_TIMEZONES } from '@/shared/utils/time-helpers';

import { LoadingPlaceholder } from '../LoadingPlaceholder';
import {
  bodyStrong,
  schedulingIcon,
  suggestionContainer,
  xxsRightPadding,
} from './SchedulingInfo.css';
import { SchedulingInfo as SchedulingInfoV2 } from './SmartScheduler/SchedulingInfo';
import {
  isCarePlanVisit,
  isRtePatientWithRecommendedStartDate,
  shouldBeScheduledAsap,
} from './appointment.utils';

type Props = {
  patientId: string;
  recommendedAppt: Maybe<NextAppointmentRecommendation>;
};

export function SchedulingInfo({ patientId, recommendedAppt }: Props) {
  const { smartSchedulingInitialVisit } = useFlags();
  const { data: patient, isLoading } = usePatientDetails(patientId, false);
  // TODO: Once flag is rolled out, we can remove this file and only use the new SchedulingInfo component
  if (smartSchedulingInitialVisit) {
    return (
      <LoadingPlaceholder isLoading={isLoading}>
        {patient && (
          <SchedulingInfoV2
            patient={patient}
            recommendedAppt={recommendedAppt}
          />
        )}
      </LoadingPlaceholder>
    );
  }

  const recommendationExists = !!recommendedAppt?.appointmentTypeName;
  return (
    <div className={suggestionContainer}>
      <div>
        <CalendarIcon className={schedulingIcon.primary} />
      </div>
      <LoadingPlaceholder isLoading={isLoading}>
        <span>
          {recommendationExists && (
            <RecommendedNextVisit
              patient={patient}
              recommendedAppt={recommendedAppt}
            />
          )}
          <PatientTimeZone
            patient={patient}
            hasRecommendedAppt={recommendationExists}
          />
        </span>
      </LoadingPlaceholder>
    </div>
  );
}

function RecommendedNextVisit({
  patient,
  recommendedAppt,
}: {
  patient: Maybe<Patient>;
  recommendedAppt: NextAppointmentRecommendation;
}) {
  return (
    <span className={xxsRightPadding}>
      <RecommendedNextVisitMessage
        patient={patient}
        recommendedAppt={recommendedAppt}
      />
    </span>
  );
}

function RecommendedNextVisitMessage({
  patient,
  recommendedAppt,
}: {
  patient: Maybe<Patient>;
  recommendedAppt: NextAppointmentRecommendation;
}) {
  const intl = useIntl();
  if (!patient || !recommendedAppt.appointmentTypeName) {
    return null;
  }

  const patientName = getPatientName(patient, intl);

  if (
    isRtePatientWithRecommendedStartDate(patient, recommendedAppt) &&
    recommendedAppt.careProviderAcuityCalendarId
  ) {
    return (
      <FormattedMessage
        defaultMessage="{patientName} should be scheduled for <strong>{appointmentTypeName}</strong> with <strong>{appointmentCareProvider}</strong> around <strong>{date}</strong>."
        values={{
          patientName,
          date: getFormattedDate(recommendedAppt.startDate),
          strong,
          appointmentCareProvider: `${recommendedAppt.careProviderFirstName} ${recommendedAppt.careProviderLastName}`,
          appointmentTypeName: recommendedAppt.appointmentTypeName,
        }}
      />
    );
  }

  const isRelevantForImmediateScheduling = shouldBeScheduledAsap(
    patient,
    recommendedAppt,
  );

  if (isRelevantForImmediateScheduling) {
    if (isCarePlanVisit(recommendedAppt.appointmentTypeName)) {
      return (
        <FormattedMessage
          defaultMessage="{patientName} should be scheduled for <strong>{appointmentTypeName} ASAP</strong>."
          values={{
            patientName,
            strong,
            appointmentTypeName: recommendedAppt.appointmentTypeName,
          }}
        />
      );
    }
    return (
      <FormattedMessage
        defaultMessage="{patientName} should be scheduled for <strong>{appointmentTypeName} at the next available appointment time</strong>."
        values={{
          patientName,
          strong,
          appointmentTypeName: recommendedAppt.appointmentTypeName,
        }}
      />
    );
  }
  if (recommendedAppt.startDate) {
    return (
      <FormattedMessage
        defaultMessage="{patientName} should be scheduled for <strong>{appointmentTypeName}</strong> around the week of <strong>{date}</strong>."
        values={{
          patientName,
          date: getFormattedDate(recommendedAppt.startDate),
          strong,
          appointmentTypeName: recommendedAppt.appointmentTypeName,
        }}
      />
    );
  }
  return (
    <FormattedMessage
      defaultMessage="{patientName} should be scheduled for <strong>{appointmentTypeName}</strong>."
      values={{
        patientName,
        strong,
        appointmentTypeName: recommendedAppt.appointmentTypeName,
      }}
    />
  );
}

function PatientTimeZone({
  patient,
  hasRecommendedAppt,
}: {
  patient: Maybe<Patient>;
  hasRecommendedAppt: boolean;
}) {
  const intl = useIntl();

  const tz = CADENCE_TIMEZONES.find(
    ({ timezone }) => timezone === patient?.timezone,
  );

  const patientName =
    [patient?.first_name, patient?.last_name].filter(Boolean).join(' ') ||
    intl.formatMessage({ defaultMessage: 'Patient' });

  let possessiveAdjective: string;
  const gender = patient?.gender;
  switch (gender) {
    case Gender.MALE:
      possessiveAdjective = intl.formatMessage({ defaultMessage: 'His' });
      break;
    case Gender.FEMALE:
      possessiveAdjective = intl.formatMessage({ defaultMessage: 'Her' });
      break;
    default:
      possessiveAdjective = intl.formatMessage({ defaultMessage: 'Their' });
  }

  if (!tz) {
    return (
      <FormattedMessage
        // Note: Even when the patient's name ends with an 's', the grammatically correct
        // form is still in the form "John Russ's local timezone"
        defaultMessage="{patientName}'s local timezone is unknown."
        values={{ patientName }}
      />
    );
  }

  return (
    <span>
      {!hasRecommendedAppt ? (
        <FormattedMessage
          // Note: Even when the patient's name ends with an 's', the grammatically correct
          // form is still in the form "John Russ's local timezone"
          defaultMessage="{patientName}'s local timezone is ({offset}) {timezone}."
          values={{
            timezone: tz.name,
            offset: tz.display,
            patientName,
          }}
        />
      ) : (
        <FormattedMessage
          defaultMessage="{possessiveAdjective} local timezone is ({offset}) {timezone}."
          values={{
            timezone: tz.name,
            offset: tz.display,
            possessiveAdjective,
          }}
        />
      )}
    </span>
  );
}

function getPatientName(patient: Patient, intl: IntlShape) {
  return (
    [patient.first_name, patient.last_name].filter(Boolean).join(' ') ||
    intl.formatMessage({ defaultMessage: 'Patient' })
  );
}

function getFormattedDate(suggestedTime: string) {
  return format(parseISO(suggestedTime), 'MMM d, yyyy');
}

function strong(children: string) {
  return <span className={bodyStrong}>{children}</span>;
}
