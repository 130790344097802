import { CCMPrognosis } from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import { Condition } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { convertToMmHg } from '@/shared/utils/unit-helpers';

import type { usePatientConditionData } from './hooks/usePatientConditionData.hook';

// Same order as CareMgmtConditionPriorityMapping in BE
const OrderedConditions: Condition[] = [
  Condition.HEART_FAILURE,
  Condition.TYPE_2_DIABETES,
  Condition.COPD,
  Condition.HYPERTENSION,
  Condition.ISCHEMIC_HEART_DISEASE,
  Condition.CHRONIC_KIDNEY_DISEASE,
  Condition.AFIB_AND_AFL,
  Condition.PERIPHERAL_ARTERY_DISEASE,
  Condition.OBSTRUCTIVE_SLEEP_APNEA,
  Condition.MORBID_OBESITY,
  Condition.ASTHMA,
  Condition.OSTEOARTHRITIS,
  Condition.HYPERLIPIDEMIA,
  Condition.HYPOTHYROIDISM,
];

export type PatientConditionData = ReturnType<
  typeof usePatientConditionData
>['data'];

export function getConditionPrognosis(
  condition: Condition,
  {
    a1c,
    avgDiastolic,
    avgSystolic,
    totalCholesterol,
    bmi,
    tsh,
    hr,
    spo2,
  }: PatientConditionData,
  carePlanOptimization: boolean,
  defaultPrognosis = CCMPrognosis.CCM_PROGNOSIS_UNSPECIFIED,
) {
  switch (condition) {
    case Condition.TYPE_2_DIABETES:
      return a1c && a1c < 7 ? CCMPrognosis.GOOD : CCMPrognosis.FAIR;
    case Condition.HYPERTENSION:
      return avgSystolic &&
        convertToMmHg(avgSystolic) < 130 &&
        avgDiastolic &&
        convertToMmHg(avgDiastolic) < 80
        ? CCMPrognosis.GOOD
        : CCMPrognosis.FAIR;
    case Condition.HYPERLIPIDEMIA:
      return totalCholesterol && totalCholesterol < 200
        ? CCMPrognosis.GOOD
        : CCMPrognosis.FAIR;
    case Condition.MORBID_OBESITY:
      return bmi && bmi < 25 ? CCMPrognosis.GOOD : CCMPrognosis.FAIR;
    case Condition.HYPOTHYROIDISM:
      return tsh && tsh < 5 && tsh > 0.5
        ? CCMPrognosis.GOOD
        : CCMPrognosis.FAIR;
    case Condition.AFIB_AND_AFL:
      return hr && hr > 60 && hr < 100 ? CCMPrognosis.GOOD : CCMPrognosis.FAIR;
    case Condition.COPD:
      return spo2 && spo2 > 88 ? CCMPrognosis.GOOD : CCMPrognosis.FAIR;
    case Condition.ASTHMA:
      if (carePlanOptimization) {
        return spo2 && spo2 > 92 ? CCMPrognosis.GOOD : CCMPrognosis.FAIR;
      }
      return defaultPrognosis;
    case Condition.OBSTRUCTIVE_SLEEP_APNEA:
      if (carePlanOptimization) {
        return spo2 && spo2 > 92 ? CCMPrognosis.GOOD : CCMPrognosis.FAIR;
      }
      return defaultPrognosis;
    default:
      return defaultPrognosis;
  }
}

export function sortConditions(
  conditions: {
    condition: Condition;
    prognosis: CCMPrognosis;
  }[],
) {
  return conditions.sort((a, b) => {
    // Well-controlled conditions last
    if (
      a.prognosis === CCMPrognosis.GOOD &&
      b.prognosis !== CCMPrognosis.GOOD
    ) {
      return 1;
    }
    if (
      b.prognosis === CCMPrognosis.GOOD &&
      a.prognosis !== CCMPrognosis.GOOD
    ) {
      return -1;
    }

    // Then sort by order of conditions
    return rankingSort(a.condition, b.condition);
  });
}

export function rankingSort(a: Condition, b: Condition) {
  return OrderedConditions.indexOf(a) - OrderedConditions.indexOf(b);
}

export function getDefaultGoalSetting(prognosis: CCMPrognosis) {
  return prognosis === CCMPrognosis.FAIR;
}
