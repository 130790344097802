import invert from 'lodash/invert';
import type { IntlShape } from 'react-intl';

import { getCcmConditionI18n } from '@/pages/adminPanel/patient-profile/tabs/ClinicalProfileTab/clinicalprofile.utils';
import type { PreventativeCareStatus } from '@/shared/generated/grpc/go/pms/pkg/care_plan/care_plan.pb';
import {
  FrequencyChoice,
  HousingConcern as GrpcHousingConcern,
  HousingStabilityDetail as GrpcHousingStabilityDetail,
  SafetyFrequencyChoice as GrpcSafetyFrequencyChoice,
  UtilityStatus as GrpcUtilityStatus,
  type PsychosocialAssessmentData,
} from '@/shared/generated/grpc/go/pms/pkg/care_plan/care_plan.pb';
import {
  type CarePlan,
  type CarePlanGoal,
  GoalsAndInterventionsActionStep,
  GoalsAndInterventionsHealthGoal,
  CCMCondition as GrpcCondition,
  type GoalsAndInterventions as GrpcGoalsAndInterventions,
} from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import type { PatientDemographics } from '@/shared/hooks/usePatientDemographics';
import type { CcmCondition } from '@/shared/types/clinicalprofile.types';
import { Condition } from '@/shared/types/clinicalprofile.types';
import { dateToGrpcDate, parseGrpcDate } from '@/shared/utils/grpc';

import { CCM_CONDITION_RESOURCES } from './conditions';
import type { FormFields, GoalsAndInterventions } from './formConfig';
import {
  getAssessmentFieldNames,
  getPreventativeCareFieldNames,
} from './formConfig';
import { isConditionReviewGoal } from './sections/GoalsAndInterventions/conditionGoalUtils';
import {
  getPatientGoalOptions,
  isOtherGoal,
} from './sections/GoalsAndInterventions/goalUtils';
import {
  HousingConcern,
  HousingConcernsChoice,
  HousingStabilityChoice,
  HousingStabilityDetail,
} from './sections/PsychosocialAssessment/livingSituation.types';
import type {
  DaysPerWeek,
  MinutesPerDay,
} from './sections/PsychosocialAssessment/physicalActivity.types';
import { PSYCHOSOCIAL_ASSESSMENT_OPTIONS } from './sections/PsychosocialAssessment/psychoSocialAssessmentOptions';
import {
  SafetyChoice,
  SafetyFrequencyChoice,
} from './sections/PsychosocialAssessment/safety.types';
import { TransportationSecurityChoice } from './sections/PsychosocialAssessment/transportationSecurity.types';
import {
  UtilitySecurityChoice,
  UtilityStatus,
} from './sections/PsychosocialAssessment/utilities.types';
import { PREVENTATIVE_CARE_OPTIONS } from './sections/preventativeCareOptions';
import {
  FoodFrequencyChoice,
  FoodSecurityChoice,
} from './sections/reliableSourceOfFood.types';

export function marshalFormDataToCarePlan(
  data: FormFields,
  intl: IntlShape,
  shouldShowCarePlanV1Changes: boolean,
  patientDemographics: PatientDemographics,
  carePlanOptimization: boolean,
): CarePlan {
  return {
    diet: {
      fastFood: data.fastFood,
      snacks: data.snacks,
      desserts: data.desserts,
      regularSoda: data.regularSoda,
      sweetTea: data.sweetTea,
      waterIntake: data.waterIntake,
      saltIntake: data.saltIntake,
    },
    ...(shouldShowCarePlanV1Changes
      ? { dietNotes: data.dietNotes }
      : { exercise: data.exercise }),
    socialHistory: {
      tobaccoUse: data.tobaccoUse,
      tobaccoUserStatus: data.tobaccoUserStatus,
      tobaccoTypes: data.tobaccoTypes,
      smokingYears: data.smokingYears,
      smokingQuantity: data.smokingQuantity,
    },
    emergencyVisits: data.emergencyVisits?.map(({ date, ...rest }) => ({
      ...rest,
      ...(date && {
        date: dateToGrpcDate(date),
      }),
    })),
    preventativeCare: PREVENTATIVE_CARE_OPTIONS.map(
      ({ name, notApplicable: notApplicableFn }) => {
        const { checked, date, nextSteps, status, notApplicable } =
          getPreventativeCareFieldNames(name);

        const isNotApplicable = Boolean(
          patientDemographics && notApplicableFn?.(patientDemographics),
        );

        return {
          name,
          date: data[date] as string,
          nextSteps: data[nextSteps] as string,
          ...(shouldShowCarePlanV1Changes
            ? {
                notApplicable: isNotApplicable,
                enabled: Boolean(data[date]),
                status: data[status] as PreventativeCareStatus,
              }
            : {
                enabled: !!data[checked],
                notApplicable: !!data[notApplicable],
              }),
        };
      },
    ),
    surgicalHistories: data.surgicalHistories,
    specialists: data.specialists,
    medsNotReviewed: data.medsNotReviewed,
    medicationAllergies: data.medicationAllergies,
    goalsAndInterventions: data.goalsAndInterventions
      ?.map((conditionData: GoalsAndInterventions) => {
        const {
          condition,
          treatmentGoal,
          healthGoal,
          customHealthGoal,
          ...rest
        } = conditionData;
        const goalOptions = condition && getPatientGoalOptions(condition, intl);
        const goalOption = goalOptions?.find(
          (option) => option.value === rest.healthGoalEnum,
        );
        const actionSteps = goalOption?.actionSteps;
        const actionStepsList = rest.actionStepsListEnums?.map(
          (actionStep) =>
            (actionStep === GoalsAndInterventionsActionStep.OTHER
              ? rest.customActionStep
              : actionSteps?.find((step) => step.value === actionStep)
                  ?.label) || '',
        );

        // Derive healthGoal field, which we'll use in the PDF so we capture the labels at the time of entry. Handles when "Other" is selected, which renders a separate customHealthGoal text field
        const healthGoalText =
          rest.healthGoalEnum ===
          GoalsAndInterventionsHealthGoal.HEALTH_GOAL_UNSPECIFIED
            ? customHealthGoal
            : goalOption?.label;

        return {
          ...rest,
          condition:
            FALCON_CONDITION_TO_GRPC_CONDITION[condition as CcmCondition] ||
            GrpcCondition.CCM_CONDITION_UNSPECIFIED,
          // Treatment goal is not a field when carePlanConditionImprovements flag is on
          treatmentGoal:
            treatmentGoal ||
            CCM_CONDITION_RESOURCES[condition as CcmCondition]?.goal,
          // Derive healthGoal field, which we'll use in the PDF so we capture the labels at the time of entry
          healthGoal: carePlanOptimization ? healthGoalText : healthGoal,
          actionStepsList,
          // Adding this redundant state to make pdf immediately compatible
          actionSteps: actionStepsList?.join(', ') || rest.actionSteps,
          goalSetting:
            // Doing this transformation here because the RadioGroup component doesn't seem to support mapping to undefined/null in the valueMap
            rest.goalSetting === 'notDiscussed' ? undefined : rest.goalSetting,
        };
      })
      .filter(
        ({ condition }) =>
          condition !== GrpcCondition.CCM_CONDITION_UNSPECIFIED,
      ),
    psychosocialAssessments: PSYCHOSOCIAL_ASSESSMENT_OPTIONS.map(
      ({ name: assessmentName }) => {
        const { checked, description } =
          getAssessmentFieldNames(assessmentName);

        return {
          name: assessmentName,
          enabled: !!data[checked],
          description: data[description] as string,
        };
      },
    ),
    ...(shouldShowCarePlanV1Changes
      ? { psychosocialAssessmentData: formToGrpcData(data) }
      : {}),
    followUp: {
      ccmAppointment: data.ccmAppointment,
      providerAppointment: data.providerAppointment,
    },
    culturalPreferences:
      data.culturalPreferences ||
      intl.formatMessage({ defaultMessage: 'Not answered at this time' }),
    culturalCommunityResources:
      data.culturalCommunityResources ||
      intl.formatMessage({ defaultMessage: 'Not answered at this time' }),
  };
}

export function marshalCarePlanToFormData(
  data: CarePlan,
  shouldShowCarePlanV1Changes: boolean,
  intl: IntlShape,
): FormFields {
  return {
    ...(shouldShowCarePlanV1Changes
      ? { dietNotes: data.dietNotes || '' }
      : {
          fastFood: data.diet?.fastFood || '',
          snacks: data.diet?.snacks || '',
          desserts: data.diet?.desserts || '',
          regularSoda: data.diet?.regularSoda || '',
          sweetTea: data.diet?.sweetTea || '',
          waterIntake: data.diet?.waterIntake || '',
          saltIntake: data.diet?.saltIntake || '',
          exercise: data.exercise || '',
        }),
    tobaccoUse: data.socialHistory?.tobaccoUse || '',
    tobaccoUserStatus: data.socialHistory?.tobaccoUserStatus,
    tobaccoTypes: data.socialHistory?.tobaccoTypes || [],
    smokingQuantity: data.socialHistory?.smokingQuantity,
    smokingYears: data.socialHistory?.smokingYears,
    emergencyVisits: data.emergencyVisits?.map(({ date, ...rest }) => ({
      ...rest,
      date: date ? parseGrpcDate(date) : '',
    })),
    surgicalHistories: data.surgicalHistories,
    specialists: data.specialists,
    medsNotReviewed: data.medsNotReviewed ?? false,
    medicationAllergies: data.medicationAllergies,
    goalsAndInterventions:
      data.goalsAndInterventions?.map(({ condition, ...rest }) => ({
        ...rest,
        goalSetting: getGoalSettingFormValue(rest, condition, data.goals, intl),
        customHealthGoal: getCustomHealthGoalFormValue(rest),
        condition:
          GRPC_CONDITION_TO_FALCON_CONDITION[condition as GrpcCondition] ||
          Condition.Unspecified,
      })) ?? [],
    ...PREVENTATIVE_CARE_OPTIONS.reduce((acc, { name }) => {
      const { checked, date, nextSteps, notApplicable, status } =
        getPreventativeCareFieldNames(name);
      const preventativeCare = data.preventativeCare?.find(
        (pc) => pc.name === name,
      );

      return {
        ...acc,
        [notApplicable]: Boolean(preventativeCare?.notApplicable) || false,
        [nextSteps]: preventativeCare?.nextSteps || '',
        [date]: preventativeCare?.date || '',
        ...(shouldShowCarePlanV1Changes
          ? { [status]: preventativeCare?.status }
          : {
              [checked]: preventativeCare?.enabled || false,
            }),
      };
    }, {}),
    ...PSYCHOSOCIAL_ASSESSMENT_OPTIONS.reduce((acc, { name }) => {
      const { checked, description } = getAssessmentFieldNames(name);
      const psychosocialAssessment = data.psychosocialAssessments?.find(
        (pa) => pa.name === name,
      );

      return {
        ...acc,
        [checked]: psychosocialAssessment?.enabled || false,
        [description]: psychosocialAssessment?.description || '',
      };
    }, {}),
    ...(shouldShowCarePlanV1Changes
      ? grpcDataToFormData(data.psychosocialAssessmentData)
      : {}),
    ccmAppointment: data.followUp?.ccmAppointment || '',
    providerAppointment: data.followUp?.providerAppointment || '',
    culturalPreferences: data.culturalPreferences || '',
    culturalCommunityResources: data.culturalCommunityResources || '',
  };
}

export const FALCON_CONDITION_TO_GRPC_CONDITION: Record<
  CcmCondition,
  GrpcCondition
> = {
  [Condition.CHF]: GrpcCondition.CHF,
  [Condition.Hypertension]: GrpcCondition.HYPERTENSION,
  [Condition.COPD]: GrpcCondition.COPD,
  [Condition.TypeTwoDiabetes]: GrpcCondition.TYPE2_DIABETES,
  [Condition.Asthma]: GrpcCondition.ASTHMA,
  [Condition.AfibAndFlutter]: GrpcCondition.AFIB_AND_FLUTTER,
  [Condition.ChronicKidneyDisease]: GrpcCondition.CHRONIC_KIDNEY_DISEASE,
  [Condition.Hyperlipidemia]: GrpcCondition.HYPERLIPIDEMIA,
  [Condition.Hypothyroidism]: GrpcCondition.HYPOTHYROIDISM,
  [Condition.IschemicHeartDisease]: GrpcCondition.ISCHEMIC_HEART_DISEASE,
  [Condition.MorbidObesity]: GrpcCondition.MORBID_OBESITY,
  [Condition.ObstructiveSleepApnea]: GrpcCondition.OBSTRUCTIVE_SLEEP_APNEA,
  [Condition.Osteoarthritis]: GrpcCondition.OSTEOARTHRITIS,
  [Condition.PeripheralArteryDisease]: GrpcCondition.PERIPHERAL_ARTERY_DISEASE,
  [Condition.Dementia]: GrpcCondition.DEMENTIA,
};

export const GRPC_CONDITION_TO_FALCON_CONDITION = invert(
  FALCON_CONDITION_TO_GRPC_CONDITION,
) as Record<GrpcCondition, CcmCondition>;

export const FORM_TO_GRPC_HOUSING_STABILITY_DETAIL: Record<
  HousingStabilityDetail,
  GrpcHousingStabilityDetail
> = {
  [HousingStabilityDetail.FutureWorries]:
    GrpcHousingStabilityDetail.HOUSING_STABILITY_DETAIL_FUTURE_WORRIES,
  [HousingStabilityDetail.Unstable]:
    GrpcHousingStabilityDetail.HOUSING_STABILITY_DETAIL_UNSTABLE,
};

export const FORM_TO_GRPC_HOUSING_CONCERN: Record<
  HousingConcern,
  GrpcHousingConcern
> = {
  [HousingConcern.Pests]: GrpcHousingConcern.HOUSING_CONCERN_PESTS,
  [HousingConcern.Mold]: GrpcHousingConcern.HOUSING_CONCERN_MOLD,
  [HousingConcern.Lead]: GrpcHousingConcern.HOUSING_CONCERN_LEAD,
  [HousingConcern.NoHeat]: GrpcHousingConcern.HOUSING_CONCERN_NO_HEAT,
  [HousingConcern.BrokenAppliances]:
    GrpcHousingConcern.HOUSING_CONCERN_BROKEN_APPLIANCES,
  [HousingConcern.MissingDetectors]:
    GrpcHousingConcern.HOUSING_CONCERN_SMOKE_DETECTORS,
  [HousingConcern.WaterLeaks]: GrpcHousingConcern.HOUSING_CONCERN_WATER_LEAKS,
  [HousingConcern.None]: GrpcHousingConcern.HOUSING_CONCERN_NONE,
};

export const FORM_TO_GRPC_UTILITY_STATUS: Record<
  UtilityStatus,
  GrpcUtilityStatus
> = {
  [UtilityStatus.Threatened]: GrpcUtilityStatus.UTILITY_STATUS_THREATENED,
  [UtilityStatus.AlreadyShutOff]:
    GrpcUtilityStatus.UTILITY_STATUS_ALREADY_SHUT_OFF,
};

export const FORM_TO_GRPC_FREQUENCY: Record<
  FoodFrequencyChoice,
  FrequencyChoice
> = {
  [FoodFrequencyChoice.Never]: FrequencyChoice.FREQUENCY_CHOICE_NEVER,
  [FoodFrequencyChoice.Sometimes]: FrequencyChoice.FREQUENCY_CHOICE_SOMETIMES,
  [FoodFrequencyChoice.Often]: FrequencyChoice.FREQUENCY_CHOICE_OFTEN,
};

export const FORM_TO_GRPC_SAFETY_FREQUENCY: Record<
  SafetyFrequencyChoice,
  GrpcSafetyFrequencyChoice
> = {
  [SafetyFrequencyChoice.Never]:
    GrpcSafetyFrequencyChoice.SAFETY_FREQUENCY_CHOICE_NEVER,
  [SafetyFrequencyChoice.Rarely]:
    GrpcSafetyFrequencyChoice.SAFETY_FREQUENCY_CHOICE_RARELY,
  [SafetyFrequencyChoice.Sometimes]:
    GrpcSafetyFrequencyChoice.SAFETY_FREQUENCY_CHOICE_SOMETIMES,
  [SafetyFrequencyChoice.FairlyOften]:
    GrpcSafetyFrequencyChoice.SAFETY_FREQUENCY_CHOICE_FAIRLY_OFTEN,
  [SafetyFrequencyChoice.Frequently]:
    GrpcSafetyFrequencyChoice.SAFETY_FREQUENCY_CHOICE_FREQUENTLY,
};

function formToGrpcData(data: FormFields): PsychosocialAssessmentData {
  return {
    exerciseDaysPerWeek: data.exerciseDaysPerWeek,
    exerciseMinutesPerDay: data.exerciseMinutesPerDay,
    hasHousingStabilityConcerns:
      data.housingStabilityChoice === HousingStabilityChoice.Yes,
    housingStabilityDetail: data.housingStabilityDetail
      ? FORM_TO_GRPC_HOUSING_STABILITY_DETAIL[data.housingStabilityDetail]
      : undefined,
    hasHousingConcerns:
      data.housingConcernsChoice === HousingConcernsChoice.Yes,
    housingConcerns: data.housingConcerns?.map(
      (concern) => FORM_TO_GRPC_HOUSING_CONCERN[concern],
    ),
    hasFoodSecurityConcerns: data.foodSecurityChoice === FoodSecurityChoice.Yes,
    foodWorryFrequency: data.foodWorryFrequency
      ? FORM_TO_GRPC_FREQUENCY[data.foodWorryFrequency]
      : undefined,
    foodRunOutFrequency: data.foodRunOutFrequency
      ? FORM_TO_GRPC_FREQUENCY[data.foodRunOutFrequency]
      : undefined,
    hasTransportationConcerns:
      data.transportationSecurityChoice === TransportationSecurityChoice.Yes,
    hasUtilityConcerns:
      data.utilitySecurityChoice === UtilitySecurityChoice.Yes,
    utilityStatus: data.utilityStatus
      ? FORM_TO_GRPC_UTILITY_STATUS[data.utilityStatus]
      : undefined,
    hasSafetyConcerns: data.safetyChoice === SafetyChoice.Yes,
    physicalHarmFrequency: data.physicalHarmFrequency
      ? FORM_TO_GRPC_SAFETY_FREQUENCY[data.physicalHarmFrequency]
      : undefined,
    threatFrequency: data.threatFrequency
      ? FORM_TO_GRPC_SAFETY_FREQUENCY[data.threatFrequency]
      : undefined,
    verbalAbuseFrequency: data.verbalAbuseFrequency
      ? FORM_TO_GRPC_SAFETY_FREQUENCY[data.verbalAbuseFrequency]
      : undefined,
    insultFrequency: data.insultFrequency
      ? FORM_TO_GRPC_SAFETY_FREQUENCY[data.insultFrequency]
      : undefined,
  };
}

function grpcDataToFormData(
  data: PsychosocialAssessmentData | undefined,
): Partial<FormFields> {
  if (!data) {
    return {};
  }

  return {
    exerciseDaysPerWeek: data.exerciseDaysPerWeek as DaysPerWeek | undefined,
    exerciseMinutesPerDay: data.exerciseMinutesPerDay as
      | MinutesPerDay
      | undefined,
    housingStabilityChoice: data.hasHousingStabilityConcerns
      ? HousingStabilityChoice.Yes
      : HousingStabilityChoice.No,
    housingStabilityDetail: data.housingStabilityDetail
      ? GRPC_TO_FORM_HOUSING_STABILITY_DETAIL[data.housingStabilityDetail]
      : undefined,
    housingConcernsChoice: data.hasHousingConcerns
      ? HousingConcernsChoice.Yes
      : HousingConcernsChoice.No,
    housingConcerns:
      data.housingConcerns
        ?.map((concern) => GRPC_TO_FORM_HOUSING_CONCERN[concern])
        .filter(
          (concern): concern is HousingConcern => concern !== undefined,
        ) ?? [],
    foodSecurityChoice: data.hasFoodSecurityConcerns
      ? FoodSecurityChoice.Yes
      : FoodSecurityChoice.No,
    foodWorryFrequency: data.foodWorryFrequency
      ? (GRPC_TO_FORM_FREQUENCY[data.foodWorryFrequency] as FoodFrequencyChoice)
      : undefined,
    foodRunOutFrequency: data.foodRunOutFrequency
      ? (GRPC_TO_FORM_FREQUENCY[
          data.foodRunOutFrequency
        ] as FoodFrequencyChoice)
      : undefined,
    transportationSecurityChoice: data.hasTransportationConcerns
      ? TransportationSecurityChoice.Yes
      : TransportationSecurityChoice.No,
    utilitySecurityChoice: data.hasUtilityConcerns
      ? UtilitySecurityChoice.Yes
      : UtilitySecurityChoice.No,
    utilityStatus: data.utilityStatus
      ? GRPC_TO_FORM_UTILITY_STATUS[data.utilityStatus]
      : undefined,
    safetyChoice: data.hasSafetyConcerns ? SafetyChoice.Yes : SafetyChoice.No,
    physicalHarmFrequency: data.physicalHarmFrequency
      ? (GRPC_TO_FORM_SAFETY_FREQUENCY[
          data.physicalHarmFrequency
        ] as SafetyFrequencyChoice)
      : undefined,
    threatFrequency: data.threatFrequency
      ? (GRPC_TO_FORM_SAFETY_FREQUENCY[
          data.threatFrequency
        ] as SafetyFrequencyChoice)
      : undefined,
    verbalAbuseFrequency: data.verbalAbuseFrequency
      ? (GRPC_TO_FORM_SAFETY_FREQUENCY[
          data.verbalAbuseFrequency
        ] as SafetyFrequencyChoice)
      : undefined,
    insultFrequency: data.insultFrequency
      ? (GRPC_TO_FORM_SAFETY_FREQUENCY[
          data.insultFrequency
        ] as SafetyFrequencyChoice)
      : undefined,
  };
}

const GRPC_TO_FORM_HOUSING_STABILITY_DETAIL: Record<
  GrpcHousingStabilityDetail,
  HousingStabilityDetail | undefined
> = {
  HOUSING_STABILITY_DETAIL_UNSPECIFIED: undefined,
  HOUSING_STABILITY_DETAIL_FUTURE_WORRIES: HousingStabilityDetail.FutureWorries,
  HOUSING_STABILITY_DETAIL_UNSTABLE: HousingStabilityDetail.Unstable,
};

const GRPC_TO_FORM_HOUSING_CONCERN: Record<
  GrpcHousingConcern,
  HousingConcern | undefined
> = {
  HOUSING_CONCERN_UNSPECIFIED: undefined,
  HOUSING_CONCERN_PESTS: HousingConcern.Pests,
  HOUSING_CONCERN_MOLD: HousingConcern.Mold,
  HOUSING_CONCERN_LEAD: HousingConcern.Lead,
  HOUSING_CONCERN_NO_HEAT: HousingConcern.NoHeat,
  HOUSING_CONCERN_BROKEN_APPLIANCES: HousingConcern.BrokenAppliances,
  HOUSING_CONCERN_SMOKE_DETECTORS: HousingConcern.MissingDetectors,
  HOUSING_CONCERN_WATER_LEAKS: HousingConcern.WaterLeaks,
  HOUSING_CONCERN_NONE: HousingConcern.None,
};

const GRPC_TO_FORM_FREQUENCY: Record<
  FrequencyChoice,
  FoodFrequencyChoice | undefined
> = {
  FREQUENCY_CHOICE_UNSPECIFIED: undefined,
  FREQUENCY_CHOICE_NEVER: FoodFrequencyChoice.Never,
  FREQUENCY_CHOICE_SOMETIMES: FoodFrequencyChoice.Sometimes,
  FREQUENCY_CHOICE_OFTEN: FoodFrequencyChoice.Often,
};

const GRPC_TO_FORM_SAFETY_FREQUENCY: Record<
  GrpcSafetyFrequencyChoice,
  SafetyFrequencyChoice | undefined
> = {
  SAFETY_FREQUENCY_CHOICE_UNSPECIFIED: undefined,
  SAFETY_FREQUENCY_CHOICE_NEVER: SafetyFrequencyChoice.Never,
  SAFETY_FREQUENCY_CHOICE_RARELY: SafetyFrequencyChoice.Rarely,
  SAFETY_FREQUENCY_CHOICE_SOMETIMES: SafetyFrequencyChoice.Sometimes,
  SAFETY_FREQUENCY_CHOICE_FAIRLY_OFTEN: SafetyFrequencyChoice.FairlyOften,
  SAFETY_FREQUENCY_CHOICE_FREQUENTLY: SafetyFrequencyChoice.Frequently,
};

const GRPC_TO_FORM_UTILITY_STATUS: Record<
  GrpcUtilityStatus,
  UtilityStatus | undefined
> = {
  UTILITY_STATUS_UNSPECIFIED: undefined,
  UTILITY_STATUS_THREATENED: UtilityStatus.Threatened,
  UTILITY_STATUS_ALREADY_SHUT_OFF: UtilityStatus.AlreadyShutOff,
};

function getGoalSettingFormValue(
  data: GrpcGoalsAndInterventions,
  condition: Maybe<GrpcCondition>,
  goals: Maybe<CarePlanGoal[]>,
  intl: IntlShape,
) {
  const { goalSetting, skipGoalSetting, actionSteps } = data;
  // For backwards compatibility
  if (
    (goalSetting === undefined || goalSetting === null) &&
    !skipGoalSetting &&
    actionSteps
  ) {
    return true;
  }

  if ((goalSetting === null || goalSetting === undefined) && condition) {
    const goal = goals?.find((g) =>
      isReviewGoalForCondition(g, condition, intl),
    );
    if (goal) {
      return 'notDiscussed';
    }
  }

  return goalSetting;
}

function getCustomHealthGoalFormValue(data: GrpcGoalsAndInterventions) {
  if (isOtherGoal(data.healthGoalEnum)) {
    return data.healthGoal;
  }

  return undefined;
}

// TODO: This is really hacky. Think about adding a conditions column to the goals table, or at least to CarePlanGoal json
export function isReviewGoalForCondition(
  goal: CarePlanGoal,
  condition: GrpcCondition,
  intl: IntlShape,
) {
  const ccmCondition = GRPC_CONDITION_TO_FALCON_CONDITION[condition];
  return isReviewGoalForCcmCondition(goal, ccmCondition, intl);
}

export function isReviewGoalForCcmCondition(
  goal: CarePlanGoal,
  condition: CcmCondition,
  intl: IntlShape,
) {
  const conditionName = getCcmConditionI18n(intl)[condition];
  return isConditionReviewGoal(goal) && goal.title?.includes(conditionName);
}
