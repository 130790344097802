import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';

import { getProblemList } from '@/pages/adminPanel/patient-enrollment-tracker/ui/shared/icd10CodeUtils';
import { usePatientMedications } from '@/pages/patients/PatientMedications/patientMedications.queries';
import { unstructuredMedInfo } from '@/pages/patients/PatientMedications/unstructuredMedInfo';
import {
  isStructured,
  isUnreferenced,
  lastStructuredChange,
} from '@/pages/patients/PatientMedications/utils/medChangeUtils';
import {
  getDoseStr,
  getRxNormStr,
} from '@/pages/patients/PatientMedications/utils/medInfoUtils';
import { compareMedChanges } from '@/pages/patients/PatientMedications/utils/sortMeds';
import {
  addPsychosocialAssessmentLabels,
  getPreventativeCareGaps,
} from '@/pages/patients/patientDetails/ui/tabs/CarePlan/CarePlanDetail/CarePlanPreview/formatters';
import { GRPC_CONDITION_TO_FALCON_CONDITION } from '@/pages/patients/patientDetails/ui/tabs/CarePlan/CarePlanForm/dataTransformation';
import { useConditionReviewGoals } from '@/pages/patients/patientDetails/ui/tabs/CarePlan/CarePlanForm/sections/GoalsAndInterventions/conditionGoalUtils';
import { useFormattedConditionVitals } from '@/pages/patients/patientDetails/ui/tabs/CarePlan/CarePlanForm/sections/GoalsAndInterventions/hooks/useFormattedConditionVitals';
import type {
  CCMCondition,
  CCMPrognosis,
  CarePlan,
  EmergencyVisit,
  FollowUp,
  GeneralAssessmentDiet,
  GeneralAssessmentSocialHistory,
  GoalsAndInterventions,
  MedicationAllergy,
  PreventativeCare,
  PsychosocialAssessment,
  Specialist,
  SurgicalHistory,
} from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import type { CCMGoal } from '@/shared/generated/grpc/go/pms/pkg/ccm_goal/ccm_goal.pb';
import {
  type MedicationChange,
  MedicationChangeStatus,
  type PatientMedications,
} from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { useFlags } from '@/shared/hooks';
import { useRouteParamPatientDetails } from '@/shared/hooks/queries';
import type { FeatureFlagSet } from '@/shared/types/featureFlags.types';

import { useCcmConditionI18n } from '../../../../../tabs/CarePlan/CarePlanForm/conditions';
import { useCcmPrognosisI18n } from '../../../../../tabs/CarePlan/CarePlanForm/prognosis';
import { addPreventativeCareLabels } from '../../../templates/carePlanTemplateHelpers';

export type CarePlanContext = {
  flags: FeatureFlagSet;
  problemList: string[];
  surgicalHistories: SurgicalHistory[];
  specialists: Specialist[];
  emergencyVisits: EmergencyVisit[];
  medsNotReviewed: boolean;
  medAllergies: MedicationAllergy[];
  preventativeCare: (PreventativeCare & {
    label?: string;
  })[];
  preventativeCareGaps: (PreventativeCare & {
    label?: string;
  })[];
  socialHistory: GeneralAssessmentSocialHistory;
  dietNotes?: string;
  diet: GeneralAssessmentDiet;
  exercise?: string;
  psychosocialAssessments: (PsychosocialAssessment & { label?: string })[];
  disabledPsychosocialAssessments: (PsychosocialAssessment & {
    label?: string;
  })[];
  goalsAndInterventions: (Omit<
    GoalsAndInterventions,
    'condition' | 'prognosis'
  > & {
    condition?: string;
    prognosis?: string;
  })[];
  goalsAndInterventionsV2: GoalsAndInterventions[];
  conditionReviewGoals: CCMGoal[];
  culturalPreferences?: string;
  culturalCommunityResources?: string;
  followUp: FollowUp;
  medications: Med[];
  identifiedCareGaps: string;
  intl: IntlShape;
};

type Med = {
  medStr: string;
  isInactive?: boolean;
};

// Builds the context for the template used to populate the Care Plan into a Care Plan visit note
export function useCarePlanTemplateContext(carePlan: Maybe<CarePlan>) {
  const intl = useIntl();
  const { data: patient } = useRouteParamPatientDetails();
  const { params } = useRouteMatch<{ patientId: string }>();
  const { data: patientMeds } = usePatientMedications(params.patientId);
  const flags = useFlags();

  const ccmConditionI18n = useCcmConditionI18n();
  const ccmPrognosisI18n = useCcmPrognosisI18n();

  let preventativeCare = addPreventativeCareLabels(
    carePlan?.preventativeCare || [],
  );
  if (flags.carePlanOptimization) {
    preventativeCare = preventativeCare.filter((item) => !item.notApplicable);
  }
  const assessments = addPsychosocialAssessmentLabels(
    carePlan?.psychosocialAssessments || [],
  );
  const preventativeCareGaps = getPreventativeCareGaps(preventativeCare);

  const identifiedCareGaps = [
    ...preventativeCareGaps.map(({ label }) => label),
    ...assessments.filter(({ enabled }) => enabled).map(({ label }) => label),
  ].join(', ');

  const formattedConditionVitals = useFormattedConditionVitals(
    params.patientId,
  );
  const conditionReviewGoals = useConditionReviewGoals(
    params.patientId,
    carePlan,
  );

  const templateContext: CarePlanContext = {
    flags,
    problemList: patient ? getProblemList(patient.diagnosis_codes) : [],
    surgicalHistories: carePlan?.surgicalHistories || [],
    specialists: carePlan?.specialists || [],
    emergencyVisits: carePlan?.emergencyVisits || [],
    medsNotReviewed: carePlan?.medsNotReviewed || false,
    medAllergies: carePlan?.medicationAllergies || [],
    preventativeCare,
    preventativeCareGaps,
    socialHistory: carePlan?.socialHistory || {},
    dietNotes: carePlan?.dietNotes,
    diet: carePlan?.diet || {},
    exercise: carePlan?.exercise,
    psychosocialAssessments: assessments,
    disabledPsychosocialAssessments: assessments.filter(
      ({ enabled }) => !enabled,
    ),
    goalsAndInterventions: (carePlan?.goalsAndInterventions || []).map(
      (goal) => {
        const condition = goal.condition
          ? GRPC_CONDITION_TO_FALCON_CONDITION[goal.condition]
          : undefined;
        const vital = condition
          ? formattedConditionVitals(condition)
          : undefined;
        return {
          ...goal,
          ...(goal.condition && {
            condition:
              ccmConditionI18n[
                GRPC_CONDITION_TO_FALCON_CONDITION[
                  goal.condition as CCMCondition
                ]
              ],
          }),
          prognosis: ccmPrognosisI18n[goal.prognosis as CCMPrognosis],
          ...(vital?.value && { vital }),
        };
      },
    ),
    goalsAndInterventionsV2: (carePlan?.goalsAndInterventions || []).map(
      (goal) => {
        const condition = goal.condition
          ? GRPC_CONDITION_TO_FALCON_CONDITION[goal.condition]
          : undefined;
        const vital = condition
          ? formattedConditionVitals(condition)
          : undefined;
        return {
          ...goal,
          conditionName:
            ccmConditionI18n[
              GRPC_CONDITION_TO_FALCON_CONDITION[goal.condition as CCMCondition]
            ],
          ...(vital?.value && { vital }),
        };
      },
    ),
    conditionReviewGoals,
    culturalPreferences: carePlan?.culturalPreferences,
    culturalCommunityResources: carePlan?.culturalCommunityResources,
    followUp: carePlan?.followUp || {},
    medications: formatMedicationsForTemplate(intl, patientMeds),
    identifiedCareGaps: `Care Gaps Identified: \n${identifiedCareGaps}`,
    intl,
  };
  return templateContext;
}

function getLatestMedChanges(medications: PatientMedications) {
  const unreferencedMeds = medications.unreferencedMedications;
  const referencedMeds = medications.referencedMedications
    ?.map((med) => lastStructuredChange(med))
    .filter((med) => med !== undefined && med !== null);

  return [
    ...(unreferencedMeds || []),
    ...(referencedMeds || []),
  ] as MedicationChange[];
}

function formatMedicationsForTemplate(
  intl: IntlShape,
  patientMeds: Maybe<PatientMedications>,
): Med[] {
  const medChanges = patientMeds ? getLatestMedChanges(patientMeds) : [];
  const sortedMedChanges = medChanges.sort((a, b) =>
    compareMedChanges(a, b, false),
  );

  return sortedMedChanges
    .map((med) => ({
      medStr: getMedStr(intl, med) || '',
      isInactive: med.status === MedicationChangeStatus.INACTIVE,
    }))
    .filter((med) => med?.medStr !== '');
}

function getMedStr(intl: IntlShape, med: MedicationChange) {
  if (isStructured(med)) {
    return `${getMedName(intl, med)} | ${getDoseStr(
      intl,
      med.doseQuantities,
      med.frequencies,
      med.rxnorm,
    )}`;
  }

  return unstructuredMedInfo(med.ehrMedication, med.medicationName);
}

function getMedName(intl: IntlShape, med: MedicationChange) {
  if (isUnreferenced(med.rxnorm) || !med.rxnorm) {
    return med.medicationName;
  }

  return getRxNormStr(med.rxnorm, intl);
}
