import merge from 'lodash/merge';

import { useFieldPortals } from '@/shared/common/@deprecated/SchemaDrivenForm/useFieldPortals.hook';

import { getHiddenConditionPropertiesUiSchema } from '../encounterModuleSchema.utils';
import { SYMPTOMS_FORMATTED_MESSAGES } from './SymptomsForm';

export const OTHER_SYMPTOM_NAME = 'other';

export function useUiSchema() {
  const symptoms = getSymptomPropertyNames();
  const sortedSymptoms = [
    ...symptoms.filter((symptom) => symptom !== OTHER_SYMPTOM_NAME).sort(),
    OTHER_SYMPTOM_NAME,
  ];
  const { fieldPortalsUiSchema, portalContainers } = useFieldPortals(
    sortedSymptoms.flatMap((symptom) => [
      `${symptom}.is_new_or_worsening_symptom`,
      `${symptom}.description`,
    ]),
  );

  const uiSchema = merge(
    {
      'ui:order': [
        ...sortedSymptoms,
        // '*' is needed because otherwise rjsf will throw an error about there
        // being missing fields (even though currently the only other fields are
        // the condition fields which are hidden)
        '*',
      ],
    },
    buildUISchemaForSymptoms(symptoms),
    getHiddenConditionPropertiesUiSchema(),
    fieldPortalsUiSchema,
  );
  return { uiSchema, portalContainers };
}

function buildUISchemaForSymptoms(symptoms: string[]) {
  return Object.fromEntries(
    symptoms.map((symptom) => [
      symptom,
      {
        'ui:options': { inline: true, label: false },
      },
    ]),
  );
}

function getSymptomPropertyNames() {
  return Object.keys(SYMPTOMS_FORMATTED_MESSAGES);
}
