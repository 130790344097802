import cx from 'classnames';
import uniq from 'lodash/uniq';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import type { ReferenceMedication } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { Tooltip } from '@/shared/tempo/atom/Tooltip';

import {
  arrow,
  brandList,
  container,
  header,
  sideEffectsAndLabs,
  triggerContainer,
  triggerContainerHover,
} from './BrandsTooltip.css';

type Props = {
  children: ReactNode;
  referenceMed?: ReferenceMedication;
  isPreview?: boolean;
};

export function BrandsTooltip({ children, isPreview, referenceMed }: Props) {
  const hasBrands = (referenceMed?.brandNames?.length ?? 0) > 0;
  const hasSideEffects = !!referenceMed?.sideEffects;
  const hasLabs = !!referenceMed?.requiredLabs;
  const hasTooltip = hasBrands || hasSideEffects || hasLabs;

  // isPreview indicates we're rendering in a server context and we cannot
  // render this tooltip (it uses portals) in that context.
  if (isPreview) {
    return <>{children}</>;
  }

  return (
    <Tooltip
      isDisabled={!hasTooltip}
      content={<TooltipContent referenceMed={referenceMed} />}
      placement="bottom-start"
      arrowClassName={arrow}
      classes={{
        triggerContainer: cx(
          triggerContainer,
          hasTooltip ? triggerContainerHover : undefined,
        ),
      }}
    >
      {children}
    </Tooltip>
  );
}

function TooltipContent({ referenceMed }: Pick<Props, 'referenceMed'>) {
  return (
    <div className={container}>
      {(referenceMed?.brandNames?.length ?? 0) > 0 && (
        <div>
          <div className={header}>
            <FormattedMessage defaultMessage="Possible brands" />
          </div>
          <span className={brandList}>
            {uniq(referenceMed?.brandNames)?.join(', ')}
          </span>
        </div>
      )}
      {referenceMed?.sideEffects && (
        <div>
          <div className={header}>
            <FormattedMessage defaultMessage="Side effects" />
          </div>
          <span className={sideEffectsAndLabs}>
            {referenceMed?.sideEffects}
          </span>
        </div>
      )}
      {referenceMed?.requiredLabs && (
        <div>
          <div className={header}>
            <FormattedMessage defaultMessage="Labs required" />
          </div>
          <span className={sideEffectsAndLabs}>
            {referenceMed?.requiredLabs}
          </span>
        </div>
      )}
    </div>
  );
}
