import { makeI18nHookAndGetter } from '../hooks';
import { ReasonForNotTitrating, type TReasonForNotTitrating } from './types';

export const {
  hook: useTitrationRejectionReasonsI18n,
  getter: getTitrationRejectionReasonsI18n,
} = makeI18nHookAndGetter<Record<TReasonForNotTitrating, string>>((intl) => ({
  [ReasonForNotTitrating.PROVIDER_PREFERENCE]: intl.formatMessage({
    defaultMessage: 'Provider Preference',
  }),
  [ReasonForNotTitrating.PATIENT_PREFERENCES]: intl.formatMessage({
    defaultMessage: 'Patient preference',
  }),
  [ReasonForNotTitrating.MEDICAL_REASON]: intl.formatMessage({
    defaultMessage: 'Medical reason',
  }),
  [ReasonForNotTitrating.FINANCIAL_REASON]: intl.formatMessage({
    defaultMessage: 'Financial reason',
  }),
  [ReasonForNotTitrating.NEW_PRESCRIPTION_REQUIRED]: intl.formatMessage({
    defaultMessage: 'New prescription required',
  }),
  [ReasonForNotTitrating.MEDICATION_NOT_ACCURATE]: intl.formatMessage({
    defaultMessage: 'Medication not accurate',
  }),
  [ReasonForNotTitrating.MEDICATION_WAS_NOT_TITRATED_REASON_UNSPECIFIED]:
    intl.formatMessage({ defaultMessage: 'Unspecified' }),
  [ReasonForNotTitrating.AGE_OR_CONDITION]: intl.formatMessage({
    defaultMessage: 'Patient condition (e.g., current symptoms)',
  }),
  [ReasonForNotTitrating.CALCIUM_TOO_HIGH]: intl.formatMessage({
    defaultMessage: 'Calcium too high',
  }),
  [ReasonForNotTitrating.CALCIUM_TOO_LOW]: intl.formatMessage({
    defaultMessage: 'Calcium too low',
  }),
  [ReasonForNotTitrating.HEART_RATE_TOO_LOW]: intl.formatMessage({
    defaultMessage: 'Heart rate too low',
  }),
  [ReasonForNotTitrating.KIDNEY_FUNCTION_TOO_LOW]: intl.formatMessage({
    defaultMessage: 'Kidney function too low',
  }),
  [ReasonForNotTitrating.MANAGED_BY_EXTERNAL_PROVIDER]: intl.formatMessage({
    defaultMessage: 'Managed by external provider',
  }),
  [ReasonForNotTitrating.POTASSIUM_TOO_LOW]: intl.formatMessage({
    defaultMessage: 'Potassium too low',
  }),
  [ReasonForNotTitrating.POTASSIUM_TOO_HIGH]: intl.formatMessage({
    defaultMessage: 'Potassium too high',
  }),
  [ReasonForNotTitrating.RECENT_MEDICATION_CHANGE_BY_EXTERNAL_PROVIDER]:
    intl.formatMessage({
      defaultMessage:
        'Recent medication change by external provider (in last 21 days)',
    }),
  [ReasonForNotTitrating.SIDE_EFFECT_AT_HIGHER_DOSE]: intl.formatMessage({
    defaultMessage: 'Side effect at higher dose',
  }),
  [ReasonForNotTitrating.SODIUM_TOO_LOW]: intl.formatMessage({
    defaultMessage: 'Sodium too low',
  }),
  [ReasonForNotTitrating.ADJUSTED_ANOTHER_MEDICATION]: intl.formatMessage({
    defaultMessage: 'Adjusted another medication',
  }),
  [ReasonForNotTitrating.ELECTROLYTE_ABNORMALITY]: intl.formatMessage({
    defaultMessage: 'Electrolyte abnormality',
  }),
  [ReasonForNotTitrating.NEED_LABS]: intl.formatMessage({
    defaultMessage: 'Need labs before additional medication adjustment',
  }),
  [ReasonForNotTitrating.ACUTE_MEDICAL_ISSUE]: intl.formatMessage({
    defaultMessage: 'Acute medical issue/hospitalization',
  }),
  [ReasonForNotTitrating.PROVIDER_GOAL_DIFFERS]: intl.formatMessage({
    defaultMessage: 'Provider goal > 140/90',
  }),
  [ReasonForNotTitrating.MEDICATION_VERIFICATION]: intl.formatMessage({
    defaultMessage: 'Medication verification',
  }),
  [ReasonForNotTitrating.HYPOGLYCEMIC_EPISODES]: intl.formatMessage({
    defaultMessage: 'Hypoglycemic episodes',
  }),
  [ReasonForNotTitrating.HYPOTENSION]: intl.formatMessage({
    defaultMessage: 'Hypotension',
  }),
  [ReasonForNotTitrating.LABILE_BLOOD_GLUCOSE]: intl.formatMessage({
    defaultMessage: 'Labile blood glucose',
  }),
  [ReasonForNotTitrating.ABNORMAL_LABS]: intl.formatMessage({
    defaultMessage: 'Abnormal labs',
  }),
  [ReasonForNotTitrating.DISENROLLED]: intl.formatMessage({
    defaultMessage: 'Disenrolled',
  }),
  [ReasonForNotTitrating.DUPLICATE_TASK]: intl.formatMessage({
    defaultMessage: 'Duplicate task',
  }),
  [ReasonForNotTitrating.RECENT_PATIENT_REFUSAL]: intl.formatMessage({
    defaultMessage:
      'Recent patient refusal to suggested medication (in last 21 days)',
  }),
  [ReasonForNotTitrating.INSUFFICIENT_READINGS]: intl.formatMessage({
    defaultMessage: 'Insufficient data (<5 readings in 30 days)',
  }),
  [ReasonForNotTitrating.NEW_RX_REQUIRED_FOR_CURRENT_MEDS_MGMT_PATIENT]:
    intl.formatMessage({
      defaultMessage:
        'New prescription required for current med management patient',
    }),
  [ReasonForNotTitrating.NON_COMPLIANT]: intl.formatMessage({
    defaultMessage: 'Non-compliant with current regimen',
  }),
  [ReasonForNotTitrating.RECOMMEND_ONLY_PATIENT]: intl.formatMessage({
    defaultMessage: 'Recommend-only patient',
  }),
  [ReasonForNotTitrating.PATIENT_REJECTED]: intl.formatMessage({
    defaultMessage: 'Patient rejected',
  }),
  [ReasonForNotTitrating.MAX_TOLERATED_DOSE_REACHED]: intl.formatMessage({
    defaultMessage: 'Maximally tolerated GDMT dose reached',
  }),
  [ReasonForNotTitrating.RECOMMEND_ONLY_CURRENT_MEDS_PATIENT_CASE_SENT]:
    intl.formatMessage({
      defaultMessage: 'Recommend-only/Current meds patient case sent',
    }),
}));
