/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as GoogleLongrunningOperations from "../../../../google/longrunning/operations.pb"
import * as GoogleProtobufEmpty from "../../../../google/protobuf/empty.pb"
import * as GoogleProtobufTimestamp from "../../../../google/protobuf/timestamp.pb"
import * as GoogleProtobufWrappers from "../../../../google/protobuf/wrappers.pb"
import * as GoogleTypeDate from "../../../../google/type/date.pb"
import * as GoogleTypeDatetime from "../../../../google/type/datetime.pb"
import * as GoPmsPkgNoteNote from "../note/note.pb"
import * as GoPmsPkgPatientMedicationMedication from "../patient/medication/medication.pb"
import * as GoPmsPkgPatientPms from "../patient/pms.pb"
import * as GoPmsPkgPatient_outreachPatient_outreach from "../patient_outreach/patient_outreach.pb"
import * as fm from "@/shared/generated/grpc/fetch.pb"

export enum TobaccoUserStatus {
  TOBACCO_USER_STATUS_UNSPECIFIED = "TOBACCO_USER_STATUS_UNSPECIFIED",
  NEVER = "NEVER",
  FORMER = "FORMER",
  CURRENT = "CURRENT",
}

export enum TobaccoType {
  TOBACCO_TYPE_UNSPECIFIED = "TOBACCO_TYPE_UNSPECIFIED",
  SMOKING = "SMOKING",
  CHEWING = "CHEWING",
  SNUFF = "SNUFF",
  ECIG = "ECIG",
}

export enum State {
  STATE_UNSPECIFIED = "STATE_UNSPECIFIED",
  DRAFT = "DRAFT",
  APPROVED = "APPROVED",
  ARCHIVED_APPROVED = "ARCHIVED_APPROVED",
  UNASSIGNED_APPROVAL = "UNASSIGNED_APPROVAL",
  CLINICALDOC_NOT_FOUND = "CLINICALDOC_NOT_FOUND",
}

export enum Condition {
  CONDITION_UNSPECIFIED = "CONDITION_UNSPECIFIED",
  CHF = "CHF",
  HYPERTENSION = "HYPERTENSION",
  COPD = "COPD",
  TYPE2_DIABETES = "TYPE2_DIABETES",
  ASTHMA = "ASTHMA",
  AFIB_AND_FLUTTER = "AFIB_AND_FLUTTER",
  CHRONIC_KIDNEY_DISEASE = "CHRONIC_KIDNEY_DISEASE",
  HYPERLIPIDEMIA = "HYPERLIPIDEMIA",
  HYPOTHYROIDISM = "HYPOTHYROIDISM",
  ISCHEMIC_HEART_DISEASE = "ISCHEMIC_HEART_DISEASE",
  MORBID_OBESITY = "MORBID_OBESITY",
  OBSTRUCTIVE_SLEEP_APNEA = "OBSTRUCTIVE_SLEEP_APNEA",
  OSTEOARTHRITIS = "OSTEOARTHRITIS",
  PERIPHERAL_ARTERY_DISEASE = "PERIPHERAL_ARTERY_DISEASE",
  DEMENTIA = "DEMENTIA",
  GENERIC = "GENERIC",
}

export enum Prognosis {
  PROGNOSIS_UNSPECIFIED = "PROGNOSIS_UNSPECIFIED",
  GOOD = "GOOD",
  FAIR = "FAIR",
  POOR = "POOR",
}

export enum HousingStabilityDetail {
  HOUSING_STABILITY_DETAIL_UNSPECIFIED = "HOUSING_STABILITY_DETAIL_UNSPECIFIED",
  HOUSING_STABILITY_DETAIL_FUTURE_WORRIES = "HOUSING_STABILITY_DETAIL_FUTURE_WORRIES",
  HOUSING_STABILITY_DETAIL_UNSTABLE = "HOUSING_STABILITY_DETAIL_UNSTABLE",
}

export enum HousingConcern {
  HOUSING_CONCERN_UNSPECIFIED = "HOUSING_CONCERN_UNSPECIFIED",
  HOUSING_CONCERN_PESTS = "HOUSING_CONCERN_PESTS",
  HOUSING_CONCERN_MOLD = "HOUSING_CONCERN_MOLD",
  HOUSING_CONCERN_LEAD = "HOUSING_CONCERN_LEAD",
  HOUSING_CONCERN_NO_HEAT = "HOUSING_CONCERN_NO_HEAT",
  HOUSING_CONCERN_BROKEN_APPLIANCES = "HOUSING_CONCERN_BROKEN_APPLIANCES",
  HOUSING_CONCERN_SMOKE_DETECTORS = "HOUSING_CONCERN_SMOKE_DETECTORS",
  HOUSING_CONCERN_WATER_LEAKS = "HOUSING_CONCERN_WATER_LEAKS",
  HOUSING_CONCERN_NONE = "HOUSING_CONCERN_NONE",
}

export enum FrequencyChoice {
  FREQUENCY_CHOICE_UNSPECIFIED = "FREQUENCY_CHOICE_UNSPECIFIED",
  FREQUENCY_CHOICE_NEVER = "FREQUENCY_CHOICE_NEVER",
  FREQUENCY_CHOICE_SOMETIMES = "FREQUENCY_CHOICE_SOMETIMES",
  FREQUENCY_CHOICE_OFTEN = "FREQUENCY_CHOICE_OFTEN",
}

export enum SafetyFrequencyChoice {
  SAFETY_FREQUENCY_CHOICE_UNSPECIFIED = "SAFETY_FREQUENCY_CHOICE_UNSPECIFIED",
  SAFETY_FREQUENCY_CHOICE_NEVER = "SAFETY_FREQUENCY_CHOICE_NEVER",
  SAFETY_FREQUENCY_CHOICE_RARELY = "SAFETY_FREQUENCY_CHOICE_RARELY",
  SAFETY_FREQUENCY_CHOICE_SOMETIMES = "SAFETY_FREQUENCY_CHOICE_SOMETIMES",
  SAFETY_FREQUENCY_CHOICE_FAIRLY_OFTEN = "SAFETY_FREQUENCY_CHOICE_FAIRLY_OFTEN",
  SAFETY_FREQUENCY_CHOICE_FREQUENTLY = "SAFETY_FREQUENCY_CHOICE_FREQUENTLY",
}

export enum UtilityStatus {
  UTILITY_STATUS_UNSPECIFIED = "UTILITY_STATUS_UNSPECIFIED",
  UTILITY_STATUS_THREATENED = "UTILITY_STATUS_THREATENED",
  UTILITY_STATUS_ALREADY_SHUT_OFF = "UTILITY_STATUS_ALREADY_SHUT_OFF",
}

export enum PreventativeCareStatus {
  PREVENTATIVE_CARE_STATUS_UNSPECIFIED = "PREVENTATIVE_CARE_STATUS_UNSPECIFIED",
  PREVENTATIVE_CARE_STATUS_ACTION_NEEDED = "PREVENTATIVE_CARE_STATUS_ACTION_NEEDED",
  PREVENTATIVE_CARE_STATUS_DECLINED = "PREVENTATIVE_CARE_STATUS_DECLINED",
  PREVENTATIVE_CARE_STATUS_UP_TO_DATE = "PREVENTATIVE_CARE_STATUS_UP_TO_DATE",
}

export type CreateCarePlanRequest = {
  carePlan?: CarePlan
}

export type GetCarePlanRequest = {
  name?: string
}

export type GetCarePlanNoteInfoRequest = {
  name?: string
}

export type ListCarePlansRequest = {
  pageSize?: number
  pageToken?: string
  filter?: string
  orderBy?: string
}

export type SaveCarePlanRequest = {
  patient?: string
  noteId?: number
  carePlan?: CarePlanContent
}

export type MarkCarePlanApprovedRequest = {
  name?: string
  approvalDate?: GoogleTypeDatetime.DateTime
  ehrProviderActionNote?: string
}

export type DeleteCarePlanRequest = {
  name?: string
}

export type ListCarePlansResponse = {
  carePlans?: CarePlan[]
  nextPageToken?: string
  totalSize?: number
}

export type GetCarePlanPdfRequest = {
  name?: string
}

export type SendCarePlanRequest = {
  name?: string
}

export type OperationMetadata = {
  startTime?: GoogleProtobufTimestamp.Timestamp
  endTime?: GoogleProtobufTimestamp.Timestamp
}

export type CarePlanPdf = {
  pdfUri?: string
}

export type CarePlanSendStatus = {
  letterId?: string
  letterStatus?: GoPmsPkgPatient_outreachPatient_outreach.LobLetterLetterStatus
  sendDate?: GoogleTypeDatetime.DateTime
  expectedDeliveryDate?: GoogleTypeDate.Date
}

export type CarePlan = {
  name?: string
  patientId?: string
  noteId?: number
  approvalDate?: GoogleTypeDatetime.DateTime
  carePlan?: CarePlanContent
  state?: State
  sends?: GoPmsPkgPatient_outreachPatient_outreach.LobLetter[]
  createTime?: GoogleProtobufTimestamp.Timestamp
  updateTime?: GoogleProtobufTimestamp.Timestamp
  ehrProviderActionNote?: string
}

export type CarePlanNoteInfo = {
  name?: string
  patientId?: string
  noteId?: number
  noteEmrSyncTime?: GoogleProtobufTimestamp.Timestamp
  noteStatus?: GoPmsPkgNoteNote.NoteStatus
  noteEmrSyncStatus?: GoPmsPkgNoteNote.NoteNoteSyncStatus
  approvalDate?: GoogleTypeDatetime.DateTime
  carePlan?: CarePlanContent
  state?: State
  createTime?: GoogleProtobufTimestamp.Timestamp
  updateTime?: GoogleProtobufTimestamp.Timestamp
}

export type CarePlanNoteInfoForPdf = {
  name?: string
  patientId?: string
  noteId?: number
  noteEmrSyncTime?: GoogleTypeDatetime.DateTime
  noteStatus?: GoPmsPkgNoteNote.NoteStatus
  noteEmrSyncStatus?: GoPmsPkgNoteNote.NoteNoteSyncStatus
  approvalDate?: GoogleTypeDatetime.DateTime
  carePlan?: CarePlanContent
  state?: State
  createTime?: GoogleProtobufTimestamp.Timestamp
  updateTime?: GoogleProtobufTimestamp.Timestamp
}

export type CarePlanContent = {
  socialHistory?: GeneralAssessmentSocialHistory
  diet?: GeneralAssessmentDiet
  exercise?: string
  medsNotReviewed?: boolean
  medicationAllergies?: MedicationAllergy[]
  emergencyVisits?: EmergencyVisit[]
  goalsAndInterventions?: GoalsAndInterventions[]
  preventativeCare?: PreventativeCare[]
  psychosocialAssessments?: PsychosocialAssessment[]
  followUp?: FollowUp
  surgicalHistories?: SurgicalHistory[]
  specialists?: Specialist[]
  culturalPreferences?: string
  culturalCommunityResources?: string
  psychosocialAssessmentData?: PsychosocialAssessmentData
  dietNotes?: string
}

export type GeneralAssessmentSocialHistory = {
  tobaccoUse?: string
}

export type GeneralAssessmentDiet = {
  fastFood?: string
  snacks?: string
  desserts?: string
  regularSoda?: string
  sweetTea?: string
  waterIntake?: string
  saltIntake?: string
}

export type GeneralAssessment = {
  socialHistory?: GeneralAssessmentSocialHistory
  diet?: GeneralAssessmentDiet
  exercise?: string
}

export type MedicationAllergy = {
  medication?: string
  reaction?: string
}

export type SurgicalHistory = {
  diagnosis?: string
  details?: string
}

export type Specialist = {
  name?: string
  specialty?: string
  lastVisit?: string
  nextVisit?: string
}

export type EmergencyVisit = {
  hospital?: string
  date?: GoogleTypeDate.Date
  relatedDiagnosis?: string
}

export type GoalsAndInterventions = {
  condition?: Condition
  prognosis?: Prognosis
  treatmentGoal?: string
  healthGoal?: string
  actionSteps?: string
  confidence?: GoogleProtobufWrappers.Int32Value
  coordination?: string
}

export type PreventativeCare = {
  name?: string
  enabled?: boolean
  date?: string
  nextSteps?: string
  notApplicable?: boolean
  status?: PreventativeCareStatus
}

export type PsychosocialAssessment = {
  name?: string
  enabled?: boolean
  description?: string
}

export type PsychosocialAssessmentData = {
  exerciseDaysPerWeek?: number
  exerciseMinutesPerDay?: number
  hasHousingStabilityConcerns?: boolean
  housingStabilityDetail?: HousingStabilityDetail
  hasHousingConcerns?: boolean
  housingConcerns?: HousingConcern[]
  hasFoodSecurityConcerns?: boolean
  foodWorryFrequency?: FrequencyChoice
  foodRunOutFrequency?: FrequencyChoice
  hasTransportationConcerns?: boolean
  hasUtilityConcerns?: boolean
  utilityStatus?: UtilityStatus
  hasSafetyConcerns?: boolean
  physicalHarmFrequency?: SafetyFrequencyChoice
  threatFrequency?: SafetyFrequencyChoice
  verbalAbuseFrequency?: SafetyFrequencyChoice
  insultFrequency?: SafetyFrequencyChoice
}

export type FollowUp = {
  careAppointment?: string
  providerAppointment?: string
}

export type CarePlanPdfWorkerArgs = {
  carePlan?: CarePlanNoteInfoForPdf
  operation?: GoogleLongrunningOperations.Operation
  operationMetadata?: OperationMetadata
  medications?: Medication[]
  patientInfo?: CarePlanPdfPatientInfo
}

export type CarePlanLobWorkerArgs = {
  carePlan?: CarePlanNoteInfoForPdf
  operation?: GoogleLongrunningOperations.Operation
  operationMetadata?: OperationMetadata
  medications?: Medication[]
  patientInfo?: CarePlanPdfPatientInfo
}

export type Medication = {
  name?: string
  doseQuantities?: number[]
  rxnorm?: GoPmsPkgPatientMedicationMedication.RxNorm
  frequencies?: GoPmsPkgPatientMedicationMedication.MedicationChangeFrequency[]
  unstructuredSig?: string
}

export type DiagnosisCode = {
  code?: string
  codeDescription?: string
  category?: string
  etiology?: string
}

export type CarePlanPdfPatientInfo = {
  id?: string
  givenName?: string
  familyName?: string
  dob?: GoogleTypeDate.Date
  email?: string
  timeZone?: string
  diagnosisCodes?: {[key: string]: DiagnosisCode}
  mobilePhone?: string
  homePhone?: string
  emergencyPhone?: string
  emergencyContactName?: string
  referringProviderFirstName?: string
  referringProviderLastName?: string
  referringProviderRole?: string
  referringProviderStatus?: string
  careManagerFirstName?: string
  careManagerLastName?: string
  careManagerRole?: string
  accommodations?: GoPmsPkgPatientPms.Accommodation[]
  accommodationsOther?: string
  primaryLanguage?: string
}

export type CarePlanTemplateData = {
  patientInfo?: CarePlanPdfPatientInfo
  generalAssessment?: GeneralAssessment
  problemList?: string[]
  medsNotReviewed?: boolean
  medicationAllergies?: MedicationAllergy[]
  medications?: Medication[]
  emergencyVisits?: EmergencyVisit[]
  preventativeCare?: PreventativeCare[]
  psychosocialAssessment?: PsychosocialAssessment[]
  goalsAndInterventions?: GoalsAndInterventions[]
  surgicalHistories?: SurgicalHistory[]
  specialists?: Specialist[]
  followUp?: FollowUp
  carePlanApprovedTime?: GoogleTypeDatetime.DateTime
  noteEhrSyncTime?: GoogleTypeDatetime.DateTime
  currentTime?: GoogleTypeDatetime.DateTime
  culturalPreferences?: string
  culturalCommunityResources?: string
}

export class CarePlanService {
  static CreateCarePlan(req: CreateCarePlanRequest, initReq?: fm.InitReq): Promise<CarePlan> {
    return fm.fetchReq<CreateCarePlanRequest, CarePlan>(`/rpm/v1/carePlan`, {...initReq, method: "POST", body: JSON.stringify(req["care_plan"], fm.replacer)})
  }
  static GetCarePlan(req: GetCarePlanRequest, initReq?: fm.InitReq): Promise<CarePlan> {
    return fm.fetchReq<GetCarePlanRequest, CarePlan>(`/rpm/v1/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static GetCarePlanNoteInfo(req: GetCarePlanNoteInfoRequest, initReq?: fm.InitReq): Promise<CarePlanNoteInfo> {
    return fm.fetchReq<GetCarePlanNoteInfoRequest, CarePlanNoteInfo>(`/rpm/v1/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static ListCarePlans(req: ListCarePlansRequest, initReq?: fm.InitReq): Promise<ListCarePlansResponse> {
    return fm.fetchReq<ListCarePlansRequest, ListCarePlansResponse>(`/rpm/v1/carePlan?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static SaveCarePlan(req: SaveCarePlanRequest, initReq?: fm.InitReq): Promise<CarePlan> {
    return fm.fetchReq<SaveCarePlanRequest, CarePlan>(`/rpm/v1/${req["patient"]}:saveCarePlan`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static MarkCarePlanApproved(req: MarkCarePlanApprovedRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<MarkCarePlanApprovedRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/${req["name"]}:markCarePlanApproved`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static DeleteCarePlan(req: DeleteCarePlanRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<DeleteCarePlanRequest, GoogleProtobufEmpty.Empty>(`/rpm/v1/${req["name"]}`, {...initReq, method: "DELETE"})
  }
  static GenerateCarePlanPdf(req: GetCarePlanPdfRequest, initReq?: fm.InitReq): Promise<GoogleLongrunningOperations.Operation> {
    return fm.fetchReq<GetCarePlanPdfRequest, GoogleLongrunningOperations.Operation>(`/rpm/v1/${req["name"]}/pdf?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static GetCarePlanPdf(req: GoogleLongrunningOperations.GetOperationRequest, initReq?: fm.InitReq): Promise<CarePlanPdf> {
    return fm.fetchReq<GoogleLongrunningOperations.GetOperationRequest, CarePlanPdf>(`/rpm/v1/${req["name"]}/operation?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static SendCarePlan(req: SendCarePlanRequest, initReq?: fm.InitReq): Promise<GoogleLongrunningOperations.Operation> {
    return fm.fetchReq<SendCarePlanRequest, GoogleLongrunningOperations.Operation>(`/rpm/v1/${req["name"]}:send`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static GetCarePlanSendStatus(req: GoogleLongrunningOperations.GetOperationRequest, initReq?: fm.InitReq): Promise<CarePlanSendStatus> {
    return fm.fetchReq<GoogleLongrunningOperations.GetOperationRequest, CarePlanSendStatus>(`/rpm/v1/${req["name"]}/operation/send?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
}