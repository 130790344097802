import type { WidgetProps } from '@rjsf/core';
import type { ChangeEvent } from 'react';

import { FormControlLabel, Radio, RadioGroup } from '@/deprecated/mui';
import { logger } from '@/logger';
import { flexContainer } from '@/shared/jsStyle/flex.css';

import { formLabel, radioGroupGrid } from '../SchemaDrivenForm.css';
import { getOmitExtraDataOnChange } from '../getOmitExtraDataOnChange';

export function RadioWidget({
  onChange: rjsfOnChange,
  value,
  options,
  uiSchema: {
    enumLabels,
    'ui:hiddenOptions': hiddenOptions,
    'ui:grid': isGrid = false,
    'ui:defaultValue': defaultValue,
  },
}: WidgetProps) {
  const onChange = getOmitExtraDataOnChange(rjsfOnChange);
  const { enumOptions } = options;

  if (!Array.isArray(enumOptions)) {
    logger.error('Unexpected enumOptions data type');
    return null;
  }

  const castValueToOptionType = (val: string) => {
    const matchingOption = enumOptions.find((opt) => String(opt.value) === val);
    if (!matchingOption) return val;

    const optionType = typeof matchingOption.value;
    switch (optionType) {
      case 'boolean':
        return val === 'true';
      case 'number':
        return Number(val);
      default:
        return val;
    }
  };

  return (
    <RadioGroup
      className={isGrid ? radioGroupGrid : flexContainer.row}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        const castedValue = castValueToOptionType(event.target.value);
        onChange(castedValue);
      }}
      value={value ?? defaultValue ?? null}
    >
      {enumOptions
        .filter((option) => !hiddenOptions?.includes(option.value))
        .map((option) => (
          <FormControlLabel
            key={option.value}
            classes={{
              label: formLabel,
            }}
            control={<Radio />}
            label={enumLabels?.[option.value] ?? option.label}
            value={option.value}
          />
        ))}
    </RadioGroup>
  );
}
