import { GoalMeasure } from '../../../Goals/goals.types';
import type { AssessmentName, FormFields } from '../../formConfig';
import { FoodSecurity } from './FoodSecurity';
import { LivingSituationForm } from './LivingSituationForm';
import { PhysicalActivity } from './PhysicalActivity';
import { Safety } from './Safety';
import { TransportationSecurity } from './TransportationSecurity';
import { Utilities } from './Utilities';
import { FoodFrequencyChoice } from './foodSecurity.types';
import { hasSafetyGap } from './safety.utils';
import type { PsychosocialAssessmentComponentProps } from './shared.types';
import { TransportationSecurityChoice } from './transportationSecurity.types';
import { UtilityStatus } from './utilities.types';

type PsychosocialAssessmentOption = {
  name: string;
  label: string;
  component: React.ComponentType<PsychosocialAssessmentComponentProps>;
  hasGap: (fields: FormFields) => boolean;
};

export const PSYCHOSOCIAL_ASSESSMENT_OPTIONS: readonly PsychosocialAssessmentOption[] =
  [
    {
      name: 'livingSituation',
      // Using string so it can be used in the template
      label: 'Living Situation',
      component: LivingSituationForm,
      hasGap: (fields: FormFields) =>
        fields.housingStabilityDetail !== undefined ||
        (fields.housingConcerns?.length ?? 0) > 0,
    },
    {
      name: 'food',
      label: 'Food Security',
      component: FoodSecurity,
      hasGap: (fields: FormFields) =>
        fields.foodRunOutFrequency === FoodFrequencyChoice.Often ||
        fields.foodRunOutFrequency === FoodFrequencyChoice.Sometimes ||
        fields.foodWorryFrequency === FoodFrequencyChoice.Often ||
        fields.foodWorryFrequency === FoodFrequencyChoice.Sometimes,
    },
    {
      name: 'transportation',
      label: 'Transportation',
      component: TransportationSecurity,
      hasGap: (fields: FormFields) =>
        fields.transportationSecurityChoice ===
        TransportationSecurityChoice.Yes,
    },
    {
      name: 'utilities',
      label: 'Utilities',
      component: Utilities,
      hasGap: (fields: FormFields) =>
        fields.utilityStatus === UtilityStatus.AlreadyShutOff ||
        fields.utilityStatus === UtilityStatus.Threatened,
    },
    {
      name: 'safety',
      label: 'Safety',
      component: Safety,
      hasGap: hasSafetyGap,
    },
    {
      name: 'physicalActivity',
      label: 'Physical Activity',
      component: PhysicalActivity,
      hasGap: ({ exerciseDaysPerWeek, exerciseMinutesPerDay }: FormFields) => {
        if (exerciseDaysPerWeek === 0) {
          return true;
        }

        if (
          exerciseDaysPerWeek === undefined ||
          exerciseMinutesPerDay === undefined
        ) {
          return false;
        }

        return exerciseDaysPerWeek * exerciseMinutesPerDay < 150;
      },
    },
  ] as const;

export const SDOH_GOAL_MEASURE_MAP: Record<AssessmentName, GoalMeasure> = {
  food: GoalMeasure.Food,
  livingSituation: GoalMeasure.LivingSituation,
  physicalActivity: GoalMeasure.PhysicalActivity,
  safety: GoalMeasure.Safety,
  transportation: GoalMeasure.Transportation,
  utilities: GoalMeasure.Utilities,
};
