import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { PatientAlertStatusIcon } from '@/pages/patients/PatientListDashboard/PatientsTable/PatientAlertsStatusIcon';
import { useFlags } from '@/shared/hooks';
import type { VitalsAlert } from '@/shared/types/alert.types';
import type { RouteParam } from '@/shared/types/route.types';

import { useEncounterModuleInstances } from '../NoteEditor/hooks/useEncounterModuleInstances.hook';
import type { NoteFormValues } from '../NoteEditor/noteFormState';
import { useNoteEditorContext } from '../NoteEditorContext';
import { EditableNoteType } from '../Notes.types';
import { TwilioFlexNoteButton } from '../TwilioFlex';
import { ZendeskNoteButton } from '../Zendesk';
import { getEncounterTypeInstance } from '../utils/encounterTypeUtils';
import {
  actionContainer,
  alertNoteTitle,
  noteTitle,
  noteTitleContainer,
} from './RecentNotes.css';
import { RecentNotesButton } from './RecentNotesButton';

type Props = {
  noteFormValues: NoteFormValues;
};

export function RecentNotesHeader({ noteFormValues }: Props) {
  const {
    enableZendeskTicketsForEncounterNotes,
    showTwilioFlexModal,
    useTwilioForCalls,
    persistAlertResolutionOnAutosave,
  } = useFlags();
  const { patientId }: RouteParam = useParams();
  const { editingNote, contacts, caregivers } = useNoteEditorContext();
  const { encounterModuleInstances } = useEncounterModuleInstances();
  const hasEncounterType = Boolean(
    getEncounterTypeInstance(encounterModuleInstances)?.inputs
      .type_of_encounter,
  );

  return (
    <div className={noteTitleContainer}>
      {editingNote?.type === EditableNoteType.Alert &&
      persistAlertResolutionOnAutosave ? (
        <AlertResolutionHeader alert={editingNote.alert} />
      ) : (
        <h2 className={noteTitle}>
          <FormattedMessage defaultMessage="Encounter Note" />
        </h2>
      )}
      <div className={actionContainer}>
        <RecentNotesButton patientId={patientId} />
        {enableZendeskTicketsForEncounterNotes &&
          !(showTwilioFlexModal && useTwilioForCalls) && (
            <ZendeskNoteButton
              variant="icon"
              patientId={patientId}
              zendeskTicket={noteFormValues.zendeskTicket}
              noteId={editingNote?.note?.id}
              isDisabled={!hasEncounterType}
              disabledTooltipMessage={
                <FormattedMessage defaultMessage="Select encounter type to enable" />
              }
            />
          )}
        {enableZendeskTicketsForEncounterNotes &&
          showTwilioFlexModal &&
          useTwilioForCalls && (
            <TwilioFlexNoteButton
              patientId={patientId}
              variant="icon"
              noteId={editingNote?.note?.id}
              contacts={contacts}
              caregivers={caregivers}
            />
          )}
      </div>
    </div>
  );
}

export function AlertResolutionHeader({ alert }: { alert: VitalsAlert }) {
  const intl = useIntl();

  const alertDate = format(parseISO(alert.patient_day), 'MM/dd/yyyy');

  return (
    <div className={alertNoteTitle.container}>
      <PatientAlertStatusIcon alert={alert} />
      <div>
        <h2 className={noteTitle}>
          {intl.formatMessage({ defaultMessage: 'Alert Resolution Note' })}
        </h2>
        <div className={alertNoteTitle.subtitle}>
          {intl.formatMessage(
            { defaultMessage: 'Alerted on {alertDate}' },
            { alertDate },
          )}
        </div>
      </div>
    </div>
  );
}
