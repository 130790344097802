import { type ReactElement, useMemo, useRef, useState } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { GridItem } from '@/shared/common/Form/GridItem';
import type { BaseFieldProps } from '@/shared/common/Form/fields/BaseField';
import { useOnMount } from '@/shared/hooks/useOnMount';
import { usePatientDemographics } from '@/shared/hooks/usePatientDemographics';
import { Button } from '@/shared/tempo/atom/Button';
import { RequiredIndicator } from '@/shared/tempo/atom/Label';

import { ContextualGoalButton } from '../../Goals/ContextualGoalButton';
import type { Goal } from '../../Goals/goals.types';
import { type GoalMeasure } from '../../Goals/goals.types';
import {
  type FormFields,
  type PreventativeCareName,
  getPreventativeCareFieldNames,
} from '../formConfig';
import { notApplicableText } from './PreventativeCare.css';
import { PreventativeCareRowV2 } from './PreventativeCare/PreventativeCareRowV2';
import { PREVENTATIVE_CARE_GOAL_MEASURE_MAP } from './PreventativeCare/preventativeCareGoals';
import {
  sectionTitle,
  toggleRowHelp,
  toggleRowLabel,
} from './carePlanSections.css';
import {
  PREVENTATIVE_CARE_OPTIONS,
  type PreventativeCareOption,
} from './preventativeCareOptions';

type Props = {
  form: UseFormReturn<FormFields>;
  createGoalWithMeasure: (measure: GoalMeasure, description?: string) => void;
  patientId: string;
  shouldShowCarePlanV1Changes: boolean;
  onEditGoal: (goal: Goal) => void;
  carePlanId: string;
};

export function PreventativeCare({
  form,
  createGoalWithMeasure,
  patientId,
  shouldShowCarePlanV1Changes,
  onEditGoal,
  carePlanId,
}: Props) {
  const intl = useIntl();
  const [showHidden, setShowHidden] = useState(false);
  const demographics = usePatientDemographics(
    patientId,
    shouldShowCarePlanV1Changes,
  );

  const { applicableOptions, hiddenOptions } = useMemo(() => {
    if (!demographics || !shouldShowCarePlanV1Changes) {
      return {
        applicableOptions: [...PREVENTATIVE_CARE_OPTIONS],
        hiddenOptions: [] as PreventativeCareOption[],
      };
    }

    return [...PREVENTATIVE_CARE_OPTIONS].reduce(
      (acc, option) => {
        if (option.notApplicable?.(demographics)) {
          acc.hiddenOptions.push(option);
        } else {
          acc.applicableOptions.push(option);
        }
        return acc;
      },
      { applicableOptions: [], hiddenOptions: [] } as {
        applicableOptions: PreventativeCareOption[];
        hiddenOptions: PreventativeCareOption[];
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    demographics.age,
    demographics.gender,
    demographics.conditions,
    shouldShowCarePlanV1Changes,
  ]);

  return (
    <Form.Section
      title={
        shouldShowCarePlanV1Changes
          ? intl.formatMessage({
              defaultMessage: 'Preventative Care',
            })
          : intl.formatMessage({
              defaultMessage: 'Preventative Care (Enter Dates)',
            })
      }
      classes={{ title: sectionTitle }}
    >
      {applicableOptions.map((option) =>
        shouldShowCarePlanV1Changes ? (
          <PreventativeCareRowV2
            key={`${option.name}-visible`}
            name={option.name}
            label={option.label}
            help={option.help}
            createGoalWithMeasure={createGoalWithMeasure}
            form={form}
            type={option.type}
            onEditGoal={onEditGoal}
            carePlanId={carePlanId}
          />
        ) : (
          <PreventativeCareRow
            key={option.name}
            name={option.name}
            label={option.label}
            help={option.help}
            alwaysApplicable={option.alwaysApplicable}
            createGoalWithMeasure={createGoalWithMeasure}
            form={form}
            patientId={patientId}
          />
        ),
      )}

      {shouldShowCarePlanV1Changes && hiddenOptions.length > 0 && (
        <Button variant="tertiary" onPress={() => setShowHidden(!showHidden)}>
          {showHidden ? (
            <FormattedMessage defaultMessage="Hide not applicable options" />
          ) : (
            <FormattedMessage defaultMessage="Show not applicable options" />
          )}
        </Button>
      )}

      {shouldShowCarePlanV1Changes &&
        showHidden &&
        hiddenOptions.map((option) => (
          <PreventativeCareRowV2
            key={`${option.name}-hidden`}
            name={option.name}
            label={option.label}
            help={option.help}
            createGoalWithMeasure={createGoalWithMeasure}
            form={form}
            isNotApplicable
            type={option.type}
            onEditGoal={onEditGoal}
            carePlanId={carePlanId}
          />
        ))}
    </Form.Section>
  );
}

function PreventativeCareRow({
  name,
  label,
  help,
  form,
  alwaysApplicable,
  createGoalWithMeasure,
}: {
  name: PreventativeCareName;
  label: string;
  help?: ReactElement;
  alwaysApplicable: boolean;
  createGoalWithMeasure: (measure: GoalMeasure) => void;
} & Omit<Props, 'shouldShowCarePlanV1Changes' | 'onEditGoal' | 'carePlanId'>) {
  const intl = useIntl();
  const [rowWidth, setRowWidth] = useState(Number.POSITIVE_INFINITY);
  const {
    checked: checkedFieldName,
    date: dateFieldName,
    notApplicable: notApplicableFieldName,
  } = getPreventativeCareFieldNames(name);

  const checked = !!form.watch(checkedFieldName);

  const rowRef = useRef<Nullable<HTMLDivElement>>(null);
  useOnMount(() => {
    if (!rowRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setRowWidth(rowRef.current?.offsetWidth || Number.POSITIVE_INFINITY);
    });
    resizeObserver.observe(rowRef.current);
    // eslint-disable-next-line consistent-return
    return () => resizeObserver.disconnect();
  });

  const rowSize = getRowSize(rowWidth);
  const toggleLabelSize = useMemo(() => {
    if (alwaysApplicable) {
      return 7;
    }
    return rowSize === 'small' ? 4.8 : 5.3;
  }, [alwaysApplicable, rowSize]);

  const checkboxSize = useMemo(
    () => (rowSize === 'small' ? 2.2 : 1.7),
    [rowSize],
  );

  return (
    <div ref={rowRef}>
      <Form.Row>
        <GridItem size={toggleLabelSize}>
          <span>
            {label}
            {checked && <RequiredIndicator />}
          </span>
          {help && <span className={toggleRowHelp}>{help}</span>}
        </GridItem>
        {!alwaysApplicable && (
          <Form.Checkbox
            // In general, we don't want to support decimal sizes, but here we need it
            // in order to get the field size to work well.
            // (Need to account for the form when the CCM/APCM Care Plan Encounter is Open/Closed)
            size={checkboxSize as BaseFieldProps['size']}
            labelPlacement="start"
            label={
              <span className={notApplicableText}>
                {intl.formatMessage({ defaultMessage: 'NA' })}
              </span>
            }
            name={notApplicableFieldName}
            onChange={(isChecked) => {
              if (isChecked) {
                form.setValue(checkedFieldName, false);
                form.setValue(dateFieldName, '');
              }
            }}
          />
        )}
        <Form.Toggle
          label=""
          disabled={!!form.getValues(notApplicableFieldName)}
          aria-label={checkedFieldName}
          name={checkedFieldName}
          labelPlacement="start"
          labelClassName={toggleRowLabel}
          size={1}
          onChange={(isChecked) => {
            if (!isChecked) {
              form.setValue(dateFieldName, '');
            }
          }}
        />
        <Form.TextField
          size={3}
          name={dateFieldName}
          placeholder="MM / DD / YYYY"
          isDisabled={
            !form.getValues(checkedFieldName) ||
            !!form.getValues(notApplicableFieldName)
          }
        />
        <ContextualGoalButton
          type="form"
          onPress={() =>
            createGoalWithMeasure(PREVENTATIVE_CARE_GOAL_MEASURE_MAP[name])
          }
        />
      </Form.Row>
    </div>
  );
}

type RowSize = 'small' | 'medium';
function getRowSize(rowSize: number): RowSize {
  if (rowSize > 850) {
    return 'medium';
  }
  return 'small';
}
