/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as GoogleProtobufDuration from "../../../../google/protobuf/duration.pb"
import * as GoogleProtobufField_mask from "../../../../google/protobuf/field_mask.pb"
import * as GoogleProtobufTimestamp from "../../../../google/protobuf/timestamp.pb"
import * as GoogleProtobufWrappers from "../../../../google/protobuf/wrappers.pb"
import * as GoogleTypeTimeofday from "../../../../google/type/timeofday.pb"
import * as fm from "@/shared/generated/grpc/fetch.pb"

type Absent<T, K extends keyof T> = { [k in Exclude<keyof T, K>]?: undefined };
type OneOf<T> =
  | { [k in keyof T]?: undefined }
  | (
    keyof T extends infer K ?
      (K extends string & keyof T ? { [k in K]: T[K] } & Absent<T, K>
        : never)
    : never);

export enum Channel {
  CHANNEL_UNSPECIFIED = "CHANNEL_UNSPECIFIED",
  CHANNEL_SMS = "CHANNEL_SMS",
  CHANNEL_EMAIL = "CHANNEL_EMAIL",
  CHANNEL_DIRECT_MAIL = "CHANNEL_DIRECT_MAIL",
}

export enum DirectMailType {
  DIRECT_MAIL_TYPE_UNSPECIFIED = "DIRECT_MAIL_TYPE_UNSPECIFIED",
  DIRECT_MAIL_TYPE_POSTCARD = "DIRECT_MAIL_TYPE_POSTCARD",
  DIRECT_MAIL_TYPE_LETTER = "DIRECT_MAIL_TYPE_LETTER",
  DIRECT_MAIL_TYPE_SELF_MAILER = "DIRECT_MAIL_TYPE_SELF_MAILER",
}

export enum ProgramType {
  PROGRAM_TYPE_UNSPECIFIED = "PROGRAM_TYPE_UNSPECIFIED",
  PROGRAM_TYPE_RPM = "PROGRAM_TYPE_RPM",
  PROGRAM_TYPE_CCM = "PROGRAM_TYPE_CCM",
  PROGRAM_TYPE_APCM = "PROGRAM_TYPE_APCM",
}

export enum ConversationState {
  STATE_UNSPECIFIED = "STATE_UNSPECIFIED",
  STATE_OPEN = "STATE_OPEN",
  STATE_CLOSED = "STATE_CLOSED",
}

export enum MessageDirection {
  DIRECTION_UNSPECIFIED = "DIRECTION_UNSPECIFIED",
  INBOUND = "INBOUND",
  OUTBOUND = "OUTBOUND",
}

export enum MessageState {
  STATE_UNSPECIFIED = "STATE_UNSPECIFIED",
  STATE_QUEUED = "STATE_QUEUED",
  STATE_DELIVERED = "STATE_DELIVERED",
  STATE_DELIVERY_FAILED = "STATE_DELIVERY_FAILED",
  STATE_SENT = "STATE_SENT",
  STATE_ACCEPTED = "STATE_ACCEPTED",
}

export enum MessageMessageHeader {
  MESSAGE_HEADER_UNSPECIFIED = "MESSAGE_HEADER_UNSPECIFIED",
  MESSAGE_HEADER_EMAIL_TEMPLATE_ID = "MESSAGE_HEADER_EMAIL_TEMPLATE_ID",
  MESSAGE_HEADER_EMAIL_TEMPLATE_DATA = "MESSAGE_HEADER_EMAIL_TEMPLATE_DATA",
  MESSAGE_HEADER_EMAIL_UNSUBSCRIBE_GROUP_ID = "MESSAGE_HEADER_EMAIL_UNSUBSCRIBE_GROUP_ID",
  MESSAGE_HEADER_DIRECT_MAIL_TYPE = "MESSAGE_HEADER_DIRECT_MAIL_TYPE",
  MESSAGE_HEADER_DIRECT_MAIL_OUTSIDE_TEMPLATE_URL = "MESSAGE_HEADER_DIRECT_MAIL_OUTSIDE_TEMPLATE_URL",
  MESSAGE_HEADER_DIRECT_MAIL_INSIDE_TEMPLATE_URL = "MESSAGE_HEADER_DIRECT_MAIL_INSIDE_TEMPLATE_URL",
  MESSAGE_HEADER_PROGRAM_TYPE = "MESSAGE_HEADER_PROGRAM_TYPE",
}

export enum MessageRecipientType {
  RECIPIENT_TYPE_UNSPECIFIED = "RECIPIENT_TYPE_UNSPECIFIED",
  RECIPIENT_TYPE_PATIENT = "RECIPIENT_TYPE_PATIENT",
  RECIPIENT_TYPE_PROSPECTIVE_PATIENT = "RECIPIENT_TYPE_PROSPECTIVE_PATIENT",
  RECIPIENT_TYPE_CAREGIVER = "RECIPIENT_TYPE_CAREGIVER",
}

export type CreateConversationRequest = {
  conversation?: Conversation
}

export type CreateMessageRequest = {
  parent?: string
  message?: Message
}

export type ListMessagesRequest = {
  pageSize?: number
  pageToken?: string
  parent?: string
  filter?: string
  orderBy?: string
}

export type ListMessagesResponse = {
  messages?: Message[]
  nextPageToken?: string
  totalSize?: number
}

export type GetMessageRequest = {
  name?: string
  readMask?: GoogleProtobufField_mask.FieldMask
}

export type ListConversationsRequest = {
  pageSize?: number
  pageToken?: string
  filter?: string
  orderBy?: string
}

export type ListConversationsResponse = {
  conversations?: Conversation[]
  nextPageToken?: string
  totalSize?: number
}

export type Conversation = {
  name?: string
  ttlSeconds?: number
  createTime?: GoogleProtobufTimestamp.Timestamp
  updateTime?: GoogleProtobufTimestamp.Timestamp
  endTime?: GoogleProtobufTimestamp.Timestamp
  patientId?: string
  state?: ConversationState
  messageHistory?: Message[]
  owner?: string
  channel?: Channel
}

export type Message = {
  name?: string
  body?: string
  conversationId?: string
  patientId?: string
  contactId?: string
  direction?: MessageDirection
  state?: MessageState
  createTime?: GoogleProtobufTimestamp.Timestamp
  updateTime?: GoogleProtobufTimestamp.Timestamp
  parent?: string
  channel?: Channel
  messageHeaders?: {[key: string]: string}
  deduplicationKey?: string
  window?: Window
  recipientType?: MessageRecipientType
  senderId?: string
  caregiverId?: GoogleProtobufWrappers.StringValue
  deduplicationDuration?: GoogleProtobufDuration.Duration
  tags?: {[key: string]: string}
}


type BaseWindow = {
  timeZone?: string
}

export type Window = BaseWindow
  & OneOf<{ fixedWindow: FixedWindow }>

export type FixedWindow = {
  startTime?: GoogleTypeTimeofday.TimeOfDay
  endTime?: GoogleTypeTimeofday.TimeOfDay
}

export class MessageRouterService {
  static CreateConversation(req: CreateConversationRequest, initReq?: fm.InitReq): Promise<Conversation> {
    return fm.fetchReq<CreateConversationRequest, Conversation>(`/messaging/v1/conversations`, {...initReq, method: "POST", body: JSON.stringify(req["conversation"], fm.replacer)})
  }
  static ListConversations(req: ListConversationsRequest, initReq?: fm.InitReq): Promise<ListConversationsResponse> {
    return fm.fetchReq<ListConversationsRequest, ListConversationsResponse>(`/messaging/v1/conversations?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static CreateMessage(req: CreateMessageRequest, initReq?: fm.InitReq): Promise<Message> {
    return fm.fetchReq<CreateMessageRequest, Message>(`/messaging/v1/${req["parent"]}/messages`, {...initReq, method: "POST", body: JSON.stringify(req["message"], fm.replacer)})
  }
  static GetMessage(req: GetMessageRequest, initReq?: fm.InitReq): Promise<Message> {
    return fm.fetchReq<GetMessageRequest, Message>(`/messaging/v1/${req["name"]}?${fm.renderURLSearchParams(req, ["name"])}`, {...initReq, method: "GET"})
  }
  static ListMessages(req: ListMessagesRequest, initReq?: fm.InitReq): Promise<ListMessagesResponse> {
    return fm.fetchReq<ListMessagesRequest, ListMessagesResponse>(`/messaging/v1/${req["parent"]}/messages?${fm.renderURLSearchParams(req, ["parent"])}`, {...initReq, method: "GET"})
  }
}