import { FormattedMessage, useIntl } from 'react-intl';

import { Check } from '@/shared/assets/svgs';
import type { CCMGoal } from '@/shared/generated/grpc/go/pms/pkg/ccm_goal/ccm_goal.pb';
import { GoalStatus } from '@/shared/generated/grpc/go/pms/pkg/ccm_goal/ccm_goal.pb';
import { Button } from '@/shared/tempo/atom/Button';

import { ContextualGoalButton } from '../../../Goals/ContextualGoalButton';
import {
  declineButton,
  declinedStatus,
  statusButton,
} from './GoalStatusButton.css';

type Props = {
  existingGoal: Maybe<CCMGoal>;
  onPressEditGoal: (goal: CCMGoal) => void;
  onPressCreateGoal: () => void;
  onDecline?: () => void;
  carePlanId: string;
};

export function GoalStatusButton({
  existingGoal,
  onDecline,
  onPressEditGoal,
  onPressCreateGoal,
  carePlanId,
}: Props) {
  const intl = useIntl();
  const goalStatus = existingGoal?.status;

  let label = '';
  switch (goalStatus) {
    case GoalStatus.OPEN:
      label = intl.formatMessage({
        defaultMessage: 'Goal needs review',
      });
      break;
    case GoalStatus.IN_PROGRESS:
      label = intl.formatMessage({
        defaultMessage: 'Goal in progress',
      });
      break;
    case GoalStatus.DECLINED:
      label = intl.formatMessage({
        defaultMessage: 'Goal declined',
      });
      break;
    case GoalStatus.COMPLETE:
      if (existingGoal?.ccmCarePlanId === carePlanId) {
        label = intl.formatMessage({
          defaultMessage: 'Goal completed',
        });
      }
      break;
    case GoalStatus.ABANDONED:
      if (existingGoal?.ccmCarePlanId === carePlanId) {
        label = intl.formatMessage({
          defaultMessage: 'Goal abandoned',
        });
      }
      break;
    default:
  }

  let statusButtonClass: string | undefined = statusButton;
  if (goalStatus === GoalStatus.OPEN) {
    statusButtonClass = undefined;
  } else if (goalStatus === GoalStatus.DECLINED) {
    statusButtonClass = declinedStatus;
  }

  if (label && existingGoal) {
    return (
      <>
        {onDecline &&
          (goalStatus === GoalStatus.OPEN ||
            goalStatus === GoalStatus.IN_PROGRESS) && (
            <Button
              className={declineButton.container}
              variant="tertiary"
              size="small"
              onPress={onDecline}
            >
              <FormattedMessage defaultMessage="Decline" />
            </Button>
          )}
        <Button
          className={statusButtonClass}
          variant="secondary"
          size="small"
          onPress={() => onPressEditGoal(existingGoal)}
        >
          {label}
          {goalStatus === GoalStatus.IN_PROGRESS && (
            <Button.Icon>
              <Check width="16" height="16" />
            </Button.Icon>
          )}
        </Button>
      </>
    );
  }

  return <ContextualGoalButton type="default" onPress={onPressCreateGoal} />;
}
