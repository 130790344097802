import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Form } from '@/shared/common/Form';
import { CCMPrognosis } from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import type { CCMGoal } from '@/shared/generated/grpc/go/pms/pkg/ccm_goal/ccm_goal.pb';
import { useFlags } from '@/shared/hooks';
import type { CcmCondition } from '@/shared/types/clinicalprofile.types';

import { useExistingConditionGoals } from '../../../Goals/goalUtils';
import type { GoalMeasure } from '../../../Goals/goals.types';
import { type Goal } from '../../../Goals/goals.types';
import type { FormFields, GoalSetting } from '../../formConfig';
import {
  actionNeededFill,
  actionNeededLabel,
  actionNeededStroke,
  radioContainer,
  radioLabel,
  undeterminedLabel,
  upToDateFill,
  upToDateLabel,
  upToDateStroke,
} from '../../radioStyles.css';
import { useGoalMutations } from '../shared/goalHooks';
import { ActionSteps } from './ActionSteps';
import { ConditionPrognosisVitals } from './ConditionPrognosisVitals';
import { GoalSettingField } from './GoalSettingField';
import {
  additionalNotesField,
  notesField,
  patientConditionRow,
  patientConditionSection,
  patientConditionSectionContainer,
} from './GoalsAndInterventions.css';
import { PatientConditionGoal } from './PatientConditionGoal';
import { buildConditionReviewGoal } from './conditionGoalUtils';
import { removeConditionFromGoalDescription } from './goalDescriptionUtils';
import { buildDeclinedGoal, getRelatedGoalMeasure } from './goalUtils';
import { getDefaultGoalSetting } from './prognosisUtils';

type Props = {
  index: number;
  condition: CcmCondition;
  openCreateGoalModal: (measure: GoalMeasure, description?: string) => void;
  openEditGoalModal: (goal: Goal) => void;
  carePlanId: string;
};

export function PrognosisAndPlan({
  index,
  condition,
  openCreateGoalModal,
  openEditGoalModal,
  carePlanId,
}: Props) {
  const intl = useIntl();
  const form = useFormContext<FormFields>();
  const { carePlanOptimization } = useFlags();
  const { patientId } = useParams<{ patientId: string }>();
  const prognosis = form.watch(`goalsAndInterventions.${index}.prognosis`);

  const skipGoalSetting = form.watch(
    `goalsAndInterventions.${index}.skipGoalSetting`,
  );
  const goalSetting = form.watch(`goalsAndInterventions.${index}.goalSetting`);

  const showGoalSetting = carePlanOptimization
    ? goalSetting === true
    : skipGoalSetting === false;

  const healthGoalEnum = form.watch(
    `goalsAndInterventions.${index}.healthGoalEnum`,
  );

  const { closeGoal, createGoal, deleteGoalIfExists, updateGoalDescription } =
    useGoalMutations(patientId);

  // Get existing related goal measure and goal for this condition if any
  const relatedGoalMeasure = getRelatedGoalMeasure(condition);
  const {
    declinedGoal: existingDeclineGoal,
    reviewGoal: existingReviewGoal,
    nonReviewGoal: existingNonReviewGoal,
  } = useExistingConditionGoals(
    patientId,
    carePlanId,
    condition,
    relatedGoalMeasure,
  );

  const conditions = form.watch(`goalsAndInterventions`);

  // syncRelatedGoals  deletes/updates the related goals that no longer apply after the new goal setting value
  function syncRelatedGoals(newGoalSettingValue: GoalSetting) {
    switch (newGoalSettingValue) {
      case true:
        deleteGoalIfExists(existingDeclineGoal);
        deleteGoalIfExists(existingReviewGoal);
        break;
      case false:
        syncNonReviewGoal(existingNonReviewGoal);
        deleteGoalIfExists(existingReviewGoal);
        break;
      case 'notDiscussed':
        syncNonReviewGoal(existingNonReviewGoal);
        deleteGoalIfExists(existingDeclineGoal);
        break;
      default:
        break;
    }
  }

  // syncNonReviewGoal is a function that will update the description of a goal
  // if it is related to another condition, and otherwise delete it
  function syncNonReviewGoal(goal: Maybe<CCMGoal>) {
    if (!goal) {
      return;
    }

    const goalHasOtherRelatedCondition = conditions?.find(
      ({ condition: c }) =>
        c && c !== condition && getRelatedGoalMeasure(c) === relatedGoalMeasure,
    );

    if (goalHasOtherRelatedCondition) {
      // Goal is related to another condition, remove this condition's description
      const updatedDescription = removeConditionFromGoalDescription(
        goal,
        condition,
        intl,
      );
      if (updatedDescription === goal.description) {
        closeGoal(goal);
      } else {
        updateGoalDescription(goal, updatedDescription);
      }
      return;
    }

    closeGoal(goal);
  }

  return (
    <div className={patientConditionSection}>
      <div className={patientConditionSectionContainer}>
        <Form.Row rowSpacing={1}>
          <PrognosisField
            name={`goalsAndInterventions.${index}.prognosis`}
            index={index}
            syncRelatedGoals={syncRelatedGoals}
          />
        </Form.Row>
        <ConditionPrognosisVitals condition={condition} />
        <Form.Row rowSpacing={1} className={additionalNotesField}>
          <Form.TextField
            inputClass={notesField}
            required
            size={11}
            placeholder={intl.formatMessage({
              defaultMessage:
                'Please document additional prognosis notes including any overrides in the automatically detected prognosis',
            })}
            name={`goalsAndInterventions.${index}.prognosisNotes`}
          />
        </Form.Row>
      </div>

      {prognosis !== CCMPrognosis.CCM_PROGNOSIS_UNSPECIFIED && (
        <GoalSettingField
          index={index}
          prognosis={prognosis}
          onInputChange={(value) => {
            syncRelatedGoals(value);

            // If user answers "No" to goal setting, create a declined goal
            if (value === false && !existingDeclineGoal) {
              const declinedGoal = buildDeclinedGoal(condition, intl);
              createGoal(declinedGoal, carePlanId);
            }

            // If user answers "Not Discussed", create a review goal
            if (value === 'notDiscussed' && !existingReviewGoal) {
              const newReviewGoal = buildConditionReviewGoal(condition, intl);
              createGoal(newReviewGoal, carePlanId);
            }
          }}
        />
      )}

      {prognosis && (
        <PatientConditionGoal
          index={index}
          prognosis={prognosis}
          condition={condition}
          showGoalSetting={showGoalSetting}
        />
      )}

      {showGoalSetting &&
        prognosis &&
        prognosis !== CCMPrognosis.CCM_PROGNOSIS_UNSPECIFIED && (
          <>
            <Form.Row rowSpacing={1} className={patientConditionRow}>
              <ActionSteps
                healthGoalEnum={healthGoalEnum}
                condition={condition}
                index={index}
                openCreateGoalModal={openCreateGoalModal}
                openEditGoalModal={openEditGoalModal}
                carePlanId={carePlanId}
                relatedGoalMeasure={relatedGoalMeasure}
                existingGoal={existingNonReviewGoal}
              />
            </Form.Row>
            {/* Remove "Coordination of Care/Resources" with new Care Plan Optimization changes */}
            {!carePlanOptimization && (
              <Form.Row rowSpacing={1} className={patientConditionRow}>
                <Form.TextArea
                  required
                  size={11}
                  rows={2}
                  label={intl.formatMessage({
                    defaultMessage: 'Coordination of Care/Resources',
                  })}
                  name={`goalsAndInterventions.${index}.coordination`}
                />
              </Form.Row>
            )}
          </>
        )}
    </div>
  );
}

function PrognosisField({
  name,
  index,
  syncRelatedGoals,
}: {
  name: string;
  index: number;
  syncRelatedGoals: (newGoalSettingValue: GoalSetting) => void;
}) {
  const { carePlanOptimization } = useFlags();
  const intl = useIntl();
  const form = useFormContext<FormFields>();
  const actionSteps = form.watch(`goalsAndInterventions.${index}.actionSteps`);

  return (
    <Form.RadioGroup
      name={name}
      label={intl.formatMessage({ defaultMessage: 'Prognosis' })}
      orientation="horizontal"
      size={12}
      classes={{ root: radioContainer }}
      required
      onInputChange={(value: CCMPrognosis) => {
        const defaultGoalSetting = getDefaultGoalSetting(value);

        if (!defaultGoalSetting && actionSteps) {
          // Don't turn off goal setting if action steps field is already filled out
          return;
        }

        if (carePlanOptimization) {
          form.setValue(
            `goalsAndInterventions.${index}.goalSetting`,
            defaultGoalSetting,
          );
          syncRelatedGoals(defaultGoalSetting);
        } else {
          form.setValue(
            `goalsAndInterventions.${index}.skipGoalSetting`,
            !defaultGoalSetting,
          );
        }
      }}
    >
      <Form.Radio
        name={name}
        value={CCMPrognosis.CCM_PROGNOSIS_UNSPECIFIED}
        classes={{
          label: undeterminedLabel,
        }}
      >
        <FormattedMessage defaultMessage="Undetermined" />
      </Form.Radio>
      <Form.Radio
        name={name}
        value={CCMPrognosis.FAIR}
        classes={{
          label: radioLabel,
          selectedLabel: actionNeededLabel,
          selectedDot: actionNeededFill,
          selectedCircle: actionNeededStroke,
        }}
      >
        <FormattedMessage defaultMessage="Suboptimal" />
      </Form.Radio>

      <Form.Radio
        name={name}
        value={CCMPrognosis.GOOD}
        classes={{
          label: radioLabel,
          selectedLabel: upToDateLabel,
          selectedDot: upToDateFill,
          selectedCircle: upToDateStroke,
        }}
      >
        <FormattedMessage defaultMessage="Well-controlled" />
      </Form.Radio>
    </Form.RadioGroup>
  );
}
