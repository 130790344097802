import cx from 'classnames';
import type { ReactNode } from 'react';

import { footer, titleClass } from './FloatingFooter.css';

type Props = {
  title?: string;
  children: ReactNode;
  className?: string;
};

export function FloatingFooter({ children, title, className }: Props) {
  return (
    <footer className={cx(footer, className)}>
      {title && <div className={titleClass}>{title}</div>}
      {children}
    </footer>
  );
}
