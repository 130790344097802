import { useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import { CCMPrognosis } from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import { useFlags } from '@/shared/hooks';

import { type GoalSetting } from '../../formConfig';
import { goalSettingToggle } from './GoalsAndInterventions.css';

type Props = {
  index: number;
  prognosis: Maybe<CCMPrognosis>;
  onInputChange: (value: GoalSetting) => void;
};

export function GoalSettingField({ index, prognosis, onInputChange }: Props) {
  const { carePlanOptimization } = useFlags();
  const intl = useIntl();

  if (!carePlanOptimization) {
    return (
      <Form.Toggle
        classes={{ root: goalSettingToggle }}
        label={intl.formatMessage({
          defaultMessage: 'Set condition goals',
        })}
        name={`goalsAndInterventions.${index}.skipGoalSetting`}
        shouldInvertValue
      />
    );
  }

  if (prognosis === CCMPrognosis.GOOD) {
    return (
      <Form.Toggle
        classes={{ root: goalSettingToggle }}
        label={intl.formatMessage({
          defaultMessage: 'Set condition goals',
        })}
        name={`goalsAndInterventions.${index}.goalSetting`}
      />
    );
  }

  return (
    <Form.RadioGroup
      size={11}
      name={`goalsAndInterventions.${index}.goalSetting`}
      label={intl.formatMessage({
        defaultMessage:
          'Does patient want to manage this condition with Cadence?',
      })}
      valueMap={{
        yes: true,
        no: false,
        notDiscussed: 'notDiscussed', // Transforming this to null in data transformation step
      }}
      orientation="horizontal"
      onInputChange={onInputChange}
      required
    >
      <Form.Radio value="yes">
        {intl.formatMessage({ defaultMessage: 'Yes' })}
      </Form.Radio>
      <Form.Radio value="no">
        {intl.formatMessage({ defaultMessage: 'No' })}
      </Form.Radio>
      <Form.Radio value="notDiscussed">
        {intl.formatMessage({ defaultMessage: 'Not discussed' })}
      </Form.Radio>
    </Form.RadioGroup>
  );
}
