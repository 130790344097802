import cx from 'classnames';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Form } from '@/shared/common/Form';
import {
  TobaccoType,
  TobaccoUserStatus,
} from '@/shared/generated/grpc/go/pms/pkg/care_plan/care_plan.pb';
import type { CCMGoal } from '@/shared/generated/grpc/go/pms/pkg/ccm_goal/ccm_goal.pb';
import { Label } from '@/shared/tempo/atom/Label';

import { container } from '../../Goals/ContextualGoalButton.css';
import { hasActiveStatus, useExistingMeasureGoal } from '../../Goals/goalUtils';
import { GoalMeasure, GoalStatus } from '../../Goals/goals.types';
import {
  getInitialTobaccoGoal,
  getTobaccoUseDescription,
} from '../../Goals/tobaccoUtils';
import type { FormFields } from '../formConfig';
import {
  getTobaccoTypes,
  getTobaccoUserStatuses,
} from './TobaccoUse/tobaccoUtils';
import { subLabel } from './carePlanSections.css';
import { GoalStatusButton } from './shared/GoalStatusButton';
import { statusContainer } from './shared/GoalStatusButton.css';
import { useGoalMutations } from './shared/goalHooks';

type Props = {
  openCreateGoalModal: (measure: GoalMeasure, description?: string) => void;
  openEditGoalModal: (goal: CCMGoal) => void;
  carePlanId: string;
};

export function TobaccoUse({
  openCreateGoalModal,
  openEditGoalModal,
  carePlanId,
}: Props) {
  const intl = useIntl();
  const form = useFormContext<FormFields>();
  const tobaccoUserStatus = form.watch('tobaccoUserStatus');
  const tobaccoTypes = form.watch('tobaccoTypes');

  const { patientId } = useParams<{ patientId: string }>();
  const existingGoal = useExistingMeasureGoal(
    patientId,
    carePlanId,
    GoalMeasure.Tobacco,
  );

  const {
    declineGoal,
    updateGoalDescription,
    createGoal,
    closeGoal,
    updateGoalStatus,
  } = useGoalMutations(patientId);

  function handleTobaccoUserStatusChange(value: TobaccoUserStatus) {
    // If changing status to current tobacco user, if there's an existing goal
    // make sure the status is In Progress, otherwise create a new goal
    if (value === TobaccoUserStatus.CURRENT) {
      if (existingGoal) {
        updateGoalStatus(existingGoal, GoalStatus.IN_PROGRESS);
      } else {
        const tobaccoGoal = getInitialTobaccoGoal(
          {
            tobaccoUserStatus: value,
            smokingQuantity: form.getValues('smokingQuantity'),
            smokingYears: form.getValues('smokingYears'),
            tobaccoUse: form.getValues('tobaccoUse'),
          },
          intl,
        );
        if (tobaccoGoal) {
          createGoal(tobaccoGoal, carePlanId);
        }
      }
      return;
    }

    // Otherwise, if changing status to not current tobacco user, close any
    // existing active tobacco goals
    if (existingGoal && hasActiveStatus(existingGoal)) {
      closeGoal(existingGoal);
    }
  }

  return (
    <>
      <Form.Row rowSpacing={1}>
        <Form.RadioGroup
          size={11}
          label={intl.formatMessage({
            defaultMessage: 'Do you or have you ever used tobacco?',
          })}
          name="tobaccoUserStatus"
          required
          orientation="horizontal"
          onInputChange={handleTobaccoUserStatusChange}
        >
          {getTobaccoUserStatuses(intl).map((status) => (
            <Form.Radio key={status.value} value={status.value}>
              {status.label}
            </Form.Radio>
          ))}
        </Form.RadioGroup>
      </Form.Row>

      {(tobaccoUserStatus === TobaccoUserStatus.FORMER ||
        tobaccoUserStatus === TobaccoUserStatus.CURRENT) && (
        <>
          <Form.Row rowSpacing={1}>
            <Form.CheckboxGroup
              size={11}
              label={intl.formatMessage({
                defaultMessage: 'Types of tobacco used:',
              })}
              name="tobaccoTypes"
              options={getTobaccoTypes(intl)}
              compact
            />
          </Form.Row>

          {tobaccoTypes?.includes(TobaccoType.SMOKING) && (
            <>
              <Label
                label={
                  tobaccoUserStatus === TobaccoUserStatus.FORMER ? (
                    <FormattedMessage defaultMessage="How much and for how long did you smoke?" />
                  ) : (
                    <FormattedMessage defaultMessage="How much and for how long have you been smoking?" />
                  )
                }
              />
              <Form.Row rowSpacing={1}>
                <Form.TextField
                  size={5}
                  name="smokingQuantity"
                  endAdornmentText={intl.formatMessage({
                    defaultMessage: 'pack(s) per day',
                  })}
                  classes={{
                    labelInput: subLabel,
                  }}
                />
                <Form.TextField
                  size={6}
                  name="smokingYears"
                  inputType="number"
                  classes={{
                    labelInput: subLabel,
                  }}
                  startAdornmentText={<FormattedMessage defaultMessage="for" />}
                  endAdornmentText={intl.formatMessage({
                    defaultMessage: 'years',
                  })}
                />
              </Form.Row>
            </>
          )}

          <Form.Row rowSpacing={1}>
            <Form.TextArea
              size={11}
              label={<FormattedMessage defaultMessage="Notes" />}
              name="tobaccoUse"
              rows={2}
              onBlur={() => {
                if (existingGoal) {
                  const newDescription = getTobaccoUseDescription(
                    form.getValues('tobaccoTypes'),
                    form.getValues('smokingQuantity'),
                    form.getValues('smokingYears'),
                    form.getValues('tobaccoUse'),
                    intl,
                  );
                  if (existingGoal.status === GoalStatus.OPEN) {
                    updateGoalDescription(
                      {
                        ...existingGoal,
                        status: GoalStatus.IN_PROGRESS,
                      },
                      newDescription,
                    );
                  } else {
                    updateGoalDescription(existingGoal, newDescription);
                  }
                }
              }}
            />
          </Form.Row>

          <div className={cx(container.form, statusContainer)}>
            <GoalStatusButton
              carePlanId={carePlanId}
              existingGoal={existingGoal}
              onDecline={
                existingGoal ? () => declineGoal(existingGoal) : undefined
              }
              onPressCreateGoal={() => openCreateGoalModal(GoalMeasure.Tobacco)}
              onPressEditGoal={openEditGoalModal}
            />
          </div>
        </>
      )}
    </>
  );
}
