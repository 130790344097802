import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  FieldGroup,
  YesNoRadioGroup,
} from '@/pages/patients/PatientProfile/CNNotesSidebarPanel/shared';
import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { isUnscheduledVisit } from '@/pages/patients/patientDetails/ui/Notes/utils/encounterTypeUtils';
import { Form } from '@/shared/common/Form';
import type { ConfiguredForm } from '@/shared/common/Form/FormContainer';
import { Wizard } from '@/shared/common/Wizard';
import { useFlags } from '@/shared/hooks';

import { useCNFormContext } from '../../../CNFormContext';
import type { CNSectionMap } from '../../metadata';
import { INIT_VISIT_SECTIONS } from '../../metadata';
import { CallButton } from '../CallButton';
import { TimeTrackingButton } from '../TimeTrackingButton';
import { useAppointmentCancellationTypeI18n } from './appointmentCancellationTypeI18n';
import type { IntroFormFields } from './formConfig';
import { AppointmentCancellationType as CancelType } from './formConfig';

type Props = {
  form: ConfiguredForm<IntroFormFields>;
  nextPath: string;
  timeTrackingPath: string;
  sections: CNSectionMap;
};

export function SharedIntroPatientAttendance({
  form,
  nextPath,
  timeTrackingPath,
}: Props) {
  const intl = useIntl();
  const history = useHistory();
  const { noShow } = useSectionVisibility(form);
  const { cnVisitType } = useCNFormContext();
  const cancellationI18n = useAppointmentCancellationTypeI18n();
  const { captureClinicalObserverDetailsInCnNotes } = useFlags();
  const shouldDisplayClinicalObserverInfo =
    captureClinicalObserverDetailsInCnNotes &&
    cnVisitType !== TypeOfEncounter.PATIENT_FOLLOW_UP;

  return (
    <Wizard.Step
      sections={INIT_VISIT_SECTIONS}
      title={intl.formatMessage({ defaultMessage: 'Introduction' })}
      form={form}
      onNext={(values) => {
        if (values.patientAttendance === 'true') {
          history.push(nextPath);
        }
      }}
      submitButton={
        !!noShow &&
        (({ ...submitBtnProps }) => (
          <TimeTrackingButton
            {...submitBtnProps}
            submitPath={timeTrackingPath}
          />
        ))
      }
    >
      <FieldGroup>
        <Form.TextField
          size={12}
          name="languageInterpreter"
          label={
            <FormattedMessage defaultMessage="Language interpreter name and phone number" />
          }
          placeholder={intl.formatMessage({
            defaultMessage:
              'Provide the name and phone number if an interpreter is used in this visit',
          })}
        />
        <CallButton />
        <YesNoRadioGroup
          name="patientAttendance"
          label={
            <FormattedMessage defaultMessage="Did you speak with either the patient or a designated caregiver?" />
          }
        />
        <Form.TextField
          size={12}
          name="nonPatientInfo"
          label={
            <FormattedMessage defaultMessage="If you spoke with a caregiver on the patient's behalf, enter their name and role below" />
          }
          placeholder={intl.formatMessage({
            defaultMessage: "Jane Smith, patient's caregiver",
          })}
        />
        {shouldDisplayClinicalObserverInfo && (
          <Form.TextField
            size={12}
            name="clinicalObserverInfo"
            label={
              <FormattedMessage defaultMessage="If a clinical observer is shadowing the call, document patient consent below" />
            }
          />
        )}
        {noShow && (
          <>
            <Form.RadioGroup
              required
              size={12}
              name="cancellationReason"
              label={
                <FormattedMessage defaultMessage="Select the appointment cancellation type" />
              }
              orientation="vertical"
            >
              {isUnscheduledVisit(cnVisitType) ? (
                <>
                  <Form.Radio value={CancelType.UnableToReachPatient}>
                    {cancellationI18n[CancelType.UnableToReachPatient]}
                  </Form.Radio>
                  <Form.Radio value={CancelType.PatientRequestedCallback}>
                    {cancellationI18n[CancelType.PatientRequestedCallback]}
                  </Form.Radio>
                  <Form.Radio value={CancelType.Disenrollment}>
                    {cancellationI18n[CancelType.Disenrollment]}
                  </Form.Radio>
                </>
              ) : (
                <>
                  <Form.Radio value={CancelType.NoShow}>
                    {cancellationI18n[CancelType.NoShow]}
                  </Form.Radio>
                  <Form.Radio value={CancelType.PatientRequestReschedule}>
                    {cancellationI18n[CancelType.PatientRequestReschedule]}
                  </Form.Radio>
                  <Form.Radio value={CancelType.SchedulingErrorDisenrollment}>
                    {cancellationI18n[CancelType.SchedulingErrorDisenrollment]}
                  </Form.Radio>
                </>
              )}
              <Form.Radio value={CancelType.PatientHospitalized}>
                {cancellationI18n[CancelType.PatientHospitalized]}
              </Form.Radio>
            </Form.RadioGroup>
            <Form.TextArea
              size={12}
              rows={4}
              label="Additional information"
              name="additionalCancellationInfo"
            />
          </>
        )}
      </FieldGroup>
    </Wizard.Step>
  );
}

function useSectionVisibility(form: ConfiguredForm<IntroFormFields>) {
  const [patientAttendance] = form.watch(['patientAttendance']);

  return {
    noShow: patientAttendance === 'false',
  };
}
