import { addWeeks, isValid, startOfDay, subWeeks } from 'date-fns';
import {
  QueryClient,
  type UseMutationOptions,
  useMutation,
  useQuery,
} from 'react-query';

import { logger } from '@/logger';
import { conditionsToProgram } from '@/pages/patients/patientDetails/ui/Notes/NoteEditor/templates/hooks';
import { CACHE_TIME, grpcQueryFunction } from '@/reactQuery';
import type { QueryOptions } from '@/reactQuery/types';
import { ProgramType } from '@/shared/generated/grpc/cadence/models/models.pb';
import type {
  Appointment,
  AppointmentDetails,
  CheckPatientAppointmentAvailabilityResponse,
  CheckSameSlotAppointmentAvailabilityRequest,
  CheckSameSlotAppointmentAvailabilityResponse,
  Link,
  ListAllAppointmentsResponse,
  ListAppointmentTypesResponse,
  ListPatientAppointmentsResponse,
  NextAppointmentRecommendation,
  RescheduleAppointmentRequest,
} from '@/shared/generated/grpc/go/pms/pkg/scheduling/scheduling.pb';
import {
  AppointmentState,
  LinkType,
  SchedulingService,
} from '@/shared/generated/grpc/go/pms/pkg/scheduling/scheduling.pb';
import { useFlags } from '@/shared/hooks';
import { usePatientDetails } from '@/shared/hooks/queries';
import { buildAppointmentRPCRequest } from '@/shared/hooks/queries/appointment-grpc';
import { APPOINTMENT_QUERY_PARAMS_FILTER_LOOKUP } from '@/shared/hooks/queries/appointment-grpc/appointment-helpers';
import { getRpmConditionsFromProgramAndStatus } from '@/shared/patient/conditions.utils';
import { ConditionProgram } from '@/shared/types/condition.types';
import { idToGrpcName } from '@/shared/utils/grpc';

export type AppointmentParams = {
  apptStartTimeFrom?: string;
  apptStartTimeTo?: string;
  careProviderId?: string;
  patientId?: string;
};

const POLLING_INTERVAL = 60000;

export const appointmentKeys = {
  nextScheduled: (patientId: string) =>
    ['patient', patientId, 'nextScheduledVisit'] as const,
  nextRecommended: (
    patientId: string,
    programType?: ProgramType,
    forceExistingAppointmentRecommendation?: boolean,
  ) =>
    [
      'patient',
      patientId,
      'nextRecommendedAppointment',
      programType?.toString() ?? ProgramType.PROGRAM_TYPE_UNSPECIFIED,
      forceExistingAppointmentRecommendation ? 'true' : 'false',
    ] as const,
  getAppointment: (appointmentId: string) =>
    ['appointment', appointmentId] as const,
  list: (params?: AppointmentParams) =>
    ['patient', { ...params }, 'infinite'] as const,
  createLink: (patientId: string) =>
    ['patient', patientId, 'createLink'] as const,
  listAppointmentTypes: ['appointmentTypes'] as const,
};

export function useListAppointments(
  params?: AppointmentParams,
  enabled: boolean = true,
) {
  return useQuery(
    [...appointmentKeys.list(params)],
    (ctx) =>
      grpcQueryFunction<ListAllAppointmentsResponse>(
        ctx,
        SchedulingService.ListAllAppointments,
        buildAppointmentRPCRequest(ctx, APPOINTMENT_QUERY_PARAMS_FILTER_LOOKUP),
      ),
    {
      enabled,
      refetchInterval: POLLING_INTERVAL,
    },
  );
}

export function useGetNextScheduledAppointment(
  patientId: string,
  enabled: boolean,
) {
  return useQuery(
    [...appointmentKeys.nextScheduled(patientId)],
    () => SchedulingService.GetNextAppointment({ parent: patientId }),
    { enabled },
  );
}

export function useGetNextRecommendedAppointment(
  patientId: string,
  programType: ProgramType,
  forceExistingAppointmentRecommendation: boolean,
  config: QueryOptions<NextAppointmentRecommendation> = {},
) {
  // Extracting flags and patient details to determine if we should use the CHF appointment logic
  const { recommendCnVisitsForStableChf } = useFlags();
  const { data: patientData, isSuccess: isPatientDetailsSuccess } =
    usePatientDetails(patientId, true);
  const rpmConditions = getRpmConditionsFromProgramAndStatus(
    patientData?.programs,
    patientData?.patient?.status,
  );
  const program = conditionsToProgram(rpmConditions);
  const isEligibleForChfCn =
    recommendCnVisitsForStableChf && program === ConditionProgram.CHF;

  return useQuery(
    [
      ...appointmentKeys.nextRecommended(
        patientId,
        programType,
        forceExistingAppointmentRecommendation,
      ),
    ],
    () => {
      if (isEligibleForChfCn) {
        return SchedulingService.RecommendNextCHFAppointment({
          parent: patientId,
          forceExistingAppointment: forceExistingAppointmentRecommendation,
          programType,
        });
      }
      return SchedulingService.RecommendNextAppointment({
        parent: patientId,
        forceExistingAppointment: forceExistingAppointmentRecommendation,
        programType,
      });
    },
    {
      ...config,
      enabled:
        (config.enabled ?? true) &&
        Boolean(patientId) &&
        isPatientDetailsSuccess,
    },
  );
}

/**
 * Used to retrieve the appointments that are most likely to be associated with
 * the given encounter date
 */
export function useGetSuggestedEncounterAppointments(
  patientId: string,
  encounterDate: Date,
  config: QueryOptions<ListPatientAppointmentsResponse> = {},
) {
  const result = useQuery(
    ['patient', patientId, 'suggestedEncounterAppointments'],
    () => {
      const weekAhead = addWeeks(encounterDate, 1).toISOString();
      const weekBefore = subWeeks(encounterDate, 1).toISOString();
      return SchedulingService.ListPatientAppointments({
        parent: patientId,
        pageSize: 10,
        filter: `startTime > '${weekBefore}' AND startTime < '${weekAhead}' AND state != '${AppointmentState.CANCELLED}'`,
      });
    },
    {
      ...config,
      enabled:
        (config.enabled ?? true) &&
        Boolean(patientId) &&
        isValid(encounterDate),
    },
  );

  return {
    ...result,
    suggestedAppointments: result.data?.appointments || [],
  };
}

export function usePatientAppointments(
  patientId: string,
  config: QueryOptions<ListPatientAppointmentsResponse> = {},
) {
  return useQuery(
    ['patient', patientId, 'patientAppointments'],
    () =>
      SchedulingService.ListPatientAppointments({
        parent: patientId,
        filter: `startTime > '${startOfDay(
          new Date(),
        ).toISOString()}' AND state != '${AppointmentState.CANCELLED}'`,
      }),
    {
      ...config,
      enabled: (config.enabled ?? true) && Boolean(patientId),
    },
  );
}

export function useGetAppointment(
  appointmentId: Maybe<string>,
  config: QueryOptions<Appointment> = {},
) {
  return useQuery(
    [...appointmentKeys.getAppointment(appointmentId || '')],
    () =>
      SchedulingService.GetAppointment({
        name: idToGrpcName('appointments', appointmentId || ''),
      }),
    {
      ...config,
      enabled: (config.enabled ?? true) && Boolean(appointmentId),
    },
  );
}

export function useRescheduleAppointment(
  appointmentId: string,
  config: Omit<
    UseMutationOptions<
      AppointmentDetails,
      unknown,
      RescheduleAppointmentRequest,
      unknown
    >,
    'mutationFn'
  > = {},
) {
  return useMutation((payload: RescheduleAppointmentRequest) => {
    // Since we just changed the clinician to search, we need to clear the cache
    // for the availability queries
    AVAILABILITIES_QUERY_CLIENT.clear();

    return SchedulingService.RescheduleAppointment({
      name: appointmentId,
      type: payload.type,
      apptDate: payload.apptDate,
      acuityAppointmentId: payload.acuityAppointmentId,
      acuityCalendarId: payload.acuityCalendarId,
      sameSlotFailureMode: payload.sameSlotFailureMode,
      careProviderId: payload.careProviderId,
    });
  }, config);
}

type CheckAvailabilityPayload = {
  apptDate: Date;
  acuityAppointmentTypeId: number;
  recommendedApptDate: Date;
  appointmentId?: string;
  restrictCalendars?: boolean;
};
const AVAILABILITIES_QUERY_CLIENT = new QueryClient({
  defaultOptions: { queries: { cacheTime: CACHE_TIME.ONE_MINUTE } },
});
export function useCheckPatientAppointmentAvailability(
  patientId: string,
  config: Omit<
    UseMutationOptions<
      CheckPatientAppointmentAvailabilityResponse,
      unknown,
      CheckAvailabilityPayload,
      unknown
    >,
    'mutationFn'
  > = {},
) {
  return useMutation(async (payload: CheckAvailabilityPayload) => {
    const queryKey = [
      'patientAppointmentAvailability',
      patientId,
      payload.acuityAppointmentTypeId,
      payload.apptDate,
      payload.recommendedApptDate,
      payload.appointmentId,
      payload.restrictCalendars,
    ] as const;
    const cachedData =
      AVAILABILITIES_QUERY_CLIENT.getQueryData<CheckPatientAppointmentAvailabilityResponse>(
        queryKey,
      );

    if (cachedData) {
      logger.debug(
        'Returning patient appointment availabilities data from cache',
        { cacheKey: queryKey },
      );
      return cachedData;
    }

    const data = await SchedulingService.CheckPatientAppointmentAvailability({
      acuityAppointmentTypeId: payload.acuityAppointmentTypeId,
      apptDate: payload.apptDate.toISOString(),
      recommendedApptDate: payload.recommendedApptDate.toISOString(),
      appointmentId: payload.appointmentId,
      restrictCalendars: payload.restrictCalendars,
      parent: patientId,
    });
    AVAILABILITIES_QUERY_CLIENT.setQueryData(queryKey, data);
    return data;
  }, config);
}

export function useCheckSameSlotAppointmentAvailability(
  config: Omit<
    UseMutationOptions<
      CheckSameSlotAppointmentAvailabilityResponse,
      unknown,
      CheckSameSlotAppointmentAvailabilityRequest,
      unknown
    >,
    'mutationFn'
  > = {},
) {
  return useMutation(
    (payload: CheckSameSlotAppointmentAvailabilityRequest) =>
      SchedulingService.CheckSameSlotAppointmentAvailability({
        name: payload.name,
      }),
    config,
  );
}

type CreateLinkPayload = {
  patientId: string;
  appointmentTypeId: string;
};

export function useCreateAppointmentLink(
  config: Omit<
    UseMutationOptions<Link, unknown, CreateLinkPayload, unknown>,
    'mutationFn'
  > = {},
) {
  return useMutation(
    async ({ patientId, appointmentTypeId }: CreateLinkPayload) =>
      SchedulingService.CreateLink({
        parent: patientId,
        link: {
          type: LinkType.NEW_APPOINTMENT,
          patientId,
          newAppointment: { appointmentTypeId },
        },
      }),
    config,
  );
}

export function useListAppointmentTypes(enabled: boolean = true) {
  return useQuery(
    [...appointmentKeys.listAppointmentTypes],
    (ctx) =>
      grpcQueryFunction<ListAppointmentTypesResponse>(
        ctx,
        SchedulingService.ListAppointmentTypes,
        { pageToken: ctx.pageParam },
      ),
    {
      enabled,
      refetchInterval: POLLING_INTERVAL,
    },
  );
}
