import { useIntl } from 'react-intl';

import type { ChangeEndEncounterState } from '../NoteEditorHeaderAndDialogs';
import { ConfirmationDialog } from './ConfirmationDialog/ConfirmationDialog';

type Props = {
  state: ChangeEndEncounterState;
};

export function EndEarlyNotesConfirmationDialog({ state }: Props) {
  const intl = useIntl();
  if (!state.modalOpen) {
    return null;
  }
  const { modalOpen: isOpen, onConfirm, onCancel, isClearing } = state;

  const dialogTexts = {
    ending: {
      description: intl.formatMessage({
        defaultMessage:
          "The end reason you've selected will automatically update the patient notes with a new template. Are you sure you want to end the encounter?",
      }),
      cancelButton: intl.formatMessage({
        defaultMessage: 'Close',
      }),
      confirmButton: intl.formatMessage({
        defaultMessage: 'End encounter',
      }),
    },
    resuming: {
      description: intl.formatMessage({
        defaultMessage:
          'By clearing the end reason, you will resume the encounter and the patient notes will revert to the original encounter template. Are you sure you want to resume the encounter?',
      }),
      cancelButton: intl.formatMessage({
        defaultMessage: 'Cancel',
      }),
      confirmButton: intl.formatMessage({
        defaultMessage: 'Resume encounter',
      }),
    },
  };

  const {
    description: dialogDescription,
    cancelButton: cancelButtonText,
    confirmButton: confirmButtonText,
  } = isClearing ? dialogTexts.resuming : dialogTexts.ending;

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirm={onConfirm}
      dialogTitle={intl.formatMessage({
        defaultMessage: 'Encounter notes',
      })}
      dialogDescription={dialogDescription}
      cancelButtonText={cancelButtonText}
      confirmButtonText={confirmButtonText}
    />
  );
}
