import { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';

import { Edit } from '@/shared/assets/svgs';
import { Form } from '@/shared/common/Form';
import { useFormFromConfig } from '@/shared/common/Form/FormContainer';
import { MailingAddress } from '@/shared/common/FormSections/MailingAddress';
import type { AddressValues } from '@/shared/common/FormSections/MailingAddress/formUtils';
import { patientDetailsQueryKeys, patientKeys } from '@/shared/hooks/queries';
import { Button } from '@/shared/tempo/atom/Button';
import { IconButton } from '@/shared/tempo/atom/IconButton';
import type { Patient } from '@/shared/types/patient.types';

import { headerText } from '../RpmDualEnrollmentModal.css';
import { EnrollmentSection } from '../SectionHelpers';
import { actionButtons, editContainer } from './VerifyAddress.css';
import { getAddressFormConfig } from './formConfig';
import { useUpsertPatientPrimaryAddress } from './patientAddress.queries';

type Props = {
  patientDetails: Patient;
  isLoading: boolean;
  patientId: string;
};

export const VerifyAddress = ({
  patientDetails,
  isLoading,
  patientId,
}: Props) => {
  const intl = useIntl();
  const client = useQueryClient();
  const [isVerifyAddressOpen, setIsVerifyAddressOpen] = useState(false);

  const address = patientDetails?.addresses?.find(({ primary }) => primary)
    ?.address;

  const addressValues = {
    street_address: address?.street_address || '',
    premise: address?.premise || '',
    postal_code: address?.postal_code || '',
    locality: address?.locality || '',
    region: address?.region || '',
  };

  const config = getAddressFormConfig(intl, addressValues);
  const form = useFormFromConfig<AddressValues>({
    ...config,
    triggerReset: !isLoading,
  });

  const { mutate: upsertAddress, isLoading: isSubmitting } =
    useUpsertPatientPrimaryAddress(patientId);

  const handleFormSubmit = useCallback(
    (values: AddressValues) => {
      upsertAddress(values, {
        onSuccess: async () => {
          form.reset(values);
          setIsVerifyAddressOpen(false);
          await client.invalidateQueries(patientKeys.detail(patientId));
          await client.invalidateQueries(
            patientDetailsQueryKeys.detail(patientId),
          );
        },
      });
    },
    [client, form, patientId, upsertAddress],
  );

  const addressLine1 = `${addressValues.street_address || ''}, ${
    addressValues.premise || ''
  }`;
  const cityStateZip = `${addressValues.locality || ''}, ${
    addressValues.region || ''
  } ${addressValues.postal_code || ''}`;

  return (
    <>
      <div className={headerText}>
        <FormattedMessage defaultMessage="3. Verify Shipping Address" />
      </div>

      <div className={editContainer}>
        <div>
          <div>{addressLine1}</div>
          <div>{cityStateZip}</div>
        </div>
        {!isVerifyAddressOpen && (
          <IconButton
            variant="secondary"
            size="small"
            onPress={() => setIsVerifyAddressOpen(true)}
            isDisabled={isLoading}
          >
            <Edit />
          </IconButton>
        )}
      </div>

      {isVerifyAddressOpen && (
        <EnrollmentSection>
          <Form form={form} onSubmit={handleFormSubmit}>
            {({ canSubmit }) => (
              <>
                <MailingAddress
                  addressValues={addressValues}
                  showWrapper={false}
                />
                <div className={actionButtons}>
                  <Button
                    type="button"
                    size="small"
                    variant="secondary"
                    onPress={() => setIsVerifyAddressOpen(false)}
                  >
                    <FormattedMessage defaultMessage="Cancel" />
                  </Button>
                  <Button
                    type="submit"
                    size="small"
                    isDisabled={!canSubmit || isSubmitting}
                  >
                    <FormattedMessage defaultMessage="Update" />
                  </Button>
                </div>
              </>
            )}
          </Form>
        </EnrollmentSection>
      )}
    </>
  );
};
