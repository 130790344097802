import { LabGroup } from '@/shared/generated/grpc/go/pms/pkg/patient/labs/labs.pb';
import { usePatientClinicalProfile } from '@/shared/hooks/queries/clinical-profile.queries';
import { usePatientLabsAndGroups } from '@/shared/hooks/queries/labs.queries';

import { getLatestLabGroupsWithDatesAndLabs } from '../../../../../Labs/labs.utils';

export function usePatientConditionData(patientId: string) {
  const { data: clinicalProfile, isLoading: isLoadingClinicalProfile } =
    usePatientClinicalProfile(patientId);

  const { data: patientLabs, isLoading: isLoadingLabs } =
    useCarePlanLabData(patientId);

  return {
    isLoading: isLoadingClinicalProfile || isLoadingLabs,
    data: {
      a1c: patientLabs?.a1c || clinicalProfile?.a1c,
      avgDiastolic: clinicalProfile?.avgDiastolic,
      avgSystolic: clinicalProfile?.avgSystolic,
      bmi: clinicalProfile?.bmi,
      hr: clinicalProfile?.heartRate,
      spo2: clinicalProfile?.spo2,
      totalCholesterol: patientLabs?.totalCholesterol,
      tsh: patientLabs?.tsh,
    },
  };
}

type LatestLabGroups = ReturnType<typeof getLatestLabGroupsWithDatesAndLabs>;

function useCarePlanLabData(patientId: string) {
  const { data: patientLabsAndGroups, isLoading: isLoadingLabsAndGroups } =
    usePatientLabsAndGroups(patientId);

  const labGroups = patientLabsAndGroups?.labGroups;
  const latesLabs = getLatestLabGroupsWithDatesAndLabs(labGroups || []);

  return {
    isLoading: isLoadingLabsAndGroups,
    data: {
      totalCholesterol: getLabValue(
        latesLabs,
        LabGroup.LIPIDS,
        'Cholesterol, Total',
      ),
      tsh: getLabValue(latesLabs, LabGroup.TSH, 'Thyroid Stimulating Hormone'),
      a1c: getLabValue(latesLabs, LabGroup.A1C, 'Hemoglobin A1c'),
    },
  };
}

function getLabValue(
  labs: LatestLabGroups,
  labGroup: LabGroup,
  referenceLabName: string,
) {
  const lab = labs[labGroup]?.labs.find(
    (l) => l.referenceLabName === referenceLabName,
  );

  return lab?.value ? Number(lab.value) : undefined;
}
