import type { IntlShape } from 'react-intl';

import {
  TobaccoType,
  TobaccoUserStatus,
} from '@/shared/generated/grpc/go/pms/pkg/care_plan/care_plan.pb';
import type { GeneralAssessmentSocialHistory } from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import {
  GoalCategory,
  GoalStatus,
} from '@/shared/generated/grpc/go/pms/pkg/ccm_goal/ccm_goal.pb';

import { getTobaccoTypeLabel } from '../CarePlanForm/sections/TobaccoUse/tobaccoUtils';
import { GoalMeasure } from './goals.types';

export function getInitialTobaccoGoal(
  socialHistory: Maybe<GeneralAssessmentSocialHistory>,
  intl: IntlShape,
) {
  if (!socialHistory) {
    return undefined;
  }

  const { tobaccoUserStatus } = socialHistory;
  const createTobaccoGoal = tobaccoUserStatus === TobaccoUserStatus.CURRENT;

  if (createTobaccoGoal) {
    const description = getTobaccoUseDescription(
      socialHistory?.tobaccoTypes,
      socialHistory?.smokingQuantity,
      socialHistory?.smokingYears,
      socialHistory?.tobaccoUse,
      intl,
    );

    return {
      title: intl.formatMessage({
        defaultMessage: 'Achieve smoking cessation',
      }),
      description,
      measure: GoalMeasure.Tobacco,
      status: GoalStatus.OPEN,
      category: GoalCategory.LIFESTYLE,
    };
  }

  return undefined;
}

export function getTobaccoUseDescription(
  tobaccoTypes: Maybe<TobaccoType[]>,
  smokingQuantity: Maybe<string>,
  smokingYears: Maybe<number>,
  tobaccoUse: Maybe<string>,
  intl: IntlShape,
) {
  if (!tobaccoTypes || !tobaccoTypes.length) {
    return tobaccoUse || '';
  }

  const tobaccoTypesDescription = intl.formatMessage(
    {
      defaultMessage: 'Tobacco use: {types}',
    },
    {
      types: tobaccoTypes
        .map((type) => {
          const label = getTobaccoTypeLabel(type, intl);
          if (
            type === TobaccoType.SMOKING &&
            (smokingQuantity || smokingYears)
          ) {
            return `${label} (${getSmokingDescription(
              smokingQuantity,
              smokingYears,
              intl,
            )})`;
          }
          return label;
        })
        .join(', '),
    },
  );

  return tobaccoTypesDescription && tobaccoUse
    ? `${tobaccoTypesDescription}. ${tobaccoUse}`
    : tobaccoTypesDescription || tobaccoUse || '';
}

function getSmokingDescription(
  smokingQuantity: Maybe<string>,
  smokingYears: Maybe<number>,
  intl: IntlShape,
) {
  const fragments = [];
  if (smokingQuantity) {
    fragments.push(
      intl.formatMessage(
        { defaultMessage: '{smokingQuantity} packs per day' },
        { smokingQuantity },
      ),
    );
  }

  if (smokingYears) {
    fragments.push(
      intl.formatMessage(
        { defaultMessage: 'for {smokingYears} years' },
        { smokingYears },
      ),
    );
  }

  return fragments.join(', ');
}
