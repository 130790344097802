import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { AlertDetail } from '@/components/AsyncTitration/PatientActivity/AlertDetail';
import { Medication } from '@/shared/assets/svgs';
import HospitalIcon from '@/shared/assets/svgs/hospital.svg?react';
import UserIcon from '@/shared/assets/svgs/user.svg?react';
import type { CheckPatientAlertEscalationRequiredResponse } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { CheckPatientAlertEscalationRequiredResponseEscalationReason as Reason } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { color } from '@/shared/generated/theme/variables';
import { useFlags } from '@/shared/hooks';
import { usePatientDetails } from '@/shared/hooks/queries';
import { EscalationStatus } from '@/shared/types/alert.types';
import { GRPC_EHR_TO_LOCAL_ENUM_MAP } from '@/shared/types/ehr.types';

import { useNoteEditorContext } from '../../NoteEditorContext';
import type { SymptomsInputs } from '../../NotePreview/formatSymptomsInputs.util';
import { usePatientAlertEscalationRequired } from '../../NotePreview/hooks/useAlertEscalationsList';
import { EditableNoteType } from '../../Notes.types';
import type { SetNoteFormValue } from '../noteFormState';
import { AlertEscalationNotice } from './Notices/AlertEscalationNotice';
import { VisitLayoutSection } from './VisitLayoutSection';

type Props = {
  patientActivityModule: JSX.Element;
  symptomsModule: JSX.Element;
  emergencyVisitsModule: JSX.Element;
  medsModule: JSX.Element;
  optionalNotesForm: JSX.Element;
  symptoms: SymptomsInputs;
  patientId: string;
  setNoteFormValue: SetNoteFormValue;
};

export function AlertDocumentationWithEscalation({
  patientActivityModule,
  symptomsModule,
  emergencyVisitsModule,
  medsModule,
  optionalNotesForm,
  symptoms,
  patientId,
  setNoteFormValue,
}: Props) {
  const { editingNote, setEditingNote } = useNoteEditorContext();
  const isAlertType = editingNote?.type === EditableNoteType.Alert;
  const { enableAlertEscalations } = useFlags();
  const { data: patient } = usePatientDetails(patientId, true);
  const escalations = usePatientAlertEscalationRequired(patientId, symptoms);

  const [
    shouldEscalateFromVitals,
    shouldEscalateFromSymptoms,
    isProviderManaged,
  ] = getEscalationApplicability(escalations);

  const patientEhr =
    patient?.ehrInformation?.ehr &&
    GRPC_EHR_TO_LOCAL_ENUM_MAP[patient.ehrInformation.ehr];

  useEffect(() => {
    if (enableAlertEscalations && isAlertType) {
      const positiveEscalationStatus = isProviderManaged
        ? EscalationStatus.NeedsEscalationToPhysician
        : EscalationStatus.NeedsEscalationToNP;
      const escalationStatus =
        shouldEscalateFromVitals || shouldEscalateFromSymptoms
          ? positiveEscalationStatus
          : EscalationStatus.NotEscalated;
      setEditingNote({
        ...editingNote,
        alert: {
          ...editingNote.alert,
          escalation_status: escalationStatus,
        },
      });
    }
  }, [
    editingNote,
    enableAlertEscalations,
    isAlertType,
    isProviderManaged,
    setEditingNote,
    shouldEscalateFromSymptoms,
    shouldEscalateFromVitals,
  ]);

  useEffect(() => {
    if (
      enableAlertEscalations &&
      isAlertType &&
      (shouldEscalateFromVitals || shouldEscalateFromSymptoms)
    ) {
      setNoteFormValue('actionRequired', isProviderManaged);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isProviderManaged,
    isAlertType,
    shouldEscalateFromVitals,
    shouldEscalateFromSymptoms,
    enableAlertEscalations,
  ]);

  const alertEscalationNotice = (
    <AlertEscalationNotice
      shouldEscalateFromVitals={shouldEscalateFromVitals}
      shouldEscalateFromSymptoms={shouldEscalateFromSymptoms}
      isProviderManaged={isProviderManaged}
      escalations={escalations}
      ehr={patientEhr}
    />
  );

  return (
    <>
      <VisitLayoutSection
        title={<FormattedMessage defaultMessage="Patient Activity" />}
        icon={<UserIcon fill={color.Theme.Light['Base Font']} />}
      >
        {shouldEscalateFromVitals && alertEscalationNotice}
        {isAlertType && editingNote.vitals && editingNote.alert && (
          <AlertDetail
            vitals={editingNote.vitals}
            vitalsAlert={editingNote.alert}
          />
        )}
        {patientActivityModule}
      </VisitLayoutSection>
      <VisitLayoutSection
        title={<FormattedMessage defaultMessage="Symptoms" />}
        icon={<UserIcon fill={color.Theme.Light['Base Font']} />}
      >
        {symptomsModule}
        {!shouldEscalateFromVitals &&
          shouldEscalateFromSymptoms &&
          alertEscalationNotice}
      </VisitLayoutSection>
      <VisitLayoutSection
        title={<FormattedMessage defaultMessage="Emergency Visits" />}
        icon={<HospitalIcon />}
      >
        {emergencyVisitsModule}
      </VisitLayoutSection>
      <VisitLayoutSection
        title={<FormattedMessage defaultMessage="Medications" />}
        icon={<Medication fill={color.Theme.Light['Base Font']} />}
      >
        {medsModule}
      </VisitLayoutSection>
      <VisitLayoutSection
        title={<FormattedMessage defaultMessage="Optional alert details" />}
        icon={<UserIcon fill={color.Theme.Light['Base Font']} />}
      >
        {optionalNotesForm}
      </VisitLayoutSection>
    </>
  );
}

function getEscalationApplicability(
  escalations?: Nullable<CheckPatientAlertEscalationRequiredResponse>,
) {
  if (!escalations?.escalationReasons) {
    return [false, false, false] as [boolean, boolean, boolean];
  }
  const shouldEscalateFromVitals = escalations.escalationReasons.some(
    (v) => v !== Reason.NEW_OR_WORSENING_SYMPTOMS,
  );
  const shouldEscalateFromSymptoms = escalations.escalationReasons.some(
    (v) => v === Reason.NEW_OR_WORSENING_SYMPTOMS,
  );
  const isProviderManaged = !!escalations.providerManaged;
  return [
    shouldEscalateFromVitals,
    shouldEscalateFromSymptoms,
    isProviderManaged,
  ] as [boolean, boolean, boolean];
}
