import cloneDeep from 'lodash/cloneDeep';
import { Fragment } from 'react';
import type { IntlShape } from 'react-intl';

import { CareModelVersion } from '@/shared/types/featureFlags.types';
import type { TNoteBodyRTF } from '@/shared/types/note.types';

import {
  type CarePlanInputs,
  type ClinicalAttestationInputs,
  EncounterModuleId,
  type EncounterModuleInstance,
  type EncounterTypeInputs,
  type GeneralAssessmentInputs,
  type OptionalNotesInput,
  type PatientNotesInputs,
  TypeOfEncounter,
} from '../Notes.types';
import { getInstance } from '../utils/encounterModuleInstanceUtils';
import {
  getEncounterType,
  getEncounterTypeInstance,
  isAdHocClinicalEncounterType,
  isCarePlanVisit,
  isClinicalNavigatorEncounterType,
  isNPEncounterType,
  isRegularVisitClinicianType,
  showClinicalAttestation,
} from '../utils/encounterTypeUtils';
import { titleRtfBlock } from '../utils/rtfBodyUtil';
import { getOptionalNoteBody } from './getOptionalNoteBody';
import { shouldShowAsyncTitration } from './hooks/useGetAsyncTitrationNoteBody';

export enum OutputType {
  NotePreview,
  PublishedNoteBody,
}
export function getVisitLayoutOutput<T>(
  instances: EncounterModuleInstance[],
  // TODO: These function params makes the code hard to understand. Remove `transformInputs` and `transformRichText`, import directly, and use `outputType` param to determine which one to use. Also rename getVisitLayoutOutput to getNoteOutput
  transformInputs: (
    moduleId: EncounterModuleId,
    encounterTypeInstance: Maybe<EncounterModuleInstance<EncounterTypeInputs>>,
    inputs: {},
  ) => T,
  transformRichText: (rtfBody: Maybe<TNoteBodyRTF>) => T,
  intl: IntlShape,
  careModelVersion: CareModelVersion,
  outputType: OutputType,
  medReviewOutput: Nullable<T>,
  diseaseSpecificMedsOutput: Nullable<T>,
  asyncTitrationOutput: Nullable<T>,
  alertEscalationOutput: Nullable<T>,
) {
  const encounterTypeInstance = getEncounterTypeInstance(instances);
  const encounterType = getEncounterType(instances);
  if (!encounterType) {
    return null;
  }

  const moduleList = getModuleList(
    careModelVersion,
    encounterType,
    alertEscalationOutput !== null,
  );

  function placeholderInstance(moduleId: number): EncounterModuleInstance<{}> {
    return {
      encounter_module_id: moduleId,
      inputs: {},
    };
  }

  return moduleList.map((moduleId) => {
    // Modules marked explicitly as "nothing to report" are removed, so we need
    // to add placeholders with empty inputs so that they will still be output
    // in the preview
    const instance =
      getInstance(instances, moduleId) || placeholderInstance(moduleId);

    const inputs = instance?.inputs;

    if (!inputs) {
      return null;
    }

    let moduleContent;
    if (moduleId === EncounterModuleId.PatientNotes) {
      moduleContent = transformRichText(
        (inputs as PatientNotesInputs).notes_body,
      );
      if (medReviewOutput) {
        moduleContent = appendContent(
          moduleContent,
          medReviewOutput,
          outputType,
        );
      }
      if (
        diseaseSpecificMedsOutput &&
        !moduleList.includes(EncounterModuleId.GeneralAssessmentAndPlan)
      ) {
        moduleContent = appendContent(
          moduleContent,
          diseaseSpecificMedsOutput,
          outputType,
        );
      }
    } else if (moduleId === EncounterModuleId.GeneralAssessmentAndPlan) {
      moduleContent = transformRichText(
        (inputs as GeneralAssessmentInputs).assessment_body,
      );
      if (diseaseSpecificMedsOutput) {
        moduleContent = appendContent(
          moduleContent,
          diseaseSpecificMedsOutput,
          outputType,
        );
      }
    } else if (moduleId === EncounterModuleId.ClinicalAttestation) {
      moduleContent = transformRichText(
        (inputs as ClinicalAttestationInputs).clinical_attestation_body,
      );
    } else if (moduleId === EncounterModuleId.CarePlan) {
      let carePlanRtfBody = (inputs as CarePlanInputs).care_plan_body;

      if (carePlanRtfBody?.blocks) {
        carePlanRtfBody = cloneDeep(carePlanRtfBody);
        carePlanRtfBody.blocks?.unshift(
          titleRtfBlock(
            intl.formatMessage({
              defaultMessage: 'Cadence Comprehensive Care Plan:',
            }),
          ),
        );
      }

      moduleContent = transformRichText(carePlanRtfBody);
    } else if (
      shouldShowAsyncTitration(encounterType) &&
      moduleId === EncounterModuleId.Medications
    ) {
      moduleContent = appendContent(
        moduleContent,
        asyncTitrationOutput,
        outputType,
      );
    } else if (
      encounterType === TypeOfEncounter.ALERT_DOCUMENTATION &&
      moduleId === EncounterModuleId.Medications &&
      !moduleList.includes(EncounterModuleId.PatientNotes)
    ) {
      moduleContent = appendContent(moduleContent, medReviewOutput, outputType);
      moduleContent = appendContent(
        moduleContent,
        diseaseSpecificMedsOutput,
        outputType,
      );
    } else if (moduleId === EncounterModuleId.OptionalNotes) {
      const optionalNotes = (inputs as OptionalNotesInput).optional_notes;
      if (optionalNotes) {
        const optionalNoteBody = getOptionalNoteBody(optionalNotes);

        moduleContent = appendContent(
          moduleContent,
          optionalNoteBody,
          outputType,
        );
      }
    } else if (moduleId !== EncounterModuleId.Medications) {
      // we ignore meds module because it's already been embedded in PatientNotes or GeneralAssessmentAndPlan
      moduleContent = transformInputs(moduleId, encounterTypeInstance, inputs);
    }

    // Do this out of band because we don't have a module for our
    // notice.
    if (
      !!alertEscalationOutput &&
      encounterType === TypeOfEncounter.ALERT_DOCUMENTATION &&
      moduleId === EncounterModuleId.EncounterType
    ) {
      moduleContent = appendContent(
        moduleContent,
        alertEscalationOutput,
        outputType,
      );
    }

    return typeof moduleContent === 'string' || !moduleContent ? (
      moduleContent
    ) : (
      <Fragment key={moduleId}>{moduleContent}</Fragment>
    );
  });
}

export function getModuleList(
  careModelVersion: CareModelVersion,
  encounterType?: TypeOfEncounter,
  isAlertEscalation?: boolean,
) {
  if (!encounterType) {
    return [];
  }

  const isCareModelV3 = careModelVersion === CareModelVersion.V3;
  const isCnVisit = isClinicalNavigatorEncounterType(encounterType);

  if (
    isRegularVisitClinicianType(encounterType) ||
    (isCnVisit && !isCareModelV3)
  ) {
    return [
      EncounterModuleId.EncounterType,
      EncounterModuleId.PatientNotes,
      EncounterModuleId.Hospitalization,
      EncounterModuleId.Symptoms,
      EncounterModuleId.GeneralAssessmentAndPlan,
      ...(isNPEncounterType(encounterType)
        ? [EncounterModuleId.ClinicalGoalReached]
        : []),
      EncounterModuleId.Medications, // TODO: <- This seems to be bugged, current meds vs updates get split and aren't where they should be
      ...(showClinicalAttestation(encounterType, careModelVersion)
        ? [EncounterModuleId.ClinicalAttestation]
        : ([] as EncounterModuleId[])),
    ];
  }

  if (isCnVisit && isCareModelV3) {
    return [
      EncounterModuleId.EncounterType,
      EncounterModuleId.PatientNotes,
      EncounterModuleId.Symptoms,
      EncounterModuleId.GeneralAssessmentAndPlan,
    ];
  }

  if (
    isAlertEscalation &&
    encounterType === TypeOfEncounter.ALERT_DOCUMENTATION
  ) {
    return [
      EncounterModuleId.EncounterType,
      EncounterModuleId.Symptoms,
      EncounterModuleId.Hospitalization,
      EncounterModuleId.Medications,
      EncounterModuleId.OptionalNotes,
    ];
  }

  if (isAdHocClinicalEncounterType(encounterType)) {
    return [
      EncounterModuleId.EncounterType,
      EncounterModuleId.PatientNotes,
      EncounterModuleId.Medications,
      EncounterModuleId.Hospitalization,
      EncounterModuleId.Symptoms,
    ];
  }

  if (isCarePlanVisit(encounterType)) {
    return [
      EncounterModuleId.EncounterType,
      EncounterModuleId.CarePlan,
      EncounterModuleId.Hospitalization,
      EncounterModuleId.Symptoms,
      EncounterModuleId.Medications,
    ];
  }

  if (encounterType === TypeOfEncounter.ASYNC_REVIEW) {
    return [EncounterModuleId.EncounterType, EncounterModuleId.Medications];
  }

  if (encounterType === TypeOfEncounter.TITRATION_OUTREACH) {
    return [
      EncounterModuleId.EncounterType,
      EncounterModuleId.Medications,
      EncounterModuleId.Symptoms,
      EncounterModuleId.PatientNotes,
    ];
  }

  return [EncounterModuleId.EncounterType, EncounterModuleId.PatientNotes];
}

function appendContent<T>(
  existingContent: T,
  newContent: Nullable<T>,
  outputType: OutputType,
) {
  if (!existingContent) {
    return newContent;
  }
  if (!newContent) {
    return existingContent;
  }

  return outputType === OutputType.NotePreview ? (
    <>
      {existingContent}
      {newContent}
    </>
  ) : (
    `${existingContent}\n\n${newContent}`
  );
}
