import cx from 'classnames';
import omit from 'lodash/omit';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { BillableTimeGuidanceLink } from '@/pages/patients/patientDetails/ui/shared';
import {
  Divider,
  TasksContainer,
} from '@/pages/patients/patientDetails/ui/tabs/TimeTracking/ManualTimeTrackingForm/shared';
import CallIcon from '@/shared/assets/svgs/calling.svg?react';
import FileIcon from '@/shared/assets/svgs/file.svg?react';
import { ProgramType } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { useFlags } from '@/shared/hooks';

import { getTotalTimeForProgram } from '../../../shared/timeTracking.utils';
import { CallTracking } from '../../../tabs/TimeTracking/CallTracking/CallTracking';
import type { ProgramTimeEntry } from '../../Notes.types';
import {
  type EncounterTypeInputs,
  type TimeEntry,
  TimeTrackedTaskType,
} from '../../Notes.types';
import { useIsTimeTrackedTypeOfEncounter } from '../hooks/useIsTimeTrackedTypeOfEncounter';
import type { NoteFormValidationResult } from '../validation';
import { DateOfEncounter } from './DateOfEncounter';
import {
  contentsContainer,
  startDateAndTimeContainer,
} from './NoteTimeTracker.css';
import { StartOfEncounter } from './StartOfEncounter';
import { TimeTrackerMinutesPicker } from './TimeTrackerMinutesPicker';
import { TimeTrackerTasks } from './TimeTrackerTasks';
import { TimeTrackingTextInput } from './TimeTrackingTextInput';
import { TimeTrackingTotalTimeDisplay } from './TimeTrackingTotalTimeDisplay';

type Props = {
  onChange: (timeEntry: Partial<TimeEntry>) => void;
  timeEntry: Partial<TimeEntry>;
  className?: string;
  validationResults: NoteFormValidationResult['timeEntry'];
  encounterTypeInputs?: EncounterTypeInputs;
  patientId?: Maybe<string>;
  noteId?: Maybe<number>;
  captureInteractiveTime?: boolean;
};

export function NoteTimeTracker({
  onChange,
  timeEntry,
  className,
  validationResults,
  encounterTypeInputs,
  patientId,
  noteId,
  captureInteractiveTime = true,
}: Props) {
  const intl = useIntl();
  const { addCallsToEncounter } = useFlags();

  const onFieldChange =
    (
      program: Nullable<ProgramType>,
      field: keyof (TimeEntry & ProgramTimeEntry),
    ) =>
    (value: unknown) => {
      let updatedTimeEntry: Partial<TimeEntry>;

      // if program is null, we're updating a top-level field
      if (program === null) {
        updatedTimeEntry = { ...timeEntry, [field]: value };
      } else {
        updatedTimeEntry = {
          ...timeEntry,
          entries: {
            ...timeEntry.entries,
            [program]: {
              ...timeEntry.entries?.[program],
              [field]: value,
              ...(!captureInteractiveTime && {
                interactive_duration: 0,
              }),
            },
          },
        };
      }

      // Remove the old fields if present in the TT retrieved from BE.
      // These should be inferred by BE using up-to-date TT fields.
      updatedTimeEntry = omit(updatedTimeEntry, [
        'communication_type',
        'note',
        'duration',
        'tasks_accomplished',
      ]);

      onChange(updatedTimeEntry);
    };

  const rpmEntry = timeEntry.entries?.[ProgramType.RPM];

  useEffect(() => {
    if (
      rpmEntry?.other_task_description !== undefined &&
      !rpmEntry?.tasks_accomplished?.includes(TimeTrackedTaskType.Other)
    ) {
      onFieldChange(ProgramType.RPM, 'other_task_description')(undefined);
    }
    // Don't trigger on onFieldChange changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rpmEntry?.tasks_accomplished]);

  const isPatientNoShow = !!encounterTypeInputs?.patient_no_show;

  useEffect(() => {
    if (isPatientNoShow) {
      onFieldChange(ProgramType.RPM, 'interactive_duration')(0);
    }
    // Run effect on isPatientNoShow changes with up to date onFieldChange
    // but don't run on onFieldChange changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPatientNoShow /* onFieldChange */]);

  if (!useIsTimeTrackedTypeOfEncounter(encounterTypeInputs)) {
    return null;
  }

  const totalTimeInMinutes = getTotalTimeForProgram(timeEntry, ProgramType.RPM);

  return (
    <div className={cx(className)}>
      {captureInteractiveTime && (
        <>
          <TimeTrackerMinutesPicker
            tooltip={
              isPatientNoShow
                ? intl.formatMessage({
                    defaultMessage:
                      'Interactive time cannot be entered for a patient no-show ',
                  })
                : undefined
            }
            isDisabled={isPatientNoShow}
            icon={<CallIcon width={16} height={16} />}
            label={<FormattedMessage defaultMessage="Interactive time" />}
            description={
              <FormattedMessage defaultMessage="Live phone conversation" />
            }
            onChange={onFieldChange(ProgramType.RPM, 'interactive_duration')}
            value={
              rpmEntry?.interactive_duration ?? timeEntry.interactive_duration
            }
            fieldValidationResult={getFieldValidationResult(
              validationResults,
              ProgramType.RPM,
              'interactive_duration',
            )}
          />
          {addCallsToEncounter && noteId && patientId && (
            <CallTracking noteId={noteId} patientId={patientId} />
          )}

          <Divider />
        </>
      )}
      <TimeTrackerMinutesPicker
        icon={<FileIcon width={16} height={16} />}
        label={<FormattedMessage defaultMessage="Non-interactive time" />}
        description={
          <FormattedMessage defaultMessage="Chart prep, documentation and other tasks" />
        }
        onChange={onFieldChange(ProgramType.RPM, 'non_interactive_duration')}
        value={
          rpmEntry?.non_interactive_duration ??
          timeEntry.non_interactive_duration
        }
        fieldValidationResult={getFieldValidationResult(
          validationResults,
          ProgramType.RPM,
          'non_interactive_duration',
        )}
      />
      <TasksContainer>
        <TimeTrackerTasks
          onChange={onFieldChange(ProgramType.RPM, 'tasks_accomplished')}
          value={rpmEntry?.tasks_accomplished ?? timeEntry.tasks_accomplished}
          fieldValidationResult={getFieldValidationResult(
            validationResults,
            ProgramType.RPM,
            'tasks_accomplished',
          )}
          required={
            (rpmEntry?.non_interactive_duration ??
              timeEntry.non_interactive_duration ??
              0) > 0
          }
        />
        {(
          rpmEntry?.tasks_accomplished ?? timeEntry.tasks_accomplished
        )?.includes(TimeTrackedTaskType.Other) && (
          <TimeTrackingTextInput
            onChange={onFieldChange(ProgramType.RPM, 'other_task_description')}
            value={
              rpmEntry?.other_task_description ??
              timeEntry.other_task_description
            }
            label={intl.formatMessage({
              defaultMessage: 'Other task description',
            })}
            placeholder={intl.formatMessage({
              defaultMessage: 'Enter the task description',
            })}
            fieldValidationResult={getFieldValidationResult(
              validationResults,
              ProgramType.RPM,
              'other_task_description',
            )}
          />
        )}
      </TasksContainer>
      <TimeTrackingTotalTimeDisplay
        minutes={totalTimeInMinutes}
        fieldValidationResult={getFieldValidationResult(
          validationResults,
          ProgramType.RPM,
          'totalTimeDisplay',
        )}
      />
      <Divider />
      <div className={contentsContainer.default}>
        <div className={startDateAndTimeContainer}>
          <DateOfEncounter
            onChange={onFieldChange(null, 'start_date')}
            value={timeEntry.start_date}
            validationResult={getFieldValidationResult(
              validationResults,
              null,
              'start_date',
            )}
          />
          <StartOfEncounter
            onChange={onFieldChange(null, 'start_time')}
            value={timeEntry.start_time}
            validationResult={getFieldValidationResult(
              validationResults,
              null,
              'start_time',
            )}
          />
        </div>
        <BillableTimeGuidanceLink />
      </div>
    </div>
  );
}

function getFieldValidationResult(
  validationResults: NoteFormValidationResult['timeEntry'],
  program: Nullable<ProgramType>,
  field: keyof (TimeEntry & ProgramTimeEntry),
) {
  // if program is null, the field is a top-level field.
  const path = program
    ? `timeEntry.entries.${program}.${field}`
    : `timeEntry.${field}`;

  return validationResults.find((result) => result.path === path);
}
