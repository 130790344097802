import cx from 'classnames';
import { endOfDay, formatISO, parseISO, startOfDay } from 'date-fns';
import { useState } from 'react';
import type { IntlShape } from 'react-intl';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { NoShowDetails } from '@/components/ScheduleTray/NoShowDetails';
import { useCreateNoteFromAppointment } from '@/components/ScheduleTray/useCreateNoteFromAppointment';
import { ConfirmationDialog } from '@/pages/patients/patientDetails/ui/Notes/NoteEditor/dialogs';
import { useCreateEncounter } from '@/pages/patients/patientDetails/ui/Notes/note.queries';
import { Calendar, Reschedule } from '@/shared/assets/svgs';
import DraftFileIcon from '@/shared/assets/svgs/file.svg?react';
import NewFileIcon from '@/shared/assets/svgs/fileNew.svg?react';
import { ReschedulePatientModal } from '@/shared/common/SchedulePatientModal/ReschedulePatientModal';
import { NoteStatus as ApptNoteStatus } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { type AppointmentDetails } from '@/shared/generated/grpc/go/pms/pkg/scheduling/scheduling.pb';
import { useFlags } from '@/shared/hooks';
import { usePatientDetails } from '@/shared/hooks/queries';
import {
  autosavedNotesQueryKey,
  useDeleteAutosavedNoteByPatientId,
  usePatientAutosavedNote,
} from '@/shared/hooks/queries/autosave-notes.queries';
import { useCurrentUser } from '@/shared/hooks/useCurrentUser';
import { Button } from '@/shared/tempo/atom/Button';
import { IconButton } from '@/shared/tempo/atom/IconButton';
import { Tooltip } from '@/shared/tempo/atom/Tooltip';
import { useToaster } from '@/shared/tempo/molecule/Toast';
import { color } from '@/shared/tempo/theme';
import { getErrorMsg } from '@/shared/utils/helpers';
import { getUserTimezone } from '@/shared/utils/time-helpers';

import { cnNoteKeys } from '../CNNotesSidebarPanel/shared/querykeys';
import {
  appointmentButton,
  appointmentButtonContainer,
  appointmentDetailsRow,
  encounterButton,
  lastSyncedContainer,
  nextVisitText,
  openDraftEncounterButton,
} from './PatientScheduling.css';
import { UpcomingAppointments } from './UpcomingAppointments';
import { appointmentKeys } from './appointments.queries';
import { useIsRescheduleAllowed } from './useIsRescheduleAllowed';
import {
  getFormattedDate,
  getFormattedTime,
  isNewEncounter,
  isPublishedNoShow,
} from './util';

type Props = {
  appointment?: AppointmentDetails;
  upcomingAppointments?: AppointmentDetails[];
  patientId: string;
};

export function NextScheduledVisit({
  appointment,
  patientId,
  upcomingAppointments = [],
}: Props) {
  const intl = useIntl();
  const history = useHistory();
  const { toaster } = useToaster();
  const queryClient = useQueryClient();
  const { currentUserId: providerId } = useCurrentUser();
  const [isSchedulingDialogOpen, setIsSchedulingDialogOpen] = useState(false);
  const { startEncounterFromAppointment, smartRescheduling } = useFlags();
  const { data: patient } = usePatientDetails(patientId, false);
  const [isNewNoteConfirmationOpen, setIsNewNoteConfirmationOpen] =
    useState(false);
  const [newNoteAppt, setNewNoteAppt] =
    useState<Nullable<AppointmentDetails>>(null);
  const [rescheduleAppt, setRescheduleAppt] =
    useState<Nullable<AppointmentDetails>>(null);
  const isRescheduleAllowed = useIsRescheduleAllowed(appointment);
  const deleteAutoSavedNote = useDeleteAutosavedNoteByPatientId(
    appointment?.patientId ?? '',
    () => {},
    (error: unknown) => {
      toaster.error(getErrorMsg(error));
    },
  );
  const { data: autosavedNote } = usePatientAutosavedNote(
    appointment?.patientId ?? '',
    { enabled: Boolean(appointment) },
  );

  const apptDateTime = parseISO(appointment?.startTime ?? '');

  const { mutate: createEncounter, isLoading: isSaving } = useCreateEncounter({
    onSuccess: async (response) => {
      await queryClient.invalidateQueries(cnNoteKeys.autosaved(patientId));
      await queryClient.invalidateQueries(
        autosavedNotesQueryKey.patient(patientId),
      );
      await queryClient.invalidateQueries(
        appointmentKeys.nextScheduled(patientId),
      );
      await queryClient.invalidateQueries(
        appointmentKeys.list({
          apptStartTimeFrom: formatISO(startOfDay(apptDateTime)),
          apptStartTimeTo: formatISO(endOfDay(apptDateTime)),
          careProviderId: providerId,
        }),
      );
      setIsNewNoteConfirmationOpen(false);
      history.push({
        pathname: `/patients/${patientId}`,
        search: `?noteId=${response?.name}`,
      });
    },
    onError: (err: unknown) => {
      toaster.error(getErrorMsg(err));
    },
  });

  const { isLoading: isLoadingContext, createNoteFromAppointment } =
    useCreateNoteFromAppointment(appointment?.patientId ?? '', createEncounter);

  if (!appointment) {
    return (
      <div>
        <span>
          {intl.formatMessage({
            defaultMessage: 'No upcoming visits found',
          })}
        </span>
        <span className={lastSyncedContainer}>
          {intl.formatMessage({
            defaultMessage: 'Updates daily',
          })}
        </span>
      </div>
    );
  }
  const noShowAttemptNotes = (appointment.noShowAttemptNotes || []).filter(
    (noShowNote) => noShowNote.noteStatus === ApptNoteStatus.PUBLISHED,
  );

  const onCreateNote = (appt: AppointmentDetails) => {
    if (!isNewEncounter(appt) && !isPublishedNoShow(appt)) {
      history.push({
        pathname: `/patients/${patientId}`,
        search: `?noteId=${appt.noteId}`,
      });
      return;
    }

    if (autosavedNote) {
      setNewNoteAppt(appt);
      setIsNewNoteConfirmationOpen(true);
    } else {
      createNoteFromAppointment(appt);
    }
  };

  return (
    <>
      <div className={appointmentDetailsRow}>
        <Calendar fill={color.Theme.Light['Base Font']} />
        {upcomingAppointments.length > 1 && (
          <UpcomingAppointments
            appointments={upcomingAppointments}
            timezone={patient?.timezone ?? getUserTimezone()}
            onCreateNote={onCreateNote}
            onReschedule={(appt) => {
              setRescheduleAppt(appt);
              setIsSchedulingDialogOpen(true);
            }}
          />
        )}
        <div className={nextVisitText}>
          {nextScheduledVisitMessage(intl, appointment, patient?.timezone)}
        </div>
        {startEncounterFromAppointment && isPublishedNoShow(appointment) && (
          <NoShowDetails noShowAttemptNotes={noShowAttemptNotes} />
        )}
        <div className={appointmentButtonContainer}>
          {startEncounterFromAppointment && (
            <Button
              variant="secondary"
              type="submit"
              size="small"
              isDisabled={isLoadingContext}
              isProcessing={isSaving}
              className={cx(encounterButton, {
                [openDraftEncounterButton]:
                  !isNewEncounter && !isPublishedNoShow,
              })}
              onPress={() => {
                onCreateNote(appointment);
              }}
            >
              {isNewEncounter(appointment) || isPublishedNoShow(appointment) ? (
                <>
                  <Button.Icon>
                    <NewFileIcon />
                  </Button.Icon>
                  <FormattedMessage defaultMessage="Start Note" />
                </>
              ) : (
                <>
                  <Button.Icon>
                    <DraftFileIcon />
                  </Button.Icon>
                  <FormattedMessage defaultMessage="Open draft" />
                </>
              )}
            </Button>
          )}
          {smartRescheduling && (
            <Tooltip
              isDisabled={isRescheduleAllowed}
              content={
                <FormattedMessage defaultMessage="Unsupported appointment type. Please reschedule manually in Acuity until supported." />
              }
            >
              <IconButton
                variant="secondary"
                size="small"
                isDisabled={!isRescheduleAllowed}
                onPress={() => {
                  setRescheduleAppt(appointment);
                  setIsSchedulingDialogOpen(true);
                }}
                className={appointmentButton}
              >
                <Reschedule />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      <ConfirmationDialog
        isOpen={isNewNoteConfirmationOpen}
        onCancel={() => setIsNewNoteConfirmationOpen(false)}
        onConfirm={() => {
          deleteAutoSavedNote.mutate(undefined, {
            onSuccess: () => {
              if (!newNoteAppt) {
                return;
              }

              createNoteFromAppointment(newNoteAppt);
              setNewNoteAppt(null);
            },
          });
        }}
        confirmButtonText={intl.formatMessage({
          defaultMessage: 'Create Note',
        })}
        dialogTitle={intl.formatMessage({
          defaultMessage: 'Create a new note from appointment',
        })}
        dialogDescription={intl.formatMessage({
          defaultMessage:
            'Are you sure you want to create a new note? This will discard your current autosaved note not associated to this appointment.',
        })}
      />
      {patient && rescheduleAppt && (
        <ReschedulePatientModal
          isOpen={isSchedulingDialogOpen}
          onClose={() => {
            setIsSchedulingDialogOpen(false);
          }}
          patient={patient}
          appointment={rescheduleAppt}
        />
      )}
    </>
  );
}

function nextScheduledVisitMessage(
  intl: IntlShape,
  appt: AppointmentDetails,
  patientTimezone?: string,
) {
  const timezone = patientTimezone || getUserTimezone();
  const start = parseISO(appt.startTime ?? '');
  const formattedApptDate = getFormattedDate(intl, start);
  const formattedApptTime = getFormattedTime(intl, start, timezone);

  return intl.formatMessage(
    {
      defaultMessage: `{apptDate}, {apptTime}, {apptType}, {careProviderName}, {careProviderRole}`,
    },
    {
      apptType: appt.appointmentCanonicalName,
      apptDate: formattedApptDate,
      apptTime: formattedApptTime,
      careProviderName: `${appt.careProviderFirstName} ${appt.careProviderLastName}`,
      careProviderRole: appt.careProviderRole || 'NOT_FOUND',
    },
  );
}
