import type { TypeOfEncounter } from '../Notes.types';
import {
  convertScheduledToUnscheduledReason,
  convertUnscheduledToScheduledReason,
} from './encounterReasonConverters';
import { isUnscheduledVisit } from './encounterTypeUtils';

/**
 * Maps end encounter reasons between scheduled and unscheduled visit types
 * when the encounter type changes
 */
export function mapEndEncounterReason(
  reason: Maybe<string>,
  prevEncounterType: Maybe<TypeOfEncounter>,
  newEncounterType: Maybe<TypeOfEncounter>,
): Maybe<string> {
  // If no reason or encounter types are the same category, no mapping needed
  if (!reason || !prevEncounterType || !newEncounterType) {
    return reason;
  }

  const isPrevUnscheduled = isUnscheduledVisit(prevEncounterType);
  const isNewUnscheduled = isUnscheduledVisit(newEncounterType);

  // If encounter types are in the same category, no mapping needed
  if (isPrevUnscheduled === isNewUnscheduled) {
    return reason;
  }

  // Mapping from scheduled to unscheduled
  if (!isPrevUnscheduled && isNewUnscheduled) {
    return convertScheduledToUnscheduledReason(reason);
  }

  // Mapping from unscheduled to scheduled
  return convertUnscheduledToScheduledReason(reason);
}
