import { FormattedMessage } from 'react-intl';

import { ArrowUpRightCircled } from '@/shared/assets/svgs';
import AthenaLogo from '@/shared/assets/svgs/athena.svg?react';
import EpicLogo from '@/shared/assets/svgs/epic.svg?react';
import type { CheckPatientAlertEscalationRequiredResponse } from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { color } from '@/shared/generated/theme/variables';
import { EHR } from '@/shared/types/ehr.types';

import { getEscalationReasonsForVitals } from '../../../NotePreview/hooks/useAlertEscalationsList';
import { Notice } from './Notice';
import { styles } from './Notice.css';

type VitalEscalationNoticeProps = {
  shouldEscalateFromVitals: boolean;
  shouldEscalateFromSymptoms: boolean;
  isProviderManaged: boolean;
  escalations: Nullable<CheckPatientAlertEscalationRequiredResponse>;
  ehr?: EHR;
};

export function AlertEscalationNotice({
  shouldEscalateFromVitals,
  shouldEscalateFromSymptoms,
  isProviderManaged,
  escalations,
  ehr,
}: VitalEscalationNoticeProps) {
  if (!escalations) {
    return null;
  }

  const preamble = getPreamble(
    shouldEscalateFromVitals,
    shouldEscalateFromSymptoms,
    isProviderManaged,
    ehr,
  );
  const escalationBody = (
    <ul>
      {getEscalationReasonsForVitals(escalations).map((escalation) => (
        <li>{escalation}</li>
      ))}
    </ul>
  );

  if (shouldEscalateFromVitals || shouldEscalateFromSymptoms) {
    if (isProviderManaged) {
      switch (ehr) {
        case EHR.Athena:
          return (
            <Notice
              title={
                <FormattedMessage defaultMessage="Provider escalation case will be generated" />
              }
              icon={<AthenaLogo className={styles.icon} />}
            >
              {preamble}
              {shouldEscalateFromVitals && escalationBody}
            </Notice>
          );
        case EHR.Epic:
          return (
            <Notice
              title={
                <FormattedMessage defaultMessage="Action: Escalate the encounter to Provider in Epic" />
              }
              icon={<EpicLogo className={styles.icon} />}
              warning
            >
              {preamble}
              {shouldEscalateFromVitals && escalationBody}
            </Notice>
          );
        default:
      }
    }
    return (
      <Notice
        title={
          <FormattedMessage defaultMessage="Cadence escalation task will be generated" />
        }
        icon={
          <ArrowUpRightCircled
            className={styles.icon}
            fill={color.Theme.Light['Base Font']}
          />
        }
      >
        {preamble}
        {shouldEscalateFromVitals && escalationBody}
      </Notice>
    );
  }
  return null;
}

function getPreamble(
  shouldEscalateFromVitals: boolean,
  shouldEscalateFromSymptoms: boolean,
  isProviderManaged: boolean,
  ehr?: EHR,
) {
  if (shouldEscalateFromVitals) {
    if (isProviderManaged) {
      switch (ehr) {
        case EHR.Athena:
          return (
            <FormattedMessage defaultMessage="Athena patient case with note details will be generated when the note is published unless patient no-shows. Escalation reason(s):" />
          );
        case EHR.Epic:
          return (
            <FormattedMessage defaultMessage="Send a message to the provider in Epic after publishing this note because the patient met trend escalation criteria:" />
          );
        default:
      }
    }
    return (
      <FormattedMessage defaultMessage="Escalation Task with note details will be generated when the note is published unless patient no-shows. Escalation reason(s):" />
    );
  }

  if (shouldEscalateFromSymptoms) {
    if (isProviderManaged) {
      switch (ehr) {
        case EHR.Athena:
          return (
            <FormattedMessage defaultMessage="An Athena patient case will be sent when the note is published because there are new or worsening symptoms" />
          );
        case EHR.Epic:
          return (
            <FormattedMessage defaultMessage="Send a message to the provider in Epic after publishing this note because there are new or worsening symptoms" />
          );
        default:
      }
    }
    return (
      <FormattedMessage defaultMessage="An Escalation Task with details from this note will be generated when it is published because there are new or worsening symptoms" />
    );
  }
  return '';
}
