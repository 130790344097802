import { apcmCarePlanVisit } from './apcmCarePlanVisit';
import { ccmCarePlanVisit } from './ccmCarePlanVisit';
import { dischargeFollowup } from './dischargeFollowup';
import { regularApcmVisit } from './regularApcmVisit';
import { regularCcmVisit } from './regularCcmVisit';
import { titrationOutreachVisit } from './titrationOutreachVisit';

export const templates = {
  ...regularCcmVisit,
  ...regularApcmVisit,
  ...titrationOutreachVisit,
  ...dischargeFollowup,
  ...ccmCarePlanVisit,
  ...apcmCarePlanVisit,
};
