import { FormattedMessage, useIntl } from 'react-intl';

import { Form } from '@/shared/common/Form';
import type { ConfiguredForm } from '@/shared/common/Form/FormContainer';
import { useFlags } from '@/shared/hooks';

import { GoalsTasksField } from './GoalsTasksField';
import { StatusSelect } from './StatusSelect';
import { type FormFields } from './formConfig';
import { GoalMeasure } from './goals.types';
import { useGoalGoalMeasureI18n } from './i18nMapping';

type Props = {
  form: ConfiguredForm<FormFields>;
};

export function GoalForm({ form }: Props) {
  const intl = useIntl();
  const goalMeasureI18n = useGoalGoalMeasureI18n();
  const { carePlanOptimization } = useFlags();
  const { Select } = Form;
  return (
    <Form form={form}>
      <Form.Row rowSpacing={0}>
        <Form.Select
          name="measure"
          required
          label={intl.formatMessage({ defaultMessage: 'Measure' })}
          size={6}
          onChange={(measure: GoalMeasure) => {
            if (!form.getValues('title')) {
              form.setValue('title', goalMeasureI18n[measure], {
                shouldValidate: true,
              });
            }
          }}
          items={[
            {
              name: null,
              children: [
                {
                  name: GoalMeasure.Medications,
                  displayName: goalMeasureI18n[GoalMeasure.Medications],
                },
                {
                  name: GoalMeasure.EDVisitOrHospitalization,
                  displayName:
                    goalMeasureI18n[GoalMeasure.EDVisitOrHospitalization],
                },
                {
                  name: GoalMeasure.ChronicConditions,
                  displayName: goalMeasureI18n[GoalMeasure.ChronicConditions],
                },
                {
                  name: GoalMeasure.Vitals,
                  displayName: goalMeasureI18n[GoalMeasure.Vitals],
                },
                {
                  name: GoalMeasure.CareCoordination,
                  displayName: goalMeasureI18n[GoalMeasure.CareCoordination],
                },
              ],
            },
            {
              name: intl.formatMessage({ defaultMessage: 'Lifestyle' }),
              children: [
                {
                  name: GoalMeasure.Tobacco,
                  displayName: goalMeasureI18n[GoalMeasure.Tobacco],
                },
                {
                  name: GoalMeasure.Diet,
                  displayName: goalMeasureI18n[GoalMeasure.Diet],
                },
                {
                  name: GoalMeasure.Exercise,
                  displayName: goalMeasureI18n[GoalMeasure.Exercise],
                },
              ],
            },
            {
              name: intl.formatMessage({ defaultMessage: 'Preventative Care' }),
              children: [
                {
                  name: GoalMeasure.Pneumovax,
                  displayName: goalMeasureI18n[GoalMeasure.Pneumovax],
                },
                {
                  name: GoalMeasure.Influenza,
                  displayName: goalMeasureI18n[GoalMeasure.Influenza],
                },
                {
                  name: GoalMeasure.Tdap,
                  displayName: goalMeasureI18n[GoalMeasure.Tdap],
                },
                {
                  name: GoalMeasure.Shingrix,
                  displayName: goalMeasureI18n[GoalMeasure.Shingrix],
                },
                {
                  name: GoalMeasure.Colonoscopy,
                  displayName: goalMeasureI18n[GoalMeasure.Colonoscopy],
                },
                {
                  name: GoalMeasure.BreastCancerScreening,
                  displayName:
                    goalMeasureI18n[GoalMeasure.BreastCancerScreening],
                },
                {
                  name: GoalMeasure.DiabeticEyeExam,
                  displayName: goalMeasureI18n[GoalMeasure.DiabeticEyeExam],
                },
              ],
            },
            {
              name: intl.formatMessage({
                defaultMessage: 'Social Needs',
              }),
              children: [
                {
                  name: GoalMeasure.LivingSituation,
                  displayName: goalMeasureI18n[GoalMeasure.LivingSituation],
                },
                {
                  name: GoalMeasure.Food,
                  displayName: goalMeasureI18n[GoalMeasure.Food],
                },
                {
                  name: GoalMeasure.Transportation,
                  displayName: goalMeasureI18n[GoalMeasure.Transportation],
                },
                {
                  name: GoalMeasure.Utilities,
                  displayName: goalMeasureI18n[GoalMeasure.Utilities],
                },
                {
                  name: GoalMeasure.Safety,
                  displayName: goalMeasureI18n[GoalMeasure.Safety],
                },
                {
                  name: GoalMeasure.PhysicalActivity,
                  displayName: goalMeasureI18n[GoalMeasure.PhysicalActivity],
                },
              ],
            },
            ...(carePlanOptimization
              ? [
                  {
                    name: intl.formatMessage({
                      defaultMessage: 'Condition Management',
                    }),
                    children: [
                      {
                        name: GoalMeasure.BloodPressure,
                        displayName: goalMeasureI18n[GoalMeasure.BloodPressure],
                      },
                      {
                        name: GoalMeasure.Cholesterol,
                        displayName: goalMeasureI18n[GoalMeasure.Cholesterol],
                      },
                      {
                        name: GoalMeasure.BloodSugar,
                        displayName: goalMeasureI18n[GoalMeasure.BloodSugar],
                      },
                      {
                        name: GoalMeasure.HeartRate,
                        displayName: goalMeasureI18n[GoalMeasure.HeartRate],
                      },
                      {
                        name: GoalMeasure.Weight,
                        displayName: goalMeasureI18n[GoalMeasure.Weight],
                      },
                      {
                        name: GoalMeasure.OxygenSaturation,
                        displayName:
                          goalMeasureI18n[GoalMeasure.OxygenSaturation],
                      },
                      {
                        name: GoalMeasure.FluidIntake,
                        displayName: goalMeasureI18n[GoalMeasure.FluidIntake],
                      },
                      {
                        name: GoalMeasure.ThyroidHormone,
                        displayName:
                          goalMeasureI18n[GoalMeasure.ThyroidHormone],
                      },
                    ],
                  },
                ]
              : []),
          ]}
        >
          {(section) => (
            <Select.Section
              key={section.name}
              items={section.children}
              title={section.name}
            >
              {(measure) => (
                <Select.Option key={measure.name}>
                  <span>{measure.displayName}</span>
                </Select.Option>
              )}
            </Select.Section>
          )}
        </Form.Select>
        <StatusSelect size={6} />
      </Form.Row>
      <Form.Row rowSpacing={0}>
        <Form.TextField
          name="title"
          label={<FormattedMessage defaultMessage="Goal title" />}
          size={12}
        />
      </Form.Row>
      <Form.Row rowSpacing={0}>
        <Form.TextArea
          name="description"
          rows={3}
          label={<FormattedMessage defaultMessage="Description" />}
          size={12}
        />
      </Form.Row>
      <GoalsTasksField form={form} />
    </Form>
  );
}
