import { ReasonForNotTitrating } from './types';
import { useFilteredOptions } from './useTitrationOptions';

const OPTIONS_TO_OMIT = [
  ReasonForNotTitrating.ADJUSTED_ANOTHER_MEDICATION,
  ReasonForNotTitrating.DISENROLLED,
  ReasonForNotTitrating.DUPLICATE_TASK,
  ReasonForNotTitrating.MEDICATION_VERIFICATION,
  ReasonForNotTitrating.NEW_PRESCRIPTION_REQUIRED,
  ReasonForNotTitrating.RECENT_PATIENT_REFUSAL,
  ReasonForNotTitrating.RECOMMEND_ONLY_PATIENT,
  ReasonForNotTitrating.NEW_RX_REQUIRED_FOR_CURRENT_MEDS_MGMT_PATIENT,
];

export function useClinicalGoalsOptions() {
  return useFilteredOptions(OPTIONS_TO_OMIT);
}
