import { type ReactElement } from 'react';
import { type UseFormReturn } from 'react-hook-form';
import { type IntlShape, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Form } from '@/shared/common/Form';
import { GridItem } from '@/shared/common/Form/GridItem';
import { PreventativeCareStatus } from '@/shared/generated/grpc/go/pms/pkg/care_plan/care_plan.pb';
import { useFlags } from '@/shared/hooks';
import { useSaveCcmGoal } from '@/shared/hooks/queries/ccmGoals.queries';
import { useToaster } from '@/shared/tempo/molecule/Toast';

import { ContextualGoalButton } from '../../../Goals/ContextualGoalButton';
import {
  hasActiveStatus,
  useExistingMeasureGoal,
} from '../../../Goals/goalUtils';
import type { Goal } from '../../../Goals/goals.types';
import { type GoalMeasure, GoalStatus } from '../../../Goals/goals.types';
import {
  type FormFields,
  type PreventativeCareName,
  getPreventativeCareFieldNames,
} from '../../formConfig';
import { toggleRowHelp } from '../carePlanSections.css';
import { PreventativeCareType } from '../preventativeCareOptions';
import { GoalStatusButton } from '../shared/GoalStatusButton';
import { useGoalMutations } from '../shared/goalHooks';
import { actionRow, preventativeCareRow } from './PreventativeCareRow.css';
import { PreventativeCareStatusField } from './PreventativeCareStatus';
import {
  getDeclinedPreventativeCareGoal,
  getDefaultActionNeededNextSteps,
  getDefaultPreventativeCareStatus,
  getNextStepsDefaultLabels,
  getPreventativeCareGoalOnChange,
} from './preventativeCare.utils';
import { PREVENTATIVE_CARE_GOAL_MEASURE_MAP } from './preventativeCareGoals';

type Props = {
  name: PreventativeCareName;
  label: string;
  help?: ReactElement;
  form: UseFormReturn<FormFields>;
  createGoalWithMeasure: (measure: GoalMeasure, description?: string) => void;
  isNotApplicable?: boolean;
  type: PreventativeCareType;
  onEditGoal: (goal: Goal) => void;
  carePlanId: string;
};

export function PreventativeCareRowV2({
  name,
  label,
  help,
  form,
  createGoalWithMeasure,
  isNotApplicable = false,
  type,
  onEditGoal,
  carePlanId,
}: Props) {
  const intl = useIntl();
  const { carePlanOptimization } = useFlags();
  const { toaster } = useToaster();
  const {
    date: dateFieldName,
    status: statusFieldName,
    nextSteps: nextStepsFieldName,
  } = getPreventativeCareFieldNames(name);

  const status = form.getValues(statusFieldName) as PreventativeCareStatus;
  const nextSteps = form.getValues(nextStepsFieldName) as string;
  const date = form.getValues(dateFieldName) as string;
  const { patientId } = useParams<{ patientId: string }>();

  const { mutate: updateGoal } = useSaveCcmGoal();
  const { closeGoal, updateGoalDescription, createGoal } =
    useGoalMutations(patientId);

  const existingGoal = useExistingMeasureGoal(
    patientId,
    carePlanId,
    PREVENTATIVE_CARE_GOAL_MEASURE_MAP[name],
  );
  const hasGoal = !!existingGoal;

  function getDefaultStatus(dateValue: string) {
    return getDefaultPreventativeCareStatus(
      isNotApplicable,
      dateValue,
      name,
      carePlanOptimization,
    );
  }

  function setStatus(newValue: PreventativeCareStatus) {
    form.setValue(statusFieldName, newValue, { shouldValidate: true });
  }

  function setNextSteps(newValue: string) {
    form.setValue(nextStepsFieldName, newValue, { shouldValidate: true });
    if (hasGoal) {
      updateGoalDescription(existingGoal, newValue);
    }
  }

  function handleStatusChange(
    newStatus: PreventativeCareStatus,
    newDate: string,
  ) {
    switch (newStatus) {
      case PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_UP_TO_DATE:
        // Close goal if one exists
        if (existingGoal && hasActiveStatus(existingGoal)) {
          closeGoal(existingGoal);
        }
        break;
      case PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_ACTION_NEEDED: {
        const newNextSteps = getDefaultActionNeededNextSteps(intl, newDate);

        // Add default nextSteps value
        if (!nextSteps) {
          setNextSteps(newNextSteps);
        }

        // Create goal if one does not exist
        if (!hasGoal) {
          createGoal(
            getPreventativeCareGoalOnChange(
              name,
              undefined,
              nextSteps || newNextSteps,
            ),
            carePlanId,
          );
        }

        // Change goal to In Progress if an existing goal exists
        if (
          existingGoal &&
          existingGoal.status !== GoalStatus.IN_PROGRESS &&
          existingGoal.status !== GoalStatus.OPEN
        ) {
          updateGoal(
            {
              ...existingGoal,
              ...getPreventativeCareGoalOnChange(
                name,
                existingGoal.title,
                nextSteps || newNextSteps,
              ),
            },
            {
              onError: () => {
                toaster.error(
                  intl.formatMessage({
                    defaultMessage: 'Failed to update goal',
                  }),
                );
              },
            },
          );
        }
        break;
      }
      case PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_DECLINED: {
        if (hasGoal) {
          updateGoal(
            {
              ...existingGoal,
              ...getDeclinedPreventativeCareGoal(
                name,
                intl.formatMessage({
                  defaultMessage: 'Patient declined',
                }),
              ),
            },
            {
              onError: () => {
                toaster.error(
                  intl.formatMessage({
                    defaultMessage: 'Failed to decline goal',
                  }),
                );
              },
            },
          );
        } else {
          createGoal(
            getDeclinedPreventativeCareGoal(
              name,
              intl.formatMessage({ defaultMessage: 'Patient declined' }),
            ),
            carePlanId,
          );
        }
        break;
      }
      default:
    }
  }

  return (
    <>
      <Form.Row className={preventativeCareRow} rowSpacing={0}>
        <GridItem size={3}>
          <div>{label}</div>
          {help && <span className={toggleRowHelp}>{help}</span>}
        </GridItem>

        <GridItem size={6}>
          <PreventativeCareStatusField
            name={statusFieldName}
            type={type}
            onInputChange={(newStatus) => {
              if (!carePlanOptimization) {
                return;
              }

              handleStatusChange(newStatus, date);
            }}
          />
        </GridItem>

        <Form.TextField
          size={3}
          label={getDetailsFieldLabel(intl, status, type)}
          name={dateFieldName}
          onBlur={(newDateValue) => {
            const newPotentialStatus = getDefaultStatus(newDateValue);

            // If the status is still action needed but the date has changed, update the next steps
            if (
              carePlanOptimization &&
              status === newPotentialStatus &&
              status ===
                PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_ACTION_NEEDED
            ) {
              const newNextSteps = getDefaultActionNeededNextSteps(
                intl,
                newDateValue,
              );
              const nextStepsLabels = getNextStepsDefaultLabels(intl);
              const hasDefaultNextSteps =
                Object.values(nextStepsLabels).includes(nextSteps);
              if (nextSteps !== newNextSteps && hasDefaultNextSteps) {
                setNextSteps(newNextSteps);
              }
              return;
            }

            if (
              carePlanOptimization &&
              status ===
                PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_DECLINED
            ) {
              // The date field is coopted for the reason declined field
              if (hasGoal) {
                updateGoalDescription(existingGoal, newDateValue);
              }
              return;
            }

            if (status === newPotentialStatus || !newPotentialStatus) {
              return;
            }

            if (!carePlanOptimization) {
              if (
                status === undefined ||
                status ===
                  PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_UNSPECIFIED
              ) {
                setStatus(newPotentialStatus);
              }
              return;
            }

            handleStatusChange(newPotentialStatus, newDateValue);
            setStatus(newPotentialStatus);
          }}
          placeholder={
            status === PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_DECLINED
              ? intl.formatMessage({
                  defaultMessage: 'Enter reason patient declined',
                })
              : intl.formatMessage({
                  defaultMessage: 'MM / DD / YYYY',
                })
          }
          required={
            status ===
            PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_UP_TO_DATE
          }
        />
      </Form.Row>
      {status ===
        PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_ACTION_NEEDED && (
        <Form.Row rowSpacing={0} className={actionRow}>
          <GridItem size={3}> </GridItem>
          <Form.TextField
            size={9}
            name={nextStepsFieldName}
            label={intl.formatMessage({
              defaultMessage: 'Next steps',
            })}
            endAdornment={
              carePlanOptimization ? (
                <GoalStatusButton
                  carePlanId={carePlanId}
                  existingGoal={existingGoal}
                  onPressCreateGoal={() =>
                    createGoalWithMeasure(
                      PREVENTATIVE_CARE_GOAL_MEASURE_MAP[name],
                      nextSteps,
                    )
                  }
                  onPressEditGoal={onEditGoal}
                />
              ) : (
                <ContextualGoalButton
                  type="default"
                  onPress={() =>
                    createGoalWithMeasure(
                      PREVENTATIVE_CARE_GOAL_MEASURE_MAP[name],
                      nextSteps,
                    )
                  }
                />
              )
            }
            required
            onBlur={(newValue) => {
              if (carePlanOptimization && hasGoal) {
                updateGoalDescription(
                  {
                    ...existingGoal,
                    // Move goal to In Progress if it is open since user will most likely
                    // be editing the goal during a patient call
                    status:
                      existingGoal.status === GoalStatus.OPEN
                        ? GoalStatus.IN_PROGRESS
                        : existingGoal.status,
                  },
                  newValue,
                );
              }
            }}
          />
        </Form.Row>
      )}
    </>
  );
}

function getDetailsFieldLabel(
  intl: IntlShape,
  status: PreventativeCareStatus,
  type: PreventativeCareType,
) {
  if (status === PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_DECLINED) {
    return intl.formatMessage({
      defaultMessage: 'Reason declined',
    });
  }

  return type === PreventativeCareType.Vaccination
    ? intl.formatMessage({
        defaultMessage: 'Date of vaccination',
      })
    : intl.formatMessage({
        defaultMessage: 'Date of screening',
      });
}
