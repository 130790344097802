import { isFuture, isValid, parse, subYears } from 'date-fns';
import type { IntlShape } from 'react-intl';

import { PreventativeCareStatus } from '@/shared/generated/grpc/go/pms/pkg/care_plan/care_plan.pb';
import type { CarePlan } from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import {
  GoalCategory,
  GoalStatus,
} from '@/shared/generated/grpc/go/pms/pkg/ccm_goal/ccm_goal.pb';
import type { PatientDemographics } from '@/shared/hooks/usePatientDemographics';

import type { PreventativeCareName } from '../../formConfig';
import {
  PREVENTATIVE_CARE_OPTIONS,
  type PreventativeCareOption,
} from '../preventativeCareOptions';
import { PREVENTATIVE_CARE_GOAL_MEASURE_MAP } from './preventativeCareGoals';

// getInitialPreventativeCareGoals gets the initial Preventative Care goals to
// create when a care plan is first created
export function getInitialPreventativeCareGoals(
  carePlan: CarePlan,
  patientDemographics: PatientDemographics,
  intl: IntlShape,
) {
  const preventativeCareGoals = carePlan.preventativeCare
    ?.map((pc) => {
      const option = PREVENTATIVE_CARE_OPTIONS.find((o) => o.name === pc.name);
      const isNotApplicable = Boolean(
        patientDemographics && option?.notApplicable?.(patientDemographics),
      );

      // Determine what the initial status of the goal should be
      const initialStatus = getDefaultPreventativeCareStatus(
        isNotApplicable,
        pc.date,
        pc.name as PreventativeCareOption['name'],
        true, // This method is only called when carePlanOptimization is true
      );

      return {
        pc,
        option,
        initialStatus,
      };
    })
    // Only include goals that are in Action Needed status
    .filter(
      ({ initialStatus }) =>
        initialStatus ===
        PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_ACTION_NEEDED,
    )
    .map(({ pc, option }) =>
      getInitialPreventativeCareGoal(
        pc.name as PreventativeCareName,
        option?.label,
        getDefaultDescription(
          intl,
          PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_ACTION_NEEDED,
          pc.date,
        ),
        pc.date,
      ),
    );

  return preventativeCareGoals;
}

export function getInitialPreventativeCareGoal(
  pcName: PreventativeCareName,
  pcTitle: Maybe<string>,
  description: string | undefined,
  date: Maybe<string>,
) {
  const title =
    pcTitle || PREVENTATIVE_CARE_OPTIONS.find((o) => o.name === pcName)?.label;

  return {
    title,
    description,
    measure: PREVENTATIVE_CARE_GOAL_MEASURE_MAP[pcName],
    status: date ? GoalStatus.IN_PROGRESS : GoalStatus.OPEN,
    category: GoalCategory.PREVENTATIVE_CARE,
  };
}

export function getPreventativeCareGoalOnChange(
  pcName: PreventativeCareName,
  pcTitle: Maybe<string>,
  description: string | undefined,
) {
  const title =
    pcTitle || PREVENTATIVE_CARE_OPTIONS.find((o) => o.name === pcName)?.label;

  return {
    title,
    description,
    measure: PREVENTATIVE_CARE_GOAL_MEASURE_MAP[pcName],
    status: GoalStatus.IN_PROGRESS,
    category: GoalCategory.PREVENTATIVE_CARE,
  };
}

export function getDeclinedPreventativeCareGoal(
  pcName: PreventativeCareName,
  description: string | undefined,
) {
  const title = PREVENTATIVE_CARE_OPTIONS.find((o) => o.name === pcName)?.label;
  return {
    title,
    description,
    measure: PREVENTATIVE_CARE_GOAL_MEASURE_MAP[pcName],
    status: GoalStatus.DECLINED,
    category: GoalCategory.PREVENTATIVE_CARE,
  };
}

enum NextSteps {
  NEEDS = 'Needs',
  OVERDUE = 'Overdue',
}

export function getDefaultDescription(
  intl: IntlShape,
  status: Maybe<PreventativeCareStatus>,
  date: Maybe<string>,
) {
  const nextStepsLabels = getNextStepsDefaultLabels(intl);
  // Only auto-populate Next Steps field if there's a date since no date means
  // it needs review.
  // User will usually enter Next Steps during patient call, which will trigger moving the goal to In Progress
  if (
    status === PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_ACTION_NEEDED &&
    date
  ) {
    return nextStepsLabels[NextSteps.OVERDUE];
  }

  return undefined;
}

export function getDefaultActionNeededNextSteps(
  intl: IntlShape,
  date: Maybe<string>,
) {
  const nextStepsLabels = getNextStepsDefaultLabels(intl);
  return date
    ? nextStepsLabels[NextSteps.OVERDUE]
    : nextStepsLabels[NextSteps.NEEDS];
}

export function getNextStepsDefaultLabels(intl: IntlShape) {
  return {
    [NextSteps.NEEDS]: intl.formatMessage({ defaultMessage: 'Needs' }),
    [NextSteps.OVERDUE]: intl.formatMessage({ defaultMessage: 'Overdue' }),
  };
}

export function getDefaultPreventativeCareStatus(
  isNotApplicable: boolean,
  date?: string,
  name?: PreventativeCareOption['name'],
  carePlanOptimization?: boolean,
): PreventativeCareStatus | undefined {
  if (isNotApplicable) {
    return undefined;
  }

  if (!date) {
    return carePlanOptimization
      ? PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_ACTION_NEEDED
      : undefined;
  }

  // Parse the date in MM/DD/YYYY format
  const vaccineDate = parse(date, 'MM/dd/yyyy', new Date());

  if (!isValid(vaccineDate)) {
    return undefined;
  }

  if (isFuture(vaccineDate)) {
    return PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_ACTION_NEEDED;
  }

  const today = new Date();

  // Apply specific rules based on preventative care type
  switch (name) {
    case 'influenza': {
      const currentMonth = today.getMonth(); // 0-based (0-11)

      // Check if we're in flu season (September through March)
      const isInFluSeason = currentMonth >= 8 || currentMonth <= 2;

      if (!isInFluSeason) {
        return undefined;
      }

      // If we are in flu season, check if the vaccine is from this season
      const currentYear = today.getFullYear();
      // If we're in January-March, the flu season started in the previous year
      const fluSeasonStartYear =
        currentMonth <= 2 ? currentYear - 1 : currentYear;
      const fluSeasonStart = new Date(fluSeasonStartYear, 8, 1); // September 1st

      return vaccineDate >= fluSeasonStart && vaccineDate <= today
        ? PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_UP_TO_DATE
        : PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_ACTION_NEEDED;
    }

    case 'tdap': {
      const tenYearsAgo = subYears(today, 10);
      return vaccineDate >= tenYearsAgo
        ? PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_UP_TO_DATE
        : PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_ACTION_NEEDED;
    }

    case 'colonoscopy': {
      const tenYearsAgo = subYears(today, 10);
      return vaccineDate >= tenYearsAgo
        ? PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_UP_TO_DATE
        : PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_ACTION_NEEDED;
    }

    case 'breastCancerScreening': {
      const twoYearsAgo = subYears(today, 2);
      return vaccineDate >= twoYearsAgo
        ? PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_UP_TO_DATE
        : PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_ACTION_NEEDED;
    }

    case 'diabeticEyeExam': {
      const oneYearAgo = subYears(today, 1);
      return vaccineDate >= oneYearAgo
        ? PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_UP_TO_DATE
        : PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_ACTION_NEEDED;
    }

    // For other preventative care types (pneumovax, shingrix)
    // they are considered up to date if they have a valid past date
    default:
      return PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_UP_TO_DATE;
  }
}
