import { MedPermissions } from '@/pages/patients/PatientMedications/PatientMedicationsList';
import { TypeOfEncounter } from '@/pages/patients/patientDetails/ui/Notes/Notes.types';
import { NoteStatus } from '@/shared/generated/grpc/go/pms/pkg/note/note.pb';
import { AsyncTitrationAsyncTitrationStatus as TitrationStatus } from '@/shared/generated/grpc/go/pms/pkg/patient/medication/medication.pb';
import { useFlags, useIsCnExperience } from '@/shared/hooks';
import { ProviderTeamRole } from '@/shared/types/provider.types';

import { isAsyncTitrationPending } from '../statuses';
import type { TitrationRecommendation } from './useTitrationRecommendation';

type ShouldDisplay = {
  shouldDisplay: boolean;
  readOnly?: boolean;
};

export function useShouldDisplayAsyncTitration(
  asyncTitration: TitrationRecommendation,
  permissions?: MedPermissions,
  noteId?: Maybe<number>,
  typeOfEncounter?: TypeOfEncounter,
  hideSuggestions?: boolean,
  providerRole?: ProviderTeamRole,
): ShouldDisplay {
  const { asyncTitrations: enableAsyncTitrations } = useFlags();
  const { isCnExperience, isLoading: isLoadingCnExperienceCheck } =
    useIsCnExperience();
  const isTitrationCn =
    providerRole === ProviderTeamRole.TITRATION_CLINICAL_NAVIGATOR;
  const allowedCnEncounters = [
    TypeOfEncounter.CN_TITRATION_OUTREACH,
    ...(isTitrationCn ? [TypeOfEncounter.PATIENT_FOLLOW_UP] : []),
  ];

  const {
    status,
    isWritable,
    initialReviewNoteId: initialNoteId,
    initialReviewNoteStatus: initialNoteStatus,
    consentRequestNoteId: consentNoteId,
    consentRequestNoteStatus: consentNoteStatus,
    providerDecisionNoteStatus,
  } = asyncTitration;

  if (
    hideSuggestions ||
    !enableAsyncTitrations ||
    !status ||
    !isWritable ||
    isLoadingCnExperienceCheck ||
    (noteId &&
      isCnExperience &&
      !allowedCnEncounters.includes(typeOfEncounter as TypeOfEncounter))
  ) {
    return { shouldDisplay: false };
  }

  const hasTitratePermissions = permissions === MedPermissions.Titrate;
  const hasNoteId = typeof noteId !== 'undefined';
  const isCurrentNote =
    hasNoteId && (noteId === initialNoteId || noteId === consentNoteId);
  const isAsyncReview = typeOfEncounter === TypeOfEncounter.ASYNC_REVIEW;

  if (status === TitrationStatus.NEW) {
    return {
      shouldDisplay: hasNoteId && isAsyncReview,
      readOnly: !hasTitratePermissions,
    };
  }

  const isRnOutreach = typeOfEncounter === TypeOfEncounter.TITRATION_OUTREACH;
  const isCnOutreach =
    typeOfEncounter === TypeOfEncounter.CN_TITRATION_OUTREACH;
  const cnCanObtainConsent =
    isCnOutreach && permissions === MedPermissions.ProactiveTitrationConsent;

  if (status === TitrationStatus.INITIALLY_REVIEWED) {
    const isReviewPublished = initialNoteStatus === NoteStatus.PUBLISHED;
    const canObtainConsent =
      isReviewPublished && (isRnOutreach || cnCanObtainConsent);
    const canUndoReview = isCurrentNote && !isReviewPublished;

    return {
      shouldDisplay: isAsyncTitrationPending(asyncTitration, noteId),
      readOnly: !canObtainConsent && !canUndoReview,
    };
  }

  if (status === TitrationStatus.PROVIDER_ACCEPTED) {
    const isProviderDecisionPublished =
      providerDecisionNoteStatus === NoteStatus.PUBLISHED;
    const canObtainConsent =
      isProviderDecisionPublished && (isRnOutreach || cnCanObtainConsent);

    return {
      shouldDisplay: isAsyncTitrationPending(asyncTitration, noteId),
      readOnly: !canObtainConsent,
    };
  }

  if (status === TitrationStatus.REJECTED_ON_INITIAL_REVIEW) {
    return {
      shouldDisplay: isCurrentNote && isAsyncReview,
      readOnly: false,
    };
  }

  if (
    status === TitrationStatus.PATIENT_REJECTED ||
    status === TitrationStatus.PATIENT_CONSENTED
  ) {
    const isConsentPublished = consentNoteStatus === NoteStatus.PUBLISHED;
    const canUndoConsentDecision = isCurrentNote && !isConsentPublished;

    return {
      shouldDisplay: canUndoConsentDecision,
      readOnly: false,
    };
  }

  return { shouldDisplay: false };
}
