import { useIntl } from 'react-intl';

import { useFlags } from '@/shared/hooks';

import type { EncounterModuleInstance } from '../Notes.types';
import { InputsPreview } from './InputsPreview';
import { RtfBodyPreview } from './RtfBodyPreview';
import { OutputType, getVisitLayoutOutput } from './getVisitLayoutOutput';

type Props = {
  encounterModuleInstances?: EncounterModuleInstance[];
  signature: Maybe<string>;
};

export const VisitLayoutNoteBodyPreview = ({
  encounterModuleInstances,
  signature,
}: Props) => {
  const intl = useIntl();
  const { careModelVersion } = useFlags();

  if (!encounterModuleInstances) {
    return null;
  }

  const moduleNoteBodyPreviews = getVisitLayoutOutput(
    encounterModuleInstances,
    (moduleId, encounterTypeInstance, inputs) => (
      <InputsPreview
        encounterModuleId={moduleId}
        encounterTypeInstance={encounterTypeInstance}
        inputs={inputs}
      />
    ),
    (rtfBody) => <RtfBodyPreview rtfBody={rtfBody} />,
    intl,
    careModelVersion,
    OutputType.NotePreview,
    null,
    null,
    null,
    null,
  );

  return (
    <div>
      {moduleNoteBodyPreviews}
      {signature ? (
        <>
          <br />
          {signature}
        </>
      ) : null}
    </div>
  );
};
