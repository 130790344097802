import { format, isValid } from 'date-fns';
import { FormattedMessage, type IntlShape } from 'react-intl';

import { PreventativeCareStatus } from '@/shared/generated/grpc/go/pms/pkg/care_plan/care_plan.pb';
import type {
  EmergencyVisit,
  PreventativeCare,
  PsychosocialAssessment,
  Specialist,
} from '@/shared/generated/grpc/go/pms/pkg/ccm_care_plan/ccm_care_plan.pb';
import { parseGrpcDate } from '@/shared/utils/grpc';

import { PSYCHOSOCIAL_ASSESSMENT_OPTIONS } from '../../CarePlanForm/sections/PsychosocialAssessment/psychoSocialAssessmentOptions';
import { list, listItem } from './CarePlanPreview.css';
import { FieldValue } from './Layout';

export function formatGoogleDate(googleDate?: GoogleDate) {
  const NA = <FormattedMessage defaultMessage="N/A" />;

  if (!googleDate) {
    return NA;
  }

  const date = parseGrpcDate(googleDate);

  if (!isValid(date)) {
    return NA;
  }

  return format(date, 'MM/dd/yyyy');
}

export function formatSpecialist(specialist: Specialist, intl: IntlShape) {
  const { name, specialty, lastVisit, nextVisit } = specialist;
  const specialistStr = [name, specialty].filter(Boolean).join(', ');
  const lastVisitStr = lastVisit
    ? intl.formatMessage(
        { defaultMessage: 'Last visit: {lastVisit}' },
        { lastVisit },
      )
    : '';
  const nextVisitStr = nextVisit
    ? intl.formatMessage(
        { defaultMessage: 'Next visit: {nextVisit}' },
        { nextVisit },
      )
    : '';
  const visitsStr = [lastVisitStr, nextVisitStr].filter(Boolean).join(', ');
  return [specialistStr, visitsStr].join('. ');
}

export function formatDisabledPsychosocialAssessments(
  intl: IntlShape,
  psychosocialAssessments:
    | (PsychosocialAssessment & { label?: string })[]
    | undefined,
) {
  const disabledAssessments =
    psychosocialAssessments?.filter(({ enabled }) => !enabled) || [];

  if (!disabledAssessments.length) {
    return (
      <FieldValue>
        {intl.formatMessage({ defaultMessage: 'None at this time' })}
      </FieldValue>
    );
  }

  return (
    <ul className={list}>
      {disabledAssessments.map(({ label, description }) => (
        <li key={label} className={listItem}>
          {label}
          {description && ` - ${description}`}
        </li>
      ))}
    </ul>
  );
}

export function formatEmergencyVisit({
  hospital,
  date,
  relatedDiagnosis,
}: EmergencyVisit) {
  return `${hospital}, ${formatGoogleDate(date)}, ${relatedDiagnosis}`;
}

export function formatPreventativeCare(
  {
    label,
    date,
    enabled,
    notApplicable,
    status,
    nextSteps,
  }: PreventativeCare & { label?: string },
  carePlanOptimization: boolean,
) {
  if (status === PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_DECLINED) {
    return `${label}: No, patient declined${date ? `. ${date}` : ''}`;
  }

  if (notApplicable && !enabled) {
    return `${label}, N/A`;
  }

  let result = `${label}: ${enabled ? `Yes${date ? `, ${date}` : ''}` : 'No'}`;

  const appendNextSteps =
    carePlanOptimization &&
    status === PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_ACTION_NEEDED &&
    nextSteps;

  if (appendNextSteps) {
    result += `. ${nextSteps}`;
  }

  return result;
}

export function getPreventativeCareGaps(
  preventativeCare: (PreventativeCare & { label?: string })[],
) {
  return preventativeCare.filter(
    ({ nextSteps, notApplicable, enabled, status }) =>
      (status &&
      status !== PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_UNSPECIFIED
        ? status ===
          PreventativeCareStatus.PREVENTATIVE_CARE_STATUS_ACTION_NEEDED
        : !enabled) &&
      !notApplicable &&
      nextSteps,
  );
}

export function addPsychosocialAssessmentLabels(
  psychosocialAssessments: PsychosocialAssessment[],
) {
  return psychosocialAssessments.map((assessment) => {
    const option = PSYCHOSOCIAL_ASSESSMENT_OPTIONS.find(
      (opt) => opt.name === assessment.name,
    );
    return {
      ...assessment,
      label: option?.label || assessment.name,
    };
  });
}
