import type { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { Autocomplete, IconButton, TextField } from '@/deprecated/mui';
import type { TextFieldProps } from '@/deprecated/mui';
import { queriesAreLoading } from '@/reactQuery';
import CloseIcon from '@/shared/assets/svgs/close.svg?react';
import { usePatientDetails, usePatientHospitals } from '@/shared/hooks/queries';
import {
  usePmsPatientExternalCareProviders,
  useProviderList,
} from '@/shared/hooks/queries/ehr-clinic.queries';
import { EHR } from '@/shared/types/ehr.types';

type NoteCareProviderSearchBarProps = {
  required?: boolean;
  disabled?: boolean;
  patientId: string;
  onClose: () => void;
  onSelect: (id: string) => void;
  selectedValue?: string;
};

type ProviderOption = { id: string; label: string };

function useNoteProviders(patientId: string) {
  const clinicsQuery = usePatientHospitals({ patientId });
  const patientsQuery = usePatientDetails(patientId, false);
  const isCerner = patientsQuery.data?.ehr_information?.ehr === EHR.Cerner;
  const externalProvidersQuery = useProviderList(
    clinicsQuery.data?.hospitals?.[0],
    patientsQuery.data?.ehr_information ?? undefined,
  );
  const pmsProvidersQuery = usePmsPatientExternalCareProviders(patientId, {
    enabled: isCerner,
  });

  const isLoading = queriesAreLoading([
    clinicsQuery,
    patientsQuery,
    externalProvidersQuery,
  ]);

  // Combine providers if Cerner
  const combinedProviders = isCerner
    ? [
        ...(pmsProvidersQuery.data?.providers ?? []),
        ...(externalProvidersQuery.data?.providers ?? []),
      ]
    : externalProvidersQuery.data?.providers ?? [];
  const providerOptions = useMemo(() => {
    // Create a Map to dedupe by NPI and transform at the same time
    const providerMap = new Map();
    combinedProviders.forEach(
      ({
        provider_id,
        provider_name,
        provider_type,
        epic_provider_id,
        fhir_provider_id,
        npi,
      }) => {
        if (!npi || !providerMap.has(npi)) {
          let label = provider_name;
          if (provider_type && !provider_name?.includes(', ')) {
            label += `, ${provider_type}`;
          }

          const externalProviderId =
            epic_provider_id || fhir_provider_id || provider_id;

          providerMap.set(npi || externalProviderId, {
            id: externalProviderId.toString(),
            label,
          });
        }
      },
    );

    return Array.from(providerMap.values());
  }, [
    isCerner,
    pmsProvidersQuery.data?.providers,
    externalProvidersQuery.data?.providers,
  ]);

  return {
    providerOptions,
    isLoading,
  };
}

export const NoteCareProviderSearchBar = ({
  required,
  disabled,
  patientId,
  onClose,
  onSelect,
  selectedValue,
}: NoteCareProviderSearchBarProps) => {
  const intl = useIntl();
  const [inputValue, setInputValue] = useState('');
  const { providerOptions, isLoading: isLoadingProviders } =
    useNoteProviders(patientId);

  useUpdateInputValue(providerOptions, setInputValue, selectedValue);

  const optionValue = useMemo(() => {
    if (selectedValue) {
      const selectedOption = providerOptions.find(
        (option) => option.id === selectedValue,
      );
      if (selectedOption) {
        return selectedOption;
      }
    }
    // No option selected
    return undefined;
  }, [selectedValue, providerOptions]);

  function handleSelectOption(
    e: SyntheticEvent,
    option: ProviderOption | null | string, // | string to make mui types happy, this should not happen
  ) {
    if (!option || typeof option === 'string') return;
    onSelect(option.id);
  }

  function handleClear() {
    setInputValue('');
    onClose();
  }

  function handleBlur() {
    if (disabled) {
      handleClear();
    }
  }

  function handleInput(e: SyntheticEvent, value: string) {
    setInputValue(value);
  }

  const getInputProps = (params: TextFieldProps) => ({
    ...params.InputProps,
    ...(inputValue && {
      endAdornment: (
        <IconButton disabled={disabled} onMouseDown={handleClear} size="small">
          <CloseIcon width="12px" />
        </IconButton>
      ),
    }),
  });

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Autocomplete
      size="small"
      disabled={disabled}
      onBlur={handleBlur}
      onChange={handleSelectOption}
      inputValue={inputValue}
      value={optionValue}
      onInputChange={handleInput}
      loading={isLoadingProviders}
      options={providerOptions}
      fullWidth
      disableClearable
      renderOption={(props, option) => (
        // Override default renderOption to fix bug when there are duplicate
        // labels due to defaultRenderOption setting key={option.label})
        <li {...props} key={option.id}>
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          disabled={disabled}
          required={required}
          inputRef={inputRef}
          label={
            isLoadingProviders
              ? intl.formatMessage({ defaultMessage: 'Loading...' })
              : intl.formatMessage({ defaultMessage: 'Search Providers' })
          }
          InputProps={getInputProps(params)}
          sx={{ width: '100%' }}
        />
      )}
    />
  );
};

function useUpdateInputValue(
  providerOptions: ProviderOption[],
  setInputValue: Dispatch<SetStateAction<string>>,
  selectedValue?: string,
) {
  useEffect(() => {
    const selectedOption = providerOptions.find(
      (option) => option.id === selectedValue,
    );
    if (selectedOption) {
      setInputValue(selectedOption?.label || '');
    }
    // We want this to run when selectedValue or providerOptions changes.
    // setInputValue should not change because it is a React setState handler
    // (but the lint rule doesn't detect it as such because it's being passed
    // as a callback)
  }, [selectedValue, providerOptions, setInputValue]);
}
