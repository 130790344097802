import type {
  AllTagType,
  AllWeightGainTagType,
} from '@/shared/types/tagsAndThreshold.types';
import {
  BloodGlucoseMonthlyAverageTagType,
  BloodGlucoseTagType,
  BloodGlucoseWeeklyPercentageOutOfRangeTagType,
  BloodPressureTagType,
  DryWeightTagType,
  HeartRateTagType,
  WeightGainTagType,
} from '@/shared/types/tagsAndThreshold.types';

// Blood Glucose
const BgRelatedTags = [
  BloodGlucoseTagType.BloodGlucoseHighP0,
  BloodGlucoseTagType.BloodGlucoseHighP1,
  BloodGlucoseTagType.BloodGlucoseLowP0,
  BloodGlucoseTagType.BloodGlucoseLowP1,
];

const BgWeeklyPercentageRelatedTags = [
  BloodGlucoseWeeklyPercentageOutOfRangeTagType.BloodGlucoseWeeklyPercentageOutOfRangeHighP0,
  BloodGlucoseWeeklyPercentageOutOfRangeTagType.BloodGlucoseWeeklyPercentageOutOfRangeHighP1,
  BloodGlucoseWeeklyPercentageOutOfRangeTagType.BloodGlucoseWeeklyPercentageOutOfRangeHighP2,
];

const BgAvgRelatedTags = [
  BloodGlucoseMonthlyAverageTagType.BloodGlucoseMonthlyAverageHighP0,
  BloodGlucoseMonthlyAverageTagType.BloodGlucoseMonthlyAverageHighP1,
  BloodGlucoseMonthlyAverageTagType.BloodGlucoseMonthlyAverageHighP2,
];

export const isBgRelatedTags = (tag: AllTagType) =>
  BgRelatedTags.includes(tag as BloodGlucoseTagType);

export const isBgWeeklyPercentageRelatedTags = (tag: AllTagType) =>
  BgWeeklyPercentageRelatedTags.includes(
    tag as BloodGlucoseWeeklyPercentageOutOfRangeTagType,
  );

export const isBgAvgTag = (tag: AllTagType) =>
  BgAvgRelatedTags.includes(tag as BloodGlucoseMonthlyAverageTagType);

// Blood Pressure
const systolicRelatedTags = [
  BloodPressureTagType.SystolicHighP1,
  BloodPressureTagType.SystolicHighP0,
  BloodPressureTagType.SystolicLowP0,
  BloodPressureTagType.SystolicLowP1,
];

const diastolicRelatedTags = [
  BloodPressureTagType.DiastolicHighP1,
  BloodPressureTagType.DiastolicHighP0,
  BloodPressureTagType.DiastolicLowP0,
  BloodPressureTagType.DiastolicLowP1,
];

const BpRelatedTags = [
  ...systolicRelatedTags,
  ...diastolicRelatedTags,
  BloodPressureTagType.MeanArterialPressureLowP0,
];

export const isBpRelatedTags = (tag: AllTagType) =>
  BpRelatedTags.includes(tag as BloodPressureTagType);

export function isBpMonthlyPercentageRelatedTag(tag: AllTagType) {
  return [BloodPressureTagType.MonthlyPercentageHighP1].includes(
    tag as BloodPressureTagType,
  );
}

export const isBpMonthlyAvgTag = (tag: AllTagType) =>
  [BloodPressureTagType.MonthlyAverageHighP0].includes(
    tag as BloodPressureTagType,
  );

// Weight
const WeightGainRelatedTagList = [
  WeightGainTagType.RapidWeightGainP0,
  WeightGainTagType.WeeklyWeightGainP0,
];

const WeeklyWeightGainRelatedTagList = [WeightGainTagType.WeeklyWeightGainP0];

const DryWeightRelatedTagList = [
  DryWeightTagType.DryWeightGainP0,
  DryWeightTagType.DryWeightLossP1,
];

const WeightRelatedTagList = [
  ...WeightGainRelatedTagList,
  ...DryWeightRelatedTagList,
];

export const isWeightGainRelatedTags = (
  tag: AllTagType,
): tag is AllWeightGainTagType =>
  WeightGainRelatedTagList.includes(tag as WeightGainTagType);

export const isRapidWeightGainRelatedTags = (tag: AllTagType) =>
  [WeightGainTagType.RapidWeightGainP0].includes(tag as WeightGainTagType);

export const isWeeklyWeightGainRelatedTags = (tag: AllTagType) =>
  WeeklyWeightGainRelatedTagList.includes(tag as WeightGainTagType);

export const isDryWeightRelatedTags = (tag: AllTagType) =>
  DryWeightRelatedTagList.includes(tag as DryWeightTagType);
export const isWeightRelatedTags = (tag: string) =>
  WeightRelatedTagList.includes(tag as WeightGainTagType);

const HeartRateRelatedTagList = [
  HeartRateTagType.PulseLowP0,
  HeartRateTagType.PulseHighP0,
];

export const isHeartRateRelatedTags = (tag: AllTagType) =>
  HeartRateRelatedTagList.includes(tag as HeartRateTagType);
