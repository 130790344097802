import type { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Condition,
  Gender,
} from '@/shared/generated/grpc/go/pms/pkg/patient/pms.pb';
import { type PatientDemographics } from '@/shared/hooks/usePatientDemographics';

export type PreventativeCareOption = {
  name:
    | 'pneumovax'
    | 'influenza'
    | 'tdap'
    | 'shingrix'
    | 'colonoscopy'
    | 'breastCancerScreening'
    | 'diabeticEyeExam';
  label: string;
  help: ReactElement;
  alwaysApplicable: boolean;
  notApplicable?: (patientDemographics: PatientDemographics) => boolean;
  type: PreventativeCareType;
};

export enum PreventativeCareType {
  Screening = 'screening',
  Vaccination = 'vaccination',
}

export const PREVENTATIVE_CARE_OPTIONS: PreventativeCareOption[] = [
  {
    name: 'pneumovax',
    // Using string for label so it can be used in Handlebars templates
    label: 'Pneumovax',
    help: <FormattedMessage defaultMessage="At or after age of 65" />,
    alwaysApplicable: true,
    notApplicable: ({ age }: PatientDemographics) => Boolean(age && age < 65),
    type: PreventativeCareType.Vaccination,
  },
  {
    name: 'influenza',
    label: 'Influenza',
    help: <FormattedMessage defaultMessage="During yearly flu season" />,
    alwaysApplicable: true,
    type: PreventativeCareType.Vaccination,
  },
  {
    name: 'tdap',
    label: 'Tdap',
    help: <FormattedMessage defaultMessage="Every 10 years OR after injury" />,
    alwaysApplicable: true,
    type: PreventativeCareType.Vaccination,
  },
  {
    name: 'shingrix',
    label: 'Shingrix',
    help: <FormattedMessage defaultMessage="Adults age 50+" />,
    alwaysApplicable: true,
    notApplicable: ({ age }: PatientDemographics) => Boolean(age && age < 50),
    type: PreventativeCareType.Vaccination,
  },
  {
    name: 'colonoscopy',
    label: 'Colorectal Cancer Screening',
    help: <FormattedMessage defaultMessage="Adults age 45-75" />,
    alwaysApplicable: true,
    notApplicable: ({ age }: PatientDemographics) =>
      Boolean(age && (age < 45 || age > 75)),
    type: PreventativeCareType.Screening,
  },
  {
    name: 'breastCancerScreening',
    label: 'Breast Cancer Screening',
    help: <FormattedMessage defaultMessage="Females age 40+" />,
    alwaysApplicable: false,
    notApplicable: ({ age, gender }: PatientDemographics) =>
      Boolean((age && (age < 40 || age > 74)) || gender !== Gender.FEMALE),
    type: PreventativeCareType.Screening,
  },
  {
    name: 'diabeticEyeExam',
    label: 'Diabetic Eye Exam',
    help: (
      <FormattedMessage defaultMessage="Annually for patients with Diabetes diagnosis" />
    ),
    alwaysApplicable: false,
    notApplicable: ({ conditions }: PatientDemographics) =>
      !conditions?.includes(Condition.TYPE_2_DIABETES),
    type: PreventativeCareType.Screening,
  },
];
